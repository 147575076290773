import addBanner from 'actions/banner/addBanner';
import types from 'actions/types';
import AlbertClient from 'api/AlbertClient';
import { ErrorMessages, NotificationTypes } from 'constants/index';
import * as WEB from 'types/interfaces';
import { onError } from 'utils/axiosMiddlewareUtils';
import getErrorMessageFromResponse from 'utils/getErrorMessageFromResponse';

export interface CreateProfileFields {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
}

export type Attribution = Readonly<{
  advertising_partner_name?: string;
  ad_campaign?: string;
  ad_set?: string;
  ad_name?: string;
  ad_keyword?: string;
  ad_click_timestamp?: number;
  prevent_overwrite_ad_info?: boolean;
  gclid?: string;
  gbraid?: string;
  wbraid?: string;
  facebook_fbp?: string;
  facebook_fbc?: string;
  msclkid?: string;
}>;

export default (
    createProfileFields: CreateProfileFields,
    attribution?: Attribution
  ): WEB.ActionCreator<Promise<boolean>> =>
  async (dispatch: WEB.Dispatch): Promise<boolean> => {
    let error = false;

    await dispatch({
      types: [
        types.CREATE_PROFILE_REQUEST,
        types.CREATE_PROFILE_SUCCESS,
        types.CREATE_PROFILE_FAILURE,
      ],
      payload: {
        request: {
          method: 'post',
          url: AlbertClient.profileView(),
          data: {
            ...createProfileFields,
            attribution,
            device_name: navigator.userAgent,
            signup_web: true,
          },
        },
        options: {
          onError: (middlewareOptions: any, options: any) => {
            error = true;

            const message =
              getErrorMessageFromResponse(middlewareOptions?.error?.response) ||
              ErrorMessages.web.createProfile;
            dispatch(addBanner(NotificationTypes.WARNING, message, false));

            return onError(middlewareOptions, options);
          },
        },
      },
    });

    return error;
  };
