/**
 * Values for KeyboardEvent.key,
 * https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key
 */
enum KeyboardEventKeys {
  ENTER = 'Enter',
  UP = 'ArrowUp',
  DOWN = 'ArrowDown',
  ESC = 'Escape',
  SPACEBAR = ' ',
  TAB = 'Tab',
}

export default KeyboardEventKeys;
