import * as React from 'react';
import { IconContainer, Props } from './shared';

const PayOffDebtIcon = (props: Props): React.ReactElement => {
  const { color } = props;

  return (
    <IconContainer>
      <svg
        width='24'
        height='22'
        viewBox='0 0 24 22'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g clipPath='url(#clip0_9839_5454)'>
          <path
            d='M22.6318 19.287H22.2678V17.131C22.2678 15.647 21.0918 14.471 19.6078 14.471H19.4958V7.97498L20.1678 8.45098C20.3638 8.59098 20.5878 8.67498 20.8398 8.67498C21.4558 8.67498 21.9598 8.17098 21.9598 7.55498C21.9598 7.16298 21.7638 6.82698 21.4838 6.63098L13.9518 1.33898C12.8038 0.498985 11.1238 0.498985 9.97577 1.33898L2.44377 6.63098C2.16377 6.82698 1.96777 7.16298 1.96777 7.55498C1.96777 8.17098 2.47177 8.67498 3.08777 8.67498C3.33977 8.67498 3.56377 8.59098 3.75977 8.45098L4.43177 7.97498V14.471H4.31977C2.86377 14.471 1.65977 15.647 1.65977 17.131V19.287H1.29577C0.707766 19.287 0.259766 19.763 0.259766 20.351C0.259766 20.939 0.707766 21.415 1.29577 21.415H22.6318C23.2198 21.415 23.6958 20.939 23.6958 20.351C23.6958 19.763 23.2198 19.287 22.6318 19.287ZM3.89977 19.287V17.131C3.89977 16.879 4.09577 16.711 4.31977 16.711H19.6078C19.8598 16.711 20.0278 16.879 20.0278 17.131V19.287H3.89977ZM6.67177 15.031V7.77898H9.10777V15.031H6.67177ZM7.09177 6.09898L11.2638 3.15898C11.6558 2.87898 12.2718 2.87898 12.6638 3.15898L16.8358 6.09898H7.09177ZM10.7878 15.031V7.77898H13.1678V15.031H10.7878ZM14.8478 15.031V7.77898H17.2558V15.031H14.8478Z'
            fill={color || '#333333'}
          />
        </g>
        <defs>
          <clipPath id='clip0_9839_5454'>
            <rect
              width='23.436'
              height='20.706'
              fill='white'
              transform='translate(0.259766 0.708984)'
            />
          </clipPath>
        </defs>
      </svg>
    </IconContainer>
  );
};

export default PayOffDebtIcon;
