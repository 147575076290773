import * as Sentry from '@sentry/react';
import * as React from 'react';
import { useSelector } from 'react-redux';
import BannerPane from 'components/BannerPane';
import Button from 'components/common/Button';
import TopInfoButton from 'components/common/Button/TopInfoButton';
import CheckCircleList from 'components/common/List/CheckCircleList';
import MobileInfoModal from 'components/common/Modal/MobileInfoModal';
import Spacer from 'components/common/Spacer';
import Text, { TextColor, TextSizes } from 'components/common/Text';
import ButtonsLayout from 'components/layout/ButtonsLayout';
import ContentPane, { ContentContainer } from 'components/layout/ContentPane';
import InfoPane from 'components/layout/InfoPane';
import LeadContent from 'components/layout/LeadContent';
import PageBase from 'components/layout/PageBase';
import { SplashLink } from 'constants/index';
import * as language from 'reducers/entities/language';
import * as profile from 'reducers/entities/profile';
import { spacers } from 'styles';
import { spacers as homeSpacers } from 'styles/home';
import * as WEB from 'types/interfaces';
import {
  getLanguageButtons,
  getLanguageInfo,
  getLanguageSection,
} from 'utils/getFromLanguage';

type Props = {
  isLoading: boolean;
  isCompleted: boolean;
  onClickContinue: (e: React.KeyboardEvent<HTMLElement>) => Promise<void>;
  onClickLater: () => void;
};

const DirectDepositNoBonus = (props: Props): React.ReactElement => {
  const { isLoading, isCompleted, onClickContinue, onClickLater } = props;
  const [showInfoModal, setShowInfoModal] = React.useState<boolean>(false);
  const languageCtx = useSelector((state: WEB.RootState) =>
    language.getSignupPageLanguage(state, 'DirectDepositNoBonus')
  );
  let infoCtx = getLanguageInfo(languageCtx);
  let introSection = getLanguageSection(languageCtx, 'intro');
  let items = getLanguageSection<WEB.LanguageList>(languageCtx, 'list');
  let buttonsCtx = getLanguageButtons(languageCtx);
  const expanders = infoCtx?.expanders;

  // @todo remove this condition after language moves to main
  if (Object.keys(languageCtx).length === 0) {
    infoCtx = {
      header: 'Set up direct deposit',
      text: 'Access your paycheck up to 2 days early when you set up direct deposit to your new bank account with Albert.',
    };
    introSection = {
      header: 'Get paid up to 2 days early',
      text: 'Set up direct deposit to access your paycheck up to 2 days early and unlock more benefits with your new bank account.',
    };
    items = [
      {
        header: '',
        text: 'Get paid up to 2 days early',
      },
      {
        header: '',
        text: 'Up to 20% cash back rewards',
      },
      {
        header: '',
        text: '$0 Instant cash advance fees',
      },
      {
        header: '',
        text: 'Instant transfers',
      },
    ];
    buttonsCtx = {
      primary: 'Continue',
      link: 'Later',
    };
  }

  const handleInfoOnClick = () => {
    setShowInfoModal(true);
  };

  const showInfo = Array.isArray(expanders);

  return (
    <>
      {showInfo && <TopInfoButton onClick={handleInfoOnClick} />}
      <InfoPane.Desktop size='h2' {...infoCtx} />
      <ContentPane>
        <BannerPane />
        <ContentContainer>
          <PageBase>
            <LeadContent
              header={introSection?.header}
              text={introSection?.text}
              desktopHeaderSize='small'
            />
            <Spacer space={homeSpacers.g3} />
            <CheckCircleList items={items} />
            <Spacer space={spacers.large} />
            <Text color={TextColor.GRAY} size={TextSizes.SMALL}>
              By clicking Continue, you agree to Atomic&apos;s{' '}
              <Text inline underline weight='700' color={TextColor.GRAY}>
                <a
                  href={SplashLink.ATOMIC_PRIVACY as string}
                  target='_blank'
                  rel='noreferrer'
                >
                  privacy policy
                </a>
              </Text>
              .
            </Text>
            <ButtonsLayout
              marginTop={homeSpacers.g6}
              primaryButton={
                <Button
                  onClick={onClickContinue}
                  isCompleted={isCompleted}
                  isLoading={isLoading}
                  disabled={isLoading}
                >
                  {isCompleted ? null : buttonsCtx?.primary}
                </Button>
              }
              secondaryButton={
                <Text
                  isLinkButton
                  underline
                  nowrap
                  weight='700'
                  size='small'
                  onClick={onClickLater}
                >
                  {buttonsCtx?.link}
                </Text>
              }
            />
          </PageBase>
        </ContentContainer>
      </ContentPane>
      {showInfo && (
        <MobileInfoModal
          expanders={expanders}
          show={showInfoModal}
          onCancel={() => setShowInfoModal(false)}
        />
      )}
    </>
  );
};

export default DirectDepositNoBonus;
