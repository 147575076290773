import types from 'actions/types';
import AlbertClient from 'api/AlbertClient';
import SignupGoals, { SignupGoalsType } from 'constants/SignupGoals';
import { TrustedContactPhoneType } from 'constants/TrustedContactPhoneType';
import * as WEB from 'types/interfaces';
import { AutoSaving, DepositDistribution } from 'types/interfaces';
import { onErrorDisplayDefaultBanner } from 'utils/axiosMiddlewareUtils';

export interface UpdateProfileFields {
  // SourcePhoneNumber
  personal_info_signup_source?: string;
  personal_info_signup_source_influencer?: string | number | null;
  phone?: string;

  // TextOptin
  text_opt_in?: boolean;

  // ConfirmNumber
  text_confirm_code?: string;

  // ProfileInfo
  first_name?: string;
  last_name?: string;
  married?: boolean;
  kids?: number;
  birthday?: number;
  gender?: string;
  personal_info_set_pin?: boolean;
  personal_info_saved_contact?: boolean;
  personal_info_credit_score?: string;
  signup_goals?: SignupGoals[];
  signup_goals_type?: SignupGoalsType;
  is_tracking_disabled?: boolean;

  // EmploymentStatus
  occupation?: string;
  income_type?: string;
  income_source?: string;
  income?: number;
  employment?: string;

  // Manual auth
  personal_info_skipped_savings?: boolean;

  // InvestingInfo
  investment_info?: {
    signup_investing_interest?: boolean;
    portfolio_goals?: string[];
    investment_experience?: number | null;
    risk_tolerance?: number | null;
    time_horizon?: number | null;
    net_worth?: string;
    net_worth_bracket?: number;
    trusted_contact?: boolean;
    trusted_contact_family_name?: string;
    trusted_contact_given_name?: string;
    trusted_contact_phone_number?: string;
    trusted_contact_phone_number_type?: TrustedContactPhoneType;
    employer_name?: string;
    employment_position?: string;
    no_affiliations_apply?: boolean;
    is_politically_exposed?: boolean;
    is_exchange_affiliated?: boolean;
    is_control_person?: boolean;
    us_citizen?: boolean;
    deposit_distribution?: DepositDistribution | null;
    saw_signup_investing_affiliations_screen?: number; // Unix timestamp
  };

  // InstantInfo
  instant_info?: {
    signup_instant_interest?: boolean;
    signup_instant_paycycle?: string;
  };

  // Product interests
  personal_info_signup_cancel_subscriptions_interest?: boolean;
  personal_info_signup_budgeting_interest?: boolean;

  // Savings setup
  personal_info_signup_auto_savings?: boolean;
  auto_saving?: AutoSaving.ON | AutoSaving.PAUSED;

  // GeniusConfirm
  completed_genius_signup?: boolean;

  // Banking
  personal_info_signup_card_shipped?: boolean;
  personal_info_signup_dd_interest?: boolean;

  // Update web device and web app version on `Profile`
  updateDevice?: boolean;

  // Branch deep link direct deposit bonus signup flow.
  bonus_program?: string;
}

export default (
    fieldsToUpdate: UpdateProfileFields
  ): WEB.ActionCreator<Promise<Record<string, any>>> =>
  async (dispatch: WEB.Dispatch): Promise<Record<string, any>> => {
    const data: UpdateProfileFields & { device_name?: string } = {
      ...fieldsToUpdate,
    };
    let error = false;

    if (fieldsToUpdate?.updateDevice) {
      data.device_name = navigator.userAgent;
    }

    const response = await dispatch({
      types: [
        types.UPDATE_PROFILE_REQUEST,
        types.UPDATE_PROFILE_SUCCESS,
        types.UPDATE_PROFILE_FAILURE,
      ],
      payload: {
        request: {
          data,
          method: 'put',
          url: AlbertClient.profileView(),
        },
        options: {
          onError: (middlewareOptions: any, options: any) => {
            error = true;
            return onErrorDisplayDefaultBanner(middlewareOptions, options);
          },
        },
      },
    });

    return { response, error };
  };
