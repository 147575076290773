import types from 'actions/types';
import AlbertClient from 'api/AlbertClient';
import * as WEB from 'types/interfaces';

export interface AddInsLoginParams {
  // General
  link_session_id?: string;
  institution_id?: string;
  request_id?: string;

  // On exit
  status?: string;

  // On success
  get_auth?: boolean;
  public_token?: string;

  // Any other (ie. errors)
  [key: string]: any;
}

export default (params: AddInsLoginParams): WEB.Action => ({
  types: [
    types.ADD_INSTITUTION_LOGIN_REQUEST,
    types.ADD_INSTITUTION_LOGIN_SUCCESS,
    types.ADD_INSTITUTION_LOGIN_FAILURE,
  ],
  payload: {
    request: {
      method: 'post',
      url: AlbertClient.institutionsLoginView(),
      data: {
        ...params,
      },
    },
  },
});
