export enum Art {
  Logo = 0,
  BulbOff,
  BulbOn,
  Bonuses,
  CalendarOff,
  CalendarOn,
  CycleOff,
  CycleOn,
  TwoFingersOff,
  TwoFingersOn,
  Warning,
  AnnualSubscriptionGold,
  AnnualSubscriptionSilver,
  AppleAppStoreBanner,
  Bank,
  CheckNumbers,
  FlagSparkle,
  GeniusBulb,
  GeniusCards,
  GeniusChess,
  GeniusDollar,
  GeniusExampleChat,
  GeniusLogo,
  GeniusLogoNoText,
  GeniusLogoAlt,
  GeniusMountains,
  GeniusNewspaper,
  GeniusPopper,
  GeniusSignature,
  GeniusesGold,
  GeniusesTalking,
  GeniusesTalkingGold,
  InvestmentGraph,
  MoneyParachute,
  WomanPiggyBank,
  Navigation,
  AndroidPlayStoreBanner,
  ProfileCheck,
  Rocket,
  TextMessages,
  OptIn,
  ThumbsUp,
  WomanPlant,
  BarChart,
  DollarBill,
  Plant,
  Shapes,
  Vacation,
  Cake,
  Home,
  PaperPlane,
  Privacy,
  Scale,
  Shield,
  ShieldCheck,
  ShieldLock,
  Stocks,
  Tuition,
  Vault,
  Pig,
  NoBank,
  SmartSavingsBolt,
  Times,
  Search,
  ExclamationCircle,
  ExclamationCircleGenius,
  ExclamationCircleGray,
  ExclamationCircleOrange,
  ExclamationCircleRed,
  LockCheck,
  ShieldKYC,
  Fingerprint,
  Lock,
  DebitCardGenius,
  DebitCardWhite,
  DebitCardBlue,
  SecureLock,
  ModalErr,
  GreenCheck,
  NeutralCheck,
  SecureLockBackground,
  ConfirmIdentity,
  Phone,
  Laptop,
  Genius,
  IPhoneApp,
  LinkedAccounts,
  OneTimeTransfer,
  Scheduled,
  WarningBannerIcon,
  CashTransactionTransfer,
  ConfettiPhone,
  Cashback,
  LoadingSpinner,
  SocialMediaCircleIconEmail,
  SocialMediaCircleIconFacebook,
  SocialMediaCircleIconLinkedIn,
  SocialMediaCircleIconPinterest,
  SocialMediaCircleIconTwitter,
  SocialMediaCircleIconLink,
  ArrowNarrowLeft,
  ChevronUp,
  ChevronDown,
  InvestingTransactionGray,
  SavingsTransactionGray,
  CashTransactionGray,
  LicenseScan,
  GeniusLottieText,
  // app.albrt.co/signup-verification
  DownloadTheAppQRCode,
  BankAccount,
  LinkAccount,
  MMUpgradeIntro,
  DirectDeposit,
  SuccessCircle,
  UmbrellaRain,
  NewSignupPage1,
  DownloadOnTheAppStore,
  GetItOnGooglePlay,
  PaperAirplane,
  CashbackLogos,
  Handwave,
  Banking,
}
