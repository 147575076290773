import * as React from 'react';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
import Text, { TextColor, TextSizes } from 'components/common/Text';
import Lock from 'components/icons/Lock';
import { breakpoints } from 'styles';
import mixins from 'styles/mixins';
import { BaseInputProps, InputLabelProps } from 'types/interfaces';
import preventAutoZoom from 'utils/preventAutoZoom';
import FloatingLabel from './FloatingLabel';

type InternalBaseInputProps = {
  value?: string | number;
};

export const baseInputTransition = 'transition: all 0.1s ease-in 0.1s;';

const StyledInputContainer = styled.div<{
  disabled?: boolean;
  invalid?: boolean;
  height?: string;
}>`
  position: relative;
  height: ${({ height }) => height || mixins.pxToRem('43px')};
  width: 100%;
  border-color: ${({ invalid, theme }) =>
    invalid ? theme.colors.primaryError : theme.colors.lightGray1};
  border-width: 1.5px;
  ${({ theme, invalid }) =>
    invalid ? `box-shadow: 0 0 0 1px ${theme.colors.primaryError}` : ''};
  border-style: solid;
  border-radius: 4px;
  ${baseInputTransition}

  ${({ theme, disabled, invalid }) =>
    disabled || invalid
      ? ''
      : `
      &:hover, &.active {
        border-color: ${theme.colors.primaryAlbertBrand};
        box-shadow: 0 0 0 1px ${theme.colors.primaryAlbertBrand};
      }
    `}
`;

const StyledInput = styled.input<BaseInputProps & InternalBaseInputProps>`
  padding: 0 15px;
  height: 100%;
  width: 100%;
  color: ${({ theme }) => theme.colors.primaryText};
  background-color: white;
  border: none;
  border-radius: 4px;
  font-style: normal;
  outline: none;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'auto')};

  &::placeholder {
    color: ${({ theme }) => theme.colors.primaryGray};
  }

  &:focus::placeholder {
    color: transparent;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-text-fill-color: ${({ theme }) => theme.colors.primaryText};
    -webkit-box-shadow: white 0px 0px 0px 30px inset;
  }
`;

const StyledFloatingLabel = styled(FloatingLabel)`
  margin-left: 15px;
`;

const StyledFloatingLock = styled(Lock)`
  width: ${mixins.pxToRem('10px')};
  height: ${mixins.pxToRem('10px')};
  margin-left: ${mixins.pxToRem('5px')};
  margin-bottom: ${mixins.pxToRem('-1px')};
`;

const SecondaryLabel = styled.span`
  display: inline-block;
  color: ${({ theme }) => theme.colors.primaryGray};
  font-weight: 400;
  font-size: 12px;
  margin-left: 6px;
`;

export const BaseInput = React.forwardRef(function BaseInput(
  props: BaseInputProps & InternalBaseInputProps,
  ref: any
) {
  const [active, setActive] = React.useState(false);
  const [showFloatingLabel, setShowFloatingLabel] = React.useState(
    !!props.value
  );
  const onInputFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    preventAutoZoom();
    setActive(true);
    setShowFloatingLabel(true);
  };
  const inputRef = ref || React.createRef();

  const onInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setActive(false);
    // Hide the floating label when clicking out and the value is empty
    // because the placeholder will appear, which makes the label redundant
    if (!e.target.value) setShowFloatingLabel(false);
    props.onBlur && props.onBlur(e);
  };
  /**
   * Override default browser behavior of forcing the cursor on the left side
   * of the input when a user clicks near the top of the input
   */
  const onInputClick = (e: React.MouseEvent<HTMLInputElement>) => {
    const target = e.currentTarget;
    const { top: inputPositionTop, left: inputPositionLeft } =
      target.getBoundingClientRect();
    const { clientY: clickCoordinateY, clientX: clickCoordinateX } = e;

    if (
      isMobile &&
      // Check whether click occurred near top of input
      clickCoordinateY - 10 <= inputPositionTop &&
      // Check whether click is not actually near the beginning of the input
      clickCoordinateX - 30 > inputPositionLeft &&
      // Check whether browser is trying to move the cursor to the beginning of the input
      target.selectionStart === 0
    ) {
      const endOfInput = target.value?.length || 0;
      // Move cursor to the end of the input
      inputRef.current?.setSelectionRange(endOfInput, endOfInput);
    }
  };

  return (
    <StyledInputContainer
      disabled={props.disabled}
      invalid={props.invalid}
      className={`input-container ${active ? 'active' : ''}`}
      height={props.height}
    >
      <StyledInput
        {...props}
        ref={inputRef}
        onFocus={onInputFocus}
        onBlur={onInputBlur}
        onClick={onInputClick}
      />
      {props.floatingLabel && showFloatingLabel && (
        <StyledFloatingLabel isFloating>
          {props.floatingLabel}
          {props.disabled && <StyledFloatingLock />}
        </StyledFloatingLabel>
      )}
    </StyledInputContainer>
  );
});

export enum InputWidth {
  SMALL = '55%',
  MEDIUM = '75%',
  FULL = '100%',
}

export const InputWrapper = styled.div<{
  widthSize?: InputWidth;
  disabled?: boolean;
}>`
  position: relative;
  outline: none;
  width: ${({ widthSize }) => {
    let width = 'inherit';
    if (widthSize === InputWidth.SMALL) {
      width = '55%';
    } else if (widthSize === InputWidth.MEDIUM) {
      width = '75%';
    } else if (widthSize === InputWidth.FULL) {
      width = '100%';
    }
    return width;
  }};

  &:focus,
  &:hover {
    svg:not(.error-text) {
      ${({ theme, disabled }) =>
        disabled ? '' : `color: ${theme.colors.primaryText}`};
    }
  }

  @media ${breakpoints.mobileLarge} {
    width: 100%;
  }
`;

export const LabelWrapper = styled.div`
  margin-bottom: ${mixins.pxToRem('24px')};
  @media ${breakpoints.mobileLarge} {
    // mobile spacing handled by PageBase
    margin-bottom: 0;
  }
`;

export const Label = styled.label.attrs(() => ({
  className: 'label-header',
}))<{
  hideLabelOnMobile?: boolean;
  hasSmallLabel?: boolean;
}>`
  display: inline-block;
  font-weight: 700;
  @media ${breakpoints.mobileLarge} {
    ${({ hideLabelOnMobile, hasSmallLabel }) =>
      hideLabelOnMobile &&
      `
        ${!hasSmallLabel && 'display: none;'}
        &.hide-label-on-mobile {
          display: none;
        }
    `}
  }
`;

export const DescriptionWrapper = styled.div<{
  hideDescriptionOnMobile?: boolean;
}>`
  ${({ hideDescriptionOnMobile }) =>
    hideDescriptionOnMobile &&
    `@media ${breakpoints.mobileLarge} { display: none; }`}
  margin-top: ${mixins.pxToRem('10px')};
`;

const renderSmallLabel = (
  smallLabel: string | React.ReactNode,
  newLine: boolean
): string | React.ReactNode => {
  if (typeof smallLabel === 'string') {
    return (
      <Text
        size={TextSizes.SMALL}
        color={TextColor.GRAY}
        weight='400'
        inline={!newLine}
      >
        {newLine ? null : ' '}
        {smallLabel}
      </Text>
    );
  }
  return smallLabel;
};

const renderDescriptionLabel = (
  desc: string | React.ReactNode,
  hideDescriptionOnMobile?: boolean
): string | React.ReactNode => {
  let component = null;
  if (typeof desc === 'string') {
    component = <Text>{desc}</Text>;
  } else {
    component = desc;
  }
  return (
    <DescriptionWrapper
      hideDescriptionOnMobile={hideDescriptionOnMobile}
      className='description'
    >
      {component}
    </DescriptionWrapper>
  );
};

export const renderLabels = (props: InputLabelProps): React.ReactElement => {
  const {
    label,
    description,
    smallLabel,
    smallLabelNewLine,
    hideLabelOnMobile,
    hideDescriptionOnMobile,
    secondaryLabel,
  } = props;
  const hasLabel = !!(label || description || smallLabel);

  return (
    <>
      {hasLabel && (
        <LabelWrapper>
          <Label
            hideLabelOnMobile={hideLabelOnMobile}
            hasSmallLabel={!!smallLabel}
          >
            <span className='hide-label-on-mobile'>{label}</span>
            {secondaryLabel && (
              <SecondaryLabel>{secondaryLabel}</SecondaryLabel>
            )}
            {smallLabel && renderSmallLabel(smallLabel, !!smallLabelNewLine)}
          </Label>
          {description &&
            renderDescriptionLabel(description, hideDescriptionOnMobile)}
        </LabelWrapper>
      )}
    </>
  );
};
