import * as Sentry from '@sentry/react';
import { FORM_ERROR } from 'final-form';
import * as React from 'react';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import updateProfile from 'actions/async/updateProfile';
import addBanner from 'actions/banner/addBanner';
import removeBanner from 'actions/banner/removeBanner';
import Button from 'components/common/Button';
import Checkbox from 'components/common/Checkbox';
import Spacer from 'components/common/Spacer';
import ButtonsLayout from 'components/layout/ButtonsLayout';
import LeadContent from 'components/layout/LeadContent';
import PageBase from 'components/layout/PageBase';
import Pages from 'constants/Pages';
import { ErrorMessages, NotificationTypes } from 'constants/index';
import useBrowserNavigationBlockWithBanner from 'hooks/useBrowserNavigationBlockWithBanner';
import useNavigateFunction from 'hooks/useNavigateFunction';
import useVerifyCurrentPage from 'hooks/useVerifyCurrentPage';
import * as language from 'reducers/entities/language';
import * as profile from 'reducers/entities/profile';
import { spacers } from 'styles/home';
import * as WEB from 'types/interfaces';
import { InvestingAffiliations as InvestingAffiliationsType } from 'types/interfaces';
import { delayWithState } from 'utils/delay';
import { getLanguageButtons, getLanguageSection } from 'utils/getFromLanguage';
import { logger } from 'utils/logger';

type Props = {
  nextPage: string;
};

const NO_AFFILIATIONS = {
  no_affiliations_apply: false,
  is_politically_exposed: false,
  is_exchange_affiliated: false,
  is_control_person: false,
  us_citizen: false,
};

const StyledCheckbox = styled(Checkbox)`
  label {
    color: ${({ theme }) => theme.colors.darkGray1};
    font-size: 0.875rem;
  }
  div {
    align-items: center;
  }
`;

const InvestingAffiliations = (props: Props) => {
  const { updateCurrentSignupPage } = useNavigateFunction();
  const dispatch = useDispatch();
  useBrowserNavigationBlockWithBanner();
  // Make sure Redux is up-to-date with currentSignUpPage
  useVerifyCurrentPage(Pages.INVESTING_AFFILIATIONS);

  // Defaults
  const defaultNoAffiliationsApply = useSelector((state: WEB.RootState) =>
    profile.getInfoValueByField(
      state,
      'investment_info',
      'no_affiliations_apply'
    )
  );
  const defaultIsPoliticallyExposed = useSelector((state: WEB.RootState) =>
    profile.getInfoValueByField(
      state,
      'investment_info',
      'is_politically_exposed'
    )
  );
  const defaultIsExchangeAffiliated = useSelector((state: WEB.RootState) =>
    profile.getInfoValueByField(
      state,
      'investment_info',
      'is_exchange_affiliated'
    )
  );
  const defaultIsControlPerson = useSelector((state: WEB.RootState) =>
    profile.getInfoValueByField(state, 'investment_info', 'is_control_person')
  );
  const defaultUSCitizen = useSelector((state: WEB.RootState) =>
    profile.getInfoValueByField(state, 'investment_info', 'us_citizen')
  );

  const languageCtx = useSelector((state: WEB.RootState) =>
    language.getSignupPageLanguage(state, Pages.INVESTING_AFFILIATIONS)
  );

  const introCtx = getLanguageSection(languageCtx, 'intro');
  const buttonsCtx = getLanguageButtons(languageCtx);

  const [isCompleted, setIsCompleted] = React.useState<boolean>(false);
  const [affiliations, setAffiliations] =
    React.useState<InvestingAffiliationsType>({
      no_affiliations_apply: defaultNoAffiliationsApply || false,
      is_politically_exposed: defaultIsPoliticallyExposed || false,
      is_exchange_affiliated: defaultIsExchangeAffiliated || false,
      is_control_person: defaultIsControlPerson || false,
      // Invert answer. Question is "I am not a U.S. citizen".
      us_citizen:
        (typeof defaultUSCitizen === 'boolean' && !defaultUSCitizen) || false,
    });

  const isValid = () => {
    const selections = [];
    for (const option of introCtx?.list || []) {
      if (affiliations[option.id as string]) {
        selections.push(option.id as string);
      }
    }
    if (selections.includes('no_affiliations_apply')) {
      return selections.length === 1;
    }
    return selections.length > 0;
  };

  const handleCheckboxOnClick = (id: string) => {
    const newValue = !affiliations[id];
    if (id === 'no_affiliations_apply') {
      setAffiliations({
        ...NO_AFFILIATIONS,
        no_affiliations_apply: true,
      });
    } else {
      setAffiliations({
        ...affiliations,
        no_affiliations_apply: false,
        [id]: newValue,
      });
    }
  };

  const onSubmit = async () => {
    // Remove any error banner from previous attempts
    dispatch(removeBanner());
    if (!isValid()) {
      // This shouldn't be possible
      dispatch(
        addBanner(NotificationTypes.WARNING, ErrorMessages.web.generic, false)
      );
      Sentry.setContext('InvestingAffiliations', affiliations);
      logger.error('User submitted invalid investing affiliations');
      setAffiliations(NO_AFFILIATIONS);
      return { [FORM_ERROR]: 'error' };
    }

    const { error }: any = await dispatch(
      updateProfile({
        investment_info: {
          no_affiliations_apply: affiliations.no_affiliations_apply || false,
          is_politically_exposed: affiliations.is_politically_exposed || false,
          is_exchange_affiliated: affiliations.is_exchange_affiliated || false,
          is_control_person: affiliations.is_control_person || false,
          // Invert answer. Question is "I am not a U.S. citizen".
          us_citizen: !affiliations.us_citizen,
          saw_signup_investing_affiliations_screen: Date.now(),
        },
      })
    );
    if (error) return { [FORM_ERROR]: 'error' };
  };

  const submitCallback = async (errors?: unknown) => {
    if (!errors) {
      await delayWithState(400, setIsCompleted);
      dispatch(updateCurrentSignupPage({ page: props.nextPage }));
    }
  };

  return (
    <PageBase>
      <LeadContent
        header={introCtx.header || ''}
        text={introCtx?.text || ''}
        desktopHeaderSize='small'
      />
      <Spacer space={spacers.g4} />
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting }) => (
          <form onSubmit={(event) => handleSubmit(event)?.then(submitCallback)}>
            {introCtx?.list?.map((option) => (
              <Field name={option.id as string} key={option.id}>
                {({ input }) => (
                  <>
                    <Spacer space={spacers.g8} />
                    <StyledCheckbox
                      {...input}
                      id='cardholder-agreement-check'
                      check={affiliations[option.id as string]}
                      onClick={() => handleCheckboxOnClick(option.id as string)}
                      label={option.header}
                    />
                  </>
                )}
              </Field>
            ))}
            <ButtonsLayout
              marginTop={spacers.g9}
              primaryButton={
                <Button
                  disabled={!isValid() || submitting}
                  isLoading={submitting}
                  isCompleted={isCompleted}
                >
                  {isCompleted ? '' : buttonsCtx?.primary}
                </Button>
              }
            />
          </form>
        )}
      />
    </PageBase>
  );
};

export default InvestingAffiliations;
