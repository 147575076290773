import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import getAppData from 'actions/async/getAppData';
import getSignupLanguage from 'actions/async/getSignupLanguage';
import updateProfile from 'actions/async/updateProfile';
import addBanner from 'actions/banner/addBanner';
import removeBanner from 'actions/banner/removeBanner';
import types from 'actions/types';
import Button from 'components/common/Button';
import Spacer from 'components/common/Spacer';
import { FilledSquareCard } from 'components/common/SquareCard/SquareCardV2';
import Text from 'components/common/Text';
import Icon from 'components/icons/SetUp';
import ButtonsContainer from 'components/layout/ButtonsContainer';
import LeadContent from 'components/layout/LeadContent';
import PageBase from 'components/layout/PageBase';
import Pages from 'constants/Pages';
import SignupGoals, { SignupGoalsType } from 'constants/SignupGoals';
import { BannerActionType, NotificationTypes } from 'constants/index';
import useBrowserNavigationOverrideWithBanner from 'hooks/useBrowserNavigationBlockWithBanner';
import useNavigateFunction from 'hooks/useNavigateFunction';
import useVerifyCurrentPage from 'hooks/useVerifyCurrentPage';
import * as appData from 'reducers/entities/appData';
import * as language from 'reducers/entities/language';
import { breakpoints } from 'styles';
import { spacers } from 'styles/home';
import * as WEB from 'types/interfaces';
import { LanguageListItem } from 'types/interfaces';
import { getLanguageSection } from 'utils/getFromLanguage';

type Props = {
  nextPage: string;
};

const CARD_SIZE = '148px';

const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 12px;
  row-gap: 14px;
  justify-items: center;
  max-width: calc((${CARD_SIZE} * 3) + 24px);
  @media ${breakpoints.mobileLarge} {
    margin: 0 auto;
  }
  @media ${breakpoints.institutionGrid} {
    max-width: none;
    grid-template-columns: 1fr 1fr;
  }
`;

const CardContentContainer = styled.div<{
  $variant?: 'left' | 'centered';
}>`
  display: flex;
  ${({ $variant = 'left' }) =>
    $variant === 'centered'
      ? `justify-content: center;`
      : `justify-content: spece-between;`}
`;

const HeaderTextBase = styled(Text)`
  font-size: 16px;
  @media (max-width: 355px) {
    font-size: 14px;
  }
  @media (max-width: 320px) {
    font-size: 12px;
  }
`;

const HeaderTextSmall = styled(Text)`
  font-size: 13px;

  @media (min-width: 450px) {
    font-size: 14px;
  }
`;

const SetUpAlbert = (props: Props): React.ReactElement => {
  useVerifyCurrentPage(Pages.SET_UP_ALBERT);
  useBrowserNavigationOverrideWithBanner();

  const dispatch = useDispatch<WEB.Dispatch>();
  const { updateCurrentSignupPage } = useNavigateFunction();

  const signupGoalObjects: WEB.SignupGoalObject[] = useSelector(
    (state: WEB.RootState) =>
      appData.getValueByField(state, 'signup_goal_objects') || []
  );
  const setupGoals = signupGoalObjects.reduce<SignupGoals[]>((acc, obj) => {
    if (obj.type === SignupGoalsType.SETUP_GOALS) {
      acc.push(obj.name);
    }
    return acc;
  }, []);

  const languageCtx = useSelector((state: WEB.RootState) =>
    language.getSignupPageLanguage(state, Pages.SET_UP_ALBERT)
  );
  const setupLanguage = getLanguageSection(languageCtx, 'setup');
  const optionsLanguage = getLanguageSection(languageCtx, 'options');
  const goalOptions =
    (optionsLanguage?.list as (Omit<LanguageListItem, 'id'> & {
      id: SignupGoals;
    })[]) ?? [];

  const setUpAlbertV2 = useSelector((state: WEB.RootState) =>
    language.getSignupFlag(state, 'setUpAlbertV2')
  );

  const [selectedOptionIds, setSelectedOptionIds] = React.useState(
    setupGoals || []
  );
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const updateSelectedOptions = (id: SignupGoals) => {
    dispatch(removeBanner());
    if (selectedOptionIds?.includes(id)) {
      setSelectedOptionIds(
        selectedOptionIds.filter((optionId: string) => optionId !== id)
      );
    } else {
      setSelectedOptionIds(selectedOptionIds?.concat(id));
    }
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    if (!selectedOptionIds.length) {
      dispatch(
        addBanner(
          NotificationTypes.WARNING,
          setupLanguage?.errorMessage || '',
          false,
          BannerActionType.HIDE_ICON
        )
      );
      setIsSubmitting(false);
      return;
    }
    const { error } = await dispatch(
      updateProfile({
        signup_goals: selectedOptionIds,
        signup_goals_type: SignupGoalsType.SETUP_GOALS,
      })
    );

    // Refresh language in case the profile update changed the language version key
    await dispatch(getSignupLanguage());

    // Data is stored in appData even though update is made to profile, so we
    // need to refetch appData to keep the Redux store in sync with the backend
    await dispatch(getAppData());

    setIsSubmitting(false);
    if (!error) {
      // Flow may be updated depending on user's selection
      const signupGoalObjects = selectedOptionIds.map((option) => ({
        name: option,
        type: SignupGoalsType.SETUP_GOALS,
      }));
      dispatch({
        type: types.UPDATE_SIGNUP_LANGUAGE_ORDER,
        payload: {
          signupGoalObjects: signupGoalObjects,
        },
      });
      dispatch(updateCurrentSignupPage({ page: props.nextPage }));
    }
  };

  const HeaderText = setUpAlbertV2 ? HeaderTextSmall : HeaderTextBase;

  return (
    <PageBase>
      <LeadContent
        header={setupLanguage?.header || ''}
        text={setupLanguage.text}
        desktopHeaderSize='small'
      />
      <Spacer space={spacers.g6} />
      <CardsContainer>
        {goalOptions.map((option) => {
          return (
            <FilledSquareCard
              $variant={setUpAlbertV2 ? 'color-icon' : undefined}
              key={option.id}
              selected={selectedOptionIds?.includes(option.id)}
              onClick={() => updateSelectedOptions(option.id)}
              width='100%'
              height={CARD_SIZE}
            >
              <CardContentContainer
                $variant={setUpAlbertV2 ? 'centered' : undefined}
              >
                <Icon icon={option.icon || option.id || ''} />
              </CardContentContainer>
              <HeaderText
                weight='700'
                align={setUpAlbertV2 ? 'center' : undefined}
              >
                {option.header}
              </HeaderText>
            </FilledSquareCard>
          );
        })}
      </CardsContainer>
      <ButtonsContainer>
        <Button
          isLoading={isSubmitting}
          disabled={isSubmitting}
          onClick={handleSubmit}
        >
          Continue
        </Button>
      </ButtonsContainer>
    </PageBase>
  );
};

export default SetUpAlbert;
