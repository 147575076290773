import types from 'actions/types';
import AlbertClient from 'api/AlbertClient';
import * as WEB from 'types/interfaces';

export default (data: WEB.MFAAnswerSet): WEB.Action => ({
  types: [
    types.SUBMIT_KYC_MFA_REQUEST,
    types.SUBMIT_KYC_MFA_SUCCESS,
    types.SUBMIT_KYC_MFA_FAILURE,
  ],
  payload: {
    request: {
      method: 'post',
      url: AlbertClient.profileKYCMFAView(),
      data,
    },
  },
});
