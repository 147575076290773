import * as React from 'react';
import { useSelector } from 'react-redux';
import TopInfoButton from 'components/common/Button/TopInfoButton';
import MobileInfoModal from 'components/common/Modal/MobileInfoModal';
import PageBase from 'components/layout/PageBase';
import Pages from 'constants/Pages';
import useVerifyCurrentPage from 'hooks/useVerifyCurrentPage';
import SavingsBenefitsInfo from 'pages/signup/SavingsBenefitsInfo';
import * as language from 'reducers/entities/language';
import * as WEB from 'types/interfaces';
import { getLanguageInfo } from 'utils/getFromLanguage';

type Props = {
  nextPage: string;
};

const SavingsBenefitsSingleScreen = (props: Props) => {
  const [showInfoModal, setShowInfoModal] = React.useState<boolean>(false);
  // Make sure Redux is up-to-date with currentSignUpPage
  useVerifyCurrentPage(Pages.SAVINGS_BENEFITS_SINGLE_SCREEN);

  const languageCtx = useSelector((state: WEB.RootState) =>
    language.getSignupPageLanguage(state, Pages.SAVINGS_BENEFITS_SINGLE_SCREEN)
  );

  const infoCtx = getLanguageInfo(languageCtx);
  const expanders = infoCtx?.expanders || [];

  const handleInfoOnClick = () => {
    setShowInfoModal(true);
  };

  return (
    <PageBase>
      <TopInfoButton onClick={handleInfoOnClick} />
      <SavingsBenefitsInfo
        nextPage={props.nextPage}
        agreedToCoastalTerms={false}
      />
      {expanders && (
        <MobileInfoModal
          expanders={expanders}
          show={showInfoModal}
          onCancel={() => setShowInfoModal(false)}
        />
      )}
    </PageBase>
  );
};

export default SavingsBenefitsSingleScreen;
