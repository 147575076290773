import { transparentize } from 'polished';
import styled from 'styled-components';
import ZIndex from 'constants/ZIndex';
import mixins from 'styles/mixins';

const ModalBackdrop = styled.div`
  background-color: ${({ theme }) =>
    transparentize(0.2, theme.colors.primaryText)};
  ${mixins.fadeIn()}
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: ${ZIndex.MODAL_BACKDROP};
  /* required to make position: fixed work in mobile browsers */
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
`;

export default ModalBackdrop;
