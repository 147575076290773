import styled from 'styled-components';
import { textTransition } from 'components/common/Text';

export type Props = {
  color?: string;
};

export const IconContainer = styled.div`
  path {
    ${textTransition}
  }
`;
