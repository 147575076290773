import types from 'actions/types';
import AlbertClient from 'api/AlbertClient';
import * as WEB from 'types/interfaces';

export default (): WEB.Action => ({
  types: [
    types.GET_GENIUS_LANGUAGE_REQUEST,
    types.GET_GENIUS_LANGUAGE_SUCCESS,
    types.GET_GENIUS_LANGUAGE_FAILURE,
  ],
  payload: {
    request: {
      method: 'put',
      url: AlbertClient.geniusOnboardingView(),
    },
  },
});
