/* eslint-disable no-param-reassign */

/* eslint-disable @typescript-eslint/no-explicit-any */
import produce from 'immer';
import initialState from 'store.initialState';
import types from 'actions/types';
import { BannerActionType, NotificationTypes } from 'constants/';
import * as WEB from 'types/interfaces';

// /////////////////////////////////////////////////////////////////////////////
// selectors
// /////////////////////////////////////////////////////////////////////////////

export const getBanner = (state: WEB.RootState): WEB.Banner | null => {
  let { isHidden, ...banner } = state.entities.banner || {};
  return banner.message && banner.type ? banner : null;
};

export const getIsHidden = (state: WEB.RootState): boolean => {
  return state.entities.banner.isHidden;
};

export const hasMessage = (state: WEB.RootState): boolean => {
  return !!state.entities.banner.message;
};
export const getBannerAction = (
  state: WEB.RootState
): BannerActionType | null | undefined => state.entities.banner.bannerAction;

// /////////////////////////////////////////////////////////////////////////////
// reducers
// /////////////////////////////////////////////////////////////////////////////

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const banner = (state = initialState.entities.banner, action: WEB.Action) => {
  const { type, payload, error } = action;
  return produce(state, (draft: WEB.RootState['entities']['banner']) => {
    switch (type) {
      case types.ADD_BANNER: {
        draft.type = payload?.type || NotificationTypes.SUCCESS;
        draft.message = payload?.message || '';
        draft.bannerAction = payload?.bannerAction;
        return;
      }

      case types.HIDE_BANNER: {
        draft.isHidden = payload?.hide;
        return;
      }

      case types.REMOVE_BANNER:
      case types.LOGOUT_SUCCESS: {
        draft.type = '';
        draft.message = '';
      }

      default:
    }
  });
};

export default banner;
