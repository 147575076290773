import * as React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import Button from 'components/common/Button';
import Spacer from 'components/common/Spacer';
import Text from 'components/common/Text';
import ButtonsContainer from 'components/layout/ButtonsContainer';
import PageBase from 'components/layout/PageBase';
import useNavigateFunction from 'hooks/useNavigateFunction';
import { breakpoints, spacers } from 'styles';

type Props = {
  /* next page to navigate to when continue is clicked */
  nextPage: string;
  /* alternative to "nextPage". Triggered when continue is clicked */
  onSubmit?: () => void;
};

const OnlyShowOnDesktop = styled.div`
  @media ${breakpoints.mobileLarge} {
    display: none;
  }
`;

const ManualAuthInitiated = (props: Props): React.ReactElement => {
  const dispatch = useDispatch();
  const { updateCurrentSignupPage } = useNavigateFunction();

  const handleOnContinue = () => {
    if (props.onSubmit) props.onSubmit();
    else if (props.nextPage) {
      dispatch(updateCurrentSignupPage({ page: props.nextPage }));
    }
  };

  return (
    <PageBase
      mobileHeader='Account verification initiated'
      mobileLead={[
        'Albert will make two microdeposits into your bank, then withdraw the total amount deposited. This process can take a few business days.',
        'Once these transactions post to your bank, confirm and verify your account in the Albert app.',
      ]}
    >
      <OnlyShowOnDesktop>
        <h2>Account verification initiated</h2>
        <Spacer space={spacers.tiny} />
        <Text className='description'>
          Albert savings will make two microdeposits into your bank, then
          withdraw the total amount deposited. This process can take a few
          business days.
        </Text>
        <Spacer space={spacers.small} />
        <Text className='description'>
          Once these transactions post to your bank, confirm and verify your
          account in the Albert app.
        </Text>
      </OnlyShowOnDesktop>
      <ButtonsContainer>
        <Button onClick={handleOnContinue}>Continue</Button>
      </ButtonsContainer>
    </PageBase>
  );
};

export default ManualAuthInitiated;
