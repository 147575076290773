import * as React from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Button from 'components/common/Button';
import TopInfoButton from 'components/common/Button/TopInfoButton';
import MobileInfoModal from 'components/common/Modal/MobileInfoModal';
import Spacer from 'components/common/Spacer';
import Text, { TextSizes } from 'components/common/Text';
import { SavingsBonusLottie } from 'components/common/animations';
import Checkmark from 'components/icons/Checkmark';
import ButtonsContainer from 'components/layout/ButtonsContainer';
import InfoPane from 'components/layout/InfoPane';
import LeadContent from 'components/layout/LeadContent';
import PageBase from 'components/layout/PageBase';
import Pages, { PageType } from 'constants/Pages';
import PaymentFrequency from 'constants/PaymentFrequency';
import useNavigateFunction from 'hooks/useNavigateFunction';
import useVerifyCurrentPage from 'hooks/useVerifyCurrentPage';
import AnnualMonthlyOld from 'pages/genius/AnnualMonthlyOld';
import * as language from 'reducers/entities/language';
import * as genius from 'reducers/ui/genius';
import { breakpoints } from 'styles';
import { spacers } from 'styles/home';
import theme from 'styles/theme';
import * as WEB from 'types/interfaces';
import {
  getLanguageButtons,
  getLanguageInfo,
  getLanguageSection,
} from 'utils/getFromLanguage';
import renderTemplateContext from 'utils/renderTemplateContext';

type Props = {
  nextPage: string;
  /* are we in signup flow */
  fromSignup?: boolean;
};

const LottieContainer = styled.div<{ showMobileUpdate: boolean }>`
  padding: ${({ showMobileUpdate }) => (showMobileUpdate ? '30px' : '64px')}
    104px;
  background-color: ${({ theme }) => theme.colors.lightGray3};
  text-align: center;
  border-radius: 8px;
`;

const Subheader = styled.h2`
  margin: 0;
`;

const SubheaderLabelContainer = styled.div`
  display: flex;
`;

const Label = styled.div`
  // Horizontally align this label with the subheader text
  margin-top: 2px;
  margin-left: auto;
  font-size: 13px;
  text-transform: uppercase;
  align-items: center;
  text-align: center;
  letter-spacing: 1px;
  font-weight: 700;
  color: white;
  background-color: ${({ theme }) => theme.colors.primaryAlbertBrand};
  border-radius: 4px;
  padding: 4px 8px;
  white-space: nowrap;
  height: fit-content;
  @media ${breakpoints.mobileLarge} {
    font-size: 10px;
  }
`;

const ItemContainer = styled.div`
  display: flex;
`;

const CheckmarkWrapper = styled.div`
  // Horizontally align with text
  margin-top: 4px;
  flex-shrink: 0;
  margin-right: 12px;
`;

const ItemText = styled(Text)`
  font-size: 0.875rem;
`;

const GeniusAnnualMonthly = ({
  nextPage,
  fromSignup = false,
}: Props): React.ReactElement => {
  const [showInfoModal, setShowInfoModal] = React.useState<boolean>(false);
  const { updateCurrentGeniusPage } = useNavigateFunction();
  // Make Redux is up-to-date with currentSignUpPage
  useVerifyCurrentPage(Pages.GENIUS_ANNUAL_MONTHLY, PageType.GENIUS);
  const dispatch = useDispatch();
  const selectedPrice = useSelector(genius.getSelectedGeniusPrice);
  const geniusPayment = useSelector((state: WEB.RootState) =>
    genius.getSelectedGeniusFrequency(state)
  );
  const annualBonus = useSelector(genius.getAnnualBonus);
  const languageCtx = useSelector((state: WEB.RootState) => {
    return language.getGeniusPageLanguage(state, Pages.GENIUS_ANNUAL_MONTHLY);
  });
  const hasMobileUpdate = useSelector((state: WEB.RootState) => {
    return language.getSignupFlag(state, 'hasGeniusMobileUpdate');
  });
  const showMobileUpdate = hasMobileUpdate && isMobile;

  const list = languageCtx?.content?.details?.list as
    | { text: string }[]
    | undefined;
  if (!list) {
    return <AnnualMonthlyOld nextPage={nextPage} fromSignup={fromSignup} />;
  }

  // context for templating
  const pageContext = {
    selectedPrice,
    bonus: annualBonus,
  };

  const details = getLanguageSection(languageCtx, 'details');

  const infoPaneContext = getLanguageInfo(languageCtx);
  const buttonsCtx = getLanguageButtons(languageCtx);

  const handleContinueClick = () => {
    dispatch(updateCurrentGeniusPage({ page: nextPage, fromSignup }));
  };
  const handleInfoOnClick = () => {
    setShowInfoModal(true);
  };

  return (
    <>
      {showMobileUpdate && <TopInfoButton onClick={handleInfoOnClick} />}
      <PageBase>
        <LeadContent header={details?.header || ''} />
        <Spacer space={spacers.g8} />
        <LottieContainer showMobileUpdate={showMobileUpdate}>
          <SavingsBonusLottie
            amount={annualBonus}
            headerImageSrc={details?.textImage}
            gray={geniusPayment !== PaymentFrequency.ANNUAL}
            width={300}
            height={100}
            textSize={TextSizes.SMALL}
            caption={annualBonus ? 'CASH BONUS' : undefined}
          />
        </LottieContainer>
        <Spacer space={spacers.g8} />
        <SubheaderLabelContainer>
          <Subheader>{details?.subheader}</Subheader>
          {details?.labelText && <Label>{details.labelText}</Label>}
        </SubheaderLabelContainer>
        <Spacer space={spacers.g2} />
        <Text weight='600'>
          {renderTemplateContext(details?.text || '', pageContext)}
        </Text>
        <Spacer space={spacers.g8} />
        {list.map((item, i) => (
          <React.Fragment key={item.text}>
            <ItemContainer>
              <CheckmarkWrapper>
                <Checkmark />
              </CheckmarkWrapper>
              <ItemText>{item.text}</ItemText>
            </ItemContainer>
            {i < list.length - 1 && <Spacer space={spacers.g5} />}
          </React.Fragment>
        ))}
        {!showMobileUpdate && (
          <>
            <Spacer space={spacers.g5} />
            <InfoPane.Mobile expanders={infoPaneContext.expanders} />
            <Spacer space={spacers.g7} />
          </>
        )}
        {showMobileUpdate && <Spacer space={spacers.g10} />}
        <ButtonsContainer>
          <Button
            id='genius-annual-monthly-confirm'
            onClick={handleContinueClick}
            theme={theme.genius}
          >
            {buttonsCtx?.primary}
          </Button>
        </ButtonsContainer>
      </PageBase>
      {showMobileUpdate && infoPaneContext?.expanders && (
        <MobileInfoModal
          expanders={infoPaneContext?.expanders}
          show={showInfoModal}
          onCancel={() => setShowInfoModal(false)}
        />
      )}
    </>
  );
};

export default GeniusAnnualMonthly;
