export enum TransactionType {
  CASH = 'cash',
  SAVINGS = 'savings',
  INVESTING = 'investing',
  TRANSFER_DETAILS = 'transfer_details',
  ACH_INTERNAL = 'ai',
  ACH_EXTERNAL = 'ae',
}

export enum TransactionSubType {
  ANNUAL_GIFT = 'ag',
  ATM = 'a',
  CHECK_SENT = 'cs',
  CHECK_DEPOSIT = 'cd',
  DD_BONUS = 'dd',
  DD_BONUS_100_SPEND = 'dds',
  DD_REFERRED_BONUS = 'ddrd',
  DD_REFERRING_BONUS = 'ddrg',
  INSTANT = 'ip',
  INVESTING = 'iv',
  MERCHANT_CREDIT = 'c',
  PURCHASE = 'p',
  REWARD = 'r',
  SAVINGS = 'ms',
  SUBSCRIPTION = 'sf',
  TRANSFER = 'tr',
  SECOND_PRESENTMENT = 'sp',
  CHARGEBACK = 'cb',
  ARBITRATION_CHARGEBACK = 'ac',
}

export const TransactionSubTypeValues = {
  [TransactionSubType.ANNUAL_GIFT]: 'Annual gift',
  [TransactionSubType.ATM]: 'ATM',
  [TransactionSubType.CHECK_SENT]: 'Check Sent',
  [TransactionSubType.CHECK_DEPOSIT]: 'Check Deposit',
  [TransactionSubType.DD_BONUS]: 'Direct Deposit Bonus',
  [TransactionSubType.DD_BONUS_100_SPEND]: 'Direct Deposit 100 Spend Bonus',
  [TransactionSubType.DD_REFERRED_BONUS]: 'Direct Deposit Referred Bonus',
  [TransactionSubType.DD_REFERRING_BONUS]: 'Direct Deposit Referring Bonus',
  [TransactionSubType.INSTANT]: 'Instant',
  [TransactionSubType.INVESTING]: 'Investing',
  [TransactionSubType.MERCHANT_CREDIT]: 'Merchant Credit',
  [TransactionSubType.PURCHASE]: 'Purchase',
  [TransactionSubType.REWARD]: 'Reward',
  [TransactionSubType.SAVINGS]: 'Savings',
  [TransactionSubType.SUBSCRIPTION]: 'Subscription',
  [TransactionSubType.TRANSFER]: 'Transfer',
  [TransactionSubType.SECOND_PRESENTMENT]: 'Second Presentment',
  [TransactionSubType.CHARGEBACK]: 'Chargeback',
  [TransactionSubType.ARBITRATION_CHARGEBACK]: 'Arbitration Chargeback',
  '': '',
};

export default TransactionType;
