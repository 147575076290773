import { InfoCircle } from '@styled-icons/boxicons-regular/InfoCircle';
import * as React from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import TrackingBase from 'tracking.Base';
import enableGenius from 'actions/async/enableGenius';
import getAppData from 'actions/async/getAppData';
import updateGeniusPricing from 'actions/async/updateGeniusPricing';
import updateProfile from 'actions/async/updateProfile';
import addBanner from 'actions/banner/addBanner';
import removeBanner from 'actions/banner/removeBanner';
import Button from 'components/common/Button';
import Divider from 'components/common/Divider';
import ModalExpanderContent from 'components/common/Modal/ModalExpanderContent';
import Spacer from 'components/common/Spacer';
import Text, { TextColor, TextSizes } from 'components/common/Text';
import InfoPaneExpanderGroup from 'components/layout/InfoPaneExpanderGroup';
import PageBase from 'components/layout/PageBase';
import PageRoutes from 'constants/PageRoutes';
import { PageType } from 'constants/Pages';
import {
  AlbertTrackingEvent,
  NotificationTypes,
  Pages,
  PaymentFrequency,
} from 'constants/index';
import useBrowserNavigationOverrideWithRedirect from 'hooks/useBrowserNavigationOverrideWithRedirect';
import useNavigateFunction from 'hooks/useNavigateFunction';
import useVerifyCurrentPage from 'hooks/useVerifyCurrentPage';
import * as appData from 'reducers/entities/appData';
import * as language from 'reducers/entities/language';
import * as profileSelector from 'reducers/entities/profile';
import * as profile from 'reducers/entities/profile';
import * as genius from 'reducers/ui/genius';
import { breakpoints, spacers } from 'styles';
import { spacers as homeSpacers } from 'styles/home';
import * as WEB from 'types/interfaces';
import { GeniusTieredPricing } from 'types/interfaces';
import { delayWithState } from 'utils/delay';
import {
  getLanguageButtons,
  getLanguageInfo,
  getLanguageSection,
} from 'utils/getFromLanguage';
import renderTemplateContext from 'utils/renderTemplateContext';

type Props = {
  nextPage: string;
  fromSignup?: boolean;
};

const StyledTableRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DesktopHeader = styled.h1`
  @media ${breakpoints.mobileLarge} {
    display: none;
  }
`;

const StyledDisclaimer = styled(Text)<{ showMobileUpdate: boolean }>`
  font-size: 14px;
  @media ${breakpoints.mobileLarge} {
    font-weight: 600;
  }
  ${({ showMobileUpdate }) =>
    showMobileUpdate &&
    `
    text-align: center;
    padding-top: ${homeSpacers.g11};
  `}
`;

const StyledInfoCircle = styled(InfoCircle)`
  width: 14px;
  height: 14px;
  display: inline-block;
  cursor: pointer;
  vertical-align: -2px;
  color: ${({ theme }) => theme.colors.primaryGray};
`;

const StyledDivider = styled(Divider)<{ showMobileUpdate: boolean }>`
  ${({ showMobileUpdate }) => showMobileUpdate && 'margin: 30px 0 !important;'}
`;

const ButtonsContainer = styled.div<{ showMobileUpdate: boolean }>`
  display: flex;
  margin-top: ${({ showMobileUpdate }) =>
    showMobileUpdate ? homeSpacers.g4 : homeSpacers.g9};
  align-items: center;
  button {
    width: 100%;
  }
`;

const GeniusConfirm = (props: Props): React.ReactElement => {
  const { fromSignup = false } = props;
  const hasMobileUpdate = useSelector((state: WEB.RootState) => {
    return language.getSignupFlag(state, 'hasGeniusMobileUpdate');
  });
  const showMobileUpdate = hasMobileUpdate && isMobile;
  // Make Redux is up-to-date with currentSignUpPage
  useVerifyCurrentPage(Pages.GENIUS_CONFIRM, PageType.GENIUS);
  // ///////////////////////////////
  /* =========== HOOKS ========== */
  // ///////////////////////////////
  const { updateCurrentGeniusPage } = useNavigateFunction();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // ///////////////////////////////
  /* ======== MAPPED STATE ====== */
  // ///////////////////////////////
  const [primaryChecking] = useSelector(appData.getPrimaryCheckingAccounts);

  const highestBalance = primaryChecking?.balance || 0;
  const geniusLanguage = useSelector(language.getGeniusLanguage);
  const languageCtx = useSelector((state: WEB.RootState) => {
    return language.getGeniusPageLanguage(state, Pages.GENIUS_CONFIRM);
  });
  const geniusPayment = useSelector((state: WEB.RootState) =>
    genius.getSelectedGeniusFrequency(state)
  );
  const selectedPrice = useSelector(genius.getSelectedGeniusPrice);
  const numMonthsFree = geniusLanguage?.pricing?.numberMonthsFree;
  let defaultGeniusPrice = geniusLanguage?.pricing?.default;
  const annualBonus = useSelector(genius.getAnnualBonus);
  const didDownloadApp = useSelector(profileSelector.getDidDownloadApp);
  const hasFreeMonth = useSelector(profile.getHasFreeMonthGeniusOffer);
  const showTierPricing = useSelector((state: WEB.RootState) =>
    profile.getValueByField(state, 'has_tiered_genius_pricing')
  );
  const tieredPricing: GeniusTieredPricing = useSelector(
    (state: WEB.RootState) => profile.getValueByField(state, 'tiered_pricing')
  );
  const defaultTierPricing = useSelector(profile.getDefaultTierPrice);

  defaultGeniusPrice =
    (showTierPricing && defaultTierPricing) || defaultGeniusPrice;

  // ///////////////////////////////
  /* ======== LOCAL STATE ======= */
  // ///////////////////////////////
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [isCompleted, setIsCompleted] = React.useState<boolean>(false);
  const [showInfoModal, setShowInfoModal] = React.useState<boolean>(false);

  // //////////////////////////////////
  /* =========== HANDLERS ========== */
  // //////////////////////////////////
  const handleInfoOnClick = () => {
    setShowInfoModal(true);
  };
  const handleInfoOnHide = () => {
    setShowInfoModal(false);
  };
  const handleOnContinue = async () => {
    // Remove any error banner from previous attempts
    dispatch(removeBanner());

    try {
      setIsSubmitting(true);

      const geniusError: any = await dispatch(
        enableGenius({
          genius_enabled: true,
          number_months_free: numMonthsFree,
          default_price: defaultGeniusPrice,
          monthly_price: selectedPrice,
          completed_onboarding: true,
          payment_frequency: geniusPayment,
          agreed_to_receipt: true,
          tier_key: showTierPricing ? tieredPricing.default_tier : undefined,
        })
      );

      // Handle the enable genius request response
      if (geniusError) throw new Error();

      // Update genius pricing if annual bonus present
      const isAnnualSubscription = geniusPayment === PaymentFrequency.ANNUAL;
      const pricingPromise: any = isAnnualSubscription
        ? dispatch(
            updateGeniusPricing({
              savings_bonus: annualBonus,
            })
          )
        : Promise.resolve();
      const pricingResult = await pricingPromise;
      const profilePromise: any = dispatch(
        updateProfile({
          completed_genius_signup: true,
        })
      );
      const profileResult = await profilePromise;
      /* we are intentionally calling these sequentially and not using promise.all */
      const results = [pricingResult, profileResult];

      setIsSubmitting(false);

      if (highestBalance > 100) {
        TrackingBase.track({
          event:
            AlbertTrackingEvent.ENABLED_GENIUS_PRIMARY_CHECKING_GREATER_THAN_100,
          purchaseAmount: selectedPrice,
          trackSocial: true,
          trackOnce: true,
        });
      }

      // Handle the genius pricing request response if sent
      if (isAnnualSubscription) {
        const pricingError = results[0];

        if (pricingError) throw new Error();

        TrackingBase.track({
          event: AlbertTrackingEvent.ENABLED_GENIUS_ANNUAL,
          purchaseAmount: selectedPrice,
          trackSocial: true,
          trackOnce: true,
        });
      }

      // Handle the update profile request response
      const { response: updateProfileResponse, error } = results[1];
      if (error) throw new Error();

      // Load app data
      dispatch(getAppData());

      const profile = (updateProfileResponse.payload?.data ||
        {}) as WEB.Profile;
      const pricingData = profile?.pricing_data;

      // Notify user if any error while saving annual bonus
      if (
        isAnnualSubscription &&
        !!pricingData?.error_enabling_annual_payment
      ) {
        dispatch(
          addBanner(
            NotificationTypes.WARNING,
            'There was an error setting up your yearly plan. Please text Genius.'
          )
        );
      } else {
        await delayWithState(400, setIsCompleted);

        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        const redirect = window.decodeURIComponent(params.r);
        let shouldBypassNav = false;
        if (params.r && redirect !== '') {
          const url = new URL(redirect);
          const splashAppURL = new URL(window.albertWeb.SplashAppDomain);
          const signupAppURL = new URL(window.albertWeb.SignupAppDomain);

          if (
            url.host === window.location.host ||
            url.host === splashAppURL.host ||
            url.host === signupAppURL.host
          ) {
            /* the querystring is valid and on an albert domain */
            shouldBypassNav = true;
          }
        }

        if (shouldBypassNav && didDownloadApp) {
          /* Note, we use `replace` here because we may change domains */
          window.location.href = redirect;
        } else if (shouldBypassNav && !didDownloadApp) {
          navigate(PageRoutes.DOWNLOAD_APP, { replace: true });
        } else {
          dispatch(
            updateCurrentGeniusPage({
              page: props.nextPage,
              fromSignup,
            })
          );
        }
      }
    } catch (e) {
      setIsSubmitting(false);
    }
  };

  const handleBrowserButton = () => {
    dispatch(
      updateCurrentGeniusPage({
        page: Pages.GENIUS_ANNUAL_MONTHLY,
        fromSignup,
      })
    );
  };

  // //////////////////////////////////
  /* =========== LANGUAGE ========== */
  // //////////////////////////////////
  const INTRO_SECTION = 'intro';
  const MONTHLY_SECTION = 'monthly';
  const YEARLY_SECTION = 'yearly';

  // Sections
  const introSection = getLanguageSection(languageCtx, INTRO_SECTION);
  const yearlySection = getLanguageSection(languageCtx, YEARLY_SECTION);
  const monthlySection = getLanguageSection(languageCtx, MONTHLY_SECTION);

  const infoPaneContent = getLanguageInfo(languageCtx);

  // Buttons
  const buttonsCtx = getLanguageButtons(languageCtx);

  useBrowserNavigationOverrideWithRedirect(handleBrowserButton);

  const yearlyPlanTable = (
    <>
      <StyledTableRow>
        <Text>Your plan</Text>
        <Text id='your-plan'>
          ${selectedPrice}
          /mo. paid yearly
        </Text>
      </StyledTableRow>
      {!showTierPricing && (
        <>
          <Spacer space={spacers.tabSmall} />
          <StyledTableRow>
            <Text>Yearly charge</Text>
            <Text id='yearly-charge'>${selectedPrice * 12}</Text>
          </StyledTableRow>
        </>
      )}
      {hasFreeMonth && (
        <>
          <Spacer space={spacers.tabSmall} />
          <StyledTableRow>
            <Text>1 month free</Text>
            <Text id='month-free-amount'>
              -$
              {selectedPrice}
            </Text>
          </StyledTableRow>
        </>
      )}
      <StyledDivider showMobileUpdate={showMobileUpdate} />
      {!showMobileUpdate && <Spacer space={homeSpacers.g8} mobileOnly />}
      <StyledTableRow>
        <div>
          <Text weight='700' inline>
            Total due {languageCtx.info.dueDate || 'Today'}
          </Text>
        </div>
        <Text id='total-due-today' weight='700'>
          ${selectedPrice * 12 - (hasFreeMonth ? selectedPrice : 0)}
        </Text>
      </StyledTableRow>
      <Spacer space={spacers.tiny} />
      <StyledTableRow>
        <Text color={TextColor.GRAY} size={TextSizes.SMALL}>
          {hasFreeMonth ? '11' : '12'} months x ${selectedPrice}
        </Text>
      </StyledTableRow>
      {!annualBonus && languageCtx.info.dueDate && (
        <>
          <Spacer space={homeSpacers.g6} />
          <StyledTableRow>
            <div>
              <Text weight='700' inline>
                Total due today
              </Text>
            </div>
            <Text weight='700'>$0</Text>
          </StyledTableRow>
          <Spacer space={homeSpacers.g11} desktopOnly />
        </>
      )}
      {!!annualBonus && (
        <>
          <Spacer space={spacers.tabSmall} />
          <StyledTableRow>
            <Text color={TextColor.GOLD} weight='700'>
              Bonus
            </Text>
            <Text id='annual-bonus' color={TextColor.GOLD} weight='700'>
              ${annualBonus}
            </Text>
          </StyledTableRow>
        </>
      )}
    </>
  );

  const date = new Date(); // Now
  date.setDate(date.getDate() + 30);
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const month = monthNames[date.getMonth()];
  const day = String(date.getDate()).padStart(2, '0');

  const monthlyPlanTable = (
    <>
      <StyledTableRow>
        <Text>Your rate</Text>
        <Text>
          ${selectedPrice}
          /mo.
        </Text>
      </StyledTableRow>
      <Spacer space={showMobileUpdate ? spacers.tabSmall : spacers.tab} />
      <StyledTableRow>
        <Text>Your plan</Text>
        <Text>Monthly</Text>
      </StyledTableRow>
      <Divider />
      {showMobileUpdate && <Spacer space={homeSpacers.g8} mobileOnly />}
      <StyledTableRow>
        <Text weight='700'>
          Due {month} {day}
        </Text>
        <Text weight='700'>${selectedPrice}</Text>
      </StyledTableRow>
      <Spacer space={showMobileUpdate ? spacers.tabSmall : spacers.tab} />
      <StyledTableRow>
        <Text>Due today</Text>
        <Text>$0</Text>
      </StyledTableRow>
    </>
  );

  const isAnnual = geniusPayment === PaymentFrequency.ANNUAL;
  const mainHeader = isAnnual
    ? renderTemplateContext(yearlySection.header, { annualBonus })
    : monthlySection?.header;

  return (
    <>
      {showMobileUpdate && (
        <>
          <ModalExpanderContent
            expanders={infoPaneContent?.expanders}
            showModal={showInfoModal}
            setHideModal={handleInfoOnHide}
          />
        </>
      )}
      <PageBase
        mobileHeader={introSection?.header}
        isGenius
        buttonSpace='small'
      >
        <DesktopHeader>{introSection?.header}</DesktopHeader>
        <Text className='description'>{mainHeader}</Text>
        <Spacer
          space={showMobileUpdate ? spacers.tabSmall : spacers.tabLarge}
        />
        {isAnnual ? yearlyPlanTable : monthlyPlanTable}
        {!showMobileUpdate && (
          <InfoPaneExpanderGroup
            key='genius-confirm-info'
            expanders={infoPaneContent.expanders}
            mobileOnly
            addExpanderSubheadSpacing={false}
          />
        )}
        <StyledDisclaimer
          color={TextColor.GRAY}
          showMobileUpdate={showMobileUpdate}
          onClick={(showMobileUpdate && handleInfoOnClick) || undefined}
        >
          {isAnnual ? yearlySection?.text : monthlySection?.text}
          {showMobileUpdate && (
            <>
              {' '}
              <StyledInfoCircle />
            </>
          )}
        </StyledDisclaimer>
        <ButtonsContainer showMobileUpdate={showMobileUpdate}>
          <Button
            id='genius-confirm'
            isCompleted={isCompleted}
            isLoading={isSubmitting}
            disabled={isSubmitting}
            onClick={handleOnContinue}
          >
            {!isCompleted ? buttonsCtx?.primary : buttonsCtx?.complete}
          </Button>
        </ButtonsContainer>
      </PageBase>
    </>
  );
};

export default GeniusConfirm;
