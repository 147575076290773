import { FORM_ERROR } from 'final-form';
import { capitalize } from 'lodash';
import * as React from 'react';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import updateProfile from 'actions/async/updateProfile';
import removeBanner from 'actions/banner/removeBanner';
import Button from 'components/common/Button';
import Dropdown from 'components/common/Dropdown';
import { DropdownWidthSize } from 'components/common/Dropdown/Dropdown';
import Input from 'components/common/Input';
import { InputWidth } from 'components/common/Input/shared';
import Spacer from 'components/common/Spacer';
import ButtonsContainer from 'components/layout/ButtonsContainer';
import PageBase from 'components/layout/PageBase';
import Pages from 'constants/Pages';
import useNavigateFunction from 'hooks/useNavigateFunction';
import useVerifyCurrentPage from 'hooks/useVerifyCurrentPage';
import * as language from 'reducers/entities/language';
import * as profile from 'reducers/entities/profile';
import { spacers } from 'styles';
import * as WEB from 'types/interfaces';
import { DropdownItem } from 'types/interfaces';
import { delayWithState } from 'utils/delay';
import {
  getLanguageButtons,
  getLanguageInfo,
  getLanguageSection,
} from 'utils/getFromLanguage';
import { composeValidators, isRequired, minValue } from 'utils/validators';

type Props = {
  nextPage: string;
};

type Option = {
  display: string;
  value: string;
};

const HiddenInput = styled.input`
  display: none;
`;

// Helper function that returns two things:
// 1. A section's list of options formatted for the Dropdown input
// 2. The value that the dropdown should default to
const formatDropdownOptions = (
  options: Option[],
  defaultValue: string | null
): readonly [DropdownItem[], string] => {
  let defaultDisplay = '';
  const formattedOptions: DropdownItem[] = options.map(({ value, display }) => {
    // Some values from the backend are all lowercase and cannot be changed, so we need to capitalize them here
    const formattedDisplay = display ? capitalize(display) : '';
    if (value === defaultValue) {
      defaultDisplay = formattedDisplay;
    }
    return { value: formattedDisplay };
  });
  return [formattedOptions, defaultDisplay] as const;
};

const EmploymentStatus = (props: Props): React.ReactElement => {
  const { updateCurrentSignupPage } = useNavigateFunction();
  // Make Redux is up-to-date with currentSignUpPage
  useVerifyCurrentPage(Pages.EMPLOYMENT_STATUS);

  // ///////////////////////////////
  /* =========== HOOKS ========== */
  // ///////////////////////////////
  const dispatch = useDispatch();

  // ///////////////////////////////
  /* ======== MAPPED STATE ====== */
  // ///////////////////////////////
  const languageCtx = useSelector((state: WEB.RootState) =>
    language.getSignupPageLanguage(state, Pages.EMPLOYMENT_STATUS)
  );

  const occupationDefault = useSelector(
    (state: WEB.RootState) =>
      profile.getValueByField(state, 'occupation') as string | null
  );
  const paymentTypeDefault = useSelector(
    (state: WEB.RootState) =>
      profile.getValueByField(state, 'income_type') as string | null
  );
  const incomeSourceDefault = useSelector(
    (state: WEB.RootState) =>
      profile.getValueByField(state, 'income_source') as string | null
  );
  const annualIncomeDefault = useSelector(
    (state: WEB.RootState) =>
      profile.getValueByField(state, 'income') as number | null
  );

  // ///////////////////////////////
  /* =========== STATE ========== */
  // ///////////////////////////////
  const [isCompleted, setIsCompleted] = React.useState<boolean>(false);
  const [occupation, setOccupation] = React.useState<string | null>(null);
  const [paymentType, setPaymentType] = React.useState<string | null>(null);
  const [incomeSource, setIncomeSource] = React.useState<string | null>(null);

  const [showIncomeField] = React.useState<boolean>(
    annualIncomeDefault === null
  );
  const [hasSubmitFailed, setHasSubmitFailed] = React.useState(false);

  React.useEffect(() => {
    setOccupation(occupationDefault);
  }, [occupationDefault]);
  React.useEffect(() => {
    setPaymentType(paymentTypeDefault);
  }, [paymentTypeDefault]);
  React.useEffect(() => {
    setIncomeSource(incomeSourceDefault);
  }, [incomeSourceDefault]);

  // //////////////////////////////////
  /* =========== LANGUAGE ========== */
  // //////////////////////////////////

  // Section keys
  const OCCUPATION_KEY = 'occupation';
  const PAYMENT_TYPE_KEY = 'paymentType';
  const INCOME_SOURCE_KEY = 'incomeSource';
  const ANNUAL_INCOME_KEY = 'annualIncome';

  // Sections
  const occupationSection = getLanguageSection(languageCtx, OCCUPATION_KEY);
  const occupationOptions = (occupationSection?.options || []) as {
    display: string;
    value: string;
  }[];
  const paymentTypeSection = getLanguageSection(languageCtx, PAYMENT_TYPE_KEY);
  const paymentTypeOptions = (paymentTypeSection?.options || []) as {
    display: string;
    value: string;
  }[];
  const incomeSourceSection = getLanguageSection(
    languageCtx,
    INCOME_SOURCE_KEY
  );
  const incomeSourceOptions = (incomeSourceSection?.options || []) as {
    display: string;
    value: string;
  }[];
  const annualIncomeSection = getLanguageSection(
    languageCtx,
    ANNUAL_INCOME_KEY
  );

  const infoPaneContext = getLanguageInfo(languageCtx);

  // Buttons
  const buttonsCtx = getLanguageButtons(languageCtx);

  // Formatted data for dropdowns
  const [occupationItems, occupationDefaultDisplay] = formatDropdownOptions(
    occupationOptions,
    occupationDefault
  );
  const [paymentTypeItems, paymentTypeDefaultDisplay] = formatDropdownOptions(
    paymentTypeOptions,
    paymentTypeDefault
  );
  const [incomeSourceItems, incomeSourceDefaultDisplay] = formatDropdownOptions(
    incomeSourceOptions,
    incomeSourceDefault
  );

  // //////////////////////////////////
  /* =========== HANDLERS ========== */
  // //////////////////////////////////
  const handleOnNext = async (values: Record<string, number>) => {
    // Remove any error banner from previous attempts
    dispatch(removeBanner());

    const { income } = values;

    const formErrors: {
      occupation?: string;
      paymentType?: string;
      incomeSource?: string;
    } = {};
    if (occupation == null) {
      formErrors.occupation = 'error';
    }
    if (paymentType == null) {
      formErrors.paymentType = 'error';
    }
    if (incomeSource == null) {
      formErrors.incomeSource = 'error';
    }
    if (Object.keys(formErrors).length > 0) {
      // Tell react-final-form to block navigation due to errors caused by other forms
      return formErrors;
    }

    const { error }: any = await dispatch(
      updateProfile({
        income: income || 0,
        occupation: occupation as string,
        income_type: paymentType as string,
        income_source: incomeSource as string,
      })
    );

    if (error) return { [FORM_ERROR]: 'error' };
  };

  const submitCallback = async (errors?: any) => {
    if (!errors) {
      await delayWithState(400, setIsCompleted);
      dispatch(updateCurrentSignupPage({ page: props.nextPage }));
    } else {
      setHasSubmitFailed(true);
    }
  };

  const handleOccupationOnChange = ({ key }: { key: number }): void => {
    const value = occupationOptions[key]?.value || '';
    setOccupation(value);
  };
  const handlePaymentTypeOnChange = ({ key }: { key: number }): void => {
    const value = paymentTypeOptions[key]?.value || '';
    setPaymentType(value);
  };
  const handleIncomeSourceOnChange = ({ key }: { key: number }): void => {
    const value = incomeSourceOptions[key]?.value || '';
    setIncomeSource(value);
  };

  // ///////////////////////////////////////
  /* =========== FORM ELEMENTS ========== */
  // ///////////////////////////////////////

  const IncomeInput = ({ input, meta }: Partial<WEB.InputFieldProps>) => {
    const value = input?.value as string | undefined;
    return (
      <Input.Currency
        id='annual-income-input'
        valueDefault={value}
        placeholder={annualIncomeSection?.placeholder}
        label={annualIncomeSection?.header}
        description={annualIncomeSection?.text}
        widthSize={InputWidth.MEDIUM}
        min='0'
        {...input}
        invalid={(meta?.dirty || meta?.touched) && meta.error}
        errorText={annualIncomeSection?.errorMessage || meta?.error}
        defaultNoValueToZero={false}
      />
    );
  };

  const texts =
    infoPaneContext?.text instanceof Array ? infoPaneContext.text : [];

  /* The dropdown menu is not within the <Form> wrapper component
     because react-final-form and the <Dropdown> component have conflicting
     event listeners for when ENTER is pressed.
  */
  return (
    <>
      <PageBase mobileHeader={infoPaneContext?.header || ''} mobileLead={texts}>
        <Dropdown.Menu
          id='employment-menu'
          label={occupationSection?.header}
          defaultValue={occupationDefaultDisplay}
          description={occupationSection?.text}
          floatingLabel={occupationSection?.placeholder}
          items={occupationItems}
          invalid={occupation === null && hasSubmitFailed}
          errorText={occupationSection?.errorMessage}
          onSelect={handleOccupationOnChange}
          widthSize={DropdownWidthSize.MEDIUM}
          showError
        />
        <Spacer space={spacers.tabLarge} desktopOnly />
        <Dropdown.Menu
          id='payment-type-menu'
          label={paymentTypeSection?.header}
          defaultValue={paymentTypeDefaultDisplay}
          floatingLabel={paymentTypeSection?.placeholder}
          items={paymentTypeItems}
          invalid={paymentType === null && hasSubmitFailed}
          errorText={paymentTypeSection?.errorMessage}
          onSelect={handlePaymentTypeOnChange}
          widthSize={DropdownWidthSize.MEDIUM}
          showError
        />
        <Spacer space={spacers.tabLarge} desktopOnly />
        <Dropdown.Menu
          id='income-source-menu'
          label={incomeSourceSection?.header}
          defaultValue={incomeSourceDefaultDisplay}
          floatingLabel={incomeSourceSection?.placeholder}
          items={incomeSourceItems}
          invalid={incomeSource === null && hasSubmitFailed}
          errorText={incomeSourceSection?.errorMessage}
          onSelect={handleIncomeSourceOnChange}
          widthSize={DropdownWidthSize.MEDIUM}
          showError
        />
        <Spacer space={spacers.tabLarge} desktopOnly />
        <Form
          onSubmit={handleOnNext}
          initialValues={{
            occupation: false,
            paymentType: false,
            incomeSource: false,
            income: annualIncomeDefault,
          }}
          render={({ handleSubmit, submitting }) => (
            <form
              onSubmit={(event) => {
                handleSubmit(event)?.then(submitCallback);
              }}
            >
              <Field name='occupation' component={HiddenInput} />
              <Field name='incomeSource' component={HiddenInput} />
              <Field name='paymentType' component={HiddenInput} />
              <Field
                name='income'
                component={showIncomeField ? IncomeInput : HiddenInput}
                validate={composeValidators(isRequired, minValue(0))}
              />
              <ButtonsContainer>
                <Button
                  id='employment-confirm-button'
                  isCompleted={isCompleted}
                  isLoading={submitting}
                  disabled={submitting}
                >
                  {!isCompleted ? buttonsCtx?.primary : buttonsCtx?.complete}
                </Button>
              </ButtonsContainer>
            </form>
          )}
        />
      </PageBase>
    </>
  );
};

export default EmploymentStatus;
