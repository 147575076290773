import { logger } from 'utils/logger';

const version = window.albertWeb.Version;

/* Note: This function is migrated directly from
https://github.com/meetalbert/albert-web/blob/master/server/src/routes/splash.ts#L32
We'll find a better way to share utility code between FE and BE eventually
but this is unlikely to change much, so no worries for now.
*/
const injectVersionCachebuster = (url: string) => {
  try {
    if (~url.indexOf('http://') || ~url.indexOf('data://')) {
      const structure = new URL(url);
      structure.searchParams.append('v', version);
      return structure.toString();
    }
  } catch (err) {
    logger.warn(err);
  }
  let pathUrl = url;
  let hash;
  let query;
  if (~pathUrl.indexOf('#')) {
    const parts = pathUrl.split('#');
    [pathUrl] = parts;
    hash = parts.slice(1).join('#');
  }
  if (~pathUrl?.indexOf('?')) {
    const parts = url.split('?');
    [pathUrl] = parts;
    query = parts.slice(1).join('?');
  }
  query = query ? `?v=${version}&${query}` : `?v=${version}`;
  pathUrl += query;
  if (hash) {
    pathUrl += `#${hash}`;
  }
  return pathUrl;
};

const staticUrl = (url: string) => {
  return injectVersionCachebuster(`https://cdn.albert.com/static/${url}`);
};

export default staticUrl;
