import * as React from 'react';

const CreateBudgetIcon = (): React.ReactElement => (
  <svg
    width='25'
    height='24'
    viewBox='0 0 25 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M3.952 18.5835V12.6955H21.232V18.5835C21.232 19.6715 20.4 20.5035 19.312 20.5035H5.872C4.784 20.5035 3.952 19.6715 3.952 18.5835ZM0.56 18.5835C0.56 21.5275 2.928 23.8955 5.872 23.8955H19.312C22.256 23.8955 24.624 21.5275 24.624 18.5835V7.99155C24.624 5.07955 22.32 2.74355 19.408 2.67955V2.07155C19.408 1.14355 18.64 0.375546 17.712 0.375546C16.784 0.375546 16.016 1.14355 16.016 2.07155V2.67955H9.136V2.07155C9.136 1.14355 8.368 0.375546 7.44 0.375546C6.512 0.375546 5.744 1.14355 5.744 2.07155V2.67955C2.864 2.74355 0.56 5.07955 0.56 7.99155V18.5835ZM3.952 9.49555V7.99155C3.952 6.93555 4.72 6.13555 5.776 6.07155C5.936 6.83955 6.608 7.44755 7.44 7.44755C8.272 7.44755 8.944 6.83955 9.104 6.07155H16.048C16.208 6.83955 16.88 7.44755 17.712 7.44755C18.544 7.44755 19.216 6.83955 19.376 6.07155C20.432 6.10355 21.232 6.93555 21.232 7.99155V9.49555H3.952Z'
      fill='#0066FF'
    />
  </svg>
);

export default CreateBudgetIcon;
