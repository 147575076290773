import * as Sentry from '@sentry/react';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import removeBanner from 'actions/banner/removeBanner';
import BannerPane from 'components/BannerPane';
import SpinningDebitCard from 'components/banking/SpinningDebitCard';
import Button from 'components/common/Button';
import Spacer from 'components/common/Spacer';
import Text, { TextSizes } from 'components/common/Text';
import BorderContainer from 'components/layout/BorderContainer';
import {
  LeftPane,
  PageContainer,
  PaneWrapper,
  RightPane,
} from 'components/layout/FiftyFiftyPageLayout';
import PageBase from 'components/layout/PageBase';
import Pages from 'constants/Pages';
import useConfetti from 'hooks/useConfetti';
import useNavigateFunction from 'hooks/useNavigateFunction';
import useVerifyCurrentPage from 'hooks/useVerifyCurrentPage';
import Navbar from 'pages/unauthenticated/Navbar';
import * as language from 'reducers/entities/language';
import * as profile from 'reducers/entities/profile';
import { breakpoints, fontSizes, mixins, spacers } from 'styles';
import * as WEB from 'types/interfaces';
import { delayWithState } from 'utils/delay';
import { getLanguageButtons, getLanguageSection } from 'utils/getFromLanguage';
import { logger } from 'utils/logger';

type Props = {
  nextPage: string;
};

const StyledRightPane = styled(RightPane)`
  @media ${breakpoints.mobileLarge} {
    padding-top: 60px;
  }
`;

const StyledLeftPane = styled(LeftPane)`
  padding-top: 40px;
  @media ${breakpoints.mobileLarge} {
    padding-top: 30px;
  }
`;

const Header = styled.h1`
  margin-top: 0;
  margin-bottom: ${mixins.pxToRem('32px')};
  @media ${breakpoints.mobileLarge} {
    margin-bottom: 0;
  }
`;

const DisclosureText = styled.p`
  ${fontSizes.fontSize14}
  color: ${({ theme }) => theme.colors.darkGray1};
  @media ${breakpoints.mobileLarge} {
    margin: 26px 0 0 0;
  }

  b {
    font-weight: 600;
  }
`;

const CashSetup = (props: Props) => {
  // //////////////////////////////////
  /* =========== HOOKS ========== */
  // //////////////////////////////////
  const dispatch = useDispatch();
  const { updateCurrentSignupPage } = useNavigateFunction();
  // Make sure Redux is up-to-date with currentSignUpPage
  useVerifyCurrentPage(Pages.CASH_SETUP);
  useConfetti();

  // ///////////////////////////////
  /* ======= MAPPED STATE ======= */
  // ///////////////////////////////
  const languageCtx = useSelector((state: WEB.RootState) =>
    language.getSignupPageLanguage(state, Pages.CASH_SETUP)
  );
  const firstName = useSelector((state: WEB.RootState) =>
    profile.getValueByField(state, 'first_name')
  );
  const lastName = useSelector((state: WEB.RootState) =>
    profile.getValueByField(state, 'last_name')
  );

  // ///////////////////////////////
  /* =========== STATE ========== */
  // ///////////////////////////////
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [isCompleted, setIsCompleted] = React.useState<boolean>(false);

  // //////////////////////////////////
  /* =========== LANGUAGE ========== */
  // //////////////////////////////////
  const introCtx = getLanguageSection(languageCtx, 'intro');
  const buttonsCtx = getLanguageButtons(languageCtx);

  // //////////////////////////////////
  /* =========== HANDLERS ========== */
  // //////////////////////////////////
  const onSubmit = async () => {
    // Remove any error banner from previous attempts
    dispatch(removeBanner());

    await delayWithState(400, setIsCompleted);
    dispatch(updateCurrentSignupPage({ page: props.nextPage }));
  };

  const Disclosure = (): React.ReactElement => {
    return (
      <DisclosureText>
        <b>
          Albert is not a bank. Banking services provided by Sutton Bank, Member
          FDIC.&nbsp;
        </b>
        The Albert Mastercard® debit card is issued by Sutton Bank, Member FDIC,
        pursuant to a license by Mastercard International. Mastercard and the
        circles design are registered trademarks of Mastercard International
        Incorporated.
      </DisclosureText>
    );
  };

  if (!firstName || !lastName) {
    // Extremely rare & unexplained case where users manage to navigate to this page without first name or last name
    const errorContext = {
      firstNameLength: firstName?.length || 0,
      lastNameLength: lastName?.length || 0,
    };
    Sentry.setContext('Profile info', errorContext);
    logger.error('Name missing from CashSetup page');
  }

  return (
    <>
      <Navbar signupFlow />
      <PageContainer>
        <BannerPane />
        <PaneWrapper>
          <StyledLeftPane>
            <SpinningDebitCard
              firstName={firstName || ''}
              lastName={lastName || ''}
            />
          </StyledLeftPane>
          <StyledRightPane>
            <PageBase maxWidth='584px'>
              <Spacer space={spacers.small} desktopOnly />
              <BorderContainer>
                <Header>{introCtx?.header}</Header>
                <Text size={TextSizes.MEDIUM} className='description'>
                  {introCtx?.text}
                </Text>
                <div className='buttons-container'>
                  <Button
                    onClick={onSubmit}
                    isCompleted={isCompleted}
                    isLoading={isSubmitting}
                    disabled={isSubmitting}
                    stretch
                  >
                    {buttonsCtx?.primary}
                  </Button>
                </div>
                <Spacer space={spacers.tiny} />
                <Disclosure />
              </BorderContainer>
            </PageBase>
          </StyledRightPane>
        </PaneWrapper>
      </PageContainer>
    </>
  );
};

export default CashSetup;
