import { QuestionCircleFill } from '@styled-icons/bootstrap/QuestionCircleFill';
import styled from 'styled-components';
import { textTransition } from 'components/common/Text';

type Props = {
  color?: string;
};

const StyledQuestionCircle = styled(QuestionCircleFill)<Props>`
  color: ${({ theme, color }) => color || theme.colors.primaryText};
  width: 12px;
  height: 12px;
  display: inline-block;
  cursor: help;
  ${textTransition}
  &:hover {
    color: ${({ theme }) => theme.colors.primaryAlbertBrand};
  }
  &:focus {
    outline-color: ${({ theme }) => theme.colors.primaryAlbertBrand};
  }
`;

export default StyledQuestionCircle;
