import styled from 'styled-components';
import ZIndex from 'constants/ZIndex';

const BlurredBackdrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 4px;
  height: 100%;
  width: 100%;
  z-index: ${ZIndex.MODAL_POPUP};
`;

export default BlurredBackdrop;
