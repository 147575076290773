import { NavigateFunction } from 'react-router-dom';
import types from 'actions/types';
import updateAppData from 'actions/updateAppData';
import updateCurrentSignupPage from 'actions/updateCurrentSignupPage';
import { NodeEnvironment, PageRoutes } from 'constants/index';
import * as profile from 'reducers/entities/profile';
import * as app from 'reducers/ui/app';
import * as signup from 'reducers/ui/signup';
import * as WEB from 'types/interfaces';
import { getIsCheckpointDisabled } from 'utils/checkpointNavUtils';
import { logger } from 'utils/logger';

const initializeApp =
  (
    navigate: NavigateFunction,
    shouldRedirect = false
  ): WEB.ActionCreator<void> =>
  async (dispatch: WEB.Dispatch, getState: WEB.GetStateFunc) => {
    try {
      logger.info('initializing app');
      // NOTE: for now it's only a call to updateAppData - but this can change as the app gets bigger
      dispatch({ type: types.INITIALIZE_APP_REQUEST });

      // ===== STEP 1: Fetch app data, profile, and language. =====
      const success = await dispatch(updateAppData());
      if (!success) {
        return;
      }

      // Do not proceed if user is not logged in after fetching app data and profile
      // This means that we redirected them back to albert.com (auth)
      const state = getState();
      const isLoggedIn = app.isLoggedIn(state);
      if (!isLoggedIn) {
        logger.info('User is not logged in after fetching app data');
        dispatch({ type: types.REDIRECT_SPLASH_HOME });
        return;
      }
      // ===== STEP 2: Checkpoint navigation. =====
      const checkpoint = signup.getCheckpoint(state);

      if (checkpoint) {
        // Allow user to toggle whether or not initializing app can redirect to an existing checkpoint
        // A feature that mostly useful for QA
        const isProd = window.albertWeb.Environment === NodeEnvironment.PROD;
        const disabledCheckpointNav = getIsCheckpointDisabled();

        // Allow checkpoint redirect if:
        // (1) production env
        // (2) checkpoint nav not disabled (via local storage)
        const canNavToCheckpoint = isProd || !disabledCheckpointNav;

        // Get route based on checkpoint
        canNavToCheckpoint &&
          (await dispatch(
            updateCurrentSignupPage(navigate)({
              page: checkpoint,
              shouldNavTo: true,
              shouldReplace: true,
              locationState: { fromCheckpoint: true },
            })
          ));
      } else {
        const web_signup_version_key = profile.getValueByField(
          state,
          'personal_info'
        ).web_signup_version_key;
        const user_finished_signup_flow = profile.getValueByField(
          state,
          'user_finished_signup_flow'
        );

        if (
          web_signup_version_key === 'web_apr2022_short' &&
          !user_finished_signup_flow
        ) {
          window.location.href = '/apply/download';
          return;
        }

        // Check if user has downloaded the app.
        const didDownloadApp = profile.getDidDownloadApp(state);

        // Check if current request is for a signup page.
        const isSignupPageRequest =
          window.location.pathname.substring(
            0,
            PageRoutes.SIGNUP_BASE.length
          ) === PageRoutes.SIGNUP_BASE;

        const isRedirectPath =
          // when user attempts to go to signup.albert.com/ at the base route.
          window.location.pathname === PageRoutes.BASE ||
          // when user attempts to go to /signup or /login when already logged-in.
          window.location.pathname === PageRoutes.REDIRECT;

        // If user finished signup, we should handle redirect for the following cases:
        // (1) Did not download app OR
        // (2) Is one of the redirect paths (relies on initializeApp for next page) OR
        // (3) User is requesting signup/signup flow page (may or may not have downloaded app)
        // (4) Initialize app was passed in arg shouldRedirect === true.
        if (
          !didDownloadApp ||
          isSignupPageRequest ||
          isRedirectPath ||
          shouldRedirect
        ) {
          if (didDownloadApp) {
            // Should nav to albert.com if user downloaded app.
            window.location.href = window.albertWeb.SplashAppDomain;
          } else {
            navigate(
              {
                pathname: PageRoutes.DOWNLOAD_APP,
                search: window.location.search,
              },
              { replace: true }
            );
          }
        }
      }
      dispatch({ type: types.INITIALIZE_APP_SUCCESS });
    } catch (e: unknown) {
      const error = e instanceof Error ? e : undefined;
      dispatch({
        type: types.INITIALIZE_APP_FAILURE,
        error,
      });
    }
  };

export default initializeApp;
