/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import updateEmail from 'actions/async/updateEmail';
import verifyEmail from 'actions/async/verifyEmail';
import removeBanner from 'actions/banner/removeBanner';
import Button from 'components/common/Button';
import ResendCodeButton from 'components/common/Button/ResendCodeButton';
import Input from 'components/common/Input';
import Spacer from 'components/common/Spacer';
import { textTransition } from 'components/common/Text';
import ButtonsLayout from 'components/layout/ButtonsLayout';
import LeadContent from 'components/layout/LeadContent';
import PageBase from 'components/layout/PageBase';
import Pages, { PageType } from 'constants/Pages';
import useNavigateFunction from 'hooks/useNavigateFunction';
import useVerifyCurrentPage from 'hooks/useVerifyCurrentPage';
import * as language from 'reducers/entities/language';
import * as profile from 'reducers/entities/profile';
import { breakpoints, colors } from 'styles';
import { spacers } from 'styles/home';
import * as WEB from 'types/interfaces';
import { delayWithState } from 'utils/delay';
import {
  getLanguageButtons,
  getLanguageInfo,
  getLanguageSection,
} from 'utils/getFromLanguage';

type Props = {
  fromSignup?: boolean;
  nextPage: string;
};

const InlineContainer = styled.div`
  display: flex;
  align-items: center;

  &:hover,
  &:hover u,
  &:hover svg {
    ${textTransition}
    color: ${colors.primaryAlbertBrand};
  }

  @media ${breakpoints.mobileLarge} {
    // Make room for the tooltip on mobile
    padding-bottom: 50px;
  }
`;

const DesktopTooltipContainer = styled.div`
  display: block;
  @media ${breakpoints.mobileLarge} {
    display: none;
  }
`;

const MobileTooltipContainer = styled.div`
  display: none;
  @media ${breakpoints.mobileLarge} {
    display: block;
  }
`;

const HiddenInput = styled.input`
  display: none;
`;

const KYCConfirmEmail = (props: Props): React.ReactElement => {
  const { fromSignup } = props;
  const { updateCurrentKYCPage } = useNavigateFunction();

  // Make Redux is up-to-date with currentKYCPage
  useVerifyCurrentPage(Pages.KYC_CONFIRM_EMAIL, PageType.KYC);

  // ///////////////////////////////
  /* =========== HOOKS ========== */
  // ///////////////////////////////
  const dispatch = useDispatch();

  // ///////////////////////////////
  /* =========== STATE ========== */
  // ///////////////////////////////
  const [isCodeInvalid, setIsCodeInvalid] = React.useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [isCompleted, setIsCompleted] = React.useState<boolean>(false);
  const [confirmCode, setConfirmCode] = React.useState('');

  // ///////////////////////////////
  /* ======== MAPPED STATE ====== */
  // ///////////////////////////////
  const languageCtx = useSelector((state: WEB.RootState) =>
    language.getKYCPageLanguage(state, Pages.KYC_CONFIRM_EMAIL)
  );

  const emailToUpdate = useSelector((state: WEB.RootState) =>
    profile.getValueByField(state, 'email_to_update')
  );

  // //////////////////////////////////
  /* =========== HANDLERS ========== */
  // //////////////////////////////////
  const handleOnNext = async () => {
    // Remove any error banner from previous attempts
    dispatch(removeBanner());

    if (!confirmCode || confirmCode.length < 4) {
      setIsCodeInvalid(true);
      setConfirmCode('');
      return { code: 'error' };
    }
    // Indicate button loading state
    setIsSubmitting(true);

    const { response }: any = await dispatch(
      verifyEmail({ email_confirm_code: confirmCode })
    );

    setIsSubmitting(false);

    const responseData = response?.payload?.data;
    const verified = !!responseData?.personal_info?.email_verification;

    if (verified) {
      // Indicate button completed state
      await delayWithState(600, setIsCompleted);
      // In any scenario, the user will be navigate to KYCSSN after verifying
      // their email
      dispatch(
        updateCurrentKYCPage({
          fromSignup,
          page: Pages.KYC_SSN,
        })
      );
    } else {
      setConfirmCode('');
      setIsCodeInvalid(true);
      return { code: 'error' };
    }
  };

  const handleResendCode = () => {
    // updateEmail action sends verification code
    dispatch(updateEmail({ email: emailToUpdate }, 'kyc', true));
  };

  const handleGoBack = () => {
    dispatch(
      updateCurrentKYCPage({
        fromSignup,
        page: Pages.KYC_CONFIRM,
      })
    );
  };

  const handleCodeOnComplete = (value: string): void => {
    if (isCodeInvalid) {
      setIsCodeInvalid(false);
    }
    setConfirmCode(value);
  };

  // //////////////////////////////////
  /* =========== LANGUAGE ========== */
  // //////////////////////////////////
  const CONFIRM_KEY = 'confirm';

  const confirmSection = getLanguageSection(languageCtx, CONFIRM_KEY);
  const infoPaneContext = getLanguageInfo(languageCtx);
  const buttons = getLanguageButtons(languageCtx);

  return (
    <>
      <PageBase>
        <LeadContent
          header={infoPaneContext?.header || ''}
          text={confirmSection?.text}
          desktopHeaderSize='small'
        />
        <Spacer space={spacers.g5} desktopOnly />
        <Form
          onSubmit={handleOnNext}
          initialValues={{
            code: false,
          }}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Field name='code' component={HiddenInput} />
              <Input.Code
                id='code-input'
                label=''
                fields={4}
                invalid={isCodeInvalid}
                errorText={confirmSection?.errorMessage}
                onChange={handleCodeOnComplete}
              />
              <ButtonsLayout
                primaryButton={
                  <Button
                    id='verify-code-button'
                    stretch
                    isCompleted={isCompleted}
                    isLoading={isSubmitting}
                    disabled={isSubmitting}
                  >
                    {!isCompleted ? buttons?.primary : buttons?.complete}
                  </Button>
                }
                secondaryButton={
                  <ResendCodeButton
                    onClick={handleResendCode}
                    tooltipText={buttons?.linkTooltip || ''}
                  />
                }
              ></ButtonsLayout>
            </form>
          )}
        />
      </PageBase>
    </>
  );
};

export default KYCConfirmEmail;
