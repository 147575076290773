/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import styled from 'styled-components';
import { Roles } from 'constants/Accessibility';
import { fontSizes } from 'styles';
import { handleActivateKeyDown } from 'utils/accessibility';

export enum TextSizes {
  TINY = 'tiny',
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  LARGE_MEDIUM = 'large-medium',
  XLARGE = 'x-large',
  XXLARGE = 'x-x-large',
}

export enum TextColor {
  BLACK = 'black',
  GRAY = 'gray',
  LIGHT_GRAY = 'light-gray',
  LOGAN_GRAY = 'logan-gray',
  BLUE = 'blue',
  GRAY_DARK = 'gray-dark',
  WHITE = 'white',
  GOLD = 'gold',
}

export type Props = {
  /* unique ID for the component */
  id?: string;
  /* type of the text */
  size?: TextSizes | string;
  /* the color of the text */
  color?: TextColor | string;
  /* whether to display the text inline */
  inline?: boolean | false;
  /* whether to bold the text */
  weight?: string | '400';
  /* whether the text has a tooltip attached to it */
  hasTooltip?: boolean | false;
  /* the theme to use for this component */
  theme?: any;
  /* assuming a text could contain icons and other elements */
  children?: React.ReactNode | React.ReactNode[];
  /* underline text */
  underline?: boolean;
  /* italicize text */
  italic?: boolean;
  /* text align */
  align?: string;
  /* pointer on hover */
  pointer?: boolean;
  /* no wrap */
  nowrap?: boolean;
  /* tab index to set so text can be accessed using tab key  */
  tabIndex?: number;
  /* on click handler */
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  /* whether the text is intended to be a link button or not */
  isLinkButton?: boolean | false;
  /* remove transition effect from text */
  noTransition?: boolean;
  /* style class */
  className?: string;
};

const getFontSize = ({ size }: Props): string => {
  switch (size) {
    case TextSizes.TINY:
      return fontSizes.fontSize12;
    case TextSizes.SMALL:
      return fontSizes.fontSize14;
    case TextSizes.MEDIUM:
      return fontSizes.fontSize16;
    case TextSizes.LARGE:
      return fontSizes.fontSize18;
    case TextSizes.LARGE_MEDIUM:
      return fontSizes.fontSize20;
    case TextSizes.XLARGE:
      return fontSizes.fontSize24;
    case TextSizes.XXLARGE:
      return fontSizes.fontSize28;
    default:
      return 'font-size: inherit;';
  }
};

export const getColor = (props: Props): string => {
  const { theme, color } = props;
  switch (color) {
    case TextColor.BLACK:
      return theme?.colors.primaryText as string;
    case TextColor.BLUE:
      return theme?.colors.primaryAlbertBrand as string;
    case TextColor.GRAY:
      return theme?.colors.primaryGray as string;
    case TextColor.LIGHT_GRAY:
      return theme?.colors.lightGray1 as string;
    case TextColor.GRAY_DARK:
      return theme?.colors.darkGray1 as string;
    case TextColor.LOGAN_GRAY:
      return theme?.colors.loganGray as string;
    case TextColor.WHITE:
      return theme?.colors.primaryBackground as string;
    case TextColor.GOLD:
      return theme?.colors.geniusText as string;
    default:
      return theme?.colors.primaryText as string;
  }
};

const getHoverColor = (props: Props): string => {
  const { theme, color } = props;
  switch (color) {
    case TextColor.BLUE:
      return theme?.colors.brandDark2 as string;
    case TextColor.GOLD:
      return theme?.colors.primaryGenius as string;
    case TextColor.GRAY:
      return theme?.colors.primaryText as string;
    default:
      return theme?.colors.primaryText as string;
  }
};

export const textTransition = 'transition: all 0.1s ease-in 0.1s;';

const StyledText = styled.span<Props>`
  word-break: break-word;
  ${getFontSize}
  color: ${(props: Props) => getColor(props)};
  display: ${({ inline }) => (!inline ? 'block' : 'inline')};
  font-weight: ${({ weight }) => weight};
  ${({ nowrap }) => nowrap && 'white-space: nowrap;'};
  ${({ pointer }) => pointer && 'cursor: pointer;'}
  ${({ align }) => align && `text-align: ${align};`};
  ${({ underline }) => underline && 'text-decoration: underline;'};
  ${({ italic }) => italic && 'font-style: italic;'};
  ${({ noTransition }) =>
    !noTransition &&
    `
    ${textTransition}
     a, u {
      ${textTransition}
    }
  `}
  // TODO: Uncomment this once global style is added into app online pages
  // line-height: inherit;

  a,
  a:focus,
  a:visited,
  a:active,
  u,
  u:focus,
  u:visited,
  u:active {
    cursor: pointer;
    color: ${(props: Props) => getColor(props)};
  }
  a:hover,
  u:hover {
    color: ${(props: Props) => getHoverColor(props)};
  }
  b,
  strong {
    font-weight: 600;
  }
`;

const StyledLinkButton = styled(StyledText)`
  &:focus {
    outline-color: ${({ theme }) => theme.colors.primaryAlbertBrand};
  }
  // Media query prevents issues with hover state sticking on touchscreens
  @media (hover: hover) {
    &:hover {
      color: ${(props: Props) => getHoverColor(props)};
      cursor: pointer;
    }
  }
`;

export default (props: Props): React.ReactElement => {
  const { isLinkButton, onClick, children, tabIndex } = props;

  if (isLinkButton) {
    return (
      <StyledLinkButton
        {...props}
        tabIndex={tabIndex || 0}
        onKeyDown={(e) => handleActivateKeyDown(e, onClick)}
        role={Roles.BUTTON}
      >
        {children}
      </StyledLinkButton>
    );
  }

  return <StyledText {...props}>{children}</StyledText>;
};
