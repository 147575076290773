import types from 'actions/types';
import AlbertClient from 'api/AlbertClient';
import * as WEB from 'types/interfaces';
import { onErrorDisplayDefaultBanner } from 'utils/axiosMiddlewareUtils';

type Data = {
  apartment?: string;
  own: boolean;
};

/**
 * Update a property.
 *
 * @param propertyId ID of the existing property,
 *                   e.g. "property_123"
 * @param apartment field value for the property
 * @param own field value for the property
 */
export default (
    propertyId: string,
    apartment: string,
    own: boolean
  ): WEB.ActionCreator<Promise<boolean>> =>
  async (dispatch: WEB.Dispatch): Promise<boolean> => {
    let error = false;
    const data: Data = { own };

    if (apartment) {
      data.apartment = apartment;
    }

    await dispatch({
      types: [
        types.UPDATE_PROPERTY_REQUEST,
        types.UPDATE_PROPERTY_SUCCESS,
        types.UPDATE_PROPERTY_FAILURE,
      ],
      payload: {
        request: {
          method: 'put',
          url: AlbertClient.propertyView(propertyId),
          data,
        },
        options: {
          onError: (middlewareOptions: any, options: any) => {
            error = true;
            return onErrorDisplayDefaultBanner(middlewareOptions, options);
          },
        },
      },
    });

    return error;
  };
