/* eslint-disable no-param-reassign */
import produce from 'immer';
import initialState from 'store.initialState';
import types from 'actions/types';
import { Theme } from 'constants/';
import * as WEB from 'types/interfaces';

// /////////////////////////////////////////////////////////////////////////////
// selectors
// /////////////////////////////////////////////////////////////////////////////
export const isLoggedIn = (state: WEB.RootState): boolean => {
  return state.ui.app.loggedIn;
};

export const getTheme = (state: WEB.RootState): Theme => {
  return state.ui.app.theme || Theme.MAIN;
};

export const getInitializedApp = (state: WEB.RootState): boolean => {
  return !!state.ui.app.initializedApp;
};

export const getIsLoading = (state: WEB.RootState): boolean => {
  return !!state.ui.app.isLoading;
};

export const getPollingFunction = (
  state: WEB.RootState
): WEB.PollingFunction => {
  return state.ui.app.pollingFunction || {};
};

// We know app was initialized if appData and profile exist
export const initializedApp = (state: WEB.RootState): boolean =>
  !!Object.keys(state.entities.appData).length &&
  !!Object.keys(state.entities.profile).length &&
  !!state.ui.app.initializedApp;

export const show2FA = (state: WEB.RootState) => state.ui.app.show2FA;

// /////////////////////////////////////////////////////////////////////////////
// reducers
// /////////////////////////////////////////////////////////////////////////////

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable consistent-return */
const app = (state = initialState.ui.app, action: WEB.Action) => {
  const { type, payload, error } = action;
  return produce(state, (draft: WEB.RootState['ui']['app']) => {
    switch (type) {
      case types.GET_SIGNUP_INTRO_REQUEST:
      case types.INITIALIZE_APP_REQUEST: {
        draft.isLoading = true;
        return;
      }

      case types.INITIALIZE_APP_SUCCESS:
        draft.initializedApp = true;
        draft.isLoading = false;
        return;

      case types.GET_SIGNUP_INTRO_FAILURE:
      case types.GET_SIGNUP_INTRO_SUCCESS:
      case types.UPDATE_APP_DATA_FAILURE:
      case types.GET_PROFILE_FAILURE:
      case types.GET_APP_DATA_FAILURE:
      case types.INITIALIZE_APP_FAILURE: {
        draft.isLoading = false;
        return;
      }

      case types.SET_THEME: {
        draft.theme = payload.theme || Theme.MAIN;
        return;
      }

      case types.SET_LOGGED_IN: {
        draft.loggedIn = payload.loggedIn || false;
        return;
      }

      case types.LOGOUT_SUCCESS: {
        return initialState.ui.app;
      }

      case types.TWOFA_INITIATE: {
        draft.show2FA = true;
        return;
      }

      case types.TWOFA_COMPLETE: {
        draft.show2FA = false;
        return;
      }

      default:
    }
  });
};

export default app;
