import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';
import {
  BROWSER_DEFAULT_FONT_SIZE_PX,
  ROOT_FONT_SIZE_PX,
  ROOT_FONT_SIZE_SMALL_PX,
} from './constants';
import { breakpoints, fontSizes } from './index';

/* eslint-disable @typescript-eslint/no-explicit-any */
type Props = {
  theme: any;
};

/**
 * Convert a font-size from a pixel unit to a percent unit relative to the
 * browser's default font size.
 *
 * @param px pixel value to convert
 * @returns font size as a percent
 */
export const convertPxToPercent = (px: number): number =>
  (px / BROWSER_DEFAULT_FONT_SIZE_PX) * 100;

const GlobalStyle = createGlobalStyle<Props>`
  ${normalize}

  :root {
    box-sizing: border-box;
    font-size: ${convertPxToPercent(ROOT_FONT_SIZE_PX)}%;
    @media ${breakpoints.desktopLarge} {
      font-size: ${convertPxToPercent(ROOT_FONT_SIZE_SMALL_PX)}%;
    }
    overflow-x: hidden;
  
    // Remove gray background on tap in iOS Safari
    -webkit-tap-highlight-color: transparent;
  }

  *, ::before, ::after {
    box-sizing: inherit;
  }

  body {
    margin: 0;
    padding: 0;
    width: 100vw; // vw necessary to prevent scrollbar from shifting page
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.primaryText};
  }

  a, a:active, a:visited, a:focus {
    color: ${({ theme }) => theme.colors.primaryText};
  }

  a:hover {
    color: ${({ theme }) => theme.colors.primaryDark2}
  }
 
  span, p { line-height: 140%; }
  h1, h2, h3, h4 { line-height: 120%; }

  h1 {
    ${fontSizes.fontSize40}
    font-weight: 700; 
    @media ${breakpoints.mobileLarge} {
      ${fontSizes.fontSize35}
    }
    @media ${breakpoints.mobileMedium} { 
      ${fontSizes.fontSize30}
    }
  }
  h2 {
    margin: 0.5em 0;
    ${fontSizes.fontSize28}
    font-weight: 700; 
    @media ${breakpoints.mobileLarge} {
      ${fontSizes.fontSize23} 
    }
    @media ${breakpoints.mobileMedium} {
      ${fontSizes.fontSize20}
    }
  }
  h3 {
    ${fontSizes.fontSize18}
    font-weight: 700; 
  }
  h4 {
    ${fontSizes.fontSize16}
    font-weight: 600; 
  }

  button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }

`;

export default GlobalStyle;
