import classNames from 'classnames';
import * as React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import AlbertLogo from 'components/common/AlbertLogo';
import Button from 'components/common/Button';
import Cross from 'components/icons/Cross';
import Hamburger from 'components/icons/Hamburger';
import ProgressNavBar from 'components/signup/ProgressNavBar';
import { ZIndex } from 'constants/';
import { getSignupFlag } from 'reducers/entities/language';
import * as app from 'reducers/ui/app';
import { breakpoints, fontSizes, mixins, spacers } from 'styles';
import { HEADER_HEIGHT } from 'styles/constants';
import { RootState } from 'types/interfaces';
import staticUrl from 'utils/staticUrl';
import { getSplashUrl } from 'utils/urlFormatters';

type Props = {
  /* split color */
  split?: boolean;
  /* signup / genius flow */
  signupFlow?: boolean;
  /* override for the logo background color */
  background?: string;
  /* hide navbar content except logo */
  logoOnly?: boolean;
  /* only show logout / TMTA button */
  logoutButtonOnly?: boolean;
};

const transition = '0.2s linear';

const Wrapper = styled.div<{ signupFlow?: boolean }>`
  ${({ signupFlow }) =>
    // Only apply padding-bottom when displaying fixed position navbar
    !signupFlow && `padding-bottom: ${mixins.pxToRem(HEADER_HEIGHT)}`};
`;

const Container = styled.nav<Props & { shrink?: boolean }>`
  position: ${({ signupFlow }) => (signupFlow ? 'static' : 'fixed')};
  // Prevent horizontal scrolling in signup flow with static navbar
  ${({ signupFlow }) => signupFlow && 'overflow: hidden;'}

  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 0;
  margin: 0;
  border: none;
  width: 100vw; // vw necessary to prevent scrollbar from shifting page
  height: ${mixins.pxToRem(HEADER_HEIGHT)};
  ${fontSizes.fontSize16}
  line-height: 1.5;
  z-index: ${ZIndex.NAV_BAR};

  @media ${breakpoints.mobileLarge} {
    background-color: ${({ theme }) => theme.colors.primaryBackground};
  }
  @media ${breakpoints.tabletXSmall} {
    padding: 1.5rem 0;
  }

  .col-nav-left {
    height: 100%;
    width: 42vw;
    display: flex;
    align-items: center;
    padding-left: ${spacers.defaultPaddingLeftRight};
    float: left;
    // Prevent mismatching issues with the two-pane layout in the signup flow
    flex-shrink: ${({ signupFlow }) => (signupFlow ? '0' : '1')};
    background-color: ${({ theme }) => theme.colors.primaryBackground};
    ${({ theme, split, background }) =>
      split &&
      background !== 'white' &&
      `background-color: ${theme.colors.lightGray3};`}
    @media ${breakpoints.mobileLarge} {
      width: 100%;
      background-color: ${({ theme }) => theme.colors.primaryBackground};
    }
  }

  .col-nav-right {
    height: 100%;
    width: ${({ signupFlow, logoOnly }) =>
      signupFlow || logoOnly
        ? `calc(58vw - ${window.innerWidth - document.body.offsetWidth}px)`
        : '96vw'};
    display: flex;
    align-items: center;
    justify-content: end;
    text-align: right;
    padding-right: ${spacers.defaultPaddingLeftRight};
    border-bottom: thin solid ${({ theme }) => theme.colors.primaryBackground};
    background-color: ${({ theme }) => theme.colors.primaryBackground};
    ${({ signupFlow }) => {
      /* Add padding to left aligned signup progress bar */
      return signupFlow && `padding: ${mixins.pxToRem('28px')} 0;`;
    }};
  }

  a,
  a:visited {
    display: block;
    ${({ shrink }) => (shrink ? fontSizes.fontSize14 : fontSizes.fontSize16)}
    color: ${({ theme }) => theme.colors.primaryText};
    font-weight: 700;
    text-decoration: none;
    outline: none;
    transition: ${transition};
    :hover {
      color: ${({ theme }) => theme.colors.primaryAlbertBrand};
    }
  }
`;

const DesktopDropdown = styled.div<{ open: boolean }>`
  display: block;
  @media ${breakpoints.mobileLarge} {
    display: none;
  }

  .close-icon {
    position: absolute;
    top: 0;
    right: 0;
    padding: 28px 5vw 0px 40px;
    cursor: pointer;
    z-index: 1;
  }

  .backdrop {
    display: ${({ open }) => (open ? 'block' : 'none')};
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    transition: ${transition};

    &.enter {
      ${mixins.fadeIn(0.2, 'ease')}
    }
  }

  .dropdown {
    position: fixed;
    top: 0;
    bottom: 0;
    right: -500px;
    width: 500px;
    background-color: ${({ theme }) => theme.colors.primaryBackground};
    color: ${({ theme }) => theme.colors.primaryText};
    text-align: left;

    &.enter {
      transform: translateX(-500px);
      transition: transform 0.4s;
      filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.3));
    }

    &.exit {
      transform: translateX(500px);
      transition: transform 0.4s;
    }
  }

  .sections {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 80px;
    overflow: auto;
  }

  .features-section {
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray1};

    a {
      display: block;
      font-size: 1.6rem;
      font-weight: 500;
      padding-bottom: 20px;
      &:last-child {
        padding-bottom: 40px;
      }
    }
  }

  .company-info-section {
    padding: 30px 0 80px;
    a {
      display: block;
      font-size: 1rem;
      font-weight: 500;
      padding-bottom: 20px;
    }
  }

  .app-links-section {
    a {
      display: block;
      padding-bottom: 12px;

      &:last-child {
        padding-bottom: 0px;
      }

      img {
        width: 180px;
      }
    }
  }
`;

const MobileDropdown = styled.div<{ open: boolean }>`
  display: none;
  @media ${breakpoints.mobileLarge} {
    display: block;
  }

  .dropdown {
    display: ${({ open }) => (open ? 'block' : 'none')};
    position: fixed;
    top: ${mixins.pxToRem(HEADER_HEIGHT)};
    bottom: 0;
    left: 0;
    right: 0;
    padding-bottom: 5%;
    background-color: ${({ theme }) => theme.colors.primaryBackground};
    color: ${({ theme }) => theme.colors.primaryText};
    text-align: left;
    overflow: auto;
  }

  .features-section {
    // Offset extra 16px padding on top of first link.
    // We keep the extra padding on the first link so
    // that the size of the tap highlight is consistent.
    margin-top: -16px;
    margin-bottom: 16px;

    a {
      display: block;
      font-size: 1.25rem;
      font-weight: 500;
      padding: 16px 0 16px 5%;
    }
  }

  .company-info-section {
    margin-bottom: 35px;
    padding: 0 5%;
    margin-left: -7px;

    a {
      display: inline-block;
      color: ${({ theme }) => theme.colors.primaryGray};
      font-size: 1rem;
      font-weight: 500;
      padding: 0 7px;
    }
  }

  .get-app-button {
    height: 52px;
    width: 90%;
    margin: 0 5%;
  }
`;

const LogoWrapper = ({
  signupFlow,
  children,
}: {
  signupFlow?: boolean;
  children: React.ReactNode;
}) => (
  <>
    {signupFlow ? (
      children
    ) : (
      <a href='https://www.albert.com/' title='Albert'>
        {children}
      </a>
    )}
  </>
);

const Navbar = (props: Props): React.ReactElement => {
  const SHRINK_HEADER = 'shrink';

  const { signupFlow, logoOnly } = props;

  // Mapped state
  const isLoggedIn = useSelector(app.isLoggedIn);

  const isHidden = useSelector((state: RootState) =>
    getSignupFlag(state, 'hideProgressBar')
  );

  // Local state
  const [shrinkHeader, setShrinkHeader] = React.useState('');
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);

  // Handlers
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  // Handle shrinking nav on scroll.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleOnScrollEvent = (e: any): void => {
    setShrinkHeader((prev: string) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      const scrollTop = e.target.documentElement?.scrollTop || 0;
      if (scrollTop > 100 && !prev) {
        return SHRINK_HEADER;
      }
      if (scrollTop <= 100 && prev) {
        return '';
      }
      return prev;
    });
  };

  // Handle pressing the escape key.
  const handleKeydown = (e: KeyboardEvent): void => {
    if (e.key === 'Escape' && isDropdownOpen) toggleDropdown();
  };

  // Scroll hook.
  React.useEffect(() => {
    window.addEventListener('keydown', handleKeydown);
    if (!signupFlow) {
      window.addEventListener('scroll', handleOnScrollEvent);
    }
    return () => {
      window.removeEventListener('keydown', handleKeydown);
      if (!signupFlow) {
        window.removeEventListener('scroll', handleOnScrollEvent);
      }
    };
  });

  React.useEffect(() => {
    if (isDropdownOpen) {
      // Disable scrolling on our <html> tag so that, when the dropdown
      // is displayed, we can't scroll the page beneath.
      document.documentElement.style.overflow = 'hidden';
    } else {
      document.documentElement.style.overflow = '';
    }
  }, [isDropdownOpen]);

  const getUrl = (url: string): string => getSplashUrl(url, window.albertWeb);

  return (
    <Wrapper signupFlow={props.signupFlow}>
      <Container {...props} shrink={shrinkHeader === SHRINK_HEADER}>
        <div className='col-nav-left'>
          <LogoWrapper signupFlow={signupFlow}>
            <AlbertLogo
              shrink={shrinkHeader === SHRINK_HEADER}
              height='1.2em'
            />
          </LogoWrapper>
        </div>

        <div
          className='col-nav-right'
          style={{
            visibility: isHidden ? 'hidden' : 'visible',
          }}
        >
          {/* Desktop */}
          {!logoOnly &&
            (signupFlow ? (
              <ProgressNavBar />
            ) : (
              <DesktopDropdown open={isDropdownOpen}>
                <button onClick={toggleDropdown}>
                  <Hamburger />
                </button>
                <div
                  role='presentation'
                  className={classNames('backdrop', {
                    enter: isDropdownOpen,
                  })}
                  onClick={toggleDropdown}
                />
                <div
                  className={classNames('dropdown', {
                    enter: isDropdownOpen,
                    exit: !isDropdownOpen,
                  })}
                >
                  <button className='close-icon' onClick={toggleDropdown}>
                    <Cross />
                  </button>
                  <div className='sections'>
                    <div className='features-section'>
                      <a href={getUrl('cash')}>Cash</a>
                      <a href={getUrl('instant')}>Instant</a>
                      <a href={getUrl('saving')}>Saving</a>
                      <a href={getUrl('investing')}>Investing</a>
                      <a href={getUrl('protect')}>Protect</a>
                      <a href={getUrl('budgeting')}>Budgeting</a>
                      <a href={getUrl('genius')}>Genius</a>
                    </div>
                    <div className='company-info-section'>
                      <a href={getUrl('about')}>About</a>
                      <a href={getUrl('careers')}>Careers</a>
                      <a href='https://help.albert.com'>Help</a>
                    </div>
                    <div className='app-links-section'>
                      <a
                        href='https://apps.apple.com/us/app/id1057771088'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        <img
                          src={`${staticUrl(
                            'img/splash/splash-2019-09/assets/app-store.png'
                          )}`}
                          alt='App Store'
                        />
                      </a>
                      <a
                        href='https://play.google.com/store/apps/details?id=com.meetalbert'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        <img
                          src={`${staticUrl(
                            'img/splash/splash-2019-09/assets/play-store.png'
                          )}`}
                          alt='Play Store'
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </DesktopDropdown>
            ))}
          {/* Mobile */}
          {!signupFlow && (
            <MobileDropdown open={isDropdownOpen}>
              <button onClick={toggleDropdown}>
                {isDropdownOpen ? <Cross /> : <Hamburger />}
              </button>
              <div className='dropdown'>
                <div className='features-section'>
                  <a href={getUrl('cash')}>Cash</a>
                  <a href={getUrl('instant')}>Instant</a>
                  <a href={getUrl('saving')}>Saving</a>
                  <a href={getUrl('investing')}>Investing</a>
                  <a href={getUrl('protect')}>Protect</a>
                  <a href={getUrl('budgeting')}>Budgeting</a>
                  <a href={getUrl('genius')}>Genius</a>
                </div>
                <div className='company-info-section'>
                  <a href={getUrl('about')}>About</a>
                  <a href={getUrl('careers')}>Careers</a>
                  <a href='https://help.albert.com'>Help</a>
                </div>
                {!isLoggedIn && (
                  <a
                    href={`https://app.albrt.co/download${window.location.search}`}
                  >
                    <Button className='get-app-button'>Get the app</Button>
                  </a>
                )}
              </div>
            </MobileDropdown>
          )}
        </div>
      </Container>
    </Wrapper>
  );
};

export default Navbar;
