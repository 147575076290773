import BasicModal from './BasicModal';
import BrandModal from './BrandModal';
import ConfirmCodeModal from './ConfirmCodeModal';
import ModalPage from './ModalPage';
import NavigationModal from './NavigationModal';

export default {
  Basic: BasicModal,
  Navigation: NavigationModal,
  Page: ModalPage,
  ConfirmCode: ConfirmCodeModal,
  Brand: BrandModal,
};
