import types from 'actions/types';
import AlbertClient from 'api/AlbertClient';
import * as WEB from 'types/interfaces';
import { onErrorDisplayDefaultBanner } from 'utils/axiosMiddlewareUtils';

export default (): WEB.ActionCreator<Promise<Record<string, any>>> =>
  async (dispatch: WEB.Dispatch): Promise<Record<string, any>> => {
    let error = false;
    const response = await dispatch({
      types: [
        types.GET_EMAIL_VERIFICATION_STATUS_REQUEST,
        types.GET_EMAIL_VERIFICATION_STATUS_SUCCESS,
        types.GET_EMAIL_VERIFICATION_STATUS_FAILURE,
      ],
      payload: {
        request: {
          method: 'get',
          url: AlbertClient.emailVerification(),
        },
        options: {
          onError: (middlewareOptions: any, options: any) => {
            error = true;
            return onErrorDisplayDefaultBanner(middlewareOptions, options);
          },
        },
      },
    });
    return { response, error };
  };
