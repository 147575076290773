import styled, { css } from 'styled-components';
import ZIndex from 'constants/ZIndex';
import { fontSizes, mixins } from 'styles';

const floatingLabelStyles = css`
  padding: 0 ${mixins.pxToRem('5px')};
  ${fontSizes.fontSize12}
  color: ${({ theme }) => theme.colors.darkGray1};
  width: auto !important;
  position: absolute;
  top: 55%;
  background-color: white;
  transform: translate(-5px, ${mixins.pxToRem('-30px')});
`;

// Display the label as placeholder text in the input
const normalLabelStyles = css`
  color: ${({ theme }) => theme.colors.primaryGray};
  transform: translate(0, 0);
`;

const FloatingLabel = styled.div<{ isFloating: boolean }>`
  ${({ isFloating }) => (isFloating ? floatingLabelStyles : normalLabelStyles)};
  transition: 0.2s;
  z-index: ${ZIndex.FLOATING_LABEL};
`;

export default FloatingLabel;
