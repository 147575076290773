/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import albertBuckdetMiddleware from 'middleware.albertBucket';
import albertTraceIDMiddleware from 'middleware.albertTraceID';
import routerRedirectMiddleware from 'middleware.routerRedirect';
import {
  Middleware,
  Store,
  applyMiddleware,
  compose,
  createStore,
} from 'redux';
import axiosMiddleware from 'redux-axios-middleware';
import { composeWithDevTools } from 'redux-devtools-extension';
import reduxThunk from 'redux-thunk';
import initialState from 'store.initialState';
import { v4 as uuidv4 } from 'uuid';
import NodeEnvironment from 'constants/NodeEnvironment';
import rootReducer from 'reducers/rootReducer';
import {
  onErrorDisplayDefaultBanner,
  onSuccess,
} from 'utils/axiosMiddlewareUtils';

const configureStore = (): Store => {
  const axiosClient = axios.create();

  const options = {
    interceptors: {
      request: [
        function (_: any, req: any) {
          const pageUrl = new URL(document.location.href);
          let split = req.url.split('#');
          const hash = split[1] ? split[1] : '';
          split = split[0].split('?');
          const query = split[1] ? split[1] : '';
          const base = split[0];
          req.url = `${base}?${query}${query ? '&' : ''}cb=${uuidv4()}&csrf=${
            window.albertWeb.CSRFMatch
          }${
            pageUrl.searchParams.get('refresh') != null ? '&refresh=1' : ''
          }#${hash}`;
          return req;
        },
      ],
    },
    onSuccess: (arg1: any, options: any) => {
      return onSuccess(arg1, options);
    },
    onError: (arg1: any, options: any) => {
      return onErrorDisplayDefaultBanner(arg1, options);
    },
    onComplete: (data: any) => {
      /* on CSRF failure, attempt to force refresh */
      if (data?.action?.error?.response?.status === 480) {
        const url = new URL(document.location.href);
        if (url.searchParams.get('refresh') == null) {
          url.searchParams.append('refresh', '1');
          window.location.replace(url.toString());
        }
      }
    },
  };
  const middlewareAxios = axiosMiddleware(axiosClient, options);

  const middlewares = applyMiddleware(
    reduxThunk,
    middlewareAxios,
    albertTraceIDMiddleware as Middleware<any>,
    routerRedirectMiddleware as Middleware<any>,
    albertBuckdetMiddleware as Middleware<any>
  );

  // Exclude redux dev tools in production
  const storeEnhancers =
    window.albertWeb.Environment === NodeEnvironment.PROD &&
    !window.albertWeb.IsLocal
      ? compose(middlewares)
      : composeWithDevTools(middlewares);

  const store = createStore(rootReducer, initialState, storeEnhancers);

  return store;
};

export default configureStore;
