import * as React from 'react';
import styled from 'styled-components';
import Spacer from 'components/common/Spacer';
import TextContent from 'components/layout/TextContent';
import { breakpoints } from 'styles';
import { spacers } from 'styles/home';

type Props = {
  header?: string;
  text?: string | string[];
  desktopHeaderSize?: 'small' | 'default';
  mobileHeaderSize?: 'small' | 'default';
  badgeText?: string;
  betweenComponent?: React.ReactElement | null;
};

export const Header = styled.h1<{
  desktopHeaderSize?: 'small' | 'default';
  mobileHeaderSize?: 'small' | 'default';
}>`
  margin: 0;
  font-size: ${({ desktopHeaderSize }) =>
    desktopHeaderSize === 'small' ? '1rem' : 'revert'};
  @media ${breakpoints.mobileLarge} {
    font-size: ${({ mobileHeaderSize }) =>
      mobileHeaderSize === 'small' ? '1.5rem' : 'revert'};
    }
  }
`;

/**
 * Layout component to handle the common case of displaying a header and optional lead text in signup flow
 */
const LeadContent = ({
  header = '',
  text = '',
  desktopHeaderSize,
  mobileHeaderSize,
  betweenComponent = null,
}: Props) => (
  <>
    <Header
      mobileHeaderSize={mobileHeaderSize}
      desktopHeaderSize={desktopHeaderSize}
    >
      {header}
    </Header>
    {betweenComponent}
    <Spacer space={spacers.g3} />
    {text && <TextContent text={text} />}
  </>
);

export default LeadContent;
