import * as React from 'react';

export function TrackSpendingIconV2() {
  return (
    <svg
      width='53'
      height='52'
      viewBox='0 0 53 52'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='26.4717' cy='26' r='23' fill='#D9E8FF' />
      <path
        d='M26.4717 49C13.7691 49 3.47168 38.7025 3.47168 26C3.47168 13.2975 13.7691 3 26.4717 3V49Z'
        fill='#48B1FE'
      />
      <path
        d='M6.47168 15.0476C10.3204 7.87204 17.8333 3 26.4712 3L26.4717 26L6.47168 15.0476Z'
        fill='#004DBF'
      />
      <path
        d='M36.535 15.9305C42.0953 21.4908 42.0953 30.5059 36.535 36.0662C30.9747 41.6265 21.9596 41.6265 16.3993 36.0662C14.748 34.4149 13.5871 32.459 12.9166 30.3793'
        stroke='#1A405C'
        strokeWidth='2'
        strokeLinecap='round'
      />
      <path
        d='M10.2782 32.5624L13.0942 29.2064L16.4502 32.0225'
        stroke='#1A405C'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
