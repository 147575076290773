/* eslint-disable @typescript-eslint/await-thenable */
import addBanner from 'actions/banner/addBanner';
import types from 'actions/types';
import AlbertClient from 'api/AlbertClient';
import BannerActionType from 'constants/BannerActionType';
import NotificationTypes from 'constants/NotificationTypes';
import ProductType from 'constants/ProductType';
import * as WEB from 'types/interfaces';
import {
  onError,
  onErrorDisplayDefaultBanner,
} from 'utils/axiosMiddlewareUtils';

/**
 * Verify a user's SSN.
 *
 * @param product Albert product requesting identity verification
 * @param ssn Social Security # of user
 * @param socureSessionId Socure device session id
 */
export default (
    product: ProductType,
    ssn: number | null,
    socureSessionId?: string
  ): WEB.ActionCreator<Promise<Record<string, any>>> =>
  async (dispatch: WEB.Dispatch): Promise<Record<string, any>> => {
    let error = false;

    // For KYC, all errors are handled via a modal that prompts users back to KYCConfirm.
    // Duplicate SSN in the signup flow is the only exception (in which case we show a banner).
    let showModal = true;

    const data = {
      product,
      ssn,
      device_session_id: socureSessionId,
    };

    const response = await dispatch({
      types: [
        types.KYC_IDENTITY_CHECK_REQUEST,
        types.KYC_IDENTITY_CHECK_SUCCESS,
        types.KYC_IDENTITY_CHECK_FAILURE,
      ],
      payload: {
        request: {
          method: 'post',
          url: AlbertClient.kycVerifyProfile(),
          data,
        },
        options: {
          onError: (middlewareOptions: any, options: any) => {
            const responseData = middlewareOptions.error?.response?.data || {};
            const buttonAction = responseData?.button_action;

            error = true;
            // If button action is LOGIN_APP, then show banner (only in signup flow).
            if (
              buttonAction &&
              product === ProductType.SIGNUP &&
              buttonAction === BannerActionType.LOGIN_APP
            ) {
              const bannerMessage =
                'It appears that you’ve already created an account with this identity. Log in on the app.';
              dispatch(
                addBanner(
                  NotificationTypes.WARNING,
                  bannerMessage,
                  false,
                  BannerActionType.LOGIN_APP_KYC
                )
              );
              showModal = false;
              return onError(middlewareOptions, options);
            }

            const responseStatus = middlewareOptions.error?.response?.status;

            // Display banner for 5xx errors, modals for 4xx errors.
            const shouldDisplayBanner =
              !responseStatus ||
              (typeof responseStatus === 'number' &&
                responseStatus > 499 &&
                responseStatus < 600);

            // Show error modal (redirecting back to KYCConfirm) if banner (5xx error) not shown.
            showModal = !shouldDisplayBanner;

            const errorHandler = shouldDisplayBanner
              ? onErrorDisplayDefaultBanner
              : onError;

            return errorHandler(middlewareOptions, options);
          },
        },
      },
    });

    return { response, error, showModal };
  };
