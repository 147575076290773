/* eslint-disable @typescript-eslint/no-explicit-any */
import { matchSorter } from 'match-sorter';
import * as React from 'react';
import styled, { css } from 'styled-components';
import ErrorText from 'components/common/ErrorText';
import { renderLabels } from 'components/common/Input/shared';
import FloatingLabel from 'components/common/Input/shared/FloatingLabel';
import Spacer from 'components/common/Spacer';
import Text, { TextColor, TextSizes } from 'components/common/Text';
import ArtMap, { Art } from 'constants/ArtMap';
import ZIndex from 'constants/ZIndex';
import { KeyboardEventKeys } from 'constants/index';
import useOutsideClickDetection from 'hooks/useOutsideClickDetection';
import { breakpoints, fontSizes, mixins, spacers } from 'styles';
import { DropdownItem as Item } from 'types/interfaces';

export type DropdownItem = {
  key: number;
  value: string | number;
  component: React.ReactElement;
};

export enum DropdownWidthSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  FULL = 'full',
}

export type Props = {
  /* unique id for the dropdown menu */
  id: string;
  /* main label text to display above dropdown */
  label?: string;
  /* small label to display next to main label */
  smallLabel?: string | React.ReactNode;
  /* whether to display the dropdown in full width */
  fullWidth?: boolean;
  /* width for the dropdown input and menu */
  widthSize?: DropdownWidthSize;
  /* whether to show error text */
  showError?: boolean;
  /* error text to display */
  errorText?: string;
  /* invalid state */
  invalid?: boolean;
  /* default value */
  defaultValue?: string;
  /* main description text to display below label */
  description?: React.ReactNode;
  /* floating label to display for dropdown */
  floatingLabel?: string;
  /* list of text items or Item objects to be rendered */
  items: (string | Item)[];
  /* the theme to use for this component */
  theme?: any;
  /* event for when an item is selected */
  onSelect?: (...args: any[]) => void;
  /* onOpen handler function */
  onOpen?: (...args: any[]) => void;
  /* onClose handler function */
  onClose?: (...args: any[]) => void;
  /* height of input */
  height?: string;
  hideLabelOnMobile?: boolean;
};

/* Apply common field styles to both the dropdown input and the expanded menu */
const fieldStyles = css`
  color: ${({ theme }) => theme.colors.primaryGray};
  border-color: ${({ theme }) => theme.colors.lightGray1};
  background-color: white;
  font-weight: normal;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  outline: none;
  transition: all 0.1s ease-in 0.1s;
`;

export const getWidth = (widthSize?: DropdownWidthSize) => {
  let width = '100%';
  if (widthSize === DropdownWidthSize.SMALL) {
    width = '55%';
  } else if (widthSize === DropdownWidthSize.MEDIUM) {
    width = '75%';
  } else if (widthSize === DropdownWidthSize.FULL) {
    width = '100%';
  }
  return width;
};

const DropdownContainer = styled.div<{
  widthSize?: DropdownWidthSize;
}>`
  position: relative;
  width: ${({ widthSize }) => getWidth(widthSize)};
  min-width: ${mixins.pxToRem('133px')};

  @media ${breakpoints.mobileLarge} {
    width: 100%;
  }
`;

export const StyledDropdown = styled.div`
  width: 100%;
  max-height: ${mixins.pxToRem('275px')};
  margin-top: ${mixins.pxToRem('12px')};
  ${fieldStyles}
  z-index: ${ZIndex.DROPDOWN};
  position: absolute;
  height: auto;
  overflow-y: scroll;
  transition: all 1s ease-in 1s;
`;

const StyledDropdownInput = styled.div<{
  theme?: any;
  invalid?: boolean;
  height?: string;
}>`
  div {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  height: ${({ height }) => height || mixins.pxToRem('43px')};
  padding: ${mixins.pxToRem('15px')};
  ${fieldStyles}
  ${({ theme, invalid }) =>
    invalid && `border-color: ${theme.colors.primaryError}`};
  ${({ invalid }) => invalid && `border-width: 2.5px`};
  white-space: nowrap;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  font-style: normal;
  svg {
    width: ${mixins.pxToRem('24px')};
    margin-right: ${mixins.pxToRem('-5px')};
    color: ${({ theme }) => theme.colors.darkGray1};
  }
  &:hover,
  &.active {
    svg {
      color: ${({ theme }) => theme.colors.primaryText};
    }
  }
  ${({ theme, invalid }) =>
    !invalid &&
    `&:hover, &.active {
      border-color: ${theme.colors.primaryAlbertBrand};
      box-shadow: 0 0 0 1px ${theme.colors.primaryAlbertBrand};
    }`}
  @media ${breakpoints.mobileLarge} {
    min-width: ${mixins.pxToRem('100px')};
  }
`;

const StyledChevronButton = styled.button`
  background-color: transparent;
  padding: 0;
  outline: none;
  border: none;
  cursor: pointer;
  margin-left: 15px;
`;

export const StyledText = styled(Text)`
  ${fontSizes.fontSize14}
  font-weight: normal;
`;

export const StyledDropdownItem = styled.div<{
  isActive: boolean;
  hovering: boolean;
  hasSubValues?: boolean;
}>`
  min-height: ${mixins.pxToRem('50px')};
  padding-left: ${mixins.pxToRem('25px')};
  padding-right: ${mixins.pxToRem('25px')};
  ${({ hasSubValues }) =>
    hasSubValues &&
    `
    padding-top: ${mixins.pxToRem('14px')};
    padding-bottom: ${mixins.pxToRem('14px')};
  `};
  outline: none;
  display: ${({ hasSubValues }) => (hasSubValues ? 'block' : 'flex')};
  align-items: center;
  justify-content: start;
  width: 100%;
  cursor: pointer;
  ${({ theme, isActive, hovering }) =>
    isActive && !hovering && `background-color: ${theme.colors.lightGray3}`};
  &:hover {
    background-color: ${({ theme }) => theme.colors.lightGray3};
  }
`;

const Dropdown = (props: Props): React.ReactElement => {
  const {
    id,
    items = [],
    floatingLabel,
    label,
    invalid,
    errorText,
    widthSize,
    showError,
    description,
    defaultValue,
    onSelect,
    onOpen,
    onClose,
    height,
    hideLabelOnMobile,
    ...otherProps
  } = props;
  // Refs
  const dropdownContainerRef = React.useRef<HTMLDivElement>(null);
  const menuRef = React.useRef<HTMLDivElement>(null);
  const currElement = menuRef?.current && menuRef.current;

  let hasSubValues = false;

  // Convert items to a list of objects if they are strings
  const itemsFormatted: Item[] = items.map((item) => {
    if (typeof item === 'string') return { value: item };
    if (item.subValue) {
      hasSubValues = true;
    }
    return item;
  });

  // Dropdown list item refs (to scroll active item into view)
  const listItemRefs = React.useRef<React.RefObject<HTMLDivElement>[]>(
    [...Array(itemsFormatted.length)].map(() =>
      React.createRef<HTMLDivElement>()
    )
  );

  // "null" means that no value been selected and there is no default value
  const defaultIndex = defaultValue
    ? itemsFormatted.findIndex((item) => item.value === defaultValue)
    : null;

  const defaultSelectedItem =
    defaultValue && defaultIndex !== null && defaultIndex >= 0
      ? { key: defaultIndex, value: defaultValue }
      : null;

  const [search, setSearch] = React.useState('');
  const [isOpen, setOpen] = React.useState<boolean>(false);
  const [selectedItem, setSelectedItem] =
    React.useState<any>(defaultSelectedItem);
  const [activeItem, setActiveItem] = React.useState<number | null>(
    defaultIndex
  );
  const [hovering, setHovering] = React.useState(false);
  const [isToggleFocused, setIsToggleFocused] = React.useState(false);

  const handleCloseDropdown = () => {
    setOpen(false);
    onClose && onClose();
  };

  const handleOpenDropdown = () => {
    if (currElement) currElement.focus();
    setOpen(true);
    onOpen && onOpen();
  };

  const toggleDropdown = () => {
    if (isOpen) {
      handleCloseDropdown();
    } else {
      handleOpenDropdown();
    }
  };

  const handleSelect = (key: number, value: string | number) => {
    handleCloseDropdown();
    setSelectedItem({ key, value });
    setActiveItem(key);
    onSelect && onSelect({ key, value });
    if (hovering) {
      setHovering(false);
    }
  };

  const handleDropdownToggleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsToggleFocused(true);
  };
  const handleDropdownToggleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsToggleFocused(false);
  };

  const toggleHovering = () => setHovering(!hovering);

  /* Create a list of "key-able" dropdown items */
  const dropdownItems: DropdownItem[] = itemsFormatted.map(
    ({ value, subValue }, key) => {
      const component = (
        <StyledDropdownItem
          ref={listItemRefs.current[key]}
          isActive={key === activeItem}
          key={key}
          onClick={() => handleSelect(key, value)}
          hovering={hovering}
          hasSubValues={hasSubValues}
        >
          <StyledText>{value}</StyledText>
          {subValue && (
            <>
              <Spacer space={spacers.microscopic} />
              <Text size={TextSizes.SMALL} color={TextColor.GRAY}>
                {subValue}
              </Text>
            </>
          )}
        </StyledDropdownItem>
      );
      return {
        key,
        value,
        component,
      };
    }
  );

  // Scroll list item into view
  const scrollIntoView = (index: number) => {
    const itemRef = listItemRefs.current[index];
    itemRef?.current?.scrollIntoView({ block: 'nearest' });
  };

  /* Listen for keyboard events to go to the next element in the list */
  const onKeyDown = React.useCallback(
    (event: KeyboardEvent) => {
      const element = menuRef?.current;
      if (element) {
        element.focus();
        // Prevent default scroll (scrolling entire page)
        event.preventDefault();

        if (event.key === KeyboardEventKeys.UP) {
          if (activeItem !== 0) {
            const value = activeItem === null ? 0 : activeItem - 1;
            scrollIntoView(value);
            setActiveItem(value);
          }
        } else if (event.key === KeyboardEventKeys.DOWN) {
          const size = itemsFormatted.length;
          if (activeItem === null) {
            setActiveItem(0);
          } else if (activeItem + 1 < size) {
            const value = activeItem + 1;
            scrollIntoView(value);
            setActiveItem(activeItem + 1);
          }
        } else if (event.key === KeyboardEventKeys.ENTER) {
          const foundItem = dropdownItems.find(
            (d: DropdownItem) => d.key === activeItem
          );
          let foundItemIndex = null;
          if (foundItem) {
            const { key, value } = foundItem;
            foundItemIndex = key;
            handleSelect(key, value);
          }
          handleCloseDropdown();
        } else if (event.key === KeyboardEventKeys.ESC) {
          handleCloseDropdown();
        } else if (/^[0-9a-zA-Z]+$/.exec(event.key)) {
          // Only search on alphanumeric characters.
          setSearch((prevSearch: string) => prevSearch + event.key);
        }
      }
    },
    [activeItem]
  );

  // Handle dropdown item search.
  React.useEffect(() => {
    if (search && isOpen) {
      const searchResults = matchSorter(itemsFormatted, search, {
        keys: ['value'],
      });
      const newItemIndex = searchResults.length
        ? itemsFormatted.findIndex(
            (item) => item.value === searchResults[0]?.value || null
          )
        : 0;

      // Set active item and scroll into view.
      setActiveItem(newItemIndex);
      scrollIntoView(newItemIndex);

      // Reset search after 1 second.
      const timeout = setTimeout(() => {
        setSearch('');
      }, 1000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [search]);

  // Handle necessary state changes as a result of isOpen and selectedItem change
  React.useEffect(() => {
    // Scroll active item into view
    if (isOpen && activeItem !== null) {
      scrollIntoView(activeItem);
    }
    // Always have selected item and active item in sync
    if (selectedItem && selectedItem.key !== activeItem) {
      setActiveItem(selectedItem.key);
    }
  }, [isOpen, selectedItem]);

  // Handle adding and removing keydown event listeners
  React.useEffect(() => {
    document.addEventListener('keydown', onKeyDown);
    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [activeItem]);

  useOutsideClickDetection(dropdownContainerRef, handleCloseDropdown, isOpen);

  return (
    <div {...otherProps} className='al-dropdown-field'>
      {renderLabels(props)}
      <DropdownContainer
        ref={dropdownContainerRef}
        widthSize={widthSize}
        className='al-dropdown input-wrapper'
      >
        <StyledDropdownInput
          role='listbox'
          onClick={toggleDropdown}
          invalid={invalid}
          onFocus={handleDropdownToggleFocus}
          onBlur={handleDropdownToggleBlur}
          className={isToggleFocused ? 'active' : ''}
          height={height}
        >
          {selectedItem && (
            <Text size={TextSizes.MEDIUM} color={TextColor.BLACK}>
              {selectedItem.value}
            </Text>
          )}
          {floatingLabel && (
            <FloatingLabel
              className='al-floating-label'
              isFloating={!!selectedItem}
            >
              {floatingLabel}
            </FloatingLabel>
          )}

          <StyledChevronButton type='button'>
            <img
              src={ArtMap(isOpen ? Art.ChevronUp : Art.ChevronDown)}
              alt='chevron'
            />
          </StyledChevronButton>
        </StyledDropdownInput>
        {isOpen && (
          <StyledDropdown
            id={`al-dropdown-menu-${id}`}
            ref={menuRef}
            className='al-dropdown-menu'
            onMouseEnter={toggleHovering}
            onMouseLeave={toggleHovering}
          >
            {dropdownItems.map((item) => item.component)}
          </StyledDropdown>
        )}
      </DropdownContainer>
      {invalid && errorText && <ErrorText name={id} text={errorText} />}
    </div>
  );
};

export default Dropdown;
