import addBanner from 'actions/banner/addBanner';
import types from 'actions/types';
import AlbertClient from 'api/AlbertClient';
import NotificationTypes from 'constants/NotificationTypes';
import * as WEB from 'types/interfaces';
import { onErrorDisplayDefaultBanner } from 'utils/axiosMiddlewareUtils';

export default (
    source: 'signup',
    includeBanner = false
  ): WEB.ActionCreator<Promise<Record<string, any>>> =>
  async (dispatch: WEB.Dispatch): Promise<Record<string, any>> => {
    if (includeBanner) {
      const bannerBody = 'Verification email sent.';
      dispatch(addBanner(NotificationTypes.SUCCESS, bannerBody, true));
    }

    let error = false;
    const response = await dispatch({
      types: [
        types.SEND_EMAIL_VERIFICATION_LINK_REQUEST,
        types.SEND_EMAIL_VERIFICATION_LINK_SUCCESS,
        types.SEND_EMAIL_VERIFICATION_LINK_FAILURE,
      ],
      payload: {
        request: {
          method: 'post',
          url: AlbertClient.emailVerification(),
          data: {
            source,
          },
        },
        options: {
          onError: (middlewareOptions: any, options: any) => {
            error = true;
            return onErrorDisplayDefaultBanner(middlewareOptions, options);
          },
        },
      },
    });
    return { response, error };
  };
