import { useSelector } from 'react-redux';
import { AccountCategory } from 'constants/';
import * as appData from 'reducers/entities/appData';
import * as profile from 'reducers/entities/profile';
import * as WEB from 'types/interfaces';

/**
 * Get a list of the bank accounts that have been linked.
 * In the 'SelectAcount' component, this is used to display the list of linked accounts the user can select from.
 * In the 'LinkAccountFlow' component, this is used to check if the users have linked accounts and decide where to redirect them.
 */
function useAccountsToDisplay() {
  // TODO in the future: create a new selector that gets all bank accounts for savings
  const savingsAccountId: number | null = useSelector(
    appData.getSavingsAccountId
  );

  const linkedAccounts: WEB.InstitutionAccount[] = useSelector(
    appData.getAllAccounts
  );
  const isActiveCheckingRequired = useSelector((state: WEB.RootState) =>
    profile.getValueByField(state, 'is_active_checking_required')
  );
  let hasInactiveCheckingAccount = false;

  const checkingAccounts = linkedAccounts
    .filter((acc: WEB.InstitutionAccount) => {
      // Accounts that can be linked to Albert Savings (in the app)
      // - not cash accounts, because they're new accounts. We want users to
      //   select an account with transactions.
      let isValid =
        !acc.is_manual &&
        acc.active &&
        acc.id !== savingsAccountId &&
        !acc.is_albert_cash &&
        acc.account_category === AccountCategory.CHECKING;
      if (isValid && isActiveCheckingRequired && !acc.is_active) {
        hasInactiveCheckingAccount = true;
        isValid = false;
      }
      return isValid;
    })
    .sort((a: WEB.InstitutionAccount, b: WEB.InstitutionAccount) => {
      return b.balance - a.balance;
    });

  // Only show accounts with account and routing if at least one of them has it.
  const accountsWithNumbers = checkingAccounts.filter(
    (acc: WEB.InstitutionAccount) => {
      return acc.has_account_routing;
    }
  );

  const accountsToDisplay =
    accountsWithNumbers.length > 0 ? accountsWithNumbers : checkingAccounts;
  return { accountsToDisplay, hasInactiveCheckingAccount };
}

export default useAccountsToDisplay;
