import * as React from 'react';
import styled, { css } from 'styled-components';
import { textTransition } from 'components/common/Text';

type Props = {
  $variant?: 'monochrome-icon' | 'color-icon';
  /* the state of the card */
  selected?: boolean | false;
  height?: string;
  width?: string;
  image?: string;
  /* the theme to use for this component */
  theme?: any;
  /* tab index to set so card can be accessed using tab key */
  tabIndex?: number;
  /* onClick handler function */
  onClick?: (...args: any) => any;
  children: React.ReactNode;
};

const cardStyles = css<Props>`
  padding: 20px;
  display: flex;
  height: ${({ height }) => height || '148px'};
  width: ${({ width }) => width || '148px'};
  flex-direction: column;
  justify-content: ${({ $variant = 'monochrome-icon' }) =>
    $variant === 'monochrome-icon' ? 'space-between' : 'space-around'};
  };
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  &:hover {
    cursor: pointer;
  }
  .card-subtext {
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colors.primaryGray};
  }
`;

const FilledSquareCard = styled.div<Props>`
  ${cardStyles}
  path,
  line,
  circle {
    ${textTransition};
  }
  ${textTransition};
  ${({ theme, selected, $variant = 'monochrome-icon' }) => {
    if (selected && $variant === 'monochrome-icon') {
      return `
        background-color: ${theme.colors.primaryAlbertBrand};
        span {
          color: ${theme.colors.white};
        }
        .card-subtext {
          color: rgba(255, 255, 255, 0.6);
        }
        path {
          fill: ${theme.colors.white};
        }
        path.square-card-filled-check-stroke {
          fill: inherit;
          stroke: ${theme.colors.white};
        }`;
    }

    if (selected) {
      return `
        outline: 3px solid ${theme.colors.primaryText};
      `;
    }

    return '';
  }};

  // Media query prevents issues with hover state sticking on touchscreens
  @media (hover: hover) {
    &:hover {
      cursor: pointer;

      ${({ theme, $variant = 'monochrome-icon' }) => {
        if ($variant === 'monochrome-icon') {
          return `
            background-color: ${theme.colors.primaryAlbertBrand};
            border-color: ${theme.colors.primaryAlbertBrand};
            span {
              color: ${theme.colors.white};
            }
            .card-subtext {
              color: rgba(255, 255, 255, 0.6);
            }

            path {
              fill: ${theme.colors.white};
            }

            path.square-card-filled-check-stroke {
              fill: inherit;
              stroke: ${theme.colors.white};
            }
          `;
        }

        return `
          outline: 3px solid ${theme.colors.primaryText};
        `;
      }}
  }
`;

const BorderSquareCard = styled.div<Props>`
  ${cardStyles}
  ${({ theme, selected }) =>
    selected &&
    `
      border: 3px solid ${theme.colors.primaryText};
    `};
  // Media query prevents issues with hover state sticking on touchscreens
  @media (hover: hover) {
    &:hover {
      border: ${({ theme }) => `3px solid ${theme.colors.primaryText}`};
    }
  }
`;

export { FilledSquareCard, BorderSquareCard };
