import * as React from 'react';
import { Form } from 'react-final-form';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import emailConfirmCode from 'actions/async/emailConfirmCode';
import submitConfirmCode from 'actions/async/submitConfirmCode';
import textConfirmCode from 'actions/async/textConfirmCode';
import initializeApp from 'actions/initializeApp';
import setLoggedIn from 'actions/setLoggedIn';
import types from 'actions/types';
import Button from 'components/common/Button';
import Input from 'components/common/Input';
import Spacer from 'components/common/Spacer';
import Text, { TextColor, TextSizes } from 'components/common/Text';
import Tooltip from 'components/common/Tooltip';
import QuestionCircleIcon from 'components/icons/QuestionCircleIcon';
import ButtonsContainer from 'components/layout/ButtonsContainer';
import PageBase from 'components/layout/PageBase';
import { breakpoints, spacers } from 'styles';
import { sizes } from 'styles/breakpoints';
import { spacers as homeSpacers } from 'styles/home';
import { LocationState } from 'types/locationstate';

const InlineContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${homeSpacers.g8};
`;

const DesktopTooltipContainer = styled.div`
  display: block;
  @media ${breakpoints.mobileLarge} {
    display: none;
  }
`;

const MobileTooltipContainer = styled.div`
  display: none;
  @media ${breakpoints.mobileLarge} {
    display: block;
  }
`;

const VerifyButton = styled(Button)`
  @media (min-width: ${sizes.mobileLarge}) {
    max-width: 252px;
  }
`;

const StyledEmailCTAContainer = styled(Text)`
  display: flex;
  margin-top: ${homeSpacers.g7};
  @media ${breakpoints.mobileLarge} {
    flex-direction: column;
    text-align: center;
  }
`;

const TwoFAConfirmCode = ({
  mobileHeader,
}: {
  mobileHeader: string;
}): React.ReactElement => {
  // ///////////////////////////////
  /* =========== HOOKS ========== */
  // ///////////////////////////////
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const locationState = (location?.state || {}) as LocationState;

  // ///////////////////////////////
  /* =========== STATE ========== */
  // ///////////////////////////////
  const [verifying, setVerifying] = React.useState(false);
  const [confirmCode, setConfirmCode] = React.useState('');

  // Send initial confirm code on component mount.
  React.useEffect(() => {
    dispatch(setLoggedIn(true));
    // Send 2FA confirm code on component mount only if location.state.sendCode is true.
    // ie. Only send code on mount if being redirected from a request flow (login).
    if (locationState?.sendCode) {
      dispatch(textConfirmCode());

      // Delete locationState.sendCode so text is only sent once.
      const newLocationState = { ...locationState };
      delete newLocationState.sendCode;
      navigate({ ...location }, { state: newLocationState, replace: true });
    }
  }, []);

  // //////////////////////////////////
  /* =========== HANDLERS ========== */
  // //////////////////////////////////
  const handleOnVerify = async () => {
    // Disable verify button.
    setVerifying(true);
    const isError: any = await dispatch(submitConfirmCode(confirmCode));

    if (!isError) {
      // If confirm code was valid, initialize app --> will redirect to appropriate page.
      dispatch({ type: types.TWOFA_COMPLETE });
      dispatch(initializeApp(navigate, true));
    } else {
      setVerifying(false);
    }
  };

  const handleResendTextCode = () => {
    dispatch(textConfirmCode(true));
  };

  const handleSendEmailCode = () => {
    dispatch(emailConfirmCode());
  };

  // Triggered when user fills all code input fields.
  const handleCodeOnComplete = (value: string) => {
    setConfirmCode(value);
  };

  // //////////////////////////////////
  /* =========== LANGUAGE ========== */
  // //////////////////////////////////

  const NUM_CODE = 4; // Number of code input fields.
  const header = 'Enter confirmation code to sign in';
  const description =
    "We've detected that you're logging in from a new device. " +
    'To proceed, confirm the 4-digit code we sent to you.';

  const resendCode = 'Resend code';
  const tooltipText =
    'Didn’t receive a confirmation code? We can send you another code.';

  const buttonText = 'Verify';
  const footerText = 'Has your phone number changed?';
  const altLinkText = 'Send code by email';

  return (
    <>
      <PageBase mobileHeader={header}>
        <Form
          onSubmit={handleOnVerify}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Input.Code
                id='code-input'
                label={header}
                description={description}
                hideLabelOnMobile
                fields={NUM_CODE}
                onChange={handleCodeOnComplete}
              />
              <ButtonsContainer>
                <InlineContainer>
                  <Text
                    nowrap
                    pointer
                    weight='700'
                    size={TextSizes.SMALL}
                    onClick={handleResendTextCode}
                    isLinkButton
                  >
                    <u>{resendCode}</u>
                    &nbsp;
                  </Text>
                  &nbsp;
                  <QuestionCircleIcon
                    data-tip={tooltipText}
                    data-for='resend-code-tooltip'
                  />
                  <DesktopTooltipContainer>
                    <Tooltip id='resend-code-tooltip' place='top' />
                  </DesktopTooltipContainer>
                  <MobileTooltipContainer>
                    <Tooltip id='resend-code-tooltip' place='bottom' />
                  </MobileTooltipContainer>
                </InlineContainer>
                <Spacer space={spacers.tab} orientation='horizontal' />
                <VerifyButton
                  stretch
                  id='verify-code-button'
                  disabled={
                    verifying || !confirmCode || confirmCode.length < NUM_CODE
                  }
                  isLoading={verifying}
                >
                  {buttonText}
                </VerifyButton>
              </ButtonsContainer>
              <Spacer space={spacers.tab} desktopOnly />
              <StyledEmailCTAContainer>
                <Text color={TextColor.GRAY_DARK} size={TextSizes.SMALL}>
                  {footerText}&nbsp;
                </Text>
                <Text
                  size={TextSizes.SMALL}
                  underline
                  inline
                  color={TextColor.GRAY_DARK}
                  weight='700'
                  isLinkButton
                  onClick={handleSendEmailCode}
                >
                  {altLinkText}
                </Text>
              </StyledEmailCTAContainer>
            </form>
          )}
        />
      </PageBase>
    </>
  );
};

export default TwoFAConfirmCode;
