import types from 'actions/types';
import AlbertClient from 'api/AlbertClient';
import * as WEB from 'types/interfaces';
import { onErrorDisplayDefaultBanner } from 'utils/axiosMiddlewareUtils';

export interface UpdateSavingsAccountFields {
  // SavingsSetup
  transfer_interval?: string;
  recurring_transfer_amount?: number;
  agreed_to_coastal_terms?: boolean;
}

export default (
    fieldsToUpdate: UpdateSavingsAccountFields
  ): WEB.ActionCreator<Promise<boolean>> =>
  async (dispatch: WEB.Dispatch): Promise<boolean> => {
    let error = false;

    await dispatch({
      types: [
        types.UPDATE_SAVINGS_SETUP_REQUEST,
        types.UPDATE_SAVINGS_SETUP_SUCCESS,
        types.UPDATE_SAVINGS_SETUP_FAILURE,
      ],
      payload: {
        request: {
          method: 'put',
          url: AlbertClient.savingsAccountView(),
          data: {
            ...fieldsToUpdate,
          },
        },
        options: {
          onError: (middlewareOptions: any, options: any) => {
            error = true;
            return onErrorDisplayDefaultBanner(middlewareOptions, options);
          },
        },
      },
    });

    return error;
  };
