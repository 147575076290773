import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import TrackingBase from 'tracking.Base';
import createSavingsAccount from 'actions/async/createSavingsAccount';
import getAppData from 'actions/async/getAppData';
import addBanner from 'actions/banner/addBanner';
import removeBanner from 'actions/banner/removeBanner';
import BannerPane from 'components/BannerPane';
import Button from 'components/common/Button';
import Modal from 'components/common/Modal';
import RectangleCard from 'components/common/RectangleCard';
import Spacer from 'components/common/Spacer';
import Text, { TextSizes } from 'components/common/Text';
import ButtonsContainer from 'components/layout/ButtonsContainer';
import ContentPane, { ContentContainer } from 'components/layout/ContentPane';
import InfoPane from 'components/layout/InfoPane';
import PageBase from 'components/layout/PageBase';
import TwoPaneWrapper from 'components/layout/TwoPaneWrapper';
import {
  AlbertTrackingEvent,
  ComponentSize,
  ErrorMessages,
  NotificationTypes,
  PageRoutes,
  Pages,
  ResponseStatus,
} from 'constants/';
import useAccountsToDisplay from 'hooks/useAccountsToDisplay';
import Navbar from 'pages/unauthenticated/Navbar';
import * as language from 'reducers/entities/language';
import * as profile from 'reducers/entities/profile';
import { breakpoints, fontSizes, mixins, spacers } from 'styles';
import * as WEB from 'types/interfaces';
import { delayWithState } from 'utils/delay';
import {
  getLanguageButtons,
  getLanguageInfo,
  getLanguageSection,
} from 'utils/getFromLanguage';
import { logger } from 'utils/logger';
import { getIconSrc } from 'utils/merchant';

type Props = {
  fromSignup: boolean;
  onSubmit: () => void;
  onSkip: () => void;
  restartFlow: (shouldReplace?: boolean, message?: string) => void;
};

const StyledCard = styled(RectangleCard)`
  img {
    max-width: ${mixins.pxToRem('40px')};
    min-width: ${mixins.pxToRem('40px')};
  }
  header {
    ${fontSizes.fontSize18}
  }
  @media ${breakpoints.mobileLarge} {
    width: 100%;
  }
`;

const DesktopOnlyHeader = styled.h2`
  @media ${breakpoints.mobileLarge} {
    display: none;
  }
`;

const LinkWrapper = styled.div`
  margin-right: ${spacers.tab};
  @media ${breakpoints.mobileLarge} {
    margin-right: 0;
  }
`;

const SelectAccount = (props: Props): React.ReactElement | null => {
  // ///////////////////////////////
  /* =========== HOOKS ========== */
  // ///////////////////////////////
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // ///////////////////////////////
  /* =========== STATE ========== */
  // ///////////////////////////////
  const [showModal, setShowModal] = React.useState(false);
  const [highestBalance, setHighestBalance] = React.useState(0);
  const [selectedAccount, setSelectedAccount] =
    React.useState<WEB.InstitutionAccount | null>(null);
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [isCompleted, setIsCompleted] = React.useState<boolean>(false);

  // ///////////////////////////////
  /* ======== MAPPED STATE ====== */
  // ///////////////////////////////
  const languageCtx = useSelector((state: WEB.RootState) =>
    language.getSignupPageLanguage(state, Pages.SELECT_ACCOUNT)
  );
  const isActiveCheckingRequired = useSelector((state: WEB.RootState) =>
    profile.getValueByField(state, 'is_active_checking_required')
  );

  const { accountsToDisplay, hasInactiveCheckingAccount } =
    useAccountsToDisplay();

  if (!accountsToDisplay.length) {
    props.restartFlow(
      true,
      hasInactiveCheckingAccount
        ? ErrorMessages.linkAccount.checkingNoActivity
        : ErrorMessages.linkAccount.checkingNotFound
    );
  }

  // Select the first account by default
  React.useEffect(() => {
    if (accountsToDisplay.length) {
      // Track first successful account add.
      TrackingBase.track({
        event: AlbertTrackingEvent.ADDED_ACCOUNT,
        trackSocial: true,
        trackOnce: true,
      });
      const maxBalance = accountsToDisplay.reduce(
        (result: number, current: WEB.InstitutionAccount) => {
          if (current.balance > result) {
            return current.balance;
          }
          return result;
        },
        0
      );
      setHighestBalance(maxBalance);
      setSelectedAccount(accountsToDisplay[0]);
    }
  }, []);

  // //////////////////////////////////
  /* =========== HANDLERS ========== */
  // //////////////////////////////////
  const handleProceedWithAccount = async () => {
    // Sanity check to make sure we proceed only if account selected
    if (selectedAccount === null) return;

    // Determine if we need to take them to manual auth flow
    if (!selectedAccount?.has_account_routing) {
      const locationState = {
        fromSignup: true,
        accountId: selectedAccount.id,
      };

      navigate(
        {
          pathname: `../${PageRoutes.SIGNUP_MANUAL_AUTH}`,
          search: window.location.search,
        },
        {
          state: locationState,
        }
      );
      return;
    }

    try {
      setIsSubmitting(true);

      // Create savings account.
      const payload = {
        setup_type: 'auto_with_existing',
        account_id: selectedAccount?.id.toString(),
      };
      const { response, error }: any = await dispatch(
        createSavingsAccount(payload)
      );

      const { status, message } = response?.payload?.data || {};

      if (status === ResponseStatus.ERROR) {
        // Display error if for some reason an error occurred (not very likely)
        dispatch(addBanner(NotificationTypes.WARNING, message, true));
        throw new Error();
      } else if (error) {
        throw new Error();
      }

      TrackingBase.track({
        event: AlbertTrackingEvent.ENABLED_SAVINGS,
        trackSocial: true,
        trackOnce: true,
      });

      // Fetch app data.
      await dispatch(getAppData());

      // Success
      setIsSubmitting(false);
      await delayWithState(400, setIsCompleted);

      // Navigate to next page
      props.onSubmit();
    } catch (e) {
      setIsSubmitting(false);
    }
  };

  const handleOnNext = () => {
    const LIMIT = 200; // dollars
    const selectedAccountBalance = selectedAccount?.balance || 0;

    // Remove any error banner from previous attempts
    dispatch(removeBanner());

    // Present modal if user selected account with < $200 but has other accounts with more
    if (
      selectedAccountBalance < LIMIT &&
      highestBalance !== selectedAccountBalance
    ) {
      setShowModal(true);
    } else {
      handleProceedWithAccount().catch(logger.error);
    }
  };

  const handleOnAccountClick = (value: WEB.InstitutionAccount) => {
    setSelectedAccount(value);
  };

  // //////////////////////////////////
  /* =========== LANGUAGE ========== */
  // //////////////////////////////////
  const ACCOUNT_KEY = 'account';

  const accountSection = getLanguageSection(languageCtx, ACCOUNT_KEY);
  const infoPaneContext = getLanguageInfo(languageCtx);
  const buttons = getLanguageButtons(languageCtx);

  // //////////////////////////////////
  /* ========= MODAL CONFIG ======== */
  // //////////////////////////////////
  const modalDescription =
    'This account has a low balance. We recommend selecting an account with a higher balance.';

  const toggleModal = React.useCallback(() => {
    setShowModal(false);
  }, []);

  const handleOnSubmit = React.useCallback(async () => {
    // Open Plaid Link with institution
    await handleProceedWithAccount().catch(logger.error);
    setShowModal(false);
  }, [selectedAccount]);

  const modalConfig = {
    id: 'select-account-low-balance-modal',
    title: 'Low balance',
    description: modalDescription,
    iconType: 'WARNING',
    cancelLabel: 'Go back',
    submitLabel: 'Use anyway',
    onCancel: toggleModal,
    onSubmit: handleOnSubmit,
    show: showModal,
    isSubmitting,
    isCompleted,
  };

  // //////////////////////////////////
  /* ===== ACCOUNTS TO DISPLAY ===== */
  // //////////////////////////////////
  // const cardComponents = accountsToDisplay.map(
  const cardComponents = accountsToDisplay.map(
    (acc: WEB.InstitutionAccount) => {
      const ins = acc?.institution_login?.institution;
      const imgSrc = getIconSrc({
        imageName: ins?.image_name,
        logo: ins?.logo,
      });

      return (
        <StyledCard
          key={`account-${acc?.id}`}
          size={ComponentSize.SMALL}
          choice='single'
          value={acc}
          image={imgSrc}
          onClick={handleOnAccountClick}
          header={acc?.account_nickname}
          body={`Balance: $${acc?.balance?.toLocaleString()}`}
          selected={selectedAccount?.id === acc?.id}
        />
      );
    }
  );

  // TODO (nikita): Currently there's an issue when clicking the browser back button
  //    on this page when this hook exists. The page properly navigates back to FindInstitution,
  //    but the page being tracking by Tracking in history.listen in the App component
  //    thinks that the user is 2 pages back (rather than 1 page back). Will revisit this.
  // useBrowserNavigationOverrideWithRedirect(props.restartFlow);

  return (
    <>
      <Navbar split signupFlow={props.fromSignup} />
      <TwoPaneWrapper>
        <InfoPane.Desktop {...infoPaneContext} />
        <ContentPane>
          <BannerPane />
          <ContentContainer>
            <PageBase mobileHeader={infoPaneContext?.header}>
              <Modal.Basic {...modalConfig} />
              <DesktopOnlyHeader>{accountSection?.header}</DesktopOnlyHeader>
              <div className='description'>
                <Text>{accountSection?.text}</Text>
              </div>
              <Spacer space={spacers.tiny} />
              {cardComponents}
              <ButtonsContainer>
                {buttons.link && (
                  <LinkWrapper className='secondary-button'>
                    <Text
                      weight='700'
                      size={TextSizes.SMALL}
                      underline
                      isLinkButton
                      onClick={() => props.restartFlow()}
                    >
                      <a
                        className='add-accounts-link'
                        style={{ whiteSpace: 'nowrap' }}
                      >
                        {buttons?.link}
                      </a>
                    </Text>
                  </LinkWrapper>
                )}
                <Button
                  id='select-account-confirm'
                  onClick={handleOnNext}
                  isCompleted={isCompleted && !showModal}
                  isLoading={isSubmitting && !showModal}
                  disabled={isSubmitting || !selectedAccount}
                >
                  {isCompleted ? buttons?.complete : buttons?.primary}
                </Button>
              </ButtonsContainer>
            </PageBase>
          </ContentContainer>
        </ContentPane>
      </TwoPaneWrapper>
    </>
  );
};

export default SelectAccount;
