enum DebitCardStyle {
  // Blue style for debit card.
  BLUE = 'b',
  // White style for debit card.
  WHITE = 'w',
  // Genius style (black) for debit card.
  GENIUS = 'g',
}

export enum VirtualCardConfigId {
  BLUE = 'cardblue',
  WHITE = 'cardwhite',
  GENIUS = 'cardblack',
}

export enum ShippingStatus {
  ORDERED = 'o',
  SHIPPED = 's',
}

export default DebitCardStyle;
