/* eslint-disable no-param-reassign */
import produce from 'immer';
import initialState from 'store.initialState';
import types from 'actions/types';
import * as WEB from 'types/interfaces';

// /////////////////////////////////////////////////////////////////////////////
// selectors
// /////////////////////////////////////////////////////////////////////////////

export const getPreviouslySubmittedTransactionId = (
  state: WEB.RootState
): number | null => {
  return (
    state.ui.banking.previouslySubmittedTransaction?.albert_cash?.pk || null
  );
};

export const getPreviouslySubmittedTransactionACHAccountId = (
  state: WEB.RootState
): number | null => {
  return (
    state.ui.banking.previouslySubmittedTransaction?.albert_cash?.ach_account ||
    null
  );
};

export const isCreatingBankAccount = (state: WEB.RootState): boolean =>
  state.ui.banking.isCreating;

export const addAccountsClicked = (state: WEB.RootState): boolean =>
  state.ui.banking.addAccountsClicked;

// /////////////////////////////////////////////////////////////////////////////
// reducers
// /////////////////////////////////////////////////////////////////////////////

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable consistent-return */
const banking = (state = initialState.ui.banking, action: WEB.Action) => {
  const { type, payload } = action;
  return produce(state, (draft: WEB.RootState['ui']['banking']) => {
    switch (type) {
      case types.SET_ADD_ACCOUNT_CLICKED:
        draft.addAccountsClicked = payload;
        return;

      case types.CREATE_TRANSFER_TRANSACTION_SUCCESS: {
        /* Response payload shape:
         * {
         *  title: 'Success',
         *  message: "Your transfer of $50.00 has been initiated. It should complete by Jan 28, 2022",
         *  button:"Done",
         *  data: {
         *    universal_cash_transfer_id: 1234,
         *    albert_cash: {
         *      id: "albert_cash_20",
         *      ach_account: 20,
         *      pk: 20,
         *      estimated_ach_delivery_date: 163434334,
         *    }
         *  }
         * }
         */
        draft.previouslySubmittedTransaction = payload?.data?.data || {};
        return;
      }

      case types.CANCEL_ACH_TRANSACTION_SUCCESS: {
        if (
          payload?.data?.pk ===
          draft.previouslySubmittedTransaction?.albert_cash?.pk
        ) {
          draft.previouslySubmittedTransaction = {};
        }
        /* eslint-disable no-useless-return */
        return;
      }
      case types.CREATE_BANKING_ACCOUNT_REQUEST: {
        draft.isCreating = true;
        return;
      }
      case types.CREATE_BANKING_ACCOUNT_FAILURE:
      case types.SUBMIT_BANKING_APPLICATION_SUCCESS:
      case types.SUBMIT_BANKING_APPLICATION_FAILURE: {
        draft.isCreating = false;
        return;
      }

      default:
    }
  });
};

export default banking;
