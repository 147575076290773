import * as React from 'react';
import styled from 'styled-components';
import Text, { TextColor, TextSizes } from 'components/common/Text';

type Props = {
  /* number text */
  number: string | number;
};

const StyledNumberIcon = styled.div<Props>`
  background-color: ${({ theme }) => theme.colors.primaryAlbertBrand};
  // Using rem for the height/width leads to the icon
  // looking oblong on some screen sizes
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NumberIcon = (props: Props): React.ReactElement => {
  return (
    <StyledNumberIcon {...props}>
      <Text size={TextSizes.TINY} color={TextColor.WHITE}>
        {props.number}
      </Text>
    </StyledNumberIcon>
  );
};

export default NumberIcon;
