import * as React from 'react';
import styled from 'styled-components';

type Props = {
  text: string;
};

const ARROW_BOX_LENGTH = 10;
const ARROW_BOX_LENGTH_HALF = ARROW_BOX_LENGTH / 2;
const BUBBLE_PADDING = 10;
const ARROW_VERTICAL_OFFSET =
  ARROW_BOX_LENGTH_HALF * Math.floor(BUBBLE_PADDING / ARROW_BOX_LENGTH_HALF) +
  (BUBBLE_PADDING % ARROW_BOX_LENGTH_HALF) -
  ARROW_BOX_LENGTH_HALF;

const Bubble = styled.div`
  position: relative;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  background: ${({ theme }) => theme.colors.lightGray2};
  color: ${({ theme }) => theme.colors.primaryText};
  border-radius: 10px;
  padding: ${BUBBLE_PADDING}px;
`;

// Since there is no outline, we can rotate a box and lay it over the bubble
// to give the appearance of an arrow, thus avoiding overflow logic that
// comes with rendering a triangle in CSS.
const Arrow = styled.div`
  position: absolute;
  width: ${ARROW_BOX_LENGTH}px;
  height: ${ARROW_BOX_LENGTH}px;
  left: 50%;
  right: 50%;
  // With left:50% and right:50%, the left side of the box is positioned in the
  // center. Shift the box to the left by half its length to completely center
  margin-left: -${ARROW_BOX_LENGTH_HALF}px;
  margin-top: ${ARROW_VERTICAL_OFFSET}px;
  transform: rotate(45deg);
  background: ${({ theme }) => theme.colors.lightGray2};
`;

const RoundBubble = ({ text }: Props) => (
  <Bubble>
    {text}
    <Arrow />
  </Bubble>
);

export default RoundBubble;
