import * as React from 'react';
import { useSelector } from 'react-redux';
import InfoPane from 'components/layout/InfoPane';
import { Pages, PaymentFrequency } from 'constants/index';
import * as language from 'reducers/entities/language';
import * as genius from 'reducers/ui/genius';
import * as WEB from 'types/interfaces';
import { getLanguageInfo } from 'utils/getFromLanguage';
import renderTemplate from 'utils/renderTemplateContext';

interface Props {
  size?: string;
}

const GeniusInfoPane = (props: Props): React.ReactElement => {
  const { size } = props;
  const currentPage = useSelector(genius.getCurrentPage);
  const pageLanguage = useSelector((state: WEB.RootState) => {
    return language.getGeniusPageLanguage(state, currentPage);
  });
  const infoPaneContext = getLanguageInfo(pageLanguage);

  // Template specifically for Genius
  const paymentFrequency = useSelector(genius.getSelectedGeniusFrequency);
  const annualBonus = useSelector(genius.getAnnualBonus);

  let { text } = infoPaneContext;

  // Info pane content differs by subscription type
  if (currentPage === Pages.GENIUS_CONFIRM) {
    const isAnnual = paymentFrequency === PaymentFrequency.ANNUAL;
    text = isAnnual
      ? renderTemplate(infoPaneContext.annualText, { bonus: annualBonus })
      : infoPaneContext.monthlyText;
  }

  return (
    <InfoPane.Desktop
      size={size}
      currentPage={currentPage}
      text={text}
      addExpanderSubheadSpacing
      {...infoPaneContext}
    />
  );
};

export default GeniusInfoPane;
