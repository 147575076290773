import * as React from 'react';
import styled from 'styled-components';
import ErrorText from 'components/common/ErrorText';
import {
  BaseInput,
  InputWrapper,
  renderLabels,
} from 'components/common/Input/shared';
import { BaseInputProps } from 'types/interfaces';
import { formatDate } from 'utils/inputFormatters';

const PLACEHOLDER_DEFAULT = 'MM/DD/YYYY';

type Props = BaseInputProps & {
  isFormField?: boolean;
};

const DateInput = (props: Props): React.ReactElement => {
  const {
    id,
    placeholder,
    invalid,
    valueDefault,
    errorText,
    widthSize,
    onChange,
    onBlur,
    isFormField = true,
  } = props;

  const [date, setDate] = React.useState<string | number>(valueDefault || '');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (!isFormField) {
      setDate(formatDate(value));
      return;
    }

    e.target.value = formatDate(value);
    onChange && onChange(e);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace') {
      const { value } = e.currentTarget;
      if (
        value &&
        value.endsWith('/') &&
        e.currentTarget.selectionEnd === value.length
      ) {
        e.currentTarget.value = value.slice(0, -1);
      }
    }
  };

  React.useEffect(() => {
    if (!isFormField) {
      onChange && onChange(date);
    }
  }, [date]);

  return (
    <>
      {renderLabels(props)}
      <InputWrapper widthSize={widthSize} className='input-wrapper'>
        <BaseInput
          {...props}
          placeholder={placeholder || PLACEHOLDER_DEFAULT}
          onChange={handleChange}
          onBlur={onBlur}
          onKeyDown={handleKeyDown}
          invalid={invalid}
          value={isFormField ? props['value'] : date}
          inputMode='numeric'
        />
        {invalid && (
          <ErrorTextWrapper>
            <ErrorText name={id} text={errorText} />
          </ErrorTextWrapper>
        )}
      </InputWrapper>
    </>
  );
};

export default DateInput;

const ErrorTextWrapper = styled.div`
  span {
    white-space: nowrap;
  }
`;
