import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import createSavingsAccount from 'actions/async/createSavingsAccount';
import getAppData from 'actions/async/getAppData';
import logout from 'actions/async/logout';
import updateProfile from 'actions/async/updateProfile';
import removeBanner from 'actions/banner/removeBanner';
import BannerPane from 'components/BannerPane';
import Modal from 'components/common/Modal';
import ContentPane from 'components/layout/ContentPane';
import InfoPane from 'components/layout/InfoPane';
import TwoPaneWrapper from 'components/layout/TwoPaneWrapper';
import { ResponseStatus } from 'constants/index';
import ManualAuth from 'pages/manual_auth/ManualAuth';
import ManualAuthInitiated from 'pages/manual_auth/ManualAuthInitiated';
import Navbar from 'pages/unauthenticated/Navbar';
import * as appData from 'reducers/entities/appData';
import * as WEB from 'types/interfaces';
import { delayWithState } from 'utils/delay';

type LocationState = {
  accountId?: string;
};

type Props = {
  onSubmit: () => void;
  onSkip: () => void;
  nextPage: string;
};

export const infoPaneContext: WEB.LanguageInfo = {
  image: {
    filename: 'img_check_numbers.png',
    width: 79,
  },
  header: 'Verifying your account',
  text: [
    'We were unable to automatically verify your bank account.',
    `Enter your bank account and routing numbers. Find these at the bottom of your checks, or
      on your bank's website.`,
  ],
};

const ManualAuthFlow = ({
  nextPage,
  onSubmit,
  onSkip,
}: Props): React.ReactElement | null => {
  const dispatch = useDispatch<WEB.Dispatch>();
  const location = useLocation();
  const locationState = (location?.state || {}) as LocationState;
  const { accountId } = (location?.state || {}) as LocationState;

  // The manual auth should only be accessible via another page. What this means is that
  // user should not be able to directly access this page unless it's through another page.
  // We're able to know where the user was before coming to the manual auth flow by inspecting
  // history.location.state, which is set by the previous page before navigating to this flow.
  const stateIsEmpty = !Object.keys(locationState).length;

  const disabledCheckpointNav = !!JSON.parse(
    localStorage.getItem('disableCheckpointNav') as string
  );

  // true - display the "account verification initiated" page
  // false - display ManualAuth page
  const [initiatedAuth, setInitiatedAuth] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [isCompleted, setIsCompleted] = React.useState<boolean>(false);

  const account = useSelector((state: WEB.RootState) => {
    const accountIdentifier = accountId || '';
    return appData.getAccountById(
      state,
      accountIdentifier
    ) as WEB.InstitutionAccount;
  });

  const handleOnSkip = React.useCallback(() => {
    setShowModal(true);
  }, []);
  const handleOnComplete = React.useCallback(() => {
    setInitiatedAuth(true);
  }, []);
  const handleModalOnCancel = () => {
    setShowModal(false);
  };
  const handleModalOnSubmit = async () => {
    // Remove any error banner from previous attempts
    dispatch(removeBanner());

    setIsSubmitting(true);
    const profilePromise = dispatch(
      updateProfile({ personal_info_skipped_savings: true })
    );
    const savingsPromise = dispatch(
      createSavingsAccount({
        account_id: accountId || '',
        setup_type: 'manual_without_routing_number',
      })
    );
    const [profileResult, savingsResult] = await Promise.all([
      profilePromise,
      savingsPromise,
    ]);
    const savingsStatus = savingsResult.response?.payload?.data?.status;

    if (
      profileResult.error ||
      savingsResult.error ||
      savingsStatus === ResponseStatus.ERROR
    ) {
      setIsSubmitting(false);
      setShowModal(false);
    } else {
      // Refresh account data
      await dispatch(getAppData());
      setIsSubmitting(false);
      await delayWithState(400, setIsCompleted);
      onSkip();
    }
  };

  if ((stateIsEmpty || !accountId) && !disabledCheckpointNav) {
    dispatch(logout());
    window.location.href = window.albertWeb.SplashAppDomain;
    return null;
  }

  const modalConfig = {
    iconType: 'WARNING',
    title: 'Are you sure?',
    description:
      'Albert will not be able to save money for you until you verify your bank account.',
    cancelLabel: 'Go back',
    submitLabel: 'Okay',
    onCancel: handleModalOnCancel,
    onSubmit: handleModalOnSubmit,
    show: showModal,
    isSubmitting,
    isCompleted,
  };

  return (
    <>
      <Navbar split signupFlow />
      <TwoPaneWrapper>
        <InfoPane.Desktop {...infoPaneContext} />
        <ContentPane>
          <BannerPane />
          {initiatedAuth ? (
            <ManualAuthInitiated
              nextPage={nextPage as string}
              onSubmit={onSubmit}
            />
          ) : (
            <ManualAuth
              accountId={accountId || ''}
              accountBalance={account?.balance}
              onComplete={handleOnComplete}
              onSkip={handleOnSkip}
            />
          )}
        </ContentPane>
        <Modal.Basic {...modalConfig} />
      </TwoPaneWrapper>
    </>
  );
};

export default ManualAuthFlow;
