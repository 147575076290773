import * as React from 'react';
import styled from 'styled-components';
import ErrorText from 'components/common/ErrorText';
import { renderLabels } from 'components/common/Input/shared';
import { breakpoints } from 'styles';

type Props = {
  /* unique id for the dropdown group */
  id: string;
  /* label container that will show only one label for all dropdowns */
  label?: string;
  /* smaller label text that will sit right next to the label */
  smallLabel?: string;
  /* error text to display */
  errorText?: string;
  /* whether to show error text */
  invalid?: boolean;
  /* description label text that will sit beneath the label */
  description?: string;
  /* assuming an input group could contain other dropdowns */
  children?: React.ReactNode | React.ReactNode[];
  /* display items inline */
  displayContentInline?: boolean;
};

type StyledDropdownGroupProps = {
  /* display items inline */
  displayContentInline?: boolean;
};

const StyledDropdownGroup = styled.div<StyledDropdownGroupProps>`
  display: flex;
  justify-content: start;
  align-items: self-start;
  flex-direction: row;
  margin: -5px;

  div.al-dropdown-field {
    margin: 5px;
  }

  ${({ displayContentInline }) =>
    displayContentInline
      ? `
      @media ${breakpoints.tabletSmall} {
        div.al-dropdown-field {
          flex: 1;
  
          .al-dropdown {
            min-width: 0;
          }
        }
      }
  `
      : `
      
      flex-wrap: wrap;

      @media ${breakpoints.tabletSmall} {
        width: 100%;
        flex-direction: column;
        div.al-dropdown-field {
          width: 100%;
        }
      }
  `};
`;

const DropdownGroup = (props: Props): React.ReactElement => {
  const { id, errorText, invalid, displayContentInline, children } = props;
  return (
    <div>
      {renderLabels(props)}
      <StyledDropdownGroup displayContentInline={displayContentInline}>
        {children}
      </StyledDropdownGroup>
      {invalid && <ErrorText name={id} text={errorText} />}
    </div>
  );
};

export default DropdownGroup;
