let isFlexGapRowSupported: boolean | null = null;

/**
 * @deprecated - Prefer alternative styles over conditional polyfill: styles/flexGap.ts
 */
export const checkFlexGapRowSupported = (): boolean => {
  const gapValue = 1;

  if (isFlexGapRowSupported !== null) {
    return isFlexGapRowSupported;
  }

  // create flex container with row-gap set
  const flex = document.createElement('div');
  flex.style.display = 'flex';
  flex.style.flexDirection = 'column';
  flex.style.rowGap = `${gapValue}px`;

  // create two, elements inside it
  flex.appendChild(document.createElement('div'));
  flex.appendChild(document.createElement('div'));

  // append to the DOM (needed to obtain scrollHeight)
  document.body.appendChild(flex);
  const isSupported = flex.scrollHeight === gapValue;
  flex?.parentNode?.removeChild(flex);

  isFlexGapRowSupported = isSupported;

  return isSupported;
};
const flexGapPolyfill = (gap: string): string => {
  return `
    margin-top: -${gap};
    & > * {
      margin-top: ${gap};
    }
  `;
};

const flexGapRow = (gap: string): string => {
  if (checkFlexGapRowSupported()) {
    return `
      row-gap: ${gap};
    `;
  }
  return flexGapPolyfill(gap);
};

export default flexGapRow;
