import * as Sentry from '@sentry/react';
import Cookies from 'js-cookie';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Outlet,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import styled from 'styled-components';
import updateProfile from 'actions/async/updateProfile';
import removeBanner from 'actions/banner/removeBanner';
import NullComponent from 'components/NullComponent';
import QAWidget from 'components/QAWidget';
import TopLoadingBar from 'components/TopLoadingBar';
import ThemeWrapper from 'components/common/ThemeWrapper';
import ExternalRedirect from 'components/router/ExternalRedirect';
import ExternalRedirect404 from 'components/router/ExternalRedirect404';
import ProtectedRoute from 'components/router/ProtectedRoute';
import SignupRerouteWrapper from 'components/router/SignupRerouteWrapper';
import SignupLandingRoutes from 'components/signup/SignupLandingRoutes';
import NodeEnvironment from 'constants/NodeEnvironment';
import PageRoutes from 'constants/PageRoutes';
import DownloadApp from 'pages/DownloadApp';
import ErrorPage from 'pages/Error';
import TwoFAFlow from 'pages/authentication/TwoFAFlow';
import KYCVerifyIdentity from 'pages/kyc/KYCVerifyIdentity';
import * as appData from 'reducers/entities/appData';
import * as banner from 'reducers/entities/banner';
import * as app from 'reducers/ui/app';
import theme from 'styles/theme';
import * as WEB from 'types/interfaces';
import captureSentryIdentity from 'utils/captureSentryIdentity';
import { logger } from 'utils/logger';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* Min-height relative to user's screen height on mobile */
  width: 100vw;
`;

const AppWrapper = (): React.ReactElement => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const show2FA = useSelector(app.show2FA);
  const currentBanner = useSelector(banner.getBanner);
  const isHidden = useSelector(banner.getIsHidden);
  const currentBannerRef = React.useRef(currentBanner);
  const isHiddenRef = React.useRef(isHidden);

  React.useEffect(() => {
    currentBannerRef.current = currentBanner;
    isHiddenRef.current = isHidden;
  });

  React.useEffect(() => {
    if (show2FA) {
      navigate(
        {
          pathname: PageRoutes.TWO_FA,
          search: window.location.search,
        },
        {
          state: { prompt2FA: true, sendCode: true },
        }
      );
    }
  }, [navigate, show2FA]);

  React.useEffect(() => {
    dispatch(removeBanner());

    if (currentBannerRef.current && !isHiddenRef.current) {
      // "setTimeout" is needed for this code to work
      setTimeout(() => {
        const hasScrollRestoration = 'scrollRestoration' in window.history;
        // disable scroll restoration to avoid page jumping effect
        if (hasScrollRestoration) {
          window.history.scrollRestoration = 'manual';
        }

        // Scroll to the top of the page, so the banner can be seen
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

        // enable scroll restoration
        if (hasScrollRestoration) {
          window.history.scrollRestoration = 'auto';
        }
      }, 1);
    }
  }, [location.pathname, dispatch]);

  return (
    <>
      {window.albertWeb.Environment !== NodeEnvironment.PROD && <QAWidget />}
      <Outlet />
    </>
  );
};

const App = (): React.ReactElement => {
  const dispatch = useDispatch();
  const isTrackingDisabledBackend = useSelector((state: WEB.RootState) =>
    appData.getValueByField(state, 'is_tracking_disabled')
  );

  React.useEffect(() => {
    // Sync user's tracking preference with backend.
    // Users can change this asynchronously at any time in albert.com,
    // so we need to observe this value throughout the application.
    const isTrackingDisabledCookie =
      Cookies.get('is_tracking_disabled') === 'true';
    const isTrackingDisabledGPC = Boolean(navigator['globalPrivacyControl']);
    const isTrackingDisabledFrontend =
      isTrackingDisabledCookie || isTrackingDisabledGPC;

    if (
      isTrackingDisabledBackend !== null &&
      isTrackingDisabledBackend !== isTrackingDisabledFrontend
    ) {
      if (isTrackingDisabledGPC) {
        logger.message(
          'GPC enabled. Updating user profile to disable tracking.'
        );
      }

      dispatch(
        updateProfile({
          is_tracking_disabled: isTrackingDisabledFrontend,
        })
      );
    }
  }, [dispatch, isTrackingDisabledBackend]);

  const appTheme = useSelector(app.getTheme);
  const isLoading = useSelector(app.getIsLoading);

  // Capture identity of current user for Sentry
  captureSentryIdentity();

  const urlSearchParams = new URLSearchParams(window.location.search);
  const flowType = urlSearchParams.get('flow_type');

  return (
    <PageContainer>
      {/* Mobile browsers already have a native top page loading bar, so hide on mobile */}
      {flowType !== 'short' && (
        <TopLoadingBar isLoading={isLoading} hideOnMobile />
      )}
      <ThemeWrapper theme={theme[appTheme]}>
        <Sentry.ErrorBoundary fallback={ErrorPage}>
          <RouterProvider router={router} />
        </Sentry.ErrorBoundary>
      </ThemeWrapper>
    </PageContainer>
  );
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<AppWrapper />}>
      <Route path={'signup/*'} element={<SignupLandingRoutes />} />
      {['home/*', 'login'].map((path) => (
        <Route
          key={path}
          path={path}
          element={<ExternalRedirect url={window.albertWeb.SplashAppDomain} />}
        />
      ))}
      <Route
        path='confirm-identity/*'
        element={
          <SignupRerouteWrapper>
            <TwoFAFlow />
          </SignupRerouteWrapper>
        }
      />
      {/**
       * Base path is a protected route because if the user tries to access "/"
       * we have the following behavior:
       * (1) User is authenticated -> initialize app and take them to checkpoint / download app page
       * (2) User is not authenticated -> initialize app, fail, and redirect to albert.com
       */}
      {['/', 'redirect'].map((path) => (
        <Route
          path={path}
          key={path}
          element={
            <ProtectedRoute>
              <NullComponent />
            </ProtectedRoute>
          }
        />
      ))}
      <Route
        path={PageRoutes.DOWNLOAD_APP}
        element={
          <SignupRerouteWrapper>
            <ProtectedRoute>
              <DownloadApp />
            </ProtectedRoute>
          </SignupRerouteWrapper>
        }
      />
      <Route
        path={PageRoutes.KYC_VERIFY_IDENTITY}
        element={
          <SignupRerouteWrapper>
            <ProtectedRoute>
              <KYCVerifyIdentity />
            </ProtectedRoute>
          </SignupRerouteWrapper>
        }
      />
      <Route path='*' element={<ExternalRedirect404 />} />
    </Route>
  )
);

export default App;
