import React from 'react';
import styled from 'styled-components';
import { colors, fontSizes } from 'styles';

/* styles inspired by Andy Bell and Chris Coyier's "Button Pal" https://css-tricks.com/overriding-default-button-styles/ */
const StyledButton = styled.button`
  display: inline-block;
  border: none;
  padding: 0;
  margin: 0;
  text-decoration: none;
  background: transparent;
  color: #333333;
  font-family: 'Work Sans', sans-serif;
  ${fontSizes.fontSize18}
  cursor: pointer;
  text-align: center;
  outline: none;
  transition: background 250ms ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    color: ${colors.primaryAlbertBrand};
    //background: #0053ba;
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

export interface Props {
  className?: string;
  children?: any;
  onClick?: any;
  disabled?: boolean;
  type?: 'button' | 'submit';
}

const TextButton = function (props: Props) {
  let { className, children } = props;
  className = className || '';
  children = children || null;
  return (
    <StyledButton {...props} className={`non-link-anchor ${className}`}>
      {children}
    </StyledButton>
  );
};

export default TextButton;
