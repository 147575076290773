import { CheckCircle } from '@styled-icons/fa-solid/CheckCircle';
import { Spinner8 } from '@styled-icons/icomoon/Spinner8';
import * as React from 'react';
import styled from 'styled-components';
import { fontSizes, mixins } from 'styles';
import { handleActivateKeyDown } from 'utils/accessibility';

/* eslint-disable @typescript-eslint/no-explicit-any */
export type Props = {
  /* id of the button */
  id?: string;
  /* type of the button */
  secondary?: boolean | false;
  /* the state of the button */
  disabled?: boolean | false;
  /* whether the button is active */
  inactive?: boolean | false;
  /* whether the button is in a loading state */
  isLoading?: boolean | false;
  /* whether the button is in a completed state */
  isCompleted?: boolean | false;
  /* stretch to full width */
  stretch?: boolean | false;
  /* the theme to use for this component */
  theme?: any;
  /* onClick handler function */
  onClick?: (...args: any) => any;
  /* assuming a button could contain icons and other elements */
  children?: any;
  className?: string;
};

const getBackgroundColor = (props: Props, isHovering = false): string => {
  const { isCompleted, inactive, theme, secondary } = props;
  const { colors = {} } = theme;

  if (isCompleted && !secondary) {
    return theme?.colors.brandDark2 as string;
  }
  if (secondary) {
    return 'transparent';
  }
  if (isHovering && !inactive) {
    return colors.brandDark1 as string;
  }
  if (inactive) {
    return colors.primaryGray as string;
  }
  return colors.primaryAlbertBrand as string;
};

const getBorderColor = (props: Props, isHovering = false): string => {
  const { theme, secondary } = props;
  const { colors = {} } = theme;

  if (secondary && isHovering) {
    return colors.brandDark1 as string;
  }
  if (secondary && !isHovering) {
    return colors.primaryAlbertBrand as string;
  }
  return 'transparent';
};

const getColor = (props: Props, isHovering = false): string => {
  const { theme, secondary } = props;
  const { colors = {} } = theme;

  if (secondary && !isHovering) {
    return colors.primaryAlbertBrand as string;
  }
  if (secondary && isHovering) {
    return colors.brandDark2 as string;
  }
  if (colors.buttonText) {
    return colors.buttonText as string;
  }
  return 'white';
};
const sharedButtonStyles = `
  ${fontSizes.fontSize16}
  height: ${mixins.pxToRem('43px')};
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  min-width: 160px;
  border-radius: 60px;
  font-weight: 700;
  font-style: normal;
  cursor: pointer;

`;
export const StyledLinkButton = styled.a`
  ${sharedButtonStyles};
  display: inline-flex;
  color: white;
  background-color: ${({ theme }) => theme.colors.primaryAlbertBrand};
  flex-shrink: 0;
  &:visited,
  &:active,
  &:hover,
  &:link {
    color: ${({ theme }) => theme.colors.white};
    text-decoration: none;
  }
`;

const StyledButton = styled.button<Props>`
  ${sharedButtonStyles};
  color: ${(props: Props) => getColor(props)};
  background-color: ${(props: Props) => getBackgroundColor(props)};
  border-color: ${(props: Props) => getBorderColor(props)};
  height: ${mixins.pxToRem('43px')};
  display: ${({ isCompleted }) => (!isCompleted ? 'inline-flex' : 'flex')};
  border-style: solid;
  border-width: ${(props) => (props.secondary ? '2px' : '1.5px')};
  transition: ${({ isCompleted }) =>
    isCompleted ? '0.2s' : 'all 0.1s ease-in 0.1s;'};
  outline: none;
  ${({ stretch }) => stretch && 'width: 100%'};

  // Media query prevents issues with hover state sticking on touchscreens
  @media (hover: hover) {
    &:hover:not(:disabled) {
      color: ${(props: Props) => getColor(props, true)};
      background-color: ${(props: Props) => getBackgroundColor(props, true)};
      border-color: ${(props: Props) => getBorderColor(props, true)};
    }
  }
  &:disabled {
    color: ${(props: Props) => getColor(props, true)};
    cursor: not-allowed;
    /* Only set the background color gray if button is disabled NOT in loading state */
    ${(props) =>
      !props.isLoading &&
      !props.isCompleted &&
      `
        background-color: ${getBackgroundColor(props)};
        opacity: 0.2; 
        // Safari workaround for issue where opacity < 1  + display:flex causes the bg color to vanish
        -webkit-transform: translate3d(0,0,0);
      `}
  }
  &:focus {
    color: ${(props: Props) => getColor(props, true)};
    background-color: ${(props: Props) => getBackgroundColor(props, true)};
    border-color: ${(props: Props) => getBorderColor(props, true)};
  }
`;

const StyledSpinner = styled(Spinner8)<{ secondary: boolean }>`
  width: ${mixins.pxToRem('19px')};
  color: ${({ theme, secondary }) =>
    secondary ? theme.colors.primaryAlbertBrand : 'white'};
  ${mixins.spin(1)};
`;

const StyledCheckCircle = styled(CheckCircle)<{ secondary: boolean }>`
  width: ${mixins.pxToRem('19px')};
  color: ${({ theme, secondary }) =>
    secondary ? theme.colors.brandDark1 : 'white'};
`;

const Content = styled.div`
  margin-right: 15px;
`;

const getButtonLabel = (
  props: Props
): React.ReactElement | React.ReactElement[] => {
  if (props.isLoading)
    return <StyledSpinner secondary={props.secondary || false} />;
  if (props.isCompleted)
    return (
      <>
        {props.children && <Content>{props.children}</Content>}
        <div>
          <StyledCheckCircle secondary={props.secondary || false} />
        </div>
      </>
    );
  return props.children;
};

export default React.forwardRef<HTMLButtonElement, Props>(function Button(
  props,
  ref
): React.ReactElement {
  const { disabled, isCompleted, onClick } = props;

  return (
    <StyledButton
      {...props}
      ref={ref}
      disabled={disabled !== undefined ? disabled : isCompleted}
      tabIndex={0}
      onKeyDown={(e) => handleActivateKeyDown(e, onClick)}
    >
      {getButtonLabel(props)}
    </StyledButton>
  );
});
