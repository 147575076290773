enum BannerActionType {
  LOGIN_APP = 'logout',
  DISMISS_FLOW = 'dismiss_flow',
  RETRY_FLOW = 'retry_flow',

  // Custom to web only.
  LOGIN_APP_KYC = 'logout_kyc',
  LINK_ACCOUNT_WARNING = 'link_account_warning',
  HIDE_ICON = 'hide_icon',
}

export const UniqueBannerStyles = [
  BannerActionType.LOGIN_APP,
  BannerActionType.DISMISS_FLOW,
  BannerActionType.RETRY_FLOW,
  BannerActionType.LOGIN_APP_KYC,
];

export default BannerActionType;
