import types from 'actions/types';
import BannerActionType from 'constants/BannerActionType';
import NotificationTypes from 'constants/NotificationTypes';
import * as WEB from 'types/interfaces';
import removeBanner from './removeBanner';

export default (
    type: NotificationTypes = NotificationTypes.SUCCESS,
    message: string | string[],
    shouldDismiss = true,
    bannerAction: BannerActionType | null = null
  ): WEB.ActionCreator<void> =>
  (dispatch: WEB.Dispatch) => {
    dispatch({
      type: types.ADD_BANNER,
      payload: {
        type,
        message,
        bannerAction,
      },
    });

    if (shouldDismiss) {
      // timeout to be 5 seconds
      const BANNER_TIMEOUT = 5000;
      setTimeout(() => dispatch(removeBanner()), BANNER_TIMEOUT);
    }
  };
