import types from 'actions/types';
import AlbertClient from 'api/AlbertClient';
import * as WEB from 'types/interfaces';
import { onErrorDisplayDefaultBanner } from 'utils/axiosMiddlewareUtils';

export default (
    accountId: number
  ): WEB.ActionCreator<Promise<Record<string, any>>> =>
  async (dispatch: WEB.Dispatch): Promise<Record<string, any>> => {
    let error = false;
    const response = await dispatch({
      types: [
        types.GET_ACCOUNT_REQUEST,
        types.GET_ACCOUNT_SUCCESS,
        types.GET_ACCOUNT_FAILURE,
      ],
      payload: {
        request: {
          method: 'get',
          url: AlbertClient.accountView(accountId),
        },
      },
      options: {
        onError: (middlewareOptions: any, options: any) => {
          error = true;
          return onErrorDisplayDefaultBanner(middlewareOptions, options);
        },
      },
    });
    return { response, error };
  };
