import * as React from 'react';
import TextInput, { Props as TextInputProps } from './TextInput';

type Props = TextInputProps & {
  value?: string;
};

const TextInputControlled = (props: Props): React.ReactElement => {
  return <TextInput {...props} />;
};

export default TextInputControlled;
