/**
 * Persona Verify Identity Web
 */
import Persona from 'persona';
import { InquiryOptions } from 'persona/dist/lib/interfaces';
import React from 'react';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import submitProfileKYCIdentityCheck from 'actions/async/submitProfileKYCIdentityCheck';
import removeBanner from 'actions/banner/removeBanner';
import AlbertClient from 'api/AlbertClient';
import Button from 'components/common/Button';
import Link from 'components/common/Link';
import LoadingSpinner from 'components/common/LoadingSpinner';
import Modal from 'components/common/Modal';
import Spacer from 'components/common/Spacer';
import Text, { TextColor, TextSizes } from 'components/common/Text';
import ButtonsContainer from 'components/layout/ButtonsContainer';
import PageBase from 'components/layout/PageBase';
import ArtMap, { Art } from 'constants/ArtMap';
import ErrorMessages, { ErrorTitles } from 'constants/ErrorMessages';
import NodeEnvironment from 'constants/NodeEnvironment';
import NotificationTypes from 'constants/NotificationTypes';
import { KYCPages } from 'constants/Pages';
import ProductType from 'constants/ProductType';
import ZIndex from 'constants/ZIndex';
import useBrowserNavigationOverrideWithBanner from 'hooks/useBrowserNavigationBlockWithBanner';
import useNavigateFunction from 'hooks/useNavigateFunction';
import Processing from 'pages/signup/Processing';
import { breakpoints, fontSizes, spacers } from 'styles';
import { spacers as homeSpacers } from 'styles/home';
import * as WEB from 'types/interfaces';
import { AppData, RootState } from 'types/interfaces';
import { delay } from 'utils/delay';
import wrappedFetch from 'utils/wrappedFetch';

const isProd =
  window.albertWeb.Environment === NodeEnvironment.PROD &&
  !window.albertWeb.IsLocal;

type PersonaInquiry = {
  id: number;
  profile: number;
  persona_account: number;
  identity_verification: null;
  inquiry_id: string;
  template_id: string;
  session_token: string;
  status: string;
  is_settled: boolean;
  is_approved: boolean;
  is_declined: boolean;
};

const sendPersonaCallback = async ({
  status,
  inquiryId,
  source,
}: {
  status: string;
  inquiryId: string;
  source: string;
}): Promise<{
  error: boolean;
  data: PersonaInquiry | undefined;
}> => {
  const response = await wrappedFetch(AlbertClient.personaCallbackView(), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      inquiry_status: status,
      inquiry_id: inquiryId,
      source: source,
    }),
  });

  if (response.status === 200) {
    const data: PersonaInquiry = await response.json();
    return { data, error: false };
  }

  return { error: true, data: undefined };
};

const ConfirmWrapper = styled.div`
  ${fontSizes.fontSize18};
  @media ${breakpoints.mobileLarge} {
    font-size: inherit;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  display: none;

  @media ${breakpoints.mobileLarge} {
    display: block;
  }
`;

const StyledImage = styled.img`
  margin: auto;
  display: block;
  padding-top: ${spacers.tab};
  padding-bottom: ${spacers.tab};

  @media ${breakpoints.mobileLarge} {
    width: 138px;
  }
`;

const FullScreenContainer = styled.div`
  position: fixed;
  inset: 0;
  z-index: ${ZIndex.MODAL};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
`;

const PersonaContainer = styled.div<{ isLoading?: boolean }>`
  width: 100%;
  height: 100%;
  max-width: 420px;
  max-height: 800px;
  background-color: white;
  border-radius: 16px;
  overflow: hidden;

  div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  iframe {
    opacity: ${({ isLoading }) => (isLoading ? 0 : 1)};
    width: 100%;
    height: 100%;
  }

  @media ${breakpoints.mobileLarge} {
    border-radius: 0px;
    max-width: 100%;
    max-height: 100%;
  }
`;

const processingInfo = {
  header: 'Processing your verification',
  checkpoints: ['Submitting information', 'Verifying accounts', 'Finishing up'],
};

const PERSONA_ERROR = {
  UNABLE_TO_VERIFY: 'unableToVerify',
  TOO_MANY_FAILED_ATTEMPTS: 'tooManyFailedAttempts',
} as const;

type PersonaError = (typeof PERSONA_ERROR)[keyof typeof PERSONA_ERROR];

type PageStatus = 'INITIAL' | 'PERSONA' | 'PROCESSING' | 'ERROR' | 'SUCCESS';

type Props = {
  fromSignup?: boolean;
  pageOnComplete: string;
  exitKYCFlow(showPersonaVerification: boolean): Promise<boolean>;
};

const KYCVerifyIdentityWeb = (props: Props): React.ReactElement | null => {
  const { updateCurrentKYCPage } = useNavigateFunction();
  useBrowserNavigationOverrideWithBanner();

  const [pageStatus, setPageStatus] = React.useState<PageStatus>('INITIAL');
  const [errorState, setErrorState] = React.useState<
    PersonaError | undefined
  >();
  const [isPersonaLoading, setIsPersonaLoading] = React.useState(true);

  const showError = pageStatus === 'ERROR' && errorState;
  const showPersona = pageStatus === 'PERSONA';
  const unableToVerifyError = errorState === PERSONA_ERROR.UNABLE_TO_VERIFY;
  const isSuccess = pageStatus === 'SUCCESS';
  const isProcessing = pageStatus === 'PROCESSING';
  const showProcessing = isProcessing || isSuccess;

  const dispatch = useDispatch<WEB.Dispatch>();

  const socureSessionId = useSelector(
    (state: WEB.RootState) => state.ui.signup.socureSessionId
  );

  const personaTemplateId = useSelector(
    (state: RootState) =>
      (state.entities.appData as AppData).signup_persona.persona_template_id
  );

  const profile = useSelector(
    (state: WEB.RootState) => state.entities.profile as WEB.Profile
  );

  const setError = (error: PersonaError) => {
    setErrorState(error);
    setPageStatus('ERROR');
  };

  const onPersonaInquiryComplete: InquiryOptions['onComplete'] = async ({
    inquiryId,
    status,
  }) => {
    if (status === 'failed') {
      // Handle persona verification failed
      setError(PERSONA_ERROR.UNABLE_TO_VERIFY);
      return;
    }

    setPageStatus('PROCESSING');

    const { error, data } = await sendPersonaCallback({
      inquiryId,
      status,
      source: 'signup',
    });

    if (error || !data) {
      // Handle unexpected error from server
      setError(PERSONA_ERROR.UNABLE_TO_VERIFY);
      return;
    }

    if (data.status === 'c' || data.status === 'a') {
      // Submit KYC profile
      const kycResponse = await dispatch(
        submitProfileKYCIdentityCheck(ProductType.SIGNUP, null, socureSessionId)
      );

      if (kycResponse.error) {
        setError(PERSONA_ERROR.UNABLE_TO_VERIFY);
        return;
      }

      // Exit KYC flow
      const exitKYCError = await props.exitKYCFlow(false);

      if (exitKYCError) {
        setError(PERSONA_ERROR.UNABLE_TO_VERIFY);
        return;
      }

      // Handle status from server is confirmed or approved
      setPageStatus('SUCCESS');
      return;
    }

    // Handle status from server if persona verification succeeded, but server failed
    setError(PERSONA_ERROR.TOO_MANY_FAILED_ATTEMPTS);
  };

  const processingCompleted = async () => {
    // Navigate to next page
    dispatch(
      updateCurrentKYCPage({
        page: props.pageOnComplete,
        fromSignup: props.fromSignup,
      })
    );
  };

  const onSubmit = () => {
    dispatch(removeBanner());

    if (errorState) {
      setPageStatus('ERROR');
    } else {
      setPageStatus('PERSONA');
    }
    return;
  };

  if (showProcessing) {
    return (
      <Processing
        canProceed={isSuccess}
        onSubmit={processingCompleted}
        {...processingInfo}
      />
    );
  }

  return (
    <PageBase>
      <Form
        onSubmit={onSubmit}
        initialValues={undefined}
        render={({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <ImageContainer>
              <StyledImage src={ArtMap(Art.LicenseScan)} />
            </ImageContainer>
            <Text size={TextSizes.XLARGE} color={TextColor.BLACK} weight='bold'>
              Verify your identity
            </Text>
            <Spacer space={spacers.miniscule} />
            <ConfirmWrapper className='description'>
              <Text color={TextColor.BLACK}>
                Verify your identity to protect against fraud. You&apos;ll need
                a government-issued ID and a matching selfie. Your selfie may be
                stored for up to three years for verification and security
                improvements.
              </Text>
            </ConfirmWrapper>
            <Spacer space={homeSpacers.g4} />
            <Text size={TextSizes.SMALL} color={TextColor.GRAY}>
              By continuing, you agree to Persona&apos;s{' '}
              <Link
                href='https://withpersona.com/legal/privacy-policy'
                target='_blank'
                underline
              >
                privacy policy
              </Link>{' '}
              and use of your biometrics.
            </Text>
            <Spacer space={homeSpacers.g6} />
            <ButtonsContainer>
              <Button isLoading={submitting} disabled={submitting}>
                Continue
              </Button>
            </ButtonsContainer>
          </form>
        )}
      />
      {showPersona && (
        <FullScreenContainer>
          <PersonaContainer isLoading={isPersonaLoading}>
            {isPersonaLoading && (
              <div>
                <LoadingSpinner size='48px' />
              </div>
            )}
            <Persona.Inquiry
              templateId={personaTemplateId}
              referenceId={String(profile.id)}
              fields={{
                name_first: profile.first_name,
                name_last: profile.last_name,
              }}
              environment={isProd ? 'production' : 'sandbox'}
              onReady={async () => {
                await delay(250);
                setIsPersonaLoading(false);
              }}
              onComplete={onPersonaInquiryComplete}
            />
          </PersonaContainer>
        </FullScreenContainer>
      )}
      {showError && (
        <Modal.Basic
          title={ErrorTitles.kyc[errorState]}
          description={ErrorMessages.kyc[errorState]}
          iconType={NotificationTypes.ERROR}
          show
          submitLabel={unableToVerifyError ? 'Try Again' : 'Okay'}
          onSubmit={() => {
            setPageStatus('INITIAL');

            if (unableToVerifyError) {
              // Clear error state if retry is allowed
              setErrorState(undefined);

              // Redirect back to confirm page
              dispatch(
                updateCurrentKYCPage({
                  page: KYCPages.KYC_CONFIRM,
                  fromSignup: props.fromSignup,
                })
              );
            }
          }}
        />
      )}
    </PageBase>
  );
};

export default KYCVerifyIdentityWeb;
