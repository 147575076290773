import * as React from 'react';

/**
 * A check circle icon where the checkmark
 * has rounded ends.
 */
const CheckCircle = ({
  className,
  noFill,
}: {
  className?: string;
  noFill?: boolean;
}) => {
  return (
    <svg
      className={className}
      viewBox='0 0 30 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15 28C22.1797 28 28 22.1797 28 15C28 7.8203 22.1797 2 15 2C7.8203 2 2 7.8203 2 15C2 22.1797 7.8203 28 15 28Z'
        fill={noFill ? 'white' : 'currentColor'}
        stroke='currentColor'
        strokeWidth='3'
      />
      <path
        d='M9.84863 15.5466L13.3837 19.0817L20.6607 11.8047'
        stroke={noFill ? 'currentColor' : 'white'}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default CheckCircle;
