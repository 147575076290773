// All the z-index used throughout app
enum ZIndex {
  MODAL_POPUP = 11,
  MODAL = 10,
  MODAL_BACKDROP = 9,
  NAV_BAR = 7,
  BANNER = 6,
  LOTTIE = 3,
  DROPDOWN = 3,
  NUMBER_SLIDER_THUMB = 3,
  NUMBER_SLIDER_TRACK = 2,
  MODAL_CLOSE_ICON = 2,
  FLOATING_LABEL = 2,
  INFO_PANE = 1,
  PROGRESS_NAV_BAR = 1,
}

export default ZIndex;
