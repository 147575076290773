/* eslint-disable @typescript-eslint/no-floating-promises */
import getAccount from 'actions/async/getAccount';
import getAppData from 'actions/async/getAppData';
import albertClient from 'api/AlbertClient';
import { PlaidUpdateStates, PollingType } from 'constants/';
import * as WEB from 'types/interfaces';
import { delay } from 'utils/delay';
import { logger } from 'utils/logger';
import wrappedFetch from 'utils/wrappedFetch';
import configureStore from './store.configureStore';

const store = configureStore();

export default class PollingManager {
  /**
   * Poll for bank account auth.
   *
   * See AccountAuthService.swift > manageAuth() for more context.
   */
  static async pollForBankAccountAuth({
    accountId,
    dispatch,
    completionHandler,
    completionHandlerArgs,
  }: WEB.PollBankAccountAuthArgs): Promise<void> {
    const POLLING_INTERVAL = 5; // seconds
    const AUTH_POLLING_DURATION = 2 * 60; // 2 minutes

    let hasCompletedAuth = false;
    let secondsElapsed = 0;

    while (secondsElapsed < AUTH_POLLING_DURATION && !hasCompletedAuth) {
      /* eslint-disable no-await-in-loop */
      const { response, error } = await dispatch(getAccount(accountId));

      // Terminate polling on error.
      if (error) {
        secondsElapsed = AUTH_POLLING_DURATION;
        break;
      }

      const account = (response?.payload?.data || {}) as WEB.InstitutionAccount;
      hasCompletedAuth = !!account?.has_completed_auth;

      // "Polling" effect - delay before making the next request
      /* eslint-disable no-await-in-loop */
      await delay(POLLING_INTERVAL * 1000); // in ms
      secondsElapsed += POLLING_INTERVAL;
    }

    // TODO: In the app, we show the manual verification/Plaid Link if not hasCompletedAuth.
    // TODO (cont): For now, we do not support this in web -> let the user proceed to make transfer.
    completionHandler(completionHandlerArgs);
  }

  /**
   * Poll for plaid transactions.
   *
   * @param institutionId - id for the insitution to pull transactions from
   */
  static async pollForPlaidTransactions({
    institutionLoginId,
  }: WEB.PollPlaidTransactionsArgs): Promise<void> {
    logger.info('Polling for Plaid transactions');

    const SHORT_POLLING_INTERVAL = 5; // seconds
    const LONG_POLLING_INTERVAL = 10; // seconds
    const TRANSACTIONS_POLLING_DURATION = 3 * 60; // 3 minutes

    // Do not proceed if institution id is missing
    // TODO: should not fail silently if this is the case - although it should never happen
    if (!institutionLoginId) {
      logger.error('Missing Institution ID: This should never happen');
      return;
    }

    /* eslint-disable no-loop-func */
    let updateState: string | PlaidUpdateStates = PlaidUpdateStates.INITIATED;
    let numTransactions = 0;
    let secondsElapsed = 0;

    const baseUrl = albertClient.institutionsLoginView();
    const pollingEndpoint = `${baseUrl}/${institutionLoginId}/`;

    while (
      (secondsElapsed < TRANSACTIONS_POLLING_DURATION &&
        updateState === PlaidUpdateStates.INITIATED) ||
      updateState === PlaidUpdateStates.COMPLETED_INITIAL_UPDATE
    ) {
      wrappedFetch(pollingEndpoint)
        .then((res) => {
          if (res.status !== 200) throw new Error();
          return res.json();
        })
        .then((insLogin: WEB.InstitutionLogin) => {
          // Update "updateState" to current institution login refresh status state
          const refreshStatus = insLogin.refresh_status?.accounts?.state;
          if (refreshStatus) {
            updateState = refreshStatus;
          }
          numTransactions =
            Number(insLogin.refresh_status?.transactions_webhook_count) || 0;
        })
        // eslint-disable-next-line no-loop-func
        .catch(() => {
          secondsElapsed = TRANSACTIONS_POLLING_DURATION;
          updateState = PlaidUpdateStates.ERROR;
        });

      // "Polling" effect - delay before making the next request
      /* eslint-disable no-await-in-loop */
      const interval =
        secondsElapsed > 30 ? LONG_POLLING_INTERVAL : SHORT_POLLING_INTERVAL;
      await delay(interval * 1000); // in ms
      secondsElapsed += interval;
    }

    // Force refresh if found transactions found
    if (updateState !== PlaidUpdateStates.INITIATED && numTransactions) {
      store.dispatch(getAppData() as any);
    }
  }

  /**
   * Poll after creating institution login to fetch accounts for the first time.
   *
   * @param pollingId - institution login polling id
   * @param institutionId - the institution id
   * @param successHandler - success callback function
   * @param errorHandler - error callback function
   */
  static async pollForPlaidLinkSuccess({
    pollingId,
    institutionId,
    successHandler,
    errorHandler,
  }: WEB.PollPlaidLinkArgs): Promise<void> {
    logger.info('Polling for Plaid Link success');

    // Same constraints used in the app
    const ACCOUNTS_POLLING_DURATION = 2 * 60; // 2 minutes in seconds
    const SHORT_POLLING_INTERVAL = 5; // 5 seconds

    // Variable to keep track of returned institution login refresh status state
    /* eslint-disable no-loop-func */
    let updateState = '';
    let secondsElapsed = 0;
    let institutionLoginId = null;

    const queryParams = new URLSearchParams({ polling_id: pollingId });
    const pollingEndpoint = albertClient.linkingVerificationView(queryParams);

    // Keep polling the endpoint until we either:
    // (1) Reach the time limit or
    // (2) Institution login refresh status state is "COMPLETED"
    while (
      secondsElapsed < ACCOUNTS_POLLING_DURATION &&
      updateState !== WEB.AccountsState.COMPLETED
    ) {
      // Poll for institution login to check refresh state
      wrappedFetch(pollingEndpoint)
        .then((res) => {
          // If there are any errors, throw new error - this will stop the polling
          // 404 error will be returned if institution login by polling id was not found
          // This happens if you initiate polling with a refresh and then refresh again while the first
          // polling task is running
          if (res.status !== 200) throw new Error();
          return res.json();
        })
        .then((data: WEB.LinkingVerificationData) => {
          const insLogin = data.institution_login;
          // Make sure there are no errors on newly created institution login
          if (insLogin.error_message) {
            errorHandler(insLogin.error_message);
            throw new Error();
          } else {
            // Update "updateState" to current institution login refresh status state
            const refreshStatus = insLogin.refresh_status?.accounts?.state;
            if (refreshStatus) {
              updateState = refreshStatus;
              institutionLoginId = insLogin.id;
            }
          }
        })
        .catch(() => {
          // Set status as "ERROR" and exit loop (stop polling)
          updateState = WEB.AccountsState.ERROR;
          secondsElapsed = ACCOUNTS_POLLING_DURATION;
        });

      // "Polling" effect - delay before making the next request
      /* eslint-disable no-await-in-loop */
      await delay(SHORT_POLLING_INTERVAL * 1000); // in ms
      secondsElapsed += SHORT_POLLING_INTERVAL;
    }

    if (updateState === WEB.AccountsState.COMPLETED) {
      // Blocking call to re-fetch app data
      const response = await store.dispatch(getAppData() as any);
      // Get all accounts associated with institution login
      const accountsDict = response?.payload?.data?.accounts_dict || {};
      const accountsArr: WEB.InstitutionAccount[] = Object.values(accountsDict);

      const accounts: WEB.InstitutionAccount[] = accountsArr.filter(
        (acc: WEB.InstitutionAccount) => {
          return (
            acc.institution_login?.institution?.institution_id === institutionId
          );
        }
      );

      successHandler(accounts, institutionId, institutionLoginId);
      // If there was no error and the status was not "COMPLETED", we can assume that Plaid took too long
    } else {
      errorHandler('The financial institution is taking too long to respond');
    }
  }

  /**
   * Triggers a polling function given the type.
   *
   * @param type - the polling type (i.e. TRANSACTIONS, LINK)
   * @param args - an object containing the function arguments
   */
  static triggerPolling(type: PollingType, args: Record<string, any>): void {
    switch (type) {
      case PollingType.TRANSACTIONS:
        this.pollForPlaidTransactions(args as WEB.PollPlaidTransactionsArgs);
        break;
      case PollingType.LINK:
        this.pollForPlaidLinkSuccess(args as WEB.PollPlaidLinkArgs);
        break;
      case PollingType.AUTH:
        this.pollForBankAccountAuth(args as WEB.PollBankAccountAuthArgs);
        break;
      default:
        break;
    }
  }
}
