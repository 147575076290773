import ArtMap, { Art } from 'constants/ArtMap';
import InstitutionsArtMap from 'constants/InstitutionsArtMap';

export const getIconSrc = ({
  imageName,
  logo,
}: {
  // Fields come from account.institution_login.institution
  imageName?: string;
  logo?: string | null;
}): string => {
  if (imageName) {
    const src = InstitutionsArtMap(imageName);
    if (src) {
      return src;
    }
  }
  return ArtMap(logo ? `data:img/png;base64,${logo}` : Art.BankAccount);
};
