/* eslint-disable no-param-reassign */
import produce from 'immer';
import initialState from 'store.initialState';
import types from 'actions/types';
import Pages from 'constants/Pages';
import { PaymentFrequency } from 'constants/index';
import * as WEB from 'types/interfaces';

// /////////////////////////////////////////////////////////////////////////////
// selectors
// /////////////////////////////////////////////////////////////////////////////
export const getCurrentPage = (state: WEB.RootState): Pages => {
  return state.ui.genius.currentPage || '';
};

export const getSelectedGeniusPrice = (state: WEB.RootState): number => {
  return state.ui.genius.selectedPrice;
};

export const getAnnualBonus = (state: WEB.RootState): number => {
  return state.ui.genius.annualBonus || 0;
};

export const getSelectedGeniusFrequency = (
  state: WEB.RootState
): PaymentFrequency => {
  return state.ui.genius.selectedGeniusFrequency;
};

// /////////////////////////////////////////////////////////////////////////////
// reducers
// /////////////////////////////////////////////////////////////////////////////

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable consistent-return */
const genius = (state = initialState.ui.genius, action: WEB.Action) => {
  const { type, payload, error } = action;
  return produce(state, (draft: WEB.RootState['ui']['genius']) => {
    switch (type) {
      case types.UPDATE_CURRENT_GENIUS_PAGE: {
        draft.currentPage = payload?.page || '';
        return;
      }

      case types.SET_SELECTED_GENIUS_PRICE: {
        draft.selectedPrice = payload?.price;
        return;
      }

      case types.SET_SELECTED_ANNUAL_BONUS: {
        draft.annualBonus = payload?.annualBonus;
        return;
      }

      case types.SET_SELECTED_GENIUS_FREQUENCY: {
        draft.selectedGeniusFrequency = payload.frequency;
        return;
      }

      case types.LOGOUT_SUCCESS: {
        return initialState.ui.genius;
      }
      default:
    }
  });
};

export default genius;
