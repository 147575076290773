/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { useDispatch } from 'react-redux';
import useScript from 'react-script-hook';
import addInstitutionLogin from 'actions/async/addInstitutionLogin';
import albertClient from 'api/AlbertClient';
import { logger } from 'utils/logger';
import {
  OnEventMetadata,
  OnExitMetadata,
  OnSuccessMetadata,
  getExitParameters,
  getPlaidProduct,
} from 'utils/plaidUtils';
import wrappedFetch from 'utils/wrappedFetch';

// URL to add to async load to DOM
const PLAID_LINK_STABLE_URL =
  'https://cdn.plaid.com/link/v2/stable/link-initialize.js';

const PLAID_LINK_DYNAMIC_LOADER_URL =
  'https://cdn.plaid.com/link/2.0.726/link-dynamic-loader.js';

export enum PlaidLinkType {
  ADD = 'ADD',
  UPDATE = 'UPDATE',
}

export type PlaidLinkConfig = {
  /* choice of ADD or UPDATE */
  plaidLinkType?: PlaidLinkType;
  /* custom callback to handle success */
  handleOnSuccess?: (
    token: string,
    metadata: OnSuccessMetadata,
    getAuth: boolean
  ) => void;
  /* custom callback to handle event */
  handleOnEvent?: (event: string, metadata: OnEventMetadata) => void;
  /* custom callback to handle exit */
  handleOnExit?: (err: Error, metadata: OnExitMetadata) => void;
};

type LinkTokenConfiguration = {
  token: string;
  onSuccess: (token: string, metadata: OnSuccessMetadata) => void;
  onEvent?: (event: string, metadata: OnEventMetadata) => void;
  onExit?: (err: Error, metadata: OnExitMetadata) => void;
};

const usePlaidLink = (
  config: PlaidLinkConfig
): (({
  institution,
  getAuth,
}: {
  institution: string;
  getAuth: boolean;
}) => void) => {
  const { plaidLinkType = PlaidLinkType.ADD } = config;

  // ////////////////////////////////////
  /* ============= HOOKS ============= */
  // ////////////////////////////////////
  const dispatch = useDispatch();

  // Add the Plaid Link resource URL to DOM
  const [loading, error] = useScript({
    src: PLAID_LINK_STABLE_URL,
    checkForExisting: true,
    nonce: window.albertWeb.PlaidNonce,
  });

  // ////////////////////////////////////
  /* ========= LOCAL STATE =========== */
  // ////////////////////////////////////
  const [plaid, setPlaid] = React.useState(null);
  const [retries, setRetries] = React.useState(0);

  // ///////////////////////////////
  /* ========= HANDLERS ========= */
  // ///////////////////////////////
  const exitAndDestroyPlaidLink = () => {
    (plaid as any)?.exit({ force: true }, () => (plaid as any).destroy());
  };

  // ///////////////////////////////
  /* ========= HANDLERS ========= */
  // ///////////////////////////////
  const createOnSuccessHandler =
    (getAuth: boolean) => (token: string, metadata: OnSuccessMetadata) => {
      // Invoke handleOnSuccess if passed in
      config.handleOnSuccess &&
        config.handleOnSuccess(token, metadata, getAuth);
      exitAndDestroyPlaidLink();
    };

  const onEvent = (eventName: string, metadata: OnEventMetadata) => {
    // TODO: Tracking

    // Invoke handleOnEvent if passed in
    config.handleOnEvent && config.handleOnEvent(eventName, metadata);
  };

  const onExit = (err: Error, metadata: OnExitMetadata) => {
    // Step 1: Get exit parameters
    const exitParameters = getExitParameters(err, metadata);

    // Step 2: Make a request to record data. Notes from BE:
    // "We don't store the institution login object if attempted to create unsuccessfully
    // instead, we store a refresh log object with the connection data"
    dispatch(addInstitutionLogin(exitParameters));

    // Invoke handleOnExit if passed in
    config.handleOnExit && config.handleOnExit(err, metadata);
    exitAndDestroyPlaidLink();
  };

  // ////////////////////////////////////
  /* ========= PRESENT LINK ========== */
  // ////////////////////////////////////
  const buildPlaidConfig = (
    linkToken: string,
    getAuth: boolean
  ): LinkTokenConfiguration => ({
    onExit,
    onEvent,
    onSuccess: createOnSuccessHandler(getAuth),
    token: linkToken,
  });

  const createPlaidLink = (
    configuration: LinkTokenConfiguration
  ): Window['Plaid'] => {
    // Exit and destroy if instance exists
    plaid && exitAndDestroyPlaidLink();

    const newPlaidInstance = window.Plaid.create(configuration);
    setPlaid(newPlaidInstance);
    return newPlaidInstance;
  };

  const presentPlaidLinkAdd = async (
    institution: string,
    getAuth = false
  ): Promise<void> => {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        client_name: 'Albert',
        product: getPlaidProduct(getAuth),
      }),
    };

    const url = albertClient.institutionsLinkTokenView();
    const response = await wrappedFetch(url, options);
    const data: { link_token?: string } = await response.json();

    const linkToken = data?.link_token;

    if (linkToken) {
      const plaidConfig = buildPlaidConfig(linkToken, getAuth);
      const plaidLink = createPlaidLink(plaidConfig);

      // Open Plaid Link for institution
      plaidLink && plaidLink?.open(institution);
    }
  };

  // TODO: in the future
  // const getInstitutionLoginLinkTokenUpdate = async () => {};

  // /////////////////////////////////////////
  /* ============== ON MOUNT ============== */
  // /////////////////////////////////////////
  const RETRY_AMOUNT = 3;
  const RETRY_INTERVAL_SEC = 1;

  /* eslint-disable consistent-return */
  React.useEffect(() => {
    // Exit prematurely if script has not been added to the DOM yet
    if (loading) return;

    // Check if error before stalling to re-check if script loaded to DOM
    if (error) {
      logger.error(
        `Error loading Plaid Link resource URL to DOM, error: ${error}`
      );
      return;
    }

    // Check if script was loaded to DOM but hasn't been executed yet.
    // GitHub users report this as a race condition where the loading state
    // value from the useScript hook will become true even if the script hasn't
    // been executed yet
    if (!window.Plaid) {
      // Check if script was executed a couple times before logging error
      if (retries < RETRY_AMOUNT) {
        setTimeout(() => setRetries(retries + 1), RETRY_INTERVAL_SEC * 1000);
        return;
      }

      // If there was an error loading script to DOM, return and print error
      logger.error('Plaid is not loaded, cannot proceed');
      return;
    }

    // Close and destroy previous instance if exists - this should never happen
    // because we're listening for the "loading"
    if (plaid) {
      exitAndDestroyPlaidLink();
    }

    // Clean up
    return () => {
      // Step 1: Remove link tag from DOM
      const linkList = Array.from(
        document.querySelectorAll(
          `link[href="${PLAID_LINK_DYNAMIC_LOADER_URL}"]`
        )
      );
      linkList.forEach((link: Element) => {
        link?.parentNode?.removeChild(link);
      });

      // Step 2:Remove iframe from DOM
      const iframeList = Array.from(document.querySelectorAll('iframe'));
      const iframe = iframeList.find(({ id = '' }: HTMLIFrameElement) => {
        return id.includes('plaid-link-iframe');
      });
      iframe?.parentNode?.removeChild(iframe);

      // Step 3: Remove id that makes entire screen white on mobile
      document.documentElement.removeAttribute('id');
    };
  }, [loading, window.location.href, retries]);

  // /////////////////////////////////////////
  /* =========== FUNC TO RETURN =========== */
  // /////////////////////////////////////////
  const onOpen = ({
    institution,
    getAuth = false,
  }: {
    institution: string;
    getAuth: boolean;
  }) => {
    // Init Plaid Link for institution
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    presentPlaidLinkAdd(institution, getAuth);
  };

  return onOpen;
};

export default usePlaidLink;
