import { ChevronRight } from '@styled-icons/heroicons-outline/ChevronRight';
import * as React from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Button from 'components/common/Button';
import Carousel from 'components/common/Carousel';
import Modal from 'components/common/Modal';
import Spacer from 'components/common/Spacer';
import Text, { TextColor } from 'components/common/Text';
import ButtonsContainer from 'components/layout/ButtonsContainer';
import InfoPane from 'components/layout/InfoPane';
import PageBase from 'components/layout/PageBase';
import ArtMap from 'constants/ArtMap';
import Pages, { PageType } from 'constants/Pages';
import useNavigateFunction from 'hooks/useNavigateFunction';
import useVerifyCurrentPage from 'hooks/useVerifyCurrentPage';
import TourMobile from 'pages/genius/TourMobile';
import * as language from 'reducers/entities/language';
import { breakpoints, colors, mixins, spacers, theme } from 'styles';
import { flexGap } from 'styles/flexGap';
import { spacers as homeSpacers } from 'styles/home';
import * as WEB from 'types/interfaces';
import {
  getLanguageButtons,
  getLanguageInfo,
  getLanguageSection,
} from 'utils/getFromLanguage';
import { hash32 } from 'utils/hash';
import GeniusSingleScreen from './SingleScreen';

type Props = {
  nextPage: string;
  fromSignup?: boolean;
};

// Carousel
const ImageContainer = styled.div`
  height: 150px;
  img {
    display: block;
    margin: 0 auto;
    height: 100%;
  }
  @media ${breakpoints.mobileLarge} {
    height: calc(150px * 0.8);
  }
`;

const StyledCarouselPageText = styled(Text)`
  @media ${breakpoints.mobileLarge} {
    font-size: 14px;
  }
`;

const StyledDisclaimerText = styled(Text)`
  margin: ${homeSpacers.g5} 0;
  @media ${breakpoints.mobileLarge} {
    font-size: 14px;
  }
`;

const CarouselContainer = styled.div`
  width: 100%;
`;

const CarouselPageHeader = styled.h2`
  text-align: center;
  @media ${breakpoints.mobileLarge} {
    font-size: initial;
  }
`;

// Paged modal
const StyledListItem = styled.div`
  display: flex;
  padding-bottom: ${mixins.pxToRem('5px')};
`;

const StyledListItemText = styled(Text)`
  width: 100%;
  word-wrap: break-word;
  margin-left: ${mixins.pxToRem('5px')};
`;

const StyledChevronRight = styled.div`
  display: block;
  color: ${colors.primaryText};
  svg {
    width: 1rem;
  }
`;

const StyledListItemImage = styled.img`
  margin: ${spacers.tiny} 0;
`;

const StyledFaqItemHeader = styled.h3`
  margin-top: 24px;
  @media ${breakpoints.mobileLarge} {
    font-size: 14px;
  }
`;

const StyledFaqItemParagraph = styled.p`
  @media ${breakpoints.mobileLarge} {
    font-size: 14px;
  }
`;

const StyledGeniusLogo = styled.img<{ width: string }>`
  width: ${({ width }) => width};
  @media ${breakpoints.mobileLarge} {
    width: 119.69px;
  }
`;

const StyledButtonsContainer = styled(ButtonsContainer)`
  ${flexGap('18px')};
`;

const DisclaimerContainer = styled.div`
  a,
  a:hover,
  a:active,
  a:visited,
  a:focus {
    text-decoration: none;
  }
  margin-top: 48px;
  margin-bottom: 14px;
`;

const StyledLearnMoreText = styled(Text)`
  font-size: 14px;
`;

const GeniusTour = (props: Props): React.ReactElement => {
  const { fromSignup = true } = props;
  const { updateCurrentGeniusPage } = useNavigateFunction();
  // Make Redux is up-to-date with currentSignUpPage
  useVerifyCurrentPage(Pages.GENIUS_TOUR, PageType.GENIUS);
  // ///////////////////////////////
  /* =========== HOOKS ========== */
  // ///////////////////////////////
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // ///////////////////////////////
  /* ======== LOCAL STATE ======= */
  // ///////////////////////////////
  const [pageIndex, setPageIndex] = React.useState<null | number>(null);

  // ///////////////////////////////
  /* ======== MAPPED STATE ====== */
  // ///////////////////////////////
  const hasMobileUpdate = useSelector((state: WEB.RootState) => {
    return language.getSignupFlag(state, 'hasGeniusMobileUpdate');
  });
  const showMobileUpdate = hasMobileUpdate && isMobile;

  const geniusLanguage = useSelector(language.getGeniusLanguage);

  const languageCtx = useSelector((state: WEB.RootState) => {
    return language.getGeniusPageLanguage(state, Pages.GENIUS_TOUR);
  });
  const flowType = useSelector(
    (state: WEB.RootState) => state.entities.language.genius?.flow_type
  );

  // Show genius single screen flow if `flow_type` is "single_screen"
  if (flowType === 'single_screen') {
    return <GeniusSingleScreen {...props} />;
  }

  // //////////////////////////////////
  /* =========== HANDLERS ========== */
  // //////////////////////////////////
  const handleOnContinue = () => {
    dispatch(updateCurrentGeniusPage({ page: props.nextPage, fromSignup }));
  };

  const handleOnModalClose = () => {
    setPageIndex(null);
  };

  const handleOnClick = (indx: number) => () => {
    setPageIndex(indx);
  };

  const onClickGoBack = (): void => {
    navigate(-1);
  };

  // //////////////////////////////////
  /* =========== LANGUAGE ========== */
  // //////////////////////////////////
  const INTRO_SECTION = 'intro';
  const TOUR_SECTION = 'tour';

  // Sections
  const introSection = getLanguageSection(languageCtx, INTRO_SECTION);
  const carouselSection = geniusLanguage[TOUR_SECTION] as WEB.TourElement[];

  const infoPaneContext = getLanguageInfo(languageCtx);

  // Buttons
  const buttonsCtx = getLanguageButtons(languageCtx);

  // //////////////////////////////////
  /* ========= PAGED MODALS ======== */
  // //////////////////////////////////
  const modalPages: React.ReactElement[] = [];

  const createList = (list: string[]): React.ReactElement[] => {
    return list.map((text: string) => (
      <StyledListItem key={`modal-page-list-item-${hash32(text)}`}>
        <StyledChevronRight>
          <ChevronRight />
        </StyledChevronRight>
        <StyledListItemText>{text}</StyledListItemText>
      </StyledListItem>
    ));
  };

  const createListItems = (
    items: WEB.FaqElement['items']
  ): React.ReactElement[] => {
    return items.map((item: WEB.FaqItem) => (
      <React.Fragment key={`modal-faq-item-${item.header}`}>
        {item.header && (
          <StyledFaqItemHeader>{item.header}</StyledFaqItemHeader>
        )}
        {item.image && (
          <StyledListItemImage
            src={ArtMap(item.image.filename)}
            width={item.image.width || '100%'}
          />
        )}
        {item.text && (
          <StyledFaqItemParagraph>{item.text}</StyledFaqItemParagraph>
        )}
        {item.list && createList(item.list)}
        {item.disclaimer && (
          <DisclaimerContainer>
            <StyledDisclaimerText color={TextColor.GRAY}>
              {item.disclaimer}
              {item.disclaimer_url && (
                <a href={item.disclaimer_url} target='_blank' rel='noreferrer'>
                  <u>{'\nRead more'}</u>
                </a>
              )}
            </StyledDisclaimerText>
          </DisclaimerContainer>
        )}
      </React.Fragment>
    ));
  };

  // //////////////////////////////////
  /* =========== CAROUSEL ========== */
  // //////////////////////////////////
  const carouselPages = (carouselSection || []).map(
    (section: WEB.TourElement, index: number) => {
      const { header, text, image, faq, disclaimer } = section;

      // Create paged modal component for carousel section
      const modalIcon: React.ReactElement = (
        <img
          alt=''
          src={ArtMap(faq?.image?.filename)}
          width={faq?.image?.width || '100%'}
        />
      );

      modalPages.push(
        <Modal.Page icon={modalIcon} key={`modal-faq-${section.faqKey}`}>
          {createListItems(faq.items)}
        </Modal.Page>
      );

      return (
        <Carousel.Page
          className={`genius-carousel-page-${index}`}
          key={`carousel-faq-${section.faqKey}`}
        >
          <Spacer space={spacers.tabSmall} desktopOnly />
          <ImageContainer>
            <img src={ArtMap(image?.filename)} alt='' />
          </ImageContainer>
          <Spacer space={spacers.small} />
          <CarouselPageHeader>{header}</CarouselPageHeader>
          <StyledCarouselPageText align='center'>{text}</StyledCarouselPageText>
          {disclaimer ? (
            <StyledDisclaimerText color={TextColor.GRAY} align='center'>
              {disclaimer}
            </StyledDisclaimerText>
          ) : (
            <Spacer space={homeSpacers.g10} />
          )}
          <StyledLearnMoreText
            weight='700'
            underline
            isLinkButton
            onClick={handleOnClick(index)}
            color={TextColor.LOGAN_GRAY}
          >
            <a className='carousel-learn-more-link'>Learn more</a>
          </StyledLearnMoreText>
        </Carousel.Page>
      );
    }
  );

  // /////////////////////////////////////////
  /* ========= MOBILE DEVICE FLOW ======== */
  // ////////////////////////////////////////
  if (showMobileUpdate) {
    return (
      <TourMobile
        navigateToNextPage={handleOnContinue}
        modalPages={modalPages}
      />
    );
  }

  // /////////////////////////////////////////
  /* ============ DEFAULT FLOW =========== */
  // ////////////////////////////////////////
  return (
    <>
      <PageBase isGenius>
        <Modal.Navigation
          id='genius-tour-nav-modal'
          defaultPage={pageIndex || 0}
          show={pageIndex !== null}
          onCancel={handleOnModalClose}
          bottomPad={35}
        >
          {modalPages}
        </Modal.Navigation>
        <Spacer space={homeSpacers.g2} mobileOnly />
        <StyledGeniusLogo
          src={ArtMap(
            introSection?.image?.filename ? introSection?.image?.filename : ''
          )}
          alt='Albert Genius Logo'
          width={`${introSection?.image?.width || 20}%`}
        />
        <h1>{introSection?.header}</h1>
        <Text className='description'>{introSection?.text}</Text>
        <Spacer space={spacers.tabLarge} />
        <CarouselContainer>
          <Carousel.Slider id='genius-tour-carousel' pause={pageIndex !== null}>
            {carouselPages}
          </Carousel.Slider>
        </CarouselContainer>
        <InfoPane.Mobile expanders={infoPaneContext.expanders} size='small' />
        <StyledButtonsContainer>
          <Button
            id='genius-tour-confirm'
            onClick={handleOnContinue}
            theme={theme.genius}
          >
            {buttonsCtx?.primary}
          </Button>
          {!fromSignup && (
            <Button
              id='go-back'
              onClick={onClickGoBack}
              secondary
              theme={theme.genius}
            >
              Not now
            </Button>
          )}
        </StyledButtonsContainer>
      </PageBase>
    </>
  );
};

export default GeniusTour;
