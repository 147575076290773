import * as React from 'react';

export function PlusIcon() {
  return (
    <svg
      width='12'
      height='12'
      viewBox='0 0 12 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.89442 10.9884C6.89442 11.4524 6.51042 11.8364 6.04642 11.8364C5.58242 11.8364 5.19842 11.4524 5.19842 10.9884V7.05237H1.26242C0.798423 7.05237 0.414423 6.66837 0.414423 6.20437C0.414423 5.74037 0.798423 5.35637 1.26242 5.35637H5.19842V1.42037C5.19842 0.956374 5.58242 0.572374 6.04642 0.572374C6.51042 0.572374 6.89442 0.956374 6.89442 1.42037V5.35637H10.8304C11.2944 5.35637 11.6784 5.74037 11.6784 6.20437C11.6784 6.66837 11.2944 7.05237 10.8304 7.05237H6.89442V10.9884Z'
        fill='white'
      />
    </svg>
  );
}
