import * as React from 'react';
import { Field, FieldRenderProps, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import TrackingBase from 'tracking.Base';
import { v4 as uuidv4 } from 'uuid';
import completeBankingAccount from 'actions/async/completeBankingAccount';
import getAppData from 'actions/async/getAppData';
import updateProfile from 'actions/async/updateProfile';
import removeBanner from 'actions/banner/removeBanner';
import BannerPane from 'components/BannerPane';
import Button from 'components/common/Button';
import Checkbox from 'components/common/Checkbox';
import Dropdown from 'components/common/Dropdown';
import Input from 'components/common/Input';
import Spacer from 'components/common/Spacer';
import Text from 'components/common/Text';
import ButtonsContainer from 'components/layout/ButtonsContainer';
import ContentPane, { ContentContainer } from 'components/layout/ContentPane';
import PageBase from 'components/layout/PageBase';
import TwoPaneWrapper from 'components/layout/TwoPaneWrapper';
import SignupInfoPane from 'components/signup/SignupInfoPane';
import AlbertTrackingEvent from 'constants/AlbertTrackingEvent';
import Pages from 'constants/Pages';
import useNavigateFunction from 'hooks/useNavigateFunction';
import useVerifyCurrentPage from 'hooks/useVerifyCurrentPage';
import CardHasShipped from 'pages/signup/CardHasShipped';
import Navbar from 'pages/unauthenticated/Navbar';
import * as appData from 'reducers/entities/appData';
import * as language from 'reducers/entities/language';
import * as profile from 'reducers/entities/profile';
import { breakpoints, fontSizes, mixins, spacers } from 'styles';
import { spacers as homeSpacers } from 'styles/home';
import * as WEB from 'types/interfaces';
import { delayWithState } from 'utils/delay';
import {
  getLanguageButtons,
  getLanguageInfo,
  getLanguageSection,
} from 'utils/getFromLanguage';
import { logger } from 'utils/logger';
import { composeValidators, isRequired } from 'utils/validators';

type Props = {
  nextPage: string;
};

const HomeHeading = styled.p`
  font-weight: 700;
  margin-bottom: ${mixins.pxToRem('10px')};
  @media ${breakpoints.mobileLarge} {
    margin: ${homeSpacers.g8} 0 0 0;
  }
`;
const ShippingHeading = styled.p`
  font-weight: 700;
  margin-bottom: ${mixins.pxToRem('24px')};
  @media ${breakpoints.mobileLarge} {
    margin: ${homeSpacers.g11} 0 0 0;
  }
`;

const HideSection = styled.div`
  &.hidden {
    display: none;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  & + label {
    ${fontSizes.fontSize16}
  }
`;

const AddressInput = ({
  input,
  meta,
  sessiontoken,
  shippingSection,
  setShippingAddress,
  setHomeShippingAddress,
  homeShippingAddress,
  onChange,
  setIsLoadingPlace,
  setSessiontoken,
}: FieldRenderProps<any, HTMLElement>) => {
  return (
    <Dropdown.AddressTypeahead
      id='address-typeahead'
      value={input.value}
      sessiontoken={sessiontoken}
      placeholder={shippingSection?.placeholder}
      floatingLabel={shippingSection?.placeholder}
      onSelect={(value: any) => {
        onChange(value?.description);
        input.onChange({ address: value?.description });
      }}
      onPlaceRetrieved={(err, place) => {
        // From Google Places docs: https://developers.google.com/maps/documentation/places/web-service/autocomplete#sessiontoken
        // > The session begins when the user starts typing a query, and concludes when they select a place
        // > and a call to Place Details is made.
        // Reset sessiontoken after a call to Place Details is made.
        setSessiontoken(uuidv4());

        if (err) {
          logger.error(err);
        }
        if (place) {
          setShippingAddress(place);
          /* the first run of this should always be the home
           * address */
          if (Object.keys(homeShippingAddress).length === 0) {
            setHomeShippingAddress(place);
          }
        }

        setIsLoadingPlace(false);
      }}
      invalid={meta?.touched && meta.error}
      errorText={meta?.error}
      showError
      fullWidth
    />
  );
};

const ApartmentInput = ({
  input,
  meta,
  apartmentSection,
  onChange,
}: Partial<WEB.InputFieldProps>) => {
  return (
    <Input.Text
      id='apartment-input'
      type='text'
      floatingLabel={apartmentSection?.placeholder}
      placeholder={apartmentSection?.placeholder}
      {...input}
      onChange={(e) => {
        const { value } = e.target;
        onChange(value);
        input?.onChange(e);
      }}
      invalid={meta?.touched && meta.error}
      errorText={apartmentSection?.errorMessage || meta?.error}
    />
  );
};

const CashShippingAddress = (props: Props) => {
  // //////////////////////////////////
  /* =========== HOOKS ========== */
  // //////////////////////////////////
  const dispatch = useDispatch();
  const { updateCurrentSignupPage } = useNavigateFunction();
  // Make sure Redux is up-to-date with currentSignUpPage
  useVerifyCurrentPage(Pages.CASH_SHIPPING_ADDRESS);

  const continueBtnRef = React.useRef<HTMLButtonElement>(null);
  // ///////////////////////////////////
  /* =========== SELECTORS ========== */
  // ///////////////////////////////////
  const languageCtx = useSelector((state: WEB.RootState) =>
    language.getSignupPageLanguage(state, Pages.CASH_SHIPPING_ADDRESS)
  );
  const bankingData = useSelector(
    (state: WEB.RootState) =>
      appData.getValueByField(state, 'banking') as WEB.BankingAppData
  );
  const bankingAccount = bankingData?.account;

  const property: WEB.Property | null = useSelector(
    (state: WEB.RootState) =>
      appData.getLegalProperty(state) || appData.getPrimaryProperty(state)
  );

  // //////////////////////////////////
  /* =========== STATE ========== */
  // //////////////////////////////////

  // Unique sessiontoken for Google Place requests.
  const [sessiontoken, setSessiontoken] = React.useState<string>(uuidv4());

  /* Note: we default this to true on purpose. We need to wait for the
   * address to be retrieved from the AddressTypeahead, and then we witll
   * set this to false in its onPlaceRetrieved
   */
  const [isLoadingPlace, setIsLoadingPlace] = React.useState<boolean>(true);
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [isCompleted, setIsCompleted] = React.useState<boolean>(false);

  const [shippingAddressInput, setShippingAddressInput] =
    React.useState<string>(property?.google_address || '');
  const [aptInput, setAptInput] = React.useState<string | null>(
    property && property.apt ? property.apt : null
  );
  const [shippingAddress, setShippingAddress] =
    React.useState<WEB.AddressPhysical>({});
  const [homeShippingAddress, setHomeShippingAddress] =
    React.useState<WEB.AddressPhysical>({});

  const [sameAsBilling, setSameAsBilling] = React.useState(!!property);
  const [isContinueBtnClicked, setIsContinueBtnClicked] = React.useState(false);

  const personalInfo = useSelector((state: WEB.RootState) =>
    profile.getValueByField(state, 'personal_info')
  );
  const showCardHasShipped = !!personalInfo.signup_card_shipped;

  // //////////////////////////////////
  /* =========== EFFECTS ========== */
  // //////////////////////////////////

  React.useEffect(() => {
    if (
      bankingData?.account?.onboarding_status ===
      WEB.OnboardingStatus.GALILEO_APPLICATION_QUEUED
    ) {
      // There is a problem with the Galileo service. Bring the user to the end of signup, skipping Jumpstart and DD.
      logger.warn('Galileo application queued. Moving user to end of signup.');
      dispatch(updateCurrentSignupPage({ page: Pages.DOWNLOAD_APP }));
    }
  }, [bankingData?.account?.onboarding_status]);

  React.useEffect(() => {
    // The "continue" button was clicked while the google place request was
    // still underway. Now the request is complete, so try
    // submitting the page again.
    if (!isLoadingPlace && isContinueBtnClicked) {
      if (continueBtnRef?.current) {
        continueBtnRef.current.click();
      }
    }
  }, [isLoadingPlace, isContinueBtnClicked]);

  // //////////////////////////////////
  /* =========== LANGUAGE ========== */
  // //////////////////////////////////
  const HOME_KEY = 'home';
  const SHIPPING_KEY = 'shipping';
  const APARTMENT_KEY = 'apartment';

  const infoPaneContext = getLanguageInfo(languageCtx);
  const buttonsCtx = getLanguageButtons(languageCtx);
  const homeSection = getLanguageSection(languageCtx, HOME_KEY);
  const shippingSection = getLanguageSection(languageCtx, SHIPPING_KEY);
  const apartmentSection = getLanguageSection(languageCtx, APARTMENT_KEY);

  // //////////////////////////////////
  /* =========== HANDLERS ========== */
  // //////////////////////////////////
  const handleSameAsBillingOnClick = (): void => {
    setSameAsBilling(!sameAsBilling);
    if (sameAsBilling) {
      setShippingAddressInput(property?.google_address || '');
      setShippingAddress(homeShippingAddress);
      setAptInput(property?.apt ? property?.apt : null);
    } else {
      setShippingAddressInput('');
      setAptInput(null);
    }
  };

  const onSubmit = async (): Promise<void | Record<string, any>> => {
    // Remove any error banner from previous attempts
    dispatch(removeBanner());

    // If the google place request is still underway, show the submit button's
    // loading state until that request is complete, then call this method
    // again to continue with submitting the page.
    if (isLoadingPlace) {
      setIsContinueBtnClicked(true);
      return;
    }

    if (Object.keys(shippingAddress).length === 0) {
      return {
        address:
          'Address could not be found. Please refresh and try again in a moment.',
      };
    }

    // If (for an unknown reason) the profile's banking account doesn't exist,
    // go back to the cash setup page to create it
    if (!bankingAccount) {
      dispatch(updateCurrentSignupPage({ page: Pages.CASH_SETUP }));
      return;
    }

    setIsSubmitting(true);
    let error = {};
    error = await dispatch(
      completeBankingAccount(bankingAccount.id, shippingAddress)
    );

    // Refresh user data
    await dispatch(getAppData());

    if (!error) {
      // Track open bank account event if no errors.
      // Reference to iOS: see CSHBankModuleDependencyBridge & BankingOnboardingService.
      TrackingBase.track({
        event: AlbertTrackingEvent.OPENED_CASH_BANK_ACCOUNT,
        trackSocial: true,
      });
      // On success, update Profile.personal_info['signup_card_shipped'].
      // NOTE: See ASCashDebitCardSetupWireframe.swift > showCardShipped().
      dispatch(
        await updateProfile({
          personal_info_signup_card_shipped: true,
        })
      );
    } else if (
      bankingData?.account?.onboarding_status ===
      WEB.OnboardingStatus.GALILEO_APPLICATION_QUEUED
    ) {
      // There is a problem with the Galileo service. Bring the user to the end of signup, skipping Jumpstart and DD.
      logger.warn('Galileo application queued. Moving user to end of signup.');
      dispatch(updateCurrentSignupPage({ page: Pages.DOWNLOAD_APP }));
      return;
    }

    setIsSubmitting(false);
    return error;
  };

  const submitCallback = async (errors?: any): Promise<void> => {
    if (!errors) {
      await delayWithState(400, setIsCompleted);
    }
  };

  // ///////////////////////////////
  /* ========= RENDER ======== */
  // ///////////////////////////////
  if (showCardHasShipped) {
    return (
      <CardHasShipped
        nextPage={props.nextPage}
        cardStyle={bankingData?.account?.debit_card_style}
      />
    );
  }

  const leadText =
    typeof infoPaneContext?.text === 'string' ? [infoPaneContext.text] : [];

  return (
    <>
      <Navbar split signupFlow />
      <TwoPaneWrapper>
        <SignupInfoPane />
        <ContentPane>
          <BannerPane />
          <ContentContainer>
            <PageBase
              mobileHeader={infoPaneContext?.header}
              mobileLead={leadText}
              buttonSpace='tiny'
            >
              <HideSection className={property ? '' : 'hidden'}>
                <HomeHeading>{homeSection.header}</HomeHeading>
                <Spacer space={homeSpacers.g2} mobileOnly />
                <Text>{`${property?.street} ${
                  property?.apt ? `Apt ${property?.apt}` : ''
                }`}</Text>
                <Text>{`${property?.city} ${property?.state} ${property?.zipcode}`}</Text>
                <Spacer space={spacers.tabSmall} desktopOnly />
              </HideSection>
              <Form
                onSubmit={onSubmit}
                initialValues={{
                  address: shippingAddressInput,
                  apartment: aptInput,
                }}
                render={({ handleSubmit }) => (
                  <form
                    onSubmit={(event) => {
                      handleSubmit(event)?.then(submitCallback);
                    }}
                  >
                    <HideSection className={property ? '' : 'hidden'}>
                      <ShippingHeading className='label-header'>
                        {shippingSection.header}
                      </ShippingHeading>
                      <Spacer space={homeSpacers.g2} mobileOnly />
                      <StyledCheckbox
                        id='same-as-billing-check'
                        check={sameAsBilling}
                        onClick={handleSameAsBillingOnClick}
                        label={shippingSection.text || ''}
                      />
                      <Spacer
                        space={sameAsBilling ? spacers.tabSmall : spacers.small}
                        desktopOnly
                      />
                    </HideSection>
                    <HideSection className={sameAsBilling ? 'hidden' : ''}>
                      <Spacer space={spacers.tiny} />
                      <Spacer space={spacers.miniscule} />
                      <Field
                        name='address'
                        component={AddressInput}
                        onChange={(value: string) => {
                          setShippingAddressInput(value);
                        }}
                        sessiontoken={sessiontoken}
                        validate={composeValidators(isRequired)}
                        shippingSection={shippingSection}
                        setShippingAddress={setShippingAddress}
                        setHomeShippingAddress={setHomeShippingAddress}
                        homeShippingAddress={homeShippingAddress}
                        setIsLoadingPlace={setIsLoadingPlace}
                        setSessiontoken={setSessiontoken}
                      />
                      <Spacer space={spacers.small} desktopOnly />
                      <Field
                        name='apartment'
                        component={ApartmentInput}
                        onChange={(value: string) => {
                          setAptInput(value);
                        }}
                        apartmentSection={apartmentSection}
                      />
                      <Spacer space={spacers.tabSmall} desktopOnly />
                    </HideSection>
                    <Spacer space={spacers.tab} mobileOnly />
                    <ButtonsContainer>
                      <Button
                        ref={continueBtnRef}
                        isCompleted={isCompleted}
                        isLoading={
                          isSubmitting ||
                          (isContinueBtnClicked && isLoadingPlace)
                        }
                        disabled={
                          isSubmitting ||
                          (isContinueBtnClicked && isLoadingPlace)
                        }
                      >
                        {isCompleted
                          ? buttonsCtx?.complete
                          : buttonsCtx?.primary}
                      </Button>
                    </ButtonsContainer>
                  </form>
                )}
              />
            </PageBase>
          </ContentContainer>
        </ContentPane>
      </TwoPaneWrapper>
    </>
  );
};

export default CashShippingAddress;
