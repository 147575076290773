import * as React from 'react';
import styled from 'styled-components';
import breakpoints from 'styles/breakpoints';
import { renderLabels } from './shared';

export type Props = {
  /* unique id for the input group */
  id: string;
  /* label container that will show only one label for all inputs */
  label?: string;
  /* smaller label text that will sit right next to the label */
  smallLabel?: string;
  /* show smallLabel on newline */
  smallLabelNewLine?: boolean;
  /* description label text that will sit beneath the label */
  description?: string;
  /* assuming an input group could contain other input fields */
  children?: React.ReactNode | React.ReactNode[];
};

const StyledInputGroup = styled.div<Props>`
  display: flex;
  justify-content: start;
  align-items: self-start;
  > div {
    width: 100%;
  }
  > div:not(:last-child) {
    margin-right: 15px;
  }

  @media ${breakpoints.inputGroup} {
    flex-direction: column;
  }
`;

const InputGroup = (props: Props): React.ReactElement => {
  return (
    <div>
      {renderLabels(props)}
      <StyledInputGroup {...props}>{props.children}</StyledInputGroup>
    </div>
  );
};

export default InputGroup;
