import branch from 'branch-sdk';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import * as profile from 'reducers/entities/profile';
import * as WEB from 'types/interfaces';

const DownloadTheAppButton = () => {
  const email = useSelector((state: WEB.RootState) =>
    profile.getValueByField(state, 'email')
  );

  // Ref to "Download the App" link
  const downloadAppLinkRef = React.useRef<HTMLAnchorElement>(null);

  React.useEffect(() => {
    // create a deep link to prefill email once the user installs the app and is directed to login screen
    const linkData = {
      channel: 'Website',
      feature: 'DownloadTheApp',
      tags: [],
      data: {
        email,
      },
    };

    branch.link(linkData, (err, link) => {
      // bind elements
      if (downloadAppLinkRef?.current && link) {
        downloadAppLinkRef.current.href = link;
      }
    });
  }, [email]);

  return (
    <BlueButton
      id='download-the-app-link'
      href='https://app.albrt.co/download'
      ref={downloadAppLinkRef}
    >
      Download the app
    </BlueButton>
  );
};

export default DownloadTheAppButton;

const BlueButton = styled.a`
  display: inline-block;
  background-color: ${({ theme }) => theme.colors.primaryAlbertBrand};
  color: ${({ theme }) => theme.colors.white};
  font-weight: bold;
  font-size: 0.875rem;
  border-radius: 60px;
  width: 100%;
  text-align: center;
  cursor: pointer;
  outline: none;
  border: none;
  padding: 1em;
  text-decoration: none;

  &:visited,
  &:active,
  &:hover,
  &:link {
    color: ${({ theme }) => theme.colors.white};
    text-decoration: none;
  }
`;
