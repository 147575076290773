import * as React from 'react';
import styled from 'styled-components';
import { breakpoints } from 'styles';

const StyledSvg = styled.svg`
  width: 25px;
  height: 25px;
  @media ${breakpoints.mobileLarge} {
    width: 18px;
    height: 18px;
  }
`;

/**
 * A big X icon.
 */
const Cross = () => (
  <StyledSvg viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.29083 8.99908L0.53559 15.7543L2.27914 17.4979L9.03437 10.7426L15.7912 17.4994L17.5347 15.7559L10.7779 8.99908L17.5352 2.24184L15.7916 0.498291L9.03437 7.25553L2.2787 0.499865L0.535156 2.24341L7.29083 8.99908Z'
      fill='black'
    />
  </StyledSvg>
);

export default Cross;
