import * as React from 'react';

export function CreateBudgetIconV2() {
  return (
    <svg
      width='53'
      height='52'
      viewBox='0 0 53 52'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4.47168 10C4.47168 8.89543 5.36711 8 6.47168 8H46.4717C47.5762 8 48.4717 8.89543 48.4717 10V44C48.4717 45.1046 47.5762 46 46.4717 46H6.47168C5.36711 46 4.47168 45.1046 4.47168 44V10Z'
        fill='#C3DBFF'
      />
      <path
        d='M4.47168 10C4.47168 8.89543 5.36711 8 6.47168 8H46.4717C47.5762 8 48.4717 8.89543 48.4717 10V17H4.47168V10Z'
        fill='#F51616'
      />
      <rect
        x='10.958'
        y='23.8379'
        width='8.86487'
        height='6.64865'
        rx='1'
        fill='white'
      />
      <rect
        x='10.958'
        y='32.7031'
        width='8.86487'
        height='6.64865'
        rx='1'
        fill='white'
      />
      <rect
        x='22.0391'
        y='23.8379'
        width='8.86487'
        height='6.64865'
        rx='1'
        fill='white'
      />
      <rect
        x='22.0391'
        y='32.7031'
        width='8.86487'
        height='6.64865'
        rx='1'
        fill='white'
      />
      <rect
        x='33.1201'
        y='23.8379'
        width='8.86487'
        height='6.64865'
        rx='1'
        fill='white'
      />
      <rect
        x='33.1201'
        y='32.7031'
        width='8.86487'
        height='6.64865'
        rx='1'
        fill='white'
      />
      <path
        d='M13.9717 11C13.4194 11 12.9717 11.4477 12.9717 12C12.9717 12.5523 13.4194 13 13.9717 13V11ZM16.4717 8.5C16.4717 9.88071 15.3524 11 13.9717 11V13C16.457 13 18.4717 10.9853 18.4717 8.5H16.4717ZM11.4717 8.5C11.4717 7.11929 12.591 6 13.9717 6V4C11.4864 4 9.47168 6.01472 9.47168 8.5H11.4717ZM13.9717 6C15.3524 6 16.4717 7.11929 16.4717 8.5H18.4717C18.4717 6.01472 16.457 4 13.9717 4V6Z'
        fill='#5C1A1A'
      />
      <path
        d='M38.9717 11C38.4194 11 37.9717 11.4477 37.9717 12C37.9717 12.5523 38.4194 13 38.9717 13V11ZM41.4717 8.5C41.4717 9.88071 40.3524 11 38.9717 11V13C41.457 13 43.4717 10.9853 43.4717 8.5H41.4717ZM36.4717 8.5C36.4717 7.11929 37.591 6 38.9717 6V4C36.4864 4 34.4717 6.01472 34.4717 8.5H36.4717ZM38.9717 6C40.3524 6 41.4717 7.11929 41.4717 8.5H43.4717C43.4717 6.01472 41.457 4 38.9717 4V6Z'
        fill='#5C1A1A'
      />
      <path
        d='M26.4717 11C25.9194 11 25.4717 11.4477 25.4717 12C25.4717 12.5523 25.9194 13 26.4717 13V11ZM28.4717 8.5C28.4717 10.0309 27.4372 11 26.4717 11V13C28.8199 13 30.4717 10.8351 30.4717 8.5H28.4717ZM24.4717 8.5C24.4717 6.96911 25.5062 6 26.4717 6V4C24.1235 4 22.4717 6.16489 22.4717 8.5H24.4717ZM26.4717 6C27.4372 6 28.4717 6.96911 28.4717 8.5H30.4717C30.4717 6.16489 28.8199 4 26.4717 4V6Z'
        fill='#5C1A1A'
      />
    </svg>
  );
}
