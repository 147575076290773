import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import logout from 'actions/async/logout';
import BannerPane from 'components/BannerPane';
import ContentPane from 'components/layout/ContentPane';
import InfoPane from 'components/layout/InfoPane';
import TwoPaneWrapper from 'components/layout/TwoPaneWrapper';
import Navbar from 'pages/unauthenticated/Navbar';
import * as WEB from 'types/interfaces';
import { LocationState } from 'types/locationstate';
import TwoFAConfirmNumber from './TwoFAConfirmCode';

const TwoFAFlow = (): React.ReactElement | null => {
  const dispatch = useDispatch();

  // ///////////////////////////////////////
  /* ===== HISTORY.LOCATION STATE  ====== */
  // ///////////////////////////////////////
  const location = useLocation();
  const locationState = (location?.state || {}) as LocationState;
  const { prompt2FA } = locationState;

  // The 2FA flow should not be manually accessible and should only be prompted programmatically.
  // Utilize react router's location.state to only allow access to this flow if `prompt2FA` is set
  // to true, and redirect to home page otherwise. Disabling checkpoint nav (local and dev only) will
  // allow you to stay on this page post-reload (for QA).
  const disabledCheckpointNav = !!JSON.parse(
    localStorage.getItem('disableCheckpointNav') as string
  );

  if (prompt2FA !== true && !disabledCheckpointNav) {
    // This route is only accessible if logged-in. Log user out and redirect to login.
    dispatch(logout());
    window.location.href = window.albertWeb.SplashAppDomain;
    return null;
  }

  // ///////////////////////////////////////
  /* ============ INFO PANE  ============ */
  // ///////////////////////////////////////
  const infoPaneContext: WEB.LanguageInfo = {
    image: {
      filename: 'img_lock_check.svg',
      width: 25,
    },
    header: 'ID confirmation code',
    text: "We'll send you a confirmation code to verify your identity",
  };

  return (
    <>
      <Navbar split signupFlow logoOnly />
      <TwoPaneWrapper>
        <InfoPane.Desktop {...infoPaneContext} />
        <ContentPane>
          <BannerPane />
          <TwoFAConfirmNumber mobileHeader={infoPaneContext?.header || ''} />
        </ContentPane>
      </TwoPaneWrapper>
    </>
  );
};

export default TwoFAFlow;
