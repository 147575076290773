import styled from 'styled-components';
import breakpoints, { sizes } from 'styles/breakpoints';

type Props = {
  space: string;
  orientation?: 'horizontal' | 'vertical';
  desktopOnly?: boolean;
  mobileOnly?: boolean;
};

const Spacer = styled.div<Props>`
  ${({ space, orientation }) => `
    ${orientation === 'horizontal' ? 'padding-right' : 'padding-top'}: ${space}
  `};
  ${({ desktopOnly, mobileOnly }) => {
    if (desktopOnly) {
      return `@media ${breakpoints.mobileLarge} {
        display: none
      };`;
    }
    if (mobileOnly) {
      return `@media (min-width: ${sizes.mobileLarge}) {
        display: none
      };`;
    }
    return '';
  }}
`;

export default Spacer;
