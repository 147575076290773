import * as React from 'react';

const CashAdvanceIcon = (): React.ReactElement => (
  <svg
    width='16'
    height='28'
    viewBox='0 0 16 28'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M0.6 20.8432C1.752 23.2432 4.408 24.4272 6.84 24.8112V26.0592C6.84 26.9552 7.544 27.6592 8.44 27.6592C9.336 27.6592 10.04 26.9552 10.04 26.0592V24.8432C13.784 24.2992 15.864 21.8032 15.864 18.7312C15.864 16.1072 13.848 13.4512 10.04 12.6192V6.63521C11.192 6.95521 12.056 7.62721 12.664 8.87521C12.952 9.45121 13.528 9.83521 14.2 9.83521C15.128 9.83521 15.896 9.06721 15.896 8.13921C15.896 7.88321 15.832 7.62721 15.736 7.40321C14.52 4.90721 12.344 3.62721 10.04 3.17921V1.86721C10.04 0.97121 9.336 0.26721 8.44 0.26721C7.544 0.26721 6.84 0.97121 6.84 1.86721V3.05121C2.84 3.56321 0.76 6.60321 0.76 9.25921C0.76 11.6592 2.488 14.5712 6.84 15.4992V21.3872C5.4 21.1312 4.216 20.4912 3.672 19.3712C3.384 18.7952 2.808 18.4112 2.136 18.4112C1.208 18.4112 0.44 19.1792 0.44 20.1072C0.44 20.3632 0.504 20.6192 0.6 20.8432ZM4.152 9.25921C4.152 7.94721 4.856 6.79521 6.84 6.47521V12.0432C4.728 11.4352 4.152 10.4112 4.152 9.25921ZM10.04 21.4512V16.1072C11.864 16.6192 12.472 17.5792 12.472 18.7312C12.472 20.2352 11.864 21.1312 10.04 21.4512Z'
      fill='#0066FF'
    />
  </svg>
);

export default CashAdvanceIcon;
