import * as React from 'react';
import styled from 'styled-components';
import NumberSlider from 'components/common/NumberSlider';
import Spacer from 'components/common/Spacer';
import SquareCard from 'components/common/SquareCard';
import SquareCardGroup from 'components/common/SquareCard/SquareCardGroup';
import Text, { TextColor, TextSizes } from 'components/common/Text';
import { breakpoints, colors, fontSizes, mixins, spacers } from 'styles';
import { spacers as homeSpacers } from 'styles/home';
import * as WEB from 'types/interfaces';

// The smallest mobile breakpoint for styling the price cards
const MIN_MOBILE_BREAKPOINT = 500;

type Props = {
  showMobileUpdate: boolean;
  pricingSection: WEB.PricingElement;
  selectedPrice: number;
  updateSelectedPriceAndAnnualBonus: (amount: number) => void;
  defaultGeniusPrice: number;
};

const MobileUpdateOptionContent = styled.div`
  margin: auto;
`;

const MobileOptionDollar = styled.span`
  font-size: 18px;
`;
const MobileOptionMonth = styled.span`
  font-size: 12px;
`;

const Sizer = styled.span`
  ${fontSizes.fontSize16}
  font-weight: 700;
`;

const MobileUpdateOptionGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: auto;
  margin: auto;
  max-width: 300px;
  margin-bottom: ${homeSpacers.g6};
`;
const MobileUpdateOption = styled.div<{
  isSelected: boolean;
  onClick: () => void;
}>`
  margin: auto;
  position: relative;
  display: flex;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  cursor: pointer;
  font-size: 24px;
  font-weight: 500;
  color: ${({ isSelected, theme }) =>
    isSelected ? theme.colors.white : theme.colors.primaryGray};
  border: ${({ isSelected, theme }) =>
    !isSelected ? `2px solid ${theme.colors.lightGray1}` : 'none'};
  background: ${({ isSelected, theme }) =>
    isSelected
      ? 'linear-gradient(216.83deg, #C5A26D -9.59%, #9C7949 30.14%, #4A2700 109.59%)'
      : theme.colors.lightGray3};
  @media ${breakpoints.mobileLarge} {
    text-align: center;
  }
`;

const StyledSquareCardGroup = styled(SquareCardGroup)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: auto;
  grid-gap: 5px 22px;
`;

const StyledSquareCard = styled(SquareCard)<{ selected?: boolean }>`
  border-color: ${({ selected }) => !selected && '#E6E7EB'};
  padding: ${mixins.pxToRem('23px')} ${mixins.pxToRem('18px')};

  @media (max-width: ${MIN_MOBILE_BREAKPOINT}px) {
    min-height: ${mixins.pxToRem('145px')};
  }

  .square-card-header {
    ${fontSizes.fontSize24}
    font-weight: 700;
    color: ${({ selected }) => !selected && colors.primaryGray};
  }

  .square-card-body {
    ${fontSizes.fontSize16}
    color: ${({ selected }) => !selected && colors.primaryGray};
  }
`;

// Container to give the same height to the price cards and slider,
// so that the "Custom amount" button link doesn't move up/down as
// the user clicks between the cards and slider.
const PriceContainer = styled.div<{ showMobileUpdate: boolean }>`
  ${({ showMobileUpdate }) =>
    !showMobileUpdate &&
    `
      @media ${breakpoints.mobileLarge} {
        height: 36vw;
      }
      @media (max-width: 700px) {
        height: 38vw;
      }
      @media (max-width: ${MIN_MOBILE_BREAKPOINT}px) {
        height: ${mixins.pxToRem('200px')};
      }
`}
`;

const GeniusCustomPricingCards = ({
  showMobileUpdate,
  pricingSection,
  selectedPrice,
  updateSelectedPriceAndAnnualBonus,
  defaultGeniusPrice,
}: Props) => {
  const [selectedCustom, setSelectedCustom] = React.useState<boolean>(false);
  const priceRange = pricingSection?.priceRange;
  const geniusPriceRanges = priceRange?.values;
  const sliderValues = priceRange?.slider_values;

  const pricingCards = geniusPriceRanges?.map((price: number) => {
    const isPriceSelected = price === selectedPrice;
    if (showMobileUpdate) {
      return (
        <MobileUpdateOption
          key={`styled-square-card-genius-pricing-${price}`}
          onClick={() => updateSelectedPriceAndAnnualBonus(price)}
          isSelected={isPriceSelected}
        >
          <MobileUpdateOptionContent>
            <MobileOptionDollar>$</MobileOptionDollar>
            {price}
            {isPriceSelected && <MobileOptionMonth>/mo</MobileOptionMonth>}
          </MobileUpdateOptionContent>
        </MobileUpdateOption>
      );
    }
    return (
      <StyledSquareCard
        grid
        key={`styled-square-card-genius-pricing-${price}`}
        value={price}
        header={
          <>
            <Sizer>$</Sizer>
            {price}
          </>
        }
        body='per month'
        size='large'
        selected={isPriceSelected}
        clickHandler={updateSelectedPriceAndAnnualBonus}
      />
    );
  });

  const geniusCards = showMobileUpdate ? (
    <MobileUpdateOptionGroup>{pricingCards}</MobileUpdateOptionGroup>
  ) : (
    <StyledSquareCardGroup>{pricingCards}</StyledSquareCardGroup>
  );

  const handleOnGoBackToCards = (): void => {
    // Select default card if selected custom price is not in view (cards).
    if (!geniusPriceRanges?.includes(selectedPrice)) {
      updateSelectedPriceAndAnnualBonus(defaultGeniusPrice);
    }
    setSelectedCustom(!selectedCustom);
  };

  return (
    <>
      <PriceContainer showMobileUpdate={showMobileUpdate}>
        {selectedCustom ? (
          <>
            <Spacer space={spacers.small} />
            <NumberSlider
              id='genius-pricing-slider'
              sliderValues={sliderValues}
              value={selectedPrice}
              valueDefault={priceRange?.label?.price || sliderValues?.[1]}
              minText={priceRange?.label?.text}
              onChange={updateSelectedPriceAndAnnualBonus}
            />
            <Spacer space={spacers.tiny} />

            <Spacer space={spacers.large} />
            <Spacer space={spacers.tab} />
            {!showMobileUpdate && <div style={{ paddingTop: '50px' }}></div>}
          </>
        ) : (
          <>
            {geniusCards}
            {!showMobileUpdate && <Spacer space={homeSpacers.g5} />}
          </>
        )}
      </PriceContainer>
      <Text
        id='custom-amount-link'
        size={TextSizes.MEDIUM}
        color={TextColor.GRAY}
        weight='400'
        align='center'
        onClick={handleOnGoBackToCards}
        isLinkButton
      >
        {selectedCustom ? 'See suggested amounts' : 'Custom amount'}
      </Text>
    </>
  );
};

export default GeniusCustomPricingCards;
