/* eslint-disable no-param-reassign */

/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable no-useless-return */
import produce from 'immer';
import initialState from 'store.initialState';
import types from 'actions/types';
import * as WEB from 'types/interfaces';
import { GeniusPayments } from 'types/interfaces';

// /////////////////////////////////////////////////////////////////////////////
// selectors
// /////////////////////////////////////////////////////////////////////////////

export const shouldFetchGeniusPayments = (state: WEB.RootState): boolean =>
  state.entities.geniusPayments.length === 0;

export const getGeniusPayments = (state: WEB.RootState): GeniusPayments[] => {
  return state.entities.geniusPayments;
};

// /////////////////////////////////////////////////////////////////////////////
// reducers
// /////////////////////////////////////////////////////////////////////////////

const geniusPayments = (
  state = initialState.entities.geniusPayments,
  action: WEB.Action
) => {
  const { type, payload, error } = action;
  return produce(
    state,
    (draft: WEB.RootState['entities']['geniusPayments']) => {
      switch (type) {
        case types.GET_GENIUS_PAYMENTS_SUCCESS: {
          draft = payload.data?.length ? payload.data : [];
          return draft;
        }
        default:
      }
    }
  );
};

export default geniusPayments;
