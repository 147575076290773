import styled from 'styled-components';
import { breakpoints } from 'styles';

export const DesktopWrapper = styled.div`
  display: block;
  @media ${breakpoints.mobileLarge} {
    display: none;
  }
`;
export const MobileWrapper = styled.div`
  display: none;
  @media ${breakpoints.mobileLarge} {
    display: block;
  }
`;
