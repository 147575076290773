/* eslint-disable no-case-declarations */
import { ExternalLink } from '@styled-icons/heroicons-outline/ExternalLink';
import * as React from 'react';
import ReactMarkdown from 'react-markdown';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import deactivateProfile from 'actions/async/deactivateProfile';
import logout from 'actions/async/logout';
import Button, { StyledLinkButton } from 'components/common/Button';
import Text, { TextColor, textTransition } from 'components/common/Text';
import ArtMap, { Art } from 'constants/ArtMap';
import { UniqueBannerStyles } from 'constants/BannerActionType';
import { BannerActionType, NotificationTypes, ZIndex } from 'constants/index';
import { breakpoints, fontSizes, mixins } from 'styles';
import { spacers } from 'styles/home';
import * as WEB from 'types/interfaces';

export type Props = WEB.Banner & {
  /* the theme to use for this component */
  theme?: any;
  hideIcon?: boolean;
  linkMessage?: string;
  linkUrl?: string | null;
};

const getBackgroundColor = (props: Props) => {
  const { theme, type } = props;
  if (type === NotificationTypes.SUCCESS) {
    return theme?.colors.bannerSuccessBg;
  }
  if (type === NotificationTypes.WARNING) {
    return theme?.colors.bannerWarningBg;
  }
  if (type === NotificationTypes.NEUTRAL) {
    return theme?.colors.lightGray3;
  }
  return 'transparent';
};

const getColor = (props: Props): string => {
  const { theme, type } = props;
  if (type === NotificationTypes.SUCCESS) {
    return theme?.colors.darkConfirmation1;
  }
  if (type === NotificationTypes.WARNING) {
    return theme?.colors.primaryWarning;
  }
  if (type === NotificationTypes.NEUTRAL) {
    return theme?.colors.darkGray1;
  }
  return 'inherit';
};

const StyledWarningIcon = styled.img`
  width: ${mixins.pxToRem('16px')};
  height: ${mixins.pxToRem('16px')};
  margin-right: 5px;
  path,
  line,
  circle {
    stroke: ${getColor};
    fill: ${getColor};
  }
`;

const ActionButton = styled(StyledLinkButton)`
  margin-left: ${mixins.pxToRem('44px')};
  background-color: ${({ theme }) => theme.colors.primaryWarning};
  :hover:not(:disabled) {
    background-color: ${({ theme }) => theme.colors.primaryWarning};
    filter: brightness(0.95);
  }
  @media ${breakpoints.tabletSmall} {
    min-width: 99px;
  }
  @media ${breakpoints.mobileMedium} {
    min-width: 73px;
    margin-left: ${mixins.pxToRem('16px')};
  }
`;

const StyledBanner = styled.div<Props>`
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${ZIndex.BANNER};
  background-color: ${getBackgroundColor};
  ${mixins.fadeIn()};
  color: ${getColor};
  ${fontSizes.fontSize12}
  width: 100%;
  min-height: 43px;
  border-radius: 4px;
  display: flex;
  justify-content: ${({ bannerAction }) =>
    bannerAction && UniqueBannerStyles.includes(bannerAction)
      ? 'space-between'
      : 'center'};
  align-items: center;
  padding: ${mixins.pxToRem('11px')} ${mixins.pxToRem('25px')};
`;

const Message = styled.div<Props>`
  display: flex;
  text-align: ${({ bannerAction }) =>
    bannerAction && UniqueBannerStyles.includes(bannerAction)
      ? 'left'
      : 'center'};

  p {
    margin: 0;
  }
`;

const StyledExternalLinkIcon = styled(ExternalLink)`
  flex-shrink: 0;
  vertical-align: -3px;
  color: ${({ theme }) => theme.colors.darkGray1};
  width: ${mixins.pxToRem('16px')};
  height: ${mixins.pxToRem('16px')};
  margin-left: ${spacers.g2};
  ${textTransition}
`;

const StyledLink = styled.a<Props>`
  color: ${getColor};
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  ${textTransition}
  &:hover {
    color: ${({ theme }) => theme.colors.primaryText};
    svg {
      color: ${({ theme }) => theme.colors.primaryText};
    }
  }
`;

const Banner = (props: Props): React.ReactElement | null => {
  const { message, type, bannerAction, hideIcon, linkMessage, linkUrl } = props;
  const dispatch = useDispatch();
  if (!message) return null;

  const getActionButton = (): React.ReactElement | null => {
    switch (props.bannerAction) {
      case BannerActionType.LOGIN_APP:
        const onClick = () => {
          dispatch(deactivateProfile());
          dispatch(logout());
          return true;
        };
        return (
          <ActionButton
            {...props}
            onClick={onClick}
            href='https://app.albrt.co/login-app'
            target='_blank'
          >
            Get app
          </ActionButton>
        );

      case BannerActionType.LOGIN_APP_KYC:
        const onKYCClick = () => {
          dispatch(logout());
          return true;
        };
        return (
          <ActionButton
            {...props}
            onClick={onKYCClick}
            href='https://app.albrt.co/login-app'
            target='_blank'
          >
            Get app
          </ActionButton>
        );
      default:
        return null;
    }
  };

  const showWarningIcon =
    !hideIcon &&
    type === NotificationTypes.WARNING &&
    !bannerAction &&
    // Exclude multi-line banner messages
    !message.includes('\n\n');

  return (
    <StyledBanner {...props}>
      <Message {...props}>
        {showWarningIcon && (
          <StyledWarningIcon
            src={ArtMap(Art.WarningBannerIcon)}
            alt='warning'
            {...props}
          />
        )}
        {linkUrl ? (
          <Text color={TextColor.GRAY_DARK} inline>
            {message}
            <StyledLink
              {...props}
              href={linkUrl}
              target='_blank'
              rel='noreferrer'
            >
              {linkMessage || ''}
              <StyledExternalLinkIcon />
            </StyledLink>
          </Text>
        ) : (
          <div>
            <ReactMarkdown>{message}</ReactMarkdown>
          </div>
        )}
      </Message>
      {bannerAction && getActionButton()}
    </StyledBanner>
  );
};

export default Banner;
