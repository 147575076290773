import { v4 as uuidv4 } from 'uuid';

const wrappedFetch = (url: string, ...args: any[]) => {
  const pageUrl = new URL(document.location.href);
  let split = url.split('#');
  const hash = split[1] ? split[1] : '';
  split = split[0].split('?');
  const query = split[1] ? split[1] : '';
  const base = split[0];
  const finalUrl = `${base}?${query}${query ? '&' : ''}cb=${uuidv4()}&csrf=${
    window.albertWeb.CSRFMatch
  }${pageUrl.searchParams.get('refresh') != null ? '&refresh=1' : ''}#${hash}`;

  const responsePromise = fetch(finalUrl, ...args);
  responsePromise.then((response) => {
    /* on CSRF failure, attempt to force refresh */
    if (response.status === 480) {
      if (pageUrl.searchParams.get('refresh') == null) {
        pageUrl.searchParams.append('refresh', '1');
        window.location.replace(url.toString());
      }
    }
  });

  return responsePromise;
};

export default wrappedFetch;
