import { DebitCardStyle } from 'constants/index';
import { DebitCardAvailability } from 'types/interfaces';

const DEFAULT_ORDER = [
  DebitCardStyle.GENIUS,
  DebitCardStyle.WHITE,
  DebitCardStyle.BLUE,
];

const GENIUS_LOCKED_ORDER = [
  DebitCardStyle.WHITE,
  DebitCardStyle.BLUE,
  DebitCardStyle.GENIUS,
];

export const getOrderedOptions = (
  showPrices: boolean,
  isGeniusLocked: boolean,
  debitCardAvailability?: DebitCardAvailability[]
) => {
  if (showPrices && debitCardAvailability) {
    // Ordering rules when there is a surcharge on debit card colors
    const sortedAvailability = [...debitCardAvailability].sort(
      (a, b) =>
        // Prioritize the highest price first
        b.price - a.price ||
        // Sort same-priced items according to the default order
        DEFAULT_ORDER.indexOf(a.style) - DEFAULT_ORDER.indexOf(b.style)
    );
    return sortedAvailability.map((availability) => availability.style);
  }
  // Ordering rules when there is no surcharge, dependent on whether
  // user has a genius subscription
  return isGeniusLocked ? GENIUS_LOCKED_ORDER : DEFAULT_ORDER;
};
