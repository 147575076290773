import addBanner from 'actions/banner/addBanner';
import types from 'actions/types';
import AlbertClient from 'api/AlbertClient';
import NotificationTypes from 'constants/NotificationTypes';
import * as WEB from 'types/interfaces';
import { onErrorDisplayDefaultBanner } from 'utils/axiosMiddlewareUtils';

export interface UpdateEmailFields {
  email: string;
}

export default (
    fieldsToUpdate: UpdateEmailFields,
    source: 'signup' | 'kyc' | 'settings',
    includeBanner = false
  ): WEB.ActionCreator<Promise<Record<string, any>>> =>
  async (dispatch: WEB.Dispatch): Promise<Record<string, any>> => {
    if (includeBanner) {
      const bannerBody = `We sent a new code to your email **${fieldsToUpdate.email}**.`;
      dispatch(addBanner(NotificationTypes.SUCCESS, bannerBody, true));
    }

    let error = false;
    const response = await dispatch({
      types: [
        types.UPDATE_EMAIL_REQUEST,
        types.UPDATE_EMAIL_SUCCESS,
        types.UPDATE_EMAIL_FAILURE,
      ],
      payload: {
        request: {
          method: 'put',
          url: AlbertClient.profileUpdateEmailView(),
          data: {
            ...fieldsToUpdate,
            source,
          },
        },
        options: {
          onError: (middlewareOptions: any, options: any) => {
            error = true;
            return onErrorDisplayDefaultBanner(middlewareOptions, options);
          },
        },
      },
    });
    return { response, error };
  };
