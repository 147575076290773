import * as React from 'react';
import styled from 'styled-components';
import { TieredPricingLottie } from 'components/common/animations';
import Checkmark from 'components/icons/Checkmark';
import { PricingElementFeatures } from 'types/interfaces';
import { formatIncome } from 'utils/textFormatters';

type Props = {
  features?: PricingElementFeatures;
  tieredPricing: TieredPricing;
};

type TieredPricing = {
  active_tier: string | null;
  default_tier: string;
  has_renewed: boolean;
  program_key: string;
  renewal_tier: string | null;
  tiers: {
    features: string[];
    monthly_price: number;
    name: string;
    rank: number;
    renewal_monthly_price: string | null;
    tier_key: string;
  }[];
};
const PricingTierContainer = styled.div`
  width: 100%;
  border-radius: 8px;
  border: 2px solid black;
  padding: 24px;
  display: flex;
  gap: 24px;
  flex-direction: column;
  align-items: center;
`;

const PricingTierHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-weight: 700;
`;

const PricingTierPoint = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 500;
  font-size: 0.875rem;
`;

const PricingTierPoints = styled.div`
  display: flex;
  flex-direction: column;
  align-self: start;
  gap: 8px;
`;

const CheckmarkWrapper = styled.div`
  flex-shrink: 0;
`;

const PricingTierCard = (props: {
  title: string;
  price: number;
  points: string[];
}) => {
  const decimals = props.price % 1 === 0 ? 0 : 2;
  const dollarAmount = formatIncome(props.price, decimals);
  return (
    <PricingTierContainer>
      <PricingTierHeader>
        <div>{props.title}</div>
        <div>{dollarAmount}/mo</div>
      </PricingTierHeader>
      <TieredPricingLottie />
      <PricingTierPoints>
        {props.points.map((point) => (
          <PricingTierPoint key={point}>
            <CheckmarkWrapper>
              <Checkmark />
            </CheckmarkWrapper>
            <div>{point}</div>
          </PricingTierPoint>
        ))}
      </PricingTierPoints>
    </PricingTierContainer>
  );
};

const GeniusTierCards = ({ features, tieredPricing }: Props) => (
  <>
    {tieredPricing.tiers.map((tier) => {
      const points = (features?.[tier.tier_key] || [])
        .filter((feature) => feature.included)
        .map((feature) => feature.name);
      return (
        <PricingTierCard
          key={tier.tier_key}
          title={tier.name}
          points={points}
          price={tier.monthly_price}
        />
      );
    })}
  </>
);

export default GeniusTierCards;
