import * as React from 'react';
import styled from 'styled-components';
import AlbertClient from 'api/AlbertClient';
import {
  DropdownWidthSize,
  getWidth,
} from 'components/common/Dropdown/Dropdown';
import Typeahead, {
  Props as BaseTypeaheadDropdownProps,
  Option,
} from 'components/common/Dropdown/TypeaheadDropdown';
import useDebounce from 'hooks/useDebounce';
import { breakpoints, colors, fontSizes, mixins } from 'styles';
import * as WEB from 'types/interfaces';
import { DropdownItem } from 'types/interfaces';
import { logger } from 'utils/logger';
import wrappedFetch from 'utils/wrappedFetch';

export type Props = Omit<
  BaseTypeaheadDropdownProps & {
    id: string;
    label?: string;
  },
  'items' | 'onChange' | 'onSelect'
> & {
  onSelect: (value: WEB.InfluencerOption | null) => void;
};

const DEBOUNCE_TIMEOUT = 275; // 0.275 seconds

const Container = styled.div<{ widthSize?: DropdownWidthSize }>`
  width: ${({ widthSize }) => getWidth(widthSize)};

  @media ${breakpoints.mobileLarge} {
    width: 100%;
  }
`;

const Placeholder = styled.div`
  color: ${colors.primaryText};
  .header {
    margin-bottom: ${mixins.pxToRem('12px')};
    ${fontSizes.fontSize16}
  }
  .subtitle {
    ${fontSizes.fontSize16}
    color: ${colors.primaryGray};
  }
`;

const InfluencerTypeahead = (props: Props): React.ReactElement => {
  const { id, widthSize } = props;

  const [isTyping, setIsTyping] = React.useState(false);
  const [query, setQuery] = React.useState('');
  const debouncedQuery = useDebounce(query.trim(), DEBOUNCE_TIMEOUT);

  // ///////////////////////////////
  /* =========== STATE ========== */
  // ///////////////////////////////

  const [options, setOptions] = React.useState<WEB.InfluencerOption[]>([]);

  // //////////////////////////////////
  /* =========== HANDLERS ========== */
  // //////////////////////////////////

  const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setQuery(event?.target?.value || '');
    !isTyping && setIsTyping(true);
  };

  const onSelect = (option: Option): void => {
    props.onSelect(option ? options[option.key] : option);
  };

  const handleSearchInfluencer = (): void => {
    // Search for influencer.
    const queryParams = new URLSearchParams({ query });
    const influencerEndpoint = AlbertClient.influencersView(queryParams);

    // Async call to fetch influencers by query.
    wrappedFetch(influencerEndpoint)
      .then((res) => res.json())
      .then((res) => {
        setIsTyping(false);
        setOptions(res);
      })
      .catch((error) => {
        logger.error(error);
      });
  };

  // ///////////////////////////////
  /* ========= LIFECYCLE ======== */
  // ///////////////////////////////

  React.useEffect(() => {
    if (debouncedQuery) {
      handleSearchInfluencer();
    } else if (isTyping) {
      setIsTyping(false);
    }
  }, [debouncedQuery]);

  // ///////////////////////////////
  /* =========== RENDER ========== */
  // ///////////////////////////////

  const items: DropdownItem[] = options.map((option: WEB.InfluencerOption) => ({
    value: option.display_name,
    subValue: option.channel,
  }));

  const emptyText = (
    <Placeholder>
      <div className='header'>No results found.</div>
    </Placeholder>
  );

  return (
    <Container widthSize={widthSize}>
      <Typeahead
        {...props}
        isLoading={isTyping}
        id={`${id}-typeahead`}
        items={items}
        emptyText={emptyText}
        onSelect={onSelect}
        onChange={onChange}
      />
    </Container>
  );
};

export default InfluencerTypeahead;
