import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import TrackingBase from 'tracking.Base';
import updateProfile from 'actions/async/updateProfile';
import updateSavingsSetup, {
  UpdateSavingsAccountFields,
} from 'actions/async/updateSavingsSetup';
import hideBanner from 'actions/banner/hideBanner';
import removeBanner from 'actions/banner/removeBanner';
import getNextPage from 'actions/getNextPage';
import Button from 'components/common/Button';
import Spacer from 'components/common/Spacer';
import { FilledSquareCard } from 'components/common/SquareCard/SquareCardV2';
import Text, { TextColor, TextSizes } from 'components/common/Text';
import ButtonsLayout from 'components/layout/ButtonsLayout';
import LeadContent from 'components/layout/LeadContent';
import PageBase from 'components/layout/PageBase';
import AlbertTrackingEvent from 'constants/AlbertTrackingEvent';
import ArtMap from 'constants/ArtMap';
import Pages from 'constants/Pages';
import { SavingsMethods } from 'constants/SavingsSchedules';
import useNavigateFunction from 'hooks/useNavigateFunction';
import * as language from 'reducers/entities/language';
import { spacers } from 'styles/home';
import * as WEB from 'types/interfaces';
import { delayWithState } from 'utils/delay';
import { getLanguageButtons, getLanguageSection } from 'utils/getFromLanguage';

type Props = {
  nextPage: string;
  agreedToCoastalTerms: boolean;
};

const CardContentContainer = styled.div`
  display: flex;
  align-items: center;
  margin: auto 0;
`;

const CardTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 24px;
  max-width: 300px;
`;

const CardHeaderContainer = styled.div`
  display: flex;
`;

const StyledSquareCard = styled(FilledSquareCard)`
  // Disable hover
  pointer-events: none;
`;

const Badge = styled.div`
  flex-shrink: 0;
  width: fit-content;
  display: flex;
  border-radius: 19px;
  padding: 4px 10px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.primaryConfirmation};
  font-size: 10px;
  font-weight: 700;
  margin-left: 6px;
  line-height: 1rem;
`;

const ImageWrapper = styled.div`
  width: 70px;
`;

const SavingsBenefitsInfo = ({ nextPage, agreedToCoastalTerms }: Props) => {
  const { updateCurrentSignupPage } = useNavigateFunction();
  const dispatch = useDispatch<WEB.Dispatch>();
  const [isCompleted, setIsCompleted] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const languageCtx = useSelector((state: WEB.RootState) =>
    language.getSignupPageLanguage(state, Pages.SAVINGS_BENEFITS_INFO)
  );

  const introCtx = getLanguageSection(languageCtx, 'intro');
  const smartMoneyCtx = getLanguageSection(languageCtx, 'smartMoney');
  const buttonsCtx = getLanguageButtons(languageCtx);

  const handleSubmit = async () => {
    dispatch(removeBanner());

    try {
      setIsLoading(true);
      const savingsData: UpdateSavingsAccountFields = {
        transfer_interval: SavingsMethods.AUTO,
        // User agrees to Coastal terms on new banking signup flow.
        agreed_to_coastal_terms: agreedToCoastalTerms,
      };
      // Create savings account.
      const savingsPromise = dispatch(updateSavingsSetup(savingsData));
      const profilePromise = dispatch(
        updateProfile({
          personal_info_signup_auto_savings: true,
          auto_saving: WEB.AutoSaving.ON,
        })
      );
      const [savingsError, { error: profileError }] = await Promise.all([
        savingsPromise,
        profilePromise,
      ]);
      dispatch(hideBanner(false));

      // Handle the update savings setup request response
      if (savingsError) throw new Error();

      // Handle the update profile request response
      if (profileError) throw new Error();

      TrackingBase.track({
        event: AlbertTrackingEvent.ENABLED_SAVINGS,
        trackOnce: true,
        trackSocial: true,
      });

      await goToNextPage({ showSuccessCheck: true });
    } catch (e) {
      setIsLoading(false);
    }
  };

  const handleOnSkip = async () => {
    // Remove any error banner from previous attempts
    dispatch(removeBanner());
    setIsLoading(true);
    const { error } = await dispatch(
      updateProfile({
        personal_info_signup_auto_savings: false,
        auto_saving: WEB.AutoSaving.PAUSED,
      })
    );
    if (!error) {
      await goToNextPage({ showSuccessCheck: false });
    }
  };

  const goToNextPage = async ({
    showSuccessCheck,
  }: {
    showSuccessCheck: boolean;
  }) => {
    const { error, nextPageOverride } = await dispatch(getNextPage());
    if (error) {
      return;
    }
    if (showSuccessCheck) {
      setIsLoading(false);
      await delayWithState(400, setIsCompleted);
    }
    dispatch(updateCurrentSignupPage({ page: nextPageOverride || nextPage }));
  };

  return (
    <PageBase>
      <LeadContent
        header={introCtx?.header || ''}
        text={introCtx?.text || ''}
      />
      <Spacer space={spacers.g5} />
      <StyledSquareCard width='100%' height='130px'>
        <CardContentContainer>
          <ImageWrapper>
            <img src={ArtMap(smartMoneyCtx?.image?.filename || '')} alt='' />
          </ImageWrapper>
          <CardTextContainer>
            <CardHeaderContainer>
              <Text>{smartMoneyCtx.header}</Text>
              <Badge>{smartMoneyCtx.labelText}</Badge>
            </CardHeaderContainer>
            <Text size={TextSizes.SMALL}>{smartMoneyCtx.text}</Text>
          </CardTextContainer>
        </CardContentContainer>
      </StyledSquareCard>
      <Spacer space={spacers.g6} />
      <Text color={TextColor.GRAY} size={TextSizes.TINY} weight='600'>
        You’ll be able to change your settings or pause deposits at any time in
        the app.
      </Text>
      <Spacer space={spacers.g14} />
      <Text color={TextColor.GRAY} size={TextSizes.TINY}>
        By continuing, I agree to&nbsp;
        <a
          href='https://albert.com/terms?hide_nav=true&hide_banner=true#econsent'
          target='_blank'
          rel='noreferrer'
        >
          <b>E-Sign Consent</b>
        </a>
        &nbsp;and I authorize Albert to transfer money from my linked accounts.
      </Text>
      <ButtonsLayout
        marginTop={spacers.g5}
        primaryButton={
          <Button
            onClick={handleSubmit}
            isCompleted={isCompleted}
            isLoading={isLoading}
          >
            {isCompleted ? '' : buttonsCtx?.primary}
          </Button>
        }
        secondaryButton={
          buttonsCtx?.link ? (
            <Text weight='700' size='small' onClick={handleOnSkip} isLinkButton>
              <u>{buttonsCtx?.link}</u>
            </Text>
          ) : undefined
        }
      />
    </PageBase>
  );
};

export default SavingsBenefitsInfo;
