enum PageRoutes {
  BASE = '/',
  REDIRECT = '/redirect',

  TWO_FA = '/confirm-identity',
  LOGIN = '/login',
  SIGNUP = '/signup',
  DOWNLOAD_APP = '/download-app',
  NEW_ROUTE = '/new/route',

  // Signup
  SIGNUP_BASE = '/signup',
  APPLY = '/apply',

  // Genius
  GENIUS_BASE = '/discover-genius',

  // KYC
  KYC = '/identity',
  KYC_VERIFY_IDENTITY = '/verify-identity',

  // Banking
  CASH_SETUP = '/albert-cash',
  CASH_SHIPPING_ADDRESS = '/verify-shipping-address',

  // Link account
  LINK_ACCOUNT = '/signup/link-account',
  SIGNUP_MANUAL_AUTH = 'manual-account-verification',
}

export default PageRoutes;
