import { mixins } from 'styles';

export default {
  // Layout
  defaultPaddingLeftRight: '5vw',
  defaultPaddingTopBottom: '35px',
  mobilePaddingBottom: mixins.pxToRem('50px'),

  // Specific component
  bannerMobile: '135px',

  // Generic
  largeExtra: '80px',
  large: '60px',
  tabLarge: '50px',
  tab: '40px',
  tabSmall: '30px',
  small: '20px',
  tiny: '10px',
  miniscule: '6px',
  microscopic: '4px',
  submicroscopic: '2px',
};
