import * as React from 'react';

/**
 * A lock outline icon.
 */
const Lock = (props: any) => {
  return (
    <svg
      width='16'
      height='18'
      viewBox='0 0 16 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M13.8333 6.625H11.8889V4.75C11.8889 2.6875 10.1389 1 8 1C5.86111 1 4.11111 2.6875 4.11111 4.75V6.625H2.16667C1.50556 6.625 1 7.1125 1 7.75V15.8335C1 16.471 1.50556 16.9585 2.16667 16.9585H13.8333C14.4944 16.9585 15 16.471 15 15.8335V7.75C15 7.1125 14.4944 6.625 13.8333 6.625ZM5.27778 4.75C5.27778 3.2875 6.48333 2.125 8 2.125C9.51667 2.125 10.7222 3.2875 10.7222 4.75V6.625H5.27778V4.75ZM13.8333 15.8335H2.16667V7.75H10.7222H11.8889H13.8333V15.8335Z'
        fill='currentColor'
        stroke='currentColor'
        strokeWidth='0.2'
      />
    </svg>
  );
};

export default Lock;
