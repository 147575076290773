import * as React from 'react';

const CancelSubscriptionsIcon = (): React.ReactElement => (
  <svg
    width='33'
    height='27'
    viewBox='0 0 33 27'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M28.384 21.4539L28.128 21.7419C27.424 22.5419 26.24 22.6379 25.408 21.9659L19.968 17.4539L22.016 15.6939L28.352 21.1659C28.448 21.2299 28.448 21.3579 28.384 21.4539ZM2.624 24.2059C4.704 26.7019 8.416 26.7339 11.04 24.6539C13.696 22.5099 14.208 18.7979 12.192 16.3019L12.032 16.1099L12.544 15.6619L14.72 17.4859L15.872 18.4459L23.264 24.5899C25.504 26.4139 28.768 26.1579 30.688 23.9819H30.656L30.976 23.6299C32.288 22.0619 32.032 19.7899 30.496 18.5419L24.608 13.4539L30.496 8.3979C32.032 7.1819 32.288 4.8779 30.976 3.3099L30.656 2.9579C28.768 0.781897 25.504 0.525898 23.264 2.3499L12.512 11.2459L12.032 10.8299L12.192 10.6379C14.208 8.1419 13.696 4.4299 11.04 2.2859C8.416 0.205896 4.704 0.237898 2.624 2.7339C0.672 5.1659 1.152 8.8459 3.776 11.0219C5.28 12.2699 7.104 12.7819 8.8 12.5259L9.92 13.4539L8.8 14.3819C7.104 14.1579 5.28 14.6699 3.776 15.9179C1.152 18.0939 0.672 21.7739 2.624 24.2059ZM5.248 22.0619C4.48 21.0699 4.768 19.5019 5.952 18.5099C7.136 17.5179 8.768 17.4539 9.536 18.4459C10.368 19.4379 10.112 21.0379 8.928 21.9979C7.68 22.9899 6.08 23.0219 5.248 22.0619ZM5.248 4.8779C6.08 3.9179 7.68 3.9499 8.928 4.9419C10.112 5.9019 10.368 7.5019 9.536 8.4939C8.768 9.4859 7.136 9.4219 5.952 8.4299C4.768 7.4379 4.48 5.8699 5.248 4.8779ZM15.168 13.4539L16.704 12.1739L25.408 4.9739C26.24 4.3019 27.424 4.3979 28.128 5.1979L28.384 5.4859C28.448 5.5819 28.448 5.7099 28.352 5.7739L22.016 11.2139L17.312 15.2459L15.168 13.4539Z'
      fill='#0066FF'
    />
  </svg>
);

export default CancelSubscriptionsIcon;
