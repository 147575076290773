import * as Sentry from '@sentry/react';
import * as React from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import enableGenius from 'actions/async/enableGenius';
import updateProfile from 'actions/async/updateProfile';
import removeBanner from 'actions/banner/removeBanner';
import setSelectedAnnualBonus from 'actions/setSelectedAnnualBonus';
import setSelectedGeniusPrice from 'actions/setSelectedGeniusPrice';
import TopLoadingBar from 'components/TopLoadingBar';
import Button from 'components/common/Button';
import TopInfoButton from 'components/common/Button/TopInfoButton';
import Modal from 'components/common/Modal';
import MobileInfoModal from 'components/common/Modal/MobileInfoModal';
import Spacer from 'components/common/Spacer';
import Text, { TextColor, TextSizes } from 'components/common/Text';
import GeniusCustomPricingCards from 'components/genius/GeniusCustomPricingCards';
import GeniusTierCards from 'components/genius/GeniusTierCards';
import InfoPane from 'components/layout/InfoPane';
import LeadContent from 'components/layout/LeadContent';
import PageBase from 'components/layout/PageBase';
import ArtMap from 'constants/ArtMap';
import { HIDE_NAV_AND_BANNER } from 'constants/PageDisplayOptions';
import PageRoutes from 'constants/PageRoutes';
import Pages, { PageType } from 'constants/Pages';
import SplashLink from 'constants/SplashLink';
import useBrowserNavigationOverride from 'hooks/useBrowserNavigationOverride';
import useNavigateFunction from 'hooks/useNavigateFunction';
import useVerifyCurrentPage from 'hooks/useVerifyCurrentPage';
import GeniusSuccess from 'pages/genius/Success';
import * as language from 'reducers/entities/language';
import * as profile from 'reducers/entities/profile';
import { mixins, spacers } from 'styles';
import { spacers as homeSpacers } from 'styles/home';
import * as WEB from 'types/interfaces';
import { GeniusTieredPricing } from 'types/interfaces';
import { delayWithState } from 'utils/delay';
import {
  getLanguageButtons,
  getLanguageInfo,
  getLanguageSection,
} from 'utils/getFromLanguage';
import { getRemainingPageHeight } from 'utils/layout';
import { logger } from 'utils/logger';

type Props = {
  /* Page to render after Genius flow. */
  pageOnComplete: string;
  /* Next Genius page */
  nextPage: string;
  /* are we in signup flow */
  fromSignup?: boolean;
};

const FullDeviceHeightContainer = styled.div<{
  isEnabled: boolean;
}>`
  ${({ isEnabled }) =>
    isEnabled &&
    `
      display: flex;
      flex-direction: column;
      // 35px comes from ContentPane padding
      min-height: calc(${getRemainingPageHeight()} - 35px);
      text-align: center;
      
`}
`;

const OfferTextContainer = styled.div<{ showMobileUpdate: boolean }>`
  b {
    ${({ showMobileUpdate }) => showMobileUpdate && 'font-weight: 700'};
  }
`;

const CardHeaderContent = styled.h3<{ showMobileUpdate: boolean }>`
  text-align: center;
  ${({ showMobileUpdate }) =>
    showMobileUpdate &&
    `
    font-size: 14px;
    margin-top: 30px;
    @media (max-height: 675px) {
      margin-top: 0;
    }
  `}
`;

const StyledButton = styled(Button)`
  width: ${mixins.pxToRem('210px')};
`;

const LeadContentWrapper = styled.div<{ showMobileUpdate: boolean }>`
  ${({ showMobileUpdate }) => showMobileUpdate && 'text-align: center;'}
`;

const DisclosureWrapper = styled.div<{ showMobileUpdate: boolean }>`
  ${({ showMobileUpdate }) => showMobileUpdate && 'display: none;'}
`;

const OfferText = ({
  hasFreeMonth,
  hasZero,
  showMobileUpdate,
}: {
  hasFreeMonth: boolean;
  hasZero: boolean;
  showMobileUpdate: boolean;
}) => {
  if (!hasFreeMonth) {
    return (
      <OfferTextContainer showMobileUpdate={showMobileUpdate}>
        Try <b>a month before you pay.</b> Cancel in 30 days and you won't be
        charged.
      </OfferTextContainer>
    );
  }
  if (hasZero) {
    return (
      <OfferTextContainer showMobileUpdate={showMobileUpdate}>
        Try a <b>month free</b> and cancel at any time.
      </OfferTextContainer>
    );
  }
  return (
    <OfferTextContainer showMobileUpdate={showMobileUpdate}>
      Try <b>a month free.</b> Cancel within 30 days and you will not be
      charged.{' '}
    </OfferTextContainer>
  );
};

const MobileDeviceHeader = styled.h2`
  margin: 0;
`;

const BottomContainer = styled.div<{ showMobileUpdate: boolean }>`
  ${({ showMobileUpdate }) =>
    showMobileUpdate && 'margin-top: auto; padding-top: 24px;'}
`;

const ButtonsContainer = styled.div<{ showMobileUpdate: boolean }>`
  display: flex;
  margin-top: ${({ showMobileUpdate }) =>
    showMobileUpdate ? homeSpacers.g4 : homeSpacers.g9};
  align-items: center;
  button {
    width: 100%;
  }
`;

const GeniusPricing = (props: Props): React.ReactElement => {
  const { fromSignup = false } = props;
  const { updateCurrentGeniusPage } = useNavigateFunction();
  // Make Redux is up-to-date with currentSignUpPage
  useVerifyCurrentPage(Pages.GENIUS_PRICING, PageType.GENIUS);

  // ///////////////////////////////
  /* =========== HOOKS ========== */
  // ///////////////////////////////
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // ///////////////////////////////
  /* ======== MAPPED STATE ====== */
  // ///////////////////////////////
  const geniusLanguage = useSelector(language.getGeniusLanguage);
  const languageCtx = useSelector((state: WEB.RootState) => {
    return language.getGeniusPageLanguage(state, Pages.GENIUS_PRICING);
  });
  const didDownloadApp = useSelector(profile.getDidDownloadApp);
  const hasFreeMonth = useSelector(profile.getHasFreeMonthGeniusOffer);

  const PRICING_SECTION = 'pricing';
  const pricingSection = geniusLanguage[PRICING_SECTION] as WEB.PricingElement;

  const hasMobileUpdate = useSelector((state: WEB.RootState) => {
    return language.getSignupFlag(state, 'hasGeniusMobileUpdate');
  });
  const showMobileUpdate = hasMobileUpdate && isMobile;

  const showTierPricing = useSelector((state: WEB.RootState) =>
    profile.getValueByField(state, 'has_tiered_genius_pricing')
  );
  const tieredPricing: GeniusTieredPricing = useSelector(
    (state: WEB.RootState) => profile.getValueByField(state, 'tiered_pricing')
  );
  const defaultTierPricing = useSelector(profile.getDefaultTierPrice);
  let defaultGeniusPrice = showTierPricing
    ? defaultTierPricing
    : pricingSection?.default;

  if (showTierPricing && !defaultTierPricing) {
    const errorContext = {
      tieredPricing: JSON.stringify(tieredPricing),
    };
    Sentry.setContext('data', errorContext);
    logger.error(
      'Failed to determine default tier pricing for Genius pricing page'
    );
    defaultGeniusPrice = pricingSection?.default;
  }

  // ///////////////////////////////
  /* ======== LOCAL STATE ======= */
  // ///////////////////////////////
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [isCompleted, setIsCompleted] = React.useState<boolean>(false);
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [showInfoModal, setShowInfoModal] = React.useState<boolean>(false);
  const [annualBonus, setAnnualBonus] = React.useState(0);
  const [selectedPrice, setSelectedPrice] = React.useState(defaultGeniusPrice);
  const [showLoadingBar, setShowLoadingBar] = React.useState<boolean>(false);
  const [showSuccessPage, setShowSuccessPage] = React.useState(false);

  // //////////////////////////////////
  /* =========== LANGUAGE ========== */
  // //////////////////////////////////
  const INTRO_SECTION = 'intro';
  const SUBSCRIPTION_SECTION = 'subscription';
  const GENIUS_PRICING_MODAL = 'geniusPricing';

  // Sections
  const introSection = getLanguageSection(languageCtx, INTRO_SECTION);
  const modal = languageCtx?.modal;
  const modalSection = modal && modal[GENIUS_PRICING_MODAL];
  const subscriptionSection = getLanguageSection(
    languageCtx,
    SUBSCRIPTION_SECTION
  );

  const infoPaneContext = getLanguageInfo(languageCtx);

  // Buttons
  const buttonsCtx = getLanguageButtons(languageCtx);

  // Genius price ranges for cards
  const numMonthsFree = pricingSection?.numberMonthsFree;
  const priceRange = pricingSection?.priceRange;

  // //////////////////////////////////
  /* =========== HANDLERS ========== */
  // //////////////////////////////////

  const handleGoBack = React.useCallback(() => {
    if (showSuccessPage) {
      setShowSuccessPage(false);
      return true;
    }
    // Navigate backwards normally
    return false;
  }, [showSuccessPage]);

  useBrowserNavigationOverride(handleGoBack, true);

  const updateSelectedPriceAndAnnualBonus = (amount: number) => {
    const annualBonusAmount = geniusLanguage?.pricing?.annualBonus?.[
      amount
    ] as number;
    setAnnualBonus(annualBonusAmount);
    setSelectedPrice(amount);
  };

  const handleOnCancelModal = () => setShowModal(false);
  const handleOnSubmitModal = async (): Promise<void> => {
    setShowModal(false);
    setShowLoadingBar(true);

    // Set completed_genius_signup to true before proceeding.
    const completedGeniusSignup: any = await dispatch(
      updateProfile({ completed_genius_signup: true })
    );

    await delayWithState(400, setShowLoadingBar, false, false);

    if (!completedGeniusSignup?.error) {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      const redirect = window.decodeURIComponent(params.r);
      let shouldBypassNav = false;
      if (params.r && redirect !== '') {
        const url = new URL(redirect);
        const splashAppURL = new URL(window.albertWeb.SplashAppDomain);
        const signupAppURL = new URL(window.albertWeb.SignupAppDomain);

        if (
          url.host === window.location.host ||
          url.host === splashAppURL.host ||
          url.host === signupAppURL.host
        ) {
          /* the querystring is valid and on an albert domain */
          shouldBypassNav = true;
        }
      }

      if (shouldBypassNav && didDownloadApp) {
        /* Note, we do not use `history.push` here because we may change domains */
        window.location.href = redirect;
      } else if (shouldBypassNav && !didDownloadApp) {
        navigate(PageRoutes.DOWNLOAD_APP, { replace: true });
      } else {
        dispatch(
          updateCurrentGeniusPage({
            page: props.pageOnComplete,
            fromSignup,
          })
        );
      }
    }
  };

  const handleOnContinue = async () => {
    if (showTierPricing) {
      setShowSuccessPage(true);
    } else {
      await handleSubmit();
    }
  };

  const handleSubmit = async () => {
    // Check if genius price selected is $0
    if (selectedPrice === 0) {
      setShowModal(true);
      return;
    }

    setIsSubmitting(true);

    // Remove any error banner from previous attempts
    dispatch(removeBanner());

    // Update state in Redux
    dispatch(setSelectedAnnualBonus(annualBonus));
    dispatch(setSelectedGeniusPrice(selectedPrice));

    // Enable genius
    const enableGeniusError: any = await dispatch(
      enableGenius({
        genius_enabled: false,
        number_months_free: numMonthsFree,
        default_price: defaultGeniusPrice,
        monthly_price: selectedPrice,
        completed_onboarding: false,
        tier_key: showTierPricing ? tieredPricing.default_tier : undefined,
      })
    );

    setIsSubmitting(false);
    if (enableGeniusError) return;

    await delayWithState(400, setIsCompleted);
    dispatch(updateCurrentGeniusPage({ page: props.nextPage, fromSignup }));
  };

  const handleInfoOnClick = () => {
    setShowInfoModal(true);
  };

  const imageHeader = modalSection?.image ? (
    <img alt='' src={ArtMap(modalSection?.image?.filename)} />
  ) : null;

  const geniusPricingModal = modalSection ? (
    <Modal.Basic
      id='genius-pricing-modal'
      title={modalSection?.header}
      customIcon={imageHeader}
      description={modalSection?.description}
      cancelLabel={modalSection?.cancelLabel}
      submitLabel={modalSection?.submitLabel}
      show={showModal}
      onSubmit={handleOnSubmitModal}
      onCancel={handleOnCancelModal}
    />
  ) : null;

  // On mount, mark the default genius price as the current one
  React.useEffect(() => {
    const annualBonusAmount = geniusLanguage?.pricing?.annualBonus?.[
      defaultGeniusPrice
    ] as number;
    setAnnualBonus(annualBonusAmount);
    setSelectedPrice(defaultGeniusPrice);
  }, []);

  let buttonText;
  if (showMobileUpdate) {
    buttonText = buttonsCtx?.primaryAlt;
  } else {
    buttonText = !isCompleted ? buttonsCtx?.primary : buttonsCtx?.complete;
  }

  const showMobileInfoModal = showMobileUpdate && !showTierPricing;

  const pricingContent = (
    <PageBase isGenius>
      <FullDeviceHeightContainer isEnabled={showMobileUpdate}>
        <LeadContentWrapper showMobileUpdate={showMobileUpdate}>
          {showMobileUpdate && (
            <MobileDeviceHeader>{introSection?.header}</MobileDeviceHeader>
          )}
          <LeadContent
            header={(!showMobileUpdate && introSection?.header) || ''}
            text={introSection?.text || ''}
          />
        </LeadContentWrapper>
        <Spacer
          space={subscriptionSection?.header ? spacers.small : spacers.tiny}
        />
        {geniusPricingModal}
        {subscriptionSection?.header && (
          <CardHeaderContent showMobileUpdate={showMobileUpdate}>
            {subscriptionSection.header}
          </CardHeaderContent>
        )}
        {showTierPricing ? (
          <GeniusTierCards
            features={pricingSection?.features}
            tieredPricing={tieredPricing}
          />
        ) : (
          <GeniusCustomPricingCards
            showMobileUpdate={showMobileUpdate}
            pricingSection={pricingSection}
            selectedPrice={selectedPrice}
            updateSelectedPriceAndAnnualBonus={
              updateSelectedPriceAndAnnualBonus
            }
            defaultGeniusPrice={defaultGeniusPrice}
          />
        )}
        {!showMobileUpdate && <Spacer space={spacers.tabLarge} />}
        <BottomContainer showMobileUpdate={showMobileUpdate}>
          <Text align='center'>
            <OfferText
              hasFreeMonth={hasFreeMonth}
              hasZero={priceRange?.slider_values?.includes(0) || false}
              showMobileUpdate={showMobileUpdate}
            />
          </Text>

          {!showMobileUpdate && (
            <>
              <Spacer space={spacers.tab} />
              <InfoPane.Mobile expanders={infoPaneContext.expanders} />
            </>
          )}
          <DisclosureWrapper showMobileUpdate={showMobileUpdate}>
            <Text
              inline
              color={TextColor.GRAY}
              weight='400'
              size={TextSizes.SMALL}
            >
              I agree to&nbsp;
              <a
                href={`${SplashLink.TERMS}${HIDE_NAV_AND_BANNER}`}
                target='_blank'
                rel='noreferrer'
              >
                <Text inline color={TextColor.GRAY} weight='700'>
                  <u>Albert’s Terms of Service</u>
                </Text>
              </a>
              &nbsp;and to be charged for Albert Genius.
            </Text>
          </DisclosureWrapper>
          <ButtonsContainer showMobileUpdate={showMobileUpdate}>
            <StyledButton
              id='genius-pricing-confirm'
              isLoading={isSubmitting}
              isCompleted={isCompleted}
              onClick={handleOnContinue}
            >
              {buttonText}
            </StyledButton>
          </ButtonsContainer>
        </BottomContainer>
      </FullDeviceHeightContainer>
    </PageBase>
  );

  return (
    <>
      {showMobileInfoModal && <TopInfoButton onClick={handleInfoOnClick} />}
      <TopLoadingBar isLoading={showLoadingBar} />
      {showSuccessPage ? (
        <GeniusSuccess
          onContinue={handleSubmit}
          isCompleted={isCompleted}
          isSubmitting={isSubmitting}
        />
      ) : (
        pricingContent
      )}
      {showMobileInfoModal &&
        infoPaneContext?.expanders?.[0]?.content?.items && (
          <MobileInfoModal
            expanders={infoPaneContext?.expanders}
            show={showInfoModal}
            onCancel={() => setShowInfoModal(false)}
          />
        )}
    </>
  );
};

export default GeniusPricing;
