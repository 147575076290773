import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import getGeniusLanguage from 'actions/async/getGeniusLanguage';
import setTheme from 'actions/setTheme';
import BannerPane from 'components/BannerPane';
import ContentPane from 'components/layout/ContentPane';
import TwoPaneWrapper from 'components/layout/TwoPaneWrapper';
import { PageRoutes, Pages, Theme } from 'constants/index';
import * as GeniusPages from 'pages/genius/index';
import Navbar from 'pages/unauthenticated/Navbar';
import * as language from 'reducers/entities/language';
import * as profile from 'reducers/entities/profile';
import * as WEB from 'types/interfaces';
import GeniusInfoPane from './GeniusInfoPane';

type Props = {
  /* from signup flow */
  fromSignup?: boolean;
  /* page to render after finishing flow */
  nextPage: string;
};

export const GENIUS_PAGE_ORDER = [
  Pages.GENIUS_TOUR,
  Pages.GENIUS_PRICING,
  Pages.GENIUS_WELCOME,
  Pages.GENIUS_ANNUAL_MONTHLY,
  Pages.GENIUS_CONFIRM,
] as string[];

const generateRoutes = (
  page: string,
  index: number,
  basePath: string,
  pageContext: any,
  props: Props,
  prefix = ''
) => {
  // Page component to render
  const PageComponent = GeniusPages[page];

  // Get next page, if any. If there is no next page in the flow, take them to props.nextPage
  const nextGeniusPage = GENIUS_PAGE_ORDER?.[index + 1] || props.nextPage;

  return (
    <Route
      path={pageContext?.route}
      key={`${prefix}route-${pageContext?.route}`}
      element={
        <PageComponent
          fromSignup={!!props.fromSignup}
          nextPage={nextGeniusPage}
          pageOnComplete={props.nextPage}
        />
      }
    />
  );
};

const GeniusOnboarding = (props: Props): React.ReactElement => {
  const { fromSignup } = props;
  const profileData = useSelector((state: WEB.RootState) =>
    profile.getAllValues(state)
  );
  const completedGeniusSignup =
    !!profileData?.pricing_data?.completed_genius_signup;

  // //////////////////////////////
  /* ========== Hooks ========== */
  // //////////////////////////////
  const dispatch = useDispatch();

  // //////////////////////////////
  /* ======== Constants ======== */
  // //////////////////////////////
  // Note: this will change as we plug in the Genius flow in other parts of the app
  const { SIGNUP_BASE, GENIUS_BASE, DOWNLOAD_APP } = PageRoutes;

  // //////////////////////////////
  /* ======= Mapped state ====== */
  // //////////////////////////////
  const shouldFetchGeniusLanguage = useSelector(
    language.shouldFetchGeniusLanguage
  );
  const geniusLanguage = useSelector(language.getGeniusLanguage);

  const routes: React.ReactElement[] = [
    ...GENIUS_PAGE_ORDER.map((v, i) =>
      generateRoutes(
        v,
        i,
        `${SIGNUP_BASE}${GENIUS_BASE}`,
        geniusLanguage[v],
        props,
        'signup-'
      )
    ),
    ...GENIUS_PAGE_ORDER.map((v, i) =>
      generateRoutes(v, i, GENIUS_BASE, geniusLanguage[v], props)
    ),
  ];

  const redirectRoute: React.ReactElement[] = [];

  // //////////////////////////////
  /* ======= Local state ====== */
  // //////////////////////////////
  // NOTE: We know it is Genius flow initial mount if language needs to be fetched.
  const isInitialMount = React.useRef(shouldFetchGeniusLanguage);
  const [fromAnotherPage, setFromAnotherPage] = React.useState(false);

  // Navigate to first page of Genius flow if on Genius flow mount, not first page.
  if (!fromAnotherPage && !shouldFetchGeniusLanguage && !fromSignup) {
    const pathnameArr = (window.location.pathname || '').split('/');
    const currentPath = `/${pathnameArr[pathnameArr.length - 1]}`;
    const firstRoute = `/${geniusLanguage[GENIUS_PAGE_ORDER[0]]?.route}`;

    const shouldRedirectToFirstPage =
      currentPath === GENIUS_BASE ||
      (isInitialMount && currentPath !== firstRoute);

    if (!shouldFetchGeniusLanguage && shouldRedirectToFirstPage) {
      redirectRoute.push(
        <Navigate
          key='genius-redirect-route'
          to={`${firstRoute}${window.location.search}`}
          replace
        />
      );
    }
  }

  // ///////////////////////////////////////////
  /* On mount - switch app theme to "genius" */
  // ///////////////////////////////////////////
  React.useEffect(() => {
    dispatch(setTheme(Theme.GENIUS));

    // Set theme back to MAIN on Genius flow unmount
    return () => {
      dispatch(setTheme(Theme.MAIN));
    };
  }, []);

  React.useEffect(() => {
    // Whenever pathname change (ie. navigating from one page to next), update fromAnotherPage.
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      !fromAnotherPage && setFromAnotherPage(true);
    }
  }, [window.location.pathname]);

  React.useEffect(() => {
    // Load genius language
    if (shouldFetchGeniusLanguage) {
      dispatch(getGeniusLanguage());
    }
  }, [shouldFetchGeniusLanguage]);

  // If completed Genius flow already, redirect back to appropriate page.
  if (isInitialMount.current && completedGeniusSignup) {
    return <Navigate to={DOWNLOAD_APP} replace />;
  }

  return (
    <>
      <Navbar split signupFlow={fromSignup} logoOnly={!fromSignup} />
      <TwoPaneWrapper>
        <GeniusInfoPane size='small' />
        <ContentPane>
          <BannerPane />
          <Routes>
            {redirectRoute}
            {routes}
          </Routes>
        </ContentPane>
      </TwoPaneWrapper>
    </>
  );
};

export default GeniusOnboarding;
