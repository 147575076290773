import { useNavigate } from 'react-router-dom';
import updateCurrentGeniusPage from 'actions/updateCurrentGeniusPage';
import updateCurrentKYCPage from 'actions/updateCurrentKYCPage';
import updateCurrentSignupPage from 'actions/updateCurrentSignupPage';

const useNavigateFunction = () => {
  const navigate = useNavigate();

  return {
    updateCurrentSignupPage: updateCurrentSignupPage(navigate),
    updateCurrentKYCPage: updateCurrentKYCPage(navigate),
    updateCurrentGeniusPage: updateCurrentGeniusPage(navigate),
  };
};

export default useNavigateFunction;
