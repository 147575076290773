/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { FORM_ERROR } from 'final-form';
import * as React from 'react';
import { Field, FieldRenderProps, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import createProperty from 'actions/async/createProperty';
import updateEmail from 'actions/async/updateEmail';
import updatePhone from 'actions/async/updatePhone';
import updateProfile, {
  UpdateProfileFields,
} from 'actions/async/updateProfile';
import hideBanner from 'actions/banner/hideBanner';
import ActionTypes from 'actions/types';
import Button from 'components/common/Button';
import Dropdown from 'components/common/Dropdown';
import Input from 'components/common/Input';
import { InputTypes } from 'components/common/Input/TextInput';
import Spacer from 'components/common/Spacer';
import Text, { TextColor, TextSizes } from 'components/common/Text';
import BorderContainer from 'components/layout/BorderContainer';
import ButtonsContainer from 'components/layout/ButtonsContainer';
import PageBase from 'components/layout/PageBase';
import ArtMap, { Art } from 'constants/ArtMap';
import { DATE_FULLY_ENTERED } from 'constants/DateInputValidation';
import KYCStage from 'constants/KYCStage';
import Pages, { PageType } from 'constants/Pages';
import useNavigateFunction from 'hooks/useNavigateFunction';
import useVerifyCurrentPage from 'hooks/useVerifyCurrentPage';
import * as appData from 'reducers/entities/appData';
import * as language from 'reducers/entities/language';
import * as profile from 'reducers/entities/profile';
import { breakpoints, fontSizes, mixins, spacers } from 'styles';
import { spacers as homeSpacers } from 'styles/home';
import * as WEB from 'types/interfaces';
import { convertDateStringToUnix, formatDate } from 'utils/dates';
import { delayWithState } from 'utils/delay';
import { logger } from 'utils/logger';
import {
  composeValidators,
  isRequired,
  minLen,
  validBirthday,
  validEmail,
} from 'utils/validators';

type Props = {
  fromSignup?: boolean;
};

const HiddenOnMobile = styled.div`
  @media ${breakpoints.mobileLarge} {
    display: none;
  }
`;

const StyledLock = styled.img`
  height: 3rem;
  width: 3rem;
  margin: auto ${mixins.pxToRem('8px')} auto 0;
  float: right;
`;

const ConfirmWrapper = styled.div`
  ${fontSizes.fontSize18};
  @media ${breakpoints.mobileLarge} {
    font-size: inherit;
  }
`;

const SecondaryLinkWrapper = styled.div`
  margin-right: ${spacers.tabSmall};
  @media ${breakpoints.mobileLarge} {
    margin-right: 0;
  }
`;

const KYCConfirm = (props: Props): React.ReactElement => {
  const { updateCurrentKYCPage } = useNavigateFunction();
  const { fromSignup } = props;
  // ///////////////////////////////
  /* =========== HOOKS ========== */
  // ///////////////////////////////////////
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // Make sure Redux is up-to-date with current page.
  useVerifyCurrentPage(Pages.KYC_CONFIRM, PageType.KYC);

  // ///////////////////////////////
  /* =========== STATE ========== */
  // ///////////////////////////////
  const [locked, setLocked] = React.useState(true);
  const [canEditNameBirthday, setCanEditNameBirthday] = React.useState(true);
  const [isCompleted, setIsCompleted] = React.useState<boolean>(false);
  // Unique sessiontoken for Google Place requests.
  const [sessiontoken, setSessiontoken] = React.useState<string>(uuidv4());
  const [updatedFormData, setUpdatedFormData] = React.useState<any | null>(
    null
  );

  // ///////////////////////////////////////
  /* =========== MAPPED STATE ========== */
  // ///////////////////////////////////////
  const KYCAppData = useSelector((state: WEB.RootState) =>
    appData.getValueByField(state, 'kyc')
  );

  const kycStage = useSelector((state: WEB.RootState) => {
    return profile.getValueByField(state, 'kyc_stage');
  });

  // If user has completed KYC for any product, disable editing of name and birthday
  React.useEffect(() => {
    const finishedAlbertProductKYC = Object.values(
      KYCAppData.products
    ).includes(true);
    const kycComplete = kycStage === KYCStage.COMPLETE;

    if (finishedAlbertProductKYC || kycComplete) {
      setCanEditNameBirthday(false);
    }
  }, [KYCAppData, kycStage]);

  const profileData = useSelector((state: WEB.RootState) =>
    profile.getAllValues(state)
  );
  const addressData = useSelector(
    (state: WEB.RootState) =>
      appData.getLegalProperty(state) || appData.getPrimaryProperty(state)
  );

  // useMemo to prevent form from resetting to initial values on component re-render.
  const initialFormData = React.useMemo(
    () => ({
      'first-name': profileData.first_name,
      'last-name': profileData.last_name,
      birthday: formatDate(profileData.birthday, 'MM/dd/yyyy'),
      phone: profileData.phone,
      email: profileData.email,
      income: profileData.income,
      address: {
        place_id: addressData?.google_placeid,
        description: addressData?.google_address,
        own: addressData?.own,
      },
      apartment: addressData?.apt,
    }),
    []
  );
  const formData = updatedFormData || initialFormData;

  const languageCtx = useSelector((state: WEB.RootState) =>
    language.getKYCPageLanguage(state, Pages.KYC_CONFIRM)
  );
  const kycLanguage = useSelector((state: WEB.RootState) =>
    language.getKYCLanguage(state)
  );
  const kycBasePath = useSelector((state: WEB.RootState) =>
    language.getKYCBasePath(state, fromSignup)
  );

  // ///////////////////////////////////////
  /* =========== FORM ELEMENTS ========== */
  // ///////////////////////////////////////
  const FirstNameInput = ({
    input,
    meta,
  }: FieldRenderProps<any, HTMLElement>) => (
    <Input.Text
      id='first-name'
      type={InputTypes.TEXT}
      placeholder={languageCtx.content.firstName.placeholder}
      floatingLabel={languageCtx.content.firstName.placeholder}
      errorText={languageCtx.content.firstName.errorMessage}
      invalid={meta?.error && meta.submitFailed && !meta.dirtySinceLastSubmit}
      {...input}
    />
  );

  const LastNameInput = ({
    input,
    meta,
  }: FieldRenderProps<any, HTMLElement>) => (
    <Input.Text
      id='last-name'
      type={InputTypes.TEXT}
      placeholder={languageCtx.content.lastName.placeholder}
      floatingLabel={languageCtx.content.lastName.placeholder}
      errorText={languageCtx.content.lastName.errorMessage}
      invalid={meta?.error && meta.submitFailed && !meta.dirtySinceLastSubmit}
      {...input}
    />
  );

  const BirthdayInput = ({
    input,
    meta,
  }: FieldRenderProps<any, HTMLElement>) => {
    const value = input?.value as string | undefined;

    const isInvalid =
      (meta?.submitFailed || (value && DATE_FULLY_ENTERED.test(value))) &&
      meta?.error;

    return (
      <Input.Date
        id='birthday'
        floatingLabel={`${languageCtx.content.birthday.placeholder} (MM/DD/YYYY)`}
        invalid={isInvalid}
        errorText={validBirthday(value || '')}
        {...input}
      />
    );
  };

  const PhoneInput = ({ input, meta }: FieldRenderProps<any, HTMLElement>) => (
    <Input.Text
      id='phone'
      type={InputTypes.PHONE}
      placeholder={languageCtx.content.phoneNumber.placeholder}
      floatingLabel={languageCtx.content.phoneNumber.placeholder}
      invalid={meta?.error && meta.submitFailed && !meta.dirtySinceLastSubmit}
      errorText={languageCtx.content.phoneNumber.errorMessage}
      {...input}
    />
  );

  const EmailInput = ({ input, meta }: FieldRenderProps<any, HTMLElement>) => (
    <Input.Text
      id='email'
      type={InputTypes.EMAIL}
      placeholder={languageCtx.content.email.placeholder}
      floatingLabel='Email'
      errorText={languageCtx.content.email.errorMessage}
      invalid={meta?.error && meta.submitFailed && !meta.dirtySinceLastSubmit}
      {...input}
    />
  );

  const IncomeInput = ({ input, meta }: FieldRenderProps<any, HTMLElement>) => (
    <Input.Currency
      id='income'
      valueDefault={input?.value as string | undefined}
      placeholder={languageCtx.content.income.placeholder}
      floatingLabel={languageCtx.content.income.placeholder}
      errorText={languageCtx.content.income.errorMessage}
      invalid={meta?.error && meta.submitFailed && !meta.dirtySinceLastSubmit}
      {...input}
    />
  );

  const AddressInput = ({
    input,
    meta,
  }: FieldRenderProps<any, HTMLElement>) => (
    <Dropdown.AddressTypeahead
      id='address'
      sessiontoken={sessiontoken}
      floatingLabel={languageCtx.content.address.placeholder}
      placeholder={languageCtx.content.address.placeholder}
      errorText={languageCtx.content.address.errorMessage}
      invalid={meta?.error && meta.submitFailed && !meta.dirtySinceLastSubmit}
      value={input.value?.description}
      onSelect={input.onChange}
      showError
      fullWidth
    />
  );

  const ApartmentInput = ({
    input,
    meta,
  }: FieldRenderProps<any, HTMLElement>) => (
    <Input.Text
      id='apartment'
      type={InputTypes.TEXT}
      placeholder={languageCtx.content.apartment.placeholder}
      floatingLabel={languageCtx.content.apartment.placeholder}
      {...input}
    />
  );

  // ///////////////////////////////////////
  /* =========== ELEMENT GROUPS ========== */
  // ///////////////////////////////////////
  // Group form elements into constants since groups of certain elements are
  // locked to the user depending on their KYC state
  const nameBirthdayInputs = (
    <>
      <Field
        name='first-name'
        component={FirstNameInput}
        validate={composeValidators(isRequired)}
      />
      <Spacer space={spacers.tabSmall} desktopOnly />
      <Field
        name='last-name'
        component={LastNameInput}
        validate={composeValidators(isRequired)}
      />
      <Spacer space={spacers.tabSmall} desktopOnly />
      <Field
        name='birthday'
        component={BirthdayInput}
        validate={composeValidators(validBirthday)}
      />
      <Spacer space={spacers.tabSmall} desktopOnly />
    </>
  );

  const nameBirthdayValues = (
    <>
      <Text size={TextSizes.SMALL} color={TextColor.GRAY}>
        {languageCtx.content.firstName.placeholder}
      </Text>
      <Spacer space={spacers.tiny} />
      <Text size={TextSizes.LARGE} color={TextColor.BLACK}>
        {formData['first-name']}
      </Text>
      <Spacer space={spacers.small} />
      <Text size={TextSizes.SMALL} color={TextColor.GRAY}>
        {languageCtx.content.lastName.placeholder}
      </Text>
      <Spacer space={spacers.tiny} />
      <Text size={TextSizes.LARGE} color={TextColor.BLACK}>
        {formData['last-name']}
      </Text>
      <Spacer space={spacers.small} />
      <Text size={TextSizes.SMALL} color={TextColor.GRAY}>
        {languageCtx.content.birthday.placeholder}
      </Text>
      <Spacer space={spacers.tiny} />
      <Text size={TextSizes.LARGE} color={TextColor.BLACK}>
        {formData.birthday}
      </Text>
      <Spacer space={spacers.small} />
    </>
  );

  const remainingInputs = (
    <>
      <Field
        name='phone'
        component={PhoneInput}
        validate={composeValidators(minLen(14), isRequired)}
      />
      <Spacer space={spacers.tabSmall} desktopOnly />
      <Field
        name='email'
        component={EmailInput}
        validate={composeValidators(isRequired, validEmail)}
      />
      <Spacer space={spacers.tabSmall} desktopOnly />
      <Field
        name='income'
        component={IncomeInput}
        validate={composeValidators(isRequired)}
      />
      <Spacer space={spacers.tabSmall} desktopOnly />
      <Field
        name='address'
        component={AddressInput}
        validate={composeValidators(isRequired)}
      />
      <Spacer space={spacers.tabSmall} desktopOnly />
      <Field name='apartment' component={ApartmentInput} />
    </>
  );

  const remainingValues = (
    <>
      <Text size={TextSizes.SMALL} color={TextColor.GRAY}>
        {languageCtx.content.phoneNumber.placeholder}
      </Text>
      <Spacer space={spacers.tiny} />
      <Text size={TextSizes.LARGE} color={TextColor.BLACK}>
        {formData.phone}
      </Text>
      <Spacer space={spacers.small} />
      <Text size={TextSizes.SMALL} color={TextColor.GRAY}>
        Email
      </Text>
      <Spacer space={spacers.tiny} />
      <Text size={TextSizes.LARGE} color={TextColor.BLACK}>
        {formData.email}
      </Text>
      <Spacer space={spacers.small} />
      <Text size={TextSizes.SMALL} color={TextColor.GRAY}>
        {languageCtx.content.income.placeholder}
      </Text>
      <Spacer space={spacers.tiny} />
      <Text size={TextSizes.LARGE} color={TextColor.BLACK}>
        {/* Using toLocaleString() to format income with commas */}$
        {formData.income ? formData.income.toLocaleString() : 0}
      </Text>
      <Spacer space={spacers.small} />
      <Text size={TextSizes.SMALL} color={TextColor.GRAY}>
        {languageCtx.content.address.placeholder}
      </Text>
      <Spacer space={spacers.tiny} />
      <Text size={TextSizes.LARGE} color={TextColor.BLACK}>
        {formData.address.description}
      </Text>
      {formData.apartment ? (
        <>
          <Spacer space={spacers.small} />
          <Text size={TextSizes.SMALL} color={TextColor.GRAY}>
            {languageCtx.content.apartment.placeholder}
          </Text>
          <Spacer space={spacers.tiny} />
          <Text size={TextSizes.LARGE} color={TextColor.BLACK}>
            {formData.apartment}
          </Text>
        </>
      ) : null}
    </>
  );

  // Determine which form elements are locked to user and which can be edited
  // Based on KYC condition, determined in HOOKS section above
  const formToRender = () => {
    if (locked) {
      return (
        <>
          <BorderContainer
            desktopPadding={mixins.pxToRem('40px')}
            showMobileBorder
          >
            <StyledLock src={ArtMap(Art.SecureLockBackground)} />
            {nameBirthdayValues} {remainingValues}
          </BorderContainer>
        </>
      );
    }
    if (canEditNameBirthday) {
      return (
        <>
          {nameBirthdayInputs} {remainingInputs}{' '}
        </>
      );
    }
    return (
      <>
        <BorderContainer
          desktopPadding={mixins.pxToRem('40px')}
          showMobileBorder
        >
          <StyledLock src={ArtMap(Art.SecureLockBackground)} />
          {nameBirthdayValues}
        </BorderContainer>
        <Spacer space={spacers.tabSmall} />
        {remainingInputs}
      </>
    );
  };

  // ///////////////////////////////////////
  /* =========== HANDLERS ========== */
  // ///////////////////////////////////////
  const unlockForm = (): void => {
    window.scrollTo(0, 0);
    setLocked(false);
  };

  const onSubmit = async (values: Record<string, any>) => {
    dispatch(hideBanner(true));

    const profileUpdatePromise: any = () => {
      // Determine if update profile request is necessary.
      const updateProfilePayload: UpdateProfileFields = {};

      if (values['first-name'] !== formData['first-name']) {
        updateProfilePayload.first_name = values['first-name'];
      }

      if (values['last-name'] !== formData['last-name']) {
        updateProfilePayload.last_name = values['last-name'];
      }

      const newBirthday = convertDateStringToUnix(
        values.birthday,
        'MM/dd/yyyy'
      );
      const prevBirthday = convertDateStringToUnix(
        formData.birthday,
        'MM/dd/yyyy'
      );
      if (newBirthday && newBirthday !== prevBirthday) {
        updateProfilePayload.birthday = newBirthday;
      }

      if (values.income !== formData.income) {
        updateProfilePayload.income = values.income;
      }

      // Only dispatch update profile action if necessary.
      if (Object.values(updateProfilePayload).length) {
        return dispatch(updateProfile(updateProfilePayload));
      }
      return false;
    };

    const shouldCreateProperty: boolean =
      values.address.place_id !== formData.address.place_id ||
      values.apartment !== formData.apartment;
    const propertyUpdatePromise = () => {
      if (shouldCreateProperty) {
        return dispatch(
          createProperty(
            values.address.place_id,
            values.apartment,
            values.address.own,
            sessiontoken
          )
        );
      }
      return false;
    };

    // For phone and email, a confirmation code will be sent upon update.
    // We don't want the user to receive a confirmation code if they didn't
    // update their phone or email, so we check if a change was made before
    // dispatching the action.
    let emailUpdated = false;
    const emailUpdatePromise = () => {
      if (values.email && values.email !== formData.email) {
        emailUpdated = true;
        return dispatch(updateEmail({ email: values.email }, 'kyc'));
      }
      return false;
    };

    let phoneUpdated = false;
    const phoneUpdatePromise = () => {
      if (values.phone !== formData.phone) {
        phoneUpdated = true;
        return dispatch(updatePhone({ phone: values.phone }));
      }
      return false;
    };

    try {
      const results = await Promise.all([
        profileUpdatePromise(),
        propertyUpdatePromise(),
        emailUpdatePromise(),
        phoneUpdatePromise(),
      ]);

      // From Google Places docs: https://developers.google.com/maps/documentation/places/web-service/autocomplete#sessiontoken
      // > The session begins when the user starts typing a query, and concludes when they select a place
      // > and a call to Place Details is made.
      // Reset sessiontoken after a call to Place Details is made (createProperty).
      // NOTE: Avoiding bending the rules of hooks by calling hook conditionally.
      setSessiontoken(shouldCreateProperty ? uuidv4() : sessiontoken);

      // Handle errors
      dispatch(hideBanner(false));

      // NOTE: Use vanilla for loop to make use of the return statements.
      for (let i = 0; i < results.length; i += 1) {
        const result = results[i];
        if (result?.error) {
          switch (result.response?.type) {
            case ActionTypes.UPDATE_PROFILE_FAILURE:
              return { [FORM_ERROR]: 'Error updating profile' };
            case ActionTypes.UPDATE_PHONE_FAILURE:
              return { phone: 'Error updating phone number' };
            case ActionTypes.UPDATE_EMAIL_FAILURE:
              return { email: 'Error updating email' };
            case ActionTypes.CREATE_PROPERTY_FAILURE:
              return {
                address: 'Error updating address',
                apartment: 'Error updating address',
              };
            default:
              return { [FORM_ERROR]: 'error' };
          }
        }
      }

      // Handle rerouting in onSubmit since it needs to be known which values changed
      if (phoneUpdated || emailUpdated) {
        // Determine which confirmation screen(s) user needs to be taken to based
        // on changed inputs
        let pathname = `${kycBasePath}/`;
        let navToEmailOnComplete = false;
        if (phoneUpdated) {
          pathname += `${kycLanguage[Pages.KYC_CONFIRM_NUMBER].route}`;
          // Since both phone and email need to be updated, navigate to KYCConfirmPhone
          // first then navigate to KYCConfirmEmail next
          navToEmailOnComplete = emailUpdated;
        } else if (emailUpdated) {
          pathname += `${kycLanguage[Pages.KYC_CONFIRM_EMAIL].route}`;
        }

        await delayWithState(400, setIsCompleted);
        navigate(pathname, { state: { navToEmailOnComplete } });
      } else {
        await delayWithState(400, setIsCompleted);
        if (locked) {
          dispatch(
            updateCurrentKYCPage({
              fromSignup,
              page: Pages.KYC_SSN,
            })
          );
        } else {
          setLocked(true);
          setUpdatedFormData(values);
          setIsCompleted(false);
          window.scrollTo(0, 0);
        }
      }
    } catch (e) {
      logger.error(e);
      return { [FORM_ERROR]: 'error' };
    }
  };
  let primaryButtonText = '';
  if (!isCompleted) {
    primaryButtonText = locked ? 'Confirm details' : 'Update details';
  }

  return (
    <>
      <PageBase mobileHeader={languageCtx.content.confirm.header}>
        <Form
          onSubmit={onSubmit}
          initialValues={formData}
          render={({ handleSubmit, submitting }) => {
            return (
              <form onSubmit={handleSubmit}>
                <HiddenOnMobile>
                  <Text
                    size={TextSizes.LARGE}
                    color={TextColor.BLACK}
                    weight='bold'
                  >
                    {languageCtx.content.confirm.header}
                  </Text>
                  <Spacer space={spacers.tiny} />
                </HiddenOnMobile>
                <ConfirmWrapper className='description'>
                  <Text color={TextColor.BLACK}>
                    {languageCtx.content.confirm.text}
                  </Text>
                </ConfirmWrapper>
                <Spacer space={homeSpacers.g8} />
                {formToRender()}

                <ButtonsContainer>
                  {locked ? (
                    <SecondaryLinkWrapper className='secondary-button'>
                      <Text
                        color={TextColor.BLACK}
                        weight='bold'
                        size={TextSizes.SMALL}
                        underline
                        onClick={unlockForm}
                        isLinkButton
                      >
                        {languageCtx.buttons.link}
                      </Text>
                    </SecondaryLinkWrapper>
                  ) : null}
                  <Button
                    isLoading={submitting && !isCompleted}
                    isCompleted={isCompleted}
                    disabled={submitting}
                    // Set a key to force the button in the locked state to render
                    // separately from the unlocked state. This will prevent
                    // button states from bleeding over when the locked state flips
                    key={locked ? 'locked' : 'unlocked'}
                  >
                    {primaryButtonText}
                  </Button>
                </ButtonsContainer>
              </form>
            );
          }}
        />
      </PageBase>
    </>
  );
};

export default KYCConfirm;
