import * as React from 'react';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from 'styles/global';

type Props = {
  theme: any;
  children: React.ReactNode;
};

export default ({ theme, children }: Props): React.ReactElement => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle theme={theme} />
      {children}
    </ThemeProvider>
  );
};
