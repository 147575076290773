import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import updateProfile, {
  UpdateProfileFields,
} from 'actions/async/updateProfile';
import hideBanner from 'actions/banner/hideBanner';
import removeBanner from 'actions/banner/removeBanner';
import TopLoadingBar from 'components/TopLoadingBar';
import Button from 'components/common/Button';
import { renderLabels } from 'components/common/Input/shared';
import Link from 'components/common/Link';
import ExternalLinkIcon from 'components/icons/ExternalLinkIcon';
import PageBase from 'components/layout/PageBase';
import Pages from 'constants/Pages';
import SplashLink from 'constants/SplashLink';
import useNavigateFunction from 'hooks/useNavigateFunction';
import useVerifyCurrentPage from 'hooks/useVerifyCurrentPage';
import * as language from 'reducers/entities/language';
import { breakpoints } from 'styles';
import * as WEB from 'types/interfaces';
import { delayWithState } from 'utils/delay';
import { getLanguageButtons, getLanguageSection } from 'utils/getFromLanguage';

type Props = {
  nextPage: string;
};

const TextOptin = (props: Props): React.ReactElement => {
  const { updateCurrentSignupPage } = useNavigateFunction();
  // Make Redux is up-to-date with currentSignUpPage
  useVerifyCurrentPage(Pages.TEXT_OPTIN);

  // ///////////////////////////////
  /* =========== STATE ========== */
  // ///////////////////////////////
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [isCompleted, setIsCompleted] = React.useState<boolean>(false);
  const [optIn, setOptIn] = React.useState<boolean>(false);

  // ///////////////////////////////
  /* ======== MAPPED STATE ====== */
  // ///////////////////////////////
  const languageCtx = useSelector((state: WEB.RootState) =>
    language.getSignupPageLanguage(state, Pages.TEXT_OPTIN)
  );

  // ///////////////////////////////
  /* =========== HOOKS ========== */
  // ///////////////////////////////
  const dispatch = useDispatch();

  // //////////////////////////////////
  /* =========== LANGUAGE ========== */
  // //////////////////////////////////
  const OPTIN_KEY = 'textOptin';
  const optInSection = getLanguageSection(languageCtx, OPTIN_KEY);
  const composedDisclosureItems = optInSection?.composed_disclosure || [];

  // Buttons
  const buttonsCtx: WEB.LanguageButtons = getLanguageButtons(languageCtx);

  // //////////////////////////////////
  /* =========== HANDLERS ========== */
  // //////////////////////////////////
  const handleOnNext = async (
    optIn: boolean
  ): Promise<Record<string, string> | void> => {
    // Remove any error banner from previous attempts
    dispatch(removeBanner());

    // Indicate that we are submitting a form and show a loading button state
    setIsSubmitting(true);

    // Hide any error banners until all requests are complete
    dispatch(hideBanner());

    const updateProfilePayload: UpdateProfileFields = {
      text_opt_in: optIn,
    };

    const response: any = await dispatch(updateProfile(updateProfilePayload));

    setIsSubmitting(false);

    dispatch(hideBanner(false));

    if (response.error) {
      return;
    }

    // Indicate that we have completed the form and show a completed button state
    await delayWithState(400, setIsCompleted);
    dispatch(
      updateCurrentSignupPage({
        page: props.nextPage,
      })
    );
  };

  const handleReceiveTexts = () => {
    setOptIn(true);
    handleOnNext(true);
  };

  const handleDoNotReceiveTexts = () => {
    setOptIn(false);
    handleOnNext(false);
  };

  // ///////////////////////////////////////
  /* =========== FORM ELEMENTS ========== */
  // ///////////////////////////////////////

  return (
    <>
      <PageBase mobileHeader={optInSection?.header || ''}>
        {isSubmitting && !optIn && <TopLoadingBar isLoading={isSubmitting} />}
        {renderLabels({
          label: optInSection?.header,
          description: optInSection?.text,
          hideLabelOnMobile: true,
        })}
        {composedDisclosureItems.length ? (
          <ComposedDisclosureContainer>
            {composedDisclosureItems.map((item, index) => (
              <div key={`disclosure_container_${index}`}>
                {index > 0 && <Separator>&bull;</Separator>}
                <StyledLink
                  href={item.disclosure_url}
                  target='_blank'
                  color='gray'
                >
                  {item.title}
                </StyledLink>
              </div>
            ))}
          </ComposedDisclosureContainer>
        ) : (
          <LinkWrapper>
            <StyledLink
              href={SplashLink.TERMS_SMS}
              target='_blank'
              color='gray'
            >
              {optInSection?.linkLabel}
              <ExternalLinkIcon />
            </StyledLink>
          </LinkWrapper>
        )}
        <ButtonsWrapper>
          <Button
            id='texts-confirm-button'
            isCompleted={isCompleted && optIn}
            isLoading={isSubmitting && optIn}
            disabled={isSubmitting}
            onClick={handleReceiveTexts}
          >
            {isCompleted && optIn ? buttonsCtx?.complete : buttonsCtx?.primary}
          </Button>
          <SecondaryButton
            id='no-texts-confirm-button'
            secondary
            disabled={isSubmitting}
            onClick={handleDoNotReceiveTexts}
            stretch={false}
          >
            {buttonsCtx?.secondary}
          </SecondaryButton>
        </ButtonsWrapper>
      </PageBase>
    </>
  );
};

export default TextOptin;

const LinkWrapper = styled.div`
  @media ${breakpoints.mobileLarge} {
    margin-top: 16px;
  }
`;

const StyledLink = styled(Link)`
  font-size: 0.875rem;
  svg {
    display: inline-block;
    width: 16px;
    margin-left: 4px;
    // Prevent transition being applied twice
    transition: none;
  }

  @media ${breakpoints.mobileLarge} {
    font-size: 1rem;
  }
`;

const ButtonsWrapper = styled.div`
  margin-top: 48px;

  > button {
    display: block;
  }

  #texts-confirm-button {
    width: 242px;
    margin-bottom: 18px;
  }

  @media ${breakpoints.mobileLarge} {
    text-align: center;

    > button {
      margin-left: auto;
      margin-right: auto;
    }

    #texts-confirm-button {
      width: 100%;
      margin-bottom: 32px;
    }
  }
`;

const SecondaryButton = styled(Button)`
  white-space: nowrap;
  border: none;
  width: auto;
  min-width: 0;
  padding-left: 0;
  padding-right: 0;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 0.875rem;
  font-weight: 700;
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.primaryGray};
  background-color: transparent;

  svg {
    color: ${({ theme }) => theme.colors.primaryGray};
  }

  &:disabled {
    color: ${({ theme }) => theme.colors.primaryGray};
    background-color: transparent;
    opacity: 1;
  }

  &:hover:not(:disabled) {
    background-color: transparent;
    color: ${({ theme }) => theme.colors.primaryText};
    width: auto;
  }

  @media ${breakpoints.mobileLarge} {
    font-size: 1rem;
  }
`;

const ComposedDisclosureContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 16px;
`;

const Separator = styled.span`
  display: inline-block;
  margin: 0 5px;
  color: ${({ theme }) => theme.colors.primaryGray};
`;
