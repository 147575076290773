import { capitalize } from 'lodash';
import * as WEB from 'types/interfaces';

type Option = {
  display: string;
  value: string | number;
};

// Helper function that returns two things:
// 1. A section's list of options formatted for the Dropdown input
// 2. The value that the dropdown should default to
export const formatDropdownOptions = (
  options: Option[],
  defaultValue: string | number | null
): readonly [WEB.DropdownItem[], string] => {
  let defaultDisplay = '';
  const formattedOptions: WEB.DropdownItem[] = options.map(
    ({ value, display }) => {
      // Some values from the backend are all lowercase and cannot be changed, so we need to capitalize them here
      const formattedDisplay = display ? capitalize(display) : '';
      if (value === defaultValue) {
        defaultDisplay = formattedDisplay;
      }
      return { value: formattedDisplay };
    }
  );
  return [formattedOptions, defaultDisplay] as const;
};
