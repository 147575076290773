/* eslint-disable @typescript-eslint/no-explicit-any */
// Libraries
import branch from 'branch-sdk';
import 'firebase/analytics';
import Firebase from 'firebase/app';
import { store } from 'index';
import Cookies from 'js-cookie';
import AlbertAnalytics from 'tracking.Analytics';
import BranchEvent from 'constants/BranchEvent';
import GTMEvent from 'constants/GTMEvents';
import * as profile from 'reducers/entities/profile';
// Selectors
import * as app from 'reducers/ui/app';
// Modules
import * as WEB from 'types/interfaces';
import { getDifferenceInMinutes } from 'utils/dates';
import { logger } from 'utils/logger';
import {
  AlbertTrackingEvent,
  FacebookEvent,
  FirebaseEvent,
  NodeEnvironment,
} from './constants';

// https://help.branch.io/developers-hub/docs/web-full-reference#initbranch_key-options-callback
type BranchOptions = {
  branch_match_id?: string;
};

export type BranchDataParsed = {
  bonus_program?: string;
};

const isTrackableEnvironment = (): boolean => {
  if (Cookies.get('is_tracking_disabled') === 'true') {
    return false;
  }
  if (navigator['globalPrivacyControl']) {
    logger.message('GPC enabled. Skipping page tracking.');
    return false;
  }
  return (
    !!window.albertWeb.EnableAnalytics ||
    window.albertWeb.Environment === NodeEnvironment.PROD
  );
};

/* 2021/06/18, MPR: I have no idea what this snippet does, but its part of
 * the google tag maanager initialization. Don't modify it.
 */
/* Begin do not modify section */
window.dataLayer = window.dataLayer || [];
function gtag() {
  /* eslint-disable-next-line */
  window.dataLayer.push(arguments);
}
/* End do not modify section */

const FIREBASE_ENABLED = window.albertWeb.EnableFirebase;
const BRANCH_ENABLED = window.albertWeb.EnableBranch;
const FACEBOOK_ENABLED = window.albertWeb.EnableFacebook;
const GTM_ENABLED = window.albertWeb.EnableGTM;
const UET_ENABLED = window.albertWeb.EnableUET;

/**
 * Track with external services ie. Facebook, Firebase, Branch, etc.
 *
 * @param event (string) - name of event
 * @param purchaseAmount (number) - special property tracked through social
 * @param eventData (object) - additional metadata
 */
const trackWithServices = (
  event: string,
  purchaseAmount: number,
  eventData: object
): void => {
  if (!(<any>Object).values(AlbertTrackingEvent).includes(event)) {
    logger.log(`unknown event fired ${event}`);
  } else if (
    event === AlbertTrackingEvent.ENABLED_GENIUS ||
    event === AlbertTrackingEvent.ENABLED_GENIUS_ANNUAL ||
    event ===
      AlbertTrackingEvent.ENABLED_GENIUS_PRIMARY_CHECKING_GREATER_THAN_100
  ) {
    if (GTM_ENABLED) {
      try {
        window.gtag('event', 'conversion', {
          send_to: GTMEvent[event],
          transaction_id: '',
        });
        logger.debug('[analytics] tracked to Google Tag Manager');
      } catch (err) {
        logger.log(`Could not track to Google Tag Manager: ${err}`);
      }
    } else {
      logger.debug('[analytics] Google Tag Manager disabled, skipping track');
    }

    if (UET_ENABLED) {
      try {
        window.uetq = window.uetq || [];
        window.uetq.push('event', event, {
          event_action: event,
          event_category: event,
        });
        logger.debug('[analytics] tracked to Universal Event Tracking');
      } catch (err) {
        logger.log(
          `Could not track to Universal Event Tracking Manager: ${err}`
        );
      }
    } else {
      logger.debug(
        '[analytics] Universal Event Tracking Manager disabled, skipping track'
      );
    }

    if (FACEBOOK_ENABLED) {
      try {
        window.fbq(
          FacebookEvent[event].custom ? 'trackCustom' : 'track',
          FacebookEvent[event].name,
          { currency: 'USD', value: purchaseAmount }
        );
        logger.debug('[analytics] tracked to Facebook Pixel');
      } catch (err) {
        logger.log(`Could not track to Facebook Pixel: ${err}`);
      }
    } else {
      logger.debug('[analytics] Facebook Pixel disabled, skipping track');
    }

    if (BRANCH_ENABLED) {
      try {
        branch.logEvent(BranchEvent[event], {
          revenue: purchaseAmount,
          currency: 'USD',
          ...eventData,
        });
        logger.debug('[analytics] tracked to Branch');
      } catch (err) {
        logger.log(`Could not track to Branch: ${err}`);
      }
    } else {
      logger.debug('[analytics] Branch disabled, skipping track');
    }

    if (FIREBASE_ENABLED) {
      try {
        Firebase.analytics().logEvent(FirebaseEvent[event], {
          currency: 'USD',
          value: purchaseAmount,
        });
        logger.debug('[analytics] tracked to Firebase');
      } catch (err) {
        logger.log(`Could not track to Firebase: ${err}`);
      }
    } else {
      logger.debug('[analytics] Firebase disabled, skipping track');
    }
  } else {
    if (GTM_ENABLED) {
      try {
        window.gtag('event', 'conversion', { send_to: GTMEvent[event] });
        logger.debug('[analytics] tracked to Google Tag Manager');
      } catch (err) {
        logger.log(`Could not track to Google Tag Manager: ${err}`);
      }
    } else {
      logger.debug('[analytics] Google Tag Manager disabled, skipping track');
    }

    if (UET_ENABLED) {
      try {
        window.uetq = window.uetq || [];
        window.uetq.push('event', event, {
          event_action: event,
          event_category: event,
        });
        logger.debug('[analytics] tracked to Universal Event Tracking');
      } catch (err) {
        logger.log(
          `Could not track to Universal Event Tracking Manager: ${err}`
        );
      }
    } else {
      logger.debug(
        '[analytics] Universal Event Tracking Manager disabled, skipping track'
      );
    }

    if (FACEBOOK_ENABLED) {
      try {
        window.fbq(
          FacebookEvent[event].custom ? 'trackCustom' : 'track',
          FacebookEvent[event].name,
          eventData
        );
        logger.debug('[analytics] tracked to Facebook Pixel');
      } catch (err) {
        logger.log(`Could not track to Facebook Pixel: ${err}`);
      }
    } else {
      logger.debug('[analytics] Facebook Pixel disabled, skipping track');
    }

    if (BRANCH_ENABLED) {
      try {
        branch.logEvent(BranchEvent[event], eventData);
        logger.debug('[analytics] tracked to Branch');
      } catch (err) {
        logger.log(`Could not track to Branch: ${err}`);
      }
    } else {
      logger.debug('[analytics] Branch disabled, skipping track');
    }

    if (FIREBASE_ENABLED) {
      try {
        Firebase.analytics().logEvent(FirebaseEvent[event], {});
        logger.debug('[analytics] tracked to Firebase');
      } catch (err) {
        logger.log(`Could not track to Firebase: ${err}`);
      }
    } else {
      logger.debug('[analytics] Firebase disabled, skipping track');
    }
  }
};

/* ================ TRACKING BASE ================ */
const TrackingBase = {
  initialize(): void {
    /* ======== SERVICE INITIALIZATION ========= */
    if (BRANCH_ENABLED) {
      try {
        // Branch init options.
        const options: BranchOptions = {};

        // Get branch match ID from URL query param.
        const BRANCH_MATCH_ID_KEY = 'branch_match_id';
        const params = new URLSearchParams(window.location.search);

        // Add branch_match_id on branch init to get access to branch link data.
        const branchMatchId =
          params.get('branch_match_id') ||
          params.get(`_${BRANCH_MATCH_ID_KEY}`);
        if (branchMatchId) {
          options.branch_match_id = branchMatchId;
        }

        branch.init(window.albertWeb.BranchKey, options, function (err, data) {
          if (err) {
            logger.warn(`Could not initialize Branch: ${err}`);
          }
          if (data) {
            logger.log(`branch data ${JSON.stringify(data)}`);
          }
        });
        logger.debug('[analytics] initialized Branch');
      } catch (err) {
        logger.warn(`Could not initialize Branch: ${err}`);
      }
    } else {
      logger.debug('[analytics] Branch disabled, skipping initialize');
    }

    if (GTM_ENABLED) {
      try {
        window.gtag('config', 'AW-932828725');
        logger.debug('[analytics] initialized Google Tag Manager');
      } catch (err) {
        logger.warn(`Could not initialize Google Tag Manager: ${err}`);
      }
    } else {
      logger.debug(
        '[analytics] Google Tag Manager disabled, skipping initialize'
      );
    }

    if (FIREBASE_ENABLED) {
      try {
        Firebase.initializeApp({
          apiKey: window.albertWeb.FirebaseConfig.apiKey,
          authDomain: window.albertWeb.FirebaseConfig.authDomain,
          databaseURL: window.albertWeb.FirebaseConfig.databaseURL,
          projectId: window.albertWeb.FirebaseConfig.projectId,
          storageBucket: window.albertWeb.FirebaseConfig.storageBucket,
          messagingSenderId: window.albertWeb.FirebaseConfig.messagingSenderId,
          appId: window.albertWeb.FirebaseConfig.appId,
        });
        logger.debug('[analytics] initialized Firebase');
      } catch (err) {
        logger.warn(`Could not initialize Firebase: ${err}`);
      }
    } else {
      logger.debug('[analytics] Firebase disabled, skipping initialize');
    }
  },
  /**
   * Ensure events are tracking in production.
   */

  /**
   * Identify a user
   */
  identify(): void {
    try {
      const state = store.getState();
      const profileId = profile.getValueByField(state, 'id');
      const profileIsReal = profile.getValueByField(state, 'is_real');
      const isLoggedIn = app.isLoggedIn(state);

      // Do not call identify with Albert and services if not logged in or profile ID DNE or fake profile.
      if (!isLoggedIn || !profileId || !profileIsReal) return;

      // Wait 15 min between identify (for scale purposes - this is what the app does as well).
      // NOTE: See Utilities > tracking.swift > identify() method for more info.
      const LAST_IDENTIFY_KEY = `${profileId}-li`;
      const IDENTIFY_INTERVAL_MINUTES = 15;
      const lastIdentifyTime = profileId
        ? localStorage.getItem(LAST_IDENTIFY_KEY)
        : null;

      // Send to our tracking table (/v2/tracking/).
      try {
        // If lastIdentifyTime DNE (very first time calling identify), skip this.
        if (lastIdentifyTime) {
          const lastIdentifyDate = new Date(parseInt(lastIdentifyTime, 10));
          const minutesDiff = getDifferenceInMinutes(
            new Date(),
            lastIdentifyDate
          );

          // Do not proceed if we've already identified within past 15 minutes.
          if (minutesDiff > IDENTIFY_INTERVAL_MINUTES) {
            // Set in localStorage the timestamp of last identify.
            localStorage.setItem(LAST_IDENTIFY_KEY, `${new Date().getTime()}`);

            AlbertAnalytics.identify();
            logger.debug('[analytics] user identified to custom analytics');
          }
        } else {
          AlbertAnalytics.identify();
          logger.debug('[analytics] user identified to custom analytics');
        }
      } catch (err) {
        logger.log(`Unable to identify to Albert Analytics: ${err}`);
      }

      // Do not track external services if not in trackable environment.
      if (!isTrackableEnvironment()) return;

      if (GTM_ENABLED) {
        try {
          window.gtag('config', 'G-2HFD2GVT5C', {
            user_id: `${profileId}`,
          });
          window.gtag('config', 'AW-932828725', {
            user_id: `${profileId}`,
          });
          logger.debug('[analytics] setting user ID with Google Tag Manager');
        } catch (err) {
          logger.log(`Could not set ID with Google Tag Manager: ${err}`);
        }
      } else {
        logger.debug(
          '[analytics] Google Tag Manager disabled, skipping identify'
        );
      }

      if (BRANCH_ENABLED) {
        try {
          branch.setIdentity(`${profileId}`, (err, data) => {
            if (err) {
              logger.log(`branch identification failed: ${err}`);
            }

            if (data) {
              logger.info(
                `branch identification success: ${JSON.stringify(data)}`
              );
            }
          });
          logger.debug('[analytics] user identified to Branch');

          // TODO: PHASE 3 - Branch
          // Branch - map our user id to the branch id. Do this only once.
          // We do it this way and not at signup for reverse compatibility
          /* 2021/04/12, MPR: There does not appear to be an equivalent Branch method to
           * tie our profile ID to a branch fingerprint. We can fire a track event with a
           * custom branch name? I'll do that. Note the we have both the Branch "identity"
           * available in addition to the Branch "fingerprint". We use identity below.
           */
          branch.data((err, data) => {
            if (err) {
              logger.log(`Error associating albert id and branch id ${err}`);
            }
            try {
              branch.track(
                AlbertTrackingEvent.IDENTIFY,
                {
                  albertProfileId: profileId,
                  branchId: data?.identity,
                },
                (errTrack) => {
                  logger.log(
                    `Error associating albert id and branch id ${errTrack}`
                  );
                }
              );
            } catch (errUnknown) {
              logger.log(
                `Error associating albert id and branch id ${errUnknown}`
              );
            }
          });
        } catch (err) {
          logger.log(`Unable to identify to Branch: ${err}`);
        }
      } else {
        logger.debug('[analytics] Branch disabled, skipping identify');
      }
    } catch (err) {
      logger.log(
        `Could not run analytics identify, unexpected non-vendor error: ${err}`
      );
    }
  },

  /**
   * Resets the identity of the user to track session anonymously
   */
  resetIdentity(): void {
    if (BRANCH_ENABLED) {
      try {
        branch.logout((err) => {
          if (err) {
            logger.log(`unable to reset branch user identity: ${err}`);
          }
        });
      } catch (err) {
        logger.log(`unable to reset branch user identity: ${err}`);
      }
    } else {
      logger.debug('[analytics] Branch disabled, skipping reset');
    }
  },

  /**
   * Track an event to external services
   *
   * @param event (string) - event to track
   * @param subEvent (string) - logs additional info to the event in Albert's db
   * @param trackSocial (bool) - track event to external social services like Facebook
   * @param trackToAlbert (bool)- track to Albert's db
   * @param purchaseAmount (number) - special property tracked through social
   * @param trackOnce (bool) - prevents the event from being logged more than once
   * @param info (object) - additional metadata to include with event payload
   * @param containsSensitiveInfo (bool) - flag indicating sensitive info is in info payload
   */
  track({
    event,
    subEvent = '',
    trackSocial = false,
    trackToAlbert = false,
    purchaseAmount = 0,
    trackOnce = false,
    info = {},
    containsSensitiveInfo = false,
  }: WEB.TrackKwargs): void {
    try {
      const state = store.getState();
      const profileId = profile.getValueByField(state, 'id');

      if (!profileId && trackOnce) {
        logger.log(
          `Event ${event} is attempting to be tracked once for a logged out user. This is most likely a logical error.`
        );
      }

      // Use local storage to persist tracked events across sessions
      // NOTE: do we want to use local storage or some other mechanism? for context, in the app
      // we store tracked event names in LocalStore, which persists for the duration of the app's lifespan
      /* 2021/04/13, MPR: added profile prefix, as we cannot assume a single user in the web context */
      const alreadyTrackedEvent = !!JSON.parse(
        localStorage.getItem(`${profileId}-${event}`) || 'false'
      );

      // For certain events (ie. enable savings) only track once
      if (trackOnce && alreadyTrackedEvent) {
        return;
        // Store the event name on local storage for future reference
      }
      if (trackOnce) {
        localStorage.setItem(`${profileId}-${event}`, 'true');
      }

      const profileIsReal = !!profile.getValueByField(state, 'is_real');

      // Only track if profile is real and in a trackable environment (ie. prod)
      if (isTrackableEnvironment() && profileIsReal) {
        // External party custom events. We use this to track things like signed up,
        // added account, or enabled savings
        if (trackSocial) {
          trackWithServices(
            event,
            purchaseAmount,
            containsSensitiveInfo ? {} : info
          );
        }

        // Track to Albert's db - use this sparingly
        if (trackToAlbert) {
          AlbertAnalytics.track(event, subEvent, info);
        }
      }
    } catch (err) {
      logger.log(
        `Could not run analytics track, unexpected non-vendor error: ${err}`
      );
    }
  },

  /**
   * Store the referring profile and the referring partner from branch link params
   */
  // TODO: Future - for now, referrals will only link to the mobile app
  /* 2021/04/12, MPR: Is this not what branch does on its own? Do we want these
   * details in our DB? This seems like we do not need ot do this.
   */
  // setReferringData(): void {},
};

export default TrackingBase;
