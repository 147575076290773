import * as React from 'react';
import List from 'components/common/List';
import { TextColor, TextSizes } from 'components/common/Text';
import * as WEB from 'types/interfaces';

type Props = {
  list: WEB.LanguageInfoList;
  /* adjusts the spacing under the heading */
  addExpanderSubheadSpacing?: boolean;
};

const InfoPaneList = (props: Props): React.ReactElement => {
  const { addExpanderSubheadSpacing = true } = props;
  const contentType = props.list.type;
  const textSize = props.list.altText ? TextSizes.MEDIUM : '';
  const textColor = props.list.altText ? TextColor.GRAY_DARK : TextColor.BLACK;
  return (
    <List
      hasMarkdown
      textSize={textSize}
      textColor={textColor}
      items={props.list.items || []}
      type={contentType}
      addExpanderSubheadSpacing={addExpanderSubheadSpacing}
      className='info-pane-list'
    />
  );
};

export default InfoPaneList;
