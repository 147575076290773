/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
import CheckCircleRounded from 'components/icons/CheckCircleRounded';
import { Roles } from 'constants/Accessibility';
import ArtMap, { Art } from 'constants/ArtMap';
import { breakpoints, fontSizes, mixins, spacers } from 'styles';
import { handleActivateKeyDown } from 'utils/accessibility';

export type Props = {
  /* key for the card */
  value?: any;
  /* the state of the card */
  selected?: boolean | false;
  /* hide the checkmark on the selected card */
  hideCheckmark?: boolean;
  /* the size of the card ie. large, small */
  size?: CardSize | string;
  /* image on the card */
  image?: Art | string;
  /* disabled image on the card */
  altImage?: Art | string;
  /* header text on the card */
  header?: string | React.ReactNode;
  /* body text on the card */
  body?: string;
  /* error state of the card */
  isError?: boolean;
  /* the theme to use for this component */
  theme?: any;
  /* grid item */
  grid?: boolean;
  /* tab index to set so card can be accessed using tab key */
  tabIndex?: number;
  /* onClick handler function */
  clickHandler?: (...args: any) => any;
};

export enum CardSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

const getSideLength = ({ size }: Props) => {
  let side: string;
  if (size === CardSize.SMALL) side = '86px';
  else if (size === CardSize.MEDIUM) side = '108px';
  else side = '232px';
  return mixins.pxToRem(side);
};

const getBorderColor = (props: Props, isHovering = false) => {
  const { theme, isError } = props;
  if (isError) {
    return theme?.colors.primaryError;
  }
  if ((!isMobile && isHovering) || props.selected) {
    return theme?.colors.primaryAlbertBrand;
  }
  return theme?.colors.solitudeGray;
};

const getHeaderStyles = ({ size, theme, selected, isError }: Props) =>
  size !== 'large'
    ? `
      font-weight: 650;
      ${fontSizes.fontSize16}
      color: ${
        isError
          ? theme?.colors.primaryError
          : selected
          ? theme?.colors.primaryAlbertBrand
          : theme?.colors.loganGray
      };
      line-height: 150%;
    `
    : `
      ${fontSizes.fontSize18}
      line-height: ${mixins.pxToRem('21px')};
      color: ${
        isError ? theme?.colors.primaryError : theme?.colors.primaryText
      };
      font-weight: normal;
      margin-bottom: ${mixins.pxToRem('5px')};
      `;

const getBodyStyles = ({ size }: Props) =>
  size !== 'large' ? fontSizes.fontSize12 : fontSizes.fontSize14;

/* NOTE: Perfect square grid item trick */
const gridTrick = (grid: any) =>
  grid &&
  `
    margin-right: 0;
    &::before {
      content: '';
      padding-bottom: 100%;
    }
  `;
const StyledCard = styled.div<Props>`
  ${(props: Props) => {
    if (props.grid) {
      return 'width: 100%;';
    }
    const sideLength = getSideLength(props);
    return `
      min-height: ${sideLength};
      min-width: ${sideLength};
      max-height: ${sideLength};
      max-width: ${sideLength};
      `;
  }};

  border: 1.5px solid ${(props: Props) => getBorderColor(props)};
  ${(props) =>
    props.selected && `box-shadow: 0 0 0 1px ${getBorderColor(props)};`}
  border-radius: ${mixins.pxToRem('4px')};
  display: flex;
  flex-direction: column;
  justify-content: ${({ image }: Props) => (image ? 'flex-start' : 'center')};
  align-items: center;
  text-align: center;
  @media (hover: hover) {
    &:hover {
      border-color: ${(props: Props) =>
        !isMobile && getBorderColor(props, true)};
      box-shadow: 0 0 0 1px
        ${(props: Props) => !isMobile && getBorderColor(props, true)};
      cursor: ${!isMobile && 'pointer'};
    }
  }
  position: relative;
  padding: ${mixins.pxToRem('18px')};
  margin-right: ${spacers.tiny};
  ${({ grid }) => gridTrick(grid)}
`;

/* NOTE: Perfect square grid item trick */
const StyledCardContent = styled.div<Props>`
  ${({ grid }) =>
    grid &&
    `
    position: absolute;
    left: ${mixins.pxToRem('5px')};
    right: ${mixins.pxToRem('5px')};
    margin-right: auto;
    margin-left: auto;
    text-align: center;
  `};
`;

export const CheckCircleContainer = styled.div<Props>`
  width: ${mixins.pxToRem('26px')};
  color: ${(props: Props) => props.theme?.colors.primaryAlbertBrand};
  position: absolute;
  top: -12px;
  right: -12px;

  @media ${breakpoints.mobileMedium} {
    width: 1.125rem;
    top: -9px;
    right: -9px;
  }
`;

const StyledCheckCircle = (props: Props) => (
  <CheckCircleContainer {...props}>
    <CheckCircleRounded />
  </CheckCircleContainer>
);

const StyledCardHeader = styled.header<Props>`
  ${(props: Props) => getHeaderStyles(props)}
`;
const StyledCardBody = styled.span<Props>`
  ${(props: Props) => getBodyStyles(props)}
  display: inline-block;
  color: ${({ theme }) => theme?.colors.loganGray};
`;
const StyledImage = styled.img<Props>`
  color: ${({ theme }) => theme?.colors.primaryGenius};
  width: ${mixins.pxToRem('58px')};
  height: ${mixins.pxToRem('58px')};
  margin: ${mixins.pxToRem('16.38px')};
`;

const SquareCard = ({
  clickHandler,
  ...props
}: Props): React.ReactElement<Props> => {
  const {
    selected,
    altImage,
    image,
    header,
    body,
    value,
    isError,
    tabIndex,
    hideCheckmark,
  } = props;

  // Default to the image if card isn't selected but no altImage is passed in
  const imageSource = !selected ? altImage || image : image;

  const handleSelect = () => {
    clickHandler && clickHandler(value);
  };

  return (
    <StyledCard
      {...props}
      onClick={handleSelect}
      onKeyDown={(e) => handleActivateKeyDown(e, handleSelect)}
      tabIndex={tabIndex || 0}
      role={Roles.BUTTON}
    >
      {selected && !isError && !hideCheckmark && <StyledCheckCircle />}
      <StyledCardContent {...props} className='square-card-content'>
        {image && (
          <StyledImage src={ArtMap(imageSource as Art | string)} {...props} />
        )}
        <StyledCardHeader {...props} className='square-card-header'>
          {header}
        </StyledCardHeader>
        {body && (
          <StyledCardBody {...props} className='square-card-body'>
            {body}
          </StyledCardBody>
        )}
      </StyledCardContent>
    </StyledCard>
  );
};

export default SquareCard;
