import * as React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Button from 'components/common/Button';
import Spacer from 'components/common/Spacer';
import Icon from 'components/icons/SetUp';
import ButtonsContainer from 'components/layout/ButtonsContainer';
import LeadContent from 'components/layout/LeadContent';
import PageBase from 'components/layout/PageBase';
import ArtMap from 'constants/ArtMap';
import { Art } from 'constants/ArtMap';
import { Pages } from 'constants/Pages';
import { PageRoutes } from 'constants/index';
import useBrowserNavigationOverrideWithBanner from 'hooks/useBrowserNavigationBlockWithBanner';
import useVerifyCurrentPage from 'hooks/useVerifyCurrentPage';
import { getSignupPageLanguage } from 'reducers/entities/language';
import { spacers } from 'styles/home';
import { RootState } from 'types/interfaces';
import { getLanguageSection } from 'utils/getFromLanguage';

type TrackSpendingProps = {
  nextPage: string;
};

export function TrackSpending(props: TrackSpendingProps) {
  useVerifyCurrentPage(Pages.TRACK_SPENDING);
  useBrowserNavigationOverrideWithBanner();

  const navigate = useNavigate();

  const languageCtx = useSelector((state: RootState) =>
    getSignupPageLanguage(state, Pages.TRACK_SPENDING)
  );
  const introLanguage = getLanguageSection(languageCtx, 'intro');
  const linkAccountLanguage = getLanguageSection(languageCtx, 'linkAccount');
  const ticker = (linkAccountLanguage['ticker'] as TickerItem[]) ?? [];

  const goToNextPage = () => {
    navigate(PageRoutes.LINK_ACCOUNT, {
      state: {
        pageOnComplete: props.nextPage,
        pageOnSkip: props.nextPage,
        currentPage: Pages.TRACK_SPENDING,
      },
    });
  };

  return (
    <PageBase>
      <LeadContent
        header={introLanguage?.header || ''}
        text={introLanguage.text}
      />
      <Spacer space={spacers.g6} />
      <SelectableItem
        title={linkAccountLanguage.header || ''}
        text={linkAccountLanguage.text || ''}
        onClick={goToNextPage}
      />
      <Spacer space='100px' />
      <ButtonsContainer>
        <div>
          <Ticker items={ticker} />
          <Spacer space={spacers.g4} />
          <ButtonContainer>
            <Button stretch onClick={goToNextPage}>
              {languageCtx.buttons.primary}
            </Button>
          </ButtonContainer>
        </div>
      </ButtonsContainer>
    </PageBase>
  );
}

type TickerItem = {
  icon: string;
  body: string;
};

const DEFAULT_TICKER_INTERVAL = 2000;

function Ticker({
  items,
  interval = DEFAULT_TICKER_INTERVAL,
}: {
  items: TickerItem[];
  interval?: number;
}) {
  const [index, setIndex] = React.useState(1);

  const loopedItems = React.useMemo(() => {
    if (items.length < 1) {
      return [];
    }

    if (items.length < 2) {
      return [...items, ...items, ...items, ...items];
    }

    if (items.length < 3) {
      return [...items, ...items, ...items];
    }

    return [...items, items[0], items[1], items[2]];
  }, [items]);

  React.useEffect(() => {
    let timeout: NodeJS.Timeout;

    const setIndexToNext = () => {
      setIndex((prevIndex) => {
        const nextIndex =
          prevIndex === loopedItems.length - 2 ? 1 : prevIndex + 1;
        timeout = setTimeout(
          setIndexToNext,
          nextIndex === 1 ? 10 : DEFAULT_TICKER_INTERVAL
        );
        return nextIndex;
      });
    };

    timeout = setTimeout(() => {
      setIndexToNext();
    }, 3000);

    return () => clearTimeout(timeout);
  }, [loopedItems, interval]);

  const shouldTransition = index !== 1;

  if (loopedItems.length < 1) {
    return null;
  }

  return (
    <Ticker.Container>
      <div
        style={{
          transform: `translateX(calc(${-index} * var(--ticker-item-width) + (100% - var(--ticker-item-width)) * 0.5))`,
          transition: shouldTransition ? 'transform 0.5s' : 'none',
        }}
      >
        {loopedItems.map((item, i) => (
          <div className='ticker-item' key={i}>
            {item.icon} {item.body}
          </div>
        ))}
      </div>
    </Ticker.Container>
  );
}

Ticker.Container = styled.div`
  width: 100vw;

  background-color: ${({ theme }) => theme.colors.lightGray3};

  --ticker-item-width: 100%;

  @media (min-width: 400px) {
    --ticker-item-width: 80%;
  }

  @media (min-width: 450px) {
    --ticker-item-width: 70%;
  }

  @media (min-width: 550px) {
    --ticker-item-width: 60%;
  }

  @media (min-width: 1150px) {
    width: 500px;
    --ticker-item-width: 100%;
    background-color: transparent;
  }

  text-align: center;
  font-weight: 500;
  padding-top: 14px;
  padding-bottom: 14px;
  overflow: hidden;
  white-space: nowrap;
  position: relative;

  .ticker-item {
    display: inline-block;
    width: var(--ticker-item-width);
  }
`;

function SelectableItem({
  title,
  text,
  onClick,
}: {
  title: string;
  text: string;
  onClick?: () => void;
}) {
  // Remove any strings that are wrapped in backticks
  const cleanedText = text.replace(/`.*?`/g, '');

  return (
    <SelectableItem.Container onClick={onClick}>
      <SelectableItem.Left>
        <img src={ArtMap(Art.Banking)} alt='' />
        <div>
          <h3>{title}</h3>
          <p>
            <Icon icon='lock' />
            &nbsp;{cleanedText}
          </p>
        </div>
      </SelectableItem.Left>
      <div>
        <button>
          <Icon icon='plus' />
        </button>
      </div>
    </SelectableItem.Container>
  );
}

SelectableItem.Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-top: 26px;
  padding-bottom: 26px;
  padding-left: 22px;
  padding-right: 22px;
  cursor: pointer;
  border-radius: 12px;
  background: white;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12);

  button {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) =>
      theme.colors.primaryAlbertBrand} !important;
    border-radius: 9999px;
    cursor: pointer;
    background: none;

    &:visited,
    &:active,
    &:hover,
    &:link {
      text-decoration: none;
    }
  }
`;

SelectableItem.Left = styled.div`
  display: flex;

  img {
    margin-right: 20px;
    width: 46px;
    height: 46px;
  }

  h3 {
    margin: 0px;
    line-height: 21.3px;
  }

  p {
    padding-top: 4px;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.1em;
    margin: 0;
  }
`;

const ButtonContainer = styled.div`
  padding-left: 22px;
  padding-right: 22px;
`;
