import { FORM_ERROR } from 'final-form';
import * as React from 'react';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import updateProfile from 'actions/async/updateProfile';
import Button from 'components/common/Button';
import Input from 'components/common/Input';
import { InputWidth } from 'components/common/Input/shared';
import Spacer from 'components/common/Spacer';
import ButtonsLayout from 'components/layout/ButtonsLayout';
import LeadContent from 'components/layout/LeadContent';
import PageBase from 'components/layout/PageBase';
import { DATE_FULLY_ENTERED } from 'constants/DateInputValidation';
import Pages from 'constants/Pages';
import useNavigateFunction from 'hooks/useNavigateFunction';
import useVerifyCurrentPage from 'hooks/useVerifyCurrentPage';
import * as language from 'reducers/entities/language';
import * as profile from 'reducers/entities/profile';
import { spacers } from 'styles/home';
import * as WEB from 'types/interfaces';
import { convertDateStringToUnix, getMonthDayYearFromUnix } from 'utils/dates';
import { delayWithState } from 'utils/delay';
import { getLanguageButtons, getLanguageSection } from 'utils/getFromLanguage';
import { composeValidators, validBirthday } from 'utils/validators';

type Props = {
  nextPage: string;
};

type Values = {
  birthday: string;
};

const Birthday = (props: Props) => {
  const { updateCurrentSignupPage } = useNavigateFunction();
  const dispatch = useDispatch<WEB.Dispatch>();
  // Make sure Redux is up-to-date with currentSignUpPage
  useVerifyCurrentPage(Pages.BIRTHDAY);
  const [isCompleted, setIsCompleted] = React.useState<boolean>(false);

  const languageCtx = useSelector((state: WEB.RootState) =>
    language.getSignupPageLanguage(state, Pages.BIRTHDAY)
  );
  const ctx = getLanguageSection(languageCtx, 'birthday');
  const buttonsCtx = getLanguageButtons(languageCtx);

  const birthdayDefault = useSelector((state: WEB.RootState) =>
    profile.getValueByField(state, 'birthday')
  );
  let defaultBirthdayValue = '';
  if (birthdayDefault) {
    // Unix to javascript Date object
    const [monthDefault, dayDefault, yearDefault] = getMonthDayYearFromUnix(
      birthdayDefault,
      false,
      true
    );
    const month = Number(monthDefault) < 10 ? `0${monthDefault}` : monthDefault;
    const day = Number(dayDefault) < 10 ? `0${dayDefault}` : dayDefault;
    defaultBirthdayValue = `${month}/${day}/${yearDefault}`;
  }

  const onSubmit = async (values: Values) => {
    const birthdayUnix = convertDateStringToUnix(values.birthday, 'MM/dd/yyyy');
    const { error } = await dispatch(
      updateProfile({
        birthday: birthdayUnix,
      })
    );
    if (error) return { [FORM_ERROR]: 'error' };
  };
  const submitCallback = async (errors?: unknown): Promise<void> => {
    if (!errors) {
      await delayWithState(400, setIsCompleted);
      dispatch(updateCurrentSignupPage({ page: props.nextPage }));
    }
  };

  const BirthdayInput = ({ input, meta }: Partial<WEB.InputFieldProps>) => {
    const value = input?.value as string | undefined;

    const isInvalid =
      (meta?.submitFailed || (value && DATE_FULLY_ENTERED.test(value))) &&
      meta?.error;

    return (
      <Input.Date
        id='birthday'
        key='birthday'
        floatingLabel={languageCtx.content.birthday.placeholder || 'Birthday'}
        secondaryLabel='(MM/DD/YYYY)'
        label='Birthday'
        widthSize={InputWidth.SMALL}
        valueDefault={value}
        {...input}
        invalid={isInvalid}
        errorText={validBirthday(value || '')}
      />
    );
  };

  return (
    <PageBase>
      <LeadContent
        header={ctx?.header}
        text={ctx?.text}
        desktopHeaderSize='small'
      />
      <Form<Values>
        onSubmit={onSubmit}
        initialValues={{
          birthday: defaultBirthdayValue,
        }}
        render={({ handleSubmit, submitting }) => (
          <form
            onSubmit={(event) => {
              handleSubmit(event)?.then(submitCallback);
            }}
          >
            <Spacer space={spacers.g8} desktopOnly />
            <Field
              name='birthday'
              component={BirthdayInput}
              validate={composeValidators(validBirthday)}
            />
            <ButtonsLayout
              marginTop={spacers.g9}
              primaryButton={
                <Button
                  isLoading={submitting}
                  disabled={submitting}
                  isCompleted={isCompleted}
                >
                  {isCompleted ? '' : buttonsCtx?.primary}
                </Button>
              }
            />
          </form>
        )}
      />
    </PageBase>
  );
};

export default Birthday;
