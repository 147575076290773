import addBanner from 'actions/banner/addBanner';
import removeBanner from 'actions/banner/removeBanner';
import types from 'actions/types';
import AlbertClient from 'api/AlbertClient';
import {
  BannerActionType,
  NotificationTypes,
  ResponseStatus,
} from 'constants/index';
import * as WEB from 'types/interfaces';
import {
  onError,
  onErrorDisplayDefaultBanner,
  onSuccess,
} from 'utils/axiosMiddlewareUtils';

export default (
    phone: string,
    includeBanner?: boolean
  ): WEB.ActionCreator<Promise<string | boolean>> =>
  async (dispatch: WEB.Dispatch): Promise<string | boolean> => {
    // Remove any error banner from previous attempts at this action
    dispatch(removeBanner());

    // Include a banner as well
    if (includeBanner) {
      const bannerBody = `We sent a new code to your number **${phone}**`;
      dispatch(addBanner(NotificationTypes.SUCCESS, bannerBody, true));
    }

    let error: string | boolean = false;
    const response: WEB.Action = await dispatch({
      types: [
        types.TEXT_WELCOME_REQUEST,
        types.TEXT_WELCOME_SUCCESS,
        types.TEXT_WELCOME_FAILURE,
      ],
      payload: {
        request: {
          method: 'post',
          url: AlbertClient.textWelcomeView(),
          data: {
            phone,
          },
        },
        options: {
          onSuccess: (middlewareOptions: any, options: any) => {
            const { status, message } = middlewareOptions.response?.data || {};

            // Handle phone voip errors and fraud errors
            // by returning the API's user friendly error message
            // to be displayed under the input.
            if (status === ResponseStatus.ERROR) {
              error = message;
              return onSuccess(middlewareOptions, options);
            }
            return onSuccess(middlewareOptions, options);
          },
          onError: (middlewareOptions: any, options: any) => {
            const buttonAction =
              middlewareOptions.error?.response?.data?.button_action;
            error = true;

            // Display a banner for a duplicate phone error
            if (buttonAction && buttonAction === BannerActionType.LOGIN_APP) {
              const bannerMessage =
                'An Albert account with this number already exists. Try a different number or log in on the app.';
              dispatch(
                addBanner(
                  NotificationTypes.WARNING,
                  bannerMessage,
                  false,
                  buttonAction as BannerActionType
                )
              );
              return onError(middlewareOptions, options);
            }

            return onErrorDisplayDefaultBanner(middlewareOptions, options);
          },
        },
      },
    });

    return error;
  };
