import { combineReducers } from 'redux';
import appData from './appData';
import banner from './banner';
import geniusPayments from './geniusPayments';
import institution from './institution';
import language from './language';
import profile from './profile';

const entities = combineReducers({
  appData,
  profile,
  language,
  institution,
  banner,
  geniusPayments,
});

export default entities;
