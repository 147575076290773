import {
  getDaysInMonth as dateFnsGetDaysInMonth,
  differenceInMinutes,
  format,
  getYear,
  parse,
  subYears,
} from 'date-fns';

export const MONTHS_KEY = {
  1: 'Jan',
  2: 'Feb',
  3: 'Mar',
  4: 'Apr',
  5: 'May',
  6: 'Jun',
  7: 'Jul',
  8: 'Aug',
  9: 'Sep',
  10: 'Oct',
  11: 'Nov',
  12: 'Dec',
};
const FULL_MONTHS = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December',
};

/**
 * Format date.
 *
 * @param date (string | number)
 * @param dateFormat (string)
 * @returns formatted date (string)
 */
export const formatDate = (
  date: string | number | Date,
  dateFormat: string
): string => {
  let dateToFormat = date;
  if (typeof date === 'number') {
    dateToFormat = date * 1000;
  }
  const datetime = new Date(dateToFormat);
  const utcOffsetDatetime = new Date(
    datetime.valueOf() + datetime.getTimezoneOffset() * 60 * 1000
  );
  const datetimeOnly = new Date(utcOffsetDatetime);
  try {
    return format(datetimeOnly, dateFormat);
  } catch (err) {
    return '-';
  }
};

/**
 * Returns array of valid years users can select as birthday year.
 * NOTE: In the app, we have min/max age of 18/130, respectively.
 *
 * @param asString (boolean) - years as array of strings
 * @returns years (as array of string or number)
 */
export const getAlbertYearRange = (asString?: boolean): (string | number)[] => {
  const MIN_AGE = 18;
  const MAX_AGE = 100;

  const now = new Date();
  const minYear = getYear(subYears(now, MAX_AGE));
  const maxYear = getYear(subYears(now, MIN_AGE));

  const result: (string | number)[] = [];
  for (let i = maxYear; i >= minYear; i -= 1) {
    result.push(asString ? `${i}` : i);
  }
  return result;
};

/**
 * Returns year, month, and day extracted from unix timestamp.
 *
 * @param unix (number) - unix timestamp
 * @returns array of [month, day, year]
 */
export const getMonthDayYearFromUnix = (
  unix: number,
  long = false,
  monthAsNumber = false
): string[] => {
  const date = new Date(unix * 1000);
  const monthNum = date.getUTCMonth() + 1; // 0 indexed
  const monthAbbrev = long ? FULL_MONTHS[monthNum] : MONTHS_KEY[monthNum];
  const day = date.getUTCDate();
  const year = date.getUTCFullYear();
  const month = monthAsNumber ? monthNum : monthAbbrev;
  return [`${month}`, `${day}`, `${year}`];
};

/**
 * Get days in month.
 *
 * @param year
 * @param month
 * @returns number of days in month in that year
 */
export const getDaysInMonth = (year: number, month: number): number => {
  return dateFnsGetDaysInMonth(new Date(year, month));
};

export const getMonthIndex = (month: string): number => {
  const months = Object.values(MONTHS_KEY);
  return months.indexOf(month);
};

export const getDifferenceInMinutes = (
  firstDate: Date,
  secondDate: Date
): number => {
  return differenceInMinutes(firstDate, secondDate);
};

// this method needs to be updated to support the different formats
export const convertDateStringToUnix = (dateStr: string, format?: string) => {
  const date = parse(dateStr, format || 'MM/dd/yyyy', new Date());
  return date.getTime() / 1000;
};
