import types from 'actions/types';
import AlbertClient from 'api/AlbertClient';
import * as WEB from 'types/interfaces';
import { onErrorDisplayDefaultBanner } from 'utils/axiosMiddlewareUtils';

/**
 * Create a Banking account.
 */
export default (): WEB.ActionCreator<Promise<boolean>> =>
  async (dispatch: WEB.Dispatch): Promise<any> => {
    let error = false;

    const response = await dispatch({
      types: [
        types.CREATE_BANKING_ACCOUNT_REQUEST,
        types.CREATE_BANKING_ACCOUNT_SUCCESS,
        types.CREATE_BANKING_ACCOUNT_FAILURE,
      ],
      payload: {
        request: {
          method: 'post',
          url: AlbertClient.bankingAccountView(),
        },
        options: {
          onError: (middlewareOptions: any, options: any) => {
            error = true;
            return onErrorDisplayDefaultBanner(middlewareOptions, options);
          },
        },
      },
    });

    return { response, error };
  };
