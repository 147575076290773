import * as React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { GENIUS_PAGE_ORDER } from 'components/genius/GeniusOnboarding';
import ZIndex from 'constants/ZIndex';
import { Pages } from 'constants/index';
import * as language from 'reducers/entities/language';
import * as genius from 'reducers/ui/genius';
import * as signup from 'reducers/ui/signup';
import { breakpoints, fontSizes, mixins, spacers } from 'styles';
import { sizes } from 'styles/breakpoints';
import * as WEB from 'types/interfaces';

type Props = {
  active?: boolean;
  progress?: number;
};

const StyledContainer = styled.div`
  /**
   * Hacky workaround to have this position fixed (relative to window) 
   * but at the same time be positioned relative to the content pane
   * both "58vw" as width and same "padding-left" is applied for content pane
   */
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-left: ${mixins.pxToRem('150px')};
  padding-right: ${spacers.defaultPaddingLeftRight};
  background-color: ${({ theme }) => theme.colors.primaryBackground};
  z-index: ${ZIndex.PROGRESS_NAV_BAR};
  max-width: ${sizes.desktopMedium};
  @media ${breakpoints.mobileLarge} {
    display: none;
    padding-left: ${spacers.defaultPaddingLeftRight};
  }
  @media ${breakpoints.desktopMedium} {
    padding-left: ${mixins.pxToRem('100px')};
  }

  @media ${breakpoints.desktopSmall} {
    padding-left: ${mixins.pxToRem('70px')};
  }
`;

const StyledProgressElement = styled.div<Props>`
  display: inline-block;
  position: relative;
  padding: 0 ${spacers.tiny} ${spacers.tiny} ${spacers.tiny};
  &:first-child {
    padding-left: 0;
  }
  &:last-child {
    padding-right: 0;
  }
  text-transform: uppercase;
  ${fontSizes.fontSize12}
  font-weight: 600;
  letter-spacing: 0.05em;
  color: ${({ theme, active }) =>
    active ? theme.colors.primaryAlbertBrand : theme.colors.lightGray1};
  @media ${breakpoints.progressNavBar} {
    ${fontSizes.fontSize12}
  }
`;

const StyledProgressBar = styled.span<Props>`
  ${({ active, theme, progress }) =>
    active &&
    `
    background-color: ${theme.colors.lightGray1};
    height: 2px;
    width: 100%;
    // Using "border" causes misalignment in child pseudo element.
    box-shadow: inset 0 0 0 2px ${theme.colors.lightGray1};
    box-sizing: border-box;
    border-radius: 2px;
    position: absolute;
    bottom: 0;
    left: 0;

    &:after {
      transition: 0.5s;
      position: absolute;
      content: '';
      width: ${progress}%;
      height: 3px;
      box-sizing: inherit;
      background: ${theme.colors.primaryAlbertBrand};
      bottom: 0;
      left: 0;
      overflow: hidden;
      border: thin solid ${theme.colors.primaryAlbertBrand};
      border-radius: 50px;
    }
  `}
`;

const ProgressNavBar = (): React.ReactElement => {
  // ////////////////////////////////
  /* ======= MAPPED STATE ======== */
  // ////////////////////////////////
  const currentGeniusPage = useSelector(genius.getCurrentPage);
  const currentSignupPage = useSelector(signup.getCurrentPage);

  const flowOrder: WEB.LanguageSubflow[] = useSelector(
    language.getSignupFlowOrder
  );

  const progressElements: React.ReactElement[] = flowOrder.map(
    (subflow: WEB.LanguageSubflow): React.ReactElement => {
      const { flow, pages = [] } = subflow;

      const currentPage = currentGeniusPage || currentSignupPage;
      const currentPageIndex = pages.indexOf(currentPage);

      const active =
        currentPageIndex !== -1 ||
        (!!currentGeniusPage && pages.includes(Pages.GENIUS_TOUR));

      let progress = 0;

      if (active) {
        // GENIUS_PAGE_ORDER is defined client-side and we use this to determine Genius flow progress
        const progressPercentage = currentGeniusPage
          ? (GENIUS_PAGE_ORDER.indexOf(currentGeniusPage) + 1) /
            GENIUS_PAGE_ORDER.length
          : (currentPageIndex + 1) / pages.length;

        progress = Math.ceil(progressPercentage * 100);
      }

      return (
        <StyledProgressElement key={`progress-${flow}`} active={active}>
          {flow}
          <StyledProgressBar
            key={`progress-bar-${flow}`}
            progress={progress}
            active={active}
          />
        </StyledProgressElement>
      );
    }
  );

  return <StyledContainer>{progressElements}</StyledContainer>;
};

export default ProgressNavBar;
