/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import ReactMarkdown from 'react-markdown';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import updateProfile from 'actions/async/updateProfile';
import removeBanner from 'actions/banner/removeBanner';
import getNextPage from 'actions/getNextPage';
import TopLoadingBar from 'components/TopLoadingBar';
import Button from 'components/common/Button';
import TopInfoButton from 'components/common/Button/TopInfoButton';
import List from 'components/common/List';
import MobileInfoModal from 'components/common/Modal/MobileInfoModal';
import Spacer from 'components/common/Spacer';
import Text, { TextColor, TextSizes } from 'components/common/Text';
import ButtonsLayout from 'components/layout/ButtonsLayout';
import LeadContent from 'components/layout/LeadContent';
import PageBase from 'components/layout/PageBase';
import {
  LinkAccountCompleteLocationState,
  LinkAccountSkipLocationState,
} from 'components/linkAccount/LinkAccountFlow';
import Pages from 'constants/Pages';
import { PageRoutes } from 'constants/index';
import useNavigateFunction from 'hooks/useNavigateFunction';
import useVerifyCurrentPage from 'hooks/useVerifyCurrentPage';
import InstantConfirm from 'pages/signup/InstantConfirm';
import * as language from 'reducers/entities/language';
import { colors, fontSizes } from 'styles';
import { sizes } from 'styles/breakpoints';
import { spacers as homeSpacers } from 'styles/home';
import * as WEB from 'types/interfaces';
import {
  getLanguageButtons,
  getLanguageInfo,
  getLanguageSection,
} from 'utils/getFromLanguage';

type Props = {
  nextPage: string;
};

type LocationState =
  | (LinkAccountCompleteLocationState & LinkAccountSkipLocationState)
  | WEB.EmptyObject;

const MobileDisclaimerContainer = styled.div`
  color: ${colors.darkGray1};
  ${fontSizes.fontSize12};
  p {
    margin: 0;
  }
  @media (min-width: ${sizes.mobileLarge}) {
    display: none;
  }
`;

const InstantBenefits = ({ nextPage }: Props): React.ReactElement => {
  const { updateCurrentSignupPage } = useNavigateFunction();
  // Make Redux is up-to-date with currentSignUpPage
  useVerifyCurrentPage(Pages.INSTANT_BENEFITS);

  // ///////////////////////////////
  /* =========== HOOKS ========== */
  // ///////////////////////////////
  const dispatch = useDispatch();
  const location = useLocation();
  const locationState = (location?.state ?? {}) as LocationState;
  const navigate = useNavigate();

  // ///////////////////////////////
  /* =========== STATE ========== */
  // ///////////////////////////////
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [showConfirmPage, setShowConfirmPage] = React.useState<boolean>(
    // If user just exited the Link Account flow, then initialize to show the InstantConfirm page.
    !!locationState.showConfirmation
  );
  const [showLoadingBar, setShowLoadingBar] = React.useState<boolean>(false);
  const [showInfoModal, setShowInfoModal] = React.useState<boolean>(false);

  React.useEffect(() => {
    // Trigger a render whenever showConfirmation changes.
    // Otherwise, when navigating backwards from InstantConfirm -> InstantBenefits,
    // the url changes correctly but the app still displays InstantConfirm.
    // Show the confirm page if user skipped link account flow.
    setShowConfirmPage(
      !!locationState.showConfirmation || !!locationState.skippedLinkAccount
    );
  }, [locationState.showConfirmation, locationState.skippedLinkAccount]);

  // ///////////////////////////////
  /* ======== MAPPED STATE ====== */
  // ///////////////////////////////
  const languageCtx = useSelector((state: WEB.RootState) =>
    language.getSignupPageLanguage(state, Pages.INSTANT_BENEFITS)
  );

  // Determine whether we are in the newer Set Up First products flow
  const isSetUpFirst = useSelector(language.hasSetUpFirst);

  // //////////////////////////////////
  /* =========== HANDLERS ========== */
  // //////////////////////////////////
  const handleOnLater = async (): Promise<void> => {
    // Do nothing if a request is underway
    if (isSubmitting) return;
    // Remove any error banner from previous attempts
    dispatch(removeBanner());

    setShowLoadingBar(true);
    const { error }: any = await dispatch(
      updateProfile({
        instant_info: { signup_instant_interest: false },
      })
    );
    if (!error) {
      const { nextPageOverride }: any = await dispatch(getNextPage());
      setShowLoadingBar(false);
      dispatch(updateCurrentSignupPage({ page: nextPageOverride ?? nextPage }));
    }
  };

  const handleOnNext = async (): Promise<void> => {
    // Remove any error banner from previous attempts
    dispatch(removeBanner());

    setIsSubmitting(true);
    const { error }: any = await dispatch(
      updateProfile({
        instant_info: { signup_instant_interest: true },
      })
    );
    setIsSubmitting(false);

    if (!error) {
      // Navigate to the Link Account flow for the user to link their bank account with Albert.
      navigate(PageRoutes.LINK_ACCOUNT, {
        state: {
          pageOnComplete: Pages.INSTANT_BENEFITS,
          pageOnSkip: Pages.INSTANT_BENEFITS,
          currentPage: Pages.INSTANT_BENEFITS,
        },
      });
    }
  };

  const handleInfoOnClick = () => {
    setShowInfoModal(true);
  };

  // //////////////////////////////////
  /* =========== LANGUAGE ========== */
  // //////////////////////////////////
  const INTRO_KEY = 'intro';
  const BENEFITS_KEY = 'benefits';

  const introSection = getLanguageSection(languageCtx, INTRO_KEY);
  const benefitsSection = getLanguageSection(languageCtx, BENEFITS_KEY);
  const infoPaneContext = getLanguageInfo(languageCtx);
  const buttonsContext = getLanguageButtons(languageCtx);
  const expanders = infoPaneContext?.expanders;

  const instantBenefitsPage = (
    <>
      <PageBase>
        <LeadContent header={introSection?.header} text={introSection?.text} />
        <List
          items={benefitsSection?.list ?? []}
          type={WEB.ListType.List}
          itemSpacing={homeSpacers.g10}
          textColor={isSetUpFirst ? TextColor.GRAY_DARK : TextColor.BLACK}
          textSize={isSetUpFirst ? TextSizes.MEDIUM : ''}
        />
        <MobileDisclaimerContainer>
          <Spacer space={homeSpacers.g13} />
          <ReactMarkdown>{infoPaneContext?.disclaimer ?? ''}</ReactMarkdown>
        </MobileDisclaimerContainer>
        <ButtonsLayout
          marginTop={homeSpacers.g9}
          primaryButton={
            <Button
              onClick={handleOnNext}
              isLoading={isSubmitting}
              disabled={isSubmitting}
            >
              {buttonsContext?.primary}
            </Button>
          }
          secondaryButton={
            <Text
              weight='700'
              size='small'
              onClick={handleOnLater}
              isLinkButton
            >
              <u>{buttonsContext?.link}</u>
            </Text>
          }
        />
      </PageBase>
    </>
  );

  const showInfo = !showConfirmPage && Array.isArray(expanders);

  return (
    <>
      {showInfo && <TopInfoButton onClick={handleInfoOnClick} />}
      {showLoadingBar && <TopLoadingBar isLoading={isSubmitting} />}
      {showConfirmPage ? (
        <InstantConfirm nextPage={nextPage} />
      ) : (
        instantBenefitsPage
      )}
      {showInfo && (
        <MobileInfoModal
          expanders={expanders}
          show={showInfoModal}
          onCancel={() => setShowInfoModal(false)}
        />
      )}
    </>
  );
};

export default InstantBenefits;
