/* eslint-disable @typescript-eslint/await-thenable */
import addBanner from 'actions/banner/addBanner';
import removeBanner from 'actions/banner/removeBanner';
import types from 'actions/types';
import AlbertClient from 'api/AlbertClient';
import { NotificationTypes } from 'constants/index';
import * as WEB from 'types/interfaces';
import {
  onError,
  onErrorDisplayDefaultBanner,
  onSuccess,
} from 'utils/axiosMiddlewareUtils';
import getErrorMessageFromResponse from 'utils/getErrorMessageFromResponse';

export interface LoginFields {
  email: string;
  password: string;
}

export default (
    loginFields: LoginFields,
    /** Overrides the server error message in the error banner */
    errorMessage?: string
  ): WEB.ActionCreator<Promise<boolean>> =>
  async (dispatch: WEB.Dispatch): Promise<boolean> => {
    let error = false;

    // Remove any error banner from previous attempts at this action
    dispatch(removeBanner());

    await dispatch({
      types: [types.LOGIN_REQUEST, types.LOGIN_SUCCESS, types.LOGIN_FAILURE],
      payload: {
        request: {
          method: 'post',
          url: AlbertClient.loginView(),
          data: {
            email: loginFields.email.toLowerCase(),
            password: loginFields.password,
          },
        },
        options: {
          onSuccess: (middlewareOptions: any, options: any) => {
            return onSuccess(middlewareOptions, options);
          },
          onError: (middlewareOptions: any, options: any) => {
            error = true;
            const message =
              errorMessage ||
              getErrorMessageFromResponse(middlewareOptions?.error?.response);
            if (message) {
              dispatch(addBanner(NotificationTypes.WARNING, message, false));
              return onError(middlewareOptions, options);
            }

            return onErrorDisplayDefaultBanner(middlewareOptions, options);
          },
        },
      },
    });

    return error;
  };
