import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import getEmailVerificationStatus from 'actions/async/getEmailVerificationStatus';
import sendEmailVerificationLink from 'actions/async/sendEmailVerificationLink';
import { renderLabels } from 'components/common/Input/shared';
import Spacer from 'components/common/Spacer';
import Text, { TextColor, TextSizes } from 'components/common/Text';
import PageBase from 'components/layout/PageBase';
import Pages from 'constants/Pages';
import useNavigateFunction from 'hooks/useNavigateFunction';
import useVerifyCurrentPage from 'hooks/useVerifyCurrentPage';
import VerifyEmailUpdate from 'pages/signup/VerifyEmailUpdate';
import * as language from 'reducers/entities/language';
import { getValueByField } from 'reducers/entities/profile';
import { breakpoints, spacers } from 'styles';
import * as WEB from 'types/interfaces';
import { getLanguageButtons, getLanguageSection } from 'utils/getFromLanguage';

type Props = {
  nextPage: string;
};

const EmailVerificationSent = (props: Props) => {
  const { updateCurrentSignupPage } = useNavigateFunction();
  // Make Redux is up-to-date with currentSignUpPage
  useVerifyCurrentPage(Pages.VERIFY_EMAIL);

  // ///////////////////////////////
  /* =========== HOOKS ========== */
  // ///////////////////////////////
  const dispatch = useDispatch();

  // ///////////////////////////////
  /* =========== STATE ========== */
  // ///////////////////////////////
  const [isUpdatingEmail, setIsUpdatingEmail] = React.useState(false);

  // ///////////////////////////////
  /* ======== MAPPED STATE ====== */
  // ///////////////////////////////
  const languageCtx = useSelector((state: WEB.RootState) =>
    language.getSignupPageLanguage(state, Pages.VERIFY_EMAIL)
  );
  const email = useSelector((state: WEB.RootState) =>
    getValueByField(state, 'email')
  );
  const emailToUpdate = useSelector((state: WEB.RootState) =>
    getValueByField(state, 'email_to_update')
  );

  // //////////////////////////////////
  /* =========== LANGUAGE ========== */
  // //////////////////////////////////
  const introSection = getLanguageSection(languageCtx, 'intro');
  const buttons = getLanguageButtons(languageCtx);

  // //////////////////////////////////
  /* =========== HANDLERS ========== */
  // //////////////////////////////////

  const handleClickUpdateEmail = () => {
    setIsUpdatingEmail(true);
  };

  const handleCompleteEmailUpdate = () => {
    setIsUpdatingEmail(false);
  };

  const handleClickResend = () => {
    dispatch(sendEmailVerificationLink('signup', true));
  };

  // ///////////////////////////////
  /* ========== EFFECTS ========= */
  // ///////////////////////////////

  // Refresh every 15s to get the user's latest email verification status.
  // Automatically advance to the next page when we detect that the user
  // has received the email.
  React.useEffect(() => {
    const intervalId = setInterval(async () => {
      const { response, error }: any = await dispatch(
        getEmailVerificationStatus()
      );

      if (!error && response?.payload?.data?.email_not_bounced) {
        dispatch(updateCurrentSignupPage({ page: props.nextPage }));
      }
    }, 15000);

    return () => {
      clearInterval(intervalId);
    };
  }, [dispatch, props.nextPage]);

  // ///////////////////////////////
  /* ========== RENDER ========== */
  // ///////////////////////////////

  const currentEmail = emailToUpdate || email;

  if (isUpdatingEmail)
    return (
      <VerifyEmailUpdate
        email={currentEmail}
        onComplete={handleCompleteEmailUpdate}
      />
    );

  return (
    <PageBase
      mobileHeader={introSection.header}
      mobileLead={[introSection.text || '']}
    >
      <HideOnMobile>
        {renderLabels({
          label: introSection.header,
          description: introSection.text,
        })}
      </HideOnMobile>
      <Email>
        <Text nowrap size={TextSizes.XLARGE}>
          {currentEmail}
        </Text>
        <Spacer space={spacers.tiny} />
        <Text
          nowrap
          pointer
          isLinkButton
          underline
          weight='700'
          color={TextColor.BLUE}
          onClick={handleClickUpdateEmail}
        >
          {buttons.link}
        </Text>
      </Email>
      Didn't receive it?&nbsp;
      <Text
        nowrap
        pointer
        isLinkButton
        underline
        inline
        weight='700'
        color={TextColor.BLUE}
        onClick={handleClickResend}
      >
        Resend link
      </Text>
    </PageBase>
  );
};

const HideOnMobile = styled.div`
  @media ${breakpoints.mobileLarge} {
    display: none;
  }
`;

const Email = styled.div`
  margin: 50px 0;
`;

export default EmailVerificationSent;
