import * as React from 'react';

/**
 * Hook to detect when a click or touch is
 * outside of an element.
 *
 * @param ref - React ref of the element.
 * @param onClickOutside - callback to trigger when a click
 *  or touch is outside of the element.
 * @param enableDetection - optional boolean to turn the document
 *  event listeners on/off. By default, detection starts on mount
 *  and stops on dismount. However, it's best practice to limit
 *  these listeners for performance. For example, use your dropdown menu
 *  show state to set this parameter. That way, the detection starts
 *  when the dropdown is opened and stops when the dropdown is closed.
 */
const useOutsideClickDetection = (
  ref: React.RefObject<HTMLElement>,
  onClickOutside: (event: MouseEvent) => void,
  enableDetection = true
): void => {
  const listener = React.useCallback(
    (event: MouseEvent) => {
      const target = event.target as Node | null;

      // Do nothing if clicking the ref element or descendent elements
      if (!ref?.current || ref.current.contains(target)) return;
      onClickOutside(event);
    },
    [ref, onClickOutside]
  );

  React.useEffect(() => {
    if (enableDetection) {
      document.addEventListener('mousedown', listener);
    }
    return () => {
      document.removeEventListener('mousedown', listener);
    };
  }, [enableDetection, listener]);

  React.useEffect(() => {
    return () => {
      document.removeEventListener('mousedown', listener);
    };
  }, []);
};

export default useOutsideClickDetection;
