enum Currency {
  USD = 'USD',
  AUD = 'AUD',
  BRL = 'BRL',
  BGN = 'BGN',
  CAD = 'CAD',
  CNY = 'CNY',
  HRK = 'HRK',
  CYP = 'CYP',
  CZK = 'CZK',
  DKK = 'DKK',
  EEK = 'EEK',
  EUR = 'EUR',
  HKD = 'HKD',
  HUF = 'HUF',
  ISK = 'ISK',
  IDR = 'IDR',
  JPY = 'JPY',
  KRW = 'KRW',
  LVL = 'LVL',
  LTL = 'LTL',
  MYR = 'MYR',
  MTL = 'MTL',
  NZD = 'NZD',
  NOK = 'NOK',
  PHP = 'PHP',
  PLN = 'PLN',
  RON = 'RON',
  RUB = 'RUB',
  SGD = 'SGD',
  SKK = 'SKK',
  SIT = 'SIT',
  ZAR = 'ZAR',
  SEK = 'SEK',
  CHF = 'CHF',
  THB = 'THB',
  TRY = 'TRY',
  GBP = 'GBP',
}

export default Currency;
