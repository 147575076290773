export default {
  // Async
  INITIALIZE_APP_REQUEST: 'INITIALIZE_APP_REQUEST',
  INITIALIZE_APP_SUCCESS: 'INITIALIZE_APP_SUCCESS',
  INITIALIZE_APP_FAILURE: 'INITIALIZE_APP_FAILURE',

  UPDATE_APP_DATA_REQUEST: 'UPDATE_APP_DATA_REQUEST',
  UPDATE_APP_DATA_SUCCESS: 'UPDATE_APP_DATA_SUCCESS',
  UPDATE_APP_DATA_FAILURE: 'UPDATE_APP_DATA_FAILURE',

  CREATE_PROFILE_AND_LOG_IN_REQUEST: 'CREATE_PROFILE_AND_LOG_IN_REQUEST',
  CREATE_PROFILE_AND_LOG_IN_SUCCESS: 'CREATE_PROFILE_AND_LOG_IN_SUCCESS',
  CREATE_PROFILE_AND_LOG_IN_FAILURE: 'CREATE_PROFILE_AND_LOG_IN_FAILURE',

  LOGIN_AND_REDIRECT_REQUEST: 'LOGIN_AND_REDIRECT_REQUEST',
  LOGIN_AND_REDIRECT_SUCCESS: 'LOGIN_AND_REDIRECT_SUCCESS',
  LOGIN_AND_REDIRECT_FAILURE: 'LOGIN_AND_REDIRECT_FAILURE',

  GET_PROFILE_REQUEST: 'GET_PROFILE_REQUEST',
  GET_PROFILE_SUCCESS: 'GET_PROFILE_SUCCESS',
  GET_PROFILE_FAILURE: 'GET_PROFILE_FAILURE',

  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',

  LOGOUT_REQUEST: 'LOGOUT_REQUEST',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_FAILURE: 'LOGOUT_FAILURE',

  GET_APP_DATA_REQUEST: 'GET_APP_DATA_REQUEST',
  GET_APP_DATA_SUCCESS: 'GET_APP_DATA_SUCCESS',
  GET_APP_DATA_FAILURE: 'GET_APP_DATA_FAILURE',

  GET_SIGNUP_INTRO_REQUEST: 'GET_SIGNUP_INTRO_REQUEST',
  GET_SIGNUP_INTRO_SUCCESS: 'GET_SIGNUP_INTRO_SUCCESS',
  GET_SIGNUP_INTRO_FAILURE: 'GET_SIGNUP_INTRO_FAILURE',

  GET_SIGNUP_LANGUAGE_REQUEST: 'GET_SIGNUP_LANGUAGE_REQUEST',
  GET_SIGNUP_LANGUAGE_SUCCESS: 'GET_SIGNUP_LANGUAGE_SUCCESS',
  GET_SIGNUP_LANGUAGE_FAILURE: 'GET_SIGNUP_LANGUAGE_FAILURE',

  UPDATE_SIGNUP_LANGUAGE_ORDER: 'UPDATE_SIGNUP_LANGUAGE_ORDER',

  GET_GENIUS_LANGUAGE_REQUEST: 'GET_GENIUS_LANGUAGE_REQUEST',
  GET_GENIUS_LANGUAGE_SUCCESS: 'GET_GENIUS_LANGUAGE_SUCCESS',
  GET_GENIUS_LANGUAGE_FAILURE: 'GET_GENIUS_LANGUAGE_FAILURE',

  GET_KYC_LANGUAGE_REQUEST: 'GET_KYC_LANGUAGE_REQUEST',
  GET_KYC_LANGUAGE_SUCCESS: 'GET_KYC_LANGUAGE_SUCCESS',
  GET_KYC_LANGUAGE_FAILURE: 'GET_KYC_LANGUAGE_FAILURE',

  CREATE_PROFILE_REQUEST: 'CREATE_PROFILE_REQUEST',
  CREATE_PROFILE_SUCCESS: 'CREATE_PROFILE_SUCCESS',
  CREATE_PROFILE_FAILURE: 'CREATE_PROFILE_FAILURE',

  UPDATE_PROFILE_REQUEST: 'UPDATE_PROFILE_REQUEST',
  UPDATE_PROFILE_SUCCESS: 'UPDATE_PROFILE_SUCCESS',
  UPDATE_PROFILE_FAILURE: 'UPDATE_PROFILE_FAILURE',

  DEACTIVATE_PROFILE_REQUEST: 'DEACTIVATE_PROFILE_REQUEST',
  DEACTIVATE_PROFILE_SUCCESS: 'DEACTIVATE_PROFILE_SUCCESS',
  DEACTIVATE_PROFILE_FAILURE: 'DEACTIVATE_PROFILE_FAILURE',

  TEXT_WELCOME_REQUEST: 'TEXT_WELCOME_REQUEST',
  TEXT_WELCOME_SUCCESS: 'TEXT_WELCOME_SUCCESS',
  TEXT_WELCOME_FAILURE: 'TEXT_WELCOME_FAILURE',

  CREATE_PROPERTY_REQUEST: 'CREATE_PROPERTY_REQUEST',
  CREATE_PROPERTY_SUCCESS: 'CREATE_PROPERTY_SUCCESS',
  CREATE_PROPERTY_FAILURE: 'CREATE_PROPERTY_FAILURE',

  UPDATE_PROPERTY_REQUEST: 'UPDATE_PROPERTY_REQUEST',
  UPDATE_PROPERTY_SUCCESS: 'UPDATE_PROPERTY_SUCCESS',
  UPDATE_PROPERTY_FAILURE: 'UPDATE_PROPERTY_FAILURE',

  ADD_INSTITUTION_LOGIN_REQUEST: 'ADD_INSTITUTION_LOGIN_REQUEST',
  ADD_INSTITUTION_LOGIN_SUCCESS: 'ADD_INSTITUTION_LOGIN_SUCCESS',
  ADD_INSTITUTION_LOGIN_FAILURE: 'ADD_INSTITUTION_LOGIN_FAILURE',

  CREATE_SAVINGS_ACCOUNT_REQUEST: 'CREATE_SAVINGS_ACCOUNT_REQUEST',
  CREATE_SAVINGS_ACCOUNT_SUCCESS: 'CREATE_SAVINGS_ACCOUNT_SUCCESS',
  CREATE_SAVINGS_ACCOUNT_FAILURE: 'CREATE_SAVINGS_ACCOUNT_FAILURE',

  UPDATE_SAVINGS_SETUP_REQUEST: 'UPDATE_SAVINGS_SETUP_REQUEST',
  UPDATE_SAVINGS_SETUP_SUCCESS: 'UPDATE_SAVINGS_SETUP_SUCCESS',
  UPDATE_SAVINGS_SETUP_FAILURE: 'UPDATE_SAVINGS_SETUP_FAILURE',

  CANCEL_SAVINGS_TRANSACTION_REQUEST: 'CANCEL_SAVINGS_TRANSACTION_REQUEST',
  CANCEL_SAVINGS_TRANSACTION_SUCCESS: 'CANCEL_SAVINGS_TRANSACTION_SUCCESS',
  CANCEL_SAVINGS_TRANSACTION_FAILURE: 'CANCEL_SAVINGS_TRANSACTION_FAILURE',

  UPDATE_GENIUS_PRICING_REQUEST: 'UPDATE_GENIUS_PRICING_REQUEST',
  UPDATE_GENIUS_PRICING_SUCCESS: 'UPDATE_GENIUS_PRICING_SUCCESS',
  UPDATE_GENIUS_PRICING_FAILURE: 'UPDATE_GENIUS_PRICING_FAILURE',

  GET_INSTITUTIONS_REQUEST: 'GET_INSTITUTIONS_REQUEST',
  GET_INSTITUTIONS_SUCCESS: 'GET_INSTITUTIONS_SUCCESS',
  GET_INSTITUTIONS_FAILURE: 'GET_INSTITUTIONS_FAILURE',

  GET_BANKING_STATEMENTS_REQUEST: 'GET_BANKING_STATEMENTS_REQUEST',
  GET_BANKING_STATEMENTS_SUCCESS: 'GET_BANKING_STATEMENTS_SUCCESS',
  GET_BANKING_STATEMENTS_FAILURE: 'GET_BANKING_STATEMENTS_FAILURE',

  GET_COASTAL_SAVINGS_STATEMENTS_REQUEST:
    'GET_COASTAL_SAVINGS_STATEMENTS_REQUEST',
  GET_COASTAL_SAVINGS_STATEMENTS_SUCCESS:
    'GET_COASTAL_SAVINGS_STATEMENTS_SUCCESS',
  GET_COASTAL_SAVINGS_STATEMENTS_FAILURE:
    'GET_COASTAL_SAVINGS_STATEMENTS_FAILURE',

  GET_INVESTING_DOCUMENTS_REQUEST: 'GET_INVESTING_DOCUMENTS_REQUEST',
  GET_INVESTING_DOCUMENTS_SUCCESS: 'GET_INVESTING_DOCUMENTS_SUCCESS',
  GET_INVESTING_DOCUMENTS_FAILURE: 'GET_INVESTING_DOCUMENTS_FAILURE',

  CANCEL_TRADE_REQUEST: 'CANCEL_TRADE_REQUEST',
  CANCEL_TRADE_SUCCESS: 'CANCEL_TRADE_SUCCESS',
  CANCEL_TRADE_FAILURE: 'CANCEL_TRADE_FAILURE',

  CANCEL_CASH_INVESTING_TRANSACTION_REQUEST:
    'CANCEL_CASH_INVESTING_TRANSACTION_REQUEST',
  CANCEL_CASH_INVESTING_TRANSACTION_SUCCESS:
    'CANCEL_CASH_INVESTING_TRANSACTION_SUCCESS',
  CANCEL_CASH_INVESTING_TRANSACTION_FAILURE:
    'CANCEL_CASH_INVESTING_TRANSACTION_FAILURE',

  UPDATE_INVESTING_PREFERENCES_REQUEST: 'UPDATE_INVESTING_PREFERENCES_REQUEST',
  UPDATE_INVESTING_PREFERENCES_SUCCESS: 'UPDATE_INVESTING_PREFERENCES_SUCCESS',
  UPDATE_INVESTING_PREFERENCES_FAILURE: 'UPDATE_INVESTING_PREFERENCES_FAILURE',

  GET_WF_SAVINGS_STATEMENTS_REQUEST: 'GET_WF_SAVINGS_STATEMENTS_REQUEST',
  GET_WF_SAVINGS_STATEMENTS_SUCCESS: 'GET_WF_SAVINGS_STATEMENTS_SUCCESS',
  GET_WF_SAVINGS_STATEMENTS_FAILURE: 'GET_WF_SAVINGS_STATEMENTS_FAILURE',

  GET_TRANSFER_HISTORY_REQUEST: 'GET_TRANSFER_HISTORY_REQUEST',
  GET_TRANSFER_HISTORY_SUCCESS: 'GET_TRANSFER_HISTORY_SUCCESS',
  GET_TRANSFER_HISTORY_FAILURE: 'GET_TRANSFER_HISTORY_FAILURE',

  ENABLE_GENIUS_REQUEST: 'ENABLE_GENIUS_REQUEST',
  ENABLE_GENIUS_SUCCESS: 'ENABLE_GENIUS_SUCCESS',
  ENABLE_GENIUS_FAILURE: 'ENABLE_GENIUS_FAILURE',

  GET_GENIUS_PAYMENTS_REQUEST: 'GET_GENIUS_PAYMENTS_REQUEST',
  GET_GENIUS_PAYMENTS_SUCCESS: 'GET_GENIUS_PAYMENTS_SUCCESS',
  GET_GENIUS_PAYMENTS_FAILURE: 'GET_GENIUS_PAYMENTS_FAILURE',

  UPDATE_EMAIL_REQUEST: 'UPDATE_EMAIL_REQUEST',
  UPDATE_EMAIL_SUCCESS: 'UPDATE_EMAIL_SUCCESS',
  UPDATE_EMAIL_FAILURE: 'UPDATE_EMAIL_FAILURE',

  UPDATE_PHONE_REQUEST: 'UPDATE_PHONE_REQUEST',
  UPDATE_PHONE_SUCCESS: 'UPDATE_PHONE_SUCCESS',
  UPDATE_PHONE_FAILURE: 'UPDATE_PHONE_FAILURE',

  CREATE_JUMPSTART_SAVINGS_TRANSACTION_REQUEST:
    'CREATE_JUMPSTART_SAVINGS_TRANSACTION_REQUEST',
  CREATE_JUMPSTART_SAVINGS_TRANSACTION_SUCCESS:
    'CREATE_JUMPSTART_SAVINGS_TRANSACTION_SUCCESS',
  CREATE_JUMPSTART_SAVINGS_TRANSACTION_FAILURE:
    'CREATE_JUMPSTART_SAVINGS_TRANSACTION_FAILURE',

  CREATE_ACH_TRANSACTION_REQUEST: 'CREATE_ACH_TRANSACTION_REQUEST',
  CREATE_ACH_TRANSACTION_SUCCESS: 'CREATE_ACH_TRANSACTION_SUCCESS',
  CREATE_ACH_TRANSACTION_FAILURE: 'CREATE_ACH_TRANSACTION_FAILURE',

  CANCEL_ACH_TRANSACTION_REQUEST: 'CANCEL_ACH_TRANSACTION_REQUEST',
  CANCEL_ACH_TRANSACTION_SUCCESS: 'CANCEL_ACH_TRANSACTION_SUCCESS',
  CANCEL_ACH_TRANSACTION_FAILURE: 'CANCEL_ACH_TRANSACTION_FAILURE',

  TRACK_ANALYTICS_REQUEST: 'TRACK_ANALYTICS_REQUEST',
  TRACK_ANALYTICS_SUCCESS: 'TRACK_ANALYTICS_SUCCESS',
  TRACK_ANALYTICS_FAILURE: 'TRACK_ANALYTICS_FAILURE',

  KYC_IDENTITY_CHECK_REQUEST: 'KYC_IDENTITY_CHECK_REQUEST',
  KYC_IDENTITY_CHECK_SUCCESS: 'KYC_IDENTITY_CHECK_SUCCESS',
  KYC_IDENTITY_CHECK_FAILURE: 'KYC_IDENTITY_CHECK_FAILURE',

  FINISH_SIGNUP_FLOW_REQUEST: 'FINISH_SIGNUP_FLOW_REQUEST',
  FINISH_SIGNUP_FLOW_SUCCESS: 'FINISH_SIGNUP_FLOW_SUCCESS',
  FINISH_SIGNUP_FLOW_FAILURE: 'FINISH_SIGNUP_FLOW_FAILURE',

  REDIRECT_SPLASH_HOME: 'REDIRECT_SPLASH_HOME',
  REDIRECT_SIGNUP: 'REDIRECT_SIGNUP',

  // Move Money transfers.
  CREATE_TRANSFER_TRANSACTION_REQUEST: 'CREATE_TRANSFER_TRANSACTION_REQUEST',
  CREATE_TRANSFER_TRANSACTION_SUCCESS: 'CREATE_TRANSFER_TRANSACTION_SUCCESS',
  CREATE_TRANSFER_TRANSACTION_FAILURE: 'CREATE_TRANSFER_TRANSACTION_FAILURE',

  GET_ACCOUNT_REQUEST: 'GET_ACCOUNT_REQUEST',
  GET_ACCOUNT_SUCCESS: 'GET_ACCOUNT_SUCCESS',
  GET_ACCOUNT_FAILURE: 'GET_ACCOUNT_FAILURE',

  VERIFY_EXTERNAL_ACCOUNT_REQUEST: 'VERIFY_EXTERNAL_ACCOUNT_REQUEST',
  VERIFY_EXTERNAL_ACCOUNT_SUCCESS: 'VERIFY_EXTERNAL_ACCOUNT_SUCCESS',
  VERIFY_EXTERNAL_ACCOUNT_FAILURE: 'VERIFY_EXTERNAL_ACCOUNT_FAILURE',

  // 2FA
  TEXT_CONFIRM_CODE_REQUEST: 'TEXT_CONFIRM_CODE_REQUEST',
  TEXT_CONFIRM_CODE_SUCCESS: 'TEXT_CONFIRM_CODE_SUCCESS',
  TEXT_CONFIRM_CODE_FAILURE: 'TEXT_CONFIRM_CODE_FAILURE',

  EMAIL_CONFIRM_CODE_REQUEST: 'EMAIL_CONFIRM_CODE_REQUEST',
  EMAIL_CONFIRM_CODE_SUCCESS: 'EMAIL_CONFIRM_CODE_SUCCESS',
  EMAIL_CONFIRM_CODE_FAILURE: 'EMAIL_CONFIRM_CODE_FAILURE',

  SUBMIT_CONFIRM_CODE_REQUEST: 'SUBMIT_CONFIRM_CODE_REQUEST',
  SUBMIT_CONFIRM_CODE_SUCCESS: 'SUBMIT_CONFIRM_CODE_SUCCESS',
  SUBMIT_CONFIRM_CODE_FAILURE: 'SUBMIT_CONFIRM_CODE_FAILURE',

  SEND_EMAIL_VERIFICATION_LINK_REQUEST: 'SEND_EMAIL_VERIFICATION_LINK_REQUEST',
  SEND_EMAIL_VERIFICATION_LINK_SUCCESS: 'SEND_EMAIL_VERIFICATION_LINK_SUCCESS',
  SEND_EMAIL_VERIFICATION_LINK_FAILURE: 'SEND_EMAIL_VERIFICATION_LINK_FAILURE',

  GET_EMAIL_VERIFICATION_STATUS_REQUEST:
    'GET_EMAIL_VERIFICATION_STATUS_REQUEST',
  GET_EMAIL_VERIFICATION_STATUS_SUCCESS:
    'GET_EMAIL_VERIFICATION_STATUS_SUCCESS',
  GET_EMAIL_VERIFICATION_STATUS_FAILURE:
    'GET_EMAIL_VERIFICATION_STATUS_FAILURE',

  // Banking
  GET_BANKING_DATA_REQUEST: 'GET_BANKING_DATA_REQUEST',
  GET_BANKING_DATA_SUCCESS: 'GET_BANKING_DATA_SUCCESS',
  GET_BANKING_DATA_FAILURE: 'GET_BANKING_DATA_FAILURE',

  GET_BANKING_DOCUMENTS_REQUEST: 'GET_BANKING_DOCUMENTS_REQUEST',
  GET_BANKING_DOCUMENTS_SUCCESS: 'GET_BANKING_DOCUMENTS_SUCCESS',
  GET_BANKING_DOCUMENTS_FAILURE: 'GET_BANKING_DOCUMENTS_FAILURE',

  CREATE_BANKING_ACCOUNT_REQUEST: 'CREATE_BANKING_ACCOUNT_REQUEST',
  CREATE_BANKING_ACCOUNT_SUCCESS: 'CREATE_BANKING_ACCOUNT_SUCCESS',
  CREATE_BANKING_ACCOUNT_FAILURE: 'CREATE_BANKING_ACCOUNT_FAILURE',

  UPDATE_BANKING_ACCOUNT_REQUEST: 'UPDATE_BANKING_ACCOUNT_REQUEST',
  UPDATE_BANKING_ACCOUNT_SUCCESS: 'UPDATE_BANKING_ACCOUNT_SUCCESS',
  UPDATE_BANKING_ACCOUNT_FAILURE: 'UPDATE_BANKING_ACCOUNT_FAILURE',

  SUBMIT_BANKING_APPLICATION_REQUEST: 'SUBMIT_BANKING_APPLICATION_REQUEST',
  SUBMIT_BANKING_APPLICATION_SUCCESS: 'SUBMIT_BANKING_APPLICATION_SUCCESS',
  SUBMIT_BANKING_APPLICATION_FAILURE: 'SUBMIT_BANKING_APPLICATION_FAILURE',

  COMPLETE_BANKING_APPLICATION_REQUEST: 'COMPLETE_BANKING_APPLICATION_REQUEST',
  COMPLETE_BANKING_APPLICATION_SUCCESS: 'COMPLETE_BANKING_APPLICATION_SUCCESS',
  COMPLETE_BANKING_APPLICATION_FAILURE: 'COMPLETE_BANKING_APPLICATION_FAILURE',

  GET_BANK_ACCOUNT_NUM_REQUEST: 'GET_BANK_ACCOUNT_NUM_REQUEST',
  GET_BANK_ACCOUNT_NUM_SUCCESS: 'GET_BANK_ACCOUNT_NUM_SUCCESS',
  GET_BANK_ACCOUNT_NUM_FAILURE: 'GET_BANK_ACCOUNT_NUM_FAILURE',

  // KYC
  SUBMIT_KYC_MFA_REQUEST: 'SUBMIT_KYC_MFA_REQUEST',
  SUBMIT_KYC_MFA_SUCCESS: 'SUBMIT_KYC_MFA_SUCCESS',
  SUBMIT_KYC_MFA_FAILURE: 'SUBMIT_KYC_MFA_FAILURE',

  CLEAR_KYC_MFA_QUESTIONS: 'CLEAR_KYC_MFA_QUESTIONS',

  // Actions
  ADD_BANNER: 'ADD_BANNER',
  REMOVE_BANNER: 'REMOVE_BANNER',
  HIDE_BANNER: 'HIDE_BANNER',

  SET_THEME: 'SET_THEME',
  SET_SELECTED_GENIUS_PRICE: 'SET_SELECTED_GENIUS_PRICE',
  SET_SELECTED_ANNUAL_BONUS: 'SET_SELECTED_ANNUAL_BONUS',
  SET_LOGGED_IN: 'SET_LOGGED_IN',
  REDIRECT_PAGE: 'REDIRECT_PAGE',

  UPDATE_CURRENT_KYC_PAGE: 'UPDATE_CURRENT_KYC_PAGE',
  UPDATE_CURRENT_GENIUS_PAGE: 'UPDATE_CURRENT_GENIUS_PAGE',
  UPDATE_CURRENT_SIGNUP_PAGE: 'UPDATE_CURRENT_SIGNUP_PAGE',
  UPDATE_SELECTED_INS_ID: 'UPDATE_SELECTED_INS_ID',
  SET_SELECTED_GENIUS_FREQUENCY: 'SET_SELECTED_GENIUS_FREQUENCY',

  SOCURE_SESSION_ID_LOADED: 'SOCURE_SESSION_ID_LOADED',

  SET_ADD_ACCOUNT_CLICKED: 'SET_ADD_ACCOUNT_CLICKED',

  TWOFA_INITIATE: 'TWOFA_INITIATE',
  TWOFA_COMPLETE: 'TWOFA_COMPLETE',
};
