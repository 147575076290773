import types from 'actions/types';
import AlbertClient from 'api/AlbertClient';
import * as WEB from 'types/interfaces';
import { onError } from 'utils/axiosMiddlewareUtils';

export default (finishedSignup = false): WEB.ActionCreator<Promise<boolean>> =>
  async (dispatch: WEB.Dispatch): Promise<boolean> => {
    let error = false;

    await dispatch({
      types: [
        types.TEXT_WELCOME_REQUEST,
        types.TEXT_WELCOME_SUCCESS,
        types.TEXT_WELCOME_FAILURE,
      ],
      payload: {
        request: {
          method: 'post',
          url: AlbertClient.textDownloadApp(),
          data: {
            finished_web_signup: finishedSignup,
          },
        },
        options: {
          onError: (middlewareOptions: any, options: any) => {
            error = true;
            return onError(middlewareOptions, options);
          },
        },
      },
    });

    return error;
  };
