/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import ErrorText from 'components/common/ErrorText';
import { BaseInputProps } from 'types/interfaces';
import { formatPhone } from 'utils/inputFormatters';
import { BaseInput, InputWrapper, renderLabels } from './shared';

export enum InputTypes {
  TEXT = 'text',
  DATE = 'date',
  PHONE = 'tel',
  PASSWORD = 'password',
  EMAIL = 'email',
}

export enum AutoCapitalize {
  OFF = 'off',
  ON = 'on',
  WORDS = 'words',
  CHARACTERS = 'characters',
}

export type Props = BaseInputProps & {
  /* the textfield type */
  type?: InputTypes | InputTypes.TEXT | string;
  /* mininum string size */
  minlen?: string;
  /* maximum string size */
  maxlen?: string;
  /* min date allowed */
  min?: string;
  /* max date allowed */
  max?: string;
  /* formatter function that outputs formatted value */
  onFormat?: (...args: any) => any;
};

const TextInput = (props: Props): React.ReactElement => {
  const {
    id,
    invalid,
    errorText,
    type,
    widthSize,
    disabled,
    onChange,
    onFormat,
  } = props;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    let newValue = value;
    // Format text input field based on type
    if (type === InputTypes.PHONE) {
      newValue = formatPhone(value);
    } else {
      newValue = onFormat ? onFormat(value) : value;
    }
    e.target.value = newValue;
    onChange && onChange(e);
  };

  return (
    <div>
      {renderLabels(props)}
      <InputWrapper
        disabled={disabled}
        widthSize={widthSize}
        className='input-wrapper'
      >
        <BaseInput {...props} onChange={handleChange} />
        {invalid && <ErrorText name={id} text={errorText} />}
      </InputWrapper>
    </div>
  );
};

export default TextInput;
