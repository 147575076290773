/* eslint-disable @typescript-eslint/await-thenable */
import * as React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import updateProfile from 'actions/async/updateProfile';
import removeBanner from 'actions/banner/removeBanner';
import Button from 'components/common/Button';
import RectangleCard from 'components/common/RectangleCard';
import Spacer from 'components/common/Spacer';
import Text, { TextColor, TextSizes } from 'components/common/Text';
import ButtonsContainer from 'components/layout/ButtonsContainer';
import PageBase from 'components/layout/PageBase';
import ComponentSize from 'constants/ComponentSize';
import Pages from 'constants/Pages';
import * as language from 'reducers/entities/language';
import * as profile from 'reducers/entities/profile';
import { breakpoints } from 'styles';
import { spacers as homeSpacers } from 'styles/home';
import * as WEB from 'types/interfaces';
import { delayWithState } from 'utils/delay';
import { getLanguageButtons, getLanguageSection } from 'utils/getFromLanguage';
import { hash32 } from 'utils/hash';

type Props = {
  onComplete: () => void;
};

const DesktopHeader = styled.h1`
  @media ${breakpoints.mobileLarge} {
    display: none;
  }
`;

const InvestingInfo = ({ onComplete }: Props): React.ReactElement => {
  // ///////////////////////////////
  /* =========== HOOKS ========== */
  // ///////////////////////////////
  const dispatch = useDispatch();

  // ///////////////////////////////
  /* ======= MAPPED STATE ======= */
  // ///////////////////////////////
  // Default investing goals from profile investment_info.
  const investmentGoalsDefault = useSelector(
    (state: WEB.RootState) =>
      profile.getInfoValueByField(
        state,
        'investment_info',
        'portfolio_goals'
      ) || [],
    shallowEqual
  );

  // ///////////////////////////////
  /* =========== STATE ========== */
  // ///////////////////////////////
  const [investmentGoals, setInvestmentGoals] = React.useState<string[]>([]);
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [isCompleted, setIsCompleted] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (investmentGoalsDefault.length) {
      setInvestmentGoals(investmentGoalsDefault);
    }
  }, [investmentGoalsDefault]);

  // //////////////////////////////////
  /* =========== LANGUAGE ========== */
  // //////////////////////////////////
  const { INVESTING_INFO } = Pages;
  const investingInfoCtx = useSelector((state: WEB.RootState) =>
    language.getSignupPageLanguage(state, INVESTING_INFO)
  );
  const INTRO_KEY = 'intro';
  const INVESTING_GOALS_KEY = 'investingGoals';

  // Sections
  const introSection = getLanguageSection(investingInfoCtx, INTRO_KEY);

  const investingGoalsSection = getLanguageSection(
    investingInfoCtx,
    INVESTING_GOALS_KEY
  );

  const buttonsCtx = getLanguageButtons(investingInfoCtx);

  // //////////////////////////////////
  /* =========== HANDLERS ========== */
  // //////////////////////////////////
  const handleGoalClick = (value: string): void => {
    const cardIndex = investmentGoals.indexOf(value);

    // If card not in selected goals, add it
    if (cardIndex === -1) {
      setInvestmentGoals([...investmentGoals, value]);
    } else {
      // find its index and remove it
      const activeCardsCopy = [...investmentGoals];
      activeCardsCopy.splice(cardIndex, 1);
      setInvestmentGoals(activeCardsCopy);
    }
  };

  const handleInvestingInfoContinueClick = async (): Promise<void> => {
    // Remove any error banner from previous attempts
    dispatch(removeBanner());
    setIsSubmitting(true);

    const { error }: any = await dispatch(
      updateProfile({
        investment_info: {
          signup_investing_interest: true,
          portfolio_goals: investmentGoals,
        },
      })
    );

    setIsSubmitting(false);
    if (!error) {
      await delayWithState(400, setIsCompleted);
      onComplete();
    }
  };

  const investingGoalsOptions = (investingGoalsSection?.options || []) as {
    image: string;
    header: string;
    text: string;
  }[];
  const investingGoals = investingGoalsOptions.map((option, i) => {
    return (
      <RectangleCard
        key={`investment-goal-${hash32(option?.text)}`}
        size={ComponentSize.XSMALL}
        image={option?.image}
        header={option?.header}
        value={option?.header}
        body={option?.text}
        choice='multiple'
        selected={investmentGoals.includes(option?.header)}
        onClick={handleGoalClick}
        noImageSizing
        isFirst={i === 0}
      />
    );
  });

  return (
    <PageBase mobileHeader={introSection?.header || ''}>
      <DesktopHeader>{introSection?.header}</DesktopHeader>
      <Text key='commission-free-text' className='description'>
        {introSection?.text}
      </Text>
      <Spacer space={homeSpacers.g8} />
      {investingGoals}
      <Spacer space={homeSpacers.g5} />
      {investingGoalsSection?.disclaimer && (
        <Text size={TextSizes.TINY} color={TextColor.GRAY}>
          {investingGoalsSection.disclaimer}
        </Text>
      )}
      <ButtonsContainer>
        <Button
          id='investing-info-confirm-button'
          onClick={handleInvestingInfoContinueClick}
          isCompleted={isCompleted}
          isLoading={isSubmitting}
          disabled={!investmentGoals.length || isSubmitting}
        >
          {isCompleted ? buttonsCtx?.complete : buttonsCtx?.primary}
        </Button>
      </ButtonsContainer>
    </PageBase>
  );
};

export default InvestingInfo;
