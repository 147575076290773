import * as React from 'react';

const Checkmark = () => (
  <svg
    width='13'
    height='14'
    viewBox='0 0 15 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M12.51 1.516L6.984 10.318C6.732 10.696 6.354 10.714 6.084 10.336L2.898 5.926C2.664 5.602 2.268 5.386 1.836 5.386C1.116 5.386 0.54 5.962 0.54 6.682C0.54 6.97 0.63 7.24 0.774 7.456L3.96 11.866C5.31 13.738 7.92 13.666 9.18 11.722L14.724 2.902C14.85 2.704 14.922 2.47 14.922 2.218C14.922 1.498 14.328 0.903999 13.608 0.903999C13.158 0.903999 12.744 1.156 12.51 1.516Z'
      fill='#333333'
    />
  </svg>
);

export default Checkmark;
