import { ChevronLeft } from '@styled-icons/heroicons-outline/ChevronLeft';
import styled from 'styled-components';
import { mixins } from 'styles';

const StyledChevronLeft = styled(ChevronLeft)`
  color: ${({ theme }) => theme.colors.primaryAlbertBrand};
  width: ${mixins.pxToRem('35px')};
  display: inline-block;
`;

export default StyledChevronLeft;
