import React from 'react';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
import { Drawer } from 'vaul';
import Spacer from '../Spacer';
import BasicModal, {
  Props as BasicModalProps,
  StyledButton,
  StyledButtonWrapper,
  StyledH2,
  StyledTitleWrapper,
} from './BasicModal';
import { ModalContent } from './shared/BaseModal';

const StyledOverlay = styled(Drawer.Overlay)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1;
`;

const StyledDrawerContent = styled(Drawer.Content)`
  padding-top: 8px;
  padding-bottom: 1.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  background-color: white;
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  outline: none;
  z-index: 2;
`;

const TopBar = styled.div`
  margin: 0 auto;
  width: 5rem;
  height: 0.25rem;
  flex-shrink: 0;
  border-radius: 9999px;
  background-color: #e5e5e5;
  margin-bottom: 16px;
`;

export default function DrawerModal(props: BasicModalProps) {
  // Fallback to basic modal if not mobile
  if (!isMobile) {
    return <BasicModal {...props} />;
  }

  const {
    title,
    theme,
    submitLabel,
    onSubmit,
    onCancel,
    isSubmitting,
    children,
    isCompleted,
    customTitlePadding,
    disabled,
    submitCompletedLabel,
  } = props;

  return (
    <Drawer.Root open={props.show} onClose={onCancel}>
      <Drawer.Portal>
        <StyledOverlay />
        <StyledDrawerContent>
          <TopBar />
          <StyledTitleWrapper customTitlePadding={customTitlePadding}>
            <StyledH2>{title}</StyledH2>
          </StyledTitleWrapper>
          {children && <ModalContent>{children}</ModalContent>}
          <Spacer space='16px' />
          <StyledButtonWrapper>
            {submitLabel && onSubmit && (
              <StyledButton
                id='modal-submit-button'
                stretch
                theme={theme}
                onClick={onSubmit}
                isLoading={isSubmitting}
                isCompleted={isCompleted}
                disabled={disabled}
              >
                {isCompleted ? submitCompletedLabel : submitLabel}
              </StyledButton>
            )}
          </StyledButtonWrapper>
        </StyledDrawerContent>
      </Drawer.Portal>
    </Drawer.Root>
  );
}
