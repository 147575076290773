import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import initializeApp from 'actions/initializeApp';
import * as app from 'reducers/ui/app';

export type Props = {
  children: React.ReactElement;
};

/**
 * Wrapper component for React Router v6 element
 * "Protected" is synonymous with "authenticated" - meaning these are routes can only be accessed by
 * logged-in users. For these "protected" routes, we initialize app (if not initialized already)
 * on route mount and only render the protected route's component if and when initialize app is successful.
 * Initializing app successfully means the user is authenticated. If call to initialize app fails,
 * user is redirected to the login page and we set state "loggedIn" as "false" in our store.
 */
const ProtectedRoute = ({ children }: Props): React.ReactElement | null => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const initializedApp = useSelector(app.initializedApp);

  // Only initialize app if not yet already
  React.useEffect(() => {
    !initializedApp && dispatch(initializeApp(navigate));
  }, []);

  // Return "null" if app has not been initialized; otherwise, return protected route's component
  return initializedApp ? children : null;
};

export default ProtectedRoute;
