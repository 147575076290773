/* eslint-disable react/destructuring-assignment */
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Navigate,
  NavigationType,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useNavigationType,
} from 'react-router-dom';
import setAddAccountsClicked from 'actions/setAddAccountsClicked';
import Modal from 'components/common/Modal';
import NotificationTypes from 'constants/NotificationTypes';
import { PageRoutes, Pages } from 'constants/index';
import useAccountsToDisplay from 'hooks/useAccountsToDisplay';
import useNavigateFunction from 'hooks/useNavigateFunction';
import ManualAuthFlow from 'pages/manual_auth/ManualAuthFlow';
import * as SignupPages from 'pages/signup';
import * as appData from 'reducers/entities/appData';
import * as language from 'reducers/entities/language';
import * as banking from 'reducers/ui/banking';
import * as WEB from 'types/interfaces';
import { getIsCheckpointDisabled } from 'utils/checkpointNavUtils';
import { getLanguageModal } from 'utils/getFromLanguage';

type LocationState = {
  /* Page to navigate to when the link account flow is completed.
      When navigating here, the location state will be { showConfirmation: true }
      so that the destination page can optionally display some confirmation UI. */
  pageOnComplete: Pages;
  /* Page to navigate to when the link account flow is skipped
      (i.e. the next page in the signup flow) */
  pageOnSkip: Pages;
  /* Current page the link account flow was opened on. */
  currentPage: Pages;
};

/* The location state the Link Account flow will navigate to "pageOnComplete" with
when the flow is completed */
export type LinkAccountCompleteLocationState = {
  showConfirmation: boolean;
};

export type LinkAccountSkipLocationState = {
  skippedLinkAccount: boolean;
};

const LinkAccountPageList = [Pages.FIND_INSTITUTION, Pages.SELECT_ACCOUNT];

const getPageUrl = (subRoute: string): string => {
  return `${PageRoutes.LINK_ACCOUNT}/${subRoute}`;
};

const completeFlowLocationState: LinkAccountCompleteLocationState = {
  showConfirmation: true,
};

const skipFlowLocationState: LinkAccountSkipLocationState = {
  skippedLinkAccount: true,
};

/**
 * A page can go to this flow by navigating to the route PageRoutes.LINK_ACCOUNT with
 * the location state typed above. This component will determine if the flow is necessary.
 *
 * 1. If the user already has a primary checking account linked with Albert, the flow skips
 *    itself by navigating to the pageOnComplete defined in the incoming location state and
 *    passes the location state typed in LinkAccountCompleteLocationState.
 *
 * 2. Otherwise, the user navigated to the first page in this flow. If the user clicks skip
 *    at any point, this component will navigate the user to the pageOnSkip defined in the
 *    incoming location state. Otherwise, once the user completes the flow, this component
 *    navigates to the pageOnComplete defined in the incoming location state and
 *    passes the location state typed in LinkAccountCompleteLocationState.
 *
 * Note that we do not allow the user to click the browser back button to re-enter this
 * flow (by using replace). That way they don't enter this flow without the
 * appropriate incoming location state.
 */
const LinkAccountFlow = (): React.ReactElement | null => {
  // //////////////////////////////
  /* ========== Hooks ========== */
  // //////////////////////////////
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { updateCurrentSignupPage } = useNavigateFunction();
  const navType: NavigationType = useNavigationType();

  const locationState = location.state as LocationState | undefined;
  const fromSignup = location.pathname.includes(PageRoutes.SIGNUP_BASE);

  // //////////////////////////////
  /* ========== State ========== */
  // //////////////////////////////
  const [showWarningModal, setShowWarningModal] =
    React.useState<boolean>(false);
  const [pageOnComplete, setPageOnComplete] = React.useState<Pages | string>(
    locationState?.pageOnComplete || ''
  );
  const [pageOnSkip, setPageOnSkip] = React.useState<Pages | string>(
    locationState?.pageOnComplete || ''
  );
  const [currentPage, setCurrentPage] = React.useState<Pages | string>(
    locationState?.currentPage || ''
  );
  const [isRedirectingBack, setIsRedirectingBack] = React.useState(false);
  const [message, setMessage] = React.useState<null | string>(null);

  React.useEffect(() => {
    if (locationState?.pageOnComplete) {
      setPageOnComplete(locationState?.pageOnComplete);
    }
    if (locationState?.pageOnSkip) {
      setPageOnSkip(locationState?.pageOnSkip);
    }
    if (locationState?.currentPage) {
      setCurrentPage(locationState.currentPage);
    }
  }, [locationState]);

  /* If the incoming location state is incomplete and
      we're not already redirecting back in history,
      redirect back to disallow the flow.
      (e.g. user uses browser forward button to navigate
      into this flow)

      Note: we check if we're already redirecting because
      multiple renders can cause this sanity check to
      run multiple times and trigger going back in history
      more than once.
  */
  const invalidLocationState = !pageOnComplete || !pageOnSkip;
  React.useEffect(() => {
    if (invalidLocationState && !isRedirectingBack) {
      setIsRedirectingBack(true);
      if (navType === 'POP') {
        navigate(-2);
      } else {
        navigate(-1);
      }
    }
  }, [invalidLocationState, isRedirectingBack, navigate]);
  // //////////////////////////////
  /* ======= Mapped state ====== */
  // //////////////////////////////
  const signupLanguage = useSelector(language.getSignupLanguage);
  // TODO: Remove all page skip logic
  const pageOnSkipRoute = useSelector((state: WEB.RootState) =>
    language.getSignupRouteByPage(state, pageOnSkip as Pages, {
      disableLogging: true,
    })
  );

  const addAccountsClicked = useSelector(banking.addAccountsClicked);

  const { accountsToDisplay } = useAccountsToDisplay();

  const primaryCheckingAccounts = useSelector(
    appData.getPrimaryCheckingAccounts
  );

  const languageCtx = useSelector((state: WEB.RootState) =>
    language.getSignupPageLanguage(state, currentPage)
  );

  // //////////////////////////////
  /* ======== Handlers ========= */
  // //////////////////////////////
  const onCompleteFlow = (): void => {
    dispatch(
      updateCurrentSignupPage({
        page: pageOnComplete,
        locationState: completeFlowLocationState,
        shouldReplace: true,
      })
    );
  };

  const onSkipFlow = (): void => {
    // Show warning modal.
    setShowWarningModal(true);
  };

  const onCompletePage = (subRoute: string) => (): void => {
    const url = getPageUrl(subRoute);
    navigate(url, { replace: true });
  };

  const restartFlow = (shouldReplace = false, message = null): void => {
    dispatch(setAddAccountsClicked(true));
    navigate(PageRoutes.LINK_ACCOUNT, {
      replace: shouldReplace,
      state: { pageOnComplete, pageOnSkip },
    });
    setMessage(message);
  };

  // Modal.
  const handleOnSkipLinking = (): void => {
    dispatch(
      updateCurrentSignupPage({
        page: pageOnSkip,
        shouldReplace: true,
        locationState: skipFlowLocationState,
      })
    );
  };

  const handleCloseModal = (): void => {
    setShowWarningModal(false);
  };

  // Modal config.
  const modalContext = getLanguageModal(
    languageCtx || ({} as WEB.PageLanguage)
  );

  const modalConfig = {
    title: modalContext?.header || 'Are you sure?',
    description: modalContext?.text || '',
    cancelLabel: modalContext?.secondary || 'Skip linking',
    submitLabel: modalContext?.primary || 'Go back',
    iconType: NotificationTypes.WARNING,
    show: showWarningModal,
    onCancel: handleOnSkipLinking,
    onSubmit: handleCloseModal,
    onClickX: handleCloseModal,
  };

  // //////////////////////////////
  /* ========== Render ========= */
  // //////////////////////////////

  if (invalidLocationState && !isRedirectingBack) {
    return null;
  }

  /* Build the flow routes */
  const routes = LinkAccountPageList.map((page: Pages, index: number) => {
    // Page component to render
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const PageComponent = SignupPages[page];
    const pageSubRoute = signupLanguage[page]?.route;

    let onSubmit = onCompleteFlow;
    const isLastPage = index === LinkAccountPageList.length - 1;
    if (!isLastPage) {
      // If the page isn't the last in the Link Account Flow,
      // set its submit callback to navigate to the next page in this flow.
      const nextPage = LinkAccountPageList[index + 1];
      const nextPageSubRoute = signupLanguage[nextPage]?.route;
      onSubmit = onCompletePage(nextPageSubRoute);
    }

    return (
      <Route
        path={pageSubRoute}
        key={`route-${pageSubRoute}`}
        element={
          <PageComponent
            onSubmit={onSubmit}
            onSkip={onSkipFlow}
            restartFlow={restartFlow}
            fromSignup={fromSignup}
            message={message}
          />
        }
      />
    );
  });

  const manualAuthRoute = (
    <Route
      key='manual-auth-flow'
      path={PageRoutes.SIGNUP_MANUAL_AUTH}
      element={
        <ManualAuthFlow
          onSubmit={onCompleteFlow}
          onSkip={handleOnSkipLinking}
          nextPage={pageOnComplete}
        />
      }
    />
  );

  // If the user has already linked a bank account, skip the link account subflow by
  // navigating them to the next page in the signup flow.
  // Otherwise, go to the first page in the link account subflow.
  const firstPageUrl = signupLanguage[LinkAccountPageList[0]].route;
  const firstPageRoute = getPageUrl(firstPageUrl);
  const lastPageUrl =
    signupLanguage[LinkAccountPageList[LinkAccountPageList.length - 1]].route;
  const lastPageRoute = getPageUrl(lastPageUrl);
  let redirectUrl =
    primaryCheckingAccounts.length && !getIsCheckpointDisabled()
      ? pageOnSkipRoute
      : firstPageRoute;
  if (addAccountsClicked) {
    redirectUrl = firstPageRoute;
  } else if (
    primaryCheckingAccounts.length === 0 &&
    accountsToDisplay.length > 0
  ) {
    // if no primaryCheckingAccount has been selected, but accounts have been linked,
    // go to to the last page of the 'LinkAccount' flow
    redirectUrl = lastPageRoute;
  }

  // Redirect to a specific page in this flow if the current path is just the subflow path
  const redirectRoute = (
    <Route
      path='*'
      element={
        <Navigate to={redirectUrl} state={completeFlowLocationState} replace />
      }
    />
  );

  return (
    <>
      <Modal.Basic {...modalConfig} />
      <Routes>
        {redirectRoute}
        {manualAuthRoute}
        {routes}
      </Routes>
    </>
  );
};

export default LinkAccountFlow;
