import * as React from 'react';
import { useSelector } from 'react-redux';
import ExternalRedirect from 'components/router/ExternalRedirect';
import * as app from 'reducers/ui/app';
import { logger } from 'utils/logger';

const ExternalRedirect404 = () => {
  const initializedApp = useSelector(app.initializedApp);
  if (initializedApp) {
    // This indicates a bug in our routing logic as we should be in complete
    // control of the authenticated signup flow.
    logger.error('Unexpected 404 in the signup flow');
  }
  if (window.albertWeb.Environment === 'development') {
    // Easier debugging in local development
    return <div>404 not found!</div>;
  }
  return <ExternalRedirect url='https://albert.com/404/' />;
};

export default ExternalRedirect404;
