const colors = {
  // Misc
  primaryText: '#333333',
  // TODO: Delete this after fully integrating style guide
  geniusText: '#907143',
  primaryBackground: '#FFFFFF',

  // Albert brand (blue)
  brandLight4: '#F2F7FF0',
  brandLight3: '#D9E8FF',
  brandLight2: '#80B2FF',
  brandLight1: '#408CFF',
  primaryAlbertBrand: '#0066FF',
  brandDark1: '#004DBF',
  brandDark2: '#003E99',
  brandDark3: '#003380',
  // TODO: Delete this after fully integrating style guide
  brandDebitCard: '#0073DC',

  // Genius
  // TODO: Delete current primaryGenius color and replace with commented value
  // TODO: once style guide has been integrated
  primaryGenius: '#333333',
  // primaryGenius: '#907143',
  geniusDark1: '#000000',

  // Cards
  cardBlue: '#0073DC',
  cardBlack: '#000000',
  cardWhite: '#FFFFFF',

  // Green
  lightConfirmation1: '#66D596',
  primaryConfirmation: '#0FBB68',
  darkConfirmation1: '#0B874B',
  bannerSuccessBg: 'rgba(102, 213, 150, 0.2)', // primaryConfirmation with 0.2 opacity

  // Orange
  lightWarning1: '#FBB058',
  primaryWarning: '#FB8E0E',
  darkWarning1: '#CC730B',
  bannerWarningBg: 'rgba(251, 176, 88, 0.1)', // lightWarning1 with 0.1 opacity

  // Gray
  lightGray3: '#F8F8FA',
  lightGray2: '#F2F3F7',
  lightGray1: '#CFD1DC',
  primaryGray: '#A2A5B2',
  darkGray1: '#747680',
  solitudeGray: '#E6E7EB',
  loganGray: '#989CAE',

  // Red
  lightError1: '#EF4A48',
  primaryError: '#EF0400',
  darkError1: '#B20300',
  darkError2: '#800200',
  // TODO: Delete this after fully integrating style guide
  bannerErrorBg: 'rgba(239, 4, 0, 0.1)',

  // White
  white: '#FFFFFF',

  // Yellow
  primaryHighlight: '#FFBA0D',
  lightHighlight1: '#FFDE8C',
  darkHighlight1: '#D99E0B',

  // Other
  mobileYellow: 'rgba(255, 186, 13, 0.37);',
};

export default colors;
