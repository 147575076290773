import addBanner from 'actions/banner/addBanner';
import types from 'actions/types';
import AlbertClient from 'api/AlbertClient';
import { NotificationTypes } from 'constants/index';
import * as WEB from 'types/interfaces';
import { onSuccess } from 'utils/axiosMiddlewareUtils';

export default (): WEB.ActionCreator<void> =>
  async (dispatch: WEB.Dispatch): Promise<void> => {
    const response: WEB.Action = await dispatch({
      types: [
        types.EMAIL_CONFIRM_CODE_REQUEST,
        types.EMAIL_CONFIRM_CODE_SUCCESS,
        types.EMAIL_CONFIRM_CODE_FAILURE,
      ],
      payload: {
        request: {
          method: 'get',
          url: AlbertClient.emailConfirmCode(),
        },
        options: {
          onSuccess: (middlewareOptions: any, options: any) => {
            const bannerBody = 'We sent a new code to your email address.';
            dispatch(addBanner(NotificationTypes.SUCCESS, bannerBody, true));
            return onSuccess(middlewareOptions, options);
          },
        },
      },
    });
  };
