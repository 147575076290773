import types from 'actions/types';
import AlbertClient from 'api/AlbertClient';
import { DebitCardStyle } from 'constants/index';
import * as WEB from 'types/interfaces';
import { onErrorDisplayDefaultBanner } from 'utils/axiosMiddlewareUtils';

type Data = {
  debit_card_style?: DebitCardStyle;
  physical_card_opt_in?: boolean;
  physical_card_opt_in_source?: WEB.PhysicalCardOptInSource;
};

/**
 * Update a Banking account.
 */
export default (
    bankingAccountId: number,
    data: Data
  ): WEB.ActionCreator<Promise<boolean>> =>
  async (dispatch: WEB.Dispatch): Promise<boolean> => {
    let error = false;

    await dispatch({
      types: [
        types.UPDATE_BANKING_ACCOUNT_REQUEST,
        types.UPDATE_BANKING_ACCOUNT_SUCCESS,
        types.UPDATE_BANKING_ACCOUNT_FAILURE,
      ],
      payload: {
        request: {
          method: 'patch',
          url: AlbertClient.bankingAccountView(bankingAccountId),
          data,
        },
        options: {
          onError: (middlewareOptions: any, options: any) => {
            error = true;
            return onErrorDisplayDefaultBanner(middlewareOptions, options);
          },
        },
      },
    });

    return error;
  };
