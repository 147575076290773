import * as React from 'react';

const InvestingIcon = (): React.ReactElement => (
  <svg
    width='29'
    height='18'
    viewBox='0 0 29 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M13.504 10.6779L11.392 7.9899C9.664 5.6539 6.24 5.8139 4.736 8.3419L1.216 14.0059C1.056 14.2619 0.96 14.5819 0.96 14.9339C0.96 15.8619 1.728 16.6299 2.656 16.6299C3.264 16.6299 3.776 16.3099 4.096 15.8299L7.616 10.1019C7.904 9.6219 8.384 9.5899 8.704 10.0379L10.816 12.7259C10.848 12.8219 10.88 12.8859 10.944 12.9499L12.608 15.1259C14.368 17.4619 17.792 17.3019 19.296 14.7739L24.096 7.0299L24.928 10.6139C25.088 11.3179 25.728 11.8299 26.464 11.8299C27.488 11.8299 28.256 10.8699 28.032 9.8779L26.24 2.2299C26.048 1.4299 25.312 0.949898 24.512 1.1419L16.864 2.9339C16.16 3.0939 15.616 3.7339 15.616 4.5019C15.616 5.5259 16.608 6.2939 17.6 6.0379L21.216 5.2059L16.416 13.0139C16.128 13.4939 15.648 13.5259 15.328 13.0779L13.632 10.8379C13.6 10.8059 13.6 10.7739 13.568 10.7739L13.504 10.6779ZM23.136 2.9659V2.9979V2.9659ZM25.152 4.2779L25.248 4.2459C25.216 4.2459 25.184 4.2779 25.152 4.2779Z'
      fill='#0066FF'
    />
  </svg>
);

export default InvestingIcon;
