import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import loadSocureSessionId from 'actions/loadSocureSessionId';
import GeniusOnboarding from 'components/genius/GeniusOnboarding';
import KYCFlow from 'components/kyc/KYCFlow';
import LinkAccountFlow from 'components/linkAccount/LinkAccountFlow';
import ExternalRedirect404 from 'components/router/ExternalRedirect404';
import SubFlowContainer from 'components/signup/SubFlowContainer';
import { SignupPages } from 'constants/Pages';
import { Pages, ProductType } from 'constants/index';
import * as language from 'reducers/entities/language';
import * as app from 'reducers/ui/app';
import * as WEB from 'types/interfaces';

// These pages are subpages of its parent page (ie. InstantInfo, SavingsSetup)
// We want to keep this list in this component as the source of truth
export const SIGNUP_PAGES_TO_EXCLUDE = [
  SignupPages.INSTANT_CONFIRM,
  SignupPages.INVESTING_CONFIRM,
  SignupPages.SAVINGS_CONFIRM,
  SignupPages.CARD_HAS_SHIPPED,
] as string[];

const SignupFlow = (): React.ReactElement => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadSocureSessionId('signup'));
  }, [dispatch]);
  const didInitializeApp = useSelector(app.getInitializedApp);
  const languageOrder = useSelector(language.getSignupFlowOrder, shallowEqual);

  const kycModule: React.ReactElement[] = [];

  const dynamicSubflowRoutes = languageOrder.map(
    (context: WEB.LanguageSubflow, index: number) => {
      const subflowPages = context.pages;
      const subflowPath = context.route;

      // Get the subflow component
      const nextSubflowPage =
        languageOrder[index + 1]?.pages?.[0] || Pages.DOWNLOAD_APP;

      const subflowComponent = subflowPages.includes(Pages.GENIUS_TOUR) ? (
        <GeniusOnboarding
          key={`${context.route}-flow`}
          fromSignup
          nextPage={nextSubflowPage}
        />
      ) : (
        <SubFlowContainer
          key={`${context.route}-flow`}
          nextSubflowPage={nextSubflowPage}
          pages={context.pages}
        />
      );

      // NOTE: Alt approach is to use Fragment but React Router does not recognize route if
      // wrapped in Fragment. This is the workaround approach.
      const kycFlowIndex = subflowPages.indexOf(Pages.KYC);
      if (kycFlowIndex >= 0) {
        // If KYC flow is the last step of the current subflow, then pass `nextSubflowPage` (the first page of
        // the next subflow) as KYCFlow prop `nextPage`. If KYC is NOT the last step in the current subflow,
        // then pass the next subsequent signup page in the current subflow as prop `nextPage`.
        const nextPage =
          subflowPages.length - 1 === kycFlowIndex
            ? nextSubflowPage
            : subflowPages[kycFlowIndex + 1];

        kycModule.push(
          <Route
            key={`subflow-route-${subflowPath}-kyc`}
            path={`${subflowPath}/identity/*`}
            element={
              <KYCFlow product={ProductType.SIGNUP} nextPage={nextPage} />
            }
          />
        );
      }

      return (
        <Route
          key={`subflow-route-${subflowPath}`}
          path={`${subflowPath}/*`}
          element={subflowComponent}
        />
      );
    }
  );

  const linkAccountRoute =
    didInitializeApp && dynamicSubflowRoutes.length ? (
      <Route
        key='link-account-flow'
        path='link-account/*'
        element={<LinkAccountFlow />}
      />
    ) : null;

  const catchAllRoute = didInitializeApp ? (
    <Route path='*' element={<ExternalRedirect404 />} />
  ) : null;

  return (
    <Routes>
      {/* Dynamically create routes based on language */}
      {kycModule}
      {dynamicSubflowRoutes}
      {linkAccountRoute}
      {catchAllRoute}
    </Routes>
  );
};

export default SignupFlow;
