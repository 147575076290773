import * as React from 'react';
import styled from 'styled-components';
import ArtMap, { Art } from 'constants/ArtMap';

const StyledImage = styled.img<{ size?: string }>`
  width: {props => props.size || '24px'};
  height: {props => props.size || '24px'};
  animation: spin 2s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

type Props = {
  size?: string;
};

const LoadingSpinner = (props: Props): React.ReactElement => (
  <StyledImage
    size={props.size}
    src={ArtMap(Art.LoadingSpinner)}
    alt='Loading'
  />
);

export default LoadingSpinner;
