import * as React from 'react';
import { isMobile } from 'react-device-detect';
import LoadingBar from 'react-top-loading-bar';
import colors from 'styles/colors';

type Props = {
  /* Is the bar still loading */
  isLoading: boolean;
  /* Hide the bar on mobile */
  hideOnMobile?: boolean;
};

type LoadingBarRef = {
  add(value: number): void;
  decrease(value: number): void;
  continuousStart(startingValue?: number, refreshRate?: number): void;
  staticStart(startingValue: number): void;
  complete(): void;
};

const TopLoadingBar = (props: Props): null | React.ReactElement => {
  // Top loading bar ref
  const ref = React.useRef<LoadingBarRef>(null);
  const [inProgress, setInProgress] = React.useState(false);

  React.useEffect(() => {
    if (props.isLoading && ref?.current) {
      ref?.current?.continuousStart();
      setInProgress(true);
      // Complete only if loading bar is in progress
    } else if (inProgress) {
      ref?.current?.complete();
      setInProgress(false);
    }
  }, [props.isLoading]);

  const LoadingBarComponent = (
    <LoadingBar
      height={4}
      color={colors.primaryAlbertBrand}
      ref={ref}
      shadow={false}
    />
  );

  return props.hideOnMobile && isMobile ? null : LoadingBarComponent;
};

export default TopLoadingBar;
