import * as React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import removeBanner from 'actions/banner/removeBanner';
import BannerPane from 'components/BannerPane';
import Button from 'components/common/Button';
import Input from 'components/common/Input';
import Spacer from 'components/common/Spacer';
import Text, { TextSizes } from 'components/common/Text/index';
import Tooltip from 'components/common/Tooltip';
import QuestionCircleIcon from 'components/icons/QuestionCircleIcon';
import { colors, mixins, spacers } from 'styles';
import { BaseModal } from './shared/BaseModal';

const ModalContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${mixins.pxToRem('-35px')};
`;

const InlineContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ResendCodeText = styled(Text)`
  &:hover {
    color: ${colors.primaryAlbertBrand};
  }
`;

const ModalText = styled(Text)`
  max-width: ${mixins.pxToRem('400px')};
`;

type Props = {
  /* image to render in modal */
  image?: string;
  /* header text */
  header?: string;
  /* text to show below header */
  description?: string;
  /* text to show on tooltip hover */
  tooltipText?: string;
  /* length of confirm code */
  codeLength: number;
  /* handler when code is submitted */
  onCodeSubmit: (code: string) => any;
  /* handler when code is resent */
  onCodeResend: (...args: any) => any;
  /* handler when modal is closed */
  onCancel: (...args: any) => any;
};

const ConfirmCodeModal = (props: Props): React.ReactElement => {
  const {
    image,
    header,
    description,
    tooltipText,
    codeLength,
    onCodeSubmit,
    onCodeResend,
    onCancel,
  } = props;

  const dispatch = useDispatch();

  const [isCodeInvalid, setIsCodeInvalid] = React.useState(false);
  const [code, setCode] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);

  const handleCodeChange = (value: string): void => {
    setCode(value);
  };

  const handleCodeSubmit = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();
    setIsLoading(true);
    setIsCodeInvalid(false);
    dispatch(removeBanner());
    if (!code || code.length < codeLength) {
      setIsCodeInvalid(true);
    } else {
      await onCodeSubmit(code);
    }
    setIsLoading(false);
  };

  const handleCodeResend = async (): Promise<void> => {
    setIsLoading(true);
    setIsCodeInvalid(false);
    setCode('');
    await onCodeResend();
    setIsLoading(false);
  };

  return (
    <BaseModal onClickClose={onCancel}>
      <ModalContentContainer>
        <BannerPane />
        <img src={image} alt='confirm-code' />
        <Spacer space={spacers.tabSmall} />
        <ModalText align='center' size={TextSizes.XXLARGE} weight='700'>
          {header}
        </ModalText>
        <Spacer space={spacers.tiny} />
        <ModalText align='center'>{description}</ModalText>
        <Spacer space={spacers.tabSmall} />
        <Input.Code
          id='code-input'
          label=''
          fields={codeLength}
          invalid={isCodeInvalid}
          errorText='Please enter a valid code'
          onChange={handleCodeChange}
        />
        <Spacer space={spacers.tabLarge} />
        <Button
          stretch
          onClick={handleCodeSubmit}
          isLoading={isLoading}
          disabled={isLoading}
        >
          Verify
        </Button>
        <Spacer space={spacers.small} />
        <InlineContainer>
          <ResendCodeText
            size={TextSizes.SMALL}
            weight='700'
            underline
            pointer
            inline
            onClick={handleCodeResend}
          >
            Resend code
          </ResendCodeText>
          <Spacer space={spacers.miniscule} orientation='horizontal' />
          <QuestionCircleIcon
            data-tip={tooltipText}
            data-for='resend-code-tooltip'
          />
          <Tooltip id='resend-code-tooltip' place='top' />
        </InlineContainer>
      </ModalContentContainer>
    </BaseModal>
  );
};

export default ConfirmCodeModal;
