import CodeInput from './CodeInput';
import CurrencyInput from './CurrencyInput';
import DateInput from './DateInput';
import InputGroup from './InputGroup';
import NumberInput from './NumberInput';
import SSNInput from './SSNInput';
import SearchInput from './SearchInput';
import TextInput from './TextInput';
import TextInputControlled from './TextInputControlled';

export default {
  Text: TextInput,
  TextControlled: TextInputControlled,
  Code: CodeInput,
  Number: NumberInput,
  Currency: CurrencyInput,
  Group: InputGroup,
  Search: SearchInput,
  SSN: SSNInput,
  Date: DateInput,
};
