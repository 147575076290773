import * as React from 'react';
import styled from 'styled-components';
import Text, { TextSizes } from 'components/common/Text';
import Tooltip from 'components/common/Tooltip';
import QuestionCircleIcon from 'components/icons/QuestionCircleIcon';
import { breakpoints } from 'styles';
import { spacers } from 'styles/home';
import { flexGap } from 'utils/polyfills';

type Props = {
  onClick: () => void;
  tooltipText: string;
};
const Container = styled.div`
  display: flex;
  align-items: center;
  ${flexGap(spacers.g2)};
  @media ${breakpoints.mobileLarge} {
    button {
      width: 100%;
    }
  }
`;

const DesktopTooltipWrapper = styled.div`
  display: block;
  @media ${breakpoints.mobileLarge} {
    display: none;
  }
`;

const MobileTooltipWrapper = styled.div`
  display: none;
  @media ${breakpoints.mobileLarge} {
    display: block;
  }
`;

const ResendCodeButton = ({
  onClick,
  tooltipText,
}: Props): React.ReactElement => (
  <Container>
    <Text
      nowrap
      pointer
      weight='700'
      size={TextSizes.SMALL}
      onClick={onClick}
      isLinkButton
      underline
    >
      Resend code
    </Text>
    <div>
      <QuestionCircleIcon
        data-tip={tooltipText}
        data-for='resend-code-tooltip'
      />
      <DesktopTooltipWrapper>
        <Tooltip id='resend-code-tooltip' place='top' />
      </DesktopTooltipWrapper>
      <MobileTooltipWrapper>
        <Tooltip id='resend-code-tooltip' place='bottom' />
      </MobileTooltipWrapper>
    </div>
  </Container>
);

export default ResendCodeButton;
