import types from 'actions/types';
import Theme from 'constants/Theme';
import * as WEB from 'types/interfaces';

export default (theme: Theme): WEB.Action => ({
  type: types.SET_THEME,
  payload: {
    theme,
  },
});
