import * as React from 'react';
import { Field, Form } from 'react-final-form';
import { useDispatch } from 'react-redux';
import TrackingBase from 'tracking.Base';
import removeBanner from 'actions/banner/removeBanner';
import createProfileAndLogIn from 'actions/createProfileAndLogIn';
import Button from 'components/common/Button';
import Checkbox from 'components/common/Checkbox';
import Input from 'components/common/Input';
import { AutoCapitalize } from 'components/common/Input/TextInput';
import Spacer from 'components/common/Spacer';
import Text, { TextColor, TextSizes } from 'components/common/Text';
import ButtonsContainer from 'components/layout/ButtonsContainer';
import { HIDE_NAV_AND_BANNER } from 'constants/PageDisplayOptions';
import SplashLink from 'constants/SplashLink';
import { AlbertTrackingEvent } from 'constants/index';
import useNavigateFunction from 'hooks/useNavigateFunction';
import { spacers } from 'styles';
import { InputFieldProps } from 'types/interfaces';
import { delayWithState } from 'utils/delay';
import { getFacebookCookieValues } from 'utils/facebookCookies';
import {
  composeValidators,
  isRequired,
  minLen,
  validEmail,
} from 'utils/validators';
import { parseQueryParamBoolean } from '../../../../src/common/utils/parseQueryParamBoolean';

type SignupFormProps = Readonly<{
  hasWarningBanner: boolean;
}>;

enum SignupLink {
  TERMS_OF_USE = 'TERMS_OF_USE',
  PRIVACY_POLICY = 'PRIVACY_POLICY',
  E_SIGN_CONSENT = 'E_SIGN_CONSENT',
}

const SignupLinkTextMap: { [key in SignupLink]: string } = {
  [SignupLink.TERMS_OF_USE]: 'Terms of Use',
  [SignupLink.PRIVACY_POLICY]: 'Privacy policy',
  [SignupLink.E_SIGN_CONSENT]: 'E-sign agreement',
};

const SignupLinkMap: { [key in SignupLink]: string } = {
  [SignupLink.TERMS_OF_USE]: `${SplashLink.TERMS}${HIDE_NAV_AND_BANNER}`,
  [SignupLink.PRIVACY_POLICY]: `${SplashLink.PRIVACY}${HIDE_NAV_AND_BANNER}`,
  [SignupLink.E_SIGN_CONSENT]: SplashLink.E_SIGN_CONSENT,
};

const getLink = (
  linkType: SignupLink,
  onClick?: () => void,
  color?: TextColor,
  weight?: string
) => {
  // Needed to prevent propogation of click event from anchor tag to
  // the checkbox component (ie. clicking on anchor tag also changed checkbox state)
  const createRedirectOnClickHandler = (
    e: React.MouseEvent<HTMLSpanElement>,
    link: string
  ) => {
    e?.stopPropagation();
    window.open(link, '_blank');
  };

  return (
    <Text
      inline
      color={color || TextColor.GRAY}
      weight={weight || '600'}
      size={TextSizes.SMALL}
      onClick={(event) => {
        if (onClick) {
          onClick();
          return;
        }
        createRedirectOnClickHandler(event, SignupLinkMap[linkType]);
      }}
      isLinkButton
      underline
    >
      {SignupLinkTextMap[linkType]}
    </Text>
  );
};

function SignupForm({ hasWarningBanner }: SignupFormProps) {
  // ///////////////////////////////
  /* =========== HOOKS ========== */
  // ///////////////////////////////
  const { updateCurrentSignupPage } = useNavigateFunction();
  const dispatch = useDispatch();

  // ///////////////////////////////
  /* =========== STATE ========== */
  // ///////////////////////////////
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [isCompleted, setIsCompleted] = React.useState<boolean>(false);
  const [confirmed, setConfirmed] = React.useState(false);
  const [isCheckboxSubmitted, setIsCheckboxSubmitted] = React.useState(false);

  // //////////////////////////////////
  /* =========== HANDLERS ========== */
  // //////////////////////////////////
  const handleConfirmedOnClick = () => {
    setConfirmed(!confirmed);
  };

  const handleOnSubmit = async (values: Record<string, string>) => {
    const { email, password } = values;
    const first_name = values['first-name'];
    const last_name = values['last-name'];

    // Display the confirm checkbox as invalid
    if (!confirmed) {
      setIsCheckboxSubmitted(true);
      return;
    }

    // Indicate button loading state
    setIsSubmitting(true);

    // Remove previous warning banner that way we either make another warning
    // banner if the submission fails again, or get rid of it if successful
    if (hasWarningBanner) dispatch(removeBanner());

    // get attribution
    const queryParams = new URLSearchParams(window.location.search);
    const preventOverwriteRaw = queryParams.get('prevent_overwrite_ad_info');
    const partner = queryParams.get('adpartner') || undefined;
    let attribution;

    if (partner) {
      const { fbp, fbc } = getFacebookCookieValues();
      attribution = {
        advertising_partner_name: partner,
        ad_campaign: queryParams.get('campaign') || undefined,
        ad_set: queryParams.get('adgroup') || undefined,
        ad_name: queryParams.get('adcreative') || undefined,
        ad_keyword: queryParams.get('keyword') || undefined,
        gclid: queryParams.get('gclid') || undefined,
        gbraid: queryParams.get('gbraid') || undefined,
        wbraid: queryParams.get('wbraid') || undefined,
        prevent_overwrite_ad_info:
          !preventOverwriteRaw && partner === 'Google AdWords'
            ? true
            : parseQueryParamBoolean(preventOverwriteRaw),
        facebook_fbp: fbp,
        facebook_fbc: fbc,
        msclkid: queryParams.get('msclkid') || undefined,
      };
    }

    const createProfileErrorMessage: any = await dispatch(
      createProfileAndLogIn(
        {
          password,
          first_name,
          last_name,
          email,
        },
        attribution
      )
    );

    const { error, firstPage } = createProfileErrorMessage;
    setIsSubmitting(false);

    // profile created successfully
    if (!error && firstPage) {
      TrackingBase.track({
        event: AlbertTrackingEvent.SIGNED_UP,
        trackOnce: true,
        trackSocial: true,
      });
      // Indicate button completed state
      await delayWithState(400, setIsCompleted);
      dispatch(
        updateCurrentSignupPage({ page: firstPage, shouldReplace: true })
      );
    }
  };

  // ///////////////////////////////////////
  /* =========== FORM ELEMENTS ========== */
  // ///////////////////////////////////////
  const initialEmail =
    new URLSearchParams(window.location.search).get('email') || '';

  const FirstNameInput = ({ input, meta }: Partial<InputFieldProps>) => {
    return (
      <Input.Text
        id='first-name'
        placeholder='First name'
        {...input}
        invalid={meta?.error && meta.submitFailed && !meta.dirtySinceLastSubmit}
        errorText='Please enter a valid first name.'
      />
    );
  };

  const LastNameInput = ({ input, meta }: Partial<InputFieldProps>) => {
    return (
      <Input.Text
        id='last-name'
        placeholder='Last name'
        {...input}
        invalid={meta?.error && meta.submitFailed && !meta.dirtySinceLastSubmit}
        errorText='Please enter a valid last name.'
      />
    );
  };

  const EmailAddressInput = ({ input, meta }: Partial<InputFieldProps>) => {
    return (
      <Input.Text
        id='email'
        label='Email address'
        placeholder='example@gmail.com'
        {...input}
        invalid={meta?.error && meta.submitFailed && !meta.dirtySinceLastSubmit}
        errorText='Please enter a valid email address.'
        autoCapitalize={AutoCapitalize.OFF}
      />
    );
  };

  const PasswordInput = ({ input, meta }: Partial<InputFieldProps>) => {
    return (
      <Input.Text
        id='password'
        type='password'
        label='Password'
        {...input}
        invalid={meta?.error && meta.submitFailed && !meta.dirtySinceLastSubmit}
        errorText='Password must be at least 8 characters.'
      />
    );
  };

  return (
    <Form
      onSubmit={handleOnSubmit}
      render={({ handleSubmit, submitFailed }) => (
        <form onSubmit={handleSubmit as any}>
          <Input.Group
            id='name-group'
            label="What's your full legal name?"
            smallLabel='(no nicknames or initials, please)'
            smallLabelNewLine
          >
            <Field
              name='first-name'
              component={FirstNameInput}
              validate={composeValidators(isRequired)}
            />
            <Field
              name='last-name'
              component={LastNameInput}
              validate={composeValidators(isRequired)}
            />
          </Input.Group>
          <Spacer space={spacers.tabLarge} desktopOnly />
          <Field
            name='email'
            component={EmailAddressInput}
            initialValue={initialEmail}
            validate={composeValidators(isRequired, validEmail)}
          />
          <Spacer space={spacers.tabLarge} desktopOnly />
          <Field
            name='password'
            component={PasswordInput}
            validate={composeValidators(isRequired, minLen(8))}
          />
          <Spacer space={spacers.tabLarge} desktopOnly />
          <Checkbox
            id='agreement-check'
            check={confirmed}
            onClick={handleConfirmedOnClick}
            invalid={
              // Checkbox validation won't trigger unless form validation passes, so we need to check both
              // form and checkbox validation states to determine whether to show the checkbox error
              !confirmed && (submitFailed || isCheckboxSubmitted)
            }
            label={
              <Text color={TextColor.GRAY} size={TextSizes.SMALL}>
                By clicking agree and continue, you agree to Albert's{' '}
                {getLink(SignupLink.TERMS_OF_USE)}
                {', '}
                {getLink(SignupLink.PRIVACY_POLICY)}
                {' and '}
                {getLink(SignupLink.E_SIGN_CONSENT)}.
              </Text>
            }
          />
          <ButtonsContainer>
            <Button
              id='signup-confirm-button'
              isCompleted={isCompleted}
              isLoading={isSubmitting}
              disabled={isSubmitting}
              stretch
            >
              {!isCompleted ? 'Agree and continue' : 'Email registered'}
            </Button>
          </ButtonsContainer>
        </form>
      )}
    />
  );
}

export default SignupForm;
