import * as Sentry from '@sentry/react';
import { FORM_ERROR } from 'final-form';
import * as React from 'react';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import updateProfile from 'actions/async/updateProfile';
import removeBanner from 'actions/banner/removeBanner';
import Button from 'components/common/Button';
import TopInfoButton from 'components/common/Button/TopInfoButton';
import ErrorText from 'components/common/ErrorText';
import MobileInfoModal from 'components/common/Modal/MobileInfoModal';
import Radio from 'components/common/Radio';
import Spacer from 'components/common/Spacer';
import Text, { TextColor, TextSizes } from 'components/common/Text';
import ButtonsLayout from 'components/layout/ButtonsLayout';
import LeadContent from 'components/layout/LeadContent';
import PageBase from 'components/layout/PageBase';
import { LinkAccountCompleteLocationState } from 'components/linkAccount/LinkAccountFlow';
import Pages from 'constants/Pages';
import { SavingsMethods } from 'constants/SavingsSchedules';
import SignupGoals from 'constants/SignupGoals';
import { PageRoutes } from 'constants/index';
import useNavigateFunction from 'hooks/useNavigateFunction';
import useVerifyCurrentPage from 'hooks/useVerifyCurrentPage';
import SavingsConfirm from 'pages/signup/SavingsConfirm';
import * as appData from 'reducers/entities/appData';
import * as language from 'reducers/entities/language';
import * as profile from 'reducers/entities/profile';
import { breakpoints } from 'styles';
import { spacers } from 'styles/home';
import * as WEB from 'types/interfaces';
import { LanguageListItem } from 'types/interfaces';
import { getIsCheckpointDisabled } from 'utils/checkpointNavUtils';
import { delayWithState } from 'utils/delay';
import {
  getLanguageButtons,
  getLanguageInfo,
  getLanguageSection,
} from 'utils/getFromLanguage';
import { logger } from 'utils/logger';
import { composeValidators, isRequired } from 'utils/validators';

type Props = {
  nextPage: string;
};

type LocationState = LinkAccountCompleteLocationState | WEB.EmptyObject;

const StyledText = styled(Text)`
  @media ${breakpoints.mobileLarge} {
    text-align: center;
  }
`;

const ErrorTextWrapper = styled.div`
  width: 100%;
`;

const InvestingPreferences = (props: Props) => {
  const { updateCurrentSignupPage } = useNavigateFunction();
  const location = useLocation();
  const locationState = (location?.state || {}) as LocationState;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // Make sure Redux is up-to-date with currentSignUpPage
  useVerifyCurrentPage(Pages.INVESTING_PREFERENCES);

  // Defaults
  const defaultFinancialExperience = useSelector((state: WEB.RootState) =>
    profile.getInfoValueByField(
      state,
      'investment_info',
      'investment_experience'
    )
  );
  const defaultFinancialRiskTolerance = useSelector((state: WEB.RootState) =>
    profile.getInfoValueByField(state, 'investment_info', 'risk_tolerance')
  );
  const defaultInvestmentLength = useSelector((state: WEB.RootState) =>
    profile.getInfoValueByField(state, 'investment_info', 'time_horizon')
  );
  const defaultInvestmentObjective = useSelector((state: WEB.RootState) =>
    profile.getInfoValueByField(
      state,
      'investment_info',
      'investment_objective'
    )
  );
  const hasPrimaryChecking = useSelector(appData.hasPrimaryChecking);
  const languageCtx = useSelector((state: WEB.RootState) =>
    language.getSignupPageLanguage(state, Pages.INVESTING_PREFERENCES)
  );

  const introCtx = getLanguageSection(languageCtx, 'intro');
  const buttonsCtx = getLanguageButtons(languageCtx);
  const infoPaneContext = getLanguageInfo(languageCtx);
  const expanders = infoPaneContext?.expanders;

  const hasSavingGoal = useSelector((state: WEB.RootState) =>
    appData.hasSetupGoal(state, SignupGoals.SAVE_MONEY)
  );
  const hasInvestingGoal = useSelector((state: WEB.RootState) =>
    appData.hasSetupGoal(state, SignupGoals.INVESTING)
  );
  let defaultDepositDistribution: WEB.DepositDistribution = useSelector(
    (state: WEB.RootState) =>
      profile.getInfoValueByField(
        state,
        'personal_info',
        'deposit_distribution'
      )
  );
  if (!defaultDepositDistribution) {
    if (hasSavingGoal && !hasInvestingGoal) {
      defaultDepositDistribution = 'save';
    } else if (hasInvestingGoal && !hasSavingGoal) {
      defaultDepositDistribution = 'invest';
    }
  }

  const questions = Array.isArray(introCtx?.list) ? introCtx.list : [];

  const defaultInvestingPreferences: WEB.InvestingPreferences = {
    deposit_distribution: defaultDepositDistribution,
    investment_experience: defaultFinancialExperience?.toString(),
    risk_tolerance: defaultFinancialRiskTolerance?.toString(),
    time_horizon: defaultInvestmentLength?.toString(),
    investment_objective: defaultInvestmentObjective?.toString(),
  };

  /**
   * 2023/05/26, AR: Debug issue where some users are
   * possibly stuck on this page
   */
  const hasMalformedAnswers = questions.some(
    (question) => !Array.isArray(question.items) || question.items.length < 1
  );
  if (questions.length === 0 || hasMalformedAnswers) {
    const errorContext = {
      defaultInvestingPreferences: JSON.stringify(defaultInvestingPreferences),
      questions: JSON.stringify(introCtx?.list),
    };
    Sentry.setContext('Page data', errorContext);
    logger.error(
      'Malformed questions in InvestingPreferences; signup is blocked'
    );
  }

  const [finishedSetup, setFinishedSetup] = React.useState(
    // If user just exited the Link Account flow, then initialize to show the SavingsConfirm page.
    !!locationState.showConfirmation
  );
  const [showInfoModal, setShowInfoModal] = React.useState<boolean>(false);
  const [isCompleted, setIsCompleted] = React.useState<boolean>(false);

  React.useEffect(() => {
    // Trigger a render whenever showConfirmation changes.
    // Otherwise, when navigating backwards from SavingsConfirm -> SavingsSetup,
    // the url changes correctly but the app still displays SavingsConfirm
    setFinishedSetup(!!locationState.showConfirmation);
  }, [locationState.showConfirmation]);

  const handleInfoOnClick = () => {
    setShowInfoModal(true);
  };
  const handleNavigateNextPage = (): void => {
    // Navigate to next page.
    dispatch(updateCurrentSignupPage({ page: props.nextPage }));
  };

  const onSubmit = async (values: any) => {
    // Remove any error banner from previous attempts
    dispatch(removeBanner());
    const payload = { ...values };
    // Cast numeric string values back to numbers
    Object.keys(payload).forEach((key) => {
      if (!isNaN(payload[key])) {
        payload[key] = Number(payload[key]);
      }
    });
    const { error }: any = await dispatch(
      updateProfile({
        investment_info: payload,
      })
    );
    if (error) {
      const errorContext = {
        defaultInvestingPreferences: JSON.stringify(
          defaultInvestingPreferences
        ),
        questions: JSON.stringify(introCtx?.list),
        values: JSON.stringify(values),
        payload: JSON.stringify(payload),
      };
      Sentry.setContext('Page data', errorContext);
      logger.error('Failed to update profile with investing preferences');
      return { [FORM_ERROR]: 'error' };
    }
  };
  const submitCallback = async (errors?: unknown): Promise<void> => {
    if (errors) return;
    await delayWithState(400, setIsCompleted);
    // Check if user does not have linked primary checking
    if (!hasPrimaryChecking || getIsCheckpointDisabled()) {
      // Navigate to the Link Account flow for the user to link their bank account with Albert.
      navigate(PageRoutes.LINK_ACCOUNT, {
        state: {
          pageOnComplete: Pages.INVESTING_PREFERENCES,
          pageOnSkip: props.nextPage,
          currentPage: Pages.INVESTING_PREFERENCES,
        },
      });
    } else {
      setFinishedSetup(true);
    }
  };

  const showInfo = !finishedSetup && Array.isArray(expanders);

  return (
    <>
      {showInfo && <TopInfoButton onClick={handleInfoOnClick} />}
      {finishedSetup ? (
        <SavingsConfirm
          schedule={SavingsMethods.AUTO}
          recurringAmount={0}
          onClick={handleNavigateNextPage}
        />
      ) : (
        <PageBase>
          <LeadContent
            header={introCtx?.header || ''}
            text={introCtx?.text || ''}
            desktopHeaderSize='small'
          />
          <Form
            onSubmit={onSubmit}
            initialValues={defaultInvestingPreferences}
            render={({ handleSubmit, submitting }) => (
              <form
                onSubmit={(event) => handleSubmit(event)?.then(submitCallback)}
              >
                {questions.map((section: LanguageListItem) => {
                  return (
                    <React.Fragment key={section.id}>
                      <Spacer space={spacers.g11} desktopOnly />
                      <Radio.Group
                        id={section.id as string}
                        label={section.header}
                      >
                        {section.items?.map((item) => {
                          return (
                            <Field
                              name={section.id as string}
                              type='radio'
                              // React Final Form can't handle numeric values
                              value={item.id?.toString()}
                              key={item.id}
                              validate={composeValidators(isRequired)}
                            >
                              {({ input, meta }) => {
                                const invalid =
                                  meta?.error &&
                                  meta?.submitFailed &&
                                  !meta.dirtySinceLastSubmit;
                                return (
                                  <Radio.Button
                                    id={input.name}
                                    label={item.header}
                                    labelColor={TextColor.GRAY_DARK}
                                    selected={input.checked}
                                    onClick={input.onChange}
                                    value={input.value}
                                    name={section.header}
                                    invalid={invalid}
                                  />
                                );
                              }}
                            </Field>
                          );
                        })}
                        <Field name={section.id as string} type='radio'>
                          {({ meta }) => {
                            const invalid =
                              meta?.error &&
                              meta?.submitFailed &&
                              !meta.dirtySinceLastSubmit;
                            if (invalid) {
                              return (
                                <ErrorTextWrapper>
                                  <ErrorText
                                    name={section.id as string}
                                    text={section.errorMessage}
                                  />
                                </ErrorTextWrapper>
                              );
                            }
                            return null;
                          }}
                        </Field>
                      </Radio.Group>
                    </React.Fragment>
                  );
                })}
                <Spacer space={spacers.g14} />
                <StyledText color={TextColor.GRAY} size={TextSizes.TINY}>
                  {introCtx?.disclaimer}
                </StyledText>
                <ButtonsLayout
                  marginTop={spacers.g5}
                  primaryButton={
                    <Button
                      isLoading={submitting}
                      disabled={submitting}
                      isCompleted={isCompleted}
                    >
                      {isCompleted ? '' : buttonsCtx?.primary}
                    </Button>
                  }
                />
              </form>
            )}
          />
        </PageBase>
      )}
      {showInfo && (
        <MobileInfoModal
          expanders={expanders}
          show={showInfoModal}
          onCancel={() => setShowInfoModal(false)}
        />
      )}
    </>
  );
};

export default InvestingPreferences;
