import * as React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Button from 'components/common/Button';
import Text from 'components/common/Text';
import ButtonsContainer from 'components/layout/ButtonsContainer';
import PageBase from 'components/layout/PageBase';
import ArtMap from 'constants/ArtMap';
import Pages from 'constants/Pages';
import SavingsSchedules, { SavingsMethods } from 'constants/SavingsSchedules';
import * as language from 'reducers/entities/language';
import { breakpoints } from 'styles';
import * as WEB from 'types/interfaces';
import { getLanguageButtons, getLanguageSection } from 'utils/getFromLanguage';
import renderTemplateContext from 'utils/renderTemplateContext';

type Props = {
  schedule: string;
  recurringAmount: number;
  onClick: () => void;
};

const StyledText = styled(Text)`
  white-space: pre-wrap;
`;

const StyledHeader = styled.h1`
  @media ${breakpoints.mobileLarge} {
    margin: 0;
  }
`;

const SavingsConfirm = ({
  schedule,
  recurringAmount,
  onClick,
}: Props): React.ReactElement => {
  // //////////////////////////////////
  /* =========== LANGUAGE ========== */
  // //////////////////////////////////
  const languageCtx = useSelector((state: WEB.RootState) =>
    language.getSignupPageLanguage(state, Pages.SAVINGS_CONFIRM)
  );

  const INTRO_KEY = 'intro';
  const introSection = getLanguageSection(languageCtx, INTRO_KEY);
  const buttonsCtx = getLanguageButtons(languageCtx);

  const pageContext = {
    schedule,
    recurringAmount,
  };

  // Schedule verbose - ie. '7' -> 'per week'.
  pageContext.schedule = SavingsSchedules[schedule];
  // ////////////////////////////////////////////
  /* =========== Templated Page Text ========= */
  // ////////////////////////////////////////////

  // eslint-disable-next-line prettier/prettier
  // template text for congrats bank secreen

  const introText =
    schedule !== SavingsMethods.AUTO
      ? renderTemplateContext(introSection.text || '', pageContext)
      : introSection.default_text;

  const introTextSection = (
    <React.Fragment key='info-text-fragment-congrats-bank'>
      <StyledText key='info-text-congrats-bank' className='description'>
        {introText}
      </StyledText>
    </React.Fragment>
  );

  return (
    <PageBase>
      <StyledHeader>
        {introSection?.header}{' '}
        {introSection?.image && (
          <img
            alt='Thumbs up'
            src={ArtMap(
              introSection?.image?.filename ? introSection?.image?.filename : ''
            )}
            width={introSection?.image?.width}
          />
        )}
      </StyledHeader>
      {introTextSection}
      <ButtonsContainer>
        <Button id='congrats-bank-confirm-button' onClick={onClick}>
          {buttonsCtx?.primary}
        </Button>
      </ButtonsContainer>
    </PageBase>
  );
};

export default SavingsConfirm;
