import types from 'actions/types';
import AlbertClient from 'api/AlbertClient';
import * as WEB from 'types/interfaces';
import { onErrorDisplayDefaultBanner } from 'utils/axiosMiddlewareUtils';

export interface UpdatePhoneFields {
  text_confirm_code: string;
}

export default (
    fieldsToUpdate: UpdatePhoneFields
  ): WEB.ActionCreator<Promise<Record<string, any>>> =>
  async (dispatch: WEB.Dispatch): Promise<Record<string, any>> => {
    let error = false;
    const response = await dispatch({
      types: [
        types.UPDATE_PHONE_REQUEST,
        types.UPDATE_PHONE_SUCCESS,
        types.UPDATE_PHONE_FAILURE,
      ],
      payload: {
        request: {
          method: 'post',
          url: AlbertClient.profileUpdatePhoneView(),
          data: {
            ...fieldsToUpdate,
          },
        },
        options: {
          onError: (middlewareOptions: any, options: any) => {
            error = true;
            return onErrorDisplayDefaultBanner(middlewareOptions, options);
          },
        },
      },
    });
    return { response, error };
  };
