/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { AutoCapitalize } from 'components/common/Input/TextInput';
import { InputWidth } from 'components/common/Input/shared';
import { AccountTypeShort } from 'constants/AccountType';
import { AutoTransferOptions } from 'constants/AutoTransferSchedule';
import { ShippingStatus } from 'constants/DebitCardStyle';
import Pages from 'constants/Pages';
import SignupGoals, { SignupGoalsType } from 'constants/SignupGoals';
import {
  AccountCategory,
  AccountType,
  AutoTransferSchedule,
  BannerActionType,
  DebitCardStyle,
  NotificationTypes,
  PaymentFrequency,
  PollingType,
  PropertySourceTypes,
  Theme,
} from 'constants/index';

// //////////////////////////////////
/* ============ REDUX ============ */
// //////////////////////////////////
/**
 * This is a custom to allow for actions expected by
 * redux-axios-middleware, which use the `types` field to take distinct
 * request, success, and failure types.
 */
export interface Action {
  type?: string;
  types?: string[];
  payload?: any;
  error?: {
    response?: {
      data?: {
        message?: string;
        title?: string;
        button_action?: string;
        [key: string]: any;
      };
      status: number;
    };
    message?: string;
  };
  socket?: boolean;
  meta?: any;
}

export type ActionCreator<R> = (
  dispatch: Dispatch,
  getState: GetStateFunc
) => R;

export interface Dispatch {
  <A extends Action>(action: A): Promise<A>;
  <D extends object>(action: Action): Promise<
    Omit<Action, 'payload'> & { payload?: { data?: D } }
  >;
  <R>(asyncAction: ActionCreator<R>): R;
}

/**
 * Function type for our version of getState()
 */
export interface GetStateFunc {
  (): RootState;
}

export interface RootState {
  entities: {
    profile: Profile | Record<string, any>;
    appData: AppData | Record<string, any>;
    language: {
      signup: SignupLanguage | Record<string, any>;
      genius: GeniusLanguage | Record<string, any>;
      kyc: KYCLanguage | Record<string, any>;
      originalOrder: LanguageSubflow[];
    };
    institution: Institution[];
    banner: Banner & {
      isHidden: boolean;
    };
    geniusPayments: GeniusPayments[];
  };
  ui: {
    app: App | Record<string, any>;
    signup: UISignup | Record<string, any>;
    genius: UIGenius | Record<string, any>;
    kyc: UIKYC | Record<string, any>;
    banking: UIBanking | Record<string, any>;
  };
}

// store > ui > app
export interface App {
  loggedIn: boolean;
  theme: Theme;
  initializedApp: boolean;
  isLoading: boolean;
  pollingFunction: PollingFunction;
  show2FA: boolean;
}

export interface UIKYC {
  currentPage: string;
  mfa: MFAQuestion[];
  showPersonaVerification: boolean;
}

export interface MFAQuestion {
  answer: string[];
  prompt: string;
  type: string;
}

export interface MFAAnswerSet {
  [question: string]: MFAAnswer;
}

export interface MFAAnswer {
  type: string;
  answer: string | null;
  selectedIndex?: number | null;
}

export type DepositDistribution = 'save' | 'invest';

export interface InvestingPreferences {
  deposit_distribution: DepositDistribution | null;
  investment_experience: number | null;
  risk_tolerance: number | null;
  time_horizon: number | null;
  investment_objective?: number | null;
}

export interface UIGenius {
  currentPage: string;
  selectedPrice: number;
  annualBonus: number;
  selectedGeniusFrequency: PaymentFrequency;
}

export interface UISignup {
  checkpoint: string;
  currentPage: string;
  selectedInsId: string;
  socureSessionId?: string;
}

export interface UIBanking {
  isCreating: boolean;
  previouslySubmittedTransaction: TransferTransaction;
  addAccountsClicked: boolean;
}

export interface PollingFunction {
  type: PollingType;
  args: {
    [key: string]: any;
  };
}

// store > entities > appData
export interface AppData {
  savings: SavingsAppData;
  accounts_dict: AccountsDict;
  last_visited_timestamp: number | null;
  insurance_permissions: {
    [product: string]: boolean;
  };
  signup_screen: null | string;
  web_signup_screen: null | string;
  // TODO: in the future
  instant: any;
  investing: any;
  waitlists: any;
  grouped_accounts: any;
  prompt_dict: any;
  transactions: any;
  spendable: any;
  spendable_historical: any;
  bills: any[];
  health: any;
  planning: any;
  modules: any[];
  financial_plan: any[];
  genius_topics: any;
  account_setup: any[];
  tracking_traits: TrackingTraits;
  app_notifications: AppNotification[];
  banking?: BankingAppData;
  kyc: KYCAppData;
  signup_persona: SignupPersona;
  transfer_history: TransferDetails[];
  /**
   * @deprecated Use `signup_goal_objects` instead.
   */
  signup_goals: SignupGoals[];
  signup_goal_objects: SignupGoalObject[];
}

export interface SignupGoalObject {
  id: number;
  profile: number;
  name: SignupGoals;
  type: SignupGoalsType;
}

export interface TrackingTraits {
  genius_enabled: string | null;
  auto_saving: string | null;
  monthly_price: number | null;
  payment_frequency: string | null;
  savings_method: string | null;
  savings_transfer_interval: string | null;
}

export interface EventTraits {
  [key: string]: string | number | boolean;
}

export interface PeopleTraits {
  $created: string;
  id: number;
  in_signup_flow: boolean;
  auto_saving: string;
  genius_enabled: boolean;
}

export interface AccountsDict {
  [accountId: string]: InstitutionAccount | Property;
}

export interface AppNotification {
  id: string;
  item_key: string;
  title: string;
  text: string;
  bar_prompt: string;
  color: string;
  rank: number;
  icon_name: string;
  bar_icon_name: string | null;
  image_name: string | null;
  action: string;
  is_active: boolean;
  requires_view_tracking: boolean;
  button_text: string | null;
  skip_button_text: string | null;
  image_url: string | null;
  content_name: string | null;
  is_lottie: boolean;
  lottie_info: Record<string, any>;
  can_dismiss: boolean;
  disclaimer: string | null;
  location: string;
  display_size: string;
  account_id: string;
}

export interface TransferDetails {
  product: string;
  id: number;
  amount: number;
  timestamp: number;
  is_deposit: boolean;
  is_auto: boolean;
  status: string;
  can_cancel: boolean;
  progress: ACHTransactionTimelineCheckpoint[];
  stamp_label: Record<string, any> | null;
  status_message: string | null;
  ach_account?: number;
  title: string;
  subtitle: string;
  details: TransferAccountDetails;
}

export interface TransferAccountDetails {
  action: string;
  account_name: string;
  account_details: string;
}

export interface TransferTransaction {
  universal_cash_transfer_id: number;
  albert_cash: {
    id: string;
    ach_account: number;
    pk: number;
    estimated_ach_delivery_date: number;
  };
}

export interface CreateTransferResponse {
  title: string;
  message: string;
  button: string;
  data: TransferTransaction;
}

export interface StatementUrl {
  statement_url?: string;
}

// ////////////////////////////////
/* ========== SAVINGS ========== */
// ////////////////////////////////
export interface SavingsAppData {
  account: InstitutionAccount & {
    transfer_interval: AutoTransferSchedule;
    next_auto_transfer_timestamp: number;
    recurring_transfer_amount: number | null;
    bank_account: number | null;
    can_auto_save: boolean;
    is_on_smart_money: boolean;
    smart_money_ratio: number | null;
  };
  current_interest: number;
  auto_saving: AutoTransferOptions;
  balance: number;
  available_to_widthdraw: number;
  in_progress_debits: number;
  in_progress_credits: number;
  cancellable_credits: number;
  saved_since_joining: number;
  '30d': number;
  '7d': number;
  num_transfers: number;
  days_since_first: number;
  has_active_savings: boolean;
  can_reset_savings: boolean;
  savings_goals: SavingsGoal[];
  internal_transactions: InternalTransaction[];
  has_savings_gift_to_claim: boolean | null;
  balance_less_pending_gifts: number;
  in_progress_credits_less_pending_gifts: number;
  num_referrals: number;
  bonuses_total: number;
  bonuses_ytd: number;
  bonuses_last_year: number;
  // TODO: in the future
  pending_gifts: any[];
  transactions: SavingsTransaction[];
  transactions_auth: any[];
  transactions_all: any[];
  rainy_day_fund_transactions: any[];
  coastal_statements: CoastalSavingsStatement[];
}

export interface SavingsGoal {
  id: number;
  balance: number;
  name: string;
  emoji: string;
  internal_name: string;
  priority: number;
  enabled: boolean;
}

export interface InternalTransaction {
  id: number;
  timestamp: number;
  to_goal: number;
  amount: number;
  date_timestamp: number;
  to_goal_name: string;
  profile: number;
  from_goal: null;
  transaction_status: DeviceTypeRaw;
  linked_real_transaction: number;
}

export interface TransactionInfo {
  balance?: number;
  bank_account_name?: string;
  bank_account_mask?: string;
}

export interface SavingsTransaction {
  id: number;
  profile: number;
  bank_account: number;
  savings_account: number;
  ach_partner_bank?: string | null;
  amount: number;
  timestamp: number;
  pending_timestamp: number | null;
  completed_timestamp: number | null;
  canceled_timestamp: number | null;
  failed_timestamp: number | null;
  transaction_type: string;
  transaction_status: string;
  transmission_type: string;
  transfer_interval: string | null;
  info: TransactionInfo;
  filename: string;
  account_routing_hash: any;
  is_referral_bonus?: boolean | null;
  referred_profile?: number | null;
  initiation_file?: string | null;
  ack_file?: string | null;
  return_file?: string | null;
  reversal_transaction?: number | null;
  universal_cash_transfer_id?: number | null;
  year_month: string;
  month: number;
  date_timestamp: number;
  merchant: string;
  transaction_class: string;
  category: string;
  in_progress: boolean;
  failed: boolean;
  timeline: ACHTransactionTimelineCheckpoint[];
  can_cancel: boolean;
  is_in_activity: boolean;
  activity_merchant: string;
  activity_image_name: string;
}

export interface ACHTransactionTimelineCheckpoint {
  title: string;
  body: string;
  date?: string;
  completed: boolean;
}

export interface Banner {
  type: NotificationTypes | string;
  message: string;
  bannerAction?: BannerActionType | null;
}

export interface CoastalSavingsStatement {
  id: number;
  savings_account: number;
  balance: number;
  created_timestamp: number;
  status: string;
  statement_file_name: string;
  timestamp: number;
}

// //////////////////////////////////
/* =========== PROFILE =========== */
// //////////////////////////////////
export interface InvestingAffiliations {
  no_affiliations_apply: boolean | null;
  is_politically_exposed: boolean | null;
  is_exchange_affiliated: boolean | null;
  is_control_person: boolean | null;
  us_citizen: boolean | null;
}
export interface Profile {
  text_opt_in: boolean;
  instant_info: Address;
  phone_to_update: string;
  features: Address;
  last_seen: string;
  investment_info: {
    signup_investing_interest?: boolean;
    time_horizon?: number;
    risk_tolerance?: number;
    investment_experience?: number;
  };
  push_alerts: boolean;
  push_savings: boolean;
  blacklist_phone: boolean;
  birthday: string;
  user_finished_signup_flow: boolean;
  support_team_notes: Address;
  is_support_agent: boolean;
  percent_complete: number;
  incoming_phone_number: string;
  pricing_data: PricingData;
  savings_method: string;
  occupation: string | null;
  employment: string;
  id: number;
  is_real: boolean;
  apiV2: boolean;
  investment_preferences: InvestmentPreferences;
  genius_enabled: boolean;
  assigned_phone_number: string;
  preferences: ProfilePreferences;
  personal_info: {
    device: string;
    web_signup_version_key: string;
    started_web_signup: boolean;
    web_app_version: string;
    web_signup_app_version: string;
    web_browser: string;
    is_banking_enabled: boolean;
    bonus_eligibility: string;
    text_verification: boolean;
    signup_source: string;
    married: boolean;
    kids: number;
    set_pin: boolean;
    saved_contact: boolean;
    signup_auto_savings: boolean;
    web_genius_version_key: string;
    annual_subscription_gift: number;
    annual_subscription_payment_id: number | null;
    finished_web_signup: boolean;
    credit_score?: string;
    email_verification?: boolean;
  };
  is_suspended: boolean;
  app_version: string;
  phone_number_data: Address;
  text_enabled: boolean;
  support_is_unread: boolean;
  device_type_raw: string;
  is_investing_enabled: boolean;
  previous_last_seen: string;
  deactivated_timestamp: number;
  is_referral_program_enabled: boolean;
  income: number;
  kyc_stage: string;
  email_to_update: string;
  username: string;
  is_superuser: boolean;
  referral_campaign: number;
  support_status: string;
  text_outreach: boolean;
  prompt_genius_payment: boolean;
  primary_linking_provider: string;
  is_spy: boolean;
  email_account_updates: boolean;
  phone: string;
  support_topic: string;
  is_email_verified: boolean;
  recurring_savings_amount: number;
  allow_linking_duplicate_accounts: boolean;
  groups: number[];
  agent_roles: number[];
  address: Address;
  genius_status: string;
  push_trends: boolean;
  fraud_status: string;
  campaign_card_priority: string;
  auto_saving: string;
  is_active: boolean;
  payment_frequency: string;
  messaging_service: string;
  last_refresh: number | string;
  billshark_info: Address;
  first_name: string;
  instant_preferences: InstantPreferences;
  email_features: boolean;
  last_name: string;
  deactivation_in_progress: boolean;
  registered: boolean;
  date_joined: number;
  is_instant_enabled: boolean;
  email: string;
  pause_rainy_day_fund: boolean;
  signup_app_version: string;
  balances_database: string;
  last_login: string | number;
  gender: string;
  support_agent: number;
  monthly_price: number;
  referring_profile: number;
  savings_halt: boolean;
  is_universal_cash?: boolean;
  is_drop_free_month_for_annual_available?: boolean;
  has_tiered_genius_pricing?: boolean;
  tiered_pricing: GeniusTieredPricing;
}

export interface ProfilePreferences {
  plaid_environment?: string;
  plaid_webhook_domain?: string;
}

export interface Address {
  state: LocationState;
  country: string;
  city: string;
  streetAddress: string[];
  postalCode: string;
}

export interface AddressPhysical {
  apt?: string;
  street?: string;
  city?: string;
  state?: string;
  zipcode?: string;
}

interface PricingScreen {
  button: string;
  internalName: string;
  title: string;
}

export interface PricingData {
  completed_onboarding: boolean;
  timestamp_first_payment_date: number;
  first_month_free: boolean;
  pricing_screen: PricingScreen;
  genius_timeline: Array<Array<number | string>>;
  number_months_free: number;
  signup_with_genius_version: string;
  annual_payment_amount: number;
  price_range: PriceRange;
  allow_annual_payment: boolean;
  default_price: number;
  enabled_genius_timestamp: number;
  signup_with_genius: boolean;
  pricing_option: string;
  signup_bonus: number;
  completed_genius_signup: boolean;
  error_enabling_annual_payment: string;
}

export interface InstantPreferences {
  debug: boolean;
  isManuallyEnabled: boolean;
}

export interface InvestmentPreferences {
  risk: string | null;
  experience: string | null;
  liquidity: string | null;
}

export enum LocationState {
  CA = 'CA',
  IL = 'IL',
  Ma = 'MA',
  Nv = 'NV',
  Ny = 'NY',
  Ok = 'OK',
  Tx = 'TX',
  Wa = 'WA',
}

export interface Property {
  id: string;
  profile: number;
  google_address: string;
  google_placeid: string;
  street: string;
  apt?: string;
  city: string;
  state: string;
  zipcode: string;
  own: boolean;
  is_primary: boolean;
  input_source: PropertySourceTypes;
  account_type: PropertyAccountType;
  account_category: PropertyAccountCategory;
  input_timestamp: number;
}

export enum PropertyAccountType {
  PROPERTY = 'property',
}
export enum PropertyAccountCategory {
  HOME = 'home',
}

// //////////////////////////////////
/* ======== INSTITUTION ========== */
// //////////////////////////////////
export enum AccountNotActiveReason {
  NO_ACTIVITY = 'na',
  ZERO_BALANCE = 'nb',
}

export interface Institution {
  pk: number;
  color_hex: string;
  enabled: boolean;
  id: number;
  rank: number;
  institution_id: string;
  common: boolean;
  has_auth: boolean;
  value: string;
  temporary_outage: boolean;
  info: InstitutionInfo;
  name: string;
  deprecated: boolean;
  provider: string;
  image_name: string;
  manual_exclude: boolean;
  login_fields: LoginField[];
  phone_number: null;
  primary_types: PrimaryTypes;
  create_timestamp: number;
  searchable: boolean;
  url: null;
  logo: string | null;
}

export interface InstitutionLogin {
  id: number;
  name: string;
  mothballed: boolean;
  error: null | { code: string | number; message: string };
  institution: Institution;
  info: InstitutionLoginInfo;
  profile: number;
  services: Service[];
  challenge: Record<string, any>;
  error_code: null;
  created_timestamp: number;
  retry_login_for_auth: boolean;
  sync_details: Record<string, any>;
  error_message: string | null;
  refresh_status: RefreshStatus;
  successful_refresh: number;
  error_dict: Record<string, any>;
  webhooks_enabled: boolean;
  attempted_refresh: number;
  status_code: null;
  last_refresh_type: DeviceTypeRaw;
  extra_data: InstitutionLoginExtraData;
  show_fix_connection: boolean;
  provider_id: string;
  provider_token: string;
  original_institution: null;
  connection_details: Record<string, any>;
  is_action_required: boolean;
  manual_verification_in_progress: boolean;
  verification_account_id: number | null;
}

export interface InstitutionAccount {
  id: number | string;
  is_primary_checking: boolean;
  account_id: string;
  ach_failure: boolean;
  ach_verification: string | null;
  manual_nickname: string;
  ach_verification_failed: boolean;
  // Whether account is in open or closed/deleted state
  active: boolean;
  balance: number;
  ach_verification_awaiting_manual: boolean;
  ach_verification_in_progress: boolean;
  albert_cash_account_id?: string;
  account_category: AccountCategory;
  account_display_number: string;
  ach_verification_message: string;
  account_type: AccountType;
  last_three_digits: string;
  is_manual: boolean;
  account_type_verbose: string;
  bank_name: string | null;
  historical_balances: HistoricalBalance[];
  ach_explanation: string;
  created_timestamp: number;
  last_four_digits: string;
  status_code: null;
  account_nickname: string;
  decommissioned: boolean;
  extra_data: AccountExtraData;
  has_account_routing: boolean;
  profile: number;
  is_auth_approved: boolean;
  institution_login: InstitutionLogin;
  short_name: string;
  has_completed_auth: boolean;
  manual_category: string;
  info: AccountInfo;
  balance_date: null;
  manual_type: null;
  ach_verification_required: boolean;
  is_albert_cash: boolean;
  // Whether account has recent transactions or balance > 0
  is_active: boolean | null;
  // Corresponds to is_active flag
  is_not_active_reason: AccountNotActiveReason | null;
}

interface LinkingVerificationBlockedLanguage {
  title: string;
  body: string;
  button: string;
}
export interface LinkingVerificationData {
  institution_login: InstitutionLogin;
  is_blocked: boolean;
  blocked_language: null | LinkingVerificationBlockedLanguage;
}

export interface HistoricalBalance {
  amount: number;
  timestamp: number;
}

export interface AccountExtraData {
  has_transactions: boolean;
  auth_completed?: number;
  last_transaction?: number;
  number_of_transactions_weekly?: number;
}

export interface AccountInfo {
  name: string;
  official_name: string;
  mask: string;
  account_id: string;
  match_to_existing: MatchToExisting;
  balances: Balances;
  type: string;
  subtype: string;
  credit_details?: CreditDetails;
}

export interface CreditDetails {
  minimum_payment_amount: number;
  account_id: string;
  aprs: Aprs;
  last_statement_date: null;
  last_payment_amount: number;
  last_statement_balance: number;
  last_payment_date: string;
  next_bill_due_date: string;
}

export interface Aprs {
  balance_transfers: BalanceTransfers;
  purchases: BalanceTransfers;
  cash_advances: BalanceTransfers;
}

export interface BalanceTransfers {
  interest_charge_amount: number;
  apr: number;
  balance_subject_to_apr: number;
}

export interface Balances {
  limit: number | null;
  current: number;
  available: number | null;
}

export interface MatchToExisting {
  match: string;
}

export enum Service {
  Auth = 'auth',
  CreditDetails = 'credit_details',
  Transactions = 'transactions',
}

export enum AccountsState {
  COMPLETED = 'COMPLETED',
  ERROR = 'ERROR',
  CHALLENGES = 'CHALLENGES',
}

export interface TransactionsClass {
  state: AccountsState;
}

export interface RefreshStatus {
  accounts: TransactionsClass;
  transactions: TransactionsClass;
  polling_id: string;
  transactions_webhook_count: number;
}

export interface InstitutionLoginInfo {
  webhook_url: string;
  accounts_auth_completed?: number;
}

export interface InstitutionLoginExtraData {
  transactions_webhook_state: string;
}

export interface InstitutionInfo {
  products: string[];
  background_color: string;
  mfa: string[];
  has_mfa: boolean;
}

export interface LoginField {
  name: NameEnum;
  label: LabelEnum;
  type: LoginFieldType;
}

export enum LabelEnum {
  OnlineID = 'Online ID',
  Password = 'Password',
  UserID = 'User ID',
}

export enum NameEnum {
  Password = 'password',
  Username = 'username',
}

export enum LoginFieldType {
  Password = 'password',
  Text = 'text',
}

export enum ListType {
  NumberList = 'numbered-list',
  TextList = 'text-list',
  List = 'list',
}

export interface PrimaryTypes {
  account_types: AccountTypeShort[];
  account_types_full_screen: AccountTypeShort[];
}

export enum DeviceTypeRaw {
  B = 'b',
  C = 'c',
  I = 'i',
  L = 'l',
}

export enum OnboardingStatus {
  INITIATED = 'i',
  AGREED_TO_TERMS = 't',
  SELECTED_DEBIT_CARD_STYLE = 'd',
  IDOLOGY_VERIFICATION_SUBMITTED = 's',
  IDOLOGY_VERIFICATION_PENDING_MFA = 'm',
  IDOLOGY_VERIFICATION_FAILED = 'x',
  GALILEO_APPLICATION_QUEUED = 'q',
  GALILEO_APPLICATION_SUBMITTED = 'g',
  COMPLETED = 'c',
}

// //////////////////////////////////
/* ========== Banking ============ */
// //////////////////////////////////
export interface BankingAppData {
  account: BankingAccount;
  common: BankingCommonData;
  linked_accounts: BankingLinkedAccount[];
  transactions: BankingTransaction[];
  debit_card: BankingDebitCard;
  cardholder_name: CardholderName;
  statements: BankingStatement[];
}

export enum PhysicalCardOptInSource {
  SIGNUP = 's',
  INSTANT = 'i',
}

export interface BankingAccount {
  id: number;
  profile_id: number;
  onboarding_status: OnboardingStatus;
  closing_status: string | null;
  closed_timestamp: number | null;
  agreed_to_terms_timestamp: number | null;
  debit_card_style: DebitCardStyle;
  physical_card_opt_in: boolean;
  physical_card_opt_in_source: PhysicalCardOptInSource;
  direct_deposit_status: string | null;
  documents: BankingAccountDocuments;
  notes: any;
  card_present_block: boolean;
  galileo_status: string;
  galileo_express_mail: any;
  galileo_feature_block_card_present: any;
  created_timestamp: number;
  last_refresh_timestamp: number | null;
  urbanft_user_id: number | null;
  urbanft_username: string | null;
  urbanft_account_nick_name: string | null;
  check_provider_account_id: number | null;
  dda_upgrade_timestamp: number | null;
  is_active: boolean;
  bank_account_status: string;
  is_onboarding_complete: boolean;
  is_dda: boolean;
  is_direct_deposit_enabled: boolean;
  active: boolean;
  is_albert_cash: boolean;
  has_direct_deposit: boolean;
  account_type: string;
  account_category: string;
  account_nickname: string;
  balance_available: number;
  routing_number: string;
  account_number?: string;
  account_number_hashed: string;
  ach_settlement_date?: number;
}

export interface BankingCommonData {
  check_deposit_limit: number;
  debit_card_availability: DebitCardAvailability[];
  charge_for_debit_card: boolean;
}

export interface BankingLinkedAccount {
  id: number;
  bank_account: number;
  linked_account_id: number;
}

export interface BankingAccountDocuments {
  cardholder_agreement?: string;
  fee_schedule?: string;
  detail?: string;
}

export interface BankingTransaction {
  // The string transaction id, e.g. "albert_cash_11"
  id: string;
  // The numeric transaction id, e.g. 11 -- use this (pk) for api calls
  pk: number;
  external_id: string;
  bank_account_id: number;
  profile_id: number | null;
  debit_card: number | null;
  ach_account: number | null;
  status: string;
  type: string;
  amount: number;
  amount_authorized: number | null;
  created_balance: number | null;
  completed_balance: number | null;
  raw_merchant: string;
  merchant_location: string | null;
  merchant_id: string | null;
  merchant_category_code: string | null;
  ach_account_name: string | null;
  ach_account_details: any;
  ach_return_code: any;
  ach_effective_timestamp: number | null;
  external_transaction_id: string | null;
  network: string | null;
  galileo_auth_id: number | null;
  galileo_transaction_id: number | null;
  galileo_product_id: number | null;
  galileo_program_id: number | null;
  galileo_activity_type: string | null;
  galileo_settlement_type: string | null;
  created_timestamp: number;
  completed_timestamp: number | null;
  returned_timestamp: number | null;
  failed_timestamp: number | null;
  posted_timestamp: number | null;
  bank_transaction_info_id: number;
  fee_bank_transaction_id: number | null;
  reward_bank_transaction_id: number | null;
  activated_reward: number | null;
  payment_transaction_id: number | null;
  dispute: number | null;
  subtype: string | null;
  deposit_recipient_name: string | null;
  notes: string | null;
  interchange_fee_amount: number | null;
  related_bank_transaction_id: number | null;
  rejection_reason_code: string | null;
  is_passthrough: boolean | null;
  universal_cash_transfer_id: string | null;
  investing_cash_transaction_id: number | null;
  universal_cash_transfer_status: string | null;
  ach_delay_days: number | null;
  ach_delay_reason: string | null;
  can_dispute: boolean;
  can_cancel: boolean;
  pending: boolean;
  is_sent_check: boolean;
  is_deposited_check: boolean;
  external_id_short: string;
  account_id: number;
  timestamp: number;
  date_timestamp: number;
  merchant: string;
  category: string;
  manual_merchant: any;
  clean_merchant: any;
  direct_deposit: any;
  exclude: boolean;
  is_income: any;
  is_fee: boolean;
  is_bill: any;
  is_savings: any;
  additional_info: any;
  is_mobile_check_deposit: boolean;
  account_type: string;
  image: any;
  is_real: boolean;
  is_manual: boolean;
  is_international: boolean | null;
  is_unemployment_benefit?: boolean | null;
  is_unemployment_dd?: boolean | null;
  digital_wallet_token_type: any;
  location: {
    address?: string;
    city?: string;
    state?: string;
    lat?: any;
    lon?: any;
    store_number?: number;
    zip?: string | number;
  };
  is_banking: boolean;
  recurring: boolean;
  stamp_label: { color: string; text: string } | null;
  status_logs: any;
  status_message?: string | null;
  progress: ACHTransactionTimelineCheckpoint[];
}

export interface BankingDebitCard {
  id: number;
  bank_account: number;
  style: DebitCardStyle;
  shipping_status: ShippingStatus | null;
  shipping_delivery_date: number | null;
  is_active: boolean;
  is_activated: boolean;
}

export type CardholderName = {
  [key in DebitCardStyle]: {
    truncated_first_name: string;
    truncated_last_name: string;
  };
};

export interface AccountDropdownDetails {
  id: string;
  imgSrc: string;
  title: string | React.ReactElement;
  shortName?: string;
  subtitle?: string | React.ReactElement;
  amount?: string | React.ReactElement;
  balance?: number;
  error?: string | React.ReactElement;
  hasError?: boolean;
  pending?: boolean;
  isDefault?: boolean;
  deactivated?: boolean;
  isCash?: boolean;
  isAlbert?: boolean;
  category?: string;
  achError?: boolean;
}
export interface TransactionDetails {
  id: string;
  imgSrc: string;
  title: string | React.ReactElement;
  subtitle?: string | React.ReactElement;
  amount: string | React.ReactElement;
  hasError?: boolean;
  disabled?: boolean;
  isDefault?: boolean;
}

export interface DebitCardAvailability {
  available: boolean;
  modal: {
    title: string;
    body: string;
    button: string;
  };
  style: DebitCardStyle;
  locked: boolean;
  price: number;
  price_display: string;
}

export interface BankingStatement {
  id: number;
  bank_account: number;
  profile_id: number;
  balance: number;
  timestamp: number;
  created_timestamp: number;
  statement_file_name: string;
  status: string;
  is_adhoc: boolean;
}

// ////////////////////////////////
/* ========== INVESTING ========== */
// ////////////////////////////////
export interface InvestingData {
  has_albert_investing: boolean;
  fund_data: Record<string, any>;
  profile_data: Record<string, any>;
  checkpoint: string | null;
  is_kyc_complete: boolean;
  has_had_account: boolean;
  app_notifications: any[];
  documents: InvestingDocument[];
}

export interface InvestingTransaction {
  id: number | string;
  profile_id: number;
  account_id?: number;
  investment_account: number;
  investment: number;
  security_bulk_trade: number | null;
  cash_transaction: number | null;
  dividend_transaction: number | null;
  amount: number;
  created_timestamp: number;
  liquidate_all: boolean;
  status: string;
  previous_cash_drag: number | null;
  cash_drag: number | null;
  transaction_type: string;
  info: any;
  is_real_time: string | null;
  is_not_yet_submitted: boolean;
  is_not_cancelled: boolean;
  can_cancel_transaction: boolean;
  in_progress: boolean;
  is_submitted: boolean;
  profile: number;
  app_display_details: {
    details: InvestingTransactionTimelineCheckpoint[];
    stamp_label_details?: { color?: string; text_app?: string };
  };
  display_title: string;
}

export interface DividendTransaction {
  id: number | string;
  investment_id?: number;
  profile_id: number;
  investment_account: number;
  security: number;
  amount: number;
  exchange_date: number;
  record_date: number;
  pay_date: number;
  created_timestamp: number;
  info: any;
  is_verified: boolean;
  display_title: string;
  is_in_activity: boolean;
  activity_display_title: string;
  activity_image_name: string;
  profile: number;
  app_display_details: {
    details: InvestingTransactionTimelineCheckpoint[];
  };
}

export interface InvestingCashTransaction {
  id: number | string;
  investment_id?: number;
  profile_id: number;
  account_id: number | null;
  investment_account: number;
  amount: number;
  created_timestamp: number;
  transaction_status: string;
  // Same field, used to match InvestingTransaction interface
  status?: string;
  transaction_status_event_timestamp: number | null;
  transaction_type: string;
  submission_type: string;
  info: TransactionInfo;
  external_transaction_id: string;
  transaction_id: string;
  return_reason_code: string;
  is_albert_cash: boolean | null;
  is_universal_cash: boolean | null;
  waiting_for_cash_timestamp: boolean | null;
  source_name: string;
  universal_cash_transfer_id: any;
  in_progress: boolean;
  display_title: string;
  activity_display_title: string;
  can_cancel_transaction: boolean;
  withdraw_cutoff_timestamp: number | null;
  activity_image_name: string;
  is_in_activity: boolean;
  profile: number;
  account: any;
  can_trade: boolean;
  can_withdraw: boolean;
  holding_period_finished: boolean;
  app_display_details: {
    details: InvestingTransactionTimelineCheckpoint[];
  };
}

export interface SecurityTrade {
  id: number | string;
  security: number;
  security_bulk_trade: number | null;
  status: string;
  side: string;
  allocated_quantity: number | null;
  executed_price: number | null;
  executed_timestamp: number | null;
  submitted_timestamp: number | null;
  internal_transaction_id: string | null;
  transaction_id: string | null;
  transaction_data: any;
  transaction_response: any;
  info: any;
  profile: number;
  investment_account: number;
  investment_transaction: number | null;
  bundle_info: any;
  request_id: string | null;
  trade_type: string;
  trading_platform: string;
  requested_quantity: number | null;
  submitted_price: number | null;
  created_timestamp: number;
  status_event_timestamp: number | null;
  apex_principal_amount: number | null;
  apex_net_amount: number | null;
  apex_fees_amount: number | null;
  apex_fees_details: any;
  apex_confirm_received: boolean;
  apex_confirm_date: number | null;
  is_standalone: boolean;
  standalone: boolean | null;
  investment: number;
  cash_transaction: number | null;
  amount: number;
  liquidate_all: boolean;
  transaction_type: string;
  is_incomplete: boolean;
  can_cancel_transaction: boolean;
  in_progress: boolean;
  is_not_yet_submitted: boolean;
  is_not_cancelled: boolean;
  is_submitted: boolean;
  is_settled_for_sale: boolean;
  is_settled_to_withdraw: boolean;
  is_settled_to_invest: boolean;
  is_settled_at_custodian: boolean;
  app_display_details: {
    details: InvestingTransactionTimelineCheckpoint[];
    stamp_label_details: { color?: string; text_app?: string };
  };
  display_title: string;
}

export interface InvestingTransactionTimelineCheckpoint {
  status: string;
  message: string;
  date_string: string | null;
  completion: boolean;
}

export interface InvestingDocument {
  doc_id?: string;
  date: string;
  url: string;
  type: InvestingDocumentType;
  name: string;
  timestamp: number;
}

export enum InvestingDocumentType {
  STATEMENT = 'STATEMENT',
  CONFIRM = 'CONFIRM',
  TAX = 'TAX',
}

// //////////////////////////////////
/* ============= KYC ============= */
// //////////////////////////////////

export interface KYCAppData {
  kyc_passed: boolean;
  can_kyc: boolean;
  is_universal_kyc_complete: boolean;
  show_persona_verification: boolean;
  products: {
    [product: string]: boolean;
  };
}

export interface SignupPersona {
  persona_template_id: string;
  ongoing_persona_session_token: string;
  ongoing_persona_inquiry_id: string;
  failed_persona_verification: boolean;
}

// //////////////////////////////////
/* ============ PLAID ============ */
// //////////////////////////////////
export interface PlaidInstitution {
  name: string;
  institution_id: string;
}

export interface PlaidInstitutionAccount {
  id: string;
  name: string;
  mask: string;
  type: string;
  subtype: string;
  verification_status: string;
}

// //////////////////////////////////
/* ============ ATOMIC =========== */
// //////////////////////////////////
export interface AtomicDirectDepositLinkData {
  taskId: string;
}

// //////////////////////////////////
/* ========== TRACKING =========== */
// //////////////////////////////////
export interface TrackKwargs {
  event: string;
  subEvent?: string;
  trackSocial?: boolean;
  trackToAlbert?: boolean;
  purchaseAmount?: number;
  trackOnce?: boolean;
  info?: Record<string, any>;
  containsSensitiveInfo?: boolean;
}

// //////////////////////////////////
/* ========== LANGUAGE =========== */
// //////////////////////////////////

export type Language = {
  [pageName: string]: PageLanguage;
} & { tour?: TourElement[] } & { pricing?: PricingElement };

export interface PageLanguage {
  route: string;
  info: LanguageInfo;
  altInfo: LanguageInfo;
  content: LanguageContent;
  modal?: LanguageModal;
  buttons: LanguageButtons;
}

export interface TourElement {
  image: { filename: string; width: number };
  header: string;
  text: string;
  faq: FaqElement;
  faqKey: string;
  disclaimer?: string;
}

export interface PriceRange {
  internal_name: string;
  type: string;
  values: number[];
  default_price: number;
  slider_values: number[];
  label: {
    price: number;
    text: string;
  };
}

export type PricingElementFeatures = Record<
  string,
  { index: number; name: string; included: boolean }[]
>;

export interface PricingElement {
  priceRange: PriceRange;
  annualBonus: {
    [key: number]: number;
  };
  range: number[];
  default: number;
  numberMonthsFree: number;
  features?: PricingElementFeatures;
  details?: {
    default_annual: boolean;
  };
  show_drawer?: boolean;
  show_long_disclosure?: boolean;
}
export interface FaqElement {
  image: { filename: string; width: number };
  items: FaqItem[];
}

export interface FaqItem {
  header: string;
  text: string;
  image?: { filename: string; width: number };
  list?: string[];
  disclaimer?: string;
  disclaimer_url?: string;
}

export type SignupFlags =
  | 'hasGeniusMobileUpdate'
  | 'showKYCInvestingAgreement'
  | 'showKYCCreditAgreement'
  | 'hideKYCEsignAgreement'
  | 'showPersonaFlow'
  | 'setUpAlbertV2'
  | 'hideProgressBar'
  | 'pfm';

export type SignupLanguage = Language & {
  web_signup_version_key?: string;
  flags?: Record<SignupFlags, boolean>;
  order: LanguageSubflow[];
};

export type KYCLanguage = Language;

export type GeniusLanguage = Language;

export interface LanguageContent {
  [sectionName: string]: LanguageSection;
}

export interface TextContent {
  title: string;
  content: string;
}

export type ComposedDisclosure = {
  disclosure_url: string;
  title: string;
};

export interface LanguageSection {
  header?: string;
  textImage?: string;
  subheader?: string;
  trailingHeaderText?: string;
  text?: string;
  alt_text?: string;
  default_text?: string;
  input?: boolean;
  link?: string;
  disclaimer?: string;
  linkLabel?: string;
  placeholder?: string;
  placeholder_1?: string;
  placeholder_2?: string;
  errorMessage?: string;
  bonus?: string;
  image?: { filename: string; width: number };
  altImage?: string;
  options?: ({ [key: string]: string | boolean | number } | string | number)[];
  list?: LanguageList;
  modal?: LanguageContentModal;
  primary?: string;
  secondary?: string;
  labelText?: string;
  composed_disclosure?: ComposedDisclosure[];
}

export interface LanguageSubflow {
  flow: string;
  route: string;
  pages: Pages[];
}

export interface LottieContext {
  filename: string;
}

export interface LanguageContentModal {
  header?: string;
  text?: string;
  primary?: string;
  secondary?: string;
}

export interface LanguageInfo {
  labelText?: string;
  lottie?: LottieContext;
  image?: ImageContext;
  header?: string;
  altHeader?: string;
  text?: string | string[];
  expanders?: LanguageExpander[];
  list?: LanguageInfoList;
  disclaimer?: string;
  // Genius
  annualText?: string;
  monthlyText?: string;
  dueDate?: string;
  // Cash
  showCard?: boolean;
  // Enable conditional info
  records?: Record<string, LanguageInfo>;
}

export interface ImageContext {
  filename: string;
  width: number;
  minWidth?: string;
  alt?: string;
}

export interface LanguageExpander {
  header: string;
  content: {
    defaultOpen?: boolean;
    type: ListType;
    altText?: boolean;
    text?: string;
    items?: LanguageList;
  };
}

export interface LanguageInfoList {
  type: ListType;
  altText?: boolean;
  items?: LanguageList;
}

export type LanguageList = LanguageListItem[];

export interface LanguageListItem {
  id?: string | number; // TODO check this
  header: string;
  text: string;
  icon?: string;
  iconWidth?: number;
  errorMessage?: string;
  imageBottom?: {
    filename: string;
    width: number;
  };
  items?: LanguageList;
}

export interface LanguageModal {
  [sectionName: string]: ModalSection;
}

export interface ModalSection {
  header: string;
  image: ImageContext;
  description: string;
  cancelLabel: string;
  submitLabel: string;
}

export interface LanguageButtons {
  primary?: string;
  primaryAlt?: string;
  primaryCharge?: string;
  complete?: string;
  link?: string;
  linkTooltip?: string;
  disclaimer?: string;
  secondary?: string;
}

export type InputFieldProps = FieldRenderProps<string | boolean, HTMLElement>;
export interface InputLabelProps {
  label?: string | React.ReactNode;
  smallLabel?: string | React.ReactNode;
  smallLabelNewLine?: boolean;
  description?: string | React.ReactNode;
  hideLabelOnMobile?: boolean;
  hideDescriptionOnMobile?: boolean;
  noMargin?: boolean;
  secondaryLabel?: string;
}

type InputRefProp = React.RefObject<HTMLInputElement> &
  React.RefObject<HTMLInputElement[]>;

export interface BaseInputProps extends InputLabelProps {
  /* a unique ID for the ID */
  id: string;
  /* the state of the input field */
  disabled?: boolean | false;
  /* placeholder for the input field */
  placeholder?: string;
  /* floating label to display for the input */
  floatingLabel?: string;
  /* error text to display for invalid input */
  errorText?: string;
  /* whether the input is invalid */
  invalid?: boolean;
  /* text value for the component */
  defaultValue?: string;
  /* text value for the component */
  valueDefault?: string | number;
  /* whether this field is required */
  required?: boolean | false;
  /* whether to change auto-capitalization rules for virtual and mobile keyboards */
  autoCapitalize?: AutoCapitalize;
  /* enable/disable autocomplete */
  autoComplete?: 'on' | 'off';
  /* input prop coming from final-form */
  input?: Pick<InputFieldProps, 'input'>;
  /* input mode */
  inputMode?:
    | 'search'
    | 'none'
    | 'text'
    | 'tel'
    | 'url'
    | 'email'
    | 'numeric'
    | 'decimal';
  /* pattern allowed for input */
  pattern?: string;
  /* custom width */
  widthSize?: InputWidth;
  /* ref for the component */
  ref?: InputRefProp;
  /* the theme to use for this component */
  theme?: any;
  /* custom validator function */
  validator?: (...args: any) => void;
  /* onChange handler function */
  onChange?: (...args: any) => void;
  /* onBlur handler function */
  onBlur?: (...args: any) => void;
  /* onKey handler function */
  onKeyDown?: (...args: any) => void;
  /* height of input */
  height?: string;
  autocomplete?: string;
}

export interface BaseModalProps {
  /* unique identifier */
  id?: string;
  /* whether to show or hide the modal */
  show: boolean | false;
  /* the theme to use for this component */
  theme?: any;
  /* assuming a button could contain icons and other elements */
  children?: React.ReactNode | React.ReactNode[];
  /* the action to handle when canceling */
  onCancel?: (...args: any[]) => void;
}
export interface PollPlaidTransactionsArgs {
  institutionLoginId?: number;
}

export interface PollBankAccountAuthArgs {
  accountId: number;
  dispatch: Dispatch;
  completionHandler: (...args: any) => void;
  completionHandlerArgs: any;
}

export interface PollPlaidLinkArgs {
  pollingId: string;
  institutionId: string;
  successHandler: (...args: any) => void;
  errorHandler: (...args: any) => void;
  isActiveCheckingRequired: boolean;
}

export interface DropdownItem {
  value: string | number;
  subValue?: string | null;
}

export interface InfluencerOption {
  id: number;
  display_name: string;
  channel: string;
}

export type EmptyObject = Record<string, never>;

// Declare window.Plaid object
declare global {
  interface Window {
    Atomic: any;
    Plaid: any;
    branch: any;
    store: any;
    Cypress: any;
    production: boolean;
  }
}

export interface WindowEventHandler {
  preventDefault(): void;
  returnValue: string;
}

export interface GeniusPayments {
  amount: number;
  bank_account: any | null;
  bin_id: number | null;
  id: number;
  info: any;
  instant_daily_report: any;
  interchange_fee: number | null;
  is_status_okay: boolean;
  network_fee: number | null;
  network_id: number | null;
  profile: number;
  reference_id: string;
  return_reason_code: any;
  reversal_transaction: any;
  tabapay_exception_fee: number | null;
  tabapay_exception_info: any;
  tabapay_fee: number | null;
  tabapay_response_code: any;
  timestamp: number;
  transaction_status: string;
  transaction_token: any;
  transaction_type: string;
  transmission_type: string;
  description: string;
}

export type GeniusTier = {
  features: string[];
  monthly_price: number;
  name: string;
  rank: number;
  renewal_monthly_price: string | null;
  tier_key: string;
};

export type GeniusTieredPricing = {
  active_tier: string | null;
  default_tier: string;
  has_renewed: boolean;
  program_key: string;
  renewal_tier: string | null;
  tiers: GeniusTier[];
};

export enum AutoSaving {
  ON = 'o',
  OFF = 'f',
  PENDING = 'n',
  PAUSED = 'p',
}
