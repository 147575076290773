/* eslint-disable */
/* 2021/03/16, MPR: taken directly from MC repo https://github.com/meetalbert/albert-mission-control/blob/5abca37790cd4f0a05cd3f1096e5690e0a2fa433/src/lib/utils/hash.ts */
/**
 * Convert a string into a hashcode.
 * https://werxltd.com/wp/2010/05/13/javascript-implementation-of-javas-string-hashcode-method/
 */
export function hash32(str: string): number {
  let hash = 0;
  if (str.length === 0) return hash;
  for (let i = 0; i < str.length; i += 1) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash |= 0; // Convert to 32bit integer
    hash >>>= 0;
  }
  return hash;
}
