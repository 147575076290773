import * as React from 'react';
import ReactMarkdown from 'react-markdown';
import Spacer from 'components/common/Spacer';
import Text, { TextSizes } from 'components/common/Text';
import { spacers } from 'styles/home';
import { LanguageExpander, LanguageListItem } from 'types/interfaces';
import { hash32 } from 'utils/hash';
import Modal from './index';

type Props = {
  expanders: LanguageExpander[];
  show: boolean | false;
  onCancel: (...args: any[]) => void;
};

type InfoList = {
  header: string;
  textList: string[];
};

/**
 * Expanders can return a collection of headers and texts in
 * a couple of different formats.
 */
const normalizeExpanders = (expanders: LanguageExpander[]): Array<InfoList> => {
  const infoList: Array<InfoList> = [];
  for (const expander of expanders) {
    if (
      !Array.isArray(expander?.content?.items) ||
      expander.content.items.length === 0
    ) {
      continue;
    }
    if (expander.content.items[0].header) {
      // If each item has its own header, push each item to the list.
      for (const item of expander.content.items) {
        infoList.push({
          header: item.header,
          textList: new Array(item.text),
        });
      }
    } else {
      // If each item does not have its own header, then push a single
      // entry to the list that contains the top-level header and the
      // text from all the items.
      const textList = expander.content.items.reduce(
        (acc: string[], curr: LanguageListItem) => {
          acc.push(curr.text);
          return acc;
        },
        []
      );
      infoList.push({
        header: expander.header,
        textList,
      });
    }
  }
  return infoList;
};

const MarkdownLink = ({ href, children, ...props }: any) => {
  const cleanedHref = href.replace('&amp;', '&');
  return (
    <a href={cleanedHref} {...props} target='_blank' rel='noopener noreferrer'>
      {children}
    </a>
  );
};

/**
 * Render info expander data in a modal
 */
export const MobileInfoModal = ({
  expanders,
  show,
  onCancel,
}: Props): React.ReactElement => {
  const infoList = normalizeExpanders(expanders);
  return (
    <Modal.Basic title='' show={show} onCancel={onCancel}>
      {infoList.map((info, i) => (
        <React.Fragment key={`expander-${hash32(info.header || '')}`}>
          <Text size={TextSizes.MEDIUM} weight='700'>
            {info.header}
          </Text>
          {info.textList.map((text) => (
            <React.Fragment key={`expander-${hash32(text || '')}`}>
              <ReactMarkdown components={{ a: MarkdownLink }}>
                {text}
              </ReactMarkdown>
            </React.Fragment>
          ))}
          {i < infoList?.length - 1 && <Spacer space={spacers.g3} />}
        </React.Fragment>
      ))}
    </Modal.Basic>
  );
};

export default MobileInfoModal;
