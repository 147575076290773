import * as React from 'react';
import styled from 'styled-components';
import Spacer from 'components/common/Spacer';
import Text, { TextColor } from 'components/common/Text';
import { breakpoints, fontSizes, mixins, spacers } from 'styles';
import { ListType } from 'types/interfaces';

type Props = {
  /* unique id of the list item */
  id?: string;
  /* header text */
  header: string | React.ReactNode;
  /* text paragraph */
  text: string | React.ReactNode;
  /* increases spacing under heading */
  addExpanderSubheadSpacing: boolean;
  /* optional icon to display next to item */
  icon?: React.ReactNode;
  /* optional image to display at the bottom of text */
  imageBottom?: React.ReactNode;
  /* the type of the list */
  type: ListType;
  /* the paragraph text color to use */
  textColor?: TextColor | string;
  /* the size of text */
  textSize?: string;
  /* spacing between items */
  itemSpacing?: string;
};

const getListItemMargin = (type: ListType) => {
  switch (type) {
    case ListType.NumberList:
      return mixins.pxToRem('20px');
    case ListType.List:
      return mixins.pxToRem('75px');
    default:
      return '0';
  }
};

const StyledListItem = styled.div<{ itemSpacing?: string }>`
  margin-top: 12px;
  display: flex;
  &:not(:last-child) {
    margin-bottom: ${({ itemSpacing }) => itemSpacing || spacers.tabSmall};
  }
`;

const StyledHeader = styled(Text)`
  ${fontSizes.fontSize16}
  margin-bottom: ${mixins.pxToRem('5px')};
  @media ${breakpoints.mobileLarge} {
    margin-bottom: 0;
  }
`;

const StyledText = styled(Text)<{ header?: React.ReactNode }>`
  white-space: pre-wrap;

  p {
    ${({ header }) => !header && 'margin-top: 0;'}
  }
`;

const StyledIcon = styled.div`
  width: ${mixins.pxToRem('15px')};
  height: ${mixins.pxToRem('15px')};
`;

const ListItemWrapper = styled.div<Props>`
  margin-left: ${({ type, icon }) => (icon ? getListItemMargin(type) : '0')};
  @media ${breakpoints.mobileLarge} {
    margin-left: ${({ icon }) => (icon ? mixins.pxToRem('50px') : '0')};
  }
`;

const ListItem = (props: Props): React.ReactElement => {
  const {
    header,
    type,
    text,
    icon,
    imageBottom,
    textColor,
    textSize,
    addExpanderSubheadSpacing,
    itemSpacing,
  } = props;

  return (
    <StyledListItem itemSpacing={itemSpacing}>
      {type && type !== ListType.TextList && <StyledIcon>{icon}</StyledIcon>}
      <ListItemWrapper {...props}>
        {header && <StyledHeader weight='700'>{header}</StyledHeader>}
        {header && addExpanderSubheadSpacing && <Spacer space={spacers.tiny} />}
        <StyledText color={textColor} size={textSize} header={header}>
          {text}
        </StyledText>
        {imageBottom}
      </ListItemWrapper>
    </StyledListItem>
  );
};

export default ListItem;
