/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import updateProfile from 'actions/async/updateProfile';
import removeBanner from 'actions/banner/removeBanner';
import getNextPage from 'actions/getNextPage';
import TopLoadingBar from 'components/TopLoadingBar';
import Button from 'components/common/Button';
import List from 'components/common/List';
import Text, { TextColor, TextSizes } from 'components/common/Text';
import ButtonsLayout from 'components/layout/ButtonsLayout';
import LeadContent from 'components/layout/LeadContent';
import PageBase from 'components/layout/PageBase';
import {
  LinkAccountCompleteLocationState,
  LinkAccountSkipLocationState,
} from 'components/linkAccount/LinkAccountFlow';
import Pages from 'constants/Pages';
import { PageRoutes } from 'constants/index';
import useNavigateFunction from 'hooks/useNavigateFunction';
import useVerifyCurrentPage from 'hooks/useVerifyCurrentPage';
import BudgetingNoted from 'pages/signup/BudgetingNoted';
import * as language from 'reducers/entities/language';
import { spacers as homeSpacers } from 'styles/home';
import * as WEB from 'types/interfaces';
import { getLanguageButtons, getLanguageSection } from 'utils/getFromLanguage';

type Props = {
  nextPage: string;
};

type LocationState =
  | (LinkAccountCompleteLocationState & LinkAccountSkipLocationState)
  | WEB.EmptyObject;

const BudgetingBenefits = ({ nextPage }: Props): React.ReactElement => {
  const { updateCurrentSignupPage } = useNavigateFunction();
  // Make Redux is up-to-date with currentSignUpPage
  useVerifyCurrentPage(Pages.BUDGETING_BENEFITS);

  // ///////////////////////////////
  /* =========== HOOKS ========== */
  // ///////////////////////////////
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const locationState = (location?.state ?? {}) as LocationState;

  // ///////////////////////////////
  /* =========== STATE ========== */
  // ///////////////////////////////
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [showConfirmPage, setShowConfirmPage] = React.useState<boolean>(
    // If user just exited the Link Account flow, then initialize to show the SubscriptionNoted page.
    !!locationState.showConfirmation
  );
  const [showLoadingBar, setShowLoadingBar] = React.useState<boolean>(false);

  React.useEffect(() => {
    // Trigger a render whenever showConfirmation changes.
    // Otherwise, when navigating backwards from SubscriptionNoted,
    // the url changes correctly but the app still displays SubscriptionNoted.
    // Show the confirm page if user skipped link account flow.
    setShowConfirmPage(
      !!locationState.showConfirmation || !!locationState.skippedLinkAccount
    );
  }, [locationState.showConfirmation, locationState.skippedLinkAccount]);

  // ///////////////////////////////
  /* ======== MAPPED STATE ====== */
  // ///////////////////////////////
  const languageCtx = useSelector((state: WEB.RootState) =>
    language.getSignupPageLanguage(state, Pages.BUDGETING_BENEFITS)
  );

  // //////////////////////////////////
  /* =========== HANDLERS ========== */
  // //////////////////////////////////
  const handleOnLater = async (): Promise<void> => {
    // Do nothing if a request is underway
    if (isSubmitting) return;
    // Remove any error banner from previous attempts
    dispatch(removeBanner());

    setShowLoadingBar(true);
    const { error }: any = await dispatch(
      updateProfile({
        personal_info_signup_budgeting_interest: false,
      })
    );
    setShowLoadingBar(false);

    if (!error) {
      const { error: nextPageError, nextPageOverride }: any = await dispatch(
        getNextPage()
      );
      setShowLoadingBar(false);
      if (!nextPageError) {
        dispatch(
          updateCurrentSignupPage({ page: nextPageOverride ?? nextPage })
        );
      }
    }
  };

  const handleOnNext = async (): Promise<void> => {
    // Remove any error banner from previous attempts
    dispatch(removeBanner());

    setIsSubmitting(true);
    const { error }: any = await dispatch(
      updateProfile({
        personal_info_signup_budgeting_interest: true,
      })
    );
    setIsSubmitting(false);

    if (!error) {
      // Navigate to the Link Account flow for the user to link their bank account with Albert.
      navigate(PageRoutes.LINK_ACCOUNT, {
        state: {
          pageOnComplete: Pages.BUDGETING_BENEFITS,
          pageOnSkip: nextPage,
          currentPage: Pages.BUDGETING_BENEFITS,
        },
      });
    }
  };

  // //////////////////////////////////
  /* =========== LANGUAGE ========== */
  // //////////////////////////////////
  const INTRO_KEY = 'intro';
  const BENEFITS_KEY = 'benefits';

  const introSection = getLanguageSection(languageCtx, INTRO_KEY);
  const benefitsSection = getLanguageSection(languageCtx, BENEFITS_KEY);
  const buttonsContext = getLanguageButtons(languageCtx);

  const budgetingBenefitsPage = (
    <>
      <PageBase>
        <LeadContent header={introSection?.header} text={introSection?.text} />
        <List
          items={benefitsSection?.list ?? []}
          type={WEB.ListType.List}
          itemSpacing={homeSpacers.g10}
          textColor={TextColor.GRAY_DARK}
          textSize={TextSizes.MEDIUM}
        />
        <ButtonsLayout
          marginTop={homeSpacers.g9}
          primaryButton={
            <Button
              isLoading={isSubmitting}
              disabled={isSubmitting}
              onClick={handleOnNext}
            >
              {buttonsContext?.primary}
            </Button>
          }
          secondaryButton={
            <Text
              weight='700'
              size='small'
              onClick={handleOnLater}
              isLinkButton
            >
              <u>{buttonsContext?.link}</u>
            </Text>
          }
        />
      </PageBase>
    </>
  );

  return (
    <>
      {showLoadingBar && <TopLoadingBar isLoading={isSubmitting} />}
      {showConfirmPage ? (
        <BudgetingNoted nextPage={nextPage} />
      ) : (
        budgetingBenefitsPage
      )}
    </>
  );
};

export default BudgetingBenefits;
