import * as React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import addBanner from 'actions/banner/addBanner';
import AlbertClient from 'api/AlbertClient';
import ErrorMessages from 'constants/ErrorMessages';
import NotificationTypes from 'constants/NotificationTypes';
import { colors, fontSizes, mixins } from 'styles';
import { AddressPhysical, DropdownItem } from 'types/interfaces';
import { logger } from 'utils/logger';
import wrappedFetch from 'utils/wrappedFetch';
import Typeahead, {
  Props as BaseTypeaheadDropdownProps,
  Option,
} from './TypeaheadDropdown';

/* from https://developers.google.com/maps/documentation/places/web-service/supported_types */
const GOOGLE_PLACES_ADDRESS_SEGMENT = {
  NUMBER: 'street_number',
  STREET: 'route',
  CITY: 'locality',
  ALT_CITY: 'administrative_area_level_3',
  SUBCITY: 'sublocality',
  NEIGHBORHOOD: 'neighborhood',
  STATE: 'administrative_area_level_1',
  ZIP: 'postal_code',
};

export type Address = {
  placeId: string;
  description: string;
};

type AutocompletePrediction = google.maps.places.AutocompletePrediction;

export type Props = Omit<
  BaseTypeaheadDropdownProps & {
    id: string;
    sessiontoken: string;
    label?: string;
    hideLabelOnMobile?: boolean;
    onSelect?: (value: AutocompletePrediction | null) => void;
    onPlaceRetrieved?: (
      error: Error | null,
      place: AddressPhysical | null
    ) => void;
  },
  'items' | 'onChange'
>;

type AddressComponent = {
  types: [string];
  long_name: string;
  short_name: string;
};

const getPhysical = async (
  key: string,
  sessiontoken: string
): Promise<[Error | null, AddressPhysical | null]> => {
  try {
    const raw = await wrappedFetch(
      AlbertClient.googlePlaceByIdView(`${key}`, sessiontoken)
    );
    const response = await raw.json();
    const {
      result,
    }: {
      result: {
        address_components: [AddressComponent];
      };
    } = response;
    if (!result) {
      return [new Error('Bad response from Google Places API'), null];
    }
    if (response.status !== 'OK') {
      return [
        new Error(
          `Google Places API unable to find result: ${response.status}`
        ),
        null,
      ];
    }

    const addressPhysical: AddressPhysical = result.address_components.reduce(
      (r: AddressPhysical, v: AddressComponent): AddressPhysical => {
        const accumulator = r;
        if (
          v.types.some(
            (i: string) => i === GOOGLE_PLACES_ADDRESS_SEGMENT.CITY
          ) ||
          v.types.some(
            (i: string) => i === GOOGLE_PLACES_ADDRESS_SEGMENT.ALT_CITY
          )
        ) {
          accumulator.city = v.long_name;
        } else if (
          v.types.some(
            (i: string) => i === GOOGLE_PLACES_ADDRESS_SEGMENT.SUBCITY
          ) ||
          v.types.some(
            (i: string) => i === GOOGLE_PLACES_ADDRESS_SEGMENT.NEIGHBORHOOD
          )
        ) {
          /* 2021/07/01, MPR: we need this subcity/neighborhood check because some localities
           * (like new york city) only return their sublocality in place of city
           * whereas most others report city as locality. As such, we always
           * prefer that, but in cases where it is not returned, use this.
           */
          if (accumulator.city == null) {
            accumulator.city = v.long_name;
          }
        } else if (
          v.types.some(
            (i: string) => i === GOOGLE_PLACES_ADDRESS_SEGMENT.NUMBER
          )
        ) {
          if (accumulator.street == null) {
            accumulator.street = v.long_name;
          } else {
            accumulator.street = `${v.long_name} ${r.street}`;
          }
        } else if (
          v.types.some((i: string) => i === GOOGLE_PLACES_ADDRESS_SEGMENT.STATE)
        ) {
          accumulator.state = v.short_name;
        } else if (
          v.types.some(
            (i: string) => i === GOOGLE_PLACES_ADDRESS_SEGMENT.STREET
          )
        ) {
          if (accumulator.street == null) {
            accumulator.street = v.long_name;
          } else {
            accumulator.street = `${r.street} ${v.long_name}`;
          }
        } else if (
          v.types.some((i: string) => i === GOOGLE_PLACES_ADDRESS_SEGMENT.ZIP)
        ) {
          accumulator.zipcode = v.long_name;
        }
        return accumulator;
      },
      {}
    );
    return [null, addressPhysical];
  } catch (err) {
    return [err as any, null];
  }
};

const Placeholder = styled.div`
  color: ${colors.primaryText};
  .header {
    margin-bottom: ${mixins.pxToRem('12px')};
    ${fontSizes.fontSize16}
  }
  .subtitle {
    ${fontSizes.fontSize16}
    color: ${colors.primaryGray};
  }
`;

const AddressTypeahead = (props: Props): React.ReactElement => {
  const { id, value, sessiontoken, onPlaceRetrieved = false } = props;
  const dispatch = useDispatch();

  // ///////////////////////////////
  /* =========== STATE ========== */
  // ///////////////////////////////
  const [options, setOptions] = React.useState<AutocompletePrediction[]>([]);
  const [hasRunOnce, setHasRunOnce] = React.useState(false);
  const [isMissingZipCode, setIsMissingZipCode] = React.useState(false);
  const [isMissingStreet, setIsMissingStreet] = React.useState(false);

  if (onPlaceRetrieved && value && !hasRunOnce) {
    setHasRunOnce(true);
    wrappedFetch(AlbertClient.googlePlacePredictionsView(value, sessiontoken))
      .then((res) => res.json())
      .then((response) => {
        // Status options found here:
        // https://github.com/DefinitelyTyped/DefinitelyTyped/blob/master/types/googlemaps/reference/places-service.d.ts#L93
        const successResponses = ['OK'];
        if (!successResponses.includes(response.status)) {
          logger.warn(
            'Unable to lookup stored address from Google Places. This should not happen.'
          );
          return;
        }
        const placeIdRetrieved = response.predictions[0].place_id;
        getPhysical(placeIdRetrieved, sessiontoken)
          .then((res) => {
            const [err, addressPhysical] = res;
            if (!err) {
              onPlaceRetrieved(null, addressPhysical);
            } else {
              logger.error(err);
              onPlaceRetrieved(err, null);
            }
          })
          .catch(logger.error);
      })
      .catch((err) => {
        logger.warn(err);
      });
  }

  // //////////////////////////////////
  /* =========== HANDLERS ========== */
  // //////////////////////////////////

  const onChange = (event: any) => {
    setIsMissingZipCode(false);
    setIsMissingStreet(false);
    const { value } = event.target;

    if (!value) {
      return;
    }

    wrappedFetch(AlbertClient.googlePlacePredictionsView(value, sessiontoken))
      .then((res) => res.json())
      .then((response) => {
        // Status options found here:
        // https://github.com/DefinitelyTyped/DefinitelyTyped/blob/master/types/googlemaps/reference/places-service.d.ts#L93
        const successResponses = ['OK', 'ZERO_RESULTS'];
        if (!successResponses.includes(response.status)) {
          dispatch(
            addBanner(
              NotificationTypes.WARNING,
              'Error getting suggested addresses. Please try again.',
              true
            )
          );
          return;
        }
        setOptions(response.predictions);
      })
      .catch((err) => {
        logger.error(err);
      });
  };

  const onSelect = async (option: Option) => {
    if (option) {
      const [err, addressPhysical] = await getPhysical(
        options[option.key].place_id,
        sessiontoken
      );

      if (!addressPhysical?.zipcode) {
        setIsMissingZipCode(true);
        return;
      }

      if (!addressPhysical?.street) {
        setIsMissingStreet(true);
        return;
      }

      if (onPlaceRetrieved) {
        if (!err) {
          onPlaceRetrieved(null, addressPhysical);
        } else {
          onPlaceRetrieved(err, null);
        }
      }

      props.onSelect && props.onSelect(options[option.key]);
    } else {
      props.onSelect && props.onSelect(option);
    }
  };

  // ///////////////////////////////
  /* =========== RENDER ========== */
  // ///////////////////////////////

  const items: DropdownItem[] = options.map(
    (option: AutocompletePrediction) => ({
      value: option.description,
    })
  );

  const emptyText = (
    <Placeholder>
      <div className='header'>No results found.</div>
      <div className='subtitle'>
        If you cannot locate your address, please confirm that you can find it
        using Google maps, and type in the exact address from Google maps.
      </div>
    </Placeholder>
  );

  let errorText = props.errorText;

  if (isMissingZipCode || isMissingStreet) {
    errorText = ErrorMessages.input.validAddress;
  }

  return (
    <Typeahead
      {...props}
      id={`${id}-typeahead`}
      items={items}
      emptyText={emptyText}
      onSelect={onSelect}
      onChange={onChange}
      invalid={isMissingZipCode || isMissingStreet || props.invalid}
      errorText={errorText}
    />
  );
};

export default AddressTypeahead;
