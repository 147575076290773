import addBanner from 'actions/banner/addBanner';
import types from 'actions/types';
import AlbertClient from 'api/AlbertClient';
import NotificationTypes from 'constants/NotificationTypes';
import * as WEB from 'types/interfaces';
import { onErrorDisplayDefaultBanner } from 'utils/axiosMiddlewareUtils';

export interface UpdateEmailFields {
  phone: string;
}

export default (
    fieldsToUpdate: UpdateEmailFields,
    includeBanner = false
  ): WEB.ActionCreator<Promise<Record<string, any>>> =>
  async (dispatch: WEB.Dispatch): Promise<Record<string, any>> => {
    if (includeBanner) {
      const bannerBody = `We sent a new code to your number **${fieldsToUpdate.phone}**`;
      dispatch(addBanner(NotificationTypes.SUCCESS, bannerBody, true));
    }

    let error = false;
    const response = await dispatch({
      types: [
        types.UPDATE_PHONE_REQUEST,
        types.UPDATE_PHONE_SUCCESS,
        types.UPDATE_PHONE_FAILURE,
      ],
      payload: {
        request: {
          method: 'put',
          url: AlbertClient.profileUpdatePhoneView(),
          data: {
            ...fieldsToUpdate,
          },
        },
        options: {
          onError: (middlewareOptions: any, options: any) => {
            error = true;
            return onErrorDisplayDefaultBanner(middlewareOptions, options);
          },
        },
      },
    });
    return { response, error };
  };
