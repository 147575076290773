/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement } from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import { TextColor, TextSizes } from 'components/common/Text';
import NumberIcon from 'components/icons/NumberIcon';
import ArtMap from 'constants/ArtMap';
import { LanguageListItem, ListType } from 'types/interfaces';
import ListItem from './ListItem';

type Props = {
  /* list items */
  items: any[];
  /* the type of the list */
  type: ListType;
  /* adjusts the spacing under the heading */
  addExpanderSubheadSpacing?: boolean;
  /* paragraph text color */
  textColor?: TextColor | string;
  /* paragraph text size */
  textSize?: TextSizes | '';
  /* spacing between items */
  itemSpacing?: string;
  /* certain lists contain markdown */
  hasMarkdown?: boolean;
  /* className */
  className?: string;
};

const StyledList = styled.div`
  display: flex;
  flex-direction: column;
`;

const MarkdownLink = ({ href, children, ...props }: any) => {
  const cleanedHref = href.replace('&amp;', '&');
  return (
    <a href={cleanedHref} {...props} target='_blank' rel='noopener noreferrer'>
      {children}
    </a>
  );
};

const List = (props: Props): ReactElement | null => {
  const {
    items,
    type,
    textColor,
    textSize,
    addExpanderSubheadSpacing = false,
    hasMarkdown = false,
    itemSpacing,
    className,
    ...otherProps
  } = props;

  const renderListIcon = (
    listType: ListType,
    icon: string | undefined,
    iconWidth: number | undefined,
    index: number
  ): ReactElement | null => {
    const DEFAULT_WIDTH = 40;
    let component = null;
    switch (listType) {
      case ListType.NumberList:
        component = <NumberIcon number={index + 1} />;
        break;
      case ListType.List:
        component = (
          <img
            alt={`list-icon-${icon as string}`}
            width={iconWidth || DEFAULT_WIDTH}
            src={ArtMap(icon as string)}
          />
        );
        break;
      default:
        break;
    }

    return component;
  };

  if (!items || items.length === 0) return null;
  return (
    <StyledList {...otherProps} className={className || 'common-list'}>
      {items.map((item: LanguageListItem, idx: number) => {
        const text = hasMarkdown ? (
          <ReactMarkdown components={{ a: MarkdownLink }}>
            {item.text}
          </ReactMarkdown>
        ) : (
          item.text
        );
        const newIcon = renderListIcon(type, item.icon, item.iconWidth, idx);

        const { filename, width } = item.imageBottom || {};
        const imageBottom =
          filename && width ? (
            <img width={width || '100%'} src={ArtMap(filename)} />
          ) : null;

        return (
          <ListItem
            id={`list-item-${idx}`}
            key={`list-item-${idx}`}
            icon={newIcon}
            imageBottom={imageBottom}
            type={type}
            textSize={textSize}
            textColor={textColor}
            header={item.header}
            text={text}
            addExpanderSubheadSpacing={addExpanderSubheadSpacing}
            itemSpacing={itemSpacing}
          />
        );
      })}
    </StyledList>
  );
};

export default List;
