import * as Sentry from '@sentry/react';
import getEmailVerificationStatus from 'actions/async/getEmailVerificationStatus';
import Pages, { SignupPages } from 'constants/Pages';
import SignupGoals from 'constants/SignupGoals';
import { JUMPSTART_CASH_MINIMUM_BALANCE_REQUIRED } from 'pages/banking/JumpstartCash';
import {
  getBankingAccount,
  getPrimaryCheckingAccount,
} from 'reducers/entities/appData';
import * as appData from 'reducers/entities/appData';
import { getSignupFlowOrder } from 'reducers/entities/language';
import { getValueByField } from 'reducers/entities/profile';
import { getCurrentPage } from 'reducers/ui/signup';
import * as WEB from 'types/interfaces';
import { logger } from 'utils/logger';
import { isUnemployed } from 'utils/profile';

export type Result = Promise<{
  error: boolean;
  nextPageOverride: Pages | null;
}>;

const OPTIONAL_PRODUCT_PAGES = [
  Pages.CANCEL_SUBSCRIPTIONS_BENEFITS,
  Pages.BUDGETING_BENEFITS,
  Pages.INVESTING_INTERESTS,
];

/**
 * Get the next *valid* page in the signup flow.
 *
 * There are a few signup pages that skip themselves based on some
 * user criteria (e.g. JumpstartCash, VerifyEmail). This function consolidates
 * that validation so we can skip multiple pages in a row to get the "next page".
 * This function allows running any async requests to get more criteria,
 * all while still on the current page so we can still show a loading state
 * before navigating forward.
 *
 * Our previous approach to have the skip validation in each page component,
 * while organized, didn't allow for making an async request before showing
 * the page without a stange lag in the navigation.
 *
 * Currently, this is partially implemented in the flow. We can introduce it
 * slowly as needed. Eventually, this could replace the nextPage prop passed
 * to each signup page component.
 */
const getNextPage = () => {
  return async (dispatch: WEB.Dispatch, getState: WEB.GetStateFunc): Result => {
    const state = getState();
    const currentPage = getCurrentPage(state);
    const languageOrder = getSignupFlowOrder(state);

    for (let i = 0; i < languageOrder.length; i++) {
      const subflowPages = languageOrder[i].pages;
      const nextSubflowPage =
        languageOrder[i + 1]?.pages?.[0] || Pages.DOWNLOAD_APP;
      if (subflowPages.includes(currentPage)) {
        const currentPageIndex = subflowPages.indexOf(currentPage);

        // Find the next valid page to navigate to from the current page.
        // - if the page has no restrictions, return early
        // - if the page has restrictions, check them, then return early or continue
        // - if there are no more pages, just return nextSubflowPage
        for (let j = currentPageIndex + 1; j < subflowPages.length; j++) {
          const nextPage = subflowPages[j];

          if (nextPage === Pages.VERIFY_EMAIL) {
            const { response, error }: any = await dispatch(
              getEmailVerificationStatus()
            );

            if (error) {
              return {
                error: true,
                nextPageOverride: null,
              };
            } else if (response?.payload?.data?.email_not_bounced) {
              // The user has received the email, skip the VerifyEmail page.
              continue;
            }
          } else if (nextPage === Pages.JUMPSTART_CASH) {
            const bankingAccount = getBankingAccount(state);
            const primaryCheckingAccount = getPrimaryCheckingAccount(state);

            // NOTE: This criteria can be found in iOS repo -> ASCashSetupWireframe.swift > showCardShipped().
            const isPageValid =
              bankingAccount !== null &&
              bankingAccount.is_onboarding_complete &&
              primaryCheckingAccount !== null &&
              primaryCheckingAccount.balance >=
                JUMPSTART_CASH_MINIMUM_BALANCE_REQUIRED;

            // The user doesn't have a valid bank account,
            // skip the JumpstartCash page.
            if (!isPageValid) continue;
          } else if (OPTIONAL_PRODUCT_PAGES.includes(nextPage as SignupPages)) {
            const primaryCheckingAccount = getPrimaryCheckingAccount(state);
            if (primaryCheckingAccount !== null) {
              // If primary checking account is linked, skip optional product page
              continue;
            }
            if (nextPage === Pages.CANCEL_SUBSCRIPTIONS_BENEFITS) {
              const isInterestedInCancelSubscriptions = appData.hasSetupGoal(
                state,
                SignupGoals.CANCEL_SUBSCRIPTIONS
              );
              if (!isInterestedInCancelSubscriptions) {
                continue;
              }
            }
            if (nextPage === Pages.BUDGETING_BENEFITS) {
              const isInterestedInBudgeting = appData.hasSetupGoal(
                state,
                SignupGoals.BUDGET
              );
              if (!isInterestedInBudgeting) {
                continue;
              }
            }
          } else if (nextPage === Pages.CASH_SELECT_SHIP_DEBIT_CARD) {
            const isInterestedInBanking = appData.hasSetupGoal(
              state,
              SignupGoals.BANK_ACCOUNT
            );
            if (isInterestedInBanking) {
              // Assume user wants a physical debit card shipped. Skip opt-in page.
              continue;
            }
          } else if (nextPage === Pages.CASH_SHIPPING_ADDRESS) {
            const bankingAccount = getBankingAccount(state);
            if (bankingAccount?.physical_card_opt_in === false) {
              // Skip card shipping page if user explicitly does not want a card.
              continue;
            }
          } else if (nextPage === Pages.EMPLOYMENT) {
            const occupation = getValueByField(state, 'occupation');
            if (isUnemployed(occupation)) {
              // Skip the employment page.
              continue;
            }
            const bankingAccount = getBankingAccount(state);
            if (bankingAccount?.physical_card_opt_in === false) {
              // Skip card shipping page if user explicitly does not want a card.
              continue;
            }
          }

          // Return the valid next page.
          return {
            error: false,
            nextPageOverride: nextPage,
          };
        }

        // Handle if the current page is the last in the subflow, or the
        // remaining pages in the subflow aren't valid.
        return {
          error: false,
          nextPageOverride: nextSubflowPage,
        };
      }
    }

    /* 2023/03/28, AR: Debug rare issue where InstantBenefits reaches here */
    const subflowPages = languageOrder.find((order) =>
      order.pages?.find((page) => page === currentPage)
    ) || { pages: [] };
    const errorContext = {
      currentPage,
      subflowPages: subflowPages.pages.toString(),
    };
    Sentry.setContext('Page data', errorContext);

    // Should not reach here. Let the page navigate to its fallback next page.
    logger.error('Failed to find a valid next page.');
    return {
      error: false,
      nextPageOverride: null,
    };
  };
};

export default getNextPage;
