import types from 'actions/types';
import AlbertClient from 'api/AlbertClient';
import * as WEB from 'types/interfaces';

export default (): WEB.Action => ({
  types: [
    types.GET_INSTITUTIONS_REQUEST,
    types.GET_INSTITUTIONS_SUCCESS,
    types.GET_INSTITUTIONS_FAILURE,
  ],
  payload: {
    request: {
      method: 'get',
      url: AlbertClient.institutionView(),
    },
  },
});
