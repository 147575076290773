import {
  unstable_useBlocker as useBlocker,
  useLocation,
} from 'react-router-dom';

/**
 * Hook to override clicking the browser
 * back button and forward button
 * with a redirect.
 *
 * @param onClick - redirect to trigger when the
 * browser back button or forward button is clicked.
 * @param enabled - is the override on or off.
 */
const useBrowserNavigationOverrideWithRedirect = (
  onClick: () => void,
  enabled = true
): void => {
  const location = useLocation();

  useBlocker(({ currentLocation, historyAction }) => {
    // Protect against overriding when just
    // "enabled" changes
    if (location !== currentLocation) {
      if (!enabled) {
        return false;
      }
      // Protect against overriding PUSH navigations,
      // i.e. completing a page and moving forward
      if (historyAction === 'POP') {
        // Override with a redirect
        onClick();
        return true;
      }
    }
    return false;
  });
};

export default useBrowserNavigationOverrideWithRedirect;
