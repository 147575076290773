// Loose reference: http://devfacts.com/media-queries-breakpoints-2020/
export const widths = {
  // Mobile
  mobileSmall: 320,
  mobileMedium: 375,
  mobileLarge: 1150,

  // Tablet
  tabletXSmall: 600,
  tabletSmall: 768,
  tabletLarge: 834,

  // Desktop
  desktopSmall: 950,
  desktopSmallMedium: 1024,
  desktopMedium: 1200,
  desktopLarge: 1680,

  // Grid
  institutionGrid: 500,

  // Input group
  inputGroup: 1020,

  // Progress nav bar
  progressNavBar: 1280,
};

export const sizes = {
  // Mobile
  mobileSmall: `${widths.mobileSmall}px`,
  mobileMedium: `${widths.mobileMedium}px`,
  mobileLarge: `${widths.mobileLarge}px`,

  // Tablet
  tabletXSmall: `${widths.tabletXSmall}px`,
  tabletSmall: `${widths.tabletSmall}px`,
  tabletLarge: `${widths.tabletLarge}px`,

  // Desktop
  desktopSmall: `${widths.desktopSmall}px`,
  desktopSmallMedium: `${widths.desktopSmallMedium}px`,
  desktopMedium: `${widths.desktopMedium}px`,
  desktopLarge: `${widths.desktopLarge}px`,

  // Grid
  institutionGrid: `${widths.institutionGrid}px`,

  // Input group
  inputGroup: `${widths.inputGroup}px`,

  // Progress nav bar
  progressNavBar: `${widths.progressNavBar}px`,
};

export default {
  // Mobile
  mobileSmall: `(max-width: ${sizes.mobileSmall})`,
  mobileMedium: `(max-width: ${sizes.mobileMedium})`,
  mobileLarge: `(max-width: ${sizes.mobileLarge})`,

  // Tablet
  tabletXSmall: `(max-width: ${sizes.tabletXSmall})`,
  tabletSmall: `(max-width: ${sizes.tabletSmall})`,
  tabletLarge: `(max-width: ${sizes.tabletLarge})`,

  // Desktop
  desktopSmall: `(max-width: ${sizes.desktopSmall})`,
  desktopSmallMedium: `(max-width: ${sizes.desktopSmallMedium})`,
  desktopMedium: `(max-width: ${sizes.desktopMedium})`,
  desktopLarge: `(max-width: ${sizes.desktopLarge})`,

  // Grid
  institutionGrid: `(max-width: ${sizes.institutionGrid})`,

  // Input group
  inputGroup: `(max-width: ${sizes.inputGroup})`,

  // Progress nav bar
  progressNavBar: `(max-width: ${sizes.progressNavBar})`,

  // Splash breakpoints: _variables.scss
  splashTiny: '(max-width: 320px)',
  splashXSmall: '(max-width: 375px)',
  splashSmall: '(max-width: 600px)',
  splashMedium: '(max-width: 1024px)',
  splashLarge: '(max-width: 1200px)',
  splashXLarge: '(max-width: 1300px)',
};
