/* eslint-disable @typescript-eslint/await-thenable */
import types from 'actions/types';
import AlbertClient from 'api/AlbertClient';
import { TransfersAccountType, TransfersSubmissionType } from 'constants/index';
import * as WEB from 'types/interfaces';
import { onError } from 'utils/axiosMiddlewareUtils';

export type TransferPayload = {
  source_type: TransfersAccountType;
  source_id: number | string;
  target_type: TransfersAccountType;
  target_id: number | string;
  amount: number;
  submission_type?: TransfersSubmissionType;
};

export default (
    data: TransferPayload
  ): WEB.ActionCreator<Promise<Record<string, any>>> =>
  async (dispatch: WEB.Dispatch): Promise<Record<string, any>> => {
    let errorPayload = null;

    const response = await dispatch({
      types: [
        types.CREATE_TRANSFER_TRANSACTION_REQUEST,
        types.CREATE_TRANSFER_TRANSACTION_SUCCESS,
        types.CREATE_TRANSFER_TRANSACTION_FAILURE,
      ],
      payload: {
        request: {
          method: 'post',
          url: AlbertClient.transfersView(),
          data,
        },
        options: {
          onError: (middlewareOptions: any, options: any) => {
            errorPayload = middlewareOptions?.error?.response?.data || {};
            return onError(middlewareOptions, options);
          },
        },
      },
    });

    return { response, errorPayload };
  };
