const SavingsSchedules = {
  '7': 'per week',
  '14': 'twice a month',
  '30': 'per month',
};

export const SavingsMethods = {
  AUTO: 'a',
  WEEKLY: '7',
  BIWEEKLY: '14',
  MONTHLY: '30',
};

export const SavingsAmountMax = {
  '7': '500',
  '14': '1000',
  '30': '2000',
};

export default SavingsSchedules;
