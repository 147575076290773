/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import TrackingBase from 'tracking.Base';
import textWelcome from 'actions/async/textWelcome';
import updateProfile from 'actions/async/updateProfile';
import removeBanner from 'actions/banner/removeBanner';
import Button from 'components/common/Button';
import Input from 'components/common/Input';
import { InputTypes } from 'components/common/Input/TextInput';
import { InputWidth } from 'components/common/Input/shared';
import Spacer from 'components/common/Spacer';
import Text, { TextSizes, textTransition } from 'components/common/Text';
import Tooltip from 'components/common/Tooltip';
import QuestionCircleIcon from 'components/icons/QuestionCircleIcon';
import ButtonsContainer from 'components/layout/ButtonsContainer';
import PageBase from 'components/layout/PageBase';
import Pages from 'constants/Pages';
import useBrowserNavigationOverride from 'hooks/useBrowserNavigationOverride';
import useNavigateFunction from 'hooks/useNavigateFunction';
import useVerifyCurrentPage from 'hooks/useVerifyCurrentPage';
import * as language from 'reducers/entities/language';
import { breakpoints, colors, spacers } from 'styles';
import * as WEB from 'types/interfaces';
import { InputFieldProps } from 'types/interfaces';
import { delayWithState } from 'utils/delay';
import { getLanguageButtons, getLanguageSection } from 'utils/getFromLanguage';
import { composeValidators, isRequired, minLen } from 'utils/validators';

type Props = {
  nextPage: string;
};

const InlineContainer = styled.div`
  display: flex;
  align-items: center;

  &:hover,
  &:hover u,
  &:hover svg {
    ${textTransition}
    color: ${colors.primaryAlbertBrand};
  }
  margin-right: ${spacers.tab};

  @media ${breakpoints.mobileLarge} {
    margin-right: 0;
    // Make room for the tooltip on mobile
    padding-bottom: 50px;
  }
`;

const DesktopTooltipContainer = styled.div`
  display: block;
  @media ${breakpoints.mobileLarge} {
    display: none;
  }
`;

const MobileTooltipContainer = styled.div`
  display: none;
  @media ${breakpoints.mobileLarge} {
    display: block;
  }
`;

const HiddenInput = styled.input`
  display: none;
`;

const HideSection = styled.div`
  &.hidden {
    display: none;
  }
`;

const ButtonId = 'verify-code-button';

const ConfirmNumber = (props: Props): React.ReactElement => {
  const { updateCurrentSignupPage } = useNavigateFunction();
  // Make Redux is up-to-date with currentSignUpPage
  useVerifyCurrentPage(Pages.CONFIRM_NUMBER);

  // ///////////////////////////////
  /* =========== HOOKS ========== */
  // ///////////////////////////////
  const dispatch = useDispatch();

  // ///////////////////////////////
  /* =========== STATE ========== */
  // ///////////////////////////////
  const [phone, setPhone] = React.useState('');
  const [phoneError, setPhoneError] = React.useState('');
  const [isCodeInvalid, setIsCodeInvalid] = React.useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [isCompleted, setIsCompleted] = React.useState<boolean>(false);
  const [confirmCode, setConfirmCode] = React.useState('');
  const [isPhoneSubmitted, setIsPhoneSubmitted] =
    React.useState<boolean>(false);

  // ///////////////////////////////
  /* ======== MAPPED STATE ====== */
  // ///////////////////////////////
  const languageCtx = useSelector((state: WEB.RootState) =>
    language.getSignupPageLanguage(state, Pages.CONFIRM_NUMBER)
  );
  // Determine whether this page is preceded by SetUpAlbert
  const hasSetUpAlbert = useSelector(language.hasSetUpAlbert);

  // //////////////////////////////////
  /* =========== HANDLERS ========== */
  // //////////////////////////////////
  const handleOnNext = async (values: {
    'phone-number': string;
  }): Promise<{ [key: string]: any }> => {
    setPhoneError('');
    const err: { [key: string]: string } = {};
    // Remove any error banner from previous attempts
    dispatch(removeBanner());

    // Indicate button loading state
    setIsSubmitting(true);

    if (!isPhoneSubmitted) {
      TrackingBase.track({
        event: 'Phone number entered',
        trackToAlbert: true,
        trackOnce: true,
        trackSocial: false,
      });
      // Send welcome text will also save phone number on Profile
      const phoneNumber = values['phone-number'];
      const error: any = await dispatch(textWelcome(phoneNumber));
      if (error) {
        if (typeof error === 'string') {
          setPhoneError(error);
        }
        err['phone-number'] = 'error';
      } else {
        setPhone(phoneNumber);
        const button = document.getElementById(ButtonId);
        button?.blur();
        setIsPhoneSubmitted(true);
        window.scrollTo(0, 0);
      }
    } else {
      TrackingBase.track({
        event: 'Confirm code entered',
        trackToAlbert: true,
        trackOnce: true,
        trackSocial: false,
      });
      if (!confirmCode || confirmCode.length < 4) {
        setIsCodeInvalid(true);
        setConfirmCode('');
        setIsSubmitting(false);
        return { code: 'error' };
      }

      const { response }: any = await dispatch(
        updateProfile({
          text_confirm_code: confirmCode,
        })
      );
      const responseData = response?.payload?.data;
      const verified = !!responseData?.personal_info?.text_verification;
      if (verified) {
        // Indicate button completed state
        await delayWithState(600, setIsCompleted);
        dispatch(updateCurrentSignupPage({ page: props.nextPage }));
      } else {
        setConfirmCode('');
        setIsCodeInvalid(true);
        err.code = 'error';
      }
    }

    setIsSubmitting(false);

    return err;
  };

  const handleResendCode = () => {
    dispatch(textWelcome(phone, true));
  };

  const handleGoBack = React.useCallback(() => {
    if (isPhoneSubmitted) {
      // Display phone input
      dispatch(removeBanner());
      setIsCodeInvalid(false);
      setConfirmCode('');
      setIsPhoneSubmitted(false);
    } else if (hasSetUpAlbert) {
      // SetUpAlbert precedes ConfirmPage, so we can navigate backwards
      return false;
    }
    // Block browser backwards navigation
    return true;
  }, [dispatch, isPhoneSubmitted]);

  useBrowserNavigationOverride(handleGoBack, true);

  const handleOnCodeChange = (value: string) => {
    if (isCodeInvalid) {
      setIsCodeInvalid(false);
    }
    setConfirmCode(value);
  };

  // //////////////////////////////////
  /* =========== LANGUAGE ========== */
  // //////////////////////////////////
  const CONFIRM_KEY = 'confirmationCode';
  const PHONE_KEY = 'phoneNumber';

  const confirmSection = getLanguageSection(languageCtx, CONFIRM_KEY);
  const phoneNumberSection = getLanguageSection(languageCtx, PHONE_KEY);
  const buttons = getLanguageButtons(languageCtx);

  const PhoneNumberInput = ({
    input,
    inputOnChange,
    meta,
  }: Partial<InputFieldProps>) => {
    const invalid =
      (meta?.error || phoneError) &&
      meta?.submitFailed &&
      !meta.dirtySinceLastSubmit;

    const inputProps = {
      ...input,
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        input?.onChange(e);
        inputOnChange && inputOnChange(e);
      },
    };

    return (
      <Input.Text
        id='phone-number'
        type={InputTypes.PHONE}
        widthSize={InputWidth.SMALL}
        label={phoneNumberSection?.header}
        description={phoneNumberSection?.text}
        floatingLabel={phoneNumberSection?.placeholder}
        hideLabelOnMobile
        placeholder={phoneNumberSection?.placeholder}
        {...inputProps}
        invalid={!!invalid}
        errorText={
          phoneError || phoneNumberSection?.errorMessage || meta?.error
        }
      />
    );
  };

  return (
    <>
      <PageBase
        mobileHeader={
          (isPhoneSubmitted
            ? confirmSection?.header
            : phoneNumberSection?.header) || ''
        }
      >
        <Form
          onSubmit={handleOnNext}
          initialValues={{
            code: false,
          }}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Field name='source' component={HiddenInput} />
              <HideSection className={!isPhoneSubmitted ? '' : 'hidden'}>
                <Field
                  name='phone-number'
                  component={PhoneNumberInput}
                  validate={composeValidators(minLen(14), isRequired)}
                />
              </HideSection>
              <Field name='code' component={HiddenInput} />
              <HideSection className={isPhoneSubmitted ? '' : 'hidden'}>
                <Input.Code
                  id='code-input'
                  label={confirmSection?.header}
                  description={confirmSection?.text}
                  hideLabelOnMobile
                  fields={4}
                  invalid={isCodeInvalid}
                  errorText={confirmSection?.errorMessage}
                  onChange={handleOnCodeChange}
                />
              </HideSection>
              <ButtonsContainer>
                <HideSection className={isPhoneSubmitted ? '' : 'hidden'}>
                  <InlineContainer className='secondary-button'>
                    <Text
                      nowrap
                      pointer
                      weight='700'
                      size={TextSizes.SMALL}
                      onClick={handleResendCode}
                      isLinkButton
                    >
                      <u>{buttons?.link}</u>
                      &nbsp;
                    </Text>
                    &nbsp;
                    <QuestionCircleIcon
                      data-tip={buttons?.linkTooltip}
                      data-for='resend-code-tooltip'
                    />
                    <DesktopTooltipContainer>
                      <Tooltip id='resend-code-tooltip' place='top' />
                    </DesktopTooltipContainer>
                    <MobileTooltipContainer>
                      <Tooltip id='resend-code-tooltip' place='bottom' />
                    </MobileTooltipContainer>
                    <Spacer
                      space={spacers.tab}
                      orientation='horizontal'
                      desktopOnly
                    />
                  </InlineContainer>
                </HideSection>
                <Button
                  id={ButtonId}
                  isCompleted={isCompleted}
                  isLoading={isSubmitting}
                  disabled={isSubmitting}
                >
                  {!isCompleted
                    ? isPhoneSubmitted
                      ? buttons?.primaryAlt
                      : buttons?.primary
                    : buttons?.complete}
                </Button>
              </ButtonsContainer>
            </form>
          )}
        />
      </PageBase>
    </>
  );
};

export default ConfirmNumber;
