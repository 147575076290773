import { checkFlexGapColumnSupported } from './flexGapColumn';
import { checkFlexGapRowSupported } from './flexGapRow';

/**
 * Flex gap polyfill, primarily for fairly recent versions of Safari on iOS
 * https://javascript.plainenglish.io/polyfill-flex-gap-using-mixin-function-with-styled-components-201be7951fd3
 *
 * @deprecated - Prefer alternative styles over conditional polyfill: styles/flexGap.ts
 */
let isFlexGapSupported: boolean | null = null;

const checkFlexGapSupported = (): boolean => {
  // gap is a shorthand for row-gap and column-gap
  if (isFlexGapSupported !== null) {
    return isFlexGapSupported;
  }
  isFlexGapSupported =
    checkFlexGapRowSupported() && checkFlexGapColumnSupported();

  return isFlexGapSupported;
};

const flexGapPolyfill = (gap: string): string => {
  return `
    margin-left: -${gap};
    margin-top: -${gap};
    & > * {
      margin-left: ${gap};
      margin-top: ${gap};
    }
  `;
};

const flexGap = (gap: string): string => {
  if (checkFlexGapSupported()) {
    return `
      gap: ${gap};
    `;
  }
  return flexGapPolyfill(gap);
};

export default flexGap;
