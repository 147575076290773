let isFlexGapColumnSupported: boolean | null = null;

/**
 * @deprecated - Prefer alternative styles over conditional polyfill: styles/flexGap.ts
 */
export const checkFlexGapColumnSupported = (): boolean => {
  const gapValue = 1;

  if (isFlexGapColumnSupported !== null) {
    return isFlexGapColumnSupported;
  }

  // create flex container with column-gap set
  const flex = document.createElement('div');
  // inline-flex prevents contents from stretching to 100% width, allowing us to measure gap width
  flex.style.display = 'inline-flex';
  flex.style.flexDirection = 'row';
  flex.style.columnGap = `${gapValue}px`;

  // create two, elements inside it
  flex.appendChild(document.createElement('div'));
  flex.appendChild(document.createElement('div'));

  // append to the DOM (needed to obtain scrollWidth)
  document.body.appendChild(flex);
  const isSupported = flex.scrollWidth === gapValue;
  flex?.parentNode?.removeChild(flex);

  isFlexGapColumnSupported = isSupported;

  return isSupported;
};

const flexGapPolyfill = (gap: string): string => {
  return `
    margin-left: -${gap};
    & > * {
      margin-left: ${gap};
    }
  `;
};

const flexGapColumn = (gap: string): string => {
  if (checkFlexGapColumnSupported()) {
    return `
      column-gap: ${gap};
    `;
  }
  return flexGapPolyfill(gap);
};

export default flexGapColumn;
