import * as React from 'react';
import ReactTooltip, { TooltipProps } from 'react-tooltip';
import styled from 'styled-components';
import { breakpoints, fontSizes } from 'styles';

type Props = TooltipProps & {
  /* unique id for the tooltip */
  id: string;
  /* assuming a tooltip could contain icons and other elements */
  children?: React.ReactNode | React.ReactNode[];
  /* hide on mobile */
  hideMobile?: boolean;
  /* milliseconds to show the tooltip before it's hidden */
  showDuration?: number;
};

const StyledTooltip = styled(ReactTooltip).attrs({
  className: 'albert-tooltip' as string,
})<{ hideMobile?: boolean }>`
  &.albert-tooltip {
    ${fontSizes.fontSize12}
    color: #6b6e7c;
    padding: 14px 22px 14px 22px;
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.1);
    max-width: 200px;
    &.show {
      opacity: 1 !important;
    }
    /* Hide in mobile viewports */
    ${({ hideMobile }) =>
      hideMobile &&
      `
      @media ${breakpoints.mobileLarge} {
        display: none;
      }
    `}
  }
`;

export default (props: Props): React.ReactElement => {
  const { showDuration, afterShow } = props;
  // We use state to force a rerender of the tooltip component after it's hidden
  const [durationState, setDurationState] = React.useState(0);

  const handleOnClick = () => {
    afterShow && afterShow();
    setDurationState(showDuration || 0);
  };

  React.useEffect(() => {
    if (durationState) {
      setTimeout(() => {
        ReactTooltip.hide();
        setDurationState(0);
      }, durationState);
    }
  }, [durationState]);

  return (
    <StyledTooltip
      backgroundColor='white'
      effect='solid'
      {...props}
      afterShow={handleOnClick}
    />
  );
};
