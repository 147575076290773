import * as React from 'react';
import styled from 'styled-components';
import Spacer from 'components/common/Spacer';
import { spacers } from 'styles';
import mixins from 'styles/mixins';

type Props = {
  /* the icon to display for the site */
  icon: React.ReactNode;
  /* child elements */
  children?: React.ReactNode | React.ReactNode[];
};

const Container = styled.div`
  position: relative;
`;

const StyledModalPageWrapper = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
`;

const StyledIconWrapper = styled.div`
  padding-top: ${mixins.pxToRem('20px')};
  width: 100%;
  height: ${mixins.pxToRem('250px')};
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    margin: 0 auto;
    width: auto;
    height: 90%;
  }
`;

const StyledModalTextContent = styled.div`
  height: ${mixins.pxToRem('250px')};
  margin: 0 -15px 0 0px;
  padding-right: 15px;
  padding-bottom: 15px;
  white-space: pre-line;
`;

const StyleBlurryBox = styled.div`
  height: ${mixins.pxToRem('22px')};
  width: 100%;
  position: absolute;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0);
  background-image: linear-gradient(rgba(255, 255, 255, 0), white);
`;

const ModalPage = (props: Props): React.ReactElement => {
  const { icon, children } = props;
  return (
    <Container>
      <StyledModalPageWrapper>
        <StyledIconWrapper>{icon}</StyledIconWrapper>
        <StyledModalTextContent>
          {children}
          <Spacer space={spacers.submicroscopic} />
        </StyledModalTextContent>
        <StyleBlurryBox />
      </StyledModalPageWrapper>
    </Container>
  );
};

export default ModalPage;
