import * as React from 'react';
import { IconContainer, Props } from './shared';

const InvestingIcon = (props: Props): React.ReactElement => {
  const { color } = props;

  return (
    <IconContainer>
      <svg
        width='25'
        height='22'
        viewBox='0 0 25 22'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g clipPath='url(#clip0_9839_5456)'>
          <path
            d='M20.085 19.3138H5.32898C3.90098 19.3138 2.80898 18.2218 2.80898 16.7938V2.0378C2.80898 1.4498 2.33298 0.973796 1.77298 0.973796C1.18498 0.973796 0.708984 1.4498 0.708984 2.0378V16.7938C0.708984 19.3698 2.75298 21.4138 5.32898 21.4138H20.085C20.673 21.4138 21.149 20.9378 21.149 20.3498C21.149 19.7898 20.673 19.3138 20.085 19.3138ZM4.96498 11.6138C4.96498 12.2298 5.46898 12.7338 6.08498 12.7338C6.47698 12.7338 6.81298 12.5378 7.03698 12.2298L9.94898 7.4978C10.201 7.0778 10.621 7.0498 10.901 7.4418L12.693 9.7658L12.805 9.8778L13.757 11.1098C14.989 12.7618 17.341 12.6498 18.405 10.8858L22.213 4.6978L22.773 7.0498C22.885 7.5258 23.305 7.8618 23.809 7.8618C24.481 7.8618 24.985 7.2178 24.817 6.5738L23.725 1.9538C23.557 1.2538 22.913 0.833797 22.213 1.0018L17.593 2.0938C17.117 2.2058 16.781 2.6258 16.781 3.1018C16.781 3.8018 17.425 4.3058 18.069 4.1378L20.225 3.6338L16.501 9.7098C16.249 10.1298 15.829 10.1578 15.549 9.7658L14.093 7.8618L13.981 7.7498L12.693 6.0978C11.461 4.4458 9.10898 4.5578 8.04498 6.3218L5.13298 11.0258C5.02098 11.1938 4.96498 11.3898 4.96498 11.6138Z'
            fill={color || '#333333'}
          />
        </g>
        <defs>
          <clipPath id='clip0_9839_5456'>
            <rect
              width='24.1406'
              height='20.449'
              fill='white'
              transform='translate(0.708984 0.964844)'
            />
          </clipPath>
        </defs>
      </svg>
    </IconContainer>
  );
};

export default InvestingIcon;
