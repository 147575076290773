import * as Sentry from '@sentry/react';
import { InfoCircle } from '@styled-icons/boxicons-regular/InfoCircle';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import updateBankingAccount from 'actions/async/updateBankingAccount';
import addBanner from 'actions/banner/addBanner';
import removeBanner from 'actions/banner/removeBanner';
import getNextPage from 'actions/getNextPage';
import DebitCard, { ShadowTypes } from 'components/banking/DebitCard';
import DebitCardSelector from 'components/banking/DebitCardSelector';
import PillBadge from 'components/common/Badge/PillBadge';
import Button from 'components/common/Button';
import { MobileWrapper } from 'components/common/DeviceWrapper';
import Modal from 'components/common/Modal';
import ModalExpanderContent from 'components/common/Modal/ModalExpanderContent';
import Spacer from 'components/common/Spacer';
import Text, { TextColor, TextSizes } from 'components/common/Text';
import { textTransition } from 'components/common/Text';
import ButtonsLayout from 'components/layout/ButtonsLayout';
import LeadContent from 'components/layout/LeadContent';
import PageBase from 'components/layout/PageBase';
import Pages from 'constants/Pages';
import SignupGoals from 'constants/SignupGoals';
import { DebitCardStyle, NotificationTypes } from 'constants/index';
import useNavigateFunction from 'hooks/useNavigateFunction';
import useVerifyCurrentPage from 'hooks/useVerifyCurrentPage';
import * as appData from 'reducers/entities/appData';
import * as language from 'reducers/entities/language';
import * as profile from 'reducers/entities/profile';
import { breakpoints, spacers } from 'styles';
import { spacers as homeSpacers } from 'styles/home';
import * as WEB from 'types/interfaces';
import { getOrderedOptions } from 'utils/debitCard';
import { delayWithState } from 'utils/delay';
import {
  getLanguageAltInfo,
  getLanguageButtons,
  getLanguageInfo,
  getLanguageSection,
} from 'utils/getFromLanguage';

type Props = {
  nextPage: string;
};

const DEFAULT_MODAL_TITLE = 'Temporarily out of stock';
const DEFAULT_MODAL_DESCRIPTION =
  'We’re sorry, this color is currently temporarily out of stock.\n\nPlease select another color and try again.';
const DEFAULT_MODAL_BUTTON = 'Back';

const StyledDisclaimerDisclosureText = styled(Text)`
  display: none;
  @media ${breakpoints.mobileLarge} {
    display: block;
    text-align: center;
    margin-bottom: -20px; // Push closer to button
    @media (hover: hover) {
      &:hover {
        svg {
          color: ${({ theme }) => theme.colors.primaryText};
          cursor: pointer;
        }
      }
    }
  }
`;

const StyledInfoCircle = styled(InfoCircle)`
  width: 14px;
  height: 14px;
  display: inline-block;
  ${textTransition}
  cursor: pointer;
  vertical-align: -2px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.primaryGray};
`;

const StyledPillBadge = styled(PillBadge)`
  @media ${breakpoints.mobileLarge} {
    margin: 8px auto;
  }
`;

const DebitCardContainer = styled.div`
  @media ${breakpoints.mobileLarge} {
    height: 170px;
  }
`;

const SelectCard = (props: Props) => {
  // //////////////////////////////////
  /* =========== STATE ========== */
  // //////////////////////////////////
  const [showModal, setShowModal] = React.useState(false);
  const [modalTitle, setModalTitle] = React.useState(DEFAULT_MODAL_TITLE);
  const [modalDescription, setModalDescription] = React.useState(
    DEFAULT_MODAL_DESCRIPTION
  );
  const [modalButton, setModalButton] = React.useState(DEFAULT_MODAL_BUTTON);
  const [showDisclosureModal, setShowDisclosureModal] =
    React.useState<boolean>(false);

  // //////////////////////////////////
  /* =========== HOOKS ========== */
  // //////////////////////////////////
  const dispatch = useDispatch();
  const { updateCurrentSignupPage } = useNavigateFunction();
  // Make sure Redux is up-to-date with currentSignUpPage
  useVerifyCurrentPage(Pages.SELECT_CARD);

  // ///////////////////////////////
  /* ======= MAPPED STATE ======= */
  // ///////////////////////////////
  const languageCtx = useSelector((state: WEB.RootState) =>
    language.getSignupPageLanguage(state, Pages.SELECT_CARD)
  );
  const firstName = useSelector((state: WEB.RootState) =>
    profile.getValueByField(state, 'first_name')
  );
  const lastName = useSelector((state: WEB.RootState) =>
    profile.getValueByField(state, 'last_name')
  );
  const bankingAccount = useSelector(appData.getBankingAccount);
  const bankingCommonData = useSelector(appData.getBankingCommonData);
  const hasInstantGoal = useSelector((state: WEB.RootState) =>
    appData.hasSetupGoal(state, SignupGoals.CASH_ADVANCE)
  );
  const showPrices = bankingCommonData?.charge_for_debit_card || false;
  const isGeniusLocked = false;

  const cardStyleOptions = getOrderedOptions(
    showPrices,
    isGeniusLocked,
    bankingCommonData?.debit_card_availability
  );
  // ///////////////////////////////
  /* =========== STATE ========== */
  // ///////////////////////////////
  const [selectedCardStyle, setSelectedCardStyle] = React.useState(
    bankingAccount?.debit_card_style || cardStyleOptions[0]
  );
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [isCompleted, setIsCompleted] = React.useState<boolean>(false);

  // //////////////////////////////////
  /* =========== LANGUAGE ========== */
  // //////////////////////////////////
  const infoPaneContext = getLanguageInfo(languageCtx);
  const altInfoPaneContext = getLanguageAltInfo(languageCtx);
  const cardCtx = getLanguageSection(languageCtx, 'card');
  const buttonsCtx = getLanguageButtons(languageCtx);
  const disclosureCtx = getLanguageSection(languageCtx, 'disclosure');

  const badgeText =
    (hasInstantGoal && altInfoPaneContext?.labelText) ||
    infoPaneContext?.labelText;

  const primaryButton = showPrices
    ? buttonsCtx?.primaryCharge
    : buttonsCtx?.primary;

  // //////////////////////////////////
  /* =========== HANDLERS ========== */
  // //////////////////////////////////

  const handleDisclosureOnClick = () => {
    setShowDisclosureModal(true);
  };
  const handleDisclosureHide = () => {
    setShowDisclosureModal(false);
  };

  const onSubmit = async () => {
    // Remove any error banner from previous attempts
    dispatch(removeBanner());

    if (!bankingAccount || !bankingCommonData) {
      Sentry.captureException(
        'User reached SelectCard without banking account',
        {
          extra: {
            hasBankingAccount: !!bankingAccount,
            hasBankingCommonData: !!bankingCommonData,
          },
        }
      );
      dispatch(
        addBanner(
          NotificationTypes.WARNING,
          'We were unable to connect to our network due to a technical error. Please try again. ',
          false
        )
      );
      return;
    }

    const selectedStyleAvailability =
      bankingCommonData?.debit_card_availability.find(
        (card) => card.style === selectedCardStyle
      );
    if (!selectedStyleAvailability?.available) {
      setModalTitle(
        selectedStyleAvailability?.modal?.title || DEFAULT_MODAL_TITLE
      );
      setModalDescription(
        selectedStyleAvailability?.modal?.body || DEFAULT_MODAL_DESCRIPTION
      );
      setModalButton(
        selectedStyleAvailability?.modal?.button || DEFAULT_MODAL_BUTTON
      );
      setShowModal(true);
      return;
    }

    setIsSubmitting(true);
    const error = await dispatch(
      updateBankingAccount(bankingAccount.id, {
        debit_card_style: selectedCardStyle,
      })
    );
    setIsSubmitting(false);

    if (!error) {
      const { nextPageOverride }: any = await dispatch(getNextPage());
      await delayWithState(400, setIsCompleted);
      dispatch(
        updateCurrentSignupPage({
          page: nextPageOverride || props.nextPage,
          shouldNavTo: true,
        })
      );
    }
  };

  return (
    <PageBase>
      <MobileWrapper>
        <LeadContent
          header={infoPaneContext?.header || ''}
          text={infoPaneContext?.text || ''}
          desktopHeaderSize='small'
        />
      </MobileWrapper>
      <Spacer space={spacers.small} desktopOnly />
      <Spacer space={homeSpacers.g8} mobileOnly />
      <DebitCardContainer>
        <DebitCard
          firstName={firstName}
          lastName={lastName}
          style={selectedCardStyle}
          tilted
          shadow={ShadowTypes.BACKGROUND}
        />
      </DebitCardContainer>
      <Spacer space={spacers.largeExtra} desktopOnly />
      <Spacer space={homeSpacers.g11} mobileOnly />
      <MobileWrapper>
        {badgeText && <StyledPillBadge>{badgeText}</StyledPillBadge>}
      </MobileWrapper>
      <DebitCardSelector
        selectedCardStyle={selectedCardStyle}
        cardStyleOptions={cardStyleOptions}
        handleSelectCardStyle={setSelectedCardStyle}
        isGeniusLocked={isGeniusLocked}
        hideColorLabel={!!badgeText}
      />
      {showPrices && cardCtx?.disclaimer && (
        <>
          <Spacer space={homeSpacers.g10} />
          <StyledDisclaimerDisclosureText
            color={TextColor.GRAY}
            size={TextSizes.SMALL}
          >
            {cardCtx?.disclaimer}
          </StyledDisclaimerDisclosureText>
        </>
      )}
      {disclosureCtx?.text && (
        <>
          <Spacer space={homeSpacers.g15} mobileOnly />
          <StyledDisclaimerDisclosureText
            isLinkButton
            color={TextColor.GRAY}
            onClick={handleDisclosureOnClick}
            weight='600'
          >
            {disclosureCtx.text} <StyledInfoCircle />
          </StyledDisclaimerDisclosureText>
        </>
      )}
      <ButtonsLayout
        marginTop={showPrices ? homeSpacers.g5 : homeSpacers.g11}
        primaryButton={
          <Button
            isCompleted={isCompleted}
            isLoading={isSubmitting}
            disabled={
              isSubmitting ||
              (isGeniusLocked && selectedCardStyle === DebitCardStyle.GENIUS)
            }
            onClick={onSubmit}
          >
            {isCompleted ? buttonsCtx?.complete : primaryButton}
          </Button>
        }
      />
      <Modal.Basic
        show={showModal}
        title={modalTitle}
        description={modalDescription}
        submitLabel={modalButton}
        onSubmit={() => setShowModal(false)}
        iconType={NotificationTypes.WARNING}
      />
      <ModalExpanderContent
        expanders={infoPaneContext?.expanders}
        showModal={showDisclosureModal}
        setHideModal={handleDisclosureHide}
      />
    </PageBase>
  );
};

export default SelectCard;
