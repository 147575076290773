import TrackingBase from 'tracking.Base';
import getAppData from 'actions/async/getAppData';
import getProfile from 'actions/async/getProfile';
import getSignupLanguage from 'actions/async/getSignupLanguage';
import types from 'actions/types';
import PageRoutes from 'constants/PageRoutes';
import * as WEB from 'types/interfaces';
import { logger } from 'utils/logger';

export default (): WEB.ActionCreator<Promise<boolean>> =>
  async (dispatch: WEB.Dispatch): Promise<boolean> => {
    let skipErrorLog = false;
    try {
      dispatch({ type: types.UPDATE_APP_DATA_REQUEST });

      // STEP 1: Fetch app data and profile concurrently
      const dispatchQueue: any[] = [
        dispatch(getAppData()),
        dispatch(getProfile()),
      ];

      if (
        window.location.pathname.startsWith(PageRoutes.SIGNUP_BASE) &&
        window.location.pathname !== PageRoutes.DOWNLOAD_APP
      ) {
        // Assume we will need signup language since user is navigating to the signup flow
        dispatchQueue.push(dispatch(getSignupLanguage()));
      }
      const responses = await Promise.all(dispatchQueue);
      const [appDataResponse, profileResponse] = responses;
      let languageResponse = responses[2];

      [appDataResponse, profileResponse.response].forEach((action) => {
        if (action?.error) {
          const twoFARequired =
            action.error.response?.status === 403 &&
            (action.error.response.data?.is_2fa_required as boolean);
          skipErrorLog = twoFARequired || action.error.response?.status === 480;

          throw new Error('Failed to fetch app data or profile');
        }
      });

      const checkpoint = appDataResponse?.payload?.data?.['web_signup_screen'];

      if (checkpoint && languageResponse === undefined) {
        // User tried to navigate to a page that doesn't use signup language,
        // but we need to redirect them to another page which uses signup language
        languageResponse = await dispatch(getSignupLanguage());
      }
      if (languageResponse !== undefined) {
        // Only error if we actually needed to load and display language,
        if (languageResponse?.error && checkpoint) {
          const twoFARequired =
            languageResponse.response?.error?.response?.status === 403 &&
            languageResponse.response.error.response.data?.is_2fa_required;
          skipErrorLog =
            twoFARequired ||
            languageResponse.response?.error?.response?.status === 480;
          throw new Error('Failed to fetch signup language');
        }
        dispatch({
          type: types.UPDATE_SIGNUP_LANGUAGE_ORDER,
          payload: {
            signupGoalObjects:
              appDataResponse?.payload?.data['signup_goal_objects'],
          },
        });
      }

      // Identify with Albert and other services.
      TrackingBase.identify();

      return true;
    } catch (error) {
      dispatch({ type: types.UPDATE_APP_DATA_FAILURE });
      // We don't log for MFA responses or CSRF warnings
      if (!skipErrorLog && error instanceof Error) {
        logger.warn(error.message);
      }
      return false;
    }
  };
