import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import removeBanner from 'actions/banner/removeBanner';
import Banner from 'components/common/Banner';
import { UniqueBannerStyles } from 'constants/BannerActionType';
import { BannerActionType } from 'constants/index';
import * as banner from 'reducers/entities/banner';
import { breakpoints, spacers } from 'styles';
import { sizes } from 'styles/breakpoints';

export type Props = {
  className?: string;
  hideIcon?: boolean;
  show?: boolean;
  linkUrl?: string | null;
  linkMessage?: string;
};

const getPaddingTop = (
  show: boolean,
  bannerAction?: BannerActionType | null
) => {
  if (!show) {
    return 0;
  }
  if (bannerAction && UniqueBannerStyles.includes(bannerAction)) {
    return spacers.bannerMobile;
  }
  return spacers.largeExtra;
};
// Modules
const StyledContainer = styled.div<{
  show: boolean;
  bannerAction?: BannerActionType | null;
}>`
  width: 100%;
  position: relative;
  transition: 0.2s;
  padding-top: ${spacers.large};

  @media ${breakpoints.mobileLarge} {
    padding-top: ${({ show, bannerAction }) =>
      getPaddingTop(show, bannerAction)}
`;

const BannerPane = ({ className, hideIcon }: Props): React.ReactElement => {
  const dispatch = useDispatch();
  const currentBanner = useSelector(banner.getBanner);
  const isHidden = useSelector(banner.getIsHidden);

  // On update: scroll to top of page when displaying banner
  React.useEffect(() => {
    if (currentBanner && !isHidden) {
      // "setTimeout" is needed for this code to work
      setTimeout(() => {
        const hasScrollRestoration = 'scrollRestoration' in window.history;
        // disable scroll restoration to avoid page jumping effect
        if (hasScrollRestoration) {
          window.history.scrollRestoration = 'manual';
        }

        // Scroll to the top of the page, so the banner can be seen
        window.scrollTo({ top: 0, behavior: 'smooth' });
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

        // enable scroll restoration
        if (hasScrollRestoration) {
          window.history.scrollRestoration = 'auto';
        }
      }, 1);
    }
  }, [currentBanner, isHidden]);

  // Clean up: Remove banner on unmount
  React.useEffect(() => {
    return () => {
      if (currentBanner) {
        dispatch(removeBanner());
      }
    };
  }, []);

  return (
    <StyledContainer
      className={className}
      show={currentBanner !== null}
      bannerAction={currentBanner?.bannerAction}
    >
      {currentBanner && !isHidden && (
        <Banner {...currentBanner} hideIcon={hideIcon} />
      )}
    </StyledContainer>
  );
};

export const StyledBannerDesktop = styled(BannerPane)`
  @media ${breakpoints.mobileLarge} {
    display: none;
  }
`;

export const StyledBannerMobile = styled(BannerPane)`
  @media (min-width: ${sizes.mobileLarge}) {
    display: none;
  }
`;

export default BannerPane;
