import { unstable_useBlocker as useBlocker } from 'react-router-dom';

/**
 * Hook to interrupt clicking the browser
 * back button and forward button.
 *
 * @param onClick - callback to trigger when the
 * browser back button or forward button is clicked.
 * @param selectiveNavBlocking?: whether to return value of onClick
 *
 * @returns - callback to unblock browser navigation.
 */
const useBrowserNavigationOverride = (
  onClick: () => void | boolean,
  selectiveNavBlocking?: boolean
): void => {
  useBlocker(({ historyAction }) => {
    // Protect against overriding PUSH navigations,
    // i.e. completing a page and moving forward
    if (historyAction === 'POP') {
      // Override with a redirect
      if (onClick) {
        const result = !!onClick();
        if (selectiveNavBlocking) {
          return result;
        }
      }
      // Block
      return true;
    }
    // Do not block
    return false;
  });
};

export default useBrowserNavigationOverride;
