import styled from 'styled-components';
import { textTransition } from 'components/common/Text';

type Color = 'gray';

type LinkProps = {
  weight?: '400' | '500' | '600' | '700';
  underline?: boolean;
  color?: Color;
};

const getColor = (theme: any, color?: Color): string => {
  switch (color) {
    case 'gray':
      return theme.colors.primaryGray as string;
    default:
      return 'inherit';
  }
};
export default styled.a<LinkProps>`
  font-weight: ${({ weight }) => weight || '400'};
  color: ${({ color, theme }) => getColor(theme, color)};
  text-decoration: ${({ underline }) => (underline ? 'underline' : 'none')};
  // Remove gray background on tap in iOS Safari
  -webkit-tap-highlight-color: transparent;
  ${textTransition}
  &:visited,
  &:active,
  &:link {
    color: ${({ color, theme }) => getColor(theme, color)};
  }
  &:hover {
    color: ${({ theme }) => theme.colors.primaryText};
    svg {
      ${textTransition}
      color: ${({ theme }) => theme.colors.primaryText};
    }
  }
`;
