import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Button from 'components/common/Button';
import { MobileWrapper } from 'components/common/DeviceWrapper';
import ButtonsLayout from 'components/layout/ButtonsLayout';
import LeadContent from 'components/layout/LeadContent';
import PageBase from 'components/layout/PageBase';
import ArtMap from 'constants/ArtMap';
import Pages from 'constants/Pages';
import useConfetti from 'hooks/useConfetti';
import useNavigateFunction from 'hooks/useNavigateFunction';
import useVerifyCurrentPage from 'hooks/useVerifyCurrentPage';
import * as language from 'reducers/entities/language';
import * as profile from 'reducers/entities/profile';
import { breakpoints, theme } from 'styles';
import { spacers } from 'styles/home';
import * as WEB from 'types/interfaces';
import {
  getLanguageButtons,
  getLanguageInfo,
  getLanguageSection,
} from 'utils/getFromLanguage';

type Props = {
  nextPage: string;
};

const ImgMobileWrapper = styled(MobileWrapper)`
  @media ${breakpoints.mobileLarge} {
    display: flex;
    height: 300px;
    img {
      margin: auto;
    }
  }
`;

const CashDebitCardSetup = (props: Props) => {
  const dispatch = useDispatch();
  const { updateCurrentSignupPage } = useNavigateFunction();
  // Make sure Redux is up-to-date with currentSignUpPage
  useVerifyCurrentPage(Pages.CASH_DEBIT_CARD_SETUP);
  useConfetti();

  const languageCtx = useSelector((state: WEB.RootState) =>
    language.getSignupPageLanguage(state, Pages.CASH_DEBIT_CARD_SETUP)
  );
  const firstName = useSelector((state: WEB.RootState) =>
    profile.getValueByField(state, 'first_name')
  );

  const infoCtx = getLanguageInfo(languageCtx);
  const introCtx = getLanguageSection(languageCtx, 'intro');
  const buttonsCtx = getLanguageButtons(languageCtx);

  const onSubmit = async () => {
    dispatch(updateCurrentSignupPage({ page: props.nextPage }));
  };

  const commaName = firstName ? `, ${firstName}` : '';
  const header = introCtx?.header ? introCtx.header + commaName : '';

  return (
    <PageBase>
      <ImgMobileWrapper>
        <img src={ArtMap(infoCtx?.image?.filename || '')} alt='' />
      </ImgMobileWrapper>
      <LeadContent
        header={header}
        text={introCtx?.text || ''}
        desktopHeaderSize='small'
      />
      <ButtonsLayout
        marginTop={spacers.g9}
        primaryButton={
          <Button onClick={onSubmit} theme={theme.main}>
            {buttonsCtx?.primary}
          </Button>
        }
      />
    </PageBase>
  );
};

export default CashDebitCardSetup;
