import * as React from 'react';
import Spacer from 'components/common/Spacer';
import Text from 'components/common/Text';
import { spacers } from 'styles/home';
import { hash32 } from 'utils/hash';

type Props = {
  text: string | string[];
};

/**
 * Display "text" as either a single line or several lines
 */
const TextContent = ({ text }: Props) => {
  if (text instanceof Array) {
    return (
      <>
        {text.map((txt: string, i: number) => (
          <React.Fragment key={`info-text-fragment-${hash32(txt)}`}>
            {i !== 0 && <Spacer space={spacers.g1} />}
            <Text key={`info-text-${hash32(txt)}`}>{txt}</Text>
          </React.Fragment>
        ))}
      </>
    );
  }
  return <Text>{text}</Text>;
};

export default TextContent;
