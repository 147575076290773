/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import styled from 'styled-components';
import ErrorText from 'components/common/ErrorText';
import Spacer from 'components/common/Spacer';
import ArtMap, { Art } from 'constants/ArtMap';
import { mixins, spacers } from 'styles';
import { BaseInputProps } from 'types/interfaces';
import { logger } from 'utils/logger';
import {
  BaseInput,
  InputWrapper,
  baseInputTransition,
  renderLabels,
} from './shared';

export type Props = BaseInputProps & {
  /* mininum number */
  min?: string;
  /* maximum number */
  max?: string;
  /* with arrows - default to true */
  withArrows?: boolean;
  /* placeholder */
  placeholder?: string;
  /* whether or not to preserve the value of the input string, as in account numbers, to avoid losing leading or trailing 0s */
  noParse?: boolean;
};

type InternalProps = Props & {
  type: 'number';
  value: string | number;
};

const StyledNumberInput = styled(BaseInput)<InternalProps>`
  min-width: 100px;

  /* Hide default spinners for number input */
  -moz-appearance: textfield;
  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const StyledArrowWrapper = styled.div`
  position: absolute;
  /* Center a position absolute element (offset right for padding) */
  margin-top: auto;
  margin-bottom: auto;
  top: 0;
  bottom: 0;
  right: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  svg {
    color: ${({ theme }) => theme.colors.darkGray1};
    height: 17px;
    background-color: transparent;
    padding: 0;
    margin-right: ${mixins.pxToRem('4px')};
    outline: none;
    border: none;
    cursor: pointer;
    ${baseInputTransition}
  }
`;

const NumberInput = (props: Props): React.ReactElement => {
  const {
    id,
    invalid,
    valueDefault,
    errorText,
    min = '',
    max = '',
    onChange,
    widthSize,
    withArrows = true,
    noParse = true,
  } = props;

  const inputRef: React.MutableRefObject<any> = React.useRef();
  const [numberValue, setNumberValue] = React.useState<string | number>(
    valueDefault || min || ''
  );

  if (`${numberValue}`.indexOf('0') === 0 && !noParse) {
    logger.warn('Leading zeroes in numbers will be lost.');
  }

  const minRange = min ? Number.parseInt(min, 10) : -Infinity;
  const maxRange = max ? Number.parseInt(max, 10) : Infinity;

  const isBelowRange = (value: number) => value < minRange;
  const isAboveRange = (value: number) => value > maxRange;
  const isWithinRange = (value: number) => {
    return value >= minRange && value <= maxRange;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (!value) {
      // If user clears input, set to min
      setNumberValue(min);
    } else {
      // Strip leading zeroes
      const strippedNum = Number(value);
      setNumberValue(strippedNum.toString());
    }
  };

  const setInputRange = (value: number) => {
    if (isBelowRange(value)) setNumberValue(minRange);
    if (isAboveRange(value)) setNumberValue(maxRange);
  };

  const adjustInputRange = (value: number) => {
    if (!isWithinRange(value)) setInputRange(value);
    else setNumberValue(value);
  };

  const handleValidateInput = (e: React.FocusEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const numValue = Number.parseInt(value, 10);
    if (Number.isNaN(numValue)) e.target.value = min;
    const isInvalid =
      Number.isNaN(numValue) ||
      !e.target.checkValidity() ||
      !isWithinRange(numValue);
    if (isInvalid) setInputRange(numValue);
  };

  const handleIncreaseValue = () => {
    const val = Number.parseInt(`${numberValue}`, 10);
    adjustInputRange(val + 1);
    inputRef.current.focus();
  };

  const handleDecreaseValue = () => {
    const val = Number.parseInt(`${numberValue}`, 10);
    adjustInputRange(val - 1);
    inputRef.current.focus();
  };

  React.useEffect(() => {
    if (noParse) {
      onChange && onChange(numberValue);
    } else {
      onChange && onChange(Number(numberValue));
    }
  }, [numberValue]);

  return (
    <div>
      {renderLabels(props)}
      <InputWrapper widthSize={widthSize} className='input-wrapper'>
        <StyledNumberInput
          type='number'
          ref={inputRef}
          {...props}
          onChange={handleChange}
          onBlur={handleValidateInput}
          value={numberValue}
          inputMode='numeric'
        />
        {withArrows && (
          <StyledArrowWrapper>
            <img
              src={ArtMap(Art.ChevronUp)}
              alt='increase'
              onClick={handleIncreaseValue}
            />
            <Spacer space={spacers.miniscule} />
            <Spacer space={spacers.submicroscopic} />
            <img
              src={ArtMap(Art.ChevronDown)}
              alt='decrease'
              onClick={handleDecreaseValue}
            />
          </StyledArrowWrapper>
        )}
      </InputWrapper>
      {invalid && errorText && <ErrorText name={id} text={errorText} />}
    </div>
  );
};

export default NumberInput;
