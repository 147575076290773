import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import updateProfile from 'actions/async/updateProfile';
import addBanner from 'actions/banner/addBanner';
import removeBanner from 'actions/banner/removeBanner';
import getNextPage from 'actions/getNextPage';
import BannerPane from 'components/BannerPane';
import Button from 'components/common/Button';
import Link from 'components/common/Link';
import CheckCircleList from 'components/common/List/CheckCircleList';
import Modal from 'components/common/Modal';
import Spacer from 'components/common/Spacer';
import Text, { TextColor, TextSizes } from 'components/common/Text';
import TextButton from 'components/common/TextButton';
import ButtonsContainer from 'components/layout/ButtonsContainer';
import ContentPane, { ContentContainer } from 'components/layout/ContentPane';
import InfoPane from 'components/layout/InfoPane';
import InfoPaneExpanderGroup from 'components/layout/InfoPaneExpanderGroup';
import PageBase from 'components/layout/PageBase';
import TwoPaneWrapper from 'components/layout/TwoPaneWrapper';
import DirectDepositSwitchSources from 'constants/DirectDepositSwitchSources';
import Pages from 'constants/Pages';
import { ErrorMessages, NotificationTypes, SplashLink } from 'constants/index';
import useBrowserNavigationOverrideWithBanner from 'hooks/useBrowserNavigationBlockWithBanner';
import useNavigateFunction from 'hooks/useNavigateFunction';
import useVerifyCurrentPage from 'hooks/useVerifyCurrentPage';
import DirectDepositNoBonus from 'pages/banking/DirectDepositNoBonus';
import Navbar from 'pages/unauthenticated/Navbar';
import * as appData from 'reducers/entities/appData';
import * as language from 'reducers/entities/language';
import * as profile from 'reducers/entities/profile';
import { breakpoints, spacers } from 'styles';
import { spacers as homeSpacers } from 'styles/home';
import * as WEB from 'types/interfaces';
import { fetchAtomicAccessToken, openAtomicWindow } from 'utils/atomicUtils';
import { delayWithState } from 'utils/delay';
import {
  getLanguageButtons,
  getLanguageInfo,
  getLanguageSection,
} from 'utils/getFromLanguage';
import { logger } from 'utils/logger';

type Props = {
  nextPage: string;
};

const HideOnMobile = styled.div`
  @media ${breakpoints.mobileLarge} {
    display: none;
  }
`;

export const SecondaryLinkWrapper = styled.div`
  margin-right: ${spacers.tabLarge};
  @media ${breakpoints.mobileLarge} {
    margin-right: 0;
  }
`;

const DirectDeposit = (props: Props): React.ReactElement => {
  const { nextPage } = props;

  // //////////////////////////////////
  /* =========== HOOKS ========== */
  // //////////////////////////////////
  // Make sure Redux is up-to-date with currentSignupPage
  useVerifyCurrentPage(Pages.DIRECT_DEPOSIT);
  useBrowserNavigationOverrideWithBanner();
  const { updateCurrentSignupPage } = useNavigateFunction();

  const dispatch = useDispatch();

  // ///////////////////////////////
  /* ========== CONSTANTS ========= */
  // ///////////////////////////////
  const MODAL_SUCCESS_DEFAULTS = {
    header: 'We’re working on connecting your direct deposit',
    text: 'This may take up to 48 hours. We’ll text you when your direct deposit connection is complete.',
    primary: 'Okay',
  };
  const MODAL_ERROR_DEFAULTS = {
    header: 'Unable to link direct deposit',
    text: 'We’re sorry — something went wrong and we were unable to automatically link your direct deposit to Albert. \n\nYou can still set up direct deposit manually in the app after your account is created.',
    primary: 'Okay',
  };

  // ///////////////////////////////
  /* =========== STATE ========== */
  // ///////////////////////////////
  const [atomicKey, setAtomicKey] = React.useState<string | null>(null);
  const [isButtonLoading, setIsButtonLoading] = React.useState(false);
  const [isCompleted, setIsCompleted] = React.useState(false);

  const [modalTitle, setModalTitle] = React.useState('');
  const [modalText, setModalText] = React.useState('');
  const [modalButton, setModalButton] = React.useState('');
  const [modalIcon, setModalIcon] = React.useState('');
  const [showModal, setShowModal] = React.useState(false);

  // ///////////////////////////////
  /* ======== MAPPED STATE ====== */
  // ///////////////////////////////
  const languageCtx = useSelector((state: WEB.RootState) =>
    language.getSignupPageLanguage(state, Pages.DIRECT_DEPOSIT)
  );
  const bankAccount = useSelector((state: WEB.RootState) =>
    appData.getBankingAccount(state)
  );

  const signupDDInterest: null | boolean = useSelector((state: WEB.RootState) =>
    profile.getInfoValueByField(state, 'personal_info', 'signup_dd_bonus')
  );
  const hasBonusEligibility: null | boolean = useSelector(
    (state: WEB.RootState) =>
      profile.getInfoValueByField(state, 'personal_info', 'bonus_eligibility')
  );

  // ///////////////////////////////
  /* ========= LANGUAGE ======== */
  // ///////////////////////////////
  const INTRO_KEY = 'intro';
  const ITEMS_KEY = 'list';
  const MODAL_SUCCESS_KEY = 'successModal';
  const MODAL_ERROR_KEY = 'errorModal';

  const infoPaneContext = getLanguageInfo(languageCtx);
  const introSection = getLanguageSection(languageCtx, INTRO_KEY);
  const items = getLanguageSection(languageCtx, ITEMS_KEY) as WEB.LanguageList;
  const successModal = getLanguageSection(languageCtx, MODAL_SUCCESS_KEY);
  const errorModal = getLanguageSection(languageCtx, MODAL_ERROR_KEY);
  const buttonCtx = getLanguageButtons(languageCtx);

  // ///////////////////////////////
  /* ========= HANDLERS ======== */
  // ///////////////////////////////
  const handleAtomicSuccess = (): void => {
    setModalTitle(successModal.header || MODAL_SUCCESS_DEFAULTS.header);
    setModalText(successModal.text || MODAL_SUCCESS_DEFAULTS.text);
    setModalButton(successModal.primary || MODAL_SUCCESS_DEFAULTS.primary);
    setModalIcon(NotificationTypes.SUCCESS);
    setShowModal(true);
  };

  const goToNextPage = async (): Promise<void> => {
    setIsButtonLoading(true);
    const { error, nextPageOverride }: any = await dispatch(getNextPage());
    setIsButtonLoading(false);
    if (!error) {
      await delayWithState(400, setIsCompleted);
      dispatch(updateCurrentSignupPage({ page: nextPageOverride || nextPage }));
    }
  };

  const handleOnLater = (): void => {
    // Set Profile.personal_info['signup_dd_interest'].
    // See ASDirectDepositSetupWireframe.swift > showDirectDepositSetupPresenter().
    dispatch(
      updateProfile({
        personal_info_signup_dd_interest: false,
      })
    );
    goToNextPage();
  };

  const handleAtomicError = (): void => {
    // Reset the button loading if atomic fails.
    setIsButtonLoading(false);

    setModalTitle(errorModal.header || MODAL_ERROR_DEFAULTS.header);
    setModalText(errorModal.text || MODAL_ERROR_DEFAULTS.text);
    setModalButton(errorModal.primary || MODAL_ERROR_DEFAULTS.primary);
    setModalIcon(NotificationTypes.NEUTRAL_WARNING);
    setShowModal(true);
  };

  const handleAtomicInteraction = (interaction: any): void => {
    // Detect when the Atomic modal initialization requests are complete
    if (interaction.name === 'Initialized Transact') {
      setIsButtonLoading(false);
    }
  };

  const openAtomicModal = async (
    event?: React.KeyboardEvent<HTMLElement>
  ): Promise<void> => {
    if (!bankAccount) {
      dispatch(addBanner(NotificationTypes.WARNING, ErrorMessages.web.generic));
      event?.currentTarget?.blur();
      return;
    }

    dispatch(removeBanner());
    setIsButtonLoading(true);

    let atomicToken = atomicKey;
    if (!atomicToken) {
      try {
        atomicToken =
          (await fetchAtomicAccessToken(bankAccount?.id as number)) || null;
        setAtomicKey(atomicToken);
      } catch (error) {
        logger.error(error);
      }
    }

    // Set Profile.personal_info['signup_dd_interest'].
    // See ASDirectDepositSetupWireframe.swift > showDirectDepositSetupPresenter().
    if (!signupDDInterest) {
      dispatch(
        updateProfile({
          personal_info_signup_dd_interest: true,
        })
      );
    }

    // Show loading state for the requests the Atomic modal makes before opening.
    setIsButtonLoading(true);
    openAtomicWindow(
      atomicToken,
      DirectDepositSwitchSources.SIGNUP,
      handleAtomicSuccess,
      handleAtomicError,
      handleAtomicInteraction
    );
  };

  // ///////////////////////////////
  /* ========= RENDER ======== */
  // ///////////////////////////////
  const leadText =
    typeof introSection?.text === 'string' ? [introSection.text] : [];

  return (
    <>
      <Navbar split signupFlow />
      <TwoPaneWrapper>
        {hasBonusEligibility ? (
          <>
            <InfoPane.Desktop {...infoPaneContext} />
            <ContentPane>
              <BannerPane />
              <ContentContainer>
                <PageBase
                  mobileHeader={infoPaneContext?.header || ''}
                  mobileLead={leadText}
                  buttonSpace='small'
                >
                  <HideOnMobile>
                    <Text weight='700'>{introSection.header}</Text>
                    <Spacer space={spacers.small} />
                    <Text>{introSection.text}</Text>
                  </HideOnMobile>
                  <Spacer space={homeSpacers.g3} />
                  <CheckCircleList items={items} />
                  <Spacer space={homeSpacers.g10} desktopOnly />
                  <InfoPaneExpanderGroup
                    expanders={infoPaneContext.expanders}
                    addExpanderSubheadSpacing={false}
                    mobileOnly
                  />
                  <Spacer space={homeSpacers.g3} />
                  <Text color={TextColor.GRAY} size={TextSizes.SMALL}>
                    Earn $150 when you get your paycheck deposited into Albert
                    and receive a qualifying deposit of $200 or more and spend
                    with your Albert debit card. By clicking Continue, you agree
                    to Atomic&apos;s{' '}
                    <Link
                      href={SplashLink.ATOMIC_PRIVACY as string}
                      target='_blank'
                      rel='noreferrer'
                      underline
                      weight='700'
                    >
                      privacy policy
                    </Link>
                    .
                  </Text>

                  <ButtonsContainer>
                    <SecondaryLinkWrapper className='secondary-button'>
                      <TextButton>
                        <Text
                          isLinkButton
                          underline
                          nowrap
                          weight='700'
                          size={TextSizes.MEDIUM}
                          onClick={handleOnLater}
                        >
                          {buttonCtx.link}
                        </Text>
                      </TextButton>
                    </SecondaryLinkWrapper>
                    <Button
                      stretch
                      onClick={(e) => openAtomicModal(e)}
                      isCompleted={isCompleted}
                      isLoading={isButtonLoading}
                      disabled={isButtonLoading}
                    >
                      {isCompleted ? null : buttonCtx.primary}
                    </Button>
                  </ButtonsContainer>
                </PageBase>
              </ContentContainer>
            </ContentPane>
          </>
        ) : (
          <DirectDepositNoBonus
            isLoading={isButtonLoading}
            isCompleted={isCompleted}
            onClickContinue={(e) => openAtomicModal(e)}
            onClickLater={handleOnLater}
          />
        )}
      </TwoPaneWrapper>
      <Modal.Basic
        title={modalTitle}
        description={modalText}
        submitLabel={modalButton}
        // Both modals shown should take user to next page
        onSubmit={goToNextPage}
        isSubmitting={isButtonLoading}
        isCompleted={isCompleted}
        iconType={modalIcon}
        show={showModal}
      />
    </>
  );
};

export default DirectDeposit;
