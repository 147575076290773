import * as React from 'react';
import styled from 'styled-components';
import Spacer from 'components/common/Spacer';
import CheckCircleRounded from 'components/icons/CheckCircleRounded';
import { mixins } from 'styles';
import breakpoints from 'styles/breakpoints';
import { spacers } from 'styles/home';
import { hash32 } from 'utils/hash';
import { flexGap } from 'utils/polyfills';

type Item = {
  text: string;
};
type Props = {
  items: Item[];
  mobileSpace?: string;
};

const ListContainer = styled.div`
  display: flex;
  align-items: center;
  ${flexGap('18px')}
`;

const CheckCircleContainer = styled.div`
  width: ${mixins.pxToRem('20px')};
  color: ${({ theme }) => theme.colors.primaryAlbertBrand};
  margin-top: 3px;
`;

const ListSpace = styled.div<{ mobileSpace: string }>`
  padding-top: ${spacers.g2};
  @media ${breakpoints.mobileLarge} {
    padding-top: ${({ mobileSpace }) => mobileSpace};
  }
`;

const CheckCircleList = ({ items, mobileSpace }: Props) => (
  <>
    <Spacer space={spacers.g2} />
    {items.map((item) => {
      return (
        <React.Fragment key={hash32(item.text)}>
          <ListSpace mobileSpace={mobileSpace || spacers.g2} />
          <ListContainer>
            <CheckCircleContainer>
              <CheckCircleRounded />
            </CheckCircleContainer>
            {item.text}
          </ListContainer>
        </React.Fragment>
      );
    })}
  </>
);

export default CheckCircleList;
