// "Source" field for Banking's DirectDepositSwitch model
enum DirectDepositSwitchSources {
  APP_NOTIFICATION = 'an',
  CASH_SETTINGS = 'cs',
  INSTANT_ADVANCE = 'ia',
  MOVE_MONEY_TAB = 'm',
  SIGNUP = 's',
}

export default DirectDepositSwitchSources;
