import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import getNextPage from 'actions/getNextPage';
import Button from 'components/common/Button';
import Text from 'components/common/Text';
import ButtonsContainer from 'components/layout/ButtonsContainer';
import PageBase from 'components/layout/PageBase';
import ArtMap from 'constants/ArtMap';
import Pages from 'constants/Pages';
import useNavigateFunction from 'hooks/useNavigateFunction';
import * as language from 'reducers/entities/language';
import { breakpoints } from 'styles';
import * as WEB from 'types/interfaces';
import { getLanguageButtons, getLanguageSection } from 'utils/getFromLanguage';

type Props = {
  nextPage: string;
};

const StyledHeader = styled.h1`
  @media ${breakpoints.mobileLarge} {
    margin: 0;
  }
`;

const InstantConfirm = (props: Props): React.ReactElement => {
  // ///////////////////////////////
  /* =========== HOOKS ========== */
  // ///////////////////////////////
  const { updateCurrentSignupPage } = useNavigateFunction();
  const dispatch = useDispatch();

  // ///////////////////////////////
  /* ======== MAPPED STATE ====== */
  // ///////////////////////////////
  const languageCtx = useSelector((state: WEB.RootState) =>
    language.getSignupPageLanguage(state, Pages.INSTANT_CONFIRM)
  );
  // Determine whether we are in the newer Set Up First products flow
  const isSetUpFirst = useSelector(language.hasSetUpFirst);

  // //////////////////////////////////
  /* =========== HANDLERS ========== */
  // //////////////////////////////////
  const handleOnNext = async () => {
    if (isSetUpFirst) {
      const { nextPageOverride }: any = await dispatch(getNextPage());
      dispatch(
        updateCurrentSignupPage({
          page: (isSetUpFirst && nextPageOverride) || props.nextPage,
        })
      );
    } else {
      dispatch(updateCurrentSignupPage({ page: props.nextPage }));
    }
  };
  // //////////////////////////////////
  /* =========== LANGUAGE ========== */
  // //////////////////////////////////

  const INTRO_KEY = 'intro';
  const introSection = getLanguageSection(languageCtx, INTRO_KEY);
  const buttonsCtx = getLanguageButtons(languageCtx);

  return (
    <PageBase>
      <StyledHeader>
        {introSection?.header}{' '}
        {introSection?.image && (
          <img
            src={ArtMap(
              introSection?.image?.filename ? introSection?.image?.filename : ''
            )}
            alt='Thumbs up'
            width={introSection?.image?.width}
          />
        )}
      </StyledHeader>
      <Text className='description'>{introSection?.text}</Text>
      <ButtonsContainer>
        <Button id='instant-continue-button' onClick={handleOnNext}>
          {buttonsCtx?.primary}
        </Button>
      </ButtonsContainer>
    </PageBase>
  );
};

export default InstantConfirm;
