import { FORM_ERROR } from 'final-form';
import * as React from 'react';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import updateProfile from 'actions/async/updateProfile';
import Button from 'components/common/Button';
import Input from 'components/common/Input';
import Spacer from 'components/common/Spacer';
import ButtonsLayout from 'components/layout/ButtonsLayout';
import LeadContent from 'components/layout/LeadContent';
import PageBase from 'components/layout/PageBase';
import Pages from 'constants/Pages';
import useNavigateFunction from 'hooks/useNavigateFunction';
import useVerifyCurrentPage from 'hooks/useVerifyCurrentPage';
import * as language from 'reducers/entities/language';
import * as profile from 'reducers/entities/profile';
import { spacers } from 'styles/home';
import * as WEB from 'types/interfaces';
import { delayWithState } from 'utils/delay';
import { getLanguageButtons, getLanguageSection } from 'utils/getFromLanguage';
import { composeValidators, isNotEmpty, isRequired } from 'utils/validators';

type Props = {
  nextPage: string;
};

const Employment = (props: Props) => {
  const { updateCurrentSignupPage } = useNavigateFunction();
  const dispatch = useDispatch();
  // Make sure Redux is up-to-date with currentSignUpPage
  useVerifyCurrentPage(Pages.EMPLOYMENT);
  const [isCompleted, setIsCompleted] = React.useState<boolean>(false);

  const languageCtx = useSelector((state: WEB.RootState) =>
    language.getSignupPageLanguage(state, Pages.EMPLOYMENT)
  );

  const promptCtx = getLanguageSection(languageCtx, 'prompt');
  const employerSection = getLanguageSection(languageCtx, 'employer');
  const jobTitleSection = getLanguageSection(languageCtx, 'jobTitle');
  const buttonsCtx = getLanguageButtons(languageCtx);

  const defaultEmploymentPosition = useSelector((state: WEB.RootState) =>
    profile.getInfoValueByField(state, 'investment_info', 'employment_position')
  );
  const defaultEmployerName = useSelector((state: WEB.RootState) =>
    profile.getInfoValueByField(state, 'investment_info', 'employer_name')
  );

  const onSubmit = async (values: Record<string, any>) => {
    const { error }: any = await dispatch(
      updateProfile({
        investment_info: {
          employer_name: values.employer?.trim(),
          employment_position: values.jobTitle?.trim(),
        },
      })
    );
    if (error) return { [FORM_ERROR]: 'error' };
  };
  const submitCallback = async (errors?: unknown): Promise<void> => {
    if (!errors) {
      await delayWithState(400, setIsCompleted);
      dispatch(updateCurrentSignupPage({ page: props.nextPage }));
    }
  };

  const EmployerInput = ({ input, meta }: Partial<WEB.InputFieldProps>) => {
    return (
      <Input.Text
        id='employer-input'
        type='text'
        placeholder={employerSection?.placeholder}
        floatingLabel={employerSection?.placeholder}
        {...input}
        invalid={meta?.error && meta.submitFailed && !meta.dirtySinceLastSubmit}
        errorText={employerSection?.errorMessage || meta?.error}
      />
    );
  };
  const JobTitleInput = ({ input, meta }: Partial<WEB.InputFieldProps>) => {
    return (
      <Input.Text
        id='job-title-input'
        type='text'
        placeholder={jobTitleSection?.placeholder}
        floatingLabel={jobTitleSection?.placeholder}
        {...input}
        invalid={meta?.error && meta.submitFailed && !meta.dirtySinceLastSubmit}
        errorText={jobTitleSection?.errorMessage || meta?.error}
      />
    );
  };

  return (
    <PageBase>
      <LeadContent
        header={promptCtx?.header || ''}
        text={promptCtx?.text || ''}
        desktopHeaderSize='small'
      />
      <Form
        onSubmit={onSubmit}
        initialValues={{
          employer: defaultEmployerName,
          jobTitle: defaultEmploymentPosition,
        }}
        render={({ handleSubmit, submitting }) => (
          <form
            onSubmit={(event) => {
              handleSubmit(event)?.then(submitCallback);
            }}
          >
            <Spacer space={spacers.g4} />
            <Spacer space={spacers.g4} desktopOnly />
            <Field
              name='employer'
              component={EmployerInput}
              validate={composeValidators(isRequired, isNotEmpty)}
            />
            <Spacer space={spacers.g5} desktopOnly />
            <Field
              name='jobTitle'
              component={JobTitleInput}
              validate={composeValidators(isRequired, isNotEmpty)}
            />
            <ButtonsLayout
              marginTop={spacers.g9}
              primaryButton={
                <Button
                  isLoading={submitting}
                  disabled={submitting}
                  isCompleted={isCompleted}
                >
                  {isCompleted ? '' : buttonsCtx?.primary}
                </Button>
              }
            />
          </form>
        )}
      />
    </PageBase>
  );
};

export default Employment;
