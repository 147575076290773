import * as React from 'react';
import { IconContainer, Props } from './shared';

const PrimaryBankAccountIcon = (props: Props): React.ReactElement => {
  const { color } = props;

  return (
    <IconContainer>
      <svg
        width='28'
        height='21'
        viewBox='0 0 28 21'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g clipPath='url(#clip0_9839_5451)'>
          <path
            d='M2.57105 16.7966V4.47664C2.57105 3.52464 3.29905 2.79664 4.25105 2.79664H23.8511C24.8031 2.79664 25.5311 3.52464 25.5311 4.47664V16.7966C25.5311 17.7486 24.8031 18.4766 23.8511 18.4766H4.25105C3.29905 18.4766 2.57105 17.7486 2.57105 16.7966ZM0.331055 16.7966C0.331055 18.9806 2.06705 20.7166 4.25105 20.7166H23.8511C26.0351 20.7166 27.7711 18.9806 27.7711 16.7966V4.47664C27.7711 2.29264 26.0351 0.556641 23.8511 0.556641H4.25105C2.06705 0.556641 0.331055 2.29264 0.331055 4.47664V16.7966ZM9.96305 11.7286C9.96305 14.1646 11.9231 16.2366 14.4431 16.2366C15.2271 16.2366 15.9551 16.0406 16.5991 15.6766C17.2431 16.0406 17.9711 16.2366 18.7831 16.2366C21.2191 16.2366 23.2911 14.1646 23.2911 11.7286C23.2911 9.20864 21.2191 7.24864 18.7831 7.24864C17.9711 7.24864 17.2431 7.44464 16.5991 7.80864C15.9551 7.44464 15.2271 7.24864 14.4431 7.24864C11.9231 7.24864 9.96305 9.20864 9.96305 11.7286ZM11.7831 11.7286C11.7831 10.2166 12.9311 9.06864 14.4431 9.06864C14.6671 9.06864 14.8911 9.09664 15.0871 9.15264C14.5831 9.88064 14.3031 10.7486 14.3031 11.7286C14.3031 12.6806 14.6111 13.5766 15.1151 14.3326C14.8911 14.3886 14.6671 14.4166 14.4431 14.4166C12.9311 14.4166 11.7831 13.1566 11.7831 11.7286ZM16.1231 11.7286C16.1231 11.1406 16.2911 10.5806 16.5991 10.1606C16.9351 10.5806 17.1311 11.1406 17.1311 11.7286C17.1311 12.2886 16.9351 12.8206 16.5991 13.2686C16.2911 12.8206 16.1231 12.2886 16.1231 11.7286ZM18.0831 14.3326C18.6431 13.5766 18.9511 12.6806 18.9511 11.7286C18.9511 10.7486 18.6431 9.88064 18.1391 9.15264C18.3351 9.09664 18.5591 9.06864 18.7831 9.06864C20.2111 9.06864 21.4711 10.2166 21.4711 11.7286C21.4711 13.1566 20.2111 14.4166 18.7831 14.4166C18.5311 14.4166 18.3071 14.3886 18.0831 14.3326Z'
            fill={color || '#333333'}
          />
        </g>
        <defs>
          <clipPath id='clip0_9839_5451'>
            <rect
              width='27.44'
              height='20.16'
              fill='white'
              transform='translate(0.331055 0.556641)'
            />
          </clipPath>
        </defs>
      </svg>
    </IconContainer>
  );
};

export default PrimaryBankAccountIcon;
