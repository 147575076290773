import * as React from 'react';
import styled from 'styled-components';
import Text, { textTransition } from 'components/common/Text';
import Icons, {
  AboutYouGuidanceIcons,
} from 'components/icons/AboutYouGuidance/index';
import SquareCardFilledCheck from 'components/icons/SquareCardFilledCheck';
import { colors, mixins } from 'styles';
import { breakpoints } from 'styles';
import { handleActivateKeyDown } from 'utils/accessibility';

type Props = {
  /* the state of the card */
  selected?: boolean | false;
  /* the size of the card ie. large, small */
  size?: string;
  /* image on the card */
  image?: AboutYouGuidanceIcons;
  /* body text on the card */
  body?: string;
  /* error state of the card */
  isError?: boolean;
  /* the theme to use for this component */
  theme?: any;
  /* tab index to set so card can be accessed using tab key */
  tabIndex?: number;
  /* onClick handler function */
  onClick?: (...args: any) => any;
  /* Whether to fill space on mobile */
  fillMobile?: boolean;
};

const FilledSquareCard = styled.div<Props>`
  border: 1.5px solid
    ${({ theme }) => (theme ? theme.colors.primaryText : colors.primaryText)};
  ${({ theme, selected }) => {
    if (selected) {
      return `background-color: ${
        theme ? theme.colors.primaryAlbertBrand : colors.primaryAlbertBrand
      };
        span {
          color: ${theme ? theme.colors.white : colors.white};
        }
        border-color: ${
          theme ? theme.colors.primaryAlbertBrand : colors.primaryAlbertBrand
        };
        path {
          fill: ${theme ? theme.colors.white : colors.white};
        }
        path.square-card-filled-check-stroke {
          fill: inherit;
          stroke: ${theme ? theme.colors.white : colors.white};
        }`;
    }
    return '';
  }};
  ${({ theme, isError }) =>
    isError &&
    `border-color: ${theme ? theme.colors.primaryError : colors.primaryError};`}
  border-radius: 8px;
  padding: 20px;
  height: ${({ size }) => size || '148px'};
  width: ${({ size }) => size || '148px'};
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${textTransition};
  // Media query prevents issues with hover state sticking on touchscreens
  @media (hover: hover) {
    &:hover {
      cursor: pointer;
      background-color: ${({ theme }) =>
        theme ? theme.colors.primaryAlbertBrand : colors.primaryAlbertBrand};
      border-color: ${({ theme }) =>
        theme ? theme.colors.primaryAlbertBrand : colors.primaryAlbertBrand};
      span {
        color: ${({ theme }) => (theme ? theme.colors.white : colors.white)};
      }
      path {
        fill: ${({ theme }) => (theme ? theme.colors.white : colors.white)};
      }
      path.square-card-filled-check-stroke {
        fill: inherit;
        stroke: ${({ theme }) => (theme ? theme.colors.white : colors.white)};
      }
    }
  }

  @media ${breakpoints.institutionGrid} {
    max-width: none;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    height: 112px;
  }
`;

const HeaderImagesContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const BodyText = styled(Text)`
  line-height: ${mixins.pxToRem('19px')};
`;

const SquareCardFilled = (props: Props): React.ReactElement => {
  // ///////////////////////////////
  /* ====== STATE AND PROPS ====== */
  // ///////////////////////////////
  const { selected, size, image, body, isError, tabIndex, onClick } = props;

  // ///////////////////////////////
  /* =========== RENDER ========== */
  // ///////////////////////////////
  const getIconComponent = (name: string): React.ReactElement => {
    if (
      Object.values(AboutYouGuidanceIcons).includes(
        name as AboutYouGuidanceIcons
      )
    ) {
      const IconComponent = Icons[name];
      return <IconComponent />;
    }
    return <></>;
  };

  return (
    <FilledSquareCard
      onClick={onClick}
      onKeyDown={(e) => handleActivateKeyDown(e, onClick)}
      selected={selected}
      size={size}
      isError={isError}
      tabIndex={tabIndex || 0}
    >
      <HeaderImagesContainer>
        {image && getIconComponent(image)}
        <SquareCardFilledCheck />
      </HeaderImagesContainer>
      <BodyText weight='700'>{body}</BodyText>
    </FilledSquareCard>
  );
};

export default SquareCardFilled;
