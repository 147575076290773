const KYCVerifyIdentityWebLanguage = {
  KYCVerifyIdentityWeb: {
    route: 'verify-identity',
    altInfo: {},
    info: {
      image: {
        filename: 'img_license_scan.svg',
        width: 100,
      },
    },
    content: {},
    buttons: {},
  },
};

export default KYCVerifyIdentityWebLanguage;
