/* eslint-disable no-param-reassign */

/* eslint-disable @typescript-eslint/no-explicit-any */
import produce from 'immer';
import initialState from 'store.initialState';
import types from 'actions/types';
import { DeviceType } from 'constants/index';
import * as WEB from 'types/interfaces';

// /////////////////////////////////////////////////////////////////////////////
// selectors
// /////////////////////////////////////////////////////////////////////////////

// Get all values from Profile
export const getAllValues = (state: WEB.RootState): any => {
  return state.entities.profile;
};

// Get values from Profile fields
export const getValueByField = (state: WEB.RootState, field: string): any => {
  const { profile } = state.entities;
  return profile?.[field] || (profile?.[field] === 0 ? 0 : null);
};

// Get values from Profile.[infoKey].value JSON field
export const getInfoValueByField = (
  state: WEB.RootState,
  infoKey: string,
  field: string
): any => {
  const info = state.entities.profile?.[infoKey] || {};
  const infoValue = info?.[field];
  return infoValue !== undefined ? infoValue : null;
};

// Determine if user has downloaded app.
export const getDidDownloadApp = (state: WEB.RootState): boolean => {
  // NOTE: We know user downloaded app if device_type_raw is either 'i' (ios) or 'a' (android).
  // Once this field has been updated to either one, it will never be 'w' (web) again.
  const deviceType = state.entities.profile.device_type_raw;
  return deviceType === DeviceType.IOS || deviceType === DeviceType.ANDROID;
};

// Whether this user is eligible for a free month of Genius
export const getHasFreeMonthGeniusOffer = (state: WEB.RootState): boolean =>
  !state.entities.profile.is_drop_free_month_for_annual_available;

export const getDefaultTierPrice = (state: WEB.RootState) => {
  const tieredPricing = state.entities.profile.tiered_pricing;
  return tieredPricing?.tiers?.find(
    (tier: WEB.GeniusTier) => tier.tier_key === tieredPricing.default_tier
  )?.monthly_price;
};

// /////////////////////////////////////////////////////////////////////////////
// reducers
// /////////////////////////////////////////////////////////////////////////////

/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const profile = (state = initialState.entities.profile, action: WEB.Action) => {
  const { type, payload, error } = action;
  return produce(state, (draft: WEB.RootState['entities']['profile']) => {
    switch (type) {
      case types.CREATE_PROFILE_SUCCESS:
      case types.UPDATE_PROFILE_SUCCESS:
      case types.UPDATE_PHONE_SUCCESS:
      case types.UPDATE_EMAIL_SUCCESS:
      case types.ENABLE_GENIUS_SUCCESS:
      case types.GET_PROFILE_SUCCESS: {
        return payload.data || {};
      }

      case types.VERIFY_EXTERNAL_ACCOUNT_SUCCESS: {
        if (payload.data?.profile) {
          return payload.data?.profile;
        }
        return;
      }

      case types.GET_GENIUS_LANGUAGE_SUCCESS: {
        return payload.data?.profile || {};
      }

      case types.LOGOUT_SUCCESS: {
        return initialState.entities.profile;
      }
      default:
    }
  });
};

export default profile;
