import types from 'actions/types';
import AlbertClient from 'api/AlbertClient';
import * as WEB from 'types/interfaces';
import {
  onError,
  onErrorDisplayDefaultBanner,
} from 'utils/axiosMiddlewareUtils';

export type InputPayload = {
  achAccountId: number;
  transactionId: number;
  suppressErrorBanner?: boolean;
};

export default ({
    achAccountId,
    transactionId,
    suppressErrorBanner,
  }: InputPayload): WEB.ActionCreator<Promise<Record<string, any>>> =>
  async (dispatch: WEB.Dispatch): Promise<Record<string, any>> => {
    let error = false;
    await dispatch({
      types: [
        types.CANCEL_ACH_TRANSACTION_REQUEST,
        types.CANCEL_ACH_TRANSACTION_SUCCESS,
        types.CANCEL_ACH_TRANSACTION_FAILURE,
      ],
      payload: {
        request: {
          method: 'delete',
          url: AlbertClient.bankingACHTransactionCancel(
            achAccountId,
            transactionId
          ),
        },
        options: {
          onError: (middlewareOptions: any, options: any) => {
            error = true;
            return suppressErrorBanner
              ? onError(middlewareOptions, options)
              : onErrorDisplayDefaultBanner(middlewareOptions, options);
          },
        },
      },
    });
    return { error };
  };
