import * as React from 'react';
import styled from 'styled-components';
import ArtMap, { Art } from 'constants/ArtMap';
import { breakpoints, mixins, spacers } from 'styles';

/* eslint-disable */
type Props = {
  bottomPadding?: boolean;
  width?: string;
  shrink?: boolean;
  height?: string;
  onClick?: () => void;
};
/* eslint-enable */

const StyledImage = styled.img<Props>`
  width: ${({ width, shrink }) => {
    if (width) return width;
    return shrink ? '92px' : '119.141px';
  }};
  ${({ height }) => height && `height: ${height};`}
  transition: 0.2s linear;
  ${({ bottomPadding }) =>
    bottomPadding && `padding-bottom: ${mixins.pxToRem(spacers.largeExtra)};`};

  @media ${breakpoints.mobileLarge} {
    width: ${({ width }) => width || ' 105.922px'};
  }
`;

const AlbertLogo = (props: Props): React.ReactElement => {
  return <StyledImage src={ArtMap(Art.Logo)} {...props} alt='Albert home' />;
};

export default AlbertLogo;
