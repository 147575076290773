enum AccountType {
  CREDIT = 'credit',
  BANKING = 'banking',
  LOANS = 'loans',
  INVESTMENTS = 'investments',
  PROPERTY = 'property',
  INSURANCE = 'insurance',
  OTHER = 'other',
  UNCATEGORIZED = 'uncategorized',
}

export enum AccountTypeShort {
  BANKING = 'b',
  CREDIT = 'c',
  LOANS = 'l',
  INVESTMENTS = 'i',
}

export default AccountType;
