import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PageType } from 'constants/Pages';
import * as genius from 'reducers/ui/genius';
import * as kyc from 'reducers/ui/kyc';
import * as signup from 'reducers/ui/signup';
import * as WEB from 'types/interfaces';
import useNavigateFunction from './useNavigateFunction';

/**
 * The problem this hook solves is the case when the user hits the back button
 * in the signup flow but the state "currentSignupPage" in redux does not update as well.
 * The objective is  to update "currentSignupPage" in redux if the signup page user is
 * currently on does not match the page in the redux state.
 *
 * @param currentPage - current signup page
 */
const useVerifyCurrentPage = (
  currentPage: string,
  pageType: PageType = PageType.SIGNUP
): void => {
  const {
    updateCurrentSignupPage,
    updateCurrentGeniusPage,
    updateCurrentKYCPage,
  } = useNavigateFunction();
  const dispatch = useDispatch();
  const currentPageInState = useSelector((state: WEB.RootState) => {
    let selector = signup.getCurrentPage;
    if (pageType === PageType.GENIUS) {
      selector = genius.getCurrentPage;
    } else if (pageType === PageType.KYC) {
      selector = kyc.getCurrentPage;
    }
    return selector(state);
  });

  React.useEffect(() => {
    if (currentPageInState !== currentPage) {
      let actionCreator = updateCurrentSignupPage;
      if (pageType === PageType.GENIUS) {
        actionCreator = updateCurrentGeniusPage;
      } else if (pageType === PageType.KYC) {
        actionCreator = updateCurrentKYCPage;
      }

      // Update currentSignUpPage in redux, but don't actually nav to the page
      // (we should already be on the page we're updating to)
      dispatch(actionCreator({ page: currentPage, shouldNavTo: false }));
    }
  }, []);
};

export default useVerifyCurrentPage;
