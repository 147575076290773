import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Button from 'components/common/Button';
import Text from 'components/common/Text';
import ButtonsContainer from 'components/layout/ButtonsContainer';
import PageBase from 'components/layout/PageBase';
import ArtMap from 'constants/ArtMap';
import Pages from 'constants/Pages';
import useNavigateFunction from 'hooks/useNavigateFunction';
import * as language from 'reducers/entities/language';
import { breakpoints } from 'styles';
import * as WEB from 'types/interfaces';
import { getLanguageButtons, getLanguageSection } from 'utils/getFromLanguage';

type Props = {
  nextPage: string;
};

const StyledHeader = styled.h1`
  @media ${breakpoints.mobileLarge} {
    margin: 0;
  }
`;
const InvestingConfirm = ({ nextPage }: Props): React.ReactElement => {
  const { INVESTING_CONFIRM } = Pages;

  // Hooks.
  const { updateCurrentSignupPage } = useNavigateFunction();
  const dispatch = useDispatch();

  // Mapped state.
  const investingConfirmCtx = useSelector((state: WEB.RootState) =>
    language.getSignupPageLanguage(state, INVESTING_CONFIRM)
  );

  // Language.
  const INTRO_KEY = 'intro';
  const introSection = getLanguageSection(investingConfirmCtx, INTRO_KEY);
  const buttonsCtx = getLanguageButtons(investingConfirmCtx);

  // Handlers.
  const handleConfirmClick = (): void => {
    dispatch(updateCurrentSignupPage({ page: nextPage }));
  };

  return (
    <PageBase>
      <StyledHeader>
        {introSection?.header}{' '}
        <img
          src={ArtMap(
            introSection?.image?.filename ? introSection?.image?.filename : ''
          )}
          alt='Thumbs up'
          width={introSection?.image?.width}
        />
      </StyledHeader>
      <Text className='description'>{introSection?.text}</Text>
      <ButtonsContainer>
        <Button id='investing-continue-button' onClick={handleConfirmClick}>
          {buttonsCtx?.primary}
        </Button>
      </ButtonsContainer>
    </PageBase>
  );
};

export default InvestingConfirm;
