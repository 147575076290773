import { rem } from 'polished';
import { css } from 'styled-components';
import { widths } from 'styles/breakpoints';
import { ROOT_FONT_SIZE_PX, ROOT_FONT_SIZE_SMALL_PX } from './constants';

/* eslint-disable @typescript-eslint/no-explicit-any */
export default {
  pxToRem: (px: string): string =>
    rem(
      px,
      `${
        window.innerWidth <= widths.desktopLarge
          ? ROOT_FONT_SIZE_SMALL_PX
          : ROOT_FONT_SIZE_PX
      }px`
    ),
  spin: (time = 1, iteration = 'infinite'): any => css`
    @keyframes spin {
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
    animation: spin ${time}s ${iteration};
  `,
  slideInY: (time = 0.25, iteration = 'linear'): any => css`
    @keyframes slideInY {
      0% {
        transform: translateY(-100%);
      }
      50% {
        transform: translateY(-50%);
      }
      100% {
        transform: translateY(0);
      }
    }
    animation: slideInY ${time}s ${iteration};
  `,
  slideInX: (time = 0.2, iteration = 'linear'): any => css`
    @keyframes slideInX {
      0% {
        transform: translateX(-100%);
      }
      50% {
        transform: translateX(-50%);
      }
      100% {
        transform: translateX(0);
      }
    }
    animation: slideInX ${time}s ${iteration};
  `,
  fadeIn: (time = 0.25, iteration = 'ease', reverse = false): any => css`
    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    animation: fadeIn ${time}s ${iteration};
  `,
  shimmer: (): any => css`
    background-color: ${({ theme }) => theme.colors.lightGray3};
    animation-name: shimmer;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-out;

    @keyframes shimmer {
      0% {
        background-color: ${({ theme }) => theme.colors.lightGray3};
      }
      50% {
        background-color: ${({ theme }) => theme.colors.lightGray2};
      }
      80% {
        background-color: ${({ theme }) => theme.colors.lightGray3};
      }
    }
  `,
};
