/* eslint-disable @typescript-eslint/await-thenable */
import * as React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import updateInvestingPreferences from 'actions/async/updateInvestingPreferences';
import removeBanner from 'actions/banner/removeBanner';
import Button from 'components/common/Button';
import Spacer from 'components/common/Spacer';
import { BorderSquareCard } from 'components/common/SquareCard/SquareCardV2';
import Text from 'components/common/Text';
import ButtonsLayout from 'components/layout/ButtonsLayout';
import LeadContent from 'components/layout/LeadContent';
import PageBase from 'components/layout/PageBase';
import ArtMap from 'constants/ArtMap';
import Pages from 'constants/Pages';
import * as language from 'reducers/entities/language';
import * as profile from 'reducers/entities/profile';
import { breakpoints } from 'styles';
import { spacers } from 'styles/home';
import * as WEB from 'types/interfaces';
import { delayWithState } from 'utils/delay';
import { getLanguageButtons, getLanguageSection } from 'utils/getFromLanguage';
import { hash32 } from 'utils/hash';

type Props = {
  onComplete: () => void;
};

const CARD_SIZE = '157px';

const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 12px;
  row-gap: 14px;
  align-items: center;
  max-width: calc((${CARD_SIZE} * 3) + 24px);
  @media ${breakpoints.mobileLarge} {
    margin: 0 auto;
  }
  @media ${breakpoints.institutionGrid} {
    max-width: none;
    grid-template-columns: 1fr 1fr;
  }
`;

const ContentContainer = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ImageContainer = styled.div`
  width: 56px;
  img {
    width: 100%;
  }
`;

const InvestingInterestsInfo = ({ onComplete }: Props): React.ReactElement => {
  // ///////////////////////////////
  /* =========== HOOKS ========== */
  // ///////////////////////////////
  const dispatch = useDispatch();

  // ///////////////////////////////
  /* ======= MAPPED STATE ======= */
  // ///////////////////////////////
  // Default investing goals from profile investment_info.
  const investmentGoalsDefault = useSelector(
    (state: WEB.RootState) =>
      profile.getInfoValueByField(
        state,
        'investment_info',
        'portfolio_goals'
      ) || [],
    shallowEqual
  );

  // ///////////////////////////////
  /* =========== STATE ========== */
  // ///////////////////////////////
  const [investingInterests, setInvestingInterests] = React.useState<string[]>(
    []
  );
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [isCompleted, setIsCompleted] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (investmentGoalsDefault.length) {
      setInvestingInterests(investmentGoalsDefault);
    }
  }, [investmentGoalsDefault]);

  // //////////////////////////////////
  /* =========== LANGUAGE ========== */
  // //////////////////////////////////
  const investingInfoCtx = useSelector((state: WEB.RootState) =>
    language.getSignupPageLanguage(state, Pages.INVESTING_INTERESTS_INFO)
  );
  const INTRO_KEY = 'intro';

  // Sections
  const introSection = getLanguageSection(investingInfoCtx, INTRO_KEY);

  const investingGoalsSection = getLanguageSection(
    investingInfoCtx,
    'interests'
  );

  const buttonsCtx = getLanguageButtons(investingInfoCtx);

  // //////////////////////////////////
  /* =========== HANDLERS ========== */
  // //////////////////////////////////
  const handleStockClick = (value: string): void => {
    const cardIndex = investingInterests.indexOf(value);

    // If card not in selected goals, add it
    if (cardIndex === -1) {
      setInvestingInterests([...investingInterests, value]);
    } else {
      // find its index and remove it
      const activeCardsCopy = [...investingInterests];
      activeCardsCopy.splice(cardIndex, 1);
      setInvestingInterests(activeCardsCopy);
    }
  };

  const handleInvestingInfoContinueClick = async (): Promise<void> => {
    // Remove any error banner from previous attempts
    dispatch(removeBanner());
    setIsSubmitting(true);

    const { error }: any = await dispatch(
      updateInvestingPreferences(investingInterests)
    );

    setIsSubmitting(false);
    if (!error) {
      await delayWithState(400, setIsCompleted);
      onComplete();
    }
  };

  const investingGoalsOptions = (investingGoalsSection?.options || []) as {
    image: string;
    header: string;
  }[];

  return (
    <PageBase>
      <LeadContent
        header={introSection?.header || ''}
        text={introSection?.text || ''}
      />
      <Spacer space={spacers.g8} />
      <CardsContainer>
        {investingGoalsOptions.map((option) => (
          <BorderSquareCard
            key={`investment-goal-${hash32(option?.header)}`}
            selected={investingInterests.includes(option?.header)}
            onClick={() => handleStockClick(option?.header)}
            height={CARD_SIZE}
            width='100%'
          >
            <ContentContainer>
              {option?.image && (
                <ImageContainer>
                  <img src={ArtMap(option?.image || '')} alt='' />
                </ImageContainer>
              )}
              <Text weight='700'>{option.header}</Text>
            </ContentContainer>
          </BorderSquareCard>
        ))}
      </CardsContainer>
      <ButtonsLayout
        marginTop={spacers.g9}
        primaryButton={
          <Button
            onClick={handleInvestingInfoContinueClick}
            isCompleted={isCompleted}
            isLoading={isSubmitting}
            disabled={!investingInterests.length || isSubmitting}
          >
            {isCompleted ? buttonsCtx?.complete : buttonsCtx?.primary}
          </Button>
        }
      />
    </PageBase>
  );
};

export default InvestingInterestsInfo;
