/* eslint-disable no-useless-escape */
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import TrackingBase from 'tracking.Base';
import updateProfile from 'actions/async/updateProfile';
import updateSavingsSetup, {
  UpdateSavingsAccountFields,
} from 'actions/async/updateSavingsSetup';
import hideBanner from 'actions/banner/hideBanner';
import removeBanner from 'actions/banner/removeBanner';
import Button from 'components/common/Button';
import Input from 'components/common/Input';
import Spacer from 'components/common/Spacer';
import SquareCard from 'components/common/SquareCard';
import SquareCardGroup from 'components/common/SquareCard/SquareCardGroup';
import Text, { TextColor, TextSizes } from 'components/common/Text';
import ButtonsContainer from 'components/layout/ButtonsContainer';
import InfoPaneExpanderGroup from 'components/layout/InfoPaneExpanderGroup';
import PageBase from 'components/layout/PageBase';
import { LinkAccountCompleteLocationState } from 'components/linkAccount/LinkAccountFlow';
import AlbertTrackingEvent from 'constants/AlbertTrackingEvent';
import Pages from 'constants/Pages';
import SavingsSchedules, {
  SavingsAmountMax,
  SavingsMethods,
} from 'constants/SavingsSchedules';
import SplashLink from 'constants/SplashLink';
import { KeyboardEventKeys, PageRoutes } from 'constants/index';
import useBrowserNavigationBlockWithBanner from 'hooks/useBrowserNavigationBlockWithBanner';
import useNavigateFunction from 'hooks/useNavigateFunction';
import useVerifyCurrentPage from 'hooks/useVerifyCurrentPage';
import SavingsConfirm from 'pages/signup/SavingsConfirm';
import * as appData from 'reducers/entities/appData';
import * as language from 'reducers/entities/language';
import { breakpoints, fontSizes, mixins, spacers } from 'styles';
import { spacers as homeSpacers } from 'styles/home';
import * as WEB from 'types/interfaces';
import { getIsCheckpointDisabled } from 'utils/checkpointNavUtils';
import { delayWithState } from 'utils/delay';
import {
  getLanguageButtons,
  getLanguageInfo,
  getLanguageSection,
} from 'utils/getFromLanguage';
import { hash32 } from 'utils/hash';

type Props = {
  nextPage: string;
};

type LocationState = LinkAccountCompleteLocationState | WEB.EmptyObject;

const StyledPlus = styled.span`
  font-weight: 900;
`;

const StyledExpander = styled.span`
  :hover {
    color: ${({ theme }) => theme.colors.primaryAlbertBrand};
    cursor: pointer;
  }
`;

const StyledSavingsScheduleGroup = styled(SquareCardGroup)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: auto;
  grid-gap: 15px;
  > div {
    /**
     * NOTE: these cards are unique because while they are meant to be perfect squares,
     * on smaller viewports, they need to be rectangles (content doesn't fit). Therefore,
     * we un-do the logic that makes the cards perfect squares
     */
    &::before {
      padding-bottom: 0;
    }
    .square-card-content {
      position: static;
    }
  }
`;

const StyledSavingsScheduleExpandedGroup = styled(StyledSavingsScheduleGroup)`
  margin-top: 16px;
`;

const StyledSquareCardGroup = styled(SquareCardGroup)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-auto-rows: auto;
  grid-gap: 12px;

  @media (max-width: 550px) {
    height: 109px;
  }
`;

const CurrencyInputWrapper = styled.div`
  margin-top: ${mixins.pxToRem('23px')};
`;

const SmallText = styled.span`
  ${fontSizes.fontSize16}
  font-weight: 500;
`;

const LargeText = styled.span`
  ${fontSizes.fontSize24}
  font-weight: 700;
  @media (max-width: 550px) {
    ${fontSizes.fontSize16};
  }
`;

const DesktopOnlyHeader = styled.h1`
  @media ${breakpoints.mobileLarge} {
    display: none;
  }
`;

const SavingsSetup = (props: Props): React.ReactElement | null => {
  const { updateCurrentSignupPage } = useNavigateFunction();
  // Make Redux is up-to-date with currentSignUpPage
  useVerifyCurrentPage(Pages.SAVINGS_SETUP);
  useBrowserNavigationBlockWithBanner();

  // ///////////////////////////////////////
  /* ===== HISTORY.LOCATION STATE  ====== */
  // ///////////////////////////////////////
  const location = useLocation();
  const locationState = (location?.state || {}) as LocationState;

  // ///////////////////////////////////
  /* =========== CONSTANTS ========== */
  // ///////////////////////////////////
  const OTHER_OPTION = 'Other';

  // ///////////////////////////////
  /* =========== HOOKS ========== */
  // ///////////////////////////////
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // ///////////////////////////////
  /* ======= MAPPED STATE ======= */
  // ///////////////////////////////
  const hasPrimaryChecking = useSelector(appData.hasPrimaryChecking);

  // ///////////////////////////////
  /* =========== STATE ========== */
  // ///////////////////////////////
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isCompleted, setIsCompleted] = React.useState<boolean>(false);
  const [schedule, setSchedule] = React.useState(SavingsMethods.AUTO);
  const [activeRecurring, setActiveRecurring] = React.useState('10');
  const [recurringAmount, setRecurringAmount] = React.useState(10);
  const [showAllSchedule, setShowAllSchedule] = React.useState(false);
  const [visitedCustomRecurringAmount, setVisitedCustomRecurringAmount] =
    React.useState(false);
  const [finishedSetup, setFinishedSetup] = React.useState(
    // If user just exited the Link Account flow, then initialize to show the SavingsConfirm page.
    !!locationState.showConfirmation
  );

  React.useEffect(() => {
    // Trigger a render whenever showConfirmation changes.
    // Otherwise, when navigating backwards from SavingsConfirm -> SavingsSetup,
    // the url changes correctly but the app still displays SavingsConfirm
    setFinishedSetup(!!locationState.showConfirmation);
  }, [locationState.showConfirmation]);

  // //////////////////////////////////
  /* =========== HANDLERS ========== */
  // //////////////////////////////////
  const handleShowScheduleChange = (): void => {
    setShowAllSchedule(true);
  };
  const handleScheduleChange = (value?: string | number): void => {
    if (value) {
      setSchedule(String(value));
    }
  };

  const handleOnKeyDown = (e: React.KeyboardEvent): void => {
    if (
      e.key === KeyboardEventKeys.SPACEBAR ||
      e.key === KeyboardEventKeys.ENTER
    ) {
      handleShowScheduleChange();
    }
  };

  const handleRecurringCardChange = (value?: number | string): void => {
    value && setActiveRecurring(String(value));
    if (value !== OTHER_OPTION) {
      setRecurringAmount(value as number);
    } else {
      setRecurringAmount(0);
    }
  };

  const handleRecurringOnChange = (value: number): void => {
    if (!visitedCustomRecurringAmount) {
      setVisitedCustomRecurringAmount(true);
    }
    setRecurringAmount(value);
  };

  const handleOnContinue = async (): Promise<void> => {
    // Remove any error banner from previous attempts
    dispatch(removeBanner());

    // Don't change page if recurringAmount is other and no amount chosen
    if (activeRecurring === OTHER_OPTION && !recurringAmount) {
      setVisitedCustomRecurringAmount(true);
      return;
    }

    try {
      // Button loading state.
      setIsSubmitting(true);

      // Hide any error banners until all requests are complete
      dispatch(hideBanner());

      // API call to create/update savings account.
      const savingsSetupData: UpdateSavingsAccountFields = {
        transfer_interval: schedule,
        // User agrees to Coastal terms on new banking signup flow.
        agreed_to_coastal_terms: true,
      };

      // Include recurring amount if savings method is not AUTO.
      if (schedule !== SavingsMethods.AUTO && recurringAmount) {
        savingsSetupData.recurring_transfer_amount = recurringAmount;
      }

      // Create savings account.
      const savingsPromise: any = dispatch(
        updateSavingsSetup(savingsSetupData)
      );

      // Set personal_info_signup_auto_savings for checkpointing (user setupt savings).
      const profilePromise: any = dispatch(
        updateProfile({ personal_info_signup_auto_savings: true })
      );

      const results = await Promise.all([savingsPromise, profilePromise]);
      setIsSubmitting(false);
      dispatch(hideBanner(false));

      // Handle the update savings setup request response
      const savingsError: boolean = results[0];
      if (savingsError) throw new Error();

      // Handle the update profile request response
      const { error: profileError } = results[1];
      if (profileError) throw new Error();

      TrackingBase.track({
        event: AlbertTrackingEvent.ENABLED_SAVINGS,
        trackOnce: true,
        trackSocial: true,
      });

      // Navigate to the SavingsConfirm (next) page.
      await delayWithState(400, setIsCompleted);

      // Check if user does not have linked primary checking AND
      if (!hasPrimaryChecking || getIsCheckpointDisabled()) {
        // Navigate to the Link Account flow for the user to link their bank account with Albert.
        navigate(PageRoutes.LINK_ACCOUNT, {
          state: {
            pageOnComplete: Pages.SAVINGS_SETUP,
            pageOnSkip: props.nextPage,
            currentPage: Pages.SAVINGS_SETUP,
          },
        });
      } else {
        setFinishedSetup(true);
      }
    } catch (e) {
      setIsSubmitting(false);
    }
  };

  const handleNavigateNextPage = (): void => {
    // Navigate to next page.
    dispatch(updateCurrentSignupPage({ page: props.nextPage }));
  };

  const createRedirectOnClickHandler = (url: string): (() => void) => {
    return React.useCallback(() => {
      window.open(url, '_blank');
    }, []);
  };

  // //////////////////////////////////
  /* =========== LANGUAGE ========== */
  // //////////////////////////////////
  const { SAVINGS_SETUP } = Pages;
  const savingsSetupCtx = useSelector((state: WEB.RootState) =>
    language.getSignupPageLanguage(state, SAVINGS_SETUP)
  );
  const SAVE_MONEY_KEY = 'saveMoneyWithAlbert';
  const SAVINGS_SCHEDULE_KEY = 'savingsSchedule';
  const SAVINGS_RECURRING_AMOUNT_KEY = 'savingsRecurringAmount';

  // Sections
  const saveMoneyWithAlbertSection = getLanguageSection(
    savingsSetupCtx,
    SAVE_MONEY_KEY
  );

  const savingsScheduleSection = getLanguageSection(
    savingsSetupCtx,
    SAVINGS_SCHEDULE_KEY
  );

  const savingsRecurringAmountSection = getLanguageSection(
    savingsSetupCtx,
    SAVINGS_RECURRING_AMOUNT_KEY
  );

  const infoPaneContext = getLanguageInfo(savingsSetupCtx);
  const buttonsCtx = getLanguageButtons(savingsSetupCtx);

  // //////////////////////////////////
  /* =========== SECTION GROUPS ========== */
  // //////////////////////////////////
  const scheduleCardOptions = (savingsScheduleSection?.options || []) as {
    [key: string]: string;
  }[];

  const recurringAmountOptions = (savingsRecurringAmountSection?.options ||
    []) as number[];

  // ///////////////////////////////////////////////
  /* =========== SAVINGS SCHEDULE CARDS ========= */
  // ///////////////////////////////////////////////
  const scheduleCards = scheduleCardOptions.map((option) => {
    return (
      <SquareCard
        grid
        key={`schedule_${option?.key}`}
        selected={option?.key === schedule}
        value={option?.key}
        size='large'
        header={option?.label}
        body={option?.sub_label}
        image={option?.image}
        altImage={option?.disabled_image}
        clickHandler={handleScheduleChange}
      />
    );
  });

  // More settings expander to show second CardGroup
  const savingsScheduleExpanderComponent = !showAllSchedule ? (
    <>
      <Spacer space={spacers.small} />
      <Text
        color={TextColor.GRAY}
        size={TextSizes.SMALL}
        onClick={handleShowScheduleChange}
        isLinkButton
      >
        <StyledExpander className='more-settings-link'>
          More settings&nbsp;
          <StyledPlus>+</StyledPlus>
        </StyledExpander>
      </Text>
    </>
  ) : null;

  // First two savings schedule cards
  const defaultScheduleCards = scheduleCards.slice(0, 2);

  // Last two savings schedule cards
  const expanderScheduleCards = scheduleCards.slice(2);

  // default savings schedule card group
  const savingsScheduleCardGroup = (
    <StyledSavingsScheduleGroup
      id='savings-schedule-group'
      aria-label={savingsScheduleSection?.header}
      header={savingsScheduleSection?.header}
      text={savingsScheduleSection?.text}
      disclaimer={savingsScheduleSection?.disclaimer}
      disclaimerAbove
    >
      {defaultScheduleCards}
    </StyledSavingsScheduleGroup>
  );

  // expanded savings schedule card group
  const expandedSavingsScheduleCardGroup = showAllSchedule ? (
    <>
      <StyledSavingsScheduleExpandedGroup id='savings-schedule-group'>
        {expanderScheduleCards}
      </StyledSavingsScheduleExpandedGroup>
    </>
  ) : null;

  // ///////////////////////////////////////////////
  /* =========== RECURRING AMOUNT CARDS ========= */
  // ///////////////////////////////////////////////
  const recurringAmountBody = SavingsSchedules[schedule] || '';
  const recurringErrorMessage = savingsRecurringAmountSection?.errorMessage;
  const recurringCards = recurringAmountOptions.map((option) => {
    const header = (
      <>
        <SmallText>$</SmallText>
        <LargeText>{option}</LargeText>
      </>
    );
    return (
      <SquareCard
        grid
        aria-label={header}
        key={`recurring_${option}`}
        value={option}
        selected={String(option) === activeRecurring}
        size='small'
        header={header}
        body={recurringAmountBody}
        clickHandler={handleRecurringCardChange}
      />
    );
  });

  // Special card for `Other` that opens a currencyInput
  const otherCard = (
    <SquareCard
      grid
      key='other_card'
      value={OTHER_OPTION}
      selected={activeRecurring === OTHER_OPTION}
      size='small'
      aria-label={OTHER_OPTION}
      header={<LargeText>{OTHER_OPTION}</LargeText>}
      body={recurringAmountBody}
      clickHandler={handleRecurringCardChange}
    />
  );
  recurringCards.push(otherCard);
  const recurringCardOtherComponent = (
    <CurrencyInputWrapper>
      <Input.Currency
        id='recurring_currency_other_input'
        min='0'
        max={SavingsAmountMax[schedule]}
        placeholder={`Amount ${recurringAmountBody}`}
        required
        onChange={handleRecurringOnChange}
        errorText={recurringErrorMessage}
        invalid={visitedCustomRecurringAmount && recurringAmount <= 0}
      />
    </CurrencyInputWrapper>
  );

  const recurringCardGroup = (
    <>
      <Spacer space={spacers.tabLarge} />
      <StyledSquareCardGroup
        id='recurring-amount-group'
        aria-label={savingsRecurringAmountSection?.header}
        header={savingsRecurringAmountSection?.header}
        text={savingsRecurringAmountSection?.text}
        optionalComponent={
          activeRecurring === OTHER_OPTION ? recurringCardOtherComponent : null
        }
      >
        {recurringCards}
      </StyledSquareCardGroup>
    </>
  );

  // ///////////////////////////////////////////////
  /* =========== savingsSetup Page Text ========= */
  // ///////////////////////////////////////////////
  const saveMoneyText = saveMoneyWithAlbertSection?.text;
  const saveMoneyBody = Array.isArray(saveMoneyText) ? (
    saveMoneyText.map((txt: string) => (
      <div key={`info-text-fragment-${hash32(txt)}`} className='description'>
        <Text key={`info-text-${hash32(txt)}`}>{txt}</Text>
        <Spacer space={spacers.tabSmall} desktopOnly />
      </div>
    ))
  ) : (
    <Text>{saveMoneyText}</Text>
  );
  const titleGroup = (
    <>
      <DesktopOnlyHeader>
        {saveMoneyWithAlbertSection?.header}
      </DesktopOnlyHeader>
      {saveMoneyBody}
    </>
  );

  // //////////////////////////////////
  /* =========== PAGES ========== */
  // //////////////////////////////////
  const savingsSetup = (
    <PageBase mobileHeader={saveMoneyWithAlbertSection?.header}>
      {titleGroup}
      <Spacer space={spacers.tab} desktopOnly />
      <Spacer space={homeSpacers.g8} mobileOnly />
      {savingsScheduleCardGroup}
      {expandedSavingsScheduleCardGroup}
      {savingsScheduleExpanderComponent}
      {schedule !== SavingsMethods.AUTO && recurringCardGroup}
      <Spacer space={spacers.small} mobileOnly />
      <InfoPaneExpanderGroup
        key='savings-setup-info'
        expanders={infoPaneContext.expanders}
        addExpanderSubheadSpacing={false}
        mobileOnly
      />
      <Spacer space={spacers.tabLarge} desktopOnly />
      <Spacer space={spacers.small} mobileOnly />
      <Text color={TextColor.GRAY} size={TextSizes.TINY}>
        Albert Savings is provided by Coastal Community Bank, Member FDIC. By
        clicking “Continue” I agree to the{' '}
        <Text
          nowrap
          pointer
          inline
          weight='700'
          color={TextColor.GRAY}
          size={TextSizes.TINY}
          underline
          onClick={createRedirectOnClickHandler(SplashLink.COASTAL_PRIVACY)}
          isLinkButton
        >
          privacy policy
        </Text>{' '}
        and to let Albert move funds from my linked account to Albert Savings at
        my selected schedule.
      </Text>
      <ButtonsContainer>
        <Button
          id='savings-setup-confirm-button'
          isCompleted={isCompleted}
          isLoading={isSubmitting}
          disabled={isSubmitting}
          onClick={handleOnContinue}
        >
          {isCompleted ? buttonsCtx?.complete : buttonsCtx?.primary}
        </Button>
      </ButtonsContainer>
    </PageBase>
  );
  return (
    <>
      {finishedSetup ? (
        <SavingsConfirm
          schedule={schedule}
          recurringAmount={recurringAmount}
          onClick={handleNavigateNextPage}
        />
      ) : (
        savingsSetup
      )}
    </>
  );
};

export default SavingsSetup;
