import setLoggedIn from 'actions/setLoggedIn';
import types from 'actions/types';
import * as app from 'reducers/ui/app';
import * as WEB from 'types/interfaces';

type MiddlewareProps = {
  dispatch: WEB.Dispatch;
  getState: WEB.GetStateFunc;
};

// File-scoped boolean to prevent redirecting to the login page more than once
// on multiple async requests that fail and attempt to redirect to login.
// Redirecting to login page will set this bool to "true" and any subsequent
// SUCCESSFUL api requests will reset this back to "false".
let alreadyRedirected = false;

// File-scoped boolean to prevent redirecting to 2FA flow multiple times from a single batch of responses.
let navigatedTo2FA = false;

// Middleware to redirect client-side if response payload.data includes "redirect" property
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-param-reassign */
export default (middlewareProps: MiddlewareProps) =>
  (next: (action: WEB.Action) => void) =>
  (action: WEB.Action) => {
    const { dispatch, getState } = middlewareProps;
    const { request = {}, config = {} } = action?.payload || {};

    // Check if user needs to be redirected to the 2FA flow.
    const { error } = action;
    if (error && !navigatedTo2FA) {
      const is2FARequired = error?.response?.data?.is_2fa_required;
      const status = error?.response?.status;
      const FORBIDDEN = 403;

      if (status === FORBIDDEN && is2FARequired) {
        navigatedTo2FA = true;
        dispatch({ type: types.TWOFA_INITIATE });
        return;
      }
      // Reset navigatedTo2FA if truthy and action is a API response with no error.
    } else if (navigatedTo2FA && !!request?.status) {
      navigatedTo2FA = false;
    }

    // Protected API requests/responses meet three criterias:
    // 1) Has request.status in the action.payload
    // 2) action.payload.config has a URL property which starts with "/api"
    // 3) Is not a public API action (ie. load signup intro)
    const isProtectedAPIResponse =
      !!request?.status &&
      (config?.url || '').includes('/api') &&
      action.type !== types.GET_SIGNUP_INTRO_SUCCESS;

    // Proceed if action is not an API request
    if (!isProtectedAPIResponse) {
      return next(action);
    }

    const redirectPath = action?.payload?.data?.redirect;

    // Redirect right away
    // NOTE: this could be anti-pattern because we are redirecting before the response is recorded
    // in redux history. However, redirecting and returning "next" causes a flicker because we're essentially
    // returning the response and redirecting all in a matter of ms (flickering effect between pages).
    if (redirectPath && !alreadyRedirected) {
      // Change the payload to an empty object
      action.payload.data = {};

      // Set user as logged out
      dispatch(setLoggedIn(false));
      alreadyRedirected = true;

      window.location.href = redirectPath + window.location.search;

      // Set user as logged in if successful API request (authenticated)
    } else if (!redirectPath) {
      // If API request returns success (authenticated), update logged in state
      const state = getState();
      const isLoggedIn = app.isLoggedIn(state);
      const shouldSetLoggedIn =
        !isLoggedIn &&
        action.type &&
        (action.type === types.LOGIN_SUCCESS ||
          action.type === types.GET_APP_DATA_SUCCESS ||
          action.type === types.GET_PROFILE_SUCCESS);

      // Reset global state if alreadyRedirected is "true"
      if (alreadyRedirected) {
        alreadyRedirected = false;
      }

      shouldSetLoggedIn && dispatch(setLoggedIn(true));
    }

    // Proceed with action
    return next(action);
  };
