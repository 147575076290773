import { isString } from 'lodash';
import * as React from 'react';
import styled from 'styled-components';
import ArtMap, { Art } from 'constants/ArtMap';
import { DebitCardStyle } from 'constants/index';
import { breakpoints } from 'styles';
import { sizes } from 'styles/breakpoints';

type Props = {
  /* should display the card tilted */
  tilted?: boolean;
  /* which debit card style to use */
  style: DebitCardStyle;
  /* user's first name */
  firstName: string;
  /* user's last name */
  lastName: string;
  /* last four digits of the credit card number */
  lastFour?: number;
  /* type of card shadow to display, if any */
  shadow?: ShadowTypes;
};

export enum ShadowTypes {
  /* shadow under the bottom left corner of the card */
  CORNER = 'corner',
  /* shadow behind the card */
  BACKGROUND = 'background',
}

const Container = styled.div`
  position: relative;
`;

const BaseCardContainer = styled.div<{
  tilted?: boolean;
  cardStyle?: DebitCardStyle;
  shadow?: ShadowTypes;
}>`
  position: relative;
  height: 214px;
  width: 334px;
  ${({ tilted }) => tilted && 'transform: rotate(-8deg);'}
  ${({ shadow, tilted }) =>
    shadow === ShadowTypes.BACKGROUND &&
    'filter: drop-shadow(10.8282px 32.4847px 54.1411px rgba(0, 0, 0, 0.2));' +
      // hack to prevent Safari from adding a gray background on initial render
      `-webkit-transform: ${
        tilted ? 'rotate(-8deg)' : ''
      } translate3d(0, 0, 0);`}
  img {
    width: 334px;
    height: auto;
  }
  @media ${breakpoints.mobileLarge} {
    // Prevent flicker in rerender (e.g. changing options in SelectCard) where
    // height briefly becomes zero. This collapses the div and moves the name
    // to the top. Then the height returns and the name is shifted back down
    // near the bottom of the div.
    min-height: 163px;
    width: 250px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    img {
      width: 250px;
    }
  }
`;

const GeniusContainer = styled(BaseCardContainer)`
  .first-name {
    position: absolute;
    bottom: 50px;
    left: 41px;
    color: ${({ theme }) => theme.colors.white};
    font-size: 15.04px;
    font-weight: 500;
    letter-spacing: 0.2em;
    text-transform: uppercase;
  }

  .last-name {
    position: absolute;
    bottom: 34px;
    left: 41px;
    color: ${({ theme }) => theme.colors.white};
    font-size: 8.57px;
    font-weight: 500;
    letter-spacing: 0.2em;
    text-transform: uppercase;
  }

  .last-four {
    position: absolute;
    top: 35px;
    left: 109px;
    color: ${({ theme }) => theme.colors.white};
    font-size: 8.2px;
    letter-spacing: 0.3em;
  }

  @media ${breakpoints.mobileLarge} {
    .last-four {
      top: 26px;
      left: 83px;
      font-size: 6.2px;
    }

    .first-name {
      bottom: 40px;
      left: 31px;
      font-size: 12.04px;
    }

    .last-name {
      bottom: 25px;
      left: 31px;
      font-size: 7.57px;
    }
  }
`;

const BlueWhiteContainer = styled(BaseCardContainer)`
  .name {
    position: absolute;
    bottom: 39px;
    left: 41px;
    color: ${({ cardStyle, theme }) =>
      cardStyle === DebitCardStyle.BLUE
        ? theme.colors.white
        : theme.colors.primaryText};
    font-size: 9.57px;
    font-weight: 600;
    letter-spacing: 0.3em;
    text-transform: uppercase;
  }

  @media ${breakpoints.mobileLarge} {
    .name {
      bottom: 30px;
      left: 31px;
      font-size: 8.57px;
    }
  }
`;

export const CornerShadow = styled.div`
  width: 160px;
  height: 0;
  position: absolute;
  bottom: -40px;
  box-shadow: 10px -16px 55px 18px rgba(0, 0, 0, 0.2);
`;

/* Cardholder name max length for the white/blue cards */
const FULL_NAME_MAX_LENGTH = 21;

/* Cardholder name max lengths for the genius card */
const FIRST_NAME_MAX_LENGTH = 13;
const LAST_NAME_MAX_LENGTH = 25;

/**
 * Clean a name by updating it to only contain characters
 * that are allowed to be printed on a debit card.
 *
 * @param name first or last name
 * @returns the cleaned name
 */
const cleanName = (name: string): string => {
  if (!name || !isString(name)) {
    return '';
  }
  // Replace accented characters with non-accented equivalents
  // https://stackoverflow.com/a/37511463
  const validName = name.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

  // If the name contains characters that are NOT
  // letters, hyphens, apostraphes or spaces, don't allow the name
  const INVALID_CHARACTERS_REGEX = /[^A-Za-z-'\s]+/g;
  if (INVALID_CHARACTERS_REGEX.test(validName)) return '';

  return validName;
};

/**
 * Format a cardholder's first name to pass a maximum length restriction.
 *
 * @param firstName cardholder's first name
 * @param maxLength maximum length for the first name
 * @returns formatted first name
 */
const formatFirstName = (firstName: string, maxLength: number): string => {
  let validFirstName = cleanName(firstName);

  if (!validFirstName) return '';

  if (validFirstName.length > maxLength) {
    // Omit middle name
    const firstNameOnly = validFirstName.split(' ')[0];
    validFirstName = firstNameOnly;
  }

  if (validFirstName.length > maxLength) {
    // Use first name initial
    const firstNameInitial = validFirstName[0];
    validFirstName = firstNameInitial;
  }

  return validFirstName;
};

/**
 * Format a cardholder's last name to pass a maximum length restriction.
 *
 * @param lastName cardholder's last name
 * @param maxLength maximum length for the last name
 * @returns formatted last name
 */
const formatLastName = (lastName: string, maxLength: number): string => {
  let validLastName = cleanName(lastName);

  if (!validLastName) return '';

  if (validLastName.length > maxLength) {
    const isLastNameHyphenated = validLastName.includes('-');
    let shortenedLastName;

    if (isLastNameHyphenated) {
      // Use the last word in the hypenated name
      const lastNames = validLastName.split('-');
      shortenedLastName = lastNames[lastNames.length - 1];
    } else {
      // Use the first word in the last name
      [shortenedLastName] = validLastName.split(' ');
    }
    validLastName = shortenedLastName;
  }

  // If the last name is still too long, simply truncate it
  if (validLastName.length > maxLength) {
    validLastName = validLastName.slice(0, maxLength);
  }

  return validLastName;
};

/**
 * Format a cardholder's name as a full name
 * that passes a maximum length restriction.
 *
 * @param firstName cardholder's first name
 * @param lastName cardholder's last name
 * @param maxLength maximum length for the combined first and last name with a space between.
 * @returns formatted full name
 */
const formatFullName = (
  firstName: string,
  lastName: string,
  maxLength: number
): string => {
  // Subtract 1 for the single space between the first and last name
  const firstNameMaxLength = maxLength - lastName.length - 1;
  const formattedFirstName = formatFirstName(firstName, firstNameMaxLength);

  // Subtract 1 for the single space between the first and last name
  const lastNameMaxLength = maxLength - formattedFirstName.length - 1;
  const formattedLastName = formatLastName(lastName, lastNameMaxLength);

  const fullName = `${formattedFirstName} ${formattedLastName}`;
  return fullName;
};

const DebitCard = (props: Props) => {
  let imgSrc;
  let imgAlt;
  let CardContainer;
  let formattedFirstName;
  let formattedLastName;
  let formattedFullName;

  switch (props.style) {
    case DebitCardStyle.WHITE:
      imgSrc = ArtMap(Art.DebitCardWhite);
      imgAlt = 'White debit card';
      CardContainer = BlueWhiteContainer;
      formattedFullName = formatFullName(
        props.firstName,
        props.lastName,
        FULL_NAME_MAX_LENGTH
      );
      break;
    case DebitCardStyle.BLUE:
      imgSrc = ArtMap(Art.DebitCardBlue);
      imgAlt = 'Blue debit card';
      CardContainer = BlueWhiteContainer;
      formattedFullName = formatFullName(
        props.firstName,
        props.lastName,
        FULL_NAME_MAX_LENGTH
      );
      break;
    default:
      imgSrc = ArtMap(Art.DebitCardGenius);
      imgAlt = 'Black debit card: Genius Exclusive';
      CardContainer = GeniusContainer;
      formattedFirstName = formatFirstName(
        props.firstName,
        FIRST_NAME_MAX_LENGTH
      );
      formattedLastName = formatLastName(props.lastName, LAST_NAME_MAX_LENGTH);
  }

  return (
    <Container>
      <CardContainer
        cardStyle={props.style}
        tilted={props.tilted}
        shadow={props.shadow}
      >
        <img src={imgSrc} alt={imgAlt} />
        {props.style === DebitCardStyle.GENIUS ? (
          <>
            <div className='first-name'>{formattedFirstName}</div>
            <div className='last-name'>{formattedLastName}</div>
            <div className='last-four'>{props.lastFour || '1234'}</div>
          </>
        ) : (
          <div className='name'>{formattedFullName}</div>
        )}
      </CardContainer>
      {props.shadow === ShadowTypes.CORNER && <CornerShadow />}
    </Container>
  );
};

export default DebitCard;
