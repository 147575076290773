enum PlaidUpdateStates {
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
  COMPLETED = 'COMPLETED',
  COMPLETED_INITIAL_UPDATE = 'COMPLETED_INITIAL_UPDATE',
  INITIATED = 'INITIATED',
  FAILED = 'FAILED',
  INVALID = 'INVALID',
  VERIFIED = 'VERIFIED',
  SERVICE_ERROR = 'SERVICE_ERROR',
}

export default PlaidUpdateStates;
