import * as React from 'react';
import styled from 'styled-components';
import ArtMap, { Art } from 'constants/ArtMap';
import { fontSizes } from 'styles';

type Props = {
  handleOnChange?: (value: string) => void;
  debounced?: boolean;
  placeholder?: string;
  initialValue?: string;
};

const SearchIcon = styled.img`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0.8rem;
  margin: auto 0;
  width: 15px;
`;

const Container = styled.div`
  border: 1.5px solid ${({ theme }) => theme.colors.solitudeGray};
  border-radius: 6px;
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  padding-left: 2.8rem;

  input {
    padding: 0.6rem 0.4rem 0.6rem 0;
    background-color: transparent;
    ${fontSizes.fontSize16}
    line-height: 130%;
    height: 100%;
    width: 100%;
    color: ${({ theme }) => theme.colors.primaryText};
    cursor: text;
    outline: none;
    border: none;
  }
  input::placeholder {
    color: ${({ theme }) => theme.colors.primaryGray};
  }
  input:focus::placeholder {
    color: transparent;
  }
  input:disabled {
    cursor: not-allowed;
  }
`;

const SearchInput = (props: Props): React.ReactElement => {
  const [currentValue, setCurrentValue] = React.useState(
    props?.initialValue || ''
  );

  const handleValueOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value || '';
    setCurrentValue(value);
    props?.handleOnChange && props.handleOnChange(value);
  };

  return (
    <Container>
      <SearchIcon src={ArtMap(Art.Search)} />
      <input
        value={currentValue}
        placeholder={props.placeholder || 'Search'}
        onChange={handleValueOnChange}
      />
    </Container>
  );
};

export default SearchInput;
