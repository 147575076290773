import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'components/common/Button';
import CheckCircleList from 'components/common/List/CheckCircleList';
import Spacer from 'components/common/Spacer';
import ButtonsContainer from 'components/layout/ButtonsContainer';
import LeadContent from 'components/layout/LeadContent';
import PageBase from 'components/layout/PageBase';
import ArtMap, { Art } from 'constants/ArtMap';
import Pages, { PageType } from 'constants/Pages';
import useBrowserNavigationBlockWithBanner from 'hooks/useBrowserNavigationBlockWithBanner';
import useNavigateFunction from 'hooks/useNavigateFunction';
import useVerifyCurrentPage from 'hooks/useVerifyCurrentPage';
import * as language from 'reducers/entities/language';
import * as profile from 'reducers/entities/profile';
import { spacers } from 'styles/home';
import theme from 'styles/theme';
import * as WEB from 'types/interfaces';
import { getLanguageButtons, getLanguageSection } from 'utils/getFromLanguage';

type Props = {
  nextPage: string;
  fromSignup?: boolean;
};

const GeniusWelcome = ({
  nextPage,
  fromSignup = false,
}: Props): React.ReactElement => {
  const { updateCurrentGeniusPage } = useNavigateFunction();
  // Make Redux is up-to-date with currentSignUpPage
  useVerifyCurrentPage(Pages.GENIUS_WELCOME, PageType.GENIUS);
  const dispatch = useDispatch();

  const handleOnContinue = () => {
    dispatch(updateCurrentGeniusPage({ page: nextPage, fromSignup }));
  };

  const languageCtx = useSelector((state: WEB.RootState) => {
    return language.getGeniusPageLanguage(state, Pages.GENIUS_WELCOME);
  });
  const showTierPricing = useSelector((state: WEB.RootState) =>
    profile.getValueByField(state, 'has_tiered_genius_pricing')
  );

  const benefits = (languageCtx?.content?.benefits || []) as { text: string }[];

  const welcomeToGeniusSection = getLanguageSection(
    languageCtx,
    'welcomeToGenius'
  );
  const buttonsCtx = getLanguageButtons(languageCtx);

  // Block backwards navigation for tier pricing
  const handleBackwardsNavigation = () => !!showTierPricing;
  useBrowserNavigationBlockWithBanner(handleBackwardsNavigation);

  return (
    <PageBase>
      <img height={90} src={ArtMap(Art.GeniusPopper)} alt='' />
      <Spacer space={spacers.g8} />
      <LeadContent
        header={welcomeToGeniusSection?.header || ''}
        text={welcomeToGeniusSection?.text}
      />
      <Spacer space={spacers.g2} />
      <CheckCircleList items={benefits} />
      <ButtonsContainer>
        <Button
          id='genius-welcome-confirm-button'
          onClick={handleOnContinue}
          theme={theme.genius}
        >
          {buttonsCtx?.primary}
        </Button>
      </ButtonsContainer>
    </PageBase>
  );
};
export default GeniusWelcome;
