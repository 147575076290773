/* eslint-disable no-param-reassign */

/* eslint-disable @typescript-eslint/no-explicit-any */
import produce from 'immer';
import initialState from 'store.initialState';
import types from 'actions/types';
import { AccountTypeShort } from 'constants/AccountType';
import * as WEB from 'types/interfaces';
import { logger } from 'utils/logger';

// /////////////////////////////////////////////////////////////////////////////
// selectors
// /////////////////////////////////////////////////////////////////////////////

export const getDefaultInstitutions = (
  state: WEB.RootState
): WEB.Institution[] => state.entities.institution;

// /////////////////////////////////////////////////////////////////////////////
// reducers
// /////////////////////////////////////////////////////////////////////////////

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const institution = (
  state = initialState.entities.institution,
  action: WEB.Action
) => {
  const { type, payload } = action;
  return produce(state, (draft: WEB.RootState['entities']['institution']) => {
    switch (type) {
      case types.GET_INSTITUTIONS_SUCCESS: {
        // NOTE: Okay to perform sort and filter here given that this endpoint is called in only two places.
        const defaultInstitutions =
          payload.data && payload.data.filter ? payload.data : [];
        if (payload.data && !payload.data.filter) {
          logger.warn(
            `Bad data recieved on institution check: ${JSON.stringify(
              payload.data
            )}`
          );
        }
        const unsortedCommonIns = defaultInstitutions.filter(
          (ins: WEB.Institution) => ins.common
        );
        const unrankedIns = unsortedCommonIns.filter(
          (ins: WEB.Institution) => ins.rank === null
        );
        const rankedIns = unsortedCommonIns
          .filter((ins: WEB.Institution) => ins.rank !== null)
          .sort((a: WEB.Institution, b: WEB.Institution) => a.rank - b.rank);

        const commonIns = [...rankedIns, ...unrankedIns];
        const topIns = commonIns.filter((ins: WEB.Institution) => {
          const insPrimaryTypes =
            ins.primary_types.account_types_full_screen || [];
          return insPrimaryTypes.includes(AccountTypeShort.BANKING);
        });

        return topIns;
      }
      default:
    }
  });
};

export default institution;
