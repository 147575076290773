// Libraries
import * as React from 'react';

const useDebounce = (value: any, delay: number) => {
  const [debouncedValue, setDebouncedValue] = React.useState(value);

  React.useEffect(() => {
    // create new settimeout on value change
    const debouncedSetDebouncedValue = setTimeout(
      () => setDebouncedValue(value),
      delay
    );
    // clear previous timeout every time value changes
    return () => clearTimeout(debouncedSetDebouncedValue);
  }, [value]);

  // return debounced value
  return debouncedValue;
};

export default useDebounce;
