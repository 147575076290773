/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import loadSocureSessionId from 'actions/loadSocureSessionId';
import { StyledBannerDesktop, StyledBannerMobile } from 'components/BannerPane';
import Spacer from 'components/common/Spacer';
import Text, { TextColor, TextSizes } from 'components/common/Text';
import BorderContainer from 'components/layout/BorderContainer';
import {
  LeftPane,
  PageContainer,
  PaneWrapper,
  RightPane,
} from 'components/layout/FiftyFiftyPageLayout';
import { getImage } from 'components/layout/InfoPane';
import InfoPaneList from 'components/layout/InfoPaneList';
import PageBase from 'components/layout/PageBase';
import Navbar from 'pages/unauthenticated/Navbar';
import SignupForm from 'pages/unauthenticated/SignupForm';
import * as banner from 'reducers/entities/banner';
import { sizes } from 'styles/breakpoints';
import { breakpoints, fontSizes, mixins, spacers } from 'styles/index';
import * as WEB from 'types/interfaces';
import { AverageApprovedOverdraftLimit } from '../../constants';

export type SignupLandingType = 'overdraft';

const StyledPaneWrapper = styled(PaneWrapper)`
  @media (min-width: ${sizes.mobileLarge}) {
    padding-top: 5vh;
    align-items: stretch;
  }
`;

const StyledLeftPane = styled(LeftPane)`
  padding-right: ${mixins.pxToRem('103px')};

  @media (min-width: ${sizes.mobileLarge}) {
    padding-top: 0;
  }
  @media ${breakpoints.mobileLarge} {
    padding-right: 0;
    align-items: flex-start;
  }
`;

const StyledRightPane = styled(RightPane)`
  padding-left: ${mixins.pxToRem('103px')};
  @media ${breakpoints.mobileLarge} {
    padding-left: 0;
    padding-top: 0;
  }
`;

const LeftPaneContent = styled.div`
  max-width: 410px;
  white-space: pre-wrap;

  @media ${breakpoints.mobileLarge} {
    max-width: initial;
  }

  .header {
    font-size: ${mixins.pxToRem('72px')};
    font-weight: 700;
    line-height: 86.4px;
    @media ${breakpoints.mobileLarge} {
      ${fontSizes.fontSize30}
      line-height: 31.67px;
    }
  }

  .altHeader {
    font-size: ${mixins.pxToRem('50px')};
    font-weight: 700;
    line-height: 55px;
    width: 90%;
    @media ${breakpoints.mobileLarge} {
      width: 100%;
      ${fontSizes.fontSize24}
      line-height: 26.67px;
    }
  }
`;

const DesktopOnly = styled.div`
  @media ${breakpoints.mobileLarge} {
    display: none;
  }
`;

const MobileOnly = styled.div`
  @media (min-width: ${sizes.mobileLarge}) {
    display: none;
  }
`;

type Props = {
  landingType?: SignupLandingType;
};

type InfoPaneContent = {
  header?: string;
  text: string;
  disclaimer: string;
  image?: {
    filename: string;
    width: number;
  };
  altHeader?: string;
  list?: WEB.LanguageInfoList;
};

const getInfoPaneContent = (landingType?: SignupLandingType) => {
  let infoPane: InfoPaneContent;

  switch (landingType) {
    default:
      infoPane = {
        header: 'Welcome to Albert',
        text: 'Take control of your money.',
        disclaimer: `**Albert is not a bank. Banking services provided by Sutton Bank, Member FDIC.** Early access to direct deposit funds may vary depending on the payer’s deposit timing. With Instant overdraft coverage, eligible members can overdraw their Albert Cash account for ACH transfers, ATM withdrawals, debit card purchases, and other electronic transfers. Overdraft limits range from $25 to $250. The average approved overdraft limit is approximately ${AverageApprovedOverdraftLimit.amount} as of ${AverageApprovedOverdraftLimit.date}. A Genius subscription is required to access Instant overdrafts but you may cancel any time. Not all Genius subscribers will qualify for Instant overdraft. Fast transfer, ATM, and other applicable fees may apply. See app for details. Whether your overdrafts will be paid is discretionary and we reserve the right not to pay in our sole discretion. If overdrafts are not repaid within 10 days, your repayment will be overdue and your Instant access may be temporarily suspended.`,
        list: {
          type: WEB.ListType.List,
          altText: false,
          items: [
            {
              icon: 'ic_banking.svg',
              iconWidth: 52,
              header: 'A Genius bank account',
              text: 'Get paid faster. No hidden fees. Save and invest automatically right from your paycheck. A team of financial experts – aka our geniuses – at your fingertips.',
            },
            {
              icon: 'ic_paperplane.svg',
              iconWidth: 52,
              header: 'Instant',
              text: 'Get up to $250 when you need it with Instant coverage. No credit check.',
            },
            {
              icon: 'ic_lightbulb.svg',
              iconWidth: 52,
              header: 'Budget and save automatically',
              text: 'Our geniuses find savings you’re missing, help you pay down debt, lower your bills and much more.',
            },
          ],
        },
      };
      break;
  }

  return infoPane;
};

const Signup = (props: Props): React.ReactElement => {
  const { landingType } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadSocureSessionId('signup'));
  }, [dispatch]);

  // ///////////////////////////////
  /* ======== MAPPED STATE ====== */
  // ///////////////////////////////
  const hasWarningBanner = !!useSelector(banner.getBanner);

  // //////////////////////////////////
  /* =========== LANGUAGE ========== */
  // //////////////////////////////////
  const infoPaneContext = getInfoPaneContent(landingType);

  // infoPaneContext properties.
  const { header, image, altHeader, text, disclaimer, list } = infoPaneContext;

  const Disclaimer = () => (
    <Text size={TextSizes.SMALL} color={TextColor.GRAY_DARK}>
      <ReactMarkdown>{disclaimer}</ReactMarkdown>
    </Text>
  );

  return (
    <>
      <Navbar />
      <PageContainer>
        {hasWarningBanner && <StyledBannerMobile hideIcon />}
        <StyledPaneWrapper>
          <StyledLeftPane id='signup-left-pane'>
            <LeftPaneContent>
              {image && getImage(image)}
              {header && <div className='header'>{header}</div>}
              {altHeader && <div className='altHeader'>{altHeader}</div>}
              <Spacer space={spacers.tiny} />
              {text && <Text size={TextSizes.LARGE}>{text}</Text>}
              {list && (
                <>
                  <Spacer space={spacers.tabSmall} />
                  <InfoPaneList list={list} addExpanderSubheadSpacing={false} />
                </>
              )}
              {disclaimer && (
                <DesktopOnly>
                  <Spacer space={spacers.large} />
                  <Disclaimer />
                </DesktopOnly>
              )}
            </LeftPaneContent>
          </StyledLeftPane>
          <StyledRightPane>
            <PageBase maxWidth='584px'>
              {hasWarningBanner && <StyledBannerDesktop hideIcon />}
              <BorderContainer>
                <SignupForm hasWarningBanner={hasWarningBanner} />
              </BorderContainer>
              {disclaimer && (
                <MobileOnly>
                  <Spacer space={spacers.small} />
                  <Disclaimer />
                </MobileOnly>
              )}
            </PageBase>
          </StyledRightPane>
        </StyledPaneWrapper>
      </PageContainer>
    </>
  );
};

export default Signup;
