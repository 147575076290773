/* eslint-disable @typescript-eslint/no-unsafe-member-access */

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { v4 as uuidv4 } from 'uuid';
import * as WEB from 'types/interfaces';

// Redux middleware to append the Albert trace ID to outgoing requests.
export default () =>
  (next: (action: WEB.Action) => void) =>
  (action: WEB.Action): void => {
    const { request = {} } = action?.payload || {};
    const requestHeaders = request?.headers || {};

    // Add albert-trace-id if request is outbound and it does not exist.
    if (
      !!request?.method &&
      !!request?.url &&
      !requestHeaders?.['albert-trace-id']
    ) {
      requestHeaders['albert-trace-id'] = uuidv4();
    }

    request.headers = requestHeaders;

    // Proceed with action
    return next(action);
  };
