import types from 'actions/types';
import AlbertClient from 'api/AlbertClient';
import * as WEB from 'types/interfaces';
import { onErrorDisplayDefaultBanner } from 'utils/axiosMiddlewareUtils';

type Data = {
  shipping_address: WEB.AddressPhysical;
  express_mail: boolean;
};

/**
 * Submit a Banking application.
 */
export default ({
    bankingAccountId,
    data,
  }: {
    bankingAccountId: number;
    data: Data;
  }): WEB.ActionCreator<Promise<Record<string, any>>> =>
  async (dispatch: WEB.Dispatch): Promise<Record<string, any>> => {
    let error = false;

    await dispatch({
      types: [
        types.SUBMIT_BANKING_APPLICATION_REQUEST,
        types.SUBMIT_BANKING_APPLICATION_SUCCESS,
        types.SUBMIT_BANKING_APPLICATION_FAILURE,
      ],
      payload: {
        request: {
          method: 'post',
          url: AlbertClient.bankingSubmitApplication(bankingAccountId),
          data,
        },
        options: {
          onError: (middlewareOptions: any, options: any) => {
            error = true;
            return onErrorDisplayDefaultBanner(middlewareOptions, options);
          },
        },
      },
    });

    return { error };
  };
