import types from 'actions/types';
import AlbertClient from 'api/AlbertClient';
import * as WEB from 'types/interfaces';
import { onErrorDisplayDefaultBanner } from 'utils/axiosMiddlewareUtils';

export interface UpdateGeniusPricingFields {
  savings_bonus?: number;
}

export default (
    fieldsToUpdate: UpdateGeniusPricingFields
  ): WEB.ActionCreator<Promise<boolean>> =>
  async (dispatch: WEB.Dispatch): Promise<boolean> => {
    let error = false;

    await dispatch({
      types: [
        types.UPDATE_GENIUS_PRICING_REQUEST,
        types.UPDATE_GENIUS_PRICING_SUCCESS,
        types.UPDATE_GENIUS_PRICING_FAILURE,
      ],
      payload: {
        request: {
          method: 'patch',
          url: AlbertClient.geniusSubscriptionView(),
          data: {
            is_web: true,
            ...fieldsToUpdate,
          },
        },
        options: {
          onError: (middlewareOptions: any, options: any) => {
            error = true;
            return onErrorDisplayDefaultBanner(middlewareOptions, options);
          },
        },
      },
    });

    return error;
  };
