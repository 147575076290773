import { InfoCircle } from '@styled-icons/boxicons-regular/InfoCircle';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import styled from 'styled-components';
import { breakpoints } from 'styles';

type Props = {
  onClick: () => void;
};

const StyledInfoCircle = styled(InfoCircle)`
  display: none;
  @media ${breakpoints.mobileLarge} {
    width: 24px;
    height: 24px;
    display: inline-block;
    cursor: pointer;
    position: absolute;
    top: 30px;
    right: 20px;
  }
`;

/**
 * Render an info button on the top of the page
 */
const TopInfoButton = ({ onClick }: Props): React.ReactElement | null => {
  const rootNode = document.getElementById('root');
  if (!rootNode) {
    return null;
  }
  return ReactDOM.createPortal(
    <StyledInfoCircle onClick={onClick} />,
    rootNode
  );
};

export default React.memo(TopInfoButton);
