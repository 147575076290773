// Modules
import PaymentFrequency from 'constants/PaymentFrequency';
import { RootState } from 'types/interfaces';
import { Theme } from './constants';

const InitialState: RootState = {
  entities: {
    profile: {},
    appData: {},
    language: {
      signup: {},
      genius: {},
      kyc: {},
      originalOrder: [],
    },
    institution: [],
    banner: {
      type: '',
      message: '',
      isHidden: false,
    },
    geniusPayments: [],
  },
  ui: {
    app: {
      loggedIn: false,
      theme: Theme.MAIN,
      initializedApp: false,
      isLoading: false,
      pollingFunction: {},
      show2FA: false,
    },
    signup: {
      checkpoint: '',
      currentPage: '',
      selectedInsId: '',
    },
    genius: {
      currentPage: '',
      selectedPrice: 0,
      annualBonus: 0,
      selectedGeniusFrequency: PaymentFrequency.ANNUAL, // Annual or monthly
    },
    kyc: {
      currentPage: '',
      mfa: [],
      showPersonaVerification: false,
    },
    banking: {
      isCreating: false,
      previouslySubmittedTransaction: {},
      addAccountsClicked: false,
    },
  },
};

export default InitialState;
