/* eslint-disable react/destructuring-assignment */
import * as React from 'react';
import styled from 'styled-components';
import Input from 'components/common/Input';
import { renderLabels } from 'components/common/Input/shared';
import Text, { TextColor, TextSizes } from 'components/common/Text';
import { KeyboardEventKeys } from 'constants/index';
import useOutsideClickDetection from 'hooks/useOutsideClickDetection';
import { fontSizes, mixins } from 'styles';
import { DropdownItem } from 'types/interfaces';
import {
  Props as BaseDropdownProps,
  StyledDropdownItem as StyledBaseDropdownItem,
  StyledDropdown,
  StyledText,
} from './Dropdown';

export type Props = BaseDropdownProps & {
  id: string;
  placeholder?: string;
  emptyText?: React.ReactNode;
  value?: string;
  isLoading?: boolean;
  preventResetOnOutsideClick?: boolean;
  hideLabelOnMobile?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSelect: (option: Option) => void;
};

export type Option = {
  key: number;
  value: string | number;
} | null;

const StyledDropdownItem = styled(StyledBaseDropdownItem)`
  min-height: ${mixins.pxToRem('65px')};
  padding: ${mixins.pxToRem('22px')} ${mixins.pxToRem('29px')}
    ${mixins.pxToRem('18px')} ${mixins.pxToRem('28px')};
`;

const Placeholder = styled.div`
  ${fontSizes.fontSize16}
  padding: ${mixins.pxToRem('32px')} ${mixins.pxToRem('30px')} ${mixins.pxToRem(
    '24px'
  )};
  text-align: center;
`;

const TypeheadContainer = styled.div`
  position: relative;
`;

const TypeaheadDropdown = (props: Props): React.ReactElement => {
  // ///////////////////////////////
  /* =========== STATE ========== */
  // ///////////////////////////////
  const containerRef = React.useRef<HTMLDivElement>(null);
  const menuRef = React.useRef<HTMLDivElement>(null);

  const [searchTerm, setSearchTerm] = React.useState<string>(
    props?.value || ''
  );
  const [isOpen, setOpen] = React.useState<boolean>(false);
  const [isInvalidValue, setIsInvalidValue] = React.useState(false);

  // Convert items to a list of objects if they are strings
  const itemsFormatted: DropdownItem[] = props.items.map((item) => {
    if (typeof item === 'string') return { value: item };
    return item;
  });

  const defaultIndex = props.defaultValue
    ? itemsFormatted.findIndex((item) => item.value === props.defaultValue)
    : 0;

  const [activeItem, setActiveItem] = React.useState<number>(
    defaultIndex < 0 ? 0 : defaultIndex
  );
  const [hovering, setHovering] = React.useState(false);

  // //////////////////////////////////
  /* =========== HANDLERS ========== */
  // //////////////////////////////////

  const handleCloseDropdown = () => {
    setOpen(false);
    setActiveItem(0);
    props.onClose && props.onClose();
  };

  const handleOpenDropdown = () => {
    setOpen(true);
    props.onOpen && props.onOpen();
  };

  const handleOutsideClick = React.useCallback(() => {
    // Reset the typeahead input to the selected value,
    // so it's clear which value is stored
    if (props.preventResetOnOutsideClick) {
      setIsInvalidValue(true);
      props.onSelect(null);
    } else {
      setSearchTerm(props.value || '');
    }
    handleCloseDropdown();
  }, [props.value]);

  const handleSelect = (option: Option) => {
    handleCloseDropdown();
    props.onSelect && props.onSelect(option);
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsInvalidValue(false);
    const { value } = event.target;
    setSearchTerm(value);
    props.onChange(event);

    // User started typing
    if (value && !isOpen) {
      handleOpenDropdown();

      // User cleared the typeahead input
    } else if (!value) {
      // Clear the previously selected option
      handleSelect(null);
    }
  };

  /* Listen for keyboard events to go to the next element in the list */
  const onKeyDown = React.useCallback(
    (event: KeyboardEvent) => {
      const element = menuRef?.current;
      if (element) {
        switch (event.key) {
          case KeyboardEventKeys.UP: {
            if (activeItem !== 0) {
              setActiveItem(activeItem - 1);
            }
            break;
          }
          case KeyboardEventKeys.DOWN: {
            const size = itemsFormatted.length;
            if (activeItem + 1 < size) {
              setActiveItem(activeItem + 1);
            }
            break;
          }
          // eslint-disable-next-line prettier/prettier
          case KeyboardEventKeys.ENTER: {
            // Prevent the react-final-form from submitting
            event.preventDefault();
            const foundItem = itemsFormatted[activeItem];
            if (foundItem) {
              const key = activeItem;
              const { value } = foundItem;
              handleSelect({ key, value });
            }
            break;
          }
          default:
        }
      }
    },
    [activeItem, props.items]
  );

  const toggleHovering = () => setHovering(!hovering);

  // //////////////////////////////////
  /* =========== EFFECTS ========== */
  // //////////////////////////////////

  React.useEffect(() => {
    document.addEventListener('keydown', onKeyDown);
    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [activeItem, props.items]);

  React.useEffect(() => {
    // When a selection is made, update the
    // typeahead input so it appears like
    // the selection auto-populated the input
    if (typeof props.value === 'string') {
      setSearchTerm(props.value);
    }
  }, [props.value]);

  useOutsideClickDetection(containerRef, handleOutsideClick, isOpen);

  // //////////////////////////////////
  /* =========== RENDER ========== */
  // //////////////////////////////////

  const dropdownItems: React.ReactElement[] = itemsFormatted.map(
    ({ value, subValue }, key) => (
      <StyledDropdownItem
        isActive={key === activeItem}
        key={`item-${value}`}
        onClick={() => handleSelect({ key, value })}
        hovering={hovering}
        hasSubValues={!!subValue}
      >
        <StyledText>{value}</StyledText>
        {subValue && (
          <Text size={TextSizes.SMALL} color={TextColor.GRAY}>
            {subValue}
          </Text>
        )}
      </StyledDropdownItem>
    )
  );

  const emptyText = (
    <Placeholder>{props.emptyText || 'No results found.'}</Placeholder>
  );

  return (
    <TypeheadContainer ref={containerRef}>
      {renderLabels(props)}
      <Input.TextControlled
        id={`${props.id}-input`}
        type='text'
        value={searchTerm}
        placeholder={props.placeholder}
        floatingLabel={props.floatingLabel}
        onChange={onChange}
        autoComplete='off'
        invalid={(props.invalid || isInvalidValue) && props.showError}
        errorText={props.errorText}
        height={props.height}
      />
      {isOpen && !props.isLoading && (
        <StyledDropdown
          ref={menuRef}
          className='al-dropdown-menu'
          onMouseEnter={toggleHovering}
          onMouseLeave={toggleHovering}
        >
          {dropdownItems.length ? dropdownItems : emptyText}
        </StyledDropdown>
      )}
    </TypeheadContainer>
  );
};

export default TypeaheadDropdown;
