import * as React from 'react';
import { useSelector } from 'react-redux';
import InfoPane from 'components/layout/InfoPane';
import * as language from 'reducers/entities/language';
import * as kyc from 'reducers/ui/kyc';
import * as WEB from 'types/interfaces';
import { getLanguageInfo } from 'utils/getFromLanguage';

interface Props {
  size?: string;
}

const KYCInfoPane = (props: Props): React.ReactElement => {
  const { size } = props;
  const currentPage = useSelector(kyc.getCurrentPage);
  const pageLanguage = useSelector((state: WEB.RootState) => {
    return language.getKYCPageLanguage(state, currentPage);
  });
  const infoPaneContext = getLanguageInfo(pageLanguage);

  return (
    <InfoPane.Desktop
      size={size}
      currentPage={currentPage}
      addExpanderSubheadSpacing
      {...infoPaneContext}
    />
  );
};

export default KYCInfoPane;
