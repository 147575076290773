/* eslint-disable react/no-array-index-key */
import * as Sentry from '@sentry/react';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import getProfile from 'actions/async/getProfile';
import submitKYCMFA from 'actions/async/submitKYCMFA';
import clearKYCMFAQuestions from 'actions/clearKYCMFAQuestions';
import Button from 'components/common/Button';
import Modal from 'components/common/Modal';
import Radio from 'components/common/Radio';
import ButtonsContainer from 'components/layout/ButtonsContainer';
import PageBase from 'components/layout/PageBase';
import ErrorMessages, { ErrorTitles } from 'constants/ErrorMessages';
import NotificationTypes from 'constants/NotificationTypes';
import Pages, { PageType } from 'constants/Pages';
import ProductType from 'constants/ProductType';
import useNavigateFunction from 'hooks/useNavigateFunction';
import useVerifyCurrentPage from 'hooks/useVerifyCurrentPage';
import * as language from 'reducers/entities/language';
import * as kyc from 'reducers/ui/kyc';
import * as WEB from 'types/interfaces';
import { getLanguageButtons, getLanguageInfo } from 'utils/getFromLanguage';
import { hash32 } from 'utils/hash';
import { logger } from 'utils/logger';

// Constants
const MFA_TIME_LIMIT_MS = 3 * 60 * 1000; // min * sec * ms (3 minutes)
const MFA_QUESTION_AMOUNT = 3;

type Props = {
  product: ProductType;
  fromSignup?: boolean;
  pageOnComplete: string;
  exitKYCFlow: (showPersonaVerification: boolean) => Promise<boolean>;
};

const KYCMFA = (props: Props): React.ReactElement => {
  const { fromSignup, product, pageOnComplete, exitKYCFlow } = props;

  // ///////////////////////////////
  /* =========== HOOKS ========== */
  // ///////////////////////////////
  const { updateCurrentKYCPage } = useNavigateFunction();
  const dispatch = useDispatch();

  // Make Redux is up-to-date with current page.
  useVerifyCurrentPage(Pages.KYC_MFA, PageType.KYC);

  // ///////////////////////////////
  /* ======== MAPPED STATE ====== */
  // ///////////////////////////////

  const languageCtx = useSelector((state: WEB.RootState) =>
    language.getKYCPageLanguage(state, Pages.KYC_MFA)
  );

  const mfaQuestions = useSelector(kyc.getMFAQuestions);
  const kycConfirmPath = useSelector((state: WEB.RootState) => {
    return language.getKYCRouteByPage(state, Pages.KYC_CONFIRM, fromSignup);
  });

  const showPersonaFlow = useSelector((state: WEB.RootState) =>
    language.getSignupFlag(state, 'showPersonaFlow')
  );

  // Should never land on this page without MFA questions already in the store.
  if (!mfaQuestions.length) {
    logger.error('Missing KYC MFA questions but landed on KYCMFA page.');
    return <Navigate to={kycConfirmPath} />;
  }

  // ///////////////////////////////
  /* =========== STATE ========== */
  // ///////////////////////////////
  const [showErrorModal, setShowErrorModal] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [sessionExpired, setSessionExpired] = React.useState(false);

  // Initial state of answers payload (to submit answers).
  // NOTE: There's always a fixed number of 3 MFA questions.
  const question1 = {
    type: mfaQuestions[0].type,
    answer: null,
    selectedIndex: null,
  };
  const question2 = {
    type: mfaQuestions[1].type,
    answer: null,
    selectedIndex: null,
  };
  const question3 = {
    type: mfaQuestions[2].type,
    answer: null,
    selectedIndex: null,
  };
  const [userAnswers, setUserAnswers] = React.useState<WEB.MFAAnswerSet>({
    question1,
    question2,
    question3,
  });

  // Use has a 3 minute time limit to complete MFA questions.
  // NOTE: This time limit is enforced in the backend as well.
  React.useEffect(() => {
    const sessionTimeout = setTimeout(() => {
      setSessionExpired(true);
    }, MFA_TIME_LIMIT_MS);
    return () => {
      clearTimeout(sessionTimeout);
    };
  }, []);

  // ///////////////////////////////
  /* ========= HANDLERS ========= */
  // ///////////////////////////////

  // Clean the userAnswers payload (remove selectedIndex).
  const cleanData = (): WEB.MFAAnswerSet => {
    const cleanedData: WEB.MFAAnswerSet = {};

    // Remove "selectedIndex" property.
    Object.entries(userAnswers).forEach(
      ([key, question]: [string, WEB.MFAAnswer]) => {
        const cleanedQuestion = { ...question };
        delete cleanedQuestion.selectedIndex;
        cleanedData[key] = cleanedQuestion;
      }
    );

    return cleanedData;
  };

  // Show error modal and reset button loading state if necessary.
  const promptErrorModal = (): void => {
    isSubmitting && setIsSubmitting(false);
    setShowErrorModal(true);
  };

  const handleOnSubmit = async (): Promise<void> => {
    // Check if time-limit is up.
    if (sessionExpired) {
      promptErrorModal();
      return;
    }

    // Check that all answers are filled out. Do not proceed if user hasn't answered all q's.
    const emptyAnswers = Object.values(userAnswers).filter(
      ({ answer }: { answer: string | null }) => {
        return answer === null;
      }
    );

    // NOTE: Might want to show an error banner here but will defer to Sarah and team during QA.
    if (emptyAnswers.length) {
      return;
    }

    // Button loading state.
    setIsSubmitting(true);

    // Prep user answers for submit request.
    const cleanedUserAnswers: WEB.MFAAnswerSet = cleanData();
    const mfaAttemptResponse: any = await dispatch(
      submitKYCMFA(cleanedUserAnswers)
    );

    const {
      products = {},
      // eslint-disable-next-line @typescript-eslint/naming-convention
      is_universal_kyc_complete = false,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      kyc_passed = false,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      show_persona_verification = false,
    } = (mfaAttemptResponse?.payload?.data || {}) as WEB.KYCAppData;

    // Show error modal and return user back to KYCConfirm page if:
    // - kyc_passed is false OR
    // - product kyc (current attempt) was not successful AND universal kyc is NOT complete
    const productKYCSucceeded = !!products?.[product];
    if (!kyc_passed || (!productKYCSucceeded && !is_universal_kyc_complete)) {
      promptErrorModal();
      return;
    }

    // Reset button loading state and navigate to next page (after KYC flow).
    const { error: profileErr }: any = await dispatch(getProfile());
    const exitError = await exitKYCFlow(show_persona_verification);
    setIsSubmitting(false);
    if (profileErr || exitError) {
      return;
    }

    // Clear MFA questions in redux - prevent user from being able to come back to this page.
    dispatch(clearKYCMFAQuestions());

    let page = pageOnComplete;

    if (show_persona_verification) {
      if (showPersonaFlow) {
        page = Pages.KYC_VERIFY_IDENTITY_WEB;
      } else {
        page = Pages.KYC_VERIFY_IDENTITY;
      }
    }

    dispatch(
      updateCurrentKYCPage({
        fromSignup,
        page,
      })
    );
  };

  const handleRetryNavigation = (): void => {
    // Clear MFA questions in redux - prevent user from being able to come back to this page.
    // NOTE: We redirect user to KYCConfirm page if MFA questions is empty list.
    dispatch(clearKYCMFAQuestions());

    // Navigate user back to KYC confirm page.
    dispatch(
      updateCurrentKYCPage({
        fromSignup,
        page: Pages.KYC_CONFIRM,
      })
    );
  };

  const handleUserAnswerOnClick = (
    choice: string,
    questionIndex: number,
    answerIndex: number
  ): void => {
    setUserAnswers((prevState: WEB.MFAAnswerSet): WEB.MFAAnswerSet => {
      const updatedState = { ...prevState };

      // NOTE: Questions are 1-indexed whereas list is 0-indexed.
      const questionToUpdate = updatedState[`question${questionIndex}`];
      questionToUpdate.answer = choice;
      questionToUpdate.selectedIndex = answerIndex;
      return updatedState;
    });
  };

  // //////////////////////////////////
  /* =========== LANGUAGE ========== */
  // //////////////////////////////////

  const buttonsCtx = getLanguageButtons(languageCtx);
  const infoPaneContent = getLanguageInfo(languageCtx);

  // ///////////////////////////////
  /* ========= QUESTIONS ======== */
  // ///////////////////////////////

  const mfaQuestionComponents = mfaQuestions.map(
    ({ answer, prompt }: WEB.MFAQuestion, index: number) => {
      // NOTE: Questions are 1-indexed whereas the array is 0-indexed.
      const questionIndex = index + 1;
      // ie. "question1" in state userAnswers.
      const stateKey = `question${questionIndex}`;
      /**
       * Logging to help debug WEB-0804-1326 - Investigate selectedIndex of undefined error.
       */
      if (!userAnswers[stateKey]) {
        /**
         * We are only concerned if missing answer is associated with a question
         * less than the set MFA_ QUESTION_AMOUNT limit. Backend currently
         * only takes three questions, so we aren't concerned about questions
         * 4, 5, etc.
         */
        if (questionIndex <= MFA_QUESTION_AMOUNT) {
          const errorContext = {
            question: prompt,
            questionKey: stateKey,
            numberOfQuestions: mfaQuestions.length,
          };
          Sentry.setContext('MFA Details', errorContext);
          logger.error('Failed to retrieve answers for a given question');
        }
        return null;
      }
      return (
        <React.Fragment key={`mfa-fragment-${hash32(prompt)}`}>
          <Radio.Group
            key={`mfa-group-fragment-${hash32(prompt)}`}
            id={`mfa-${prompt}`}
            label={prompt}
          >
            {answer.map((choice: string, answerIndex: number) => {
              return (
                <Radio.Button
                  key={`mfa-answer-${hash32(choice)}`}
                  id={`answer-${choice}`}
                  name={choice}
                  label={choice}
                  value={choice}
                  selected={userAnswers[stateKey].selectedIndex === answerIndex}
                  onClick={() => {
                    handleUserAnswerOnClick(choice, questionIndex, answerIndex);
                  }}
                />
              );
            })}
          </Radio.Group>
        </React.Fragment>
      );
    }
  );

  const leadText =
    typeof infoPaneContent?.text === 'string' ? [infoPaneContent.text] : [];

  return (
    <>
      <PageBase
        mobileHeader={infoPaneContent?.header || ''}
        mobileLead={leadText}
      >
        {mfaQuestionComponents}
        <ButtonsContainer>
          <Button
            id='submit-mfa'
            disabled={isSubmitting}
            isLoading={isSubmitting}
            onClick={handleOnSubmit}
          >
            {buttonsCtx.primary}
          </Button>
        </ButtonsContainer>
        <Modal.Basic
          title={ErrorTitles.kyc.unableToVerify}
          description={ErrorMessages.kyc.unableToVerify}
          submitLabel='Try again'
          iconType={NotificationTypes.ERROR}
          onClickX={handleRetryNavigation}
          onSubmit={handleRetryNavigation}
          show={showErrorModal}
        />
      </PageBase>
    </>
  );
};

export default KYCMFA;
