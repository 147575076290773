/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import getInstitutions from 'actions/async/getInstitutions';
import AlbertClient from 'api/AlbertClient';
import Input from 'components/common/Input';
import Spacer from 'components/common/Spacer';
import Text, { TextColor, TextSizes } from 'components/common/Text';
import Tooltip from 'components/common/Tooltip';
import ExternalLinkIcon from 'components/icons/ExternalLinkIcon';
import useDebounce from 'hooks/useDebounce';
import { getDefaultInstitutions } from 'reducers/entities/institution';
import { breakpoints, spacers } from 'styles';
import * as WEB from 'types/interfaces';
import { logger } from 'utils/logger';
import wrappedFetch from 'utils/wrappedFetch';
import InstitutionCard from './InstitutionCard';

type Props = {
  header?: string;
  description?: string;
  hideDescriptionOnMobile?: boolean;
  selectedInsId?: string;
  disabled?: boolean;
  handleOnSelect: (institutionId: string, insHasAuth?: boolean) => void;
};

const Container = styled.div`
  margin: 0;
  padding: 0;
`;

const StyledDefaultMessage = styled.div`
  width: 100%;
  border-bottom: thin solid ${({ theme }) => theme.colors.lightGray1};
`;

const SearchInputContainer = styled.div`
  margin-top: 2.75rem;
`;

const Grid = styled.div`
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-rows: 110px;
  grid-gap: 10px;
  overflow: scroll;
  border-bottom: thin solid ${({ theme }) => theme.colors.lightGray1};
  width: 100%;
  justify-content: center;
  padding: ${spacers.small} 0;

  /* 3-column grid on 500px */
  @media ${breakpoints.institutionGrid} {
    grid-template-columns: 1fr 1fr 1fr;
  }

  /* 2-column grid on mobile medium */
  @media ${breakpoints.mobileMedium} {
    grid-template-columns: 1fr 1fr;
  }
`;

const Description = styled(Text)<{ hideOnMobile?: boolean }>`
  @media ${breakpoints.mobileLarge} {
    ${({ hideOnMobile }) => hideOnMobile && 'display: none;'}
  }
`;

const InstitutionSelectionGrid = (props: Props): React.ReactElement => {
  const dispatch = useDispatch();
  const {
    header,
    description,
    hideDescriptionOnMobile,
    selectedInsId = '',
    disabled = false,
  } = props;
  const DEBOUNCE_TIMEOUT = 350; // 0.35 seconds

  // //////////////////////////////////////////
  /* ============ Mapped State ============= */
  // //////////////////////////////////////////
  const defaultInstitutions = useSelector(getDefaultInstitutions);

  // //////////////////////////////////////////
  /* ============ Local State ============== */
  // //////////////////////////////////////////
  const [search, setSearch] = React.useState('');
  const debouncedSearch = useDebounce(search.trim(), DEBOUNCE_TIMEOUT);
  const [institutions, setInstitutions] = React.useState<WEB.Institution[]>([]);

  // //////////////////////////////////////////
  /* ============== Handlers =============== */
  // //////////////////////////////////////////
  const handleSearchOnChange = (value: string) => {
    setSearch(value);
  };

  const handleInsOnClick = (institutionId: string, insHasAuth?: boolean) => {
    if (disabled) return;

    // Unselect if selected ins was clicked again
    const updatedInsSelection =
      selectedInsId !== institutionId ? institutionId : '';
    // Only call handle on select if institution has been SELECTEd and a
    // handleOnSelect exists as a prop
    if (updatedInsSelection && props?.handleOnSelect) {
      props.handleOnSelect(updatedInsSelection, insHasAuth);
    }
  };

  // //////////////////////////////////////////
  /* ============= Async Func ============== */
  // //////////////////////////////////////////

  const loadInstitutions = (query?: string) => {
    let queryParams: URLSearchParams | undefined;
    if (query) {
      queryParams = new URLSearchParams({ query });
    }

    const institutionsEndpoint = AlbertClient.institutionView(queryParams);

    // Async call to fetch and store institutions in local state
    wrappedFetch(institutionsEndpoint)
      .then((res) => {
        if (res.ok) {
          return res.json();
        }

        return res.json().then((data) => {
          throw new Error(data?.message);
        });
      })
      .then((res) => {
        setInstitutions(res || []);
      })
      .catch((error) => {
        logger.error(error);
      });
  };

  const handleSearchResults = () => {
    // If user does not input search term, set it back to default institutions
    if (!debouncedSearch.length) {
      setInstitutions(defaultInstitutions);
      // Search common institutions if user input is one character
    } else if (debouncedSearch.length < 2) {
      const debouncedSearchLower = debouncedSearch.toLowerCase();
      const results = defaultInstitutions.filter((ins: WEB.Institution) => {
        const insNameLower = (ins?.name || '').toLowerCase();
        return insNameLower.includes(debouncedSearchLower);
      });
      setInstitutions(results);
      // Query the BE for institutions that match the query string
    } else {
      loadInstitutions(debouncedSearch);
    }
  };

  // //////////////////////////////////////////
  /* ============== Lifecycle ============== */
  // //////////////////////////////////////////
  React.useEffect(() => {
    // Fetch default institutions on component mount if not already.
    if (!defaultInstitutions.length) {
      dispatch(getInstitutions());
    } else {
      // Set default institutions as institutions to display.
      setInstitutions(defaultInstitutions);
    }
  }, [defaultInstitutions]);

  // Load common institutions on mount
  React.useEffect(() => {
    // Reset selected institution on search change
    handleInsOnClick('');
    handleSearchResults();
  }, [debouncedSearch]);

  // //////////////////////////////////////////
  /* ======== Institution to Display ======= */
  // //////////////////////////////////////////
  const unrankedInstitutions = institutions.filter(
    (ins: WEB.Institution) => ins.rank === null
  );

  const rankedInstitutions = institutions
    .filter((ins: WEB.Institution) => ins.rank !== null)
    .sort((a: any, b: any) => a.rank - b.rank);

  const institutionsToDisplay = [
    ...rankedInstitutions,
    ...unrankedInstitutions,
  ].map((el: WEB.Institution) => {
    const selected = selectedInsId === el.institution_id;
    return (
      <InstitutionCard
        key={`${el.institution_id}-card`}
        data-for='institution-tooltip'
        data-tip='test'
        selected={selected}
        institutionUrl={el.url}
        institutionHasAuth={!!el.has_auth}
        institutionId={el.institution_id}
        institutionName={el.name}
        handleOnClick={handleInsOnClick}
        logo={el.logo}
        imageName={el.image_name}
      />
    );
  });

  const noResultMessage = (
    <StyledDefaultMessage key='no-result-default'>
      <Spacer space={spacers.small} />
      <Text size={TextSizes.SMALL} color={TextColor.GRAY}>
        Can&apos;t find your institution?&nbsp;
        <Text
          size={TextSizes.SMALL}
          color={TextColor.GRAY}
          underline
          inline
          weight='500'
        >
          <a
            href='https://help.albert.com/hc/en-us/articles/222694168-Unable-to-locate-a-financial-institution'
            target='_blank'
            rel='noreferrer'
          >
            Click here for tips.
            <ExternalLinkIcon />
          </a>
        </Text>
      </Text>
    </StyledDefaultMessage>
  );

  return (
    <Container>
      {header && <h2>{header}</h2>}
      {description && (
        <Description hideOnMobile={hideDescriptionOnMobile}>
          {description}
        </Description>
      )}
      <SearchInputContainer>
        <Input.Search handleOnChange={handleSearchOnChange} />
      </SearchInputContainer>
      {institutionsToDisplay.length ? (
        <Grid>{institutionsToDisplay}</Grid>
      ) : (
        noResultMessage
      )}
      {/* Re-render tooltip anytime insitutions change. Tooltip will not show if not re-rendered */}
      <Tooltip
        key={`tooltip-${new Date().valueOf()}`}
        delayShow={50}
        html
        id='institution-tooltip'
      />
    </Container>
  );
};

export default InstitutionSelectionGrid;
