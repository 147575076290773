import * as WEB from 'types/interfaces';

// ////////////////////////////////////////////////////////////////
/* Util methods to conveniently extract state from language JSON */
// ////////////////////////////////////////////////////////////////

export const getLanguageSection = <T extends object = WEB.LanguageSection>(
  lang: WEB.PageLanguage,
  section: string
): T => {
  const content = lang?.content ?? ({} as WEB.LanguageContent);
  return (content?.[section] as T) ?? {};
};

export const getLanguageButtons = (
  lang: WEB.PageLanguage
): WEB.LanguageButtons => {
  const buttons = lang?.buttons || ({} as WEB.LanguageButtons);
  return buttons;
};

export const getLanguageInfo = (lang: WEB.PageLanguage): WEB.LanguageInfo => {
  const info = lang?.info || ({} as WEB.LanguageInfo);
  return info;
};

export const getLanguageAltInfo = (
  lang: WEB.PageLanguage
): WEB.LanguageInfo => {
  const info = lang?.altInfo || ({} as WEB.LanguageInfo);
  return info;
};

export const getLanguageModal = (
  lang: WEB.PageLanguage,
  key = 'modal'
): WEB.LanguageContentModal => {
  const modal = (lang?.[key] || {}) as WEB.LanguageContentModal;
  return modal;
};
