import addBanner from 'actions/banner/addBanner';
import types from 'actions/types';
import AlbertClient from 'api/AlbertClient';
import { NotificationTypes } from 'constants/index';
import * as WEB from 'types/interfaces';
import {
  onError,
  onErrorDisplayDefaultBanner,
  onSuccess,
} from 'utils/axiosMiddlewareUtils';
import getErrorMessageFromResponse from 'utils/getErrorMessageFromResponse';

export default (includeBanner?: boolean): WEB.ActionCreator<void> =>
  async (dispatch: WEB.Dispatch): Promise<void> => {
    const response: WEB.Action = await dispatch({
      types: [
        types.TEXT_CONFIRM_CODE_REQUEST,
        types.TEXT_CONFIRM_CODE_SUCCESS,
        types.TEXT_CONFIRM_CODE_FAILURE,
      ],
      payload: {
        request: {
          method: 'get',
          url: AlbertClient.textConfirmCode(),
        },
        options: {
          onSuccess: (middlewareOptions: any, options: any) => {
            // includeBanner is true when user requests another confirm code.We send request for confirm code
            // on component mount by default, do not display error message in this case.
            if (includeBanner) {
              const bannerBody = 'We sent a new code to your phone number.';
              dispatch(addBanner(NotificationTypes.SUCCESS, bannerBody, true));
            }
            return onSuccess(middlewareOptions, options);
          },
          onError: (middlewareOptions: any, options: any) => {
            const errorMessage = getErrorMessageFromResponse(
              middlewareOptions?.error?.response
            );
            if (includeBanner && errorMessage) {
              dispatch(
                addBanner(NotificationTypes.WARNING, errorMessage, true)
              );
              return onError(middlewareOptions, options);
            }
            return onErrorDisplayDefaultBanner(middlewareOptions, options);
          },
        },
      },
    });
  };
