import cookie from 'cookie';

const FBCLID_TIMESTAMP = 'FBCLID_TIMESTAMP';

const getCookieValue = (cookieName: string) => {
  const cookies = cookie.parse(document.cookie);
  return cookies[cookieName] || undefined;
};

// To store the timestamp when we first observed or received a given fbclid
// we wil use a Map object consisting of (fbclid, timestamp) key-value pairs.
// This is because a Map is safe to use with user-provided keys and values.
export const getInfoFromLocalStorage = () => {
  const storedFbclidInfo = localStorage.getItem(FBCLID_TIMESTAMP);
  const fbclidInfoMap = storedFbclidInfo
    ? new Map<string, number>(JSON.parse(storedFbclidInfo))
    : null;
  return fbclidInfoMap;
};

export const setInfoToLocalStorage = (fbclid: string, timestamp: number) => {
  const fbclidInfoMap = getInfoFromLocalStorage() || new Map<string, number>();

  if (fbclidInfoMap.has(fbclid)) {
    // Local storage already contains a timestamp for this fbclid
    return;
  }

  // add the new entry to the map
  fbclidInfoMap.set(fbclid, timestamp);

  // save the map to the local storage
  localStorage.setItem(FBCLID_TIMESTAMP, JSON.stringify([...fbclidInfoMap]));
};

// Get the timestamp for a given fbclid.
// If there is a timestamp for this fbclid in the local storage, return it.
// Else, use the current timestamp
export const getFbclidTimestamp = (fbclid: string) => {
  const fbclidInfoMap = getInfoFromLocalStorage();
  const currentTimestamp = new Date().getTime();

  if (fbclidInfoMap && fbclidInfoMap.has(fbclid)) {
    return fbclidInfoMap.get(fbclid) || currentTimestamp;
  }
  return currentTimestamp;
};

export const getFacebookCookieValues = () => {
  const fbp = getCookieValue('_fbp');
  let fbc = getCookieValue('_fbc');

  if (fbc) {
    return { fbp, fbc };
  }

  // _fbc cookie not found. Check if fbclid query parameter is in the URL of current page request
  const params = new URLSearchParams(window.location.search);
  const fbclid = params.get('fbclid') || null;

  if (!fbclid) {
    return { fbp, fbc };
  }

  // fbclid query parameter is included in the URL of the current page request.
  // We need to use it to generate the fbc value.
  // The fbc event parameter value must be of the form version.subdomainIndex.creationTime.fbclid, where:

  // version is always this prefix: fb
  const version = 'fb';

  // subdomainIndex is which domain the cookie is defined on ('com' = 0, 'facebook.com' = 1, 'www.facebook.com' = 2).
  // If you’re generating this field on a server, and not saving an _fbc cookie, use the value 1.
  const subdomainIndex = 1;

  // creationTime is the UNIX time since epoch in milliseconds when the _fbc cookie was saved.
  // If you don't save the _fbc cookie, use the timestamp when you first observed or received this fbclid value.
  const creationTime = getFbclidTimestamp(fbclid);

  // fbclid is the value for the fbclid query parameter in the page URL.
  fbc = `${version}.${subdomainIndex}.${creationTime}.${fbclid}`;

  // store the timestamp of this fbclid in the local storage, if it isn't already there
  setInfoToLocalStorage(fbclid, creationTime);

  return { fbp, fbc };
};
