import * as React from 'react';
import { useSelector } from 'react-redux';
import Button from 'components/common/Button';
import TopInfoButton from 'components/common/Button/TopInfoButton';
import List from 'components/common/List';
import MobileInfoModal from 'components/common/Modal/MobileInfoModal';
import Spacer from 'components/common/Spacer';
import Text, { TextColor, TextSizes } from 'components/common/Text';
import ButtonsLayout from 'components/layout/ButtonsLayout';
import LeadContent from 'components/layout/LeadContent';
import PageBase from 'components/layout/PageBase';
import Pages from 'constants/Pages';
import SplashLink from 'constants/SplashLink';
import useBrowserNavigationOverride from 'hooks/useBrowserNavigationOverride';
import useVerifyCurrentPage from 'hooks/useVerifyCurrentPage';
import SavingsBenefitsInfo from 'pages/signup/SavingsBenefitsInfo';
import * as language from 'reducers/entities/language';
import { spacers } from 'styles/home';
import * as WEB from 'types/interfaces';
import {
  getLanguageButtons,
  getLanguageInfo,
  getLanguageSection,
} from 'utils/getFromLanguage';

type Props = {
  nextPage: string;
};

enum ViewState {
  INTRO = 'intro',
  INFO = 'info',
}

const SavingsBenefits = (props: Props) => {
  const [showInfoModal, setShowInfoModal] = React.useState<boolean>(false);
  // Make sure Redux is up-to-date with currentSignUpPage
  useVerifyCurrentPage(Pages.SAVINGS_BENEFITS);
  const [currentView, setCurrentView] = React.useState<ViewState>(
    ViewState.INTRO
  );

  const languageCtx = useSelector((state: WEB.RootState) =>
    language.getSignupPageLanguage(state, Pages.SAVINGS_BENEFITS)
  );

  const infoCtx = getLanguageInfo(languageCtx);
  const savingsSection = getLanguageSection(languageCtx, 'saveMoneyWithAlbert');
  const buttonsCtx = getLanguageButtons(languageCtx);
  const expanders = infoCtx?.expanders || [];

  const disclosureLinkCallback = React.useCallback((url: string) => {
    window.open(url, '_blank');
  }, []);

  const handleGoBack = React.useCallback(() => {
    if (currentView === ViewState.INFO) {
      setCurrentView(ViewState.INTRO);
      // Block browser backwards navigation
      return true;
    }
    // Allow browser backwards navigation
    return false;
  }, [currentView]);

  const handleInfoOnClick = () => {
    setShowInfoModal(true);
  };
  useBrowserNavigationOverride(handleGoBack, true);
  const onSubmit = async () => {
    // Remove any error banner from previous attempts
    setCurrentView(ViewState.INFO);
  };

  return (
    <PageBase>
      <TopInfoButton onClick={handleInfoOnClick} />
      {currentView === ViewState.INFO ? (
        <SavingsBenefitsInfo nextPage={props.nextPage} agreedToCoastalTerms />
      ) : (
        <>
          <LeadContent
            header={savingsSection?.header || ''}
            text={savingsSection?.text || ''}
          />
          <List
            items={savingsSection?.list || []}
            type={WEB.ListType.List}
            itemSpacing={spacers.g7}
            textColor={TextColor.GRAY_DARK}
            textSize={TextSizes.MEDIUM}
          />
          <Spacer space={spacers.g14} />
          <Text color={TextColor.GRAY} size={TextSizes.TINY}>
            Albert Savings is provided by Coastal Community Bank, Member FDIC.
            By clicking “Continue” I agree to the{' '}
            <Text
              nowrap
              pointer
              inline
              weight='700'
              color={TextColor.GRAY}
              size={TextSizes.TINY}
              underline
              onClick={() => disclosureLinkCallback(SplashLink.COASTAL_PRIVACY)}
              isLinkButton
            >
              privacy policy
            </Text>
            .
          </Text>
          <ButtonsLayout
            marginTop={spacers.g5}
            primaryButton={
              <Button onClick={onSubmit}>{buttonsCtx?.primary}</Button>
            }
          />
        </>
      )}
      {expanders && (
        <MobileInfoModal
          expanders={expanders}
          show={showInfoModal}
          onCancel={() => setShowInfoModal(false)}
        />
      )}
    </PageBase>
  );
};

export default SavingsBenefits;
