import { isMobile } from 'react-device-detect';

const unlockZoom = () => {
  const metaViewport = document.querySelector('meta[name="viewport"]');
  const content = metaViewport?.getAttribute('content');
  if (metaViewport && content) {
    const contentList = content.split(', ');
    const updatedContentList = contentList.filter(
      (item) => item !== 'maximum-scale=1'
    );
    metaViewport.setAttribute('content', updatedContentList.join(', '));
  }
};

/**
 * Workaround for browser behavior of zooming in when certain inputs are focused.
 * This behavior cannot be reconfigured, so this is a workaround to temporarily
 * disable all zooming, then quickly re-enable it in order to not disrupt accessibility.
 */
const preventAutoZoom = () => {
  if (!isMobile) {
    return;
  }
  const metaViewport = document.querySelector('meta[name="viewport"]');
  const content = metaViewport?.getAttribute('content');
  if (metaViewport && content) {
    const updatedContent = content + ', maximum-scale=1';
    metaViewport.setAttribute('content', updatedContent);
    setTimeout(unlockZoom, 1000);
  }
};

export default preventAutoZoom;
