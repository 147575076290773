import { NavigateFunction } from 'react-router-dom';
import textDownloadApp from 'actions/async/textDownloadApp';
import types from 'actions/types';
import updateCurrentGeniusPage from 'actions/updateCurrentGeniusPage';
import updateCurrentKYCPage from 'actions/updateCurrentKYCPage';
import PageRoutes from 'constants/PageRoutes';
import Pages, { GeniusPages, KYCPages } from 'constants/Pages';
import * as language from 'reducers/entities/language';
import * as WEB from 'types/interfaces';

type Kwargs = {
  /* Page to navigate to. */
  page: string;
  /* Toggle whether or not to change the page in view. Default true. */
  shouldNavTo?: boolean;
  /* Whether to replace instead of push */
  shouldReplace?: boolean;
  /* Location state to pass with navigation. */
  locationState?: Record<string, any>;
};

/**
 * Creates an action creator to update current signup page state in redux
 * and to navigate the user to the next signup page.
 *
 * @param navigate - Navigate hook.
 * @return {Function} Function that accepts paramsObject (object) - kwargs.
 */
const updateCurrentSignupPage =
  (navigate: NavigateFunction) =>
  ({
    page,
    shouldNavTo = true,
    shouldReplace = false,
    locationState = {},
  }: Kwargs): WEB.ActionCreator<void> => {
    return async (dispatch: WEB.Dispatch, getState: WEB.GetStateFunc) => {
      const queryParams = new URLSearchParams(window.location.search);
      if (queryParams.get('flow_type') === 'short') {
        navigate({
          pathname: `/signup/download/`,
          search: window.location.search,
        });
        return;
      }
      const updateCurrentSignupPageAction: WEB.Action = {
        type: types.UPDATE_CURRENT_SIGNUP_PAGE,
        payload: { page },
      };

      // If Genius flow completed and we're proceeding the user to the download app page
      if (page === Pages.DOWNLOAD_APP) {
        // Send download app text.
        dispatch(textDownloadApp(true));

        // Reset genius page
        updateCurrentSignupPageAction.payload.page = '';
        dispatch(updateCurrentSignupPageAction);

        // Navigate to page
        navigate(PageRoutes.DOWNLOAD_APP);
        return;
      }

      // KYC Verify Identity
      if (page === Pages.KYC_VERIFY_IDENTITY) {
        const state = getState();
        const showPersonaFlow = Boolean(
          state.entities.language.signup.flags?.showPersonaFlow
        );
        if (showPersonaFlow) {
          // If `showPersonaFlow` is true, route to KYC_CONFIRM page at the beginning of the web persona flow
          dispatch(
            updateCurrentKYCPage(navigate)({
              page: KYCPages.KYC_CONFIRM,
              fromSignup: true,
            })
          );
        } else {
          // If `showPersonaFlow` is false, route to KYC_VERIFY_IDENTITY page which instructs user to download the app
          updateCurrentSignupPageAction.payload.page = '';
          dispatch(updateCurrentSignupPageAction);
          navigate(PageRoutes.KYC_VERIFY_IDENTITY, { replace: true });
        }
        return;
      }

      // KYC flow.
      if (page === Pages.KYC) {
        // Nav to first page of KYC flow - KYCAddress
        const state = getState();
        const kycBasePath = language.getKYCBasePath(state, true);
        dispatch(updateCurrentSignupPageAction);
        navigate(kycBasePath);
        return;
      }

      // If page is a Genius page, handle it in updateCurrentGeniusPage action
      if (Object.values(GeniusPages).includes(page as GeniusPages)) {
        // Wait until we've navigated to Genius page - otherwise, the "useVerifyCurrentPage" hook
        // will re-update current signup page
        await dispatch(
          updateCurrentGeniusPage(navigate)({ page, fromSignup: true })
        );

        // Reset current signup page
        updateCurrentSignupPageAction.payload.page = '';
        dispatch(updateCurrentSignupPageAction);
        return;
      }

      // Update redux state to set current signup page
      dispatch(updateCurrentSignupPageAction);

      // Return if we don't want to navigate to the update page
      if (shouldNavTo && page) {
        const state = getState();
        const route = language.getSignupRouteByPage(state, page as Pages);
        navigate(
          {
            pathname: route,
            search: window.location.search,
          },
          { state: locationState, replace: shouldReplace }
        );
      }
    };
  };

export default updateCurrentSignupPage;
