import * as Sentry from '@sentry/react';
import branch from 'branch-sdk';
import TrackingBase, { BranchDataParsed } from 'tracking.Base';
import createProfile, { Attribution } from 'actions/async/createProfile';
import getAppData from 'actions/async/getAppData';
import getSignupLanguage from 'actions/async/getSignupLanguage';
import login, { LoginFields } from 'actions/async/login';
import updateProfile from 'actions/async/updateProfile';
import addBanner from 'actions/banner/addBanner';
import types from 'actions/types';
import { ErrorMessages, NotificationTypes } from 'constants/index';
import * as language from 'reducers/entities/language';
import * as WEB from 'types/interfaces';
import { logger } from 'utils/logger';

interface CreateProfileInputFields {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
}

export default (
    createProfileInputFields: CreateProfileInputFields,
    attribution?: Attribution
  ): WEB.ActionCreator<void> =>
  async (
    dispatch: WEB.Dispatch,
    getState: WEB.GetStateFunc
  ): Promise<Record<string, string | boolean>> => {
    let firstPage = '';
    let error = false;
    /* 2021/06/21, MPR: @TODO, refactor to not use try/catch as
     * control flow. This is an antipattern because it does not
     * allow for distinguishing between true runtime errors and
     * synthetic control errors.
     */
    try {
      dispatch({ type: types.CREATE_PROFILE_AND_LOG_IN_REQUEST });

      // STEP 1: Create profile
      let state = getState();

      const profileError = await dispatch(
        createProfile(
          {
            ...createProfileInputFields,
          },
          attribution
        )
      );

      if (profileError) {
        logger.warn('Failed to create a new profile');
        throw Error();
      }

      // STEP 2: Login user
      const { email, password } = createProfileInputFields;
      const loginFields: LoginFields = { email, password };
      const loginError = await dispatch(
        login(loginFields, ErrorMessages.web.createProfile)
      );

      if (loginError) {
        logger.error('Failed to login with a new profile');
        throw Error();
      }

      // If user signs up from Branch link that promotes direct deposit bonus, we
      // want to ensure that user is migrated over to bonus signup flow.
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      await new Promise<void>((resolve, reject): void => {
        try {
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          branch.data(async (_, data): Promise<void> => {
            try {
              const dataParsed = (data?.data_parsed || {}) as BranchDataParsed;
              const bonusProgram = dataParsed?.bonus_program;

              // Update profile to put user on bonus signup flow.
              // NOTE: Important that this step happens before the language fetch request below.
              if (bonusProgram) {
                const updateProfileRequest: any = await dispatch(
                  updateProfile({ bonus_program: bonusProgram })
                );

                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                if (updateProfileRequest?.error) {
                  reject(new Error(updateProfileRequest.error));
                }
              }
              resolve();
            } catch (e) {
              reject(e);
            }
          });
        } catch (e) {
          reject(e);
        }
      }).catch((e) => {
        Sentry.setContext('Error Details', {
          message: e?.message,
        });
        logger.error(
          'Failed to update to direct deposit signup flow for new profile coming from Branch link'
        );
        dispatch(
          addBanner(NotificationTypes.WARNING, ErrorMessages.web.generic, false)
        );
        throw Error();
      });

      // STEP 3: Get sign up language and app data
      const [signupLanguageResponse, appDataResponse] = await Promise.all([
        dispatch(getSignupLanguage()),
        dispatch(getAppData()),
      ]);

      if (signupLanguageResponse.error || appDataResponse.error) {
        throw Error();
      }

      // Skip app initialization, since app data and profile are already in state
      dispatch({ type: types.INITIALIZE_APP_SUCCESS });

      // Get the first subflow to start the sign up flow
      state = getState();
      const languageCtx = language.getSignupLanguage(state);
      firstPage = languageCtx.order?.[0]?.pages?.[0] || '';

      dispatch({ type: types.CREATE_PROFILE_AND_LOG_IN_SUCCESS });
      TrackingBase.identify();
    } catch (e) {
      error = true;
      // TODO: add some sort of Sentry-like logger
      dispatch({
        type: types.CREATE_PROFILE_AND_LOG_IN_FAILURE,
      });
    }
    return {
      error,
      firstPage,
    };
  };
