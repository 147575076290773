import { ChevronLeft } from '@styled-icons/fa-solid/ChevronLeft';
import { ChevronRight } from '@styled-icons/fa-solid/ChevronRight';
import ClassNames from 'classnames';
import { isNumber } from 'lodash';
import * as React from 'react';
import styled from 'styled-components';
import ProgressDots from 'components/common/ProgressDots';
import { breakpoints, colors, mixins } from 'styles';
import { sizes } from 'styles/breakpoints';

export type Props = {
  /* unique identifier */
  id?: string;
  /* pause carousel */
  pause?: boolean;
  /* delay in milliseconds */
  delay?: number;
  /* any slide/child components */
  children?: React.ReactChild | React.ReactChild[];
};

const StyledCarouselContainer = styled.div`
  position: relative;
  padding: ${mixins.pxToRem('15px')} ${mixins.pxToRem('30px')};
  height: 550px;
  width: 100%;
  border: 1.5px solid ${colors.lightGray1};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @media ${breakpoints.mobileLarge} {
    height: 450px;
  }
  @media (min-width: ${sizes.desktopLarge}) {
    height: 600px;
  }
`;

const TRANSITION_SPEED = 0.3;

const StyledSliderChildContent = styled.div`
  position: absolute;
  width: 89%;
  top: 30px;
  padding: 0 ${mixins.pxToRem('50px')};
  height: 500px;
  overflow: hidden;
  opacity: 0;
  z-index: 1;

  &.active {
    opacity: 1;
    z-index: 2;
  }
  &.out {
    opacity: 0;
    transition: opacity ${TRANSITION_SPEED}s ease-in-out;
  }
  &.in {
    opacity: 1;
    transition: opacity ${TRANSITION_SPEED}s ease-in-out;
  }

  @media ${breakpoints.mobileLarge} {
    padding: 0 ${mixins.pxToRem('10px')};
    height: 450px;
  }
  @media ${breakpoints.desktopSmall} {
    height: 400px;
  }
  @media (min-width: ${sizes.desktopLarge}) {
    height: 550px;
  }
`;

const StyledSliderDot = styled.div<{ isActive?: boolean }>`
  background-color: ${({ theme, isActive }) =>
    isActive ? theme.colors.primaryText : theme.colors.lightGray1};
  ${mixins.fadeIn()}
  width: 8px;
  height: 8px;
  border-radius: 50%;
  cursor: pointer;
`;

const StyledSliderCircle = styled.div<{ isHovering?: boolean }>`
  ${({ isHovering }) => !isHovering && 'visibility: hidden;'}
  border: 1.5px solid ${({ theme }) => theme.colors.lightGray1};
  ${mixins.fadeIn()};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  cursor: pointer;
  z-index: 2;

  @media ${breakpoints.mobileLarge} {
    visibility: visible;
  }
`;

const StyledSliderBottomContainer = styled.div`
  position: absolute;
  bottom: 20px;
  min-height: ${mixins.pxToRem('30px')};
  width: 91%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledSliderDotsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  *:not(:last-child) {
    margin-right: ${mixins.pxToRem('11px')};
  }
`;

const StyledChevronLeftIcon = styled(ChevronLeft)`
  width: ${mixins.pxToRem('8px')};
  color: ${({ theme }) => theme.colors.lightGray1};
`;

const StyledChevronRightIcon = styled(ChevronRight)`
  width: ${mixins.pxToRem('8px')};
  color: ${({ theme }) => theme.colors.lightGray1};
`;

const Carousel = (props: Props): React.ReactElement => {
  const { id, delay, children, pause } = props;
  const numSlides = React.Children.count(children);
  const [slide, setSlideInit] = React.useState(0);
  const [isHovering, setIsHovering] = React.useState(false);
  const [isPaused, setIsPaused] = React.useState(pause);
  const [isTransitioning, setIsTransitioning] = React.useState(false);
  const [nextSlide, setNextSlide] = React.useState(1);

  const setSlide = (next: number | ((prev: number) => number)) => {
    if (!isTransitioning) {
      if (isNumber(next)) {
        setNextSlide(next);
      }
      setIsTransitioning(true);
      window.setTimeout(() => {
        setIsTransitioning(false);
        setSlideInit(next);
      }, TRANSITION_SPEED * 1000);
    }
  };

  const handleHovering = () => setIsHovering(true);
  const handleBlur = () => setIsHovering(false);
  const handleChangeSlide = (idx: number) => () => setSlide(idx);

  const handleOnNext = () => {
    setNextSlide(slide + 1 < numSlides ? slide + 1 : 0);
    setSlide((prev: number) => {
      return prev + 1 < numSlides ? prev + 1 : 0;
    });
    setIsPaused(true);
  };

  const handleOnPrev = () => {
    setNextSlide(slide > 0 ? slide - 1 : numSlides - 1);
    setSlide((prev: number) => {
      return prev > 0 ? prev - 1 : numSlides - 1;
    });
    setIsPaused(true);
  };

  React.useEffect(() => {
    const sliding = setTimeout(() => {
      slide + 1 < numSlides ? setSlide(slide + 1) : setSlide(0);
    }, delay || 5000);

    // Clear timeout whenever user is hovering or if "props.pause" is true
    if (isHovering || isPaused) {
      clearTimeout(sliding);
    }

    return () => clearTimeout(sliding);
    // NOTE: important to listen for "numSlides" because when passing in an
    // array of components, "numSlides" will register 0 at first and then the
    // correct number of components in the array that is passed in as children
  }, [slide, isHovering, numSlides, isPaused]);

  React.useEffect(() => {
    setIsPaused(pause);
  }, [pause]);

  const slides = React.Children.map(children, (child, i) => {
    return (
      <StyledSliderChildContent
        className={ClassNames({
          active: i === slide,
          out: isTransitioning && i === slide,
          in: isTransitioning && i === nextSlide,
          inactive: i !== slide && i !== nextSlide,
        })}
      >
        {child}
      </StyledSliderChildContent>
    );
  });

  return (
    <StyledCarouselContainer
      id={id}
      onMouseEnter={handleHovering}
      onMouseLeave={handleBlur}
    >
      {slides}
      <StyledSliderBottomContainer>
        <StyledSliderCircle
          className='carousel-circle-prev'
          isHovering={isHovering}
          onClick={handleOnPrev}
        >
          <StyledChevronLeftIcon />
        </StyledSliderCircle>
        <ProgressDots
          length={numSlides}
          onClick={handleChangeSlide}
          currentIndex={slide}
        />
        <StyledSliderCircle
          className='carousel-circle-next'
          isHovering={isHovering}
          onClick={handleOnNext}
        >
          <StyledChevronRightIcon />
        </StyledSliderCircle>
      </StyledSliderBottomContainer>
    </StyledCarouselContainer>
  );
};

export default Carousel;
