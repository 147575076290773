import AlbertTrackingEvent from './AlbertTrackingEvent';

/* 2021/04/12, MPR: We may want to revisit some of these event strings. Firebase
 * reccomends using the gtag event names, as specified in
 * https://developers.google.com/gtagjs/reference/event, an some of ours
 * seem to be based off branch event names (e.g. ecommerce_purchase would just
 * be purchase in gtag). I do not know if there is value in using these
 * reccomendations
 */
// One-to-one translation from FIREvent_ constants in the iOS repo
// goodspend/TrackingEvent.swift
const FirebaseEvent = {
  [AlbertTrackingEvent.SIGNED_UP]: 'sign_up',
  [AlbertTrackingEvent.ADDED_ACCOUNT]: 'unlock_achievement',
  [AlbertTrackingEvent.ENABLED_SAVINGS]: 'level_start',
  [AlbertTrackingEvent.ENABLED_GENIUS]: 'ecommerce_purchase',
  [AlbertTrackingEvent.ENABLED_GENIUS_ANNUAL]: 'enabled_genius_annual',
  [AlbertTrackingEvent.ENABLED_GENIUS_PRIMARY_CHECKING_GREATER_THAN_100]:
    'enabled_genius__bank_gt_100',
  [AlbertTrackingEvent.COMPLETED_INSTANT_ONBOARDING]:
    'completed_instant_onboarding',
  [AlbertTrackingEvent.COMPLETED_INVESTMENT_APPLICATION]:
    'completed_investing_onboarding',
};

export default FirebaseEvent;
