import cookie from 'cookie';

export enum RolloutFeatures {}

export const BUCKET_KEY = 'bckt';

/**
 * @todo 08/22 This function seems buggy; we've seen it return false for features toggled at 100%
 */
export const CheckCanUserSeeFeature = (feature: RolloutFeatures) => {
  const percentEnabled = window.albertWeb.PercentageFeatures[feature];
  const parsedCookie = cookie.parse(document.cookie);
  const cookieBucket = parsedCookie[BUCKET_KEY];

  if (
    percentEnabled == null ||
    Number.isNaN(+percentEnabled) ||
    cookieBucket == null ||
    Number.isNaN(+cookieBucket)
  ) {
    // off
    return false;
  }
  /* 2022/3/17, MPR: Granularity works as such: there are 100 buckets, based on a modulus on the
   * user's PK, thus 0-99. if the feature is off (flag = 0), it will match no users. if it is set
   * to 5%, (flag = 5), then buckets 0-4 will be included.
   */
  return +cookieBucket < +percentEnabled;
};
