import { GearWideConnected } from '@styled-icons/bootstrap/GearWideConnected';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import TrackingBase from 'tracking.Base';
import logout from 'actions/async/logout';
import { KYCPageList } from 'components/kyc/KYCFlow';
import { SIGNUP_PAGES_TO_EXCLUDE } from 'components/signup/SignupFlow';
import PageRoutes from 'constants/PageRoutes';
import Pages, { GeniusPages, SignupPages } from 'constants/Pages';
import useNavigateFunction from 'hooks/useNavigateFunction';
import * as appData from 'reducers/entities/appData';
import * as profile from 'reducers/entities/profile';
import * as app from 'reducers/ui/app';
import * as genius from 'reducers/ui/genius';
import * as kyc from 'reducers/ui/kyc';
import * as signup from 'reducers/ui/signup';
import mixins from 'styles/mixins';
import * as WEB from 'types/interfaces';
import {
  getIsCheckpointDisabled,
  setCheckpointDisabled,
} from 'utils/checkpointNavUtils';

const StyledWidget = styled.div<{ show?: boolean }>`
  position: fixed;
  display: flex;
  bottom: 10px;
  right: 10px;
  color: ${({ theme }) => theme.colors.primaryBackground};
  border: thin solid ${({ theme }) => theme.colors.primaryText};
  background-color: ${({ theme }) => theme.colors.primaryText};
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  z-index: 99999;
  ${({ show }) => !show && 'cursor: pointer;'};
`;

const Content = styled.div<{ show?: boolean }>`
  padding: ${mixins.pxToRem('10px')};
  ${({ theme, show }) =>
    show &&
    `
      border-right: thin solid ${theme.colors.primaryBackground};
      padding: ${mixins.pxToRem('20px')};
    `};
  select {
    background-color: gray;
    outline: none;
  }
`;

const StyledGear = styled(GearWideConnected)`
  width: ${mixins.pxToRem('20px')};
`;

const StyledCloseButton = styled.div`
  align-self: center;
  justify-self: stretch;
  padding: ${mixins.pxToRem('40px')} ${mixins.pxToRem('10px')};
  cursor: pointer;
`;

const StyledButton = styled.button`
  cursor: pointer;
  color: black;
  background-color: gray;
  border: none;
  &:disabled {
    cursor: not-allowed;
  }
  margin-right: 10px;
  margin-bottom: 5px;
`;

const ProfileInfo = (): React.ReactElement => {
  const profilePK = useSelector(
    (state: WEB.RootState) => profile.getValueByField(state, 'id') as number
  );
  const bankAccount = useSelector((state: WEB.RootState) =>
    appData.getBankingAccount(state)
  );
  const savingsAccountId = useSelector((state: WEB.RootState) =>
    appData.getSavingsAccountId(state)
  );
  const personalInfo = useSelector(
    (state: WEB.RootState) =>
      profile.getValueByField(state, 'personal_info') as Record<string, any>
  );
  const currCheckpoint = useSelector((state: WEB.RootState) =>
    signup.getCheckpoint(state)
  );

  if (profilePK) {
    return (
      <div>
        <div>PK: {profilePK}</div>
        <div>Bank ID: {bankAccount?.id}</div>
        <div>Savings ID: {savingsAccountId}</div>
        <div>Checkpoint: {currCheckpoint}</div>
        <div>Web signup version key:</div>
        <div>{personalInfo?.web_signup_version_key}</div>
      </div>
    );
  }

  return <div>Log in to see profile data</div>;
};

const QAWidget = (): React.ReactElement => {
  const {
    updateCurrentSignupPage,
    updateCurrentGeniusPage,
    updateCurrentKYCPage,
  } = useNavigateFunction();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Get initial value from local storage
  const disableCheckpointInitValue = getIsCheckpointDisabled();

  // Mapped state
  const isLoggedIn = useSelector(app.isLoggedIn);
  const currentSignupPage = useSelector(signup.getCurrentPage);
  const currentKYCPage = useSelector(kyc.getCurrentPage);
  const currentGeniusPage = useSelector(genius.getCurrentPage);

  // Local state
  const [show, setShow] = React.useState(false);
  const [showProfile, setShowProfile] = React.useState(false);
  const [otherPage, setOtherPage] = React.useState('');
  const [disableCheckpoint, setDisableCheckpoint] = React.useState(
    disableCheckpointInitValue
  );

  React.useEffect(() => {
    // Reset if being redirected back to a checkpointed page
    if (currentSignupPage || currentKYCPage || currentGeniusPage) {
      otherPage && setOtherPage('');
    }
  }, [currentSignupPage, currentKYCPage, currentGeniusPage]);

  // Handlers
  const handleShowOnClick = (): void => {
    !show && setShow(true);
  };

  const handleHideOnClick = (): void => {
    setShow(false);
  };

  const handleDisableCheckpointRedirect = (): void => {
    // Set the value as a string in local storage
    setCheckpointDisabled(!disableCheckpoint);
    setDisableCheckpoint(!disableCheckpoint);
  };

  const handleUpdatePage = (
    event: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    const { value } = event.currentTarget;
    let locationState;
    switch (value) {
      case Pages.DOWNLOAD_APP:
        navigate({
          pathname: PageRoutes.DOWNLOAD_APP,
          search: window.location.search,
        });
        setOtherPage(Pages.DOWNLOAD_APP);
        break;

      case Pages.TWO_FA:
        locationState = { prompt2FA: true };
        navigate(
          {
            pathname: PageRoutes.TWO_FA,
            search: window.location.search,
          },
          {
            state: locationState,
          }
        );
        setOtherPage(Pages.TWO_FA);
        break;

      default:
        if (value) {
          if (Object.values(SignupPages).includes(value as SignupPages)) {
            dispatch(updateCurrentSignupPage({ page: value }));
          } else if (KYCPageList.includes(value)) {
            dispatch(updateCurrentKYCPage({ page: value, fromSignup: true }));
          } else if (
            Object.values(GeniusPages).includes(value as GeniusPages)
          ) {
            dispatch(
              updateCurrentGeniusPage({ page: value, fromSignup: true })
            );
          }
          otherPage && setOtherPage('');
        }
        break;
    }
  };

  const handleLogout = (): void => {
    setShowProfile(false);
    dispatch(logout());

    /* Side Effect */
    TrackingBase.resetIdentity();
  };

  /** When using the widget, if you switch to a different group of pages
   * the initial group of pages you were in won't be updated.
   *
   * Example:
   * If you switch from GeniusTour to CashSetup, the value of currentGeniusPage
   * won't be set to null.
   *
   * Using this set of useEffect hooks tracks when one
   * of the current[GROUP]Page variables changes, and updates
   * currentSelectedPage accordingly
   */
  const [currentSelectedPage, setCurrentSelectedPage] = React.useState<
    string | undefined
  >(undefined);
  React.useEffect(() => {
    setCurrentSelectedPage(currentSignupPage);
  }, [currentSignupPage]);
  React.useEffect(() => {
    setCurrentSelectedPage(currentKYCPage);
  }, [currentKYCPage]);
  React.useEffect(() => {
    setCurrentSelectedPage(currentGeniusPage);
  }, [currentGeniusPage]);
  React.useEffect(() => {
    setCurrentSelectedPage(otherPage);
  }, [otherPage]);

  const widget = (
    <>
      <div>
        <label htmlFor='pages'>Page:</label>
        <br />
        <select
          id='pages'
          name='pages'
          value={currentSelectedPage}
          onChange={handleUpdatePage}
        >
          <option value=''>-</option>
          <optgroup label='Signup'>
            {Object.values(SignupPages)
              .filter((page: string) => !SIGNUP_PAGES_TO_EXCLUDE.includes(page))
              .map((page: string) => (
                <option key={page} value={page}>
                  {page}
                </option>
              ))}
          </optgroup>
          <optgroup label='KYC'>
            {/* Opting to use KYCPageList instead of KYCPages because KYCPages
                includes a page called 'KYC' which doesn't render anything */}
            {Object.values(KYCPageList).map((page: string) => (
              <option key={page} value={page}>
                {page}
              </option>
            ))}
          </optgroup>
          <optgroup label='Genius'>
            {Object.values(GeniusPages).map((page: string) => (
              <option key={page} value={page}>
                {page}
              </option>
            ))}
          </optgroup>
          <optgroup label='Other'>
            <option key={Pages.TWO_FA} value={Pages.TWO_FA}>
              {Pages.TWO_FA}
            </option>
            <option key={Pages.DOWNLOAD_APP} value={Pages.DOWNLOAD_APP}>
              {Pages.DOWNLOAD_APP}
            </option>
          </optgroup>
        </select>
      </div>
      <br />
      <div>
        <input
          id='checkbox'
          type='checkbox'
          defaultChecked={disableCheckpoint}
          onClick={handleDisableCheckpointRedirect}
        />
        <label htmlFor='checkbox'>&nbsp;Disable redirect</label>
      </div>
      <br />
      <StyledButton disabled={!isLoggedIn} onClick={handleLogout}>
        Logout
      </StyledButton>
      <StyledButton onClick={() => setShowProfile(!showProfile)}>
        {showProfile ? 'Hide profile' : 'Show profile'}
      </StyledButton>
      {showProfile ? <ProfileInfo /> : null}
    </>
  );

  return (
    <StyledWidget show={show}>
      <Content show={show} onClick={handleShowOnClick}>
        {show ? widget : <StyledGear />}
      </Content>
      {show && (
        <StyledCloseButton onClick={handleHideOnClick}>x</StyledCloseButton>
      )}
    </StyledWidget>
  );
};

export default QAWidget;
