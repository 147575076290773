import * as React from 'react';
import { useSelector } from 'react-redux';
import InfoPane from 'components/layout/InfoPane';
import { SignupPages } from 'constants/Pages';
import SignupGoals from 'constants/SignupGoals';
import * as appData from 'reducers/entities/appData';
import * as language from 'reducers/entities/language';
import * as signup from 'reducers/ui/signup';
import * as WEB from 'types/interfaces';
import {
  getLanguageAltInfo,
  getLanguageInfo,
  getLanguageSection,
} from 'utils/getFromLanguage';

const SignupInfoPane = (): React.ReactElement => {
  // Mapped state
  const currentPage = useSelector(signup.getCurrentPage);
  const pageLanguage = useSelector((state: WEB.RootState) =>
    language.getSignupPageLanguage(state, currentPage)
  );
  const hasInstantGoal = useSelector((state: WEB.RootState) =>
    appData.hasSetupGoal(state, SignupGoals.CASH_ADVANCE)
  );
  const instantCtx = getLanguageSection(pageLanguage, 'instant');
  const showInstantContent = hasInstantGoal && !!Object.keys(instantCtx).length;

  let infoPaneContext = getLanguageInfo(pageLanguage);
  let canShowDebitCard = false;

  if (currentPage === SignupPages.CASH_VALUE_PROP && showInstantContent) {
    // Show alternate info pane context if user has an interest in Instant
    infoPaneContext = getLanguageAltInfo(pageLanguage);
  } else if (currentPage === SignupPages.SELECT_CARD && hasInstantGoal) {
    infoPaneContext = {
      ...infoPaneContext,
      ...(getLanguageAltInfo(pageLanguage) || {}),
    };
  } else if (currentPage === SignupPages.CASH_SELECT_SHIP_DEBIT_CARD) {
    canShowDebitCard = true;
  }

  return (
    <InfoPane.Desktop
      currentPage={currentPage}
      canShowDebitCard={canShowDebitCard}
      {...infoPaneContext}
    />
  );
};

export default SignupInfoPane;
