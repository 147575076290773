import { noop } from 'lodash';
import * as React from 'react';
import styled from 'styled-components';
import { fontSizes, mixins } from 'styles';
import { handleActivateKeyDown } from 'utils/accessibility';
import { getIconSrc } from 'utils/merchant';

type Props = {
  imageName: string;
  logo: string | null;
  institutionId: string;
  institutionName: string;
  institutionUrl: string | null;
  institutionHasAuth: boolean;
  selected?: boolean;
  handleOnClick?: (institutionId: string, institutionHasAuth?: boolean) => void;
};

const StyledImage = styled.img`
  width: ${mixins.pxToRem('32px')};
  height: ${mixins.pxToRem('32px')};
`;

const StyledCard = styled.div<{ selected?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 1.25rem;
  align-items: center;
  border: 1.5px solid #e6e7eb;
  box-sizing: border-box;
  border-radius: 4px;
  height: 100%;
  width: 100%;
  position: relative;
  transition: 0.2s;
  cursor: pointer;
  ${({ selected, theme }) =>
    selected &&
    `
      box-shadow: 0 0 0 1px ${theme.colors.primaryAlbertBrand};
      border-color: ${theme.colors.primaryAlbertBrand};
  `}
`;

const StyledCardHeader = styled.p`
  ${fontSizes.fontSize12}
  line-height: ${mixins.pxToRem('15px')};
  color: ${({ theme }) => theme.colors.primaryText};
  width: 90%;
  white-space: pre-wrap;
  text-align: center;
  /* ellipsify card header when it overflows past 2 lines */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const InstitutionCard = (props: Props): React.ReactElement => {
  const {
    institutionId,
    institutionName,
    institutionUrl,
    institutionHasAuth,
    logo,
    imageName,
    handleOnClick = noop,
    selected = false,
  } = props;

  const handleCardOnClick = () => {
    handleOnClick && handleOnClick(institutionId, institutionHasAuth);
  };

  const tooltip = `
  <p><b>Name</b>: ${institutionName}</p>
  ${institutionUrl ? `<p><b>URL</b>: ${institutionUrl}</p>` : ''}
  `;

  // Use ins icon image stored in BFF if imageName exists, else default to logo if exists.
  const imgSrc = getIconSrc({
    imageName,
    logo,
  });

  return (
    <StyledCard
      data-tip={tooltip}
      data-for='institution-tooltip'
      selected={selected}
      onClick={handleCardOnClick}
      tabIndex={0}
      onKeyDown={(e) => handleActivateKeyDown(e, handleCardOnClick)}
    >
      <StyledImage src={imgSrc} />
      <StyledCardHeader>{institutionName}</StyledCardHeader>
    </StyledCard>
  );
};

export default InstitutionCard;
