import * as React from 'react';
import styled from 'styled-components';
import DebitCard, { CornerShadow } from 'components/banking/DebitCard';
import { DebitCardStyle } from 'constants/index';

type Props = {
  /* user's first name */
  firstName: string;
  /* user's last name */
  lastName: string;
  /* last four digits of the credit card number */
  lastFour?: number;
};

const PAUSE_CARD_SECONDS = 1.95;
const SPIN_SECONDS = 0.4;
const ANIMATION_DELAY_SECONDS = 1;

const Container = styled.div`
  position: relative;
`;

const StaticCard = styled.div<{ hide: boolean }>`
  display: ${({ hide }) => (hide ? 'none' : 'initial')};
  position: absolute;
`;

export const SpinCard = styled.div`
  position: absolute;

  @keyframes spinY {
    0% {
      transform: rotateY(0);
      opacity: 1;
    }
    50% {
      opacity: 0.3;
    }
    100% {
      transform: rotateY(-360deg);
      opacity: 1;
    }
  }
  animation-name: spinY;
  animation-duration: ${SPIN_SECONDS}s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
  animation-delay: ${ANIMATION_DELAY_SECONDS}s;
`;

const HiddenDiv = styled.div`
  visibility: hidden;
`;

const cardOrder = [
  DebitCardStyle.BLUE,
  DebitCardStyle.GENIUS,
  DebitCardStyle.WHITE,
];

const SpinningDebitCard = (props: Props) => {
  const [style, setStyle] = React.useState(0);
  const [shouldSpinOut, setShouldSpinOut] = React.useState(false);
  const [showStaticCard, setShowStaticCard] = React.useState(true);

  React.useEffect(() => {
    /*
      0s - show static card, hide spinning card
      2s - show spin card with 1s animation-delay
      3s - hide static card, start spinning the spin card
      3.75s - switch the card style
    */
    const interval = (PAUSE_CARD_SECONDS + SPIN_SECONDS) * 1000;

    const resetInterval = setInterval(() => {
      setShowStaticCard(true);
      setShouldSpinOut(false);
    }, interval);

    let spinInterval: any;
    const spinTimeout = setTimeout(() => {
      // Spin the card
      setShouldSpinOut(true);
      spinInterval = setInterval(() => {
        setShouldSpinOut(true);
      }, interval);
    }, (PAUSE_CARD_SECONDS - ANIMATION_DELAY_SECONDS) * 1000);

    let staticInterval: any;
    const staticTimeout = setTimeout(() => {
      // Hide the static card
      setShowStaticCard(false);
      staticInterval = setInterval(() => {
        setShowStaticCard(false);
      }, interval);
    }, PAUSE_CARD_SECONDS * 1000);

    let styleInterval: any;
    const styleTimeout = setTimeout(() => {
      // Increment to the next card style
      setStyle((prevValue) => (prevValue + 1) % cardOrder.length);
      styleInterval = setInterval(() => {
        setStyle((prevValue) => (prevValue + 1) % cardOrder.length);
      }, interval);
    }, (PAUSE_CARD_SECONDS + SPIN_SECONDS * 0.75) * 1000);

    // Clear intervals/timeouts on unmount
    return () => {
      clearInterval(resetInterval);
      clearInterval(spinInterval);
      clearInterval(staticInterval);
      clearInterval(styleInterval);
      clearTimeout(spinTimeout);
      clearTimeout(staticTimeout);
      clearTimeout(styleTimeout);
    };
  }, []);

  return (
    <Container>
      <CornerShadow />
      {shouldSpinOut && (
        <SpinCard>
          <DebitCard
            style={cardOrder[style]}
            firstName={props.firstName}
            lastName={props.lastName}
            lastFour={props.lastFour}
            tilted
          />
        </SpinCard>
      )}
      <StaticCard hide={!showStaticCard}>
        <DebitCard
          style={cardOrder[style]}
          firstName={props.firstName}
          lastName={props.lastName}
          lastFour={props.lastFour}
          tilted
        />
      </StaticCard>
      {/* A hidden, not position:absolute debit card to give the Container a height/width */}
      <HiddenDiv>
        <DebitCard
          style={DebitCardStyle.BLUE}
          firstName=''
          lastName=''
          tilted
        />
      </HiddenDiv>
    </Container>
  );
};

/**
 * Workaround for issue where animation breaks when browser is minimized
 * This container will unmount the spinning debit card when it's not visible,
 * ensuring everything is reset when the component is mounted again.
 */
export const SpinningDebitCardContainer = (props: Props) => {
  const [visibility, setVisibility] = React.useState<'visible' | 'hidden'>(
    document.visibilityState
  );
  React.useEffect(() => {
    const onVisibilityChange = () => setVisibility(document.visibilityState);
    window.addEventListener('visibilitychange', onVisibilityChange);
    return () => {
      window.removeEventListener('visibilitychange', onVisibilityChange);
    };
  }, []);

  if (visibility === 'hidden') {
    return null;
  }
  return <SpinningDebitCard {...props} />;
};

export default SpinningDebitCardContainer;
