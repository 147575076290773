import types from 'actions/types';
import AlbertClient from 'api/AlbertClient';
import * as WEB from 'types/interfaces';
import { onErrorDisplayDefaultBanner } from 'utils/axiosMiddlewareUtils';

/**
 * Complete a Banking application.
 */
export default (
    bankAccountId: number,
    shippingAddress: WEB.AddressPhysical
  ): WEB.ActionCreator<Promise<boolean>> =>
  async (dispatch: WEB.Dispatch): Promise<boolean> => {
    let error = false;

    await dispatch({
      types: [
        types.COMPLETE_BANKING_APPLICATION_REQUEST,
        types.COMPLETE_BANKING_APPLICATION_SUCCESS,
        types.COMPLETE_BANKING_APPLICATION_FAILURE,
      ],
      payload: {
        request: {
          method: 'post',
          url: AlbertClient.bankingCompleteApplication(bankAccountId),
          data: {
            shipping_address: shippingAddress,
            bank_account_id: bankAccountId,
          },
        },
        options: {
          onError: (middlewareOptions: any, options: any) => {
            error = true;
            return onErrorDisplayDefaultBanner(middlewareOptions, options);
          },
        },
      },
    });

    return error;
  };
