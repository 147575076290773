import * as React from 'react';

export function LockIcon() {
  return (
    <svg
      width='8'
      height='10'
      viewBox='0 0 8 10'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2.448 5.112C2.328 5.112 2.232 5.208 2.232 5.328V7.872C2.232 7.992 2.328 8.088 2.448 8.088H6.432C6.552 8.088 6.648 7.992 6.648 7.872V5.328C6.648 5.208 6.552 5.112 6.432 5.112H2.448ZM0.96 8.28V4.92C0.96 4.308 1.428 3.84 2.04 3.84H2.22V2.916C2.22 1.656 3.216 0.611999 4.44 0.611999C5.664 0.611999 6.648 1.644 6.648 2.916V3.84H6.84C7.452 3.84 7.92 4.308 7.92 4.92V8.28C7.92 8.892 7.452 9.36 6.84 9.36H2.04C1.428 9.36 0.96 8.892 0.96 8.28ZM3.492 3.84H5.376V2.916C5.376 2.352 4.956 1.884 4.44 1.884C3.912 1.884 3.492 2.352 3.492 2.916V3.84Z'
        fill='#111111'
      />
    </svg>
  );
}
