import types from 'actions/types';
import AlbertClient from 'api/AlbertClient';
import * as WEB from 'types/interfaces';

export default (): WEB.Action => ({
  types: [
    types.DEACTIVATE_PROFILE_REQUEST,
    types.DEACTIVATE_PROFILE_SUCCESS,
    types.DEACTIVATE_PROFILE_FAILURE,
  ],
  payload: {
    request: {
      method: 'delete',
      url: AlbertClient.profileView(
        new URLSearchParams({
          question: 'reason for profile deletion',
          answer: 'duplicate profile',
        })
      ),
    },
  },
});
