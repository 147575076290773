import { kebabCase } from 'lodash';
import { Art } from './Art/Art';

export { Art };

export const NORMALIZING_MAP: Record<string, number> = {
  logo: Art.Logo,
  'albert-logo': Art.Logo,
  'bulb-off': Art.BulbOff,
  'bulb-gray': Art.BulbOff,
  'bulb-on': Art.BulbOn,
  bulb: Art.BulbOn,
  lightbulb: Art.BulbOn,
  'light-bulb': Art.BulbOn,
  'calendar-off': Art.CalendarOff,
  'calendar-gray': Art.CalendarOff,
  'calendar-on': Art.CalendarOn,
  calendar: Art.CalendarOn,
  'cycle-off': Art.CycleOff,
  'cycle-gray': Art.CycleOff,
  'cycle-on': Art.CycleOn,
  cycle: Art.CycleOn,
  'two-fingers-off': Art.TwoFingersOff,
  'two-fingers-gray': Art.TwoFingersOff,
  'two-fingers-on': Art.TwoFingersOn,
  'two-fingers': Art.TwoFingersOn,
  'exclamation-triangle': Art.Warning,
  exclamation: Art.Warning,
  triangle: Art.Warning,
  'warning-triangle': Art.Warning,
  warning: Art.Warning,
  'exlamation-circle': Art.ExclamationCircle,
  exclamationcircle: Art.ExclamationCircle,
  'exclamation-circle-gray': Art.ExclamationCircleGray,
  'exclamation-circle-genius': Art.ExclamationCircleGenius,
  exclamationcirclegenius: Art.ExclamationCircleGenius,
  'exclamation-circle-orange': Art.ExclamationCircleOrange,
  'exclamation-circle-red': Art.ExclamationCircleRed,
  'annual-subscription': Art.AnnualSubscriptionGold,
  'annual-subscription-gold': Art.AnnualSubscriptionGold,
  'annual-subscription-gray': Art.AnnualSubscriptionSilver,
  'annual-subscription-silver': Art.AnnualSubscriptionSilver,
  'albert-pig': Art.Pig,
  pig: Art.Pig,
  'app-store': Art.AppleAppStoreBanner,
  'apple-app-store': Art.AppleAppStoreBanner,
  bank: Art.Bank,
  bonuses: Art.Bonuses,
  'check-numbers': Art.CheckNumbers,
  check: Art.CheckNumbers,
  'flag-sparkle': Art.FlagSparkle,
  sparkle: Art.FlagSparkle,
  'genius-bulb': Art.GeniusBulb,
  'genius-cards': Art.GeniusCards,
  'genius-campaign': Art.GeniusesGold,
  'genius-chess': Art.GeniusChess,
  'genius-dollar': Art.GeniusDollar,
  'genius-example-chat': Art.GeniusExampleChat,
  'genius-logo-no-text': Art.GeniusLogoNoText,
  'genius-logo': Art.GeniusLogo,
  'genius-logo-alt': Art.GeniusLogoAlt,
  'genius-mountains': Art.GeniusMountains,
  'genius-newspaper': Art.GeniusNewspaper,
  'genius-popper': Art.GeniusPopper,
  'genius-signature': Art.GeniusSignature,
  'geniuses-gold': Art.GeniusesGold,
  'geniuses-talking': Art.GeniusesTalking,
  'geniuses-talking-gold': Art.GeniusesTalkingGold,
  'in-pbg': Art.InvestmentGraph,
  'inv-graph': Art.InvestmentGraph,
  'investment-graph': Art.InvestmentGraph,
  'location-pin': Art.Navigation,
  location: Art.Navigation,
  navigation: Art.Navigation,
  'money-parachute': Art.MoneyParachute,
  'money-piggy-bank': Art.WomanPiggyBank,
  'woman-piggy-bank': Art.WomanPiggyBank,
  'piggy-bank': Art.WomanPiggyBank,
  'play-store': Art.AndroidPlayStoreBanner,
  'android-play-store': Art.AndroidPlayStoreBanner,
  'google-play-store': Art.AndroidPlayStoreBanner,
  'profile-check': Art.ProfileCheck,
  rocket: Art.Rocket,
  'smart-savings': Art.SmartSavingsBolt,
  'text-messages': Art.TextMessages,
  'opt-in': Art.OptIn,
  'opt-in-web': Art.OptIn,
  'text-opt-in': Art.OptIn,
  'text-opt-in-web': Art.OptIn,
  'thumbs-up': Art.ThumbsUp,
  'woman-plant': Art.WomanPlant,
  barchart: Art.BarChart,
  'bar-chart': Art.BarChart,
  'building-wealth-dollar-bill': Art.DollarBill,
  'wealth-dollar-bill': Art.DollarBill,
  'dollar-bill': Art.DollarBill,
  dollarbill: Art.DollarBill,
  dollar: Art.DollarBill,
  'building-wealth-plant': Art.Plant,
  'wealth-plant': Art.Plant,
  'inv-plant': Art.Plant,
  plant: Art.Plant,
  'building-wealth-shapes': Art.Shapes,
  'wealth-shapes': Art.Shapes,
  'inv-shapes': Art.Shapes,
  shapes: Art.Shapes,
  'building-wealth-vacation': Art.Vacation,
  'wealth-vacation': Art.Vacation,
  'inv-vacation': Art.Vacation,
  vacation: Art.Vacation,
  'building-wealth-cake': Art.Cake,
  'wealth-cake': Art.Cake,
  'inv-cake': Art.Cake,
  cake: Art.Cake,
  'life-event-home': Art.Home,
  home: Art.Home,
  'paper-plane': Art.PaperPlane,
  plane: Art.PaperPlane,
  privacy: Art.Privacy,
  scale: Art.Scale,
  scales: Art.Scale,
  shield: Art.Shield,
  'shield-lock': Art.ShieldLock,
  shieldlock: Art.ShieldLock,
  'inv-chart': Art.Stocks,
  'investment-chart': Art.Stocks,
  chart: Art.Stocks,
  stocks: Art.Stocks,
  'grad-cap': Art.Tuition,
  gradcap: Art.Tuition,
  tuition: Art.Tuition,
  vault: Art.Vault,
  times: Art.Times,
  'lock-check': Art.LockCheck,
  'shield-kyc': Art.ShieldKYC,
  'finger-print': Art.Fingerprint,
  lock: Art.Lock,
  'debit-card-black': Art.DebitCardGenius,
  'debit-card-white': Art.DebitCardWhite,
  'debit-card-blue': Art.DebitCardBlue,
  'secure-lock': Art.SecureLock,
  phone: Art.Phone,
  'modal-error': Art.ModalErr,
  'neutral-check': Art.NeutralCheck,
  'green-check': Art.GreenCheck,
  'secure-lock-background': Art.SecureLockBackground,
  'confirm-identity': Art.ConfirmIdentity,
  laptop: Art.Laptop,
  genius: Art.Genius,
  iphoneapp: Art.IPhoneApp,
  'linked-accounts': Art.LinkedAccounts,
  'one-time-transfer': Art.OneTimeTransfer,
  scheduled: Art.Scheduled,
  'warning-banner-icon': Art.WarningBannerIcon,
  'cash-transaction-transfer': Art.CashTransactionTransfer,
  'download-the-app-qr-code': Art.DownloadTheAppQRCode,
  cashback: Art.Cashback,
  confettiPhone: Art.ConfettiPhone,
  'loading-spinner': Art.LoadingSpinner,
  'email-circle': Art.SocialMediaCircleIconEmail,
  'facebook-circle': Art.SocialMediaCircleIconFacebook,
  'linkedin-circle': Art.SocialMediaCircleIconLinkedIn,
  'pinterest-circle': Art.SocialMediaCircleIconPinterest,
  'twitter-circle': Art.SocialMediaCircleIconTwitter,
  'link-circle': Art.SocialMediaCircleIconLink,
  'arrow-narrow-left': Art.ArrowNarrowLeft,
  'chevron-up': Art.ChevronUp,
  'chevron-down': Art.ChevronDown,
  'direct-deposit': Art.DirectDeposit,
  'success-circle': Art.SuccessCircle,
  'umbrella-rain': Art.UmbrellaRain,
  'cash-transaction-gray': Art.CashTransactionGray,
  'license-scan': Art.LicenseScan,
  'bank-account': Art.BankAccount,
  'link-account': Art.LinkAccount,
  mmupgradeintro: Art.MMUpgradeIntro,
  banking: Art.Banking,
};

export const ART_FILE_MAP: Record<number, string> = {
  [Art.Navigation]: '/images/img_navigation.svg',
  [Art.TwoFingersOn]: '/images/ci_two_fingers_on.svg',
  [Art.TwoFingersOff]: '/images/ci_two_fingers_off.svg',
  [Art.Vacation]: '/images/li_building_wealth_vacation.svg',
  [Art.GeniusChess]: '/images/img_genius_chess.png',
  [Art.WomanPiggyBank]: '/images/img_money_piggy_bank.svg',
  [Art.GeniusLogoNoText]: '/images/img_genius_logo_no_text.svg',
  [Art.Pig]: '/images/img_albert_pig.svg',
  [Art.PaperPlane]: '/images/ic_paperplane.svg',
  [Art.ProfileCheck]: '/images/img_profile_check.svg',
  [Art.AndroidPlayStoreBanner]: '/images/img_play_store.svg',
  [Art.TextMessages]: '/images/img_text_messages.svg',
  [Art.GeniusDollar]: '/images/img_genius_dollar.png',
  [Art.GeniusBulb]: '/images/img_genius_bulb.png',
  [Art.Warning]: '/images/exclamation-triangle.svg',
  [Art.ExclamationCircle]: '/images/exclamation-circle.svg',
  [Art.ExclamationCircleGray]: '/images/exclamation-circle-gray.svg',
  [Art.ExclamationCircleGenius]: '/images/ic_warning_genius.svg',
  [Art.ExclamationCircleOrange]: '/images/ic_exclamation_orange.svg',
  [Art.ExclamationCircleRed]: '/images/ic_exclamation_red.svg',
  [Art.GeniusesGold]: '/images/img_genius_campaign.png',
  [Art.Logo]: '/images/albert-logo.svg',
  [Art.Rocket]: '/images/img_rocket.svg',
  [Art.OptIn]: '/images/img_text_opt_in_web.svg',
  [Art.Shapes]: '/images/li_building_wealth_shapes.svg',
  [Art.SmartSavingsBolt]: '/images/img_smart_savings.svg',
  [Art.CycleOn]: '/images/ci_cycle_on.svg',
  [Art.CycleOff]: '/images/ci_bulb_off.svg',
  [Art.FlagSparkle]: '/images/img_flag_sparkle.svg',
  [Art.AppleAppStoreBanner]: '/images/img_app_store.svg',
  [Art.Bonuses]: '/images/img_bonuses.svg',
  [Art.AnnualSubscriptionGold]: '/images/annual_subscription.png',
  [Art.Plant]: '/images/li_building_wealth_plant.svg',
  [Art.CheckNumbers]: '/images/img_check_numbers.svg',
  [Art.WomanPlant]: '/images/img_woman_plant.svg',
  [Art.CalendarOff]: '/images/ci_calendar_off.svg',
  [Art.MoneyParachute]: '/images/img_money_parachute.svg',
  [Art.GeniusNewspaper]: '/images/img_genius_newspaper.png',
  [Art.CycleOff]: '/images/ci_cycle_off.svg',
  [Art.GeniusExampleChat]: '/images/img_genius_example_chat.svg',
  [Art.AnnualSubscriptionSilver]: '/images/annual_subscription_gray.png',
  [Art.Tuition]: '/images/li_tuition.svg',
  [Art.GeniusSignature]: '/images/img_genius_signature.svg',
  [Art.GeniusesTalking]: '/images/img_geniuses_talking.svg',
  [Art.GeniusesTalkingGold]: '/images/img_geniuses_talking_gold.svg',
  [Art.GeniusPopper]: '/images/img_genius_popper.png',
  [Art.BulbOn]: '/images/ci_bulb_on.svg',
  [Art.BulbOff]: '/images/ci_bulb_gray.svg',
  [Art.GeniusLogo]: '/images/img_genius_logo.svg',
  [Art.GeniusLogoAlt]: '/images/img_genius_logo_alt.png',
  [Art.Home]: '/images/li_life_event_home.svg',
  [Art.Privacy]: '/images/li_privacy.svg',
  [Art.Vault]: '/images/li_vault.svg',
  [Art.CalendarOn]: '/images/ci_calendar_on.svg',
  [Art.BarChart]: '/images/li_barchart.svg',
  [Art.GeniusMountains]: '/images/img_genius_mountains.png',
  [Art.InvestmentGraph]: '/images/img_in_pbg.svg',
  [Art.Cake]: '/images/li_life_event_cake.svg',
  [Art.Shield]: '/images/li_shield.svg',
  [Art.Stocks]: '/images/li_stocks.svg',
  [Art.ShieldCheck]: '/images/li_shieldcheck.svg',
  [Art.ShieldLock]: '/images/li_shieldlock.svg',
  [Art.Bank]: '/images/img_bank.svg',
  [Art.GeniusCards]: '/images/img_genius_cards.png',
  [Art.ThumbsUp]: '/images/img_thumbs_up.svg',
  [Art.Scale]: '/images/li_scale.svg',
  [Art.DollarBill]: '/images/li_building_wealth_dollar_bill.svg',
  [Art.Times]: '/images/times.svg',
  [Art.Search]: '/images/search.svg',
  [Art.LockCheck]: '/images/img_lock_check.svg',
  [Art.ShieldKYC]: '/images/img_shield_kyc.svg',
  [Art.Fingerprint]: '/images/li_finger_print.svg',
  [Art.Lock]: '/images/li_lock.svg',
  [Art.DebitCardGenius]: '/images/debit_card_genius.svg',
  [Art.DebitCardWhite]: '/images/debit_card_white.svg',
  [Art.DebitCardBlue]: '/images/debit_card_blue.svg',
  [Art.SecureLock]: '/images/img_lock.svg',
  [Art.ModalErr]: '/images/img_modal_error.svg',
  [Art.GreenCheck]: '/images/ic_greencheck.svg',
  [Art.NeutralCheck]: '/images/ic_neutralcheck.svg',
  [Art.SecureLockBackground]: '/images/img_lock_background.svg',
  [Art.ConfirmIdentity]: '/images/img_confirm_identity.svg',
  [Art.Phone]: '/images/img_phone.svg',
  [Art.Laptop]: '/images/img_laptop.svg',
  [Art.Genius]: '/images/img_genius.png',
  [Art.LinkedAccounts]: '/images/ic_linkedaccounts.svg',
  [Art.OneTimeTransfer]: '/images/ic_onetimetransfer.svg',
  [Art.Scheduled]: '/images/ic_scheduled.svg',
  [Art.WarningBannerIcon]: '/images/ic_warning_banner.svg',
  [Art.CashTransactionTransfer]: 'images/ic_cash_transaction_transfer.svg',
  [Art.DownloadTheAppQRCode]: '/images/img_qr_downloadtheapp.png',
  [Art.IPhoneApp]: '/images/iphone_app.svg',
  [Art.Cashback]: '/images/img_cashback.png',
  [Art.ConfettiPhone]: '/images/img_confettiphone.png',
  [Art.LoadingSpinner]: '/images/ic_loading_spinner.svg',
  [Art.SocialMediaCircleIconEmail]: '/images/ic_circle_email.svg',
  [Art.SocialMediaCircleIconFacebook]: '/images/ic_circle_facebook.svg',
  [Art.SocialMediaCircleIconLinkedIn]: '/images/ic_circle_linkedin.svg',
  [Art.SocialMediaCircleIconPinterest]: '/images/ic_circle_pinterest.png',
  [Art.SocialMediaCircleIconTwitter]: '/images/ic_circle_twitter.svg',
  [Art.SocialMediaCircleIconLink]: '/images/ic_circle_link.svg',
  [Art.ArrowNarrowLeft]: '/images/img_arrow_narrow_left.svg',
  [Art.ChevronUp]: '/images/ic_chevron_up.svg',
  [Art.ChevronDown]: '/images/ic_chevron_down.svg',
  [Art.DirectDeposit]: '/images/ic_direct_deposit.svg',
  [Art.SuccessCircle]: '/images/img_success_circle.svg',
  [Art.UmbrellaRain]: '/images/img_umbrella_rain.svg',
  [Art.InvestingTransactionGray]: '/images/ic_investing_transaction_gray.png',
  [Art.SavingsTransactionGray]: '/images/ic_savings_transaction_gray.png',
  [Art.CashTransactionGray]: '/images/ic_cash_transaction_gray.png',
  [Art.LicenseScan]: '/images/img_license_scan.svg',
  [Art.BankAccount]: '/images/ic_bank_account.svg',
  [Art.LinkAccount]: '/images/ic_linkaccount.svg',
  [Art.MMUpgradeIntro]: '/images/img_mmupgradeintro.svg',
  [Art.NewSignupPage1]: '/images/img_app_signup_web.png',
  [Art.DownloadOnTheAppStore]: '/images/img_download_on_app_store.svg',
  [Art.GetItOnGooglePlay]: '/images/img_get_it_on_google_play.svg',
  [Art.GeniusLottieText]: '/images/img_genius_lottie_text.svg',
  [Art.PaperAirplane]: '/images/img_paper_airplane.svg',
  [Art.CashbackLogos]: '/images/img_cashback_logos.svg',
  [Art.Handwave]: '/images/img_handwave.svg',
  [Art.Banking]: '/images/img_banking.svg',
};

/*
Intakes either an art enum, an art code from the signum/genius manager
JSON, or a path. Paths are ignored and passed through.
*/
const ArtMap = (query: string | Art): string => {
  if (typeof query === 'number') {
    return ART_FILE_MAP[query];
  }
  // Return data URI as-is.
  if (query.indexOf('data:') === 0) {
    return query;
  }

  const [key] = query.split('.');
  let normalKey = key.replace(/^(img_)/, '');
  normalKey = normalKey.replace(/^(li_)/, '');
  normalKey = normalKey.replace(/^(ci_)/, '');
  normalKey = kebabCase(normalKey);

  let path = ART_FILE_MAP[NORMALIZING_MAP[normalKey]];

  /* Not a path */
  if (path == null && !~query.indexOf('/')) {
    path = `/images/${query}`;
  }

  return path;
};

export default ArtMap;
