import * as Sentry from '@sentry/react';
import types from 'actions/types';
import * as WEB from 'types/interfaces';

function loadSocureSessionId(context: 'homepage' | 'login' | 'signup') {
  return (dispatch: WEB.Dispatch) => {
    if (typeof window.devicer === 'undefined' || !process.env.SOCURE_SDK_KEY) {
      return;
    }
    try {
      window.devicer.run(
        {
          publicKey: process.env.SOCURE_SDK_KEY,
          userConsent: true,
          context,
        },
        (response: any) => {
          dispatch({
            type: types.SOCURE_SESSION_ID_LOADED,
            payload: {
              socureSessionId: response.sessionId,
            },
          });
        }
      );
    } catch (error) {
      Sentry.captureException(error);
    }
  };
}

export default loadSocureSessionId;
