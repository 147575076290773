import { Atomic } from '@atomicfi/transact-javascript';
import { transparentize } from 'polished';
import AlbertClient from 'api/AlbertClient';
import DirectDepositSwitchSources from 'constants/DirectDepositSwitchSources';
import { colors } from 'styles';
import { logger } from 'utils/logger';
import wrappedFetch from 'utils/wrappedFetch';

export const fetchAtomicAccessToken = async (
  bankAccountId: number
): Promise<string | undefined> => {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      switch_type: 'a',
    }),
  };

  const url = AlbertClient.bankingFetchAtomicAccessToken(bankAccountId);
  try {
    const response = await wrappedFetch(url, options);
    const data: { atomic_sdk_access_token?: string } = await response.json();
    return data?.atomic_sdk_access_token;
  } catch {
    return undefined;
  }
};

export const openAtomicWindow = (
  atomicKey: string | null,
  source?: DirectDepositSwitchSources,
  onFinish?: () => void,
  onError?: () => void,
  onInteraction?: (interaction: any) => void
) => {
  try {
    if (!atomicKey) {
      throw Error('Atomic access token could not be fetched');
    }

    const finish = () => {
      document.documentElement.style.overflow = '';
      onFinish && onFinish();
    };

    const close = () => {
      document.documentElement.style.overflow = '';
    };

    // Disable scrolling on our <html> tag so that, when Atomic's iframe modal
    // is displayed, we can't scroll the parent app beneath it.
    document.documentElement.style.overflow = 'hidden';

    Atomic.transact({
      config: {
        publicToken: atomicKey,
        tasks: [{ product: 'deposit' }],
        metadata: {
          source,
        },
        theme: {
          brandColor: colors.primaryAlbertBrand,
          overlayColor: transparentize(0.2, colors.primaryText),
        },
      },
      onFinish: finish,
      onClose: close,
      onInteraction,
    });
  } catch (e) {
    logger.error(e);
    document.documentElement.style.overflow = '';
    onError && onError();
  }
};
