import * as React from 'react';
import styled from 'styled-components';
import { breakpoints } from 'styles';
import { spacers } from 'styles/home';
import { flexGapColumn, flexGapRow } from 'utils/polyfills';

type FlexGap = '32px' | '12px';
type MobileDirection = 'column-reverse' | 'row';
type Props = {
  primaryButton: React.ReactElement;
  secondaryButton?: React.ReactElement | null;
  marginTop?: string;
  mobileDirection?: MobileDirection;
  flexGap?: FlexGap;
};

const Container = styled.div<{
  marginTop?: string;
  mobileDirection?: MobileDirection;
  flexGap?: FlexGap;
}>`
  display: flex;
  align-items: center;
  margin-top: ${({ marginTop }) => marginTop || spacers.g11};
  ${({ flexGap }) => flexGapRow(flexGap || spacers.g8)};
  ${({ flexGap }) => flexGapColumn(flexGap || spacers.g10)};
  @media ${breakpoints.mobileLarge} {
    flex-direction: ${({ mobileDirection }) =>
      mobileDirection || 'column-reverse'};
    button {
      width: 100%;
    }
  }
`;

/**
 * Layout component for displaying primary button with secondary button in signup flow
 */
const ButtonsLayout = ({
  primaryButton,
  secondaryButton = null,
  marginTop,
  mobileDirection,
  flexGap,
}: Props): React.ReactElement => (
  <Container
    marginTop={marginTop}
    mobileDirection={mobileDirection}
    flexGap={flexGap}
  >
    {secondaryButton}
    {primaryButton}
  </Container>
);

export default ButtonsLayout;
