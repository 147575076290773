import * as Sentry from '@sentry/react';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import getAppData from 'actions/async/getAppData';
import updateProfile from 'actions/async/updateProfile';
import addBanner from 'actions/banner/addBanner';
import removeBanner from 'actions/banner/removeBanner';
import types from 'actions/types';
import Button from 'components/common/Button';
import Spacer from 'components/common/Spacer';
import { FilledSquareCard } from 'components/common/SquareCard/SquareCardV2';
import Text from 'components/common/Text';
import Icon from 'components/icons/SetUp';
import ButtonsLayout from 'components/layout/ButtonsLayout';
import LeadContent from 'components/layout/LeadContent';
import PageBase from 'components/layout/PageBase';
import BannerActionType from 'constants/BannerActionType';
import Pages from 'constants/Pages';
import SignupGoals, {
  BankGoalPageMap,
  SignupGoalsType,
} from 'constants/SignupGoals';
import { NotificationTypes } from 'constants/index';
import useNavigateFunction from 'hooks/useNavigateFunction';
import useVerifyCurrentPage from 'hooks/useVerifyCurrentPage';
import * as appData from 'reducers/entities/appData';
import * as language from 'reducers/entities/language';
import { breakpoints } from 'styles';
import { spacers as homeSpacers, spacers } from 'styles/home';
import * as WEB from 'types/interfaces';
import { LanguageListItem } from 'types/interfaces';
import { getLanguageSection } from 'utils/getFromLanguage';
import { logger } from 'utils/logger';

type Props = {
  nextPage: string;
};

const CARD_SIZE = '92px';

const CardsContainer = styled.div`
  display: grid;
  column-gap: 12px;
  row-gap: 14px;
  justify-items: center;
  max-width: 480px;
  @media ${breakpoints.mobileLarge} {
    margin: 0 auto;
  }
`;

const IconContainer = styled.div`
  margin: auto;
  padding-right: 20px;
`;

const CardContentContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 5fr;
  align-items: center;
  margin: auto 0;
`;

const SetUpFirst = (props: Props): React.ReactElement => {
  // ///////////////////////////////
  /* =========== HOOKS ========== */
  // ///////////////////////////////
  const { updateCurrentSignupPage } = useNavigateFunction();
  useVerifyCurrentPage(Pages.SET_UP_FIRST);
  const dispatch = useDispatch();

  // ///////////////////////////////
  /* =========== SELECTORS ========== */
  // ///////////////////////////////
  const signupGoalObjects =
    useSelector((state: WEB.RootState) =>
      appData.getValueByField(state, 'signup_goal_objects')
    ) || [];
  const previouslySelectedOption = (
    signupGoalObjects as WEB.SignupGoalObject[]
  ).find((goal) => goal.type === SignupGoalsType.BANK_GOALS);
  const languageCtx = useSelector((state: WEB.RootState) =>
    language.getSignupPageLanguage(state, Pages.SET_UP_FIRST)
  );
  const setupLanguage = getLanguageSection(languageCtx, 'setup');
  const optionsLanguage = getLanguageSection(languageCtx, 'options');

  // ///////////////////////////////
  /* =========== STATE ========== */
  // ///////////////////////////////
  const [selectedOptionId, setSelectedOptionId] =
    React.useState<SignupGoals | null>(previouslySelectedOption?.name || null);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  // ///////////////////////////////
  /* =========== HANDLERS ========== */
  // ///////////////////////////////
  const updateSelectedOptions = (id: SignupGoals): void => {
    dispatch(removeBanner());
    if (selectedOptionId === id) {
      setSelectedOptionId(null);
    } else {
      setSelectedOptionId(id);
    }
  };

  const handleSubmit = async (): Promise<void> => {
    setIsSubmitting(true);
    if (!selectedOptionId) {
      dispatch(
        addBanner(
          NotificationTypes.WARNING,
          setupLanguage?.errorMessage || '',
          false,
          BannerActionType.HIDE_ICON
        )
      );
      setIsSubmitting(false);
      return;
    }
    const { error }: any = await dispatch(
      updateProfile({
        signup_goals: [selectedOptionId],
        signup_goals_type: SignupGoalsType.BANK_GOALS,
      })
    );
    // Data is stored in appData even though update is made to profile, so we
    // need to refetch appData to keep the Redux store in sync with the backend
    await dispatch(getAppData());

    setIsSubmitting(false);
    if (!error) {
      // Flow may be updated depending on user's selection
      const updatedSignupGoalObjects = signupGoalObjects.concat([
        {
          name: selectedOptionId,
          type: SignupGoalsType.BANK_GOALS,
        },
      ]);
      dispatch({
        type: types.UPDATE_SIGNUP_LANGUAGE_ORDER,
        payload: {
          signupGoalObjects: updatedSignupGoalObjects,
        },
      });
      const nextPage = BankGoalPageMap[selectedOptionId];
      if (!nextPage) {
        const errorContext = {
          selectedOption: selectedOptionId,
        };
        Sentry.setContext('SetUpFirst details', errorContext);
        logger.error('Failed to determine the first product page');
      }
      dispatch(updateCurrentSignupPage({ page: nextPage || props.nextPage }));
    }
  };

  // ///////////////////////////////
  /* =========== RENDER ========== */
  // ///////////////////////////////
  return (
    <>
      <PageBase>
        <LeadContent
          header={setupLanguage?.header || ''}
          text={setupLanguage.text}
        />
        <Spacer space={spacers.g6} />
        <CardsContainer>
          {optionsLanguage?.list?.map((option: LanguageListItem) => {
            return (
              <FilledSquareCard
                key={option.id}
                selected={selectedOptionId === option.id}
                onClick={() => updateSelectedOptions(option.id as SignupGoals)}
                height={CARD_SIZE}
                width='100%'
              >
                <CardContentContainer>
                  <IconContainer>
                    <Icon icon={option.icon || ''} />
                  </IconContainer>
                  <div>
                    <Text>{option.header}</Text>
                    <Text className='card-subtext'>{option.text}</Text>
                  </div>
                </CardContentContainer>
              </FilledSquareCard>
            );
          })}
        </CardsContainer>
        <ButtonsLayout
          marginTop={homeSpacers.g9}
          primaryButton={
            <Button
              isLoading={isSubmitting}
              disabled={isSubmitting}
              onClick={handleSubmit}
            >
              Continue
            </Button>
          }
        />
      </PageBase>
    </>
  );
};

export default SetUpFirst;
