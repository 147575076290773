import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import updateBankingAccount from 'actions/async/updateBankingAccount';
import addBanner from 'actions/banner/addBanner';
import removeBanner from 'actions/banner/removeBanner';
import getNextPage from 'actions/getNextPage';
import DebitCard, { ShadowTypes } from 'components/banking/DebitCard';
import Button from 'components/common/Button';
import { MobileWrapper } from 'components/common/DeviceWrapper';
import Spacer from 'components/common/Spacer';
import ButtonsLayout from 'components/layout/ButtonsLayout';
import LeadContent from 'components/layout/LeadContent';
import PageBase from 'components/layout/PageBase';
import Pages from 'constants/Pages';
import { ErrorMessages, NotificationTypes } from 'constants/index';
import useNavigateFunction from 'hooks/useNavigateFunction';
import useVerifyCurrentPage from 'hooks/useVerifyCurrentPage';
import * as appData from 'reducers/entities/appData';
import * as language from 'reducers/entities/language';
import * as profile from 'reducers/entities/profile';
import { breakpoints } from 'styles';
import { spacers } from 'styles/home';
import * as WEB from 'types/interfaces';
import { delayWithState } from 'utils/delay';
import { getLanguageButtons, getLanguageSection } from 'utils/getFromLanguage';

type Props = {
  nextPage: string;
};

const DebitCardContainer = styled.div`
  @media ${breakpoints.mobileLarge} {
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const CashSelectShipDebitCard = (props: Props) => {
  const dispatch = useDispatch();
  const { updateCurrentSignupPage } = useNavigateFunction();
  // Make sure Redux is up-to-date with currentSignUpPage
  useVerifyCurrentPage(Pages.CASH_SELECT_SHIP_DEBIT_CARD);

  const languageCtx = useSelector((state: WEB.RootState) =>
    language.getSignupPageLanguage(state, Pages.CASH_SELECT_SHIP_DEBIT_CARD)
  );
  const bankingAccount = useSelector(appData.getBankingAccount);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [choice, setChoice] = React.useState<'yes' | 'no' | null>(null);
  const [isCompleted, setIsCompleted] = React.useState<boolean>(false);

  const firstName = useSelector((state: WEB.RootState) =>
    profile.getValueByField(state, 'first_name')
  );
  const lastName = useSelector((state: WEB.RootState) =>
    profile.getValueByField(state, 'last_name')
  );

  const physicalCardCtx = getLanguageSection(languageCtx, 'physicalCard');
  const buttonsCtx = getLanguageButtons(languageCtx);

  const onSubmit = async (optIn: boolean) => {
    // Remove any error banner from previous attempts
    dispatch(removeBanner());
    setChoice(optIn ? 'yes' : 'no');
    setIsLoading(true);

    const error =
      !bankingAccount ||
      (await dispatch(
        updateBankingAccount(bankingAccount.id, {
          physical_card_opt_in: optIn,
          physical_card_opt_in_source: WEB.PhysicalCardOptInSource.SIGNUP,
        })
      ));

    if (error) {
      dispatch(
        addBanner(NotificationTypes.WARNING, ErrorMessages.web.generic, false)
      );
      setIsLoading(false);
      return;
    }
    setIsLoading(false);
    const { nextPageOverride }: any = await dispatch(getNextPage());
    await delayWithState(400, setIsCompleted);
    dispatch(
      updateCurrentSignupPage({
        page: nextPageOverride || props.nextPage,
      })
    );
  };

  return (
    <PageBase>
      <MobileWrapper>
        {bankingAccount?.debit_card_style && (
          <DebitCardContainer>
            <DebitCard
              firstName={firstName}
              lastName={lastName}
              style={bankingAccount.debit_card_style}
              tilted
              shadow={ShadowTypes.BACKGROUND}
            />
          </DebitCardContainer>
        )}
        <Spacer space={spacers.g5} desktopOnly />
      </MobileWrapper>
      <LeadContent
        header={physicalCardCtx?.header || ''}
        text={physicalCardCtx?.text || ''}
        desktopHeaderSize='small'
      />
      <ButtonsLayout
        marginTop={spacers.g9}
        mobileDirection='row'
        flexGap='12px'
        primaryButton={
          <Button
            onClick={() => onSubmit(true)}
            isCompleted={choice === 'yes' && isCompleted}
            isLoading={choice === 'yes' && isLoading}
            disabled={choice === 'no'}
          >
            {buttonsCtx?.primary}
          </Button>
        }
        secondaryButton={
          <Button
            onClick={() => onSubmit(false)}
            isCompleted={choice === 'no' && isCompleted}
            isLoading={choice === 'no' && isLoading}
            disabled={choice === 'yes'}
            secondary
          >
            {buttonsCtx?.secondary}
          </Button>
        }
      />
    </PageBase>
  );
};

export default CashSelectShipDebitCard;
