import * as Sentry from '@sentry/react';
import { useSelector } from 'react-redux';
import * as profile from 'reducers/entities/profile';
import * as WEB from '../types/interfaces';

export default (): void => {
  const id = useSelector((state: WEB.RootState) => {
    return profile.getValueByField(state, 'id');
  });
  Sentry.setUser({ id });
};
