/* eslint-disable no-param-reassign */
import produce from 'immer';
import initialState from 'store.initialState';
import types from 'actions/types';
import Pages from 'constants/Pages';
import * as WEB from 'types/interfaces';

// /////////////////////////////////////////////////////////////////////////////
// selectors
// /////////////////////////////////////////////////////////////////////////////

export const getCheckpoint = (state: WEB.RootState): string => {
  return state.ui.signup?.checkpoint || '';
};

export const getCurrentPage = (state: WEB.RootState): Pages => {
  return state.ui.signup.currentPage || '';
};

export const getSelectedInsId = (state: WEB.RootState): string => {
  return state.ui.signup.selectedInsId;
};

// /////////////////////////////////////////////////////////////////////////////
// reducers
// /////////////////////////////////////////////////////////////////////////////

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable consistent-return */
const signup = (state = initialState.ui.signup, action: WEB.Action) => {
  const { type, payload, error } = action;
  return produce(state, (draft: WEB.RootState['ui']['signup']) => {
    switch (type) {
      case types.SOCURE_SESSION_ID_LOADED:
        draft.socureSessionId = payload.socureSessionId;
        return;

      case types.GET_APP_DATA_SUCCESS: {
        const checkpoint =
          payload.data?.web_signup_screen || // Async
          payload?.checkpoint || // Action
          '';

        draft.checkpoint = checkpoint;
        return;
      }

      case types.UPDATE_SELECTED_INS_ID: {
        draft.selectedInsId = payload?.institutionId;
        return;
      }

      case types.UPDATE_CURRENT_SIGNUP_PAGE: {
        draft.currentPage = payload?.page || '';
        return;
      }

      case types.LOGOUT_SUCCESS: {
        return initialState.ui.signup;
      }
      default:
    }
  });
};

export default signup;
