/**
 * Sleeps for a given amount of milliseconds.
 *
 * @param ms Number of milliseconds to wait until the promise resolves.
 * @returns A promise that can be awaited.
 */
export const delay = async (ms: number): Promise<unknown> => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

/**
 * Delays state functions by a given number of milliseconds.
 * This is used for when making API requests and triggering a function that
 * sets a specific state, such as setIsSubmitting(true).
 *
 * @param ms Number of milliseconds to wait until the promise resolves.
 * @param func The state function with an initial boolean value.
 * @param initialValue The state's initial value.
 * @param changeValue Whether to reset the state.
 * @returns A promise that can be awaited.
 */
export const delayWithState = async (
  ms: number,
  func: (value: boolean) => void,
  initialValue = true,
  changeValue = false
): Promise<unknown> => {
  const promise = delay(ms);
  // Set state
  func(initialValue);
  await promise;
  if (changeValue) {
    // Reset state
    func(!initialValue);
  }
  return promise;
};
