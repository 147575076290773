import * as React from 'react';
import styled from 'styled-components';
import { breakpoints } from '../../styles';

const StyledSvg = styled.svg`
  width: 30px;
  height: 20px;
  @media ${breakpoints.mobileLarge} {
    width: 22px;
    height: 14px;
  }
`;

/**
 * A hamburger icon: 3 stacked horizontal lines.
 */
const Hamburger = () => (
  <StyledSvg
    width='22'
    height='14'
    viewBox='0 0 22 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0.243164 0.471191H21.2432V2.56921H0.243164V0.471191ZM0.243164 5.71631H21.2432V7.81433H0.243164V5.71631ZM21.2432 10.9614H0.243164V13.0594H21.2432V10.9614Z'
      fill='black'
    />
  </StyledSvg>
);

export default Hamburger;
