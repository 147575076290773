/* eslint-disable no-param-reassign */

/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Sentry from '@sentry/react';
import produce from 'immer';
import initialState from 'store.initialState';
import types from 'actions/types';
import KYCVerifyIdentityWebLanguage from 'constants/KYCVerifyIdentityWebLanguage';
import PageRoutes from 'constants/PageRoutes';
import Pages from 'constants/Pages';
import SignupGoals, {
  BankGoalPageMap,
  SignupGoalsType,
} from 'constants/SignupGoals';
import * as WEB from 'types/interfaces';
import { logger } from 'utils/logger';

// /////////////////////////////////////////////////////////////////////////////
// selectors
// /////////////////////////////////////////////////////////////////////////////

export const hasSetUpAlbert = (state: WEB.RootState): boolean => {
  const setThingsUpFlow = state.entities.language.signup?.order.find(
    (o: WEB.LanguageSubflow) => o.route === 'open-account'
  );
  return setThingsUpFlow?.pages.includes(Pages.SET_UP_ALBERT);
};
export const hasSetUpFirst = (state: WEB.RootState): boolean => {
  const setThingsUpFlow = state.entities.language.signup?.order.find(
    (o: WEB.LanguageSubflow) => o.route === 'set-things-up'
  );
  return setThingsUpFlow?.pages.includes(Pages.SET_UP_FIRST);
};

export const shouldFetchIntroLanguage = (state: WEB.RootState): boolean => {
  // NOTE: Refetch intro lang if web_signup_version_key is missing. Has happened locally on logout
  // sporadically and hard to reproduce but added check as a fail safe.
  const shouldFetch =
    !Object.keys(state.entities.language.signup || {}).length ||
    !state.entities.language.signup.web_signup_version_key;
  return shouldFetch;
};

export const getIntroSignupVersion = (state: WEB.RootState): string | null => {
  return state.entities.language.signup?.web_signup_version_key || null;
};

export const shouldFetchGeniusLanguage = (state: WEB.RootState): boolean => {
  return !Object.keys(state.entities.language.genius).length;
};

export const shouldFetchKYCLanguage = (state: WEB.RootState): boolean => {
  return !Object.keys(state.entities.language.kyc).length;
};

// ///////////
// sign up
// ///////////
export const getSignupFlowOrder = (
  state: WEB.RootState
): WEB.LanguageSubflow[] => {
  return (
    state.entities.language.signup?.order || ([] as WEB.SignupLanguage['order'])
  );
};

export const getSignupLanguage = (state: WEB.RootState): WEB.SignupLanguage => {
  return state.entities.language?.signup as WEB.SignupLanguage;
};

export const getSignupPageLanguage = (
  state: WEB.RootState,
  page: string
): WEB.PageLanguage => {
  return state.entities.language.signup?.[page] || ({} as WEB.SignupLanguage);
};

export const getSignupRouteByPage = (
  state: WEB.RootState,
  page: Pages,
  { disableLogging }: { disableLogging?: boolean } = {}
): string => {
  const { order = [] } = state.entities.language.signup;
  const pageCtx = getSignupPageLanguage(state, page);

  const subflowCtx = order.reduce(
    (result: WEB.LanguageSubflow, current: WEB.LanguageSubflow) => {
      if (current.pages.includes(page)) {
        return current;
      }
      return result;
    },
    {} as WEB.LanguageSubflow
  );
  if (!disableLogging && (!subflowCtx.route || !pageCtx.route)) {
    const errorContext = {
      page: page,
      subflowRoute: subflowCtx.route,
      pageRoute: pageCtx.route,
      order: JSON.stringify(order),
    };
    Sentry.setContext('Additional data', errorContext);
    logger.message('Invalid route built in signup flow');
  }

  const route = `${PageRoutes.SIGNUP_BASE}/${subflowCtx.route}/${pageCtx.route}`;
  return route;
};

// ///////////
// genius
// ///////////
export const getGeniusLanguage = (state: WEB.RootState): WEB.GeniusLanguage => {
  return state.entities.language.genius as WEB.GeniusLanguage;
};

export const getGeniusPageLanguage = (
  state: WEB.RootState,
  page: string
): WEB.PageLanguage => {
  return state.entities.language.genius?.[page] || ({} as WEB.GeniusLanguage);
};

export const getGeniusRouteByPage = (
  state: WEB.RootState,
  page: string,
  fromSignup?: boolean
): string => {
  const pageCtx = getGeniusPageLanguage(state, page);
  const routeBase = fromSignup ? PageRoutes.SIGNUP_BASE : '';
  const route = `${routeBase}${PageRoutes.GENIUS_BASE}/${pageCtx.route}`;
  return route;
};

export const getSignupFlag = (
  state: WEB.RootState,
  flag: WEB.SignupFlags
): boolean => state.entities.language.signup?.flags?.[flag] || false;

// ///////////
// KYC
// ///////////
export const getKYCLanguage = (state: WEB.RootState): WEB.KYCLanguage => {
  return {
    ...state.entities.language.kyc,
    ...KYCVerifyIdentityWebLanguage,
  } as WEB.KYCLanguage;
};

export const getKYCPageLanguage = (
  state: WEB.RootState,
  page: string
): WEB.PageLanguage => {
  const kycLanguage = getKYCLanguage(state);
  return kycLanguage?.[page] || ({} as WEB.KYCLanguage);
};

export const getKYCBasePath = (
  state: WEB.RootState,
  fromSignup = false
): string => {
  let baseRoute = PageRoutes.KYC as string;
  if (fromSignup) {
    // Find subflow with KYC module.
    // NOTE: If this is inefficient, we can store the subflow route in redux.
    const signupFlowOrder = getSignupFlowOrder(state);
    const subflow: WEB.LanguageSubflow[] = signupFlowOrder.filter(
      (flow: WEB.LanguageSubflow) => {
        return (flow?.pages || []).includes(Pages.KYC);
      }
    );
    const subflowRoute = subflow?.[0]?.route || '';
    baseRoute = `${PageRoutes.SIGNUP_BASE}/${subflowRoute}${PageRoutes.KYC}`;
  }
  return baseRoute;
};

export const getKYCRouteByPage = (
  state: WEB.RootState,
  page: string,
  fromSignup?: boolean
): string => {
  const pageCtx = getKYCPageLanguage(state, page);
  const baseRoute = getKYCBasePath(state, fromSignup);
  return `${baseRoute}/${pageCtx.route}`;
};

// /////////////////////////////////////////////////////////////////////////////
// reducers
// /////////////////////////////////////////////////////////////////////////////

// TODO: will need separate state for genius language
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const language = (
  state = initialState.entities.language,
  action: WEB.Action
) => {
  const { type, payload, error } = action;
  return produce(state, (draft: WEB.RootState['entities']['language']) => {
    switch (type) {
      // Signup
      case types.GET_SIGNUP_INTRO_SUCCESS:
      case types.GET_SIGNUP_LANGUAGE_SUCCESS: {
        const webSignupVerKey = draft.signup?.web_signup_version_key;

        if (payload?.data?.order?.length > 0) {
          // Remove the Persona page from the regular flow.
          for (let i = 0; i < payload.data.order.length; i++) {
            if (payload.data.order[i].route === 'set-things-up') {
              payload.data.order[i].pages = (
                payload.data.order[i].pages || []
              ).filter((page: Pages) => page !== Pages.KYC_VERIFY_IDENTITY);
              break;
            }
          }
          // Preserve page order before any further modification
          draft.originalOrder = payload.data.order;
        }
        draft.signup = payload.data || {};

        // Preserve web signup version key.
        if (webSignupVerKey) {
          draft.signup.web_signup_version_key = webSignupVerKey;
        }
        return;
      }

      case types.UPDATE_SIGNUP_LANGUAGE_ORDER: {
        const signupGoalObjects =
          (payload.signupGoalObjects as WEB.SignupGoalObject[]) || [];
        let showDirectDepositEarly = signupGoalObjects.some(
          (goal) =>
            goal.type === SignupGoalsType.SETUP_GOALS &&
            goal.name === SignupGoals.BANK_ACCOUNT
        );
        const firstProductPageKey = signupGoalObjects.find(
          (goal) => goal.type === SignupGoalsType.BANK_GOALS
        );

        if (
          draft?.signup?.order?.length > 0 &&
          draft?.originalOrder?.length > 0
        ) {
          for (let i = 0; i < draft.signup.order.length; i++) {
            const flow = draft.signup.order[i];
            if (flow.route === 'set-things-up') {
              // Remove one of the DirectDeposit pages from the flow depending on the user's signupGoals
              if (showDirectDepositEarly) {
                draft.signup.order[i].pages = draft.originalOrder[i].pages;
                // Sanity check
                if (
                  !draft.signup.order[i].pages.includes(Pages.DIRECT_DEPOSIT)
                ) {
                  showDirectDepositEarly = false;
                }
              } else {
                draft.signup.order[i].pages = flow.pages.filter(
                  (page: Pages) => page !== Pages.DIRECT_DEPOSIT
                );
              }
              // Modify product order if necessary
              const setUpFirstIndex = flow.pages.indexOf(Pages.SET_UP_FIRST);
              const firstProductPage =
                firstProductPageKey &&
                BankGoalPageMap[firstProductPageKey.name];
              if (setUpFirstIndex !== -1 && firstProductPage) {
                const targetPageIndex = flow.pages.indexOf(firstProductPage);
                // Remove the page from the product flow and re-insert it
                // in the beginning of the product flow (after SetUpFirst)
                if (targetPageIndex !== -1) {
                  // Delete page from flow
                  flow.pages.splice(targetPageIndex, 1);
                  // Re-insert page after SetUpFirst
                  flow.pages.splice(setUpFirstIndex + 1, 0, firstProductPage);
                  draft.signup.order[i].pages = flow.pages;
                }
              }
            } else if (flow.route === 'set-up-debit-card') {
              if (!showDirectDepositEarly) {
                draft.signup.order[i].pages = draft.originalOrder[i].pages;
              } else {
                draft.signup.order[i].pages = flow.pages.filter(
                  (page: Pages) => page !== Pages.DIRECT_DEPOSIT
                );
              }
            }
          }
        }
        return;
      }

      case types.GET_SIGNUP_INTRO_FAILURE: {
        draft.signup = {};
        return;
      }

      // Genius
      case types.GET_GENIUS_LANGUAGE_SUCCESS: {
        draft.genius = payload.data?.flow || {};
        return;
      }
      case types.GET_GENIUS_LANGUAGE_FAILURE: {
        draft.genius = {};
        return;
      }

      // KYC
      case types.GET_KYC_LANGUAGE_SUCCESS: {
        draft.kyc = payload.data || {};
        return;
      }

      case types.LOGOUT_SUCCESS: {
        // Only preserve the logout flows
        Object.entries(draft.signup).forEach(([key, value]: [string, any]) => {
          if (typeof value === 'object' && !value?.loggedOut) {
            delete draft[key];
          }
        });

        draft.genius = initialState.entities.language.genius;
        draft.kyc = initialState.entities.language.kyc;
      }

      default:
    }
  });
};

export default language;
