import * as React from 'react';
import ReactMarkdown from 'react-markdown';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import DebitCard, { ShadowTypes } from 'components/banking/DebitCard';
import PillBadge from 'components/common/Badge/PillBadge';
import Spacer from 'components/common/Spacer';
import Text, { TextColor, TextSizes } from 'components/common/Text';
import { InfoPaneLottie } from 'components/common/animations';
import InfoPaneExpanderGroup from 'components/layout/InfoPaneExpanderGroup';
import InfoPaneList from 'components/layout/InfoPaneList';
import ArtMap from 'constants/ArtMap';
import Pages, { GeniusPages, SignupPages } from 'constants/Pages';
import ZIndex from 'constants/ZIndex';
import { DebitCardStyle } from 'constants/index';
import * as appData from 'reducers/entities/appData';
import * as profile from 'reducers/entities/profile';
import { getCurrentPage } from 'reducers/ui/signup';
import { breakpoints, mixins, spacers } from 'styles';
import { HEADER_HEIGHT } from 'styles/constants';
import { spacers as homeSpacers } from 'styles/home';
import * as WEB from 'types/interfaces';
import { hash32 } from 'utils/hash';

type Props = Partial<WEB.LanguageInfo> & {
  /* current page */
  currentPage?: string;
  /* extra space */
  mobileNavPane?: boolean;
  /* h2 or h1, using string in case we need more later */
  size?: string;
  /* genius expanders have an additional 10px of padding under their headers */
  addExpanderSubheadSpacing?: boolean;
  /* text for badge */
  labelText?: string;
  canShowDebitCard?: boolean;
};

const H2_HEADER_PAGES: (SignupPages | GeniusPages)[] = [
  Pages.SAVINGS_SETUP,
  Pages.INSTANT_BENEFITS,
  Pages.INVESTING_BENEFITS,
  Pages.GENIUS_ANNUAL_MONTHLY,
  Pages.GENIUS_CONFIRM,
  Pages.GENIUS_PRICING,
  Pages.GENIUS_TOUR,
  Pages.GENIUS_WELCOME,
];

const StyledInfoPaneContainer = styled.div`
  width: 42vw;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.lightGray3};
  padding: 0 ${spacers.defaultPaddingLeftRight}
    ${spacers.defaultPaddingTopBottom} ${spacers.defaultPaddingLeftRight};
  @media (max-width: 481px) {
    /* 2021/7/6, MPR: 481 is the width at which point the default padding drops
     * below 24px. Think of this as 'min-padding'
     */
    padding: 0 ${mixins.pxToRem('24px')} ${spacers.defaultPaddingTopBottom}
      ${mixins.pxToRem('24px')};
  }
  @media ${breakpoints.mobileLarge} {
    width: 100%;
    background-color: transparent;
    padding-bottom: 0;
  }
`;

const StyledInfoPaneContent = styled.div`
  ${mixins.fadeIn()};
  padding-top: ${spacers.large};
  width: 100%;
  max-width: 345px;
  margin: 0 auto;
  @media ${breakpoints.mobileLarge} {
    width: 100%;
    max-width: 100%;
  }
`;

const StyledImage = styled.img<{ width: number; minWidth?: string }>`
  display: block;
  margin-bottom: ${spacers.tab};
  ${({ width }) => `width: ${width}%`};
  ${({ minWidth }) => (minWidth ? `min-width: ${minWidth}` : null)};
  @media ${breakpoints.mobileLarge} {
    ${({ width }) => `width: ${Math.ceil(width / 2) + 2}%`};
  }
`;
/* 2021/7/6, MPR: ^ the +2 offsets the page padding */

const MobileInfoPaneHeader = styled.h1<{ mobileNavPane?: boolean }>`
  margin-bottom: 12px;
`;

const HeaderSpacer = styled.div`
  // When there isn't a lottie/image above the header, this margin will align
  // the info pane's header with the right pane's header, regardless of whether
  // the info pane's header is h1 or h2.
  margin-top: 40px;
`;

const DebitCardContainer = styled.div`
  scale: 0.7;
  // Offset vertical space caused by scaling down the debit card
  margin-left: -48px;
  margin-bottom: -28px;
`;

export const getImage = ({
  filename,
  width,
  minWidth,
}: WEB.ImageContext): React.ReactElement => {
  return (
    <StyledImage
      key={`image-${filename}`}
      src={ArtMap(filename)}
      width={width}
      minWidth={minWidth}
    />
  );
};

const InfoPane = (props: Props): React.ReactElement => {
  const {
    text,
    image,
    lottie,
    header,
    expanders,
    list,
    disclaimer,
    labelText,
    size,
    currentPage: currentPageProp,
    addExpanderSubheadSpacing = false,
    mobileNavPane,
    canShowDebitCard,
  } = props;
  const currentPage =
    (useSelector(getCurrentPage) as SignupPages | GeniusPages) ||
    currentPageProp;

  const bankingAccount = useSelector(appData.getBankingAccount);
  const firstName = useSelector((state: WEB.RootState) =>
    profile.getValueByField(state, 'first_name')
  );
  const lastName = useSelector((state: WEB.RootState) =>
    profile.getValueByField(state, 'last_name')
  );

  const getHeader = () => {
    if (
      !mobileNavPane &&
      (size == 'h2' || H2_HEADER_PAGES.includes(currentPage))
    ) {
      return <h2 className='info-pane-header'>{header}</h2>;
    }

    return (
      <MobileInfoPaneHeader
        mobileNavPane={mobileNavPane}
        className='info-pane-header'
      >
        {header}
      </MobileInfoPaneHeader>
    );
  };

  // "text" can be either a string or a list of strings
  const texts =
    text instanceof Array ? (
      text.map((txt: string) => (
        <React.Fragment key={`info-text-fragment-${hash32(txt)}`}>
          <Text key={`info-text-${hash32(txt)}`}>{txt}</Text>
          <Spacer space={spacers.small} />
        </React.Fragment>
      ))
    ) : (
      <Text>{text}</Text>
    );

  const showDebitCard =
    firstName &&
    lastName &&
    bankingAccount?.debit_card_style &&
    canShowDebitCard;

  return (
    <StyledInfoPaneContainer>
      <StyledInfoPaneContent id='info-pane-content'>
        {lottie && <InfoPaneLottie {...lottie} />}
        {labelText && <PillBadge>{labelText}</PillBadge>}
        {showDebitCard && (
          <DebitCardContainer>
            <DebitCard
              firstName={firstName}
              lastName={lastName}
              style={bankingAccount?.debit_card_style as DebitCardStyle}
              tilted
              shadow={ShadowTypes.BACKGROUND}
            />
          </DebitCardContainer>
        )}
        {image && getImage(image)}
        {!lottie && !image && <HeaderSpacer />}
        {header && getHeader()}
        {text && texts}
        {expanders && (
          <InfoPaneExpanderGroup
            key={currentPageProp}
            expanders={expanders}
            addExpanderSubheadSpacing={addExpanderSubheadSpacing}
          />
        )}
        {list && (
          <>
            <Spacer space={spacers.tabLarge} />
            <InfoPaneList list={list} />
          </>
        )}
        {disclaimer && (
          <>
            <Spacer space={text ? spacers.tabSmall : homeSpacers.g1} />
            <Text color={TextColor.GRAY_DARK} size={TextSizes.SMALL}>
              <ReactMarkdown>{disclaimer}</ReactMarkdown>
            </Text>
          </>
        )}
      </StyledInfoPaneContent>
    </StyledInfoPaneContainer>
  );
};

// ////////////////////////////////
/* ======== RESPONSIVE ========= */
// ////////////////////////////////
const DesktopResponsiveContainer = styled.div`
  z-index: ${ZIndex.INFO_PANE};
  min-height: calc(100vh - ${mixins.pxToRem(HEADER_HEIGHT)});
  @media ${breakpoints.mobileLarge} {
    display: none;
  }
`;

const MobileResponsiveContainer = styled.div<{ extraSpace?: boolean }>`
  display: none;
  @media ${breakpoints.mobileLarge} {
    display: block;
    > div {
      padding: 0;
      > div {
        padding-top: ${({ extraSpace }) => (extraSpace ? '44px' : 0)};
      }
    }
  }
`;

const MobileInfoPane = ({ ...infoPaneProps }: Props): React.ReactElement => (
  <MobileResponsiveContainer>
    <InfoPane {...infoPaneProps} image={undefined} lottie={undefined} />
  </MobileResponsiveContainer>
);

const DesktopInfoPane = (props: Props): React.ReactElement => (
  <DesktopResponsiveContainer>
    <InfoPane {...props} />
  </DesktopResponsiveContainer>
);

export default {
  Mobile: MobileInfoPane,
  Desktop: DesktopInfoPane,
};
