import addBanner from 'actions/banner/addBanner';
import types from 'actions/types';
import AlbertClient from 'api/AlbertClient';
import { ErrorMessages, NotificationTypes } from 'constants/index';
import * as WEB from 'types/interfaces';
import { onError } from 'utils/axiosMiddlewareUtils';
import getErrorMessageFromResponse from 'utils/getErrorMessageFromResponse';

export default (): WEB.ActionCreator<Promise<Record<string, any>>> =>
  async (dispatch: WEB.Dispatch): Promise<Record<string, any>> => {
    let error = false;

    const response: any = await dispatch({
      types: [
        types.GET_SIGNUP_LANGUAGE_REQUEST,
        types.GET_SIGNUP_LANGUAGE_SUCCESS,
        types.GET_SIGNUP_LANGUAGE_FAILURE,
      ],
      payload: {
        request: {
          method: 'get',
          url: AlbertClient.signupLanguageView(),
        },
        options: {
          onError: (middlewareOptions: any, options: any) => {
            error = true;
            // Display error banner only if we are not redirecting user to 2fa (403)
            if (middlewareOptions?.error?.response?.status !== 403) {
              const message =
                getErrorMessageFromResponse(
                  middlewareOptions?.error?.response
                ) || ErrorMessages.web.generic;
              dispatch(addBanner(NotificationTypes.WARNING, message, false));
            }

            return onError(middlewareOptions, options);
          },
        },
      },
    });
    return { error, response };
  };
