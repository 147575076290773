import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import getNextPage from 'actions/getNextPage';
import Button from 'components/common/Button';
import Text from 'components/common/Text';
import ButtonsLayout from 'components/layout/ButtonsLayout';
import PageBase from 'components/layout/PageBase';
import ArtMap from 'constants/ArtMap';
import Pages from 'constants/Pages';
import useNavigateFunction from 'hooks/useNavigateFunction';
import * as language from 'reducers/entities/language';
import { breakpoints } from 'styles';
import { spacers } from 'styles/home';
import * as WEB from 'types/interfaces';
import { getLanguageButtons, getLanguageSection } from 'utils/getFromLanguage';

type Props = {
  nextPage: string;
};

const StyledHeader = styled.h1`
  @media ${breakpoints.mobileLarge} {
    margin: 0;
  }
`;
const SubscriptionsNoted = ({ nextPage }: Props): React.ReactElement => {
  // Hooks.
  const { updateCurrentSignupPage } = useNavigateFunction();
  const dispatch = useDispatch();

  // Mapped state.
  const investingConfirmCtx = useSelector((state: WEB.RootState) =>
    language.getSignupPageLanguage(state, Pages.SUBSCRIPTIONS_NOTED)
  );

  // Language.
  const introSection = getLanguageSection(investingConfirmCtx, 'intro');
  const buttonsCtx = getLanguageButtons(investingConfirmCtx);

  // Handlers.
  const handleConfirmClick = async (): Promise<void> => {
    const { nextPageOverride }: any = await dispatch(getNextPage());
    dispatch(updateCurrentSignupPage({ page: nextPageOverride || nextPage }));
  };

  return (
    <PageBase>
      <StyledHeader>
        {introSection?.header}{' '}
        <img
          src={ArtMap(
            introSection?.image?.filename ? introSection?.image?.filename : ''
          )}
          alt='Thumbs up'
          width={introSection?.image?.width}
        />
      </StyledHeader>
      <Text className='description'>{introSection?.text}</Text>
      <ButtonsLayout
        marginTop={spacers.g9}
        primaryButton={
          <Button onClick={handleConfirmClick}>{buttonsCtx?.primary}</Button>
        }
      />
    </PageBase>
  );
};

export default SubscriptionsNoted;
