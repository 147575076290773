/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import getInstitutions from 'actions/async/getInstitutions';
import Button from 'components/common/Button';
import List from 'components/common/List';
import Spacer from 'components/common/Spacer';
import Text, { TextColor, TextSizes } from 'components/common/Text';
import ExternalLinkIcon from 'components/icons/ExternalLinkIcon';
import ButtonsContainer from 'components/layout/ButtonsContainer';
import PageBase from 'components/layout/PageBase';
import { HIDE_NAV_AND_BANNER } from 'constants/PageDisplayOptions';
import Pages from 'constants/Pages';
import SplashLink from 'constants/SplashLink';
import useNavigateFunction from 'hooks/useNavigateFunction';
import useVerifyCurrentPage from 'hooks/useVerifyCurrentPage';
import * as language from 'reducers/entities/language';
import { fontSizes, spacers } from 'styles/index';
import * as WEB from 'types/interfaces';
import { getLanguageButtons, getLanguageSection } from 'utils/getFromLanguage';

const StyledH1 = styled.h1`
  margin-top: 0;
`;

const StyledList = styled(List)`
  span:first-child {
    font-weight: 700;
  }
  > div:not(:last-child) {
    margin-bottom: ${spacers.tab};
  }
`;

const StyledText = styled(Text)`
  ${fontSizes.fontSize22}
`;

const ContinueButton = styled(Button)`
  width: 250px;
`;

type Props = {
  nextPage: string;
};

/**
 * DEPRECATED
 */
const BankSetup = (props: Props): React.ReactElement => {
  // Make Redux is up-to-date with currentSignUpPage
  useVerifyCurrentPage(Pages.BANK_SETUP);

  // ///////////////////////////////
  /* =========== HOOKS ========== */
  // ///////////////////////////////
  const dispatch = useDispatch();
  const { updateCurrentSignupPage } = useNavigateFunction();

  React.useEffect(() => {
    // Prefetch institutions for FindInstutition page.
    dispatch(getInstitutions());
  }, []);

  // ///////////////////////////////
  /* ======== MAPPED STATE ====== */
  // ///////////////////////////////
  const languageCtx = useSelector((state: WEB.RootState) =>
    language.getSignupPageLanguage(state, Pages.BANK_SETUP)
  );

  // //////////////////////////////////
  /* =========== HANDLERS ========== */
  // //////////////////////////////////

  // Handle user clicking on "Agree and continue"
  const handleOnNext = () => {
    dispatch(updateCurrentSignupPage({ page: props.nextPage }));
  };

  const createRedirectOnClickHandler = (url: string) =>
    React.useCallback(() => {
      window.open(url, '_blank');
    }, []);

  // //////////////////////////////////
  /* =========== LANGUAGE ========== */
  // //////////////////////////////////
  const INSTITUTION_KEY = 'institution';
  const COMMITTED_TO_SAFETY = 'committedToSafety';

  const institutionSection = getLanguageSection(languageCtx, INSTITUTION_KEY);
  const committedToSafetySection = getLanguageSection(
    languageCtx,
    COMMITTED_TO_SAFETY
  );

  const buttons = getLanguageButtons(languageCtx);

  // List component
  const listItems = committedToSafetySection?.list || [];

  const eConsentLink = SplashLink.E_SIGN_CONSENT;

  return (
    <>
      <PageBase>
        <StyledH1>{institutionSection?.header}</StyledH1>
        <Text>{institutionSection?.text}</Text>
        <Spacer space={spacers.tabLarge} />

        <StyledText weight='700'>{committedToSafetySection?.header}</StyledText>
        <Spacer space={spacers.tabSmall} />

        <StyledList
          items={listItems}
          type={WEB.ListType.List}
          textColor={TextColor.GRAY_DARK}
        />

        <Spacer space={spacers.tabLarge} />
        <Text color={TextColor.GRAY} size={TextSizes.TINY} inline>
          By continuing, you agree to&nbsp;
        </Text>
        <Text
          pointer
          inline
          underline
          size={TextSizes.TINY}
          color={TextColor.GRAY_DARK}
          weight='700'
          onClick={createRedirectOnClickHandler(eConsentLink)}
        >
          E-Sign Consent
        </Text>
        <Text color={TextColor.GRAY} size={TextSizes.TINY} inline>
          &nbsp;and authorize Albert to save money for your goals.&nbsp;
          <ExternalLinkIcon
            dark
            onClick={createRedirectOnClickHandler(eConsentLink)}
          />
        </Text>
        <Spacer space={spacers.small} />
        <ButtonsContainer>
          <ContinueButton id='bank-setup-confirm-button' onClick={handleOnNext}>
            {buttons?.primary}
          </ContinueButton>
        </ButtonsContainer>
      </PageBase>
    </>
  );
};

export default BankSetup;
