import types from 'actions/types';
import * as WEB from 'types/interfaces';

/**
 * Hide/unhide the banner.
 * Useful when we need to wait to show the banner
 * until some other events have completed.
 *
 * (e.g. Banners for request errors are added automatically
 * at a core level of the app. But at a page level, we might
 * have multiple requests running in parallel and need to wait
 * to show the banner until all requests are complete)
 *
 * @param hide - if true, hide the banner (default)
 *               if false, show the banner.
 */
export default (hide = true): WEB.Action => ({
  type: types.HIDE_BANNER,
  payload: { hide },
});
