import AlbertTrackingEvent from './AlbertTrackingEvent';

/* These events likely need to be rethought, as many from the  FB track API do not
 * exist in the Pixel track API.
 */
const FacebookEvent = {
  [AlbertTrackingEvent.SIGNED_UP]: {
    name: 'CompleteRegistration',
    custom: false,
  },
  [AlbertTrackingEvent.ADDED_ACCOUNT]: {
    name: 'AchievementUnlocked',
    custom: true,
  },
  [AlbertTrackingEvent.ENABLED_SAVINGS]: {
    name: 'LevelAchieved',
    custom: true,
  },
  [AlbertTrackingEvent.ENABLED_GENIUS]: { name: 'Purchase', custom: false },
  [AlbertTrackingEvent.ENABLED_GENIUS_ANNUAL]: {
    name: 'Purchase',
    custom: false,
  },
  [AlbertTrackingEvent.ENABLED_GENIUS_PRIMARY_CHECKING_GREATER_THAN_100]: {
    name: 'SpentCredits',
    custom: true,
  },
  [AlbertTrackingEvent.COMPLETED_INSTANT_ONBOARDING]: {
    name: 'TutorialCompletion',
    custom: true,
  },
  [AlbertTrackingEvent.COMPLETED_INVESTMENT_APPLICATION]: {
    name: 'AddToWishlist',
    custom: false,
  },
};

export default FacebookEvent;

/* 2021/06/14, MPR: something here is off. These event names seem strange,
 * and do not match the data I see in FB's dashboard. I will instead
 * use these events: https://developers.facebook.com/docs/facebook-pixel/reference#standard-events
 * mapping based on the map in Branch, at https://albert.dashboard.branch.io/ads/partner-management/a_facebook?tab=events
 * Follow Up: The FB events API is deprecated: https://developers.facebook.com/docs/app-events/getting-started-app-events-web
 * From that page: "Instead of using FB.AppEvents.LogEvent, we recommend that you send these events through the Facebook Pixel."
// https://developers.facebook.com/docs/app-events/reference#web
enum FacebookEvent {
  COMPLETED_REGISTRATION = 'EVENT_NAME_COMPLETED_REGISTRATION',
  UNLOCKED_ACHIEVEMENT = 'EVENT_NAME_UNLOCKED_ACHIEVEMENT',
  ACHIEVED_LEVEL = 'EVENT_NAME_ACHIEVED_LEVEL',
  PURCHASED = 'EVENT_NAME_PURCHASED',
  ADDED_PAYMENT_INFO = 'EVENT_NAME_ADDED_PAYMENT_INFO',
  ADDED_TO_CART = 'EVENT_NAME_ADDED_TO_CART',
  ADDED_TO_WISHLIST = 'EVENT_NAME_ADDED_TO_WISHLIST',
  SPENT_CREDITS = 'EVENT_NAME_SPENT_CREDITS',
}

export default FacebookEvent;
*/
