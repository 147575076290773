interface Config {
  Environment: string;
  IsLocal: boolean;
  SplashAppDomain: string;
  [otherOptions: string]: unknown;
}

export const getSplashUrl = (route: string, config: Config): string => {
  if (config.IsLocal && config.Environment !== 'production') {
    return `/splash/${route}`;
  }
  return `${config.SplashAppDomain}/${route}`;
};
