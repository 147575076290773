import * as React from 'react';
import styled from 'styled-components';
import { textTransition } from 'components/common/Text';

type Props = {
  color?: string;
};

const IconContainer = styled.div`
  path {
    ${textTransition}
  }
`;

const SquareCardFilledCheck = (props: Props): React.ReactElement => {
  const { color } = props;

  return (
    <IconContainer>
      <svg
        width='25'
        height='24'
        viewBox='0 0 25 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M23.3903 11.8032C23.3903 17.8407 18.4959 22.735 12.4585 22.735C6.42098 22.735 1.52664 17.8407 1.52664 11.8032C1.52664 5.76571 6.42098 0.871366 12.4585 0.871366C18.4959 0.871366 23.3903 5.76571 23.3903 11.8032Z'
          stroke={color || '#333333'}
          strokeWidth='1.45758'
          className='square-card-filled-check-stroke'
        />
        <path
          d='M17.0552 9.98603C17.3398 9.70142 17.3398 9.23998 17.0552 8.95537C16.7706 8.67076 16.3091 8.67076 16.0245 8.95537L17.0552 9.98603ZM11.5841 14.4265L11.0688 14.9418C11.2054 15.0785 11.3908 15.1552 11.5841 15.1552C11.7774 15.1552 11.9627 15.0785 12.0994 14.9418L11.5841 14.4265ZM9.47578 11.2875C9.19117 11.0029 8.72973 11.0029 8.44512 11.2875C8.16051 11.5721 8.16051 12.0335 8.44512 12.3182L9.47578 11.2875ZM16.0245 8.95537L11.0688 13.9111L12.0994 14.9418L17.0552 9.98603L16.0245 8.95537ZM12.0994 13.9111L9.47578 11.2875L8.44512 12.3182L11.0688 14.9418L12.0994 13.9111Z'
          fill={color || '#333333'}
        />
      </svg>
    </IconContainer>
  );
};

export default SquareCardFilledCheck;
