import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import setSelectedGeniusFrequency from 'actions/setSelectedGeniusFrequency';
import Button from 'components/common/Button';
import Modal from 'components/common/Modal';
import RectangleCard from 'components/common/RectangleCard';
import Spacer from 'components/common/Spacer';
import Text, { TextColor, TextSizes } from 'components/common/Text';
import { SavingsBonusLottie } from 'components/common/animations';
import ButtonsContainer from 'components/layout/ButtonsContainer';
import InfoPane from 'components/layout/InfoPane';
import PageBase from 'components/layout/PageBase';
import ComponentSize from 'constants/ComponentSize';
import GeniusSubscriptionBillingLengths from 'constants/GeniusSubscriptionBillingLengths';
import NotificationTypes from 'constants/NotificationTypes';
import Pages from 'constants/Pages';
import PaymentFrequency from 'constants/PaymentFrequency';
import useBrowserNavigationOverrideWithRedirect from 'hooks/useBrowserNavigationOverrideWithRedirect';
import useNavigateFunction from 'hooks/useNavigateFunction';
import * as language from 'reducers/entities/language';
import * as genius from 'reducers/ui/genius';
import { breakpoints, spacers } from 'styles';
import theme from 'styles/theme';
import * as WEB from 'types/interfaces';
import {
  getLanguageButtons,
  getLanguageInfo,
  getLanguageSection,
} from 'utils/getFromLanguage';
import renderTemplateContext from 'utils/renderTemplateContext';

type Props = {
  nextPage: string;
  /* are we in signup flow */
  fromSignup?: boolean;
};

const StyledRectangleCard = styled(RectangleCard)`
  .rectangle-card-body {
    font-size: initial;
    max-width: 130px;
  }
`;

const DesktopHeader = styled.h1`
  @media ${breakpoints.mobileLarge} {
    display: none;
  }
`;

/**
 * Flow for versions before aug_2022_annual_required
 */
const GeniusAnnualMonthly = ({
  nextPage,
  fromSignup = false,
}: Props): React.ReactElement => {
  // ///////////////////////////////
  /* =========== HOOKS ========== */
  // ///////////////////////////////
  const { updateCurrentGeniusPage } = useNavigateFunction();
  const dispatch = useDispatch();

  // /////////////////////////////////////
  /* =========== LOCAL STATE ========== */
  // /////////////////////////////////////
  const [showModal, setShowModal] = React.useState<boolean>(false);

  // /////////////////////////////////////
  /* =========== MAPPED STATE ========== */
  // /////////////////////////////////////
  const selectedPrice = useSelector(genius.getSelectedGeniusPrice);
  const geniusPayment = useSelector((state: WEB.RootState) =>
    genius.getSelectedGeniusFrequency(state)
  );

  const annualBonus = useSelector(genius.getAnnualBonus);
  const languageCtx = useSelector((state: WEB.RootState) => {
    return language.getGeniusPageLanguage(state, Pages.GENIUS_ANNUAL_MONTHLY);
  });

  // context for templating
  const pageContext = {
    selectedPrice,
    bonus: annualBonus,
  };

  // //////////////////////////////////
  /* =========== LANGUAGE ========== */
  // //////////////////////////////////
  const SELECT_YOUR_PLAN_KEY = 'selectYourPlan';
  const GENIUS_ANNUAL_MODAL = 'geniusAnnual';
  const GENIUS_MONTHLY_MODAL = 'geniusMonthly';

  const selectYourPlanSection = getLanguageSection(
    languageCtx,
    SELECT_YOUR_PLAN_KEY
  );
  const pageModal = languageCtx?.modal;
  const geniusAnnualModalLanguage = pageModal && pageModal[GENIUS_ANNUAL_MODAL];
  const geniusMonthlyModalLanguage =
    pageModal && pageModal[GENIUS_MONTHLY_MODAL];

  const infoPaneContext = getLanguageInfo(languageCtx);
  const buttonsCtx = getLanguageButtons(languageCtx);

  // //////////////////////////////////
  /* =========== HANDLERS ========== */
  // //////////////////////////////////

  const handleCardClick = (frequency: string) => {
    dispatch(setSelectedGeniusFrequency(frequency));
  };
  const handleMonthlyModalCancelClick = () => {
    setShowModal(false);
  };
  const handleContinueClick = () => {
    setShowModal(true);
  };
  const handleModalConfirm = () => {
    dispatch(updateCurrentGeniusPage({ page: nextPage, fromSignup }));
  };
  const handleBrowserButton = () => {
    dispatch(
      updateCurrentGeniusPage({
        page: Pages.DOWNLOAD_APP,
        fromSignup,
      })
    );
  };

  // //////////////////////////////////
  /* =========== LOTTIES ============ */
  // //////////////////////////////////
  const annualPaymentLottie = (
    <SavingsBonusLottie
      amount={annualBonus}
      gray={geniusPayment !== PaymentFrequency.ANNUAL}
      pause={showModal}
      width={300}
      height={100}
      textSize={TextSizes.SMALL}
      caption='CASH BONUS'
    />
  );
  const annualPaymentModalLottie = (
    <SavingsBonusLottie
      amount={annualBonus}
      gray={geniusPayment !== PaymentFrequency.ANNUAL}
      pause={!showModal}
      width={230}
      height={100}
      textSize={TextSizes.SMALL}
    />
  );

  // //////////////////////////////////
  /* =========== CARDS ========== */
  // //////////////////////////////////

  const geniusCards = selectYourPlanSection?.options?.map((card: any) => {
    const bodyText = renderTemplateContext(card?.text, pageContext);
    return (
      <StyledRectangleCard
        key={`card-${card.header as string}`}
        header={card?.header}
        body={bodyText}
        image={card?.image}
        altImage={card?.altImage}
        lottie={
          card?.value === PaymentFrequency.ANNUAL ? annualPaymentLottie : null
        }
        size={ComponentSize.MEDIUM}
        choice='single'
        type='genius'
        label={card?.labelText}
        value={card?.value}
        className={
          card?.value === GeniusSubscriptionBillingLengths.MONTHLY
            ? 'genius-pay-monthly'
            : 'genius-pay-annually'
        }
        selected={geniusPayment === card?.value}
        onClick={handleCardClick}
        spaceBetween
      />
    );
  });

  // //////////////////////////////////
  /* =========== MODALS ========== */
  // //////////////////////////////////
  const monthlyModal = geniusMonthlyModalLanguage ? (
    <Modal.Basic
      isGenius
      id='genius-monthly-modal'
      title={geniusMonthlyModalLanguage?.header || ''}
      iconType={NotificationTypes.WARNING}
      description={geniusMonthlyModalLanguage?.description || ''}
      submitLabel={geniusMonthlyModalLanguage?.submitLabel || ''}
      cancelLabel={geniusMonthlyModalLanguage?.cancelLabel || ''}
      onCancel={handleMonthlyModalCancelClick}
      onSubmit={handleModalConfirm}
      show={showModal}
    />
  ) : null;

  const annualModal = geniusAnnualModalLanguage ? (
    <Modal.Basic
      id='genius-annual-modal'
      title={geniusAnnualModalLanguage?.header || ''}
      description={renderTemplateContext(
        geniusAnnualModalLanguage?.description || '',
        pageContext
      )}
      submitLabel={geniusAnnualModalLanguage?.submitLabel || ''}
      customIcon={annualPaymentModalLottie}
      onCancel={handleMonthlyModalCancelClick}
      onSubmit={handleModalConfirm}
      show={showModal}
    />
  ) : null;

  /* 
    Enable overriding browser navigation when the genius payment frequency
    is "annual" and the "Bonus Time" modal is displayed. This happens when:
    1. User selects "annual"
    2. User clicks the "Continue" button
    3. The "Bonus Time" modal appears
    We override leaving the page at this point to ensure the user
    clicks "Continue" in the modal to confirm signing up for annual genius.
  */
  const enabled = geniusPayment === PaymentFrequency.ANNUAL && showModal;
  useBrowserNavigationOverrideWithRedirect(handleBrowserButton, enabled);

  return (
    <>
      <PageBase mobileHeader={selectYourPlanSection?.header} isGenius>
        <DesktopHeader>{selectYourPlanSection?.header}</DesktopHeader>
        <Text className='description'>
          {renderTemplateContext(
            selectYourPlanSection?.text || '',
            pageContext
          )}
        </Text>
        <Spacer space={spacers.small} />
        <Text size={TextSizes.TINY} color={TextColor.GRAY_DARK}>
          {selectYourPlanSection?.disclaimer}
        </Text>
        <Spacer space={spacers.small} />
        {geniusCards}
        <InfoPane.Mobile expanders={infoPaneContext.expanders} />
        <ButtonsContainer>
          <Button
            id='genius-annual-monthly-confirm'
            onClick={handleContinueClick}
            theme={theme.genius}
          >
            {buttonsCtx?.primary}
          </Button>
        </ButtonsContainer>
        {geniusPayment === PaymentFrequency.ANNUAL ? annualModal : monthlyModal}
      </PageBase>
    </>
  );
};

export default GeniusAnnualMonthly;
