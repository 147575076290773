import { ChevronRight } from '@styled-icons/heroicons-outline/ChevronRight';
import styled from 'styled-components';
import { mixins } from 'styles';

const StyledChevronRight = styled(ChevronRight)`
  color: ${({ theme }) => theme.colors.primaryAlbertBrand};
  width: ${mixins.pxToRem('45px')};
  height: ${mixins.pxToRem('35px')};
  display: inline-block;
`;

export default StyledChevronRight;
