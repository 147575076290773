import styled from 'styled-components';
import { breakpoints, spacers } from 'styles';
import { sizes } from 'styles/breakpoints';

type Props = {
  /* the amount of space for the margin */
  space?: string;
  noSpaceTop?: boolean;
  noSpaceBottom?: boolean;
  desktopOnly?: boolean;
  mobileOnly?: boolean;
};

const StyledDivider = styled.div.attrs(({ className }) => ({
  className: className || 'divider',
}))<Props>`
  width: 100%;
  height: 1px;
  border-bottom: thin solid ${({ theme }) => theme.colors.lightGray1};
  margin: ${({ space }) => space || spacers.tabLarge} 0;
  ${({ noSpaceTop }) => noSpaceTop && 'margin-top: 0;'};
  ${({ noSpaceBottom }) => noSpaceBottom && 'margin-bottom: 0;'};

  ${({ desktopOnly }) =>
    desktopOnly && `@media ${breakpoints.mobileLarge} { display: none; }`}

  ${({ mobileOnly }) =>
    mobileOnly && `@media (min-width: ${sizes.mobileLarge}) { display: none; }`}
`;

export default StyledDivider;
