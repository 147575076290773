import { SignupPages } from 'constants/Pages';

enum SignupGoals {
  BANK_ACCOUNT = 'bank_account',
  SAVE_MONEY = 'save_money',
  CASH_ADVANCE = 'cash_advance',
  PAY_OFF_DEBT = 'pay_off_debt',
  BUDGET = 'budget',
  INVESTING = 'investing',
  CANCEL_SUBSCRIPTIONS = 'cancel_subscriptions',
  BUDGET_AND_SAVE = 'budget_and_save',
  TRACK_SPENDING = 'track_spending',
}

export enum SignupGoalsType {
  BANK_GOALS = 'bank_goals',
  SETUP_GOALS = 'setup_goals',
}

export const BankGoalPageMap = {
  [SignupGoals.CASH_ADVANCE]: SignupPages.INSTANT_BENEFITS,
  [SignupGoals.CANCEL_SUBSCRIPTIONS]: SignupPages.CANCEL_SUBSCRIPTIONS_BENEFITS,
  [SignupGoals.INVESTING]: SignupPages.INVESTING_INTERESTS,
  [SignupGoals.BUDGET_AND_SAVE]: SignupPages.BUDGETING_BENEFITS,
};

export default SignupGoals;
