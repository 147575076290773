import * as React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Button from 'components/common/Button';
import Modal from 'components/common/Modal';
import ProgressDots from 'components/common/ProgressDots';
import Spacer from 'components/common/Spacer';
import Text, { TextColor } from 'components/common/Text';
import PageBase from 'components/layout/PageBase';
import ArtMap from 'constants/ArtMap';
import Pages from 'constants/Pages';
import useSwipe from 'hooks/useSwipe';
import * as language from 'reducers/entities/language';
import { theme } from 'styles';
import { spacers as homeSpacers } from 'styles/home';
import * as WEB from 'types/interfaces';
import { getLanguageButtons, getLanguageSection } from 'utils/getFromLanguage';
import { getRemainingPageHeight } from 'utils/layout';

type Props = {
  modalPages: React.ReactElement[];
  navigateToNextPage: () => void;
};

const ImageContainer = styled.div<{ isFirst: boolean }>`
  margin: 0 auto;
  width: 100%;
  img {
    margin: auto; // Necessary for Firefox
    display: block;
    height: 100%;
    max-width: 300px; // Firefox workaround for image expanding outside of grid
    ${
      ({ isFirst }) => isFirst && 'padding: 20px;' // All other images have built-in padding
    }
  }
`;

const StyledText = styled.span`
  font-size: 16px;
`;

const StyledDisclaimerText = styled(Text)`
  font-size: 14px;
`;

const MiddleContainer = styled.div`
  padding-top: 2em;
`;
const BottomContainer = styled.div`
  margin-top: auto;
`;

const StyledLearnMoreText = styled(Text)`
  font-size: 14px;
  margin-bottom: 24px;
`;

const Container = styled.div`
  display: grid;
  grid-template-rows: [row1-end] 25vh;
  // 35px comes from ContentPane padding
  height: calc(${getRemainingPageHeight()} - 35px);
  text-align: center;
  opacity: 1;
`;

const ButtonsContainer = styled.div`
  display: flex;
  margin-top: ${homeSpacers.g6};
  align-items: center;
  button {
    width: 100%;
  }
`;

/**
 * Tour page for a mobile device
 */
const GeniusTour = ({
  modalPages,
  navigateToNextPage,
}: Props): React.ReactElement => {
  // ///////////////////////////////
  /* ======== LOCAL STATE ======= */
  // ///////////////////////////////
  const [pageIndex, setPageIndex] = React.useState<number>(0);
  const [showModal, setShowModal] = React.useState(false);

  // ///////////////////////////////
  /* ======== MAPPED STATE ====== */
  // ///////////////////////////////
  const buttonRef = React.useRef<HTMLButtonElement>(null);

  const geniusLanguage = useSelector(language.getGeniusLanguage);

  const languageCtx = useSelector((state: WEB.RootState) => {
    return language.getGeniusPageLanguage(state, Pages.GENIUS_TOUR);
  });

  // //////////////////////////////////
  /* =========== HANDLERS ========== */
  // //////////////////////////////////
  const handleOnContinue = async () => {
    navigateToNextPage();
  };

  const handleOnClickLearnMore = () => {
    setShowModal(true);
  };
  const handleOnModalClose = () => {
    setShowModal(false);
  };

  const handleNextSlide = async () => {
    if (pageIndex < tourLanguage.length - 1) {
      if (buttonRef?.current) {
        buttonRef.current.blur();
      }
      setPageIndex(pageIndex + 1);
    }
  };
  const handlePreviousSlide = () => {
    if (pageIndex > 0) {
      setPageIndex(pageIndex - 1);
    }
  };

  // //////////////////////////////////
  /* =========== LANGUAGE ========== */
  // //////////////////////////////////
  // Sections
  const introSection = getLanguageSection(languageCtx, 'intro');
  const carouselSection = (geniusLanguage['tour'] || []) as WEB.TourElement[];
  const tourLanguage = [...carouselSection];
  // Override first item
  tourLanguage[0] = {
    ...tourLanguage[0],
    header: introSection?.header || '',
    text: introSection?.text || '',
    image: { filename: 'img_genius_logo_alt.png', width: 0 },
  };

  // Preload images
  for (const item of tourLanguage) {
    if (item.image) {
      const imageElement = new Image();
      imageElement.src = ArtMap(item.image.filename);
    }
  }

  // Buttons
  const buttonsCtx = getLanguageButtons(languageCtx);

  const item = tourLanguage[pageIndex];
  useSwipe({
    onSwipeLeft: handleNextSlide,
    onSwipeRight: handlePreviousSlide,
  });

  return (
    <PageBase>
      <Modal.Navigation
        id='genius-tour-nav-modal'
        defaultPage={pageIndex}
        show={showModal}
        onCancel={handleOnModalClose}
        bottomPad={35}
      >
        {modalPages}
      </Modal.Navigation>
      <Container>
        <ImageContainer isFirst={pageIndex == 0}>
          <img
            key={item.image.filename}
            src={ArtMap(item.image.filename)}
            alt=''
          />
        </ImageContainer>
        <MiddleContainer>
          <h2>{item.header}</h2>
          <StyledText>{item.text}</StyledText>
          <Spacer space={homeSpacers.g5} />
          {item.disclaimer && (
            <>
              <StyledDisclaimerText color={TextColor.GRAY} align='center'>
                {item.disclaimer}
              </StyledDisclaimerText>
              <Spacer space={homeSpacers.g5} />
            </>
          )}
          <StyledLearnMoreText
            weight='700'
            underline
            isLinkButton
            onClick={handleOnClickLearnMore}
            color={TextColor.LOGAN_GRAY}
          >
            Learn more
          </StyledLearnMoreText>
        </MiddleContainer>
        <BottomContainer>
          <ProgressDots length={tourLanguage.length} currentIndex={pageIndex} />
          <ButtonsContainer>
            <Button
              ref={buttonRef}
              onClick={handleOnContinue}
              theme={theme.genius}
            >
              {buttonsCtx?.primary}
            </Button>
          </ButtonsContainer>
        </BottomContainer>
      </Container>
    </PageBase>
  );
};

export default GeniusTour;
