import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import updateProfile from 'actions/async/updateProfile';
import removeBanner from 'actions/banner/removeBanner';
import TopLoadingBar from 'components/TopLoadingBar';
import Button from 'components/common/Button';
import List from 'components/common/List';
import Spacer from 'components/common/Spacer';
import Text, { TextColor, TextSizes } from 'components/common/Text';
import ButtonsContainer from 'components/layout/ButtonsContainer';
import PageBase from 'components/layout/PageBase';
import {
  LinkAccountCompleteLocationState,
  LinkAccountSkipLocationState,
} from 'components/linkAccount/LinkAccountFlow';
import { HIDE_NAV_AND_BANNER } from 'constants/PageDisplayOptions';
import Pages from 'constants/Pages';
import { PageRoutes, SplashLink } from 'constants/index';
import useNavigateFunction from 'hooks/useNavigateFunction';
import useVerifyCurrentPage from 'hooks/useVerifyCurrentPage';
import InvestingConfirm from 'pages/signup/InvestingConfirm';
import InvestingInfo from 'pages/signup/InvestingInfo';
import * as language from 'reducers/entities/language';
import { breakpoints, spacers } from 'styles';
import * as WEB from 'types/interfaces';
import {
  getLanguageButtons,
  getLanguageInfo,
  getLanguageSection,
} from 'utils/getFromLanguage';

// Page view states (InvestingBenefits | InvestingInfo | InvestingConfirm).
enum ViewState {
  INTRO = 'intro',
  INFO = 'info',
  CONFIRM = 'confirm',
}

type Props = {
  nextPage: string;
};

type LocationState =
  | (LinkAccountCompleteLocationState & LinkAccountSkipLocationState)
  | WEB.EmptyObject;

const StyledLink = styled(Text)`
  margin-right: ${spacers.tabLarge};
  @media ${breakpoints.mobileLarge} {
    margin-right: 0;
  }
`;

const DesktopOnlyHeader = styled.h1`
  @media ${breakpoints.mobileLarge} {
    display: none;
  }
`;

const StyledSubheader = styled.h3`
  @media ${breakpoints.mobileLarge} {
    margin: 0;
  }
`;

const InvestingBenefits = (props: Props): React.ReactElement => {
  const { updateCurrentSignupPage } = useNavigateFunction();
  // Make Redux is up-to-date with currentSignUpPage
  useVerifyCurrentPage(Pages.INVESTING_BENEFITS);

  // ///////////////////////////////
  /* =========== HOOKS ========== */
  // ///////////////////////////////
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const locationState = (location?.state || {}) as LocationState;

  // ///////////////////////////////
  /* =========== STATE ========== */
  // ///////////////////////////////
  const [showTopLoading, setShowTopLoading] = React.useState<boolean>(false);

  // State to manage the current view (InvestingBenefits | InvestingInfo | InvestingConfirm).
  const [currentView, setCurrentView] = React.useState<ViewState>(
    // If user just exited the Link Account flow, then initialize to show the InvestingInfo page.
    locationState.showConfirmation ? ViewState.INFO : ViewState.INTRO
  );

  React.useEffect(() => {
    // Trigger a render whenever showConfirmation changes.
    // Otherwise, when navigating backwards from INFO -> INTRO,
    // the url changes correctly but the app still displays INFO
    setCurrentView((): ViewState => {
      if (locationState.showConfirmation) {
        return ViewState.INFO;
      }
      // Show the confirm page if user skipped link account flow.
      if (locationState.skippedLinkAccount) {
        return ViewState.CONFIRM;
      }
      return ViewState.INTRO;
    });
  }, [locationState.showConfirmation, locationState.skippedLinkAccount]);

  // ///////////////////////////////
  /* ======== MAPPED STATE ====== */
  // ///////////////////////////////
  const languageCtx = useSelector((state: WEB.RootState) =>
    language.getSignupPageLanguage(state, Pages.INVESTING_BENEFITS)
  );

  const handleOnLaterClick = async (): Promise<void> => {
    // Remove any error banner from previous attempts
    dispatch(removeBanner());
    setShowTopLoading(true);

    // Make request to indicate user is not interested in investing at the moment.
    const { error }: any = await dispatch(
      updateProfile({
        investment_info: { signup_investing_interest: false },
      })
    );

    setShowTopLoading(false);

    // Take user to next view.
    if (!error) {
      dispatch(updateCurrentSignupPage({ page: props.nextPage }));
    }
  };

  const handleOnContinueClick = (): void => {
    // Navigate to the Link Account flow for the user to link their bank account with Albert.
    navigate(PageRoutes.LINK_ACCOUNT, {
      state: {
        pageOnComplete: Pages.INVESTING_BENEFITS,
        pageOnSkip: Pages.INVESTING_BENEFITS,
        currentPage: Pages.INVESTING_BENEFITS,
      },
    });
  };

  const handleOnInfoComplete = (): void => {
    // Take user to InvestigConfirm page.
    setCurrentView(ViewState.CONFIRM);
  };

  // ///////////////////////////////
  /* ========== LANGUAGE ======== */
  // ///////////////////////////////
  const INTRO_KEY = 'intro';
  const BENEFITS_KEY = 'benefits';

  const infoPaneContext = getLanguageInfo(languageCtx);
  const introSection = getLanguageSection(languageCtx, INTRO_KEY);
  const benefitsSection = getLanguageSection(languageCtx, BENEFITS_KEY);

  const buttonSection = getLanguageButtons(languageCtx);

  const investingBenefitsIntroPage = (
    <>
      <TopLoadingBar isLoading={showTopLoading} />
      <PageBase
        mobileHeader={infoPaneContext?.header || ''}
        buttonSpace='small'
      >
        <DesktopOnlyHeader>{introSection?.header}</DesktopOnlyHeader>
        <div className='description'>
          <Text>{introSection?.text}</Text>
        </div>
        <Spacer space={spacers.tab} />
        <StyledSubheader>{benefitsSection.header}</StyledSubheader>
        <List
          items={benefitsSection?.list || []}
          type={WEB.ListType.List}
          itemSpacing={spacers.tabSmall}
        />
        <Spacer space={spacers.tabSmall} />
        <Text size={TextSizes.TINY} color={TextColor.GRAY}>
          Brokerage by Albert Securities LLC. View important{' '}
          <a
            href={`${SplashLink.DISCLOSURE_LIBRARY}${HIDE_NAV_AND_BANNER}`}
            target='_blank'
            rel='noreferrer'
          >
            <b>disclosures</b>
          </a>
          <b>.</b>
        </Text>
        <ButtonsContainer>
          <div className='secondary-button'>
            <StyledLink
              nowrap
              pointer
              color={TextColor.BLACK}
              weight='700'
              size={TextSizes.SMALL}
              underline
              onClick={handleOnLaterClick}
              isLinkButton
            >
              {buttonSection.link}
            </StyledLink>
          </div>
          <Button stretch onClick={handleOnContinueClick}>
            {buttonSection.primary}
          </Button>
        </ButtonsContainer>
      </PageBase>
    </>
  );

  switch (currentView) {
    case ViewState.INFO:
      return <InvestingInfo onComplete={handleOnInfoComplete} />;
    case ViewState.CONFIRM:
      return <InvestingConfirm nextPage={props.nextPage} />;
    default:
      return investingBenefitsIntroPage;
  }
};

export default InvestingBenefits;
