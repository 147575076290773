import confetti from 'canvas-confetti';
import * as React from 'react';
import useWindowSize from 'hooks/useWindowSize';
import { widths } from 'styles/breakpoints';

interface Options {
  particleCount: number;
  scalar: number;
  gravity?: number;
  originX?: number;
  originY?: number;
  angle?: number;
}

const launchConfetti = ({
  particleCount,
  scalar,
  gravity,
  originX,
  originY,
  angle,
}: Options) => {
  confetti({
    // Launch straight down
    angle: typeof angle !== 'undefined' ? angle : 270,
    particleCount,
    spread: 120,
    scalar,
    colors: ['#80b3ff', '#d9e8ff', '#ffba0d', '#003d99'],
    // Higher frequency of squares
    shapes: ['square', 'square', 'square', 'circle'],
    origin: {
      // Launch from the top of the page. 0 isn't quite high enough
      y: typeof originY !== 'undefined' ? originY : -0.1,
      x: typeof originX !== 'undefined' ? originX : 0.5,
    },
    // A11Y: Disable confetti for users who prefer reduced motion
    disableForReducedMotion: true,
    ticks: 200,
    decay: 0.85,
    gravity: typeof gravity !== 'undefined' ? gravity : 1,
  });
};

const useConfetti = (): void => {
  const hasLaunched = React.useRef(false);
  const { width } = useWindowSize();

  React.useEffect(() => {
    if (hasLaunched.current || !width) return;

    if (width > widths.desktopSmallMedium) {
      const desktopOptions = { scalar: 2, gravity: 1.1 };
      launchConfetti({ particleCount: 50, ...desktopOptions });
      // Launch two more bursts on both sides of first burst for a wide reach
      launchConfetti({
        particleCount: 25,
        angle: 245,
        originX: 0.35,
        originY: -0.05,
        ...desktopOptions,
      });
      launchConfetti({
        particleCount: 25,
        angle: 295,
        originX: 0.65,
        originY: -0.05,
        ...desktopOptions,
      });
    } else {
      const mobileOptions = { particleCount: 60, scalar: 1.5 };
      // Launch two bursts off-center for a wide reach
      launchConfetti({ angle: 260, originX: 0.25, ...mobileOptions });
      launchConfetti({ angle: 280, originX: 0.75, ...mobileOptions });
    }

    hasLaunched.current = true;

    return () => {
      confetti.reset();
    };
  }, [width]);
};

export default useConfetti;
