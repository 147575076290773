import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import TrackingBase from 'tracking.Base';
import createSavingsAccount from 'actions/async/createSavingsAccount';
import getAppData from 'actions/async/getAppData';
import addBanner from 'actions/banner/addBanner';
import removeBanner from 'actions/banner/removeBanner';
import AlbertClient from 'api/AlbertClient';
import Button from 'components/common/Button';
import Input from 'components/common/Input';
import { InputTypes } from 'components/common/Input/TextInput';
import { InputWidth } from 'components/common/Input/shared';
import Spacer from 'components/common/Spacer';
import Text, { TextColor, TextSizes } from 'components/common/Text';
import Tooltip from 'components/common/Tooltip';
import QuestionCircleIcon from 'components/icons/QuestionCircleIcon';
import ButtonsContainer from 'components/layout/ButtonsContainer';
import PageBase from 'components/layout/PageBase';
import {
  AlbertTrackingEvent,
  ErrorMessages,
  NotificationTypes,
  ResponseStatus,
} from 'constants/index';
import { infoPaneContext } from 'pages/manual_auth/ManualAuthFlow';
import * as appData from 'reducers/entities/appData';
import { breakpoints, colors, spacers } from 'styles';
import { sizes } from 'styles/breakpoints';
import * as WEB from 'types/interfaces';
import { delayWithState } from 'utils/delay';
import { logger } from 'utils/logger';
import wrappedFetch from 'utils/wrappedFetch';

type Props = {
  /* account id */
  accountId: string;
  /* account balance */
  accountBalance: number;
  /* handle user selecting to skip manual auth */
  onSkip: () => void;
  /* handle user completing manual auth */
  onComplete: () => void;
};

const ACCOUNT_NUM_MIN_LENGTH = 6;

const TooltipContainer = styled.div`
  display: inline-flex;
  align-items: center;
  cursor: help;
  @media (min-width: ${sizes.mobileLarge}) {
    margin-bottom: ${spacers.small};
  }

  &:hover {
    span,
    svg {
      color: ${({ theme }) => theme.colors.primaryAlbertBrand};
    }
  }

  svg {
    width: 13px;
  }
`;

const StyledNumberInputWrapper = styled.div`
  @media ${breakpoints.mobileLarge} {
    div {
      width: 100%;
    }
  }
`;

const ManualAuth = (props: Props): React.ReactElement => {
  const { accountId, accountBalance, onSkip, onComplete } = props;
  // ///////////////////////////////////////
  /* ============= CONSTANTS ============ */
  // ///////////////////////////////////////
  const VALID_ROUTING_LENGTH = 9;
  const MIN_BALANCE_REQUIRED = 10; // min balance required for manual auth

  // ///////////////////////////////////////
  /* =============== HOOKS ============== */
  // ///////////////////////////////////////
  const dispatch = useDispatch();

  // ///////////////////////////////////////
  /* ============= LOCAL STATE ========== */
  // ///////////////////////////////////////
  const [accountNum, setAccountNum] = React.useState('');
  const [verifyAccountNum, setVerifyAccountNum] = React.useState('');
  const [routingNum, setRoutingNum] = React.useState('');
  const [insName, setInsName] = React.useState('');

  // Button State
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isCompleted, setIsCompleted] = React.useState<boolean>(false);

  // Error states
  const [routingErr, setRoutingErr] = React.useState('');
  const [accountNumErr, setAccountNumErr] = React.useState(false);
  const [verifyAccountNumErr, setVerifyAccountNumErr] = React.useState(false);
  const [mismatchAccountNumErr, setMismatchAccountNumErr] =
    React.useState(false);

  // ///////////////////////////////////////
  /* =============== ASYNC ============== */
  // ///////////////////////////////////////
  const verifyRouting = (routing_number: string) => {
    const queryParams = new URLSearchParams({ routing_number });
    const verifyRoutingEndpoint = AlbertClient.verifyRoutingView(queryParams);

    // Async call to fetch and store verified routing
    wrappedFetch(verifyRoutingEndpoint)
      .then((res) => res.json())
      .then((data) => {
        // eslint-disable-next-line
        const { status, bank_name } = data || {};
        if (status === ResponseStatus.ERROR) {
          setRoutingErr(ErrorMessages.input.validRoutingNum);
        } else {
          setInsName(bank_name);
        }
      })
      .catch((err) => {
        logger.log(`error verifying: ${err}`);
        // TODO: handle error
      });
  };

  // ///////////////////////////////////////
  /* ============= HANDLERS ============= */
  // ///////////////////////////////////////
  const validateInput = (): boolean => {
    let inError = false;

    // Validation
    if (!accountNum || accountNum.length < ACCOUNT_NUM_MIN_LENGTH) {
      inError = true;
      setAccountNumErr(true);
    }

    if (!verifyAccountNum) {
      inError = true;
      setVerifyAccountNumErr(true);
    }

    if (accountNum !== verifyAccountNum) {
      inError = true;
      setMismatchAccountNumErr(true);
    }

    // NOTE: routing errors are strings instead of booleans because there are two
    // variations of this error msg: (1) invalid routing # (returned from BE)
    // (2) invalid routing # (length is not 9 numbers)
    if (routingNum.length !== VALID_ROUTING_LENGTH) {
      inError = true;
      setRoutingErr(ErrorMessages.input.validRoutingNum);
    }

    // Only proceed to create account if user's account has min balance
    if (accountBalance < MIN_BALANCE_REQUIRED) {
      inError = true;
      const errorMessage = `You must have at least $${MIN_BALANCE_REQUIRED} in your bank account in order to connect with Albert.`;
      // Dispatch warning banner that does not dismiss automatically
      dispatch(addBanner(NotificationTypes.WARNING, errorMessage));
    }

    return inError;
  };

  const handleOnNext = async () => {
    // Remove any error banner from previous attempts
    dispatch(removeBanner());

    // Button loading state
    setIsSubmitting(true);

    // Validate user input
    const hasValidationError = validateInput();
    if (hasValidationError || routingErr) {
      setIsSubmitting(false);
      return;
    }

    // Create manual savings account
    const payload = {
      setup_type: 'manual',
      account_id: accountId,
      account_number: accountNum,
      routing_number: routingNum,
    };
    const { error, response }: any = await dispatch(
      createSavingsAccount(payload)
    );
    const { status, message } = response?.payload?.data || {};

    if (status === ResponseStatus.ERROR) {
      setIsSubmitting(false);
      // Display error if create savings request failed
      dispatch(addBanner(NotificationTypes.WARNING, message, true));
      return;
    }
    if (error) {
      setIsSubmitting(false);
      return;
    }

    TrackingBase.track({
      event: AlbertTrackingEvent.ENABLED_SAVINGS,
      trackSocial: true,
      trackOnce: true,
    });

    // Load app data async because enabling savings affects missions
    dispatch(getAppData());

    setIsSubmitting(false);
    await delayWithState(400, setIsCompleted);

    // Move on to next page
    onComplete();
  };

  const handleRoutingOnChange = (value: Record<string, any>) => {
    const valueAsStr = value.target.value;

    // Reset error state if applicable
    if (routingErr) {
      setRoutingErr('');
    }

    // Reset ins name if inputting new routing number
    if (insName) {
      setInsName('');
    }

    if (valueAsStr.length === VALID_ROUTING_LENGTH) {
      verifyRouting(valueAsStr);
    }

    setRoutingNum(valueAsStr);
  };

  const handleAccountOnChange = (value: Record<string, any>) => {
    // Reset error state if applicable
    if (mismatchAccountNumErr) setMismatchAccountNumErr(false);
    if (accountNumErr) setAccountNumErr(false);
    setAccountNum(value.target.value);
  };

  const handleVerifyAccountOnChange = (value: Record<string, any>) => {
    // Reset error state if applicable
    if (mismatchAccountNumErr) setMismatchAccountNumErr(false);
    if (verifyAccountNumErr) setVerifyAccountNumErr(false);
    setVerifyAccountNum(value.target.value);
  };

  // ///////////////////////////////////////
  /* ============= LANGUAGE ========== */
  // ///////////////////////////////////////
  const mismatchAccountNumErrText =
    'The account number you entered does not match the original account number you entered. Please update the account number to continue.';
  const accountToVerify = useSelector(
    (state: WEB.RootState) =>
      appData.getAccountById(state, accountId) as WEB.InstitutionAccount
  );
  const pageHeader = accountToVerify
    ? `Verify your ${accountToVerify.short_name} ${accountToVerify.account_display_number} account`
    : '';

  return (
    <PageBase
      mobileHeader={pageHeader ? pageHeader : infoPaneContext.header}
      mobileLead={[
        infoPaneContext?.text
          ? infoPaneContext?.text[1]
          : `Enter your bank account and routing numbers. Find these at the bottom of your checks, or
      on your bank's website.`,
      ]}
    >
      <StyledNumberInputWrapper>
        <Input.Text
          id='routing-number'
          type={InputTypes.TEXT}
          inputMode='numeric'
          pattern='[0-9]*'
          invalid={!!routingErr}
          errorText={routingErr}
          label='Enter your routing number'
          description='Please enter your routing number to connect to Albert Savings.'
          hideDescriptionOnMobile
          placeholder='Routing number'
          floatingLabel='Routing number'
          onChange={handleRoutingOnChange}
          widthSize={InputWidth.SMALL}
        />
      </StyledNumberInputWrapper>
      <Spacer space={spacers.tiny} />
      {insName && (
        <Text size={TextSizes.SMALL} color={TextColor.BLUE} weight='400'>
          {insName}
        </Text>
      )}
      <Spacer space={spacers.tiny} />
      <Tooltip id='where-is-this-tooltip' />
      <TooltipContainer
        data-tip="Find these at the bottom of your checks, or on your bank's website."
        data-for='where-is-this-tooltip'
      >
        <QuestionCircleIcon color={colors.darkGray1} />
        <Spacer space={spacers.tiny} orientation='horizontal' />
        <Text
          weight='700'
          size={TextSizes.SMALL}
          underline
          color={TextColor.GRAY_DARK}
        >
          Where is this?
        </Text>
      </TooltipContainer>
      <StyledNumberInputWrapper>
        <Input.Text
          id='account-number'
          inputMode='numeric'
          pattern='[0-9]*'
          label='Enter your account number'
          // TODO: will need to make "Albert [product]" dynamic since this flow will be used elsewhere
          description='Please enter your account number to connect to Albert Savings.'
          hideDescriptionOnMobile
          invalid={mismatchAccountNumErr || accountNumErr}
          errorText={
            mismatchAccountNumErr ? '' : ErrorMessages.input.validAccountNum
          }
          placeholder='Account number'
          floatingLabel='Account number'
          onChange={handleAccountOnChange}
          widthSize={InputWidth.SMALL}
        />
      </StyledNumberInputWrapper>
      <Spacer space={spacers.tiny} desktopOnly />
      <Spacer space={spacers.submicroscopic} desktopOnly />
      <StyledNumberInputWrapper>
        <Input.Text
          id='verify-account-number'
          inputMode='numeric'
          pattern='[0-9]*'
          invalid={mismatchAccountNumErr || verifyAccountNumErr}
          errorText={
            mismatchAccountNumErr
              ? mismatchAccountNumErrText
              : ErrorMessages.input.validAccountNum
          }
          placeholder='Verify account number'
          floatingLabel='Verify account number'
          onChange={handleVerifyAccountOnChange}
          widthSize={InputWidth.SMALL}
        />
      </StyledNumberInputWrapper>
      <ButtonsContainer>
        <Text
          weight='700'
          size={TextSizes.SMALL}
          underline
          color={TextColor.GRAY_DARK}
          onClick={onSkip}
          isLinkButton
        >
          Skip for now
        </Text>
        <Spacer space={spacers.tab} orientation='horizontal' desktopOnly />
        <Spacer space={spacers.tabSmall} mobileOnly />
        <Button
          id='manual-auth-confirm'
          isCompleted={isCompleted}
          isLoading={isSubmitting}
          disabled={isSubmitting}
          onClick={handleOnNext}
        >
          {isCompleted ? null : 'Continue'}
        </Button>
      </ButtonsContainer>
    </PageBase>
  );
};

export default ManualAuth;
