import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import finishSignupFlow from 'actions/async/finishSignupFlow';
import AlbertLogo from 'components/common/AlbertLogo';
import DownloadTheAppButton from 'components/common/DownloadTheAppButton';
import Text from 'components/common/Text';
import PageBase from 'components/layout/PageBase';
import AppDownloadLink from 'constants/AppDownloadLink';
import ArtMap, { Art } from 'constants/ArtMap';
import useBrowserNavigationOverride from 'hooks/useBrowserNavigationOverride';
import * as profile from 'reducers/entities/profile';
import * as app from 'reducers/ui/app';
import { breakpoints } from 'styles';
import * as WEB from 'types/interfaces';

const Container = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px;
  min-height: 100vh;
`;

const LogoWrapper = styled.div`
  padding-top: 49px;
  flex-basis: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ContentWrapper = styled.div`
  flex: 1;

  > div {
    padding-top: 0;
  }
`;

const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 32px 24px 64px;
`;

const Title = styled.h5`
  font-size: 1.7rem;
  font-weight: bold;
  margin: 0;
  padding: 0 0 1.2em 0;
  width: 100%;
  text-align: center;
`;

const DownloadTheAppButtonContainer = styled.div`
  max-width: 325px;
  width: 100%;
  margin-bottom: 56px;

  @media ${breakpoints.mobileLarge} {
    margin-bottom: 43px;
  }
`;

const Footer = styled.div`
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 64px 32px;
`;

const FooterContent = styled.div`
  display: block;
`;

const Disclaimer = styled.p`
  display: inline-block;
  color: ${({ theme }) => theme.colors.darkGray1};
  font-weight: normal;
  font-size: 0.75rem;
  text-align: center;
  max-width: 660px;
  margin: 0 auto;
`;

const SplashImage = styled.img`
  max-width: 250px;

  @media (min-width: 768px) {
    max-width: 325px;
  }
`;

const AppButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 43px;

  @media ${breakpoints.mobileLarge} {
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 57px;
  }
`;

const StyledAppStoreIcon = styled.img`
  height: 36px;
  cursor: pointer;

  &:not(:last-of-type) {
    margin-right: 10px;
  }
`;

const DownloadApp = (): React.ReactElement | null => {
  // Hooks
  const dispatch = useDispatch();

  // Mapped state
  const personalInfo = useSelector((state: WEB.RootState) =>
    profile.getValueByField(state, 'personal_info')
  );

  useBrowserNavigationOverride(() => {
    // Silently block backwards navigation
  });

  const didDownloadApp = useSelector(profile.getDidDownloadApp);
  const didInitializeApp = useSelector(app.getInitializedApp);

  // Effects
  React.useEffect(() => {
    if (!personalInfo?.finished_web_signup) {
      dispatch(finishSignupFlow());
    }
  }, [personalInfo]);

  // Handlers
  const handleGoAppStore = () => {
    window.open(AppDownloadLink.IOS, '_blank');
  };

  const handleGoPlayStore = () => {
    window.open(AppDownloadLink.ANDROID, '_blank');
  };

  // Redirect to albert.com if user has downloaded app.
  if (didDownloadApp) {
    window.location.href = window.albertWeb.SplashAppDomain;
    return null;
  }

  const pageContent = (
    <Container>
      <LogoWrapper>
        <AlbertLogo />
      </LogoWrapper>
      <ContentWrapper>
        <PageBase maxWidth='100%'>
          <PageContent>
            <Title>
              Account created!
              <br />
              Now, download the app.
            </Title>
            <DownloadTheAppButtonContainer>
              <DownloadTheAppButton />
            </DownloadTheAppButtonContainer>
            <SplashImage src={ArtMap(Art.ConfettiPhone)} />
          </PageContent>
        </PageBase>
      </ContentWrapper>
      <Footer>
        <FooterContent>
          <AppButtonsContainer>
            <StyledAppStoreIcon
              onClick={handleGoAppStore}
              src={ArtMap(Art.AppleAppStoreBanner)}
            />
            <StyledAppStoreIcon
              onClick={handleGoPlayStore}
              src={ArtMap(Art.AndroidPlayStoreBanner)}
            />
          </AppButtonsContainer>
          <Disclaimer>
            <Text color='gray-dark' weight='700' inline>
              Albert is not a bank. Banking services provided by Sutton Bank,
              Member FDIC.
            </Text>{' '}
            Albert Savings accounts are held for your benefit at FDIC-insured
            banks, including Coastal Community Bank and Wells Fargo, N.A. The
            Albert Mastercard® debit card is issued by Sutton Bank, Member FDIC,
            pursuant to a license by Mastercard International Incorporated.
            Mastercard and the circles design are registered trademarks of
            Mastercard International Incorporated.
          </Disclaimer>
        </FooterContent>
      </Footer>
    </Container>
  );

  return didInitializeApp ? pageContent : null;
};

export default DownloadApp;
