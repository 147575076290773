import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import PillBadge from 'components/common/Badge/PillBadge';
import Button from 'components/common/Button';
import { MobileWrapper } from 'components/common/DeviceWrapper';
import Spacer from 'components/common/Spacer';
import ButtonsLayout from 'components/layout/ButtonsLayout';
import LeadContent from 'components/layout/LeadContent';
import PageBase from 'components/layout/PageBase';
import ArtMap from 'constants/ArtMap';
import Pages from 'constants/Pages';
import SignupGoals from 'constants/SignupGoals';
import useNavigateFunction from 'hooks/useNavigateFunction';
import useVerifyCurrentPage from 'hooks/useVerifyCurrentPage';
import * as appData from 'reducers/entities/appData';
import * as language from 'reducers/entities/language';
import { breakpoints } from 'styles';
import { spacers } from 'styles/home';
import * as WEB from 'types/interfaces';
import {
  getLanguageAltInfo,
  getLanguageButtons,
  getLanguageInfo,
  getLanguageSection,
} from 'utils/getFromLanguage';

type Props = {
  nextPage: string;
};

const ImgMobileWrapper = styled(MobileWrapper)`
  @media ${breakpoints.mobileLarge} {
    display: flex;
    height: 300px;
    img {
      margin: auto;
      width: 300px;
    }
  }
`;

const CashValueProp = (props: Props) => {
  const dispatch = useDispatch();
  const { updateCurrentSignupPage } = useNavigateFunction();
  // Make sure Redux is up-to-date with currentSignUpPage
  useVerifyCurrentPage(Pages.CASH_VALUE_PROP);

  const languageCtx = useSelector((state: WEB.RootState) =>
    language.getSignupPageLanguage(state, Pages.CASH_VALUE_PROP)
  );

  const hasInstantGoal = useSelector((state: WEB.RootState) =>
    appData.hasSetupGoal(state, SignupGoals.CASH_ADVANCE)
  );

  const defaultInfoCtx = getLanguageInfo(languageCtx);
  const altInfoCtx = getLanguageAltInfo(languageCtx);

  const defaultCtx = getLanguageSection(languageCtx, 'default');
  const instantCtx = getLanguageSection(languageCtx, 'instant');
  const buttonsCtx = getLanguageButtons(languageCtx);

  const showInstantContent = hasInstantGoal && !!Object.keys(instantCtx).length;

  const infoCtx = showInstantContent ? altInfoCtx : defaultInfoCtx;
  const introCtx = showInstantContent ? instantCtx : defaultCtx;

  const onSubmit = async () => {
    dispatch(updateCurrentSignupPage({ page: props.nextPage }));
  };

  return (
    <PageBase>
      <ImgMobileWrapper>
        <img src={ArtMap(infoCtx?.image?.filename || '')} alt='' />
      </ImgMobileWrapper>
      <LeadContent
        header={introCtx?.header || ''}
        text={introCtx?.text || ''}
        desktopHeaderSize='small'
        betweenComponent={
          (introCtx?.labelText && (
            <MobileWrapper>
              <Spacer space={spacers.g2} />
              <PillBadge>{introCtx.labelText}</PillBadge>
              <Spacer space={spacers.g4} />
            </MobileWrapper>
          )) ||
          null
        }
      />
      <ButtonsLayout
        marginTop={spacers.g9}
        primaryButton={
          <Button onClick={onSubmit}>{buttonsCtx?.primary}</Button>
        }
      />
    </PageBase>
  );
};

export default CashValueProp;
