import { FORM_ERROR } from 'final-form';
import * as React from 'react';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import updateProfile, {
  UpdateProfileFields,
} from 'actions/async/updateProfile';
import hideBanner from 'actions/banner/hideBanner';
import removeBanner from 'actions/banner/removeBanner';
import Button from 'components/common/Button';
import Dropdown from 'components/common/Dropdown';
import { DropdownWidthSize } from 'components/common/Dropdown/Dropdown';
import {
  DescriptionWrapper,
  Label,
  LabelWrapper,
} from 'components/common/Input/shared/index';
import Spacer from 'components/common/Spacer';
import Text, { TextColor, TextSizes } from 'components/common/Text';
import ButtonsContainer from 'components/layout/ButtonsContainer';
import PageBase from 'components/layout/PageBase';
import InfluencerTypeahead from 'components/signup/InfluencerTypeahead';
import Pages from 'constants/Pages';
import useNavigateFunction from 'hooks/useNavigateFunction';
import useVerifyCurrentPage from 'hooks/useVerifyCurrentPage';
import * as language from 'reducers/entities/language';
import * as profile from 'reducers/entities/profile';
import { breakpoints, spacers } from 'styles';
import * as WEB from 'types/interfaces';
import { DropdownItem } from 'types/interfaces';
import { delayWithState } from 'utils/delay';
import { getLanguageButtons, getLanguageSection } from 'utils/getFromLanguage';
import { composeValidators, isRequired } from 'utils/validators';

type Props = {
  nextPage: string;
};

const StyledDropdown = styled(Dropdown.Menu)`
  width: 55%;
  @media ${breakpoints.mobileLarge} {
    width: 100%;
  }
`;

const HiddenField = styled.input`
  display: none;
`;

// Constants.
const INFLUENCER_SOURCE = 'influencer';

const Source = (props: Props): React.ReactElement => {
  const { updateCurrentSignupPage } = useNavigateFunction();
  // Make Redux is up-to-date with currentSignUpPage
  useVerifyCurrentPage(Pages.SOURCE);

  // ///////////////////////////////
  /* =========== STATE ========== */
  // ///////////////////////////////
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [isCompleted, setIsCompleted] = React.useState<boolean>(false);
  const [source, setSource] = React.useState('');
  const [selectedInfluencer, setSelectedInfluencer] =
    React.useState<WEB.InfluencerOption | null>(null);

  const [hasSubmittedInfluencerSource, setHasSubmittedInfluencerSource] =
    React.useState(false);
  const [hasSubmitFailed, setHasSubmitFailed] = React.useState(false);

  // ///////////////////////////////
  /* ======== MAPPED STATE ====== */
  // ///////////////////////////////
  const languageCtx = useSelector((state: WEB.RootState) =>
    language.getSignupPageLanguage(state, Pages.SOURCE)
  );

  const sourceDefault = useSelector(
    (state: WEB.RootState) =>
      profile.getInfoValueByField(state, 'personal_info', 'signup_source') || ''
  );

  const personalInfo: Record<string, any> = useSelector(
    (state: WEB.RootState) => profile.getValueByField(state, 'personal_info')
  );

  React.useEffect(() => {
    setSource(sourceDefault);
  }, [sourceDefault]);

  React.useEffect(() => {
    // Check if user has already submitted an influencer as their signup source.
    // If so, hide the optional influencer typeahead input field.
    // NOTE: Do this onMount so that on submit, the influencer field doesn't disappear.
    setHasSubmittedInfluencerSource(
      personalInfo &&
        typeof personalInfo === 'object' &&
        'signup_source_influencer' in personalInfo
    );
  }, []);

  // ///////////////////////////////
  /* =========== HOOKS ========== */
  // ///////////////////////////////
  const dispatch = useDispatch();

  // //////////////////////////////////
  /* =========== LANGUAGE ========== */
  // //////////////////////////////////

  // Section keys
  const SOURCE_KEY = 'source';
  const INFLUENCER_KEY = 'influencer';

  // Sections
  const sourceSection = getLanguageSection(languageCtx, SOURCE_KEY);

  // This section will not exist for pre-jan2022 signup language.
  const influencerSection = getLanguageSection(languageCtx, INFLUENCER_KEY);
  const influencerSectionExists = !!Object.keys(influencerSection).length;

  const sources = (sourceSection?.options || []) as {
    display: string;
    subtitle: string;
    value: string;
  }[];

  // Buttons
  const buttonsCtx = getLanguageButtons(languageCtx);

  // //////////////////////////////////
  /* =========== HANDLERS ========== */
  // //////////////////////////////////
  const handleOnNext = async (): Promise<Record<string, string> | void> => {
    // Remove any error banner from previous attempts
    dispatch(removeBanner());

    if (!source) {
      setHasSubmitFailed(true);
      return { source: 'error' };
    }

    // Indicate that we are submitting a form and show a loading button state
    setIsSubmitting(true);

    // Hide any error banners until all requests are complete
    dispatch(hideBanner());

    const updateProfilePayload: UpdateProfileFields = {
      personal_info_signup_source: source,
    };

    // Save signup source influencer if signup source is "influencer".
    if (influencerSectionExists && source === INFLUENCER_SOURCE) {
      updateProfilePayload.personal_info_signup_source_influencer =
        selectedInfluencer?.id || null;
    }

    const response: any = await dispatch(updateProfile(updateProfilePayload));

    setIsSubmitting(false);
    dispatch(hideBanner(false));

    // Handle the text welcome request response
    // Handle the update profile request response
    if (response.error) {
      setHasSubmitFailed(true);
      return {
        [FORM_ERROR]: 'error',
      };
    }

    // Indicate that we have completed the form and show a completed button state
    await delayWithState(400, setIsCompleted);
    dispatch(
      updateCurrentSignupPage({
        page: props.nextPage,
      })
    );
  };

  const handleSourceOnChange = ({ key }: { key: number | string }) => {
    const selectedSourceKey = sources[key]?.value || '';
    setSource(selectedSourceKey);
  };

  const handleInfluencerOnSelect = (value: WEB.InfluencerOption | null) => {
    setSelectedInfluencer(value);
  };

  // Rework options to match the format expected by the Dropdown component
  let dropdownDefaultValue = '';
  const dropdownItems: DropdownItem[] = sources.map(
    ({ value, display, subtitle }) => {
      if (value === sourceDefault) {
        dropdownDefaultValue = display;
      }
      return { value: display, subValue: subtitle || null };
    }
  );

  // ///////////////////////////////////////
  /* =========== FORM ELEMENTS ========== */
  // ///////////////////////////////////////

  return (
    <>
      <PageBase mobileHeader={sourceSection?.header || ''}>
        <StyledDropdown
          id='source-menu'
          label={sourceSection?.header}
          description={sourceSection?.text}
          floatingLabel={sourceSection?.placeholder || ''}
          items={dropdownItems}
          defaultValue={dropdownDefaultValue || ''}
          hideLabelOnMobile
          onSelect={handleSourceOnChange}
          invalid={!source && hasSubmitFailed}
          errorText={sourceSection?.errorMessage}
          showError
          widthSize={DropdownWidthSize.SMALL}
        />
        {!hasSubmittedInfluencerSource &&
          influencerSectionExists &&
          source === INFLUENCER_SOURCE && (
            <>
              <Spacer space={spacers.tabLarge} desktopOnly />
              <LabelWrapper>
                <Label>
                  {influencerSection?.header}&nbsp;
                  <Text
                    size={TextSizes.TINY}
                    inline
                    weight='bold'
                    italic
                    color={TextColor.GRAY}
                  >
                    &nbsp;Optional
                  </Text>
                </Label>
                <DescriptionWrapper>
                  <Text>{influencerSection?.text}</Text>
                </DescriptionWrapper>
              </LabelWrapper>
              <InfluencerTypeahead
                id='influencer-typeahead'
                floatingLabel={influencerSection?.placeholder}
                value={selectedInfluencer?.display_name}
                placeholder={influencerSection?.placeholder}
                widthSize={DropdownWidthSize.SMALL}
                onSelect={handleInfluencerOnSelect}
              />
            </>
          )}
        <Form
          onSubmit={handleOnNext}
          initialValues={{
            source,
          }}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Field
                name='source'
                component={HiddenField}
                value={source}
                validate={composeValidators(isRequired)}
              />
              <ButtonsContainer>
                <Button
                  id='source-confirm-button'
                  isCompleted={isCompleted}
                  isLoading={isSubmitting}
                  disabled={isSubmitting}
                >
                  {!isCompleted ? buttonsCtx?.primary : buttonsCtx?.complete}
                </Button>
              </ButtonsContainer>
            </form>
          )}
        />
      </PageBase>
    </>
  );
};

export default Source;
