import * as React from 'react';
import styled from 'styled-components';
import Button from 'components/common/Button';
import Spacer from 'components/common/Spacer';
import PageBase from 'components/layout/PageBase';
import TwoPaneWrapper from 'components/layout/TwoPaneWrapper';
import ArtMap, { Art } from 'constants/ArtMap';
import SplashLink from 'constants/SplashLink';
import Navbar from 'pages/unauthenticated/Navbar';
import { breakpoints, spacers } from 'styles';

const StyledImage = styled.img`
  width: 483px;
  @media ${breakpoints.mobileLarge} {
    width: 100%;
  }
`;

const justifyContentMap = {
  left: 'flex-start',
  right: 'flex-end',
  center: 'center',
};

const StyledContainer = styled.div<{ justify?: 'left' | 'right' }>`
  width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: ${({ justify = 'left' }) => justifyContentMap[justify]};
  align-items: center;
  padding: 0 ${spacers.tabLarge};
`;

const handleGoHome = (): void => {
  window.location.href = SplashLink.HOME;
};
const ErrorPage = (): React.ReactElement => {
  return (
    <>
      <Navbar />
      <TwoPaneWrapper>
        <StyledContainer justify='right'>
          <StyledImage src={ArtMap(Art.Pig)} />
        </StyledContainer>
        <StyledContainer>
          <PageBase>
            <h1>We're sorry, something went wrong.</h1>
            <Spacer space={spacers.tabSmall} />
            <Button onClick={handleGoHome}>Go home</Button>
            <Spacer space={spacers.tabSmall} />
          </PageBase>
        </StyledContainer>
      </TwoPaneWrapper>
    </>
  );
};

export default ErrorPage;
