/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import styled from 'styled-components';
import Spacer from 'components/common/Spacer';
import Text, { TextColor, TextSizes } from 'components/common/Text';
import { mixins, spacers } from 'styles';

type Props = {
  /* unique identifier */
  id?: string;
  /* header text on the card group */
  header?: string;
  /* body text on the card group */
  text?: string;
  /* disclaimer of card group */
  disclaimer?: string;
  /* if true, render the disclaimer above the cards,
  rather than below the cards */
  disclaimerAbove?: boolean;
  /* size of the cards in group */
  size?: string;
  /* cards to render */
  children?: any;
  /* optional component to render */
  optionalComponent?: any;
};

const StyledCardGroup = styled.div<Props>`
  display: flex;
  flex-direction: column;
`;

const StyledCardContainer = styled.div<Props>`
  display: flex;
  flex-wrap: wrap;
  margin-top: ${mixins.pxToRem('24px')};
`;

const SquareCardGroup = ({
  id,
  header,
  text,
  disclaimer,
  disclaimerAbove,
  size,
  children,
  optionalComponent,
  ...otherProps
}: Props): React.ReactElement => {
  const getDisclaimer = () => (
    <>
      <Spacer space={disclaimerAbove ? spacers.tiny : spacers.small} />
      <Text color={TextColor.GRAY} size={TextSizes.TINY}>
        {disclaimer}
      </Text>
    </>
  );

  return (
    <StyledCardGroup id={id}>
      {header && <Text weight='700'>{header}</Text>}
      {text && <Spacer space={spacers.tiny} />}
      {text && <Text>{text}</Text>}
      {disclaimer && disclaimerAbove && getDisclaimer()}
      <StyledCardContainer {...otherProps}>{children}</StyledCardContainer>
      {optionalComponent}
      {disclaimer && !disclaimerAbove && getDisclaimer()}
    </StyledCardGroup>
  );
};

export default SquareCardGroup;
