import { Check } from '@styled-icons/fa-solid/Check';
import * as React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Spacer from 'components/common/Spacer';
import Text, {
  TextColor,
  TextSizes,
  textTransition,
} from 'components/common/Text';
import RoundBubble from 'components/common/Tooltip/RoundBubble';
import Lock from 'components/icons/Lock';
import { Roles } from 'constants/Accessibility';
import { DebitCardStyle, KeyboardEventKeys } from 'constants/index';
import * as appData from 'reducers/entities/appData';
import { breakpoints, mixins } from 'styles';
import { spacers as homeSpacers } from 'styles/home';

type Props = {
  selectedCardStyle: DebitCardStyle;
  cardStyleOptions: DebitCardStyle[];
  handleSelectCardStyle: (style: DebitCardStyle) => void;
  isGeniusLocked: boolean;
  hideColorLabel: boolean;
};

const StyleOptionsContainer = styled.div`
  @media ${breakpoints.mobileLarge} {
    text-align: center;
  }
`;

const StyledColorLabel = styled(Text)`
  // Fix inexplicable and random issue of a white background displaying on Safari iOS, iPhone 11
  -webkit-transform: translate3d(0, 0, 0);
`;

const GeniusText = styled.span`
  font-weight: 500;
  letter-spacing: ${mixins.pxToRem('2px')};
  background: linear-gradient(90deg, #9a7647 0.17%, #5a370f 80.67%);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
`;

const ExclusiveText = styled.span`
  font-weight: 700;
  letter-spacing: ${mixins.pxToRem('1px')};
  color: #694b1d;
`;

const OptionContainer = styled.div`
  position: relative;
  display: inline-block;
  margin-right: 24px;
  display: flex;
  justify-content: center;
  @media ${breakpoints.mobileLarge} {
    margin: 0 12px;
  }
`;

const BubbleContainer = styled.div`
  position: absolute;
  margin-bottom: 16px;
  min-width: 35px;
  width: fit-content;
  bottom: 45px;
`;

const ColorsContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-self: flex-start;
  @media ${breakpoints.mobileLarge} {
    justify-content: center;
  }
`;

const StyleOption = styled.div<{
  cardStyle: DebitCardStyle;
  selected: boolean;
}>`
  position: relative;
  height: 45px;
  width: 45px;
  border-radius: 50%;
  cursor: pointer;
  ${textTransition}
  @media ${breakpoints.mobileLarge} {
    text-align: center;
  }

  background-color: ${({ cardStyle, theme }) => {
    if (cardStyle === DebitCardStyle.WHITE) return theme.colors.white;
    if (cardStyle === DebitCardStyle.BLUE) return theme.colors.brandDebitCard;
    return theme.colors.primaryText;
  }};

  ${({ cardStyle, selected, theme }) => {
    if (selected)
      return `box-shadow: 0 0 0 3px ${theme.colors.lightGray1} inset;`;
    if (cardStyle === DebitCardStyle.WHITE)
      return `box-shadow: 0 0 0 1.5px ${theme.colors.lightGray2} inset;`;
    return '';
  }}

  :hover {
    ${({ selected, theme }) =>
      !selected && `box-shadow: 0 0 0 1.5px ${theme.colors.lightGray1} inset`};
  }

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  svg.check {
    width: 14px;
    color: ${({ cardStyle, theme }) =>
      cardStyle === DebitCardStyle.WHITE
        ? theme.colors.primaryText
        : theme.colors.white};
  }

  svg.lock {
    color: ${({ theme }) => theme.colors.lightGray1};
  }
`;

const cardStyleLabelMap = {
  [DebitCardStyle.WHITE]: 'White',
  [DebitCardStyle.BLUE]: 'Blue',
  [DebitCardStyle.GENIUS]: (
    <>
      <GeniusText>GENIUS</GeniusText>
      <ExclusiveText>&nbsp;EXCLUSIVE</ExclusiveText>
    </>
  ),
};

// Accessibility label map
const cardStyleAriaLabelMap = {
  [DebitCardStyle.WHITE]: 'White',
  [DebitCardStyle.BLUE]: 'Blue',
  [DebitCardStyle.GENIUS]: 'Black',
};

const DebitCardSelector = ({
  selectedCardStyle,
  cardStyleOptions,
  handleSelectCardStyle,
  isGeniusLocked,
  hideColorLabel,
}: Props) => {
  const bankingCommonData = useSelector(appData.getBankingCommonData);

  const onClickCardStyle = (cardStyle: DebitCardStyle) => () => {
    handleSelectCardStyle(cardStyle);
  };
  const onKeyDownCardOption = (
    e: React.KeyboardEvent<HTMLDivElement>,
    cardStyle: DebitCardStyle
  ) => {
    if (
      e.key === KeyboardEventKeys.ENTER ||
      e.key === KeyboardEventKeys.SPACEBAR
    ) {
      handleSelectCardStyle(cardStyle);
    }
  };
  const showPrices = bankingCommonData?.charge_for_debit_card;
  return (
    <StyleOptionsContainer>
      {!showPrices && !hideColorLabel && (
        <StyledColorLabel color={TextColor.GRAY_DARK} size={TextSizes.TINY}>
          Color: {cardStyleLabelMap[selectedCardStyle]}
        </StyledColorLabel>
      )}
      <Spacer space={showPrices ? homeSpacers.g12 : homeSpacers.g6} />
      <ColorsContainer>
        {cardStyleOptions.map((cardStyle) => {
          const isSelected = cardStyle === selectedCardStyle;
          const styleAvailability =
            bankingCommonData?.debit_card_availability.find(
              (card) => card.style === cardStyle
            );
          const priceDisplay = styleAvailability?.price_display;
          const isLocked =
            cardStyle === DebitCardStyle.GENIUS && isGeniusLocked;

          let icon = null;
          if (isLocked) {
            icon = <Lock className='lock' />;
          } else if (isSelected) {
            icon = <Check className='check' />;
          }

          let ariaLabel = cardStyleAriaLabelMap[cardStyle];
          if (showPrices && priceDisplay) {
            ariaLabel += ` (${priceDisplay})`;
          } else if (selectedCardStyle === DebitCardStyle.GENIUS) {
            ariaLabel += `: Genius Exclusive`;
          }

          return (
            <OptionContainer key={cardStyle}>
              {showPrices && priceDisplay && (
                <BubbleContainer>
                  {isSelected && <RoundBubble text={priceDisplay} />}
                </BubbleContainer>
              )}

              <StyleOption
                key={cardStyle}
                cardStyle={cardStyle}
                selected={isSelected}
                onClick={onClickCardStyle(cardStyle)}
                onKeyDown={(e) => onKeyDownCardOption(e, cardStyle)}
                tabIndex={0}
                aria-label={ariaLabel}
                role={Roles.IMAGE}
              >
                {icon}
              </StyleOption>
            </OptionContainer>
          );
        })}
      </ColorsContainer>
    </StyleOptionsContainer>
  );
};

export default DebitCardSelector;
