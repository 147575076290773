export const formatIncome = (
  income: string | number,
  decimals?: number
): string => {
  const fractionDigits = decimals || decimals === 0 ? decimals : 2;
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  });
  return formatter.format(income as number);
};

export const formatWithCommasAndDecimals = (
  num: string | number,
  decimals?: number
): string => {
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: decimals || 2,
    maximumFractionDigits: decimals || 2,
  });
  return formatter.format(num as number);
};

export const blurAccountNum = (
  accountNum: string,
  numsToShow: number
): string => {
  let blurredAccountNum = '';
  [...accountNum].forEach((num: string, i: number) => {
    if (i < accountNum.length - numsToShow) {
      blurredAccountNum += '*';
    } else {
      blurredAccountNum += num;
    }
  });
  return blurredAccountNum;
};

// Remove accents from strings and return result
// e.g. "Crème Brulée" => "Creme Brulee"
export const removeDiacritics = (str: string): string =>
  str.normalize('NFD').replace(/\p{Diacritic}/gu, '');

/**
 * Convert a float string with comma separators
 * from string to number.
 * @param value e.g. "1,234.56"
 * @returns a float, e.g. 1234.56
 */
export const floatStringToNum = (value: string): number | null => {
  const strippedValue = value.replace(/,/g, '').replace(/\$/g, '');
  const floatValue = Number(strippedValue);

  if (Number.isNaN(floatValue)) {
    return null;
  }

  return floatValue;
};

/**
 * Format a float as a string with
 * comma separators and decimal places
 * @param value e.g. 1234.56
 * @param numDecimalPlaces number of decimal places to keep in the number
 * @returns a string, e.g. "1,234.56"
 */
export const floatNumToString = (
  value: number,
  numDecimalPlaces: number
): string => {
  const formattedValue = value.toLocaleString(undefined, {
    minimumFractionDigits: numDecimalPlaces,
    maximumFractionDigits: numDecimalPlaces,
  });
  return formattedValue;
};

/**
 *
 * @param string string to truncate
 * @param charLimit limit of characters before truncating string
 * @returns a truncated string, e.g. "After going into the store..."
 */
export const truncate = (string: string, charLimit: number): string => {
  if (string.length < charLimit) {
    return string;
  }
  const slicedString = string.slice(0, charLimit);
  const slicedStringWords = slicedString.split(' ');
  slicedStringWords.splice(slicedStringWords.length - 1, 1);
  const truncatedString = [...slicedStringWords].join(' ');
  return `${truncatedString}...`;
};
