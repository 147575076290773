import * as React from 'react';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
import { Roles } from 'constants/Accessibility';
import ArtMap, { Art } from 'constants/ArtMap';
import ZIndex from 'constants/ZIndex';
import { KeyboardEventKeys } from 'constants/index';
import breakpoints from 'styles/breakpoints';
import mixins from 'styles/mixins';
import { handleActivateKeyDown } from 'utils/accessibility';
import BlurredBackdrop from './BlurredBackdrop';
import ModalBackdrop from './ModalBackdrop';

type Props = {
  id?: string;
  children?: React.ReactNode | React.ReactNode[];
  onClickClose?: () => void;
  popupChildren?: React.ReactNode | React.ReactNode[];
  showPopup?: boolean;
  onClickPopupClose?: () => void;
  width?: number;
  bottomPad?: number;
};

interface Target {
  id: string;
  addEventListener: any;
  dispatchEvent: any;
  removeEventListener: any;
}

interface DivEvent extends React.MouseEvent<HTMLDivElement, MouseEvent> {
  target: Target;
}

const StyledBaseModal = styled.div<Props>`
  width: ${({ width }) => mixins.pxToRem(`${width}px`)};
  ${mixins.slideInY()}
  height: auto;
  max-height: ${isMobile ? '85vh' : '90vh'};
  overflow: ${({ showPopup }) => (showPopup ? 'hidden' : 'auto')};
  display: block;
  position: absolute;
  background-color: white;
  z-index: ${ZIndex.MODAL};
  border-radius: 8px;
  @media ${breakpoints.mobileLarge} {
    margin: 0;
    height: auto;
    width: 93%;
  }
`;

const CloseIcon = styled.img.attrs({ src: ArtMap(Art.Times) })`
  position: absolute;
  top: 0;
  right: 0;
  padding: ${mixins.pxToRem('24px')};
  width: ${mixins.pxToRem('12px')};
  box-sizing: content-box;
  color: ${({ theme }) => theme.colors.primaryDarkGray};
  z-index: ${ZIndex.MODAL_CLOSE_ICON};
  cursor: pointer;
`;

const ModalPopup = styled.div`
  height: ${mixins.pxToRem('267px')};
  width: 100%;
  position: absolute;
  background-color: white;
  box-shadow: 0px -6px 64px rgba(0, 0, 0, 0.1);
  bottom: 0;
  left: 0;
  z-index: ${ZIndex.MODAL_POPUP};
  padding: ${mixins.pxToRem('20px')};
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface MCWProps {
  bottomPad: number;
  noOverflow?: boolean;
}

export const ModalContentWrapper = styled.div<MCWProps>`
  position: relative;
  padding: ${mixins.pxToRem('48px')} ${mixins.pxToRem('42px')}
    ${({ bottomPad }) => mixins.pxToRem(`${bottomPad}px`)}
    ${mixins.pxToRem('42px')};
  ${({ noOverflow }) => noOverflow && 'overflow: hidden;'}
`;

export const ModalContent = styled.div`
  overflow: none;
`;

export const IconWrapper = styled.div`
  padding-top: 12px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LEGAL_CLOSE_IDS = ['modal-backdrop', 'modal-close-icon'];

/** 2021/05/05, MPR: @TODO investigate why this is needed when it should be taken
 * care of by the hook in the other modals (basic, navigation) that implement
 * this base
 */
const onClickCloseGate = (
  onClickClose: React.MouseEventHandler | undefined,
  e: React.MouseEvent
) => {
  if (
    onClickClose &&
    e.target &&
    ~LEGAL_CLOSE_IDS.indexOf((e.target as HTMLElement).id)
  ) {
    onClickClose(e);
  }
};

export const BaseModal = React.forwardRef<HTMLDivElement, Props>(
  function BaseModal(
    {
      id,
      children,
      popupChildren,
      showPopup,
      onClickPopupClose,
      onClickClose,
      width = 590,
      bottomPad = 52,
    },
    ref
  ): React.ReactElement {
    const handleModalKeyDown = React.useCallback(
      (e: KeyboardEvent): void => {
        if (e.key === KeyboardEventKeys.TAB && ref) {
          const focusableElements =
            'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';

          const focusableModalElements: NodeList | [] =
            typeof ref === 'object'
              ? ref.current?.querySelectorAll(focusableElements) || []
              : [];

          const firstFocusableModalElement =
            focusableModalElements[0] as HTMLElement;

          let focusInModal = false;
          focusableModalElements.forEach((element: Node) => {
            if (document.activeElement === element) {
              focusInModal = true;
            }
          });
          if (!focusInModal && firstFocusableModalElement) {
            firstFocusableModalElement.focus();
          }
        }
      },
      [ref]
    );

    React.useEffect(() => {
      if (ref) {
        document.addEventListener('keydown', handleModalKeyDown, true);
        const focusableElements =
          'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';

        const focusableModalElement = (
          ref as React.MutableRefObject<HTMLDivElement>
        ).current.querySelectorAll(focusableElements) as NodeList;
        const firstFocusableModalElement =
          focusableModalElement[0] as HTMLElement;
        firstFocusableModalElement.focus();
      }

      return () => {
        document.removeEventListener('keydown', handleModalKeyDown, true);
      };
    }, [ref, handleModalKeyDown]);

    React.useEffect(() => {
      const { body } = document;
      body.style.height = '100vh';
      body.style.overflow = 'hidden';
      return () => {
        body.style.height = '';
        body.style.overflow = '';
      };
    }, []);

    return (
      <ModalBackdrop
        id={LEGAL_CLOSE_IDS[0]}
        onClick={(e) => {
          onClickCloseGate(onClickClose, e);
        }}
      >
        <StyledBaseModal id={id} ref={ref} width={width} showPopup={showPopup}>
          {onClickClose && (
            <CloseIcon
              id={LEGAL_CLOSE_IDS[1]}
              onClick={(e) => {
                onClickCloseGate(onClickClose, e);
              }}
              tabIndex={0}
              onKeyDown={(e) => handleActivateKeyDown(e, onClickClose)}
              role={Roles.BUTTON}
            />
          )}
          <ModalContentWrapper noOverflow={showPopup} bottomPad={bottomPad}>
            {children}
            {showPopup && (
              <>
                <BlurredBackdrop
                  onClick={() => (onClickPopupClose ? onClickPopupClose() : {})}
                />
                <ModalPopup>
                  {onClickPopupClose && (
                    <CloseIcon
                      onClick={() => onClickPopupClose()}
                      tabIndex={0}
                      onKeyDown={(e) =>
                        handleActivateKeyDown(e, onClickPopupClose)
                      }
                      role={Roles.BUTTON}
                    />
                  )}
                  {popupChildren}
                </ModalPopup>
              </>
            )}
          </ModalContentWrapper>
        </StyledBaseModal>
      </ModalBackdrop>
    );
  }
);
