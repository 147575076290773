/**
 * We can say a boolean query parameter is true if it is specified
 * with no value, or has a traditionally truthy value
 * Ex of true params:
 *   ?short_flow
 *   ?short_flow=t
 *   ?short_flow=true
 *   ?short_flow=1
 *
 *   Any other value specified will evaluate to false. If a query parameter
 *   is fully missing from the URL, that should also be false.
 * @param str The value of query parameter, as returned from
 *            URLSearchParams#get.
 */
export function parseQueryParamBoolean(str?: string | null): boolean {
  if (str === null || str === undefined) {
    return false;
  }

  return str === '' || /t|true|1/i.test(str);
}
