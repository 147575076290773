// Modules
import NodeEnvironment from '../constants/NodeEnvironment';

/**
 * Utility functions to help with overriding checkpoint navigation in local / dev.
 */

const LOCAL_STORAGE_KEY = 'disableCheckpointNav';

// Read from local storage to see if checkpoint redirect is disabled
// NOTE: Only allow override in LOCAL or DEV environment
export const getIsCheckpointDisabled = (): boolean => {
  const isDisabled = !!JSON.parse(
    localStorage.getItem(LOCAL_STORAGE_KEY) as string
  );

  return window.albertWeb.Environment !== NodeEnvironment.PROD && isDisabled;
};

export const setCheckpointDisabled = (newValue: boolean): void => {
  localStorage.setItem(LOCAL_STORAGE_KEY, `${newValue}`);
};

export default {
  getIsCheckpointDisabled,
  setCheckpointDisabled,
};
