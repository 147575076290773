import { FORM_ERROR } from 'final-form';
import * as React from 'react';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import updateProfile from 'actions/async/updateProfile';
import removeBanner from 'actions/banner/removeBanner';
import TopLoadingBar from 'components/TopLoadingBar';
import Button from 'components/common/Button';
import Dropdown from 'components/common/Dropdown';
import Spacer from 'components/common/Spacer';
import Text, { TextSizes } from 'components/common/Text';
import ButtonsContainer from 'components/layout/ButtonsContainer';
import PageBase from 'components/layout/PageBase';
import Pages from 'constants/Pages';
import useNavigateFunction from 'hooks/useNavigateFunction';
import useVerifyCurrentPage from 'hooks/useVerifyCurrentPage';
import InstantConfirm from 'pages/signup/InstantConfirm';
import * as language from 'reducers/entities/language';
import * as profile from 'reducers/entities/profile';
import { breakpoints, spacers } from 'styles';
import * as WEB from 'types/interfaces';
import { delayWithState } from 'utils/delay';
import {
  getLanguageButtons,
  getLanguageInfo,
  getLanguageSection,
} from 'utils/getFromLanguage';

type Props = {
  nextPage: string;
};

const HiddenInput = styled.input`
  display: none;
`;

const DropdownContainer = styled.div`
  div.al-dropdown {
    width: 354px;
  }
  @media ${breakpoints.tabletSmall} {
    div.al-dropdown {
      width: 100%;
    }
  }
`;

/**
 * DEPRECATED
 */
const InstantInfo = (props: Props): React.ReactElement => {
  const { updateCurrentSignupPage } = useNavigateFunction();
  // Make Redux is up-to-date with currentSignUpPage
  useVerifyCurrentPage(Pages.INSTANT_INFO);

  // ///////////////////////////////
  /* =========== HOOKS ========== */
  // ///////////////////////////////
  const dispatch = useDispatch();

  // ///////////////////////////////
  /* =========== STATE ========== */
  // ///////////////////////////////
  const [showConfirmPage, setShowConfirmPage] = React.useState<boolean>(false);
  const [frequency, setFrequency] = React.useState('');
  const [isSubmittingLater, setIsSubmittingLater] =
    React.useState<boolean>(false);
  const [isCompleted, setIsCompleted] = React.useState<boolean>(false);
  const [visitedFrequencyMenu, setVisitedFrequencyMenu] =
    React.useState<boolean>(false);

  // ///////////////////////////////
  /* ======== MAPPED STATE ====== */
  // ///////////////////////////////
  const languageCtx = useSelector((state: WEB.RootState) =>
    language.getSignupPageLanguage(state, Pages.INSTANT_INFO)
  );

  const frequencyDefault = useSelector((state: WEB.RootState) =>
    profile.getInfoValueByField(
      state,
      'instant_info',
      'signup_instant_paycycle'
    )
  );

  React.useEffect(() => {
    setFrequency(frequencyDefault);
  }, [frequencyDefault]);

  // //////////////////////////////////
  /* =========== HANDLERS ========== */
  // //////////////////////////////////
  const handleOnNext = async () => {
    // Remove any error banner from previous attempts
    dispatch(removeBanner());

    if (frequency == null) {
      setVisitedFrequencyMenu(true);
      return { frequency: 'error' };
    }

    const { error }: any = await dispatch(
      updateProfile({
        instant_info: {
          signup_instant_interest: true,
          signup_instant_paycycle: frequency,
        },
      })
    );

    if (error) return { [FORM_ERROR]: 'error' };
  };

  const submitCallback = async (errors?: any) => {
    if (!errors) {
      await delayWithState(400, setIsCompleted);
      setShowConfirmPage(true);
    }
  };
  const handleOnLater = async () => {
    // Remove any error banner from previous attempts
    dispatch(removeBanner());

    setIsSubmittingLater(true);
    const { error }: any = await dispatch(
      updateProfile({
        instant_info: {
          signup_instant_interest: false,
        },
      })
    );
    setIsSubmittingLater(false);

    if (!error) {
      dispatch(updateCurrentSignupPage({ page: props.nextPage }));
    }
  };

  const handleFrequencyOnChange = ({ value }: { value: string }) => {
    setFrequency(value);
  };

  const handleVisitedFrequencyMenu = () => setVisitedFrequencyMenu(true);

  // //////////////////////////////////
  /* =========== LANGUAGE ========== */
  // //////////////////////////////////

  const INTRO_KEY = 'intro';
  const FREQUENCY_KEY = 'frequency';
  const frequencySection = getLanguageSection(languageCtx, FREQUENCY_KEY);
  const introSection = getLanguageSection(languageCtx, INTRO_KEY);
  const infoPaneContext = getLanguageInfo(languageCtx);
  const buttonsCtx = getLanguageButtons(languageCtx);

  const frequencies = (frequencySection?.options || []) as {
    display: string;
    value: string;
  }[];

  // Lay the options flat as an array of strings to display
  let dropdownDefaultValue = '';
  const dropdownItems = frequencies.map((option) => {
    const result = `${option.display}`;
    if (option.value === frequencyDefault) {
      dropdownDefaultValue = result;
    }
    return result;
  });

  /* The dropdown is not within the <Form> wrapper component
     because, for some reason, clicking the dropdown chevron directly
     (to open the dropdown) causes the form to submit and
     proceed to the next page.
  */

  const instantInfoPage = (
    <PageBase>
      <h1>{introSection?.header}</h1>
      <Text>{introSection?.text}</Text>
      <Spacer space={spacers.tabLarge} />
      <DropdownContainer>
        <Dropdown.Menu
          id='instant-frequency-menu'
          label={frequencySection?.header}
          defaultValue={dropdownDefaultValue}
          description={frequencySection?.text}
          floatingLabel={frequencySection?.placeholder}
          items={dropdownItems}
          invalid={frequency === null && visitedFrequencyMenu}
          errorText={frequencySection?.errorMessage}
          onClose={handleVisitedFrequencyMenu}
          onSelect={handleFrequencyOnChange}
          showError
        />
      </DropdownContainer>
      <Spacer space={spacers.tabLarge} />
      <Form
        onSubmit={handleOnNext}
        initialValues={{
          frequency: false,
        }}
        render={({ handleSubmit, submitting }) => (
          <form
            onSubmit={(event) => {
              handleSubmit(event)?.then(submitCallback);
            }}
          >
            <Field name='frequency' component={HiddenInput} />
            <ButtonsContainer>
              <Text weight='700' size={TextSizes.SMALL} underline>
                <a
                  className='instant-info-later-link'
                  onClick={
                    submitting || isSubmittingLater ? undefined : handleOnLater
                  }
                >
                  {buttonsCtx?.link}
                </a>
              </Text>
              <Spacer space={spacers.tab} orientation='horizontal' />
              <Button
                id='instant-info-confirm-button'
                isCompleted={isCompleted}
                isLoading={submitting}
                disabled={submitting || isSubmittingLater}
              >
                {isCompleted ? buttonsCtx?.complete : buttonsCtx?.primary}
              </Button>
            </ButtonsContainer>
          </form>
        )}
      />
    </PageBase>
  );

  return (
    <>
      <TopLoadingBar isLoading={isSubmittingLater} />
      {showConfirmPage ? (
        <InstantConfirm nextPage={props.nextPage} />
      ) : (
        instantInfoPage
      )}
    </>
  );
};

export default InstantInfo;
