import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import getAppData from 'actions/async/getAppData';
import updateProfile from 'actions/async/updateProfile';
import types from 'actions/types';
import Button from 'components/common/Button';
import ErrorText from 'components/common/ErrorText';
import Spacer from 'components/common/Spacer';
import SquareCardFilled from 'components/common/SquareCard/SquareCardFilled';
import ButtonsContainer from 'components/layout/ButtonsContainer';
import LeadContent from 'components/layout/LeadContent';
import PageBase from 'components/layout/PageBase';
import Pages from 'constants/Pages';
import SignupGoals, { SignupGoalsType } from 'constants/SignupGoals';
import useNavigateFunction from 'hooks/useNavigateFunction';
import useVerifyCurrentPage from 'hooks/useVerifyCurrentPage';
import * as appData from 'reducers/entities/appData';
import * as language from 'reducers/entities/language';
import { breakpoints } from 'styles';
import { spacers } from 'styles/home';
import * as WEB from 'types/interfaces';
import { getLanguageSection } from 'utils/getFromLanguage';

type Props = {
  nextPage: string;
};

const CARD_SIZE = '148px';

const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 12px;
  row-gap: 14px;
  justify-items: center;
  max-width: calc((${CARD_SIZE} * 3) + 24px);
  @media ${breakpoints.mobileLarge} {
    margin: 0 auto;
  }
  @media ${breakpoints.institutionGrid} {
    max-width: none;
    grid-template-columns: 1fr 1fr;
  }
`;

const AboutYouGuidance = (props: Props): React.ReactElement => {
  // ///////////////////////////////
  /* =========== HOOKS ========== */
  // ///////////////////////////////
  useVerifyCurrentPage(Pages.ABOUT_YOU_GUIDANCE);
  const dispatch = useDispatch();
  const { updateCurrentSignupPage } = useNavigateFunction();

  // ///////////////////////////////
  /* =========== SELECTORS ========== */
  // ///////////////////////////////
  const appDataSavingsGoals = useSelector((state: WEB.RootState) =>
    appData.getValueByField(state, 'signup_goals')
  );
  const languageCtx = useSelector((state: WEB.RootState) =>
    language.getSignupPageLanguage(state, 'AboutYouGuidance')
  );
  const setupLanguage = getLanguageSection(languageCtx, 'setup');

  // ///////////////////////////////
  /* =========== STATE ========== */
  // ///////////////////////////////
  const [selectedOptionIds, setSelectedOptionIds] = React.useState<
    SignupGoals[]
  >([...appDataSavingsGoals]);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [pageInError, setPageInError] = React.useState(false);

  // ///////////////////////////////
  /* =========== HANDLERS ========== */
  // ///////////////////////////////
  const updateSelectedOptions = (id: SignupGoals): void => {
    setPageInError(false);
    if (selectedOptionIds?.includes(id)) {
      setSelectedOptionIds(
        selectedOptionIds.filter((optionId: string) => optionId !== id)
      );
    } else {
      setSelectedOptionIds(selectedOptionIds?.concat(id));
    }
  };

  const handleSubmit = async (): Promise<void> => {
    setIsSubmitting(true);
    if (!selectedOptionIds.length) {
      setPageInError(true);
      setIsSubmitting(false);
      return;
    }
    const { error }: any = await dispatch(
      updateProfile({
        signup_goals: selectedOptionIds,
        signup_goals_type: SignupGoalsType.SETUP_GOALS,
      })
    );
    // Data is stored in appData even though update is made to profile, so we
    // need to refetch appData to keep the Redux store in sync with the backend
    await dispatch(getAppData());

    setIsSubmitting(false);
    if (!error) {
      // Flow may be updated depending on user's selection
      const signupGoalObjects = selectedOptionIds.map((option) => ({
        name: option,
        type: SignupGoalsType.SETUP_GOALS,
      }));

      dispatch({
        type: types.UPDATE_SIGNUP_LANGUAGE_ORDER,
        payload: {
          signupGoalObjects: signupGoalObjects,
        },
      });
      dispatch(updateCurrentSignupPage({ page: props.nextPage }));
    }
  };

  // ///////////////////////////////
  /* =========== RENDER ========== */
  // ///////////////////////////////
  return (
    <>
      <PageBase>
        <LeadContent
          header={setupLanguage?.header || ''}
          text={setupLanguage.text}
        />
        <Spacer space={spacers.g6} />
        <CardsContainer>
          {/* Unfortunately, typecasting won't work here since this conflicts
              with the type of languageCtx */}
          {setupLanguage?.options?.map((option: any) => {
            return (
              <SquareCardFilled
                body={option.title}
                image={option.icon}
                key={option.id}
                selected={selectedOptionIds?.includes(option.id)}
                onClick={() => updateSelectedOptions(option.id)}
                isError={pageInError}
                size={CARD_SIZE}
                fillMobile
              />
            );
          })}
        </CardsContainer>
        {pageInError && (
          <>
            <Spacer space={spacers.g3} />
            <ErrorText
              name='about-you-guidance-empty-err'
              text={setupLanguage?.errorMessage}
            />
          </>
        )}
        <ButtonsContainer>
          <Button
            isLoading={isSubmitting}
            disabled={isSubmitting}
            onClick={handleSubmit}
          >
            Continue
          </Button>
        </ButtonsContainer>
      </PageBase>
    </>
  );
};

export default AboutYouGuidance;
