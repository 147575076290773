import * as React from 'react';
import styled from 'styled-components';

export type Props = {
  children?: React.ReactChild | React.ReactChild[];
  className?: string;
};

const StyledCarouselPage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
`;

const CarouselPage = (props: Props): React.ReactElement => {
  const { children, className = '' } = props;
  return (
    <StyledCarouselPage className={`styled-carousel-page ${className}`}>
      {children}
    </StyledCarouselPage>
  );
};

export default CarouselPage;
