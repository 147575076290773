import * as React from 'react';

const BetterBankAccountIcon = (): React.ReactElement => (
  <svg
    width='31'
    height='24'
    viewBox='0 0 31 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M21.008 11.2235C19.28 11.2235 17.968 12.5355 17.968 14.2635C17.968 15.8955 19.28 17.3355 21.008 17.3355C22.64 17.3355 24.08 15.8955 24.08 14.2635C24.08 12.5355 22.64 11.2235 21.008 11.2235ZM0.56 18.5835C0.56 21.5275 2.928 23.8955 5.872 23.8955H25.328C28.272 23.8955 30.64 21.5275 30.64 18.5835V6.16755C30.64 3.22355 28.272 0.855545 25.328 0.855545H5.872C2.928 0.855545 0.56 3.22355 0.56 6.16755V18.5835ZM3.952 18.5835V6.16755C3.952 5.07955 4.784 4.24755 5.872 4.24755H25.328C26.416 4.24755 27.248 5.07955 27.248 6.16755V18.5835C27.248 19.6715 26.416 20.5035 25.328 20.5035H5.872C4.784 20.5035 3.952 19.6715 3.952 18.5835Z'
      fill='#0066FF'
    />
  </svg>
);

export default BetterBankAccountIcon;
