import * as React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import getNextPage from 'actions/getNextPage';
import { StyledBannerDesktop, StyledBannerMobile } from 'components/BannerPane';
import Button from 'components/common/Button';
import Spacer from 'components/common/Spacer';
import Text from 'components/common/Text';
import { SelectCardLottie } from 'components/common/animations';
import BorderContainer from 'components/layout/BorderContainer';
import {
  LeftPane,
  PageContainer,
  PaneWrapper,
  RightPane,
} from 'components/layout/FiftyFiftyPageLayout';
import PageBase from 'components/layout/PageBase';
import DebitCardStyle from 'constants/DebitCardStyle';
import useBrowserNavigationBlockWithBanner from 'hooks/useBrowserNavigationBlockWithBanner';
import useNavigateFunction from 'hooks/useNavigateFunction';
import Navbar from 'pages/unauthenticated/Navbar';
import { breakpoints, spacers } from 'styles';
import { delayWithState } from 'utils/delay';

type Props = {
  nextPage: string;
  cardStyle: DebitCardStyle;
};

const StyledHeader = styled.h1`
  margin: 0;

  @media ${breakpoints.mobileLarge} {
    font-size: 1.875rem;
  }
`;

const Description = styled(Text)`
  font-size: 1.125rem;

  @media ${breakpoints.mobileLarge} {
    font-size: 1rem;
  }
`;

const CardHasShipped = (props: Props): React.ReactElement => {
  const { updateCurrentSignupPage } = useNavigateFunction();
  const { nextPage, cardStyle } = props;
  const dispatch = useDispatch();
  useBrowserNavigationBlockWithBanner();

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isCompleted, setIsCompleted] = React.useState(false);

  const handleContinueClick = async () => {
    setIsSubmitting(true);
    const { error, nextPageOverride }: any = await dispatch(getNextPage());
    setIsSubmitting(false);
    if (!error) {
      await delayWithState(400, setIsCompleted);
      dispatch(
        updateCurrentSignupPage({
          page: nextPageOverride || nextPage,
        })
      );
    }
  };

  return (
    <>
      <Navbar signupFlow split background='white' />
      <PageContainer>
        <PaneWrapper centered>
          <StyledBannerMobile />
          <LeftPane>
            <SelectCardLottie style={cardStyle} />
          </LeftPane>
          <RightPane>
            <PageBase maxWidth='625px'>
              <StyledBannerDesktop />
              <BorderContainer desktopPadding='80px'>
                <StyledHeader>Your card has shipped</StyledHeader>
                <Spacer space={spacers.tabSmall} desktopOnly />
                <Spacer space={spacers.submicroscopic} desktopOnly />
                <Description className='description'>
                  Look out for your debit card in the mail. It should arrive in
                  5-7 business days. Once you’ve received your card, you can
                  activate it in the app.
                </Description>
                <div className='buttons-container'>
                  <Button
                    stretch
                    onClick={handleContinueClick}
                    isCompleted={isCompleted}
                    isLoading={isSubmitting}
                    disabled={isSubmitting}
                  >
                    {isCompleted ? null : 'Continue'}
                  </Button>
                </div>
              </BorderContainer>
            </PageBase>
          </RightPane>
        </PaneWrapper>
      </PageContainer>
    </>
  );
};

export default CardHasShipped;
