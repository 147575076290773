/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable jsx-a11y/no-autofocus */
import * as React from 'react';
import ReactCodeInput from 'react-code-input';
import styled from 'styled-components';
import ErrorText from 'components/common/ErrorText';
import { mixins } from 'styles';
import { BaseInputProps } from 'types/interfaces';
import { InputWrapper, baseInputTransition, renderLabels } from './shared';

export type Props = BaseInputProps & {
  /* the label for the input codes */
  label: string | React.ReactNode;
  /* the description for the input codes */
  description?: string;
  /* number of input fields to render */
  fields: number;
  /* the theme to use for this component */
  theme?: any;
  /* on change */
  onChange?: (code: string) => void;
};

const StyledCodeInput = styled(ReactCodeInput)<{
  invalid?: boolean;
  disabled?: boolean;
  theme?: any;
}>`
  input {
    width: ${mixins.pxToRem('35px')};
    height: ${mixins.pxToRem('43px')};
    color: ${({ theme }) => theme.colors.primaryText};
    background-color: white;
    border-color: ${({ invalid, theme }) =>
      invalid ? theme.colors.primaryError : theme.colors.lightGray1};
    border-width: ${({ invalid }) => (invalid ? '2.5px' : '1.5px')};
    border-style: solid;
    border-radius: 3px;
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'auto')};
    outline: none;
    text-align: center;
    ${baseInputTransition}

    // Fix box shadow on iOS Safari
    -webkit-appearance: none;
    ${({ theme, disabled, invalid }) =>
      disabled || invalid
        ? ''
        : `
      &:focus {
        border-color: ${theme.colors.primaryAlbertBrand};
        box-shadow: 0 0 0 1px ${theme.colors.primaryAlbertBrand};
      }
      
     @media (hover: hover) {
      &:hover {
        border-color: ${theme.colors.primaryAlbertBrand};
        box-shadow: 0 0 0 1px ${theme.colors.primaryAlbertBrand};
      }
    }
    `}

    /* Hide default spinners for number input */
    -moz-appearance: textfield;
    ::-webkit-inner-spin-button,
    ::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`;

const StyledCodeInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  *:not(:last-child) {
    margin-right: ${mixins.pxToRem('15px')};
  }
`;

const CodeInput = (props: Props): React.ReactElement => {
  const { id, errorText, invalid, fields, onChange } = props;

  // NOTE: react-code-input does not come with a built-in way to clear input code fields.
  // Moreover, the input fields are not controlled inputs - seems like `value` prop is used as default value.
  // Use workaround with refs to manually clear input if invalid.
  const inputRef = React.useRef<ReactCodeInput>(null);
  React.useEffect(() => {
    if (invalid) {
      for (let i = 0; i < fields; i += 1) {
        if (inputRef?.current?.state?.input[i]) {
          inputRef.current.state.input[i] = '';
        }
      }
    }
  }, [invalid]);

  return (
    <div>
      {renderLabels(props)}
      <InputWrapper className='input-wrapper'>
        <StyledCodeInputWrapper>
          <StyledCodeInput
            ref={inputRef}
            name={`code-input-${id}`}
            type='number'
            fields={fields}
            inputMode='numeric'
            invalid={invalid}
            theme={props.theme}
            onChange={onChange}
            autoFocus={false}
          />
        </StyledCodeInputWrapper>
        {invalid && errorText && <ErrorText name={id} text={errorText} />}
      </InputWrapper>
    </div>
  );
};

export default CodeInput;
