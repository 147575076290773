/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import addInstitutionLogin, {
  AddInsLoginParams,
} from 'actions/async/addInstitutionLogin';
import getAppData from 'actions/async/getAppData';
import addBanner from 'actions/banner/addBanner';
import removeBanner from 'actions/banner/removeBanner';
import setAddAccountsClicked from 'actions/setAddAccountsClicked';
import updateSelectedInsId from 'actions/updateSelectedInsId';
import BannerPane from 'components/BannerPane';
import Button from 'components/common/Button';
import Modal from 'components/common/Modal';
import Spacer from 'components/common/Spacer';
import Text, { TextColor, TextSizes } from 'components/common/Text';
import InstitutionSelectionGrid from 'components/institution/InstitutionSelectionGrid';
import ButtonsContainer from 'components/layout/ButtonsContainer';
import ContentPane, { ContentContainer } from 'components/layout/ContentPane';
import InfoPane from 'components/layout/InfoPane';
import InfoPaneExpanderGroup from 'components/layout/InfoPaneExpanderGroup';
import PageBase from 'components/layout/PageBase';
import TwoPaneWrapper from 'components/layout/TwoPaneWrapper';
import { BannerActionType, ErrorMessages } from 'constants/';
import { HIDE_NAV_AND_BANNER } from 'constants/PageDisplayOptions';
import {
  AccountCategory,
  AccountType,
  NotificationTypes,
  Pages,
  PollingType,
  SplashLink,
} from 'constants/index';
import useBrowserNavigationOverride from 'hooks/useBrowserNavigationOverride';
import usePlaidLink, { PlaidLinkConfig } from 'hooks/usePlaidLink';
import Processing from 'pages/signup/Processing';
import Navbar from 'pages/unauthenticated/Navbar';
import * as language from 'reducers/entities/language';
import * as profile from 'reducers/entities/profile';
import * as signup from 'reducers/ui/signup';
import { breakpoints, mixins, spacers } from 'styles/index';
import * as WEB from 'types/interfaces';
import getErrorMessageFromResponse from 'utils/getErrorMessageFromResponse';
import {
  getLanguageButtons,
  getLanguageInfo,
  getLanguageModal,
  getLanguageSection,
} from 'utils/getFromLanguage';
import { OnSuccessMetadata } from 'utils/plaidUtils';
import renderTemplateContext from 'utils/renderTemplateContext';
import PollingManager from '../../manager.Polling';

type Props = {
  fromSignup: boolean;
  onSubmit: () => void;
  onSkip: () => void;
  message: string | null;
};

const Header = styled.h1`
  margin-bottom: ${mixins.pxToRem('12px')};
  @media ${breakpoints.mobileLarge} {
    display: none;
  }
`;

const processingInfo = {
  header: 'Processing your account',
  checkpoints: ['Gathering information', 'Analyzing accounts', 'Finishing up'],
};

const FindInstitution = (props: Props): React.ReactElement => {
  const { fromSignup, onSubmit, onSkip } = props;

  // ///////////////////////////////
  /* =========== HOOKS ========== */
  // ///////////////////////////////
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(setAddAccountsClicked(false));
  }, []);

  // ///////////////////////////////
  /* =========== STATE ========== */
  // ///////////////////////////////
  const [disableSelect, setDisableSelect] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [isPlaidModalDisplayed, setIsPlaidModalDisplayed] =
    React.useState(false);
  const [showErrModal, setShowErrModal] = React.useState(false);
  const [completedProcessing, setCompletedProcessing] = React.useState(false);
  const [isProcessing, setIsProcessing] = React.useState<null | boolean>(false);
  const [selectedInsId, setSelectedInsId] = React.useState<string>('');
  const [selectedInsHasAuth, setSelectedInsHasAuth] = React.useState(false);

  // ///////////////////////////////
  /* ======== MAPPED STATE ====== */
  // ///////////////////////////////
  const currentPage = useSelector(signup.getCurrentPage) as Pages;
  const languageCtx = useSelector((state: WEB.RootState) =>
    language.getSignupPageLanguage(state, Pages.FIND_INSTITUTION)
  );
  const isActiveCheckingRequired = useSelector((state: WEB.RootState) =>
    profile.getValueByField(state, 'is_active_checking_required')
  );

  const handleGoBack = React.useCallback(() => {
    if (isPlaidModalDisplayed) {
      setSelectedInsId('');
      setIsPlaidModalDisplayed(false);
      return true; // block backward navigation
    }
    return false;
  }, [isPlaidModalDisplayed]);

  useBrowserNavigationOverride(handleGoBack, true);

  React.useEffect(() => {
    if (props.message) {
      dispatch(
        addBanner(
          NotificationTypes.WARNING,
          props.message,
          false,
          BannerActionType.LINK_ACCOUNT_WARNING
        )
      );
    }
  }, []);

  // //////////////////////////////////
  /* ====== PLAID LINK CONFIG ====== */
  // //////////////////////////////////
  /**
   * Handle any errors that occur during the linking process.
   * This includes displaying a warning banner and bringing the user back to set up page.
   *
   * @param message - error message to display as banner
   */
  const handlePlaidLinkError = (message: string): void => {
    // Step 1: Bring the user back to the bank setup page
    setIsProcessing(false);

    // Step 2: Add warning banner of error
    const bannerMessage =
      message || 'Internal server error, please try again later.';
    dispatch(
      addBanner(
        NotificationTypes.WARNING,
        bannerMessage,
        false,
        BannerActionType.LINK_ACCOUNT_WARNING
      )
    );
    // Step 3: Reset selections
    setSelectedInsId('');
    disableSelect && setDisableSelect(false);
  };

  /**
   * Handle what happens AFTER Plaid Link + polling success.
   * (1) Start polling for transactions in the background if banking / credit accounts exist
   * (2) Suggest re-selection of institution if institution with no checking accounts was selected
   * (3) Signal to processing page (Processing) that we can proceed to next step
   */
  const handlePlaidLinkSuccess = async (
    accounts: WEB.InstitutionAccount[],
    institutionId: string,
    institutionLoginId: number
  ): Promise<boolean | void> => {
    // Remove any error banner from previous attempts
    dispatch(removeBanner());

    // Step 1: Start polling for transactions in the background if banking / credit accounts exist
    const BANKING_OR_CREDIT = [AccountType.BANKING, AccountType.CREDIT];

    const selectedInstitutionId = selectedInsId || institutionId;

    if (selectedInstitutionId !== institutionId) {
      setSelectedInsId(selectedInstitutionId);
    }

    const bankingAndCreditAccounts =
      accounts instanceof Array
        ? accounts.filter((acc: WEB.InstitutionAccount) =>
            BANKING_OR_CREDIT.includes(acc.account_type)
          )
        : [];

    if (bankingAndCreditAccounts.length) {
      const pollingFunctionArgs = {
        institutionLoginId,
      };

      // Ash 06/09/22: This always fails because the endpoint is not configured in the BFF middleware.
      // We can fix it, but why do we need this?
      /*
      PollingManager.triggerPolling(
        PollingType.TRANSACTIONS,
        pollingFunctionArgs
      );
       */
    }

    // Step 2: Check if selected user has checking accounts with selected institution. If not, take them
    // back to bank setup page and display appropriate warning banner suggesting to choose another ins
    const checkingAccounts = bankingAndCreditAccounts.filter(
      (acc: WEB.InstitutionAccount) => {
        return (
          acc.account_category === AccountCategory.CHECKING ||
          acc?.info?.subtype === AccountCategory.CHECKING
        );
      }
    );
    if (!checkingAccounts.length) {
      handlePlaidLinkError(ErrorMessages.linkAccount.checkingNotFound);
    } else {
      // Step 3: Fetch app data and Indicate that we can proceed to next page (ie. SelectAccount)
      await dispatch(getAppData());
      setCompletedProcessing(true);
    }
  };

  /**
   * Custom callback passed to Plaid Link configuration to control the behavior once
   * Plaid Link signals account linking success.
   *
   * @param token - public token that Plaid gives us to exchange for an access token
   * @param metadata - any relevant metadata regarding request, session, institution
   */
  const handlePlaidOnSuccess = async (
    token: string,
    metadata: OnSuccessMetadata,
    getAuth: boolean
  ): Promise<void> => {
    // Constants
    const STATUS_SUCCESS = 'SUCCESS';

    // Remove any error banner from previous attempts
    dispatch(removeBanner());

    // Add institution login with params
    const params: AddInsLoginParams = {
      public_token: token,
      get_auth: getAuth,
    };

    // Get info from metadata
    if (metadata) {
      params.link_session_id = metadata.link_session_id;
      params.institution_id = metadata.institution?.institution_id;
      const requestId = metadata.request_id;
      if (requestId) {
        params.request_id = requestId;
      }
    }

    // Make a request to add institution login
    const response = await dispatch(addInstitutionLogin(params));

    const responseData = response?.payload?.data || {};
    const pollingId = responseData?.polling_id;
    const successfullyCreated = responseData.status === STATUS_SUCCESS;

    // Successfully created institution login and polling id exists
    // Use the polling id to poll the BE to know when  Plaid has finished loading user's accounts
    if (successfullyCreated && pollingId) {
      const institutionId = metadata.institution.institution_id;
      dispatch(updateSelectedInsId(institutionId));
      const pollingFunctionArgs = {
        pollingId,
        institutionId,
        successHandler: handlePlaidLinkSuccess,
        errorHandler: handlePlaidLinkError,
        isActiveCheckingRequired,
      };
      PollingManager.triggerPolling(PollingType.LINK, pollingFunctionArgs);
      // Did not create institution login (encountered error before polling)
    } else {
      const errorMessage = getErrorMessageFromResponse(response?.payload);

      handlePlaidLinkError(errorMessage);
    }
  };

  /**
   * Navigate user to "processing account" page once user successfully linked with Plaid Link.
   * We look for the specific event "HANDOFF".
   */
  const handlePlaidOnEvent = (event: string): void => {
    const HANDOFF_EVENT = 'HANDOFF';
    const SELECT_INSTITUTION = 'SELECT_INSTITUTION';

    // Re-enable institution click
    if (event === SELECT_INSTITUTION) {
      setDisableSelect(false);
    }

    if (event === HANDOFF_EVENT) {
      setIsProcessing(true);
    }
  };

  /**
   * Reset disable select on exit (if for some reason it wasn't reset already)
   */
  const handlePlaidOnExit = (): void => {
    setSelectedInsId(''); // reset selected ins
    setIsPlaidModalDisplayed(false);
    disableSelect && setDisableSelect(false);
  };

  const plaidLinkConfig: PlaidLinkConfig = {
    handleOnSuccess: handlePlaidOnSuccess,
    handleOnEvent: handlePlaidOnEvent,
    handleOnExit: handlePlaidOnExit,
  };

  const openPlaidLink = usePlaidLink(plaidLinkConfig);

  // //////////////////////////////////
  /* =========== HANDLERS ========== */
  // //////////////////////////////////
  const handleSelectedInsOnClick = (
    institutionId: string,
    insHasAuth?: boolean
  ): void => {
    if (disableSelect || !institutionId) return;

    const PNC_INS_ID = 'ins_13';
    const CAPITAL_ONE_INS_ID = 'ins_9';

    if (!window.Plaid) {
      // If Plaid didn't load to DOM, show error modal
      setShowErrModal(true);
    } else if (
      // If PNC or Capital One, show modal first
      institutionId === PNC_INS_ID ||
      institutionId === CAPITAL_ONE_INS_ID
    ) {
      setShowModal(true);
    } else {
      // Open Plaid Link with institution
      setDisableSelect(true);

      openPlaidLink({
        institution: institutionId,
        getAuth: !!insHasAuth,
      });
      setIsPlaidModalDisplayed(true);
    }

    // Disable institution selection until modal pops up
    setSelectedInsId(institutionId);
    setSelectedInsHasAuth(!!insHasAuth);
  };

  const onClickLater = (): void => {
    onSkip();
  };

  // //////////////////////////////////
  /* ========= MODAL CONFIG ======== */
  // //////////////////////////////////
  const modalDescription =
    'Due to connectivity issues with this particular bank, attempts to link may fail.' +
    ' You may still attempt connecting or go back and try another bank instead.';

  const toggleModal = React.useCallback(() => {
    setShowModal(false);
  }, []);

  const handleOnSubmit = React.useCallback(() => {
    // Open Plaid Link with institution
    openPlaidLink({
      institution: selectedInsId,
      getAuth: !!selectedInsHasAuth,
    });
    setDisableSelect(true);
    setShowModal(false);
  }, [selectedInsId, selectedInsHasAuth]);

  // //////////////////////////////////
  /* =========== LANGUAGE ========== */
  // //////////////////////////////////
  const INSTITUTION_KEY = 'institution';
  const INS_LINK_ERR_MODAL_KEY = 'linkErrModal';
  const ERR_MODAL_KEY = 'errModal';

  const institutionSection = getLanguageSection(languageCtx, INSTITUTION_KEY);
  // Make a copy of this read-only object so we can edit it
  const infoPaneContext = { ...getLanguageInfo(languageCtx) };
  const insLinkErrModal = getLanguageModal(languageCtx, INS_LINK_ERR_MODAL_KEY);
  const errModal = getLanguageModal(languageCtx, ERR_MODAL_KEY);
  const buttons = getLanguageButtons(languageCtx);

  const recordContext =
    infoPaneContext?.records?.[currentPage] ?? infoPaneContext;
  if (recordContext) {
    infoPaneContext.header = recordContext.header;
    // Show side panel text only if it's not the same as the main text
    infoPaneContext.text =
      institutionSection.text !== recordContext.text ? recordContext.text : '';
  }

  const insLinkErrModalConfig = {
    id: 'find-institution-modal',
    title: insLinkErrModal.header || "We're having trouble linking",
    description: insLinkErrModal.text || modalDescription,
    iconType: NotificationTypes.WARNING,
    cancelLabel: insLinkErrModal.secondary || 'Go back',
    submitLabel: insLinkErrModal.primary || 'Use anyway',
    onCancel: toggleModal,
    onSubmit: handleOnSubmit,
    show: showModal,
  };

  const errModalConfig = {
    id: 'find-institution-err-modal',
    title: errModal.header || 'Error',
    description:
      errModal.text ||
      'We’re having trouble connecting to your bank. Please try again later. ',
    iconType: NotificationTypes.WARNING,
    submitLabel: errModal.primary || 'Link bank account later',
    onClickX: () => setShowErrModal(false),
    onSubmit: onClickLater,
    show: showErrModal,
  };

  // //////////////////////////////
  /* ========== RENDER ========= */
  // //////////////////////////////
  const leadText =
    typeof infoPaneContext?.text === 'string'
      ? [infoPaneContext.text || institutionSection.text || '']
      : [];

  const eConsentLink = SplashLink.E_SIGN_CONSENT;

  // Main component
  const BankSetupPage: React.ReactElement = (
    <PageBase
      key={Pages.FIND_INSTITUTION}
      mobileHeader={infoPaneContext?.header || ''}
      mobileLead={leadText}
    >
      <Modal.Basic {...insLinkErrModalConfig} />
      <Modal.Basic {...errModalConfig} />
      <Header>{institutionSection?.header}</Header>
      <InstitutionSelectionGrid
        disabled={disableSelect}
        selectedInsId={selectedInsId}
        description={institutionSection?.text}
        handleOnSelect={handleSelectedInsOnClick}
        hideDescriptionOnMobile
      />
      <Spacer space={spacers.tabSmall} desktopOnly />
      <InfoPaneExpanderGroup
        key='find-inst-info'
        expanders={infoPaneContext.expanders}
        mobileOnly
        addExpanderSubheadSpacing={false}
      />
      <Text color={TextColor.GRAY} size={TextSizes.TINY}>
        By continuing, you agree to&nbsp;
        <a href={eConsentLink} target='_blank' rel='noreferrer'>
          <b>E-Sign Consent</b>
        </a>
        &nbsp;and authorize Albert to transfer money from your bank.
      </Text>
      <Spacer space={spacers.large} />
      <ButtonsContainer>
        {buttons?.link && (
          <Button secondary onClick={onClickLater}>
            <u>{buttons?.link}</u>
          </Button>
        )}
      </ButtonsContainer>
    </PageBase>
  );

  return (
    <>
      <Navbar split signupFlow={fromSignup} />
      <TwoPaneWrapper>
        <InfoPane.Desktop {...infoPaneContext} />
        <ContentPane>
          <BannerPane />
          <ContentContainer>
            {isProcessing ? (
              <Processing
                {...processingInfo}
                canProceed={completedProcessing}
                onSubmit={onSubmit}
              />
            ) : (
              BankSetupPage
            )}
          </ContentContainer>
        </ContentPane>
      </TwoPaneWrapper>
    </>
  );
};

export default FindInstitution;
