import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import ProtectedRoute from 'components/router/ProtectedRoute';
import SignupRerouteWrapper from 'components/router/SignupRerouteWrapper';
import SignupFlow from 'components/signup/SignupFlow';
import Signup from 'pages/unauthenticated/Signup';

const SignupLandingRoutes = (): React.ReactElement => {
  return (
    <Routes>
      <Route path='/' element={<Signup />} />
      <Route
        path='*'
        element={
          <SignupRerouteWrapper>
            <ProtectedRoute>
              <SignupFlow />
            </ProtectedRoute>
          </SignupRerouteWrapper>
        }
      />
    </Routes>
  );
};

export default SignupLandingRoutes;
