import * as React from 'react';
import { useDispatch } from 'react-redux';
import addBanner from 'actions/banner/addBanner';
import removeBanner from 'actions/banner/removeBanner';
import NotificationTypes from 'constants/NotificationTypes';
import { BannerActionType } from 'constants/index';
import useBrowserNavigationOverride from 'hooks/useBrowserNavigationOverride';

/**
 * Hook to interrupt clicking the browser back button with a banner
 *
 * @param blockNavigation - optional callback for conditional blocking
 */
const useBrowserNavigationOverrideWithBanner = (
  blockNavigation?: () => void | boolean
) => {
  const dispatch = useDispatch();
  const hasConditionalBlocking = typeof blockNavigation !== 'undefined';
  useBrowserNavigationOverride(() => {
    if (hasConditionalBlocking && !blockNavigation()) {
      return false;
    }
    dispatch(
      addBanner(
        NotificationTypes.WARNING,
        'You can’t go back from here. Please continue setting up your account.',
        false,
        BannerActionType.HIDE_ICON
      )
    );
    return true;
  }, hasConditionalBlocking);

  React.useEffect(() => {
    return () => {
      // The listener in app.tsx will not remove this specific banner
      // on page navigation, so handle it here
      dispatch(removeBanner());
    };
  }, [dispatch]);
};

export default useBrowserNavigationOverrideWithBanner;
