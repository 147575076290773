import { FORM_ERROR } from 'final-form';
import * as React from 'react';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import updateProfile, {
  UpdateProfileFields,
} from 'actions/async/updateProfile';
import removeBanner from 'actions/banner/removeBanner';
import getNextPage from 'actions/getNextPage';
import Button from 'components/common/Button';
import Dropdown from 'components/common/Dropdown';
import { DropdownWidthSize } from 'components/common/Dropdown/Dropdown';
import Input from 'components/common/Input';
import { InputWidth } from 'components/common/Input/shared';
import Radio from 'components/common/Radio';
import Spacer from 'components/common/Spacer';
import ButtonsContainer from 'components/layout/ButtonsContainer';
import PageBase from 'components/layout/PageBase';
import { DATE_FULLY_ENTERED } from 'constants/DateInputValidation';
import Pages from 'constants/Pages';
import useNavigateFunction from 'hooks/useNavigateFunction';
import useVerifyCurrentPage from 'hooks/useVerifyCurrentPage';
import * as language from 'reducers/entities/language';
import * as profile from 'reducers/entities/profile';
import { spacers } from 'styles';
import { spacers as homeSpacers } from 'styles/home';
import * as WEB from 'types/interfaces';
import { convertDateStringToUnix, getMonthDayYearFromUnix } from 'utils/dates';
import { delayWithState } from 'utils/delay';
import { formatDropdownOptions } from 'utils/dropdown';
import {
  getLanguageButtons,
  getLanguageInfo,
  getLanguageSection,
} from 'utils/getFromLanguage';
import {
  composeValidators,
  isRequired,
  maxValue,
  minValue,
  validBirthday,
} from 'utils/validators';

type Props = {
  nextPage: string;
};

const HiddenField = styled.input`
  display: none;
`;

const ProfileEmploymentInfo = (props: Props): React.ReactElement | null => {
  const { updateCurrentSignupPage } = useNavigateFunction();
  // Make Redux is up-to-date with currentSignUpPage
  useVerifyCurrentPage(Pages.PROFILE_EMPLOYMENT_INFO);

  // ///////////////////////////////
  /* =========== HOOKS ========== */
  // ///////////////////////////////
  const dispatch = useDispatch();

  // ///////////////////////////////
  /* ======== MAPPED STATE ====== */
  // ///////////////////////////////
  const languageCtx = useSelector((state: WEB.RootState) =>
    language.getSignupPageLanguage(state, Pages.PROFILE_EMPLOYMENT_INFO)
  );

  // Profile info
  const birthdayDefault = useSelector((state: WEB.RootState) =>
    profile.getValueByField(state, 'birthday')
  );

  const isMarriedDefault: boolean | null = useSelector((state: WEB.RootState) =>
    profile.getInfoValueByField(state, 'personal_info', 'married')
  );

  const numKidsDefault: number = useSelector(
    (state: WEB.RootState) =>
      profile.getInfoValueByField(state, 'personal_info', 'kids') || 0
  );

  // Employment status
  const occupationDefault = useSelector(
    (state: WEB.RootState) =>
      profile.getValueByField(state, 'occupation') as string | null
  );
  const paymentTypeDefault = useSelector(
    (state: WEB.RootState) =>
      profile.getValueByField(state, 'income_type') as string | null
  );
  const incomeSourceDefault = useSelector(
    (state: WEB.RootState) =>
      profile.getValueByField(state, 'income_source') as string | null
  );
  const previousAnnualIncome = useSelector(
    (state: WEB.RootState) =>
      profile.getValueByField(state, 'income') as number | null
  );
  const netWorthDefault = useSelector(
    (state: WEB.RootState) =>
      profile.getInfoValueByField(state, 'investment_info', 'net_worth') as
        | string
        | null
  );

  // ///////////////////////////////////////
  /* =========== DEFAULT VALUE ========== */
  // ///////////////////////////////////////
  let defaultBirthdayValue = '';
  if (birthdayDefault) {
    // Unix to javascript Date object
    const [monthDefault, dayDefault, yearDefault] = getMonthDayYearFromUnix(
      birthdayDefault,
      false,
      true
    );
    const month = Number(monthDefault) < 10 ? `0${monthDefault}` : monthDefault;
    const day = Number(dayDefault) < 10 ? `0${dayDefault}` : dayDefault;
    defaultBirthdayValue = `${month}/${day}/${yearDefault}`;
  }

  // ///////////////////////////////
  /* =========== STATE ========== */
  // ///////////////////////////////
  // Profile info
  const [isMarried, setIsMarried] = React.useState<boolean | null>(
    isMarriedDefault || null
  );

  // Employment status
  const [occupation, setOccupation] = React.useState<string | null>(
    occupationDefault || null
  );
  const [paymentType, setPaymentType] = React.useState<string | null>(
    paymentTypeDefault || null
  );
  const [incomeSource, setIncomeSource] = React.useState<string | null>(
    incomeSourceDefault || null
  );

  const [netWorth, setNetWorth] = React.useState<string | null>(
    netWorthDefault || null
  );

  const [isCompleted, setIsCompleted] = React.useState<boolean>(false);
  const [submitAttempted, setSubmitAttempted] = React.useState<boolean>(false);
  const [isLastDropdownOpen, setIsLastDropdownOpen] = React.useState(false);

  const showOldIncomeField = previousAnnualIncome === null;

  // //////////////////////////////////
  /* =========== HANDLERS ========== */
  // //////////////////////////////////
  const handleIsLastDropdownOpen = () => {
    setIsLastDropdownOpen(true);
  };
  const handleIsLastDropdownClosed = () => {
    setIsLastDropdownOpen(false);
  };
  const checkInvalid = (): boolean => {
    // User did not select an option for married section
    if (isMarried === null) {
      return true;
    }

    if (occupation == null) {
      return true;
    }
    if (paymentType == null) {
      return true;
    }
    if (incomeSource == null) {
      return true;
    }
    if (showIncomeRangeSection && incomeRange == null) {
      return true;
    }
    if (showNetWorthSection && netWorth == null) {
      return true;
    }

    return false;
  };

  const handleOnNext = async (values: {
    birthday: string;
    kids: number;
    income: number;
  }) => {
    // Remove any error banner from previous attempts
    dispatch(removeBanner());

    // Check validity for manually validated inputs
    const invalid = checkInvalid();
    if (invalid) return { [FORM_ERROR]: 'error' };

    const { birthday, kids, income } = values;

    // Generate unix timestamp
    if (!birthday) return;

    const birthdayUnix = convertDateStringToUnix(birthday, 'MM/dd/yyyy');
    const netWorthBracket = netWorthOptions.findIndex(
      (option) => option.value === netWorth
    );

    const updateContext: UpdateProfileFields = {
      birthday: birthdayUnix,
      married: isMarried as boolean,
      kids,
      // Set set_pin and saved_contact so that users do not go thru signup flow again in app.
      // Set pin and save contact are one of the first few screens in app that users are checkpointed to.
      // NOTE: Set pin to True is ok because users are prompted to set pin in app if not yet already.
      personal_info_set_pin: true,
      personal_info_saved_contact: false,
      income: (showIncomeRangeSection ? incomeRange : income) || 0,
      occupation: occupation as string,
      income_type: paymentType as string,
      income_source: incomeSource as string,
    };
    if (showNetWorthSection) {
      updateContext.investment_info = {
        net_worth: netWorth || undefined,
        net_worth_bracket: netWorthBracket > -1 ? netWorthBracket : undefined,
      };
    }

    const { error }: any = await dispatch(updateProfile(updateContext));

    if (error) return { [FORM_ERROR]: 'error' };
  };

  const submitCallback = async (errors?: any) => {
    if (!errors) {
      const { nextPageOverride }: any = await dispatch(getNextPage());
      await delayWithState(400, setIsCompleted);
      dispatch(
        updateCurrentSignupPage({ page: nextPageOverride || props.nextPage })
      );
    }
  };

  const handleIsMarriedOnClick = (value: boolean) => {
    setIsMarried(value);
  };

  const handleOccupationOnChange = ({ key }: { key: number }): void => {
    const value = occupationOptions[key]?.value || '';
    setOccupation(value);
  };
  const handlePaymentTypeOnChange = ({ key }: { key: number }): void => {
    const value = paymentTypeOptions[key]?.value || '';
    setPaymentType(value);
  };
  const handleIncomeSourceOnChange = ({ key }: { key: number }): void => {
    const value = incomeSourceOptions[key]?.value || '';
    setIncomeSource(value);
  };
  const handleIncomeRangeOnChange = ({ key }: { key: number }): void => {
    const value = incomeRangeOptions?.[key]?.value || null;
    setIncomeRange(value);
  };
  const handleNetWorthOnChange = ({ key }: { key: number }): void => {
    const value = netWorthOptions?.[key]?.value || '';
    setNetWorth(value);
  };

  // //////////////////////////////////
  /* =========== LANGUAGE ========== */
  // //////////////////////////////////

  // Section keys
  const BIRTHDAY_KEY = 'birthday';
  const MARRIED_KEY = 'married';
  const KIDS_KEY = 'kids';
  const OCCUPATION_KEY = 'occupation';
  const PAYMENT_TYPE_KEY = 'paymentType';
  const INCOME_SOURCE_KEY = 'incomeSource';
  const ANNUAL_INCOME_KEY = 'annualIncome';
  const NET_WORTH_KEY = 'netWorth';

  // Sections
  const birthdaySection = getLanguageSection(languageCtx, BIRTHDAY_KEY);
  const marriedSection = getLanguageSection(languageCtx, MARRIED_KEY);
  const kidsSection = getLanguageSection(languageCtx, KIDS_KEY);
  const occupationSection = getLanguageSection(languageCtx, OCCUPATION_KEY);
  const occupationOptions = (occupationSection?.options || []) as {
    display: string;
    value: string;
  }[];
  const paymentTypeSection = getLanguageSection(languageCtx, PAYMENT_TYPE_KEY);
  const paymentTypeOptions = (paymentTypeSection?.options || []) as {
    display: string;
    value: string;
  }[];
  const incomeSourceSection = getLanguageSection(
    languageCtx,
    INCOME_SOURCE_KEY
  );
  const incomeSourceOptions = (incomeSourceSection?.options || []) as {
    display: string;
    value: string;
  }[];
  const annualIncomeSection = getLanguageSection(
    languageCtx,
    ANNUAL_INCOME_KEY
  );
  const incomeRangeOptions = (annualIncomeSection?.options || []) as {
    display: string;
    value: number;
  }[];
  const showIncomeRangeSection = !!annualIncomeSection?.options;

  const incomeRangeDefault = incomeRangeOptions.find(
    (option) => option.value === previousAnnualIncome
  )?.value;

  // Income range is represented by a number in the middle of the range
  // e.g. User selects "0-10k"; we set 5000
  const [incomeRange, setIncomeRange] = React.useState<number | null>(
    incomeRangeDefault || null
  );

  const netWorthSection = getLanguageSection(languageCtx, NET_WORTH_KEY);
  const netWorthOptions = (netWorthSection?.options || []) as {
    display: string;
    value: string;
  }[];
  const showNetWorthSection = !!netWorthSection?.options;

  const infoPaneContext = getLanguageInfo(languageCtx);

  // Buttons
  const buttonsCtx = getLanguageButtons(languageCtx);

  // Formatted data for dropdowns
  const [occupationItems, occupationDefaultDisplay] = formatDropdownOptions(
    occupationOptions,
    occupationDefault
  );
  const [paymentTypeItems, paymentTypeDefaultDisplay] = formatDropdownOptions(
    paymentTypeOptions,
    paymentTypeDefault
  );
  const [incomeSourceItems, incomeSourceDefaultDisplay] = formatDropdownOptions(
    incomeSourceOptions,
    incomeSourceDefault
  );
  const [incomeRangeItems, incomeRangeDefaultDisplay] = formatDropdownOptions(
    incomeRangeOptions,
    incomeRangeDefault || null
  );
  const [netWorthItems, netWorthDefaultDisplay] = formatDropdownOptions(
    netWorthOptions,
    netWorthDefault
  );

  // ///////////////////////////////////////
  /* =========== FORM ELEMENTS ========== */
  // ///////////////////////////////////////

  const BirthdayInput = ({ input, meta }: Partial<WEB.InputFieldProps>) => {
    const value = input?.value as string | undefined;

    const isInvalid =
      (meta?.submitFailed || (value && DATE_FULLY_ENTERED.test(value))) &&
      meta?.error;

    return (
      <Input.Date
        id='birthday'
        key='birthday'
        floatingLabel={languageCtx.content.birthday.placeholder || 'Birthday'}
        secondaryLabel='(MM/DD/YYYY)'
        label={birthdaySection?.header}
        description={birthdaySection?.text}
        widthSize={InputWidth.SMALL}
        valueDefault={value}
        {...input}
        invalid={isInvalid}
        errorText={validBirthday(value || '')}
      />
    );
  };

  const KidsInput = ({ input, meta }: Partial<WEB.InputFieldProps>) => {
    const value = input?.value as string | undefined;
    return (
      <Input.Number
        id='num-kids-input'
        floatingLabel={kidsSection?.placeholder}
        label={kidsSection?.header}
        description={kidsSection?.text}
        widthSize={InputWidth.SMALL}
        min='0'
        max='30'
        valueDefault={value}
        {...input}
        invalid={meta?.error && meta.submitFailed && !meta.dirtySinceLastSubmit}
        errorText={kidsSection?.errorMessage || meta?.error}
      />
    );
  };

  const IncomeInput = ({ input, meta }: Partial<WEB.InputFieldProps>) => {
    const value = input?.value as string | undefined;
    return (
      <Input.Currency
        id='annual-income-input'
        valueDefault={value}
        placeholder={annualIncomeSection?.placeholder}
        label={annualIncomeSection?.header}
        description={annualIncomeSection?.text}
        widthSize={InputWidth.MEDIUM}
        min='0'
        {...input}
        invalid={(meta?.dirty || meta?.touched) && meta.error}
        errorText={annualIncomeSection?.errorMessage || meta?.error}
        defaultNoValueToZero={false}
      />
    );
  };

  const invalidMarriage = submitAttempted && isMarried == null;

  // @todo Remove temporary array check after backend is updated to no longer send an array
  const texts =
    infoPaneContext?.text instanceof Array
      ? infoPaneContext.text
      : [infoPaneContext?.text || ''];

  return (
    <>
      <PageBase mobileHeader={infoPaneContext?.header || ''} mobileLead={texts}>
        <Form
          onSubmit={handleOnNext}
          initialValues={{
            birthday: defaultBirthdayValue,
            kids: numKidsDefault,
            income: previousAnnualIncome,
          }}
          render={({ handleSubmit, submitting }) => (
            <form
              onSubmit={(event) => {
                setSubmitAttempted(true);
                handleSubmit(event)?.then(submitCallback);
              }}
            >
              <Field
                name='birthday'
                component={BirthdayInput}
                validate={composeValidators(validBirthday)}
              />
              <Spacer space={spacers.tabLarge} desktopOnly />
              <Radio.Group
                id='married-radio-buttons'
                label={marriedSection?.header}
                errorText={marriedSection?.errorMessage}
                description={marriedSection?.text}
                invalid={invalidMarriage}
              >
                <Radio.Button
                  id='married-yes'
                  name='married'
                  selected={isMarried !== null && !!isMarried}
                  label='Yes'
                  value
                  onClick={handleIsMarriedOnClick}
                  invalid={invalidMarriage}
                />
                <Radio.Button
                  id='married-no'
                  name='married'
                  selected={isMarried !== null && !isMarried}
                  label='No'
                  value={false}
                  onClick={handleIsMarriedOnClick}
                  invalid={invalidMarriage}
                />
              </Radio.Group>
              <Spacer space={spacers.tabLarge} desktopOnly />
              <Field
                name='kids'
                component={KidsInput}
                validate={composeValidators(minValue(0), maxValue(30))}
              />

              {/* EmploymentStatus inputs */}
              <Spacer space={spacers.tabLarge} desktopOnly />
              <Dropdown.Menu
                id='employment-menu'
                label={occupationSection?.header}
                defaultValue={occupationDefaultDisplay}
                description={occupationSection?.text}
                floatingLabel={occupationSection?.placeholder}
                items={occupationItems}
                invalid={submitAttempted && occupation === null}
                errorText={occupationSection?.errorMessage}
                onSelect={handleOccupationOnChange}
                widthSize={DropdownWidthSize.MEDIUM}
                showError
              />
              <Spacer space={spacers.tabLarge} desktopOnly />
              <Dropdown.Menu
                id='payment-type-menu'
                label={paymentTypeSection?.header}
                defaultValue={paymentTypeDefaultDisplay}
                floatingLabel={paymentTypeSection?.placeholder}
                items={paymentTypeItems}
                invalid={submitAttempted && paymentType === null}
                errorText={paymentTypeSection?.errorMessage}
                onSelect={handlePaymentTypeOnChange}
                widthSize={DropdownWidthSize.MEDIUM}
                showError
              />
              <Spacer space={spacers.tabLarge} desktopOnly />
              <Dropdown.Menu
                id='income-source-menu'
                label={incomeSourceSection?.header}
                defaultValue={incomeSourceDefaultDisplay}
                floatingLabel={incomeSourceSection?.placeholder}
                items={incomeSourceItems}
                invalid={submitAttempted && incomeSource === null}
                errorText={incomeSourceSection?.errorMessage}
                onSelect={handleIncomeSourceOnChange}
                widthSize={DropdownWidthSize.MEDIUM}
                showError
              />
              <Spacer space={spacers.tabLarge} desktopOnly />
              {showIncomeRangeSection ? (
                <Dropdown.Menu
                  id='annual-income-menu'
                  label={annualIncomeSection?.header}
                  defaultValue={incomeRangeDefaultDisplay}
                  floatingLabel={annualIncomeSection?.placeholder}
                  items={incomeRangeItems}
                  invalid={submitAttempted && incomeRange === null}
                  errorText={annualIncomeSection?.errorMessage}
                  onSelect={handleIncomeRangeOnChange}
                  widthSize={DropdownWidthSize.MEDIUM}
                  showError
                />
              ) : (
                <Field
                  name='income'
                  component={showOldIncomeField ? IncomeInput : HiddenField}
                  validate={composeValidators(isRequired, minValue(0))}
                />
              )}
              {showNetWorthSection && (
                <>
                  <Spacer space={spacers.tabLarge} desktopOnly />
                  <Dropdown.Menu
                    id='net-worth-menu'
                    label={netWorthSection?.header}
                    defaultValue={netWorthDefaultDisplay}
                    floatingLabel={netWorthSection?.placeholder}
                    items={netWorthItems}
                    invalid={submitAttempted && netWorth === null}
                    errorText={netWorthSection?.errorMessage}
                    onSelect={handleNetWorthOnChange}
                    widthSize={DropdownWidthSize.MEDIUM}
                    onOpen={handleIsLastDropdownOpen}
                    onClose={handleIsLastDropdownClosed}
                  />
                  {isLastDropdownOpen && <Spacer space={homeSpacers.g18} />}
                </>
              )}

              <ButtonsContainer>
                <Button
                  id='profile-info-confirm-button'
                  isCompleted={isCompleted}
                  isLoading={submitting}
                  disabled={submitting}
                >
                  {isCompleted ? buttonsCtx?.complete : buttonsCtx?.primary}
                </Button>
              </ButtonsContainer>
            </form>
          )}
        />
        {showNetWorthSection && <Spacer space={spacers.tab} desktopOnly />}
      </PageBase>
    </>
  );
};

export default ProfileEmploymentInfo;
