import AlbertTrackingEvent from './AlbertTrackingEvent';

const GTMEvent = {
  [AlbertTrackingEvent.SIGNED_UP]: 'AW-932828725/_LgPCPmvpcMCELWs57wD',
  [AlbertTrackingEvent.ADDED_ACCOUNT]: 'AW-932828725/mYRxCMjJq8MCELWs57wD',
  [AlbertTrackingEvent.ENABLED_SAVINGS]: 'AW-932828725/P8EjCJ6CrMMCELWs57wD',
  [AlbertTrackingEvent.ENABLED_GENIUS]: 'AW-932828725/OOvLCKeLq8MCELWs57wD',
  [AlbertTrackingEvent.ENABLED_GENIUS_ANNUAL]:
    'AW-932828725/ysOvCOWOrMMCELWs57wD',
  [AlbertTrackingEvent.ENABLED_GENIUS_PRIMARY_CHECKING_GREATER_THAN_100]:
    'AW-932828725/W-VYCNrGrcMCELWs57wD',
  [AlbertTrackingEvent.COMPLETED_INSTANT_ONBOARDING]:
    'AW-932828725/OCyeCMaYrMMCELWs57wD',
  [AlbertTrackingEvent.COMPLETED_INVESTMENT_APPLICATION]:
    'AW-932828725/k6tWCKaph8MCELWs57wD',

  // Albert Cash.
  [AlbertTrackingEvent.OPENED_CASH_BANK_ACCOUNT]:
    'AW-932828725/s_Y-CInh_YwDELWs57wD',
};

export default GTMEvent;
