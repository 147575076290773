import cookie from 'cookie';
import addBanner from 'actions/banner/addBanner';
import types from 'actions/types';
import AlbertClient from 'api/AlbertClient';
import { ErrorMessages, NotificationTypes } from 'constants/index';
import * as WEB from 'types/interfaces';
import { onError } from 'utils/axiosMiddlewareUtils';
import getErrorMessageFromResponse from 'utils/getErrorMessageFromResponse';
import { logger } from 'utils/logger';
import { BUCKET_KEY } from 'utils/percentageRollout';

export default (): WEB.ActionCreator<Promise<Record<string, any>>> =>
  async (dispatch: WEB.Dispatch): Promise<Record<string, any>> => {
    let error = false;

    const response: any = await dispatch({
      types: [
        types.GET_PROFILE_REQUEST,
        types.GET_PROFILE_SUCCESS,
        types.GET_PROFILE_FAILURE,
      ],
      payload: {
        request: {
          method: 'get',
          headers: {
            'X-Forwarded-User-Agent': navigator.userAgent,
          },
          url: AlbertClient.profileView(),
        },
        options: {
          onError: (middlewareOptions: any, options: any) => {
            error = true;

            // Display error banner only if we are not redirecting user to 2fa (403)
            if (middlewareOptions?.error?.response?.status !== 403) {
              const message =
                getErrorMessageFromResponse(
                  middlewareOptions?.error?.response
                ) || ErrorMessages.web.generic;
              dispatch(addBanner(NotificationTypes.WARNING, message, false));
            }

            return onError(middlewareOptions, options);
          },
        },
      },
    });

    /* 2022/3/16, MPR: Don't love this side effect here but it seems like the
     * most sensible place to do this. Otherwise we'll need it in multiple places
     * which will likely be less clear and more error prone.
     */
    const cookies = cookie.parse(document.cookie);
    const storageBucket = localStorage.getItem(BUCKET_KEY);
    const cookieBucket = cookies[BUCKET_KEY];

    if (cookieBucket != null) {
      if (storageBucket != null && storageBucket !== cookieBucket) {
        logger.debug(
          `Overwriting old cohort ${storageBucket} with updated ${cookieBucket}`
        );
      }
      localStorage.setItem(BUCKET_KEY, cookieBucket);
    } else {
      logger.warn(
        'No cohort bucket received on login. This is unexpected. Defaulting cohort bucket.'
      );
      const domain = window.albertWeb.IsLocal
        ? ''
        : ';domain=.albert.com;secure';
      if (storageBucket != null) {
        document.cookie = `${BUCKET_KEY}=${storageBucket};path=/${domain}`;
      } else {
        document.cookie = `${BUCKET_KEY}=100;path=/${domain}`;
        localStorage.setItem(BUCKET_KEY, '100');
      }
    }

    return { error, response };
  };
