import { KeyboardEventKeys } from 'constants/index';

/**
 * Handles key down event (only Enter or Spacebar for now)
 */
export const handleActivateKeyDown = (
  e: React.KeyboardEvent<HTMLElement | SVGSVGElement>,
  onClick?: (e?: any) => void
): void => {
  if (
    onClick &&
    (e.key === KeyboardEventKeys.SPACEBAR || e.key === KeyboardEventKeys.ENTER)
  ) {
    // Remove focus
    e?.currentTarget?.blur();
    onClick();
  }
};
