import * as Sentry from '@sentry/react';
import { NodeEnvironment } from 'constants/index';

export enum LogLevel {
  ERROR = 'error',
  WARN = 'warn',
  LOG = 'log',
  INFO = 'info',
  DEBUG = 'debug',
}

type Log = {
  logLevel: LogLevel;
  timestamp: number;
  messages: LogMessages;
};

type LogMessages = any[];

const DEFAULT_QUEUE_SIZE_THRESHOLD = 50;
const LOCAL_STORAGE_KEYS = {
  logLevel: 'albertweblogger-logLevel',
  queueSizeThreshold: 'albertweblogger-queueSizeThreshold',
};

class NamedError extends Error {
  constructor(name: string) {
    super();
    this.name = name;
  }
}

class Logger {
  /**
   * Logs less severe than this level will not be sent.
   * This intial value is for the dev environment.
   */
  #logLevel = LogLevel.DEBUG;

  /**
   * Queued logs waiting to be sent to external loggers
   * (e.g. the browser's console)
   */
  #queuedLogs: Log[] = [];

  /**
   * Number of logs to send in a batch.
   * This initial value to send logs immediately
   * is for the dev environment.
   */
  #queueSizeThreshold = 0;

  /**
   * Has the log queue grown beyond its max length
   * for the first time.
   */
  #hasExceededQueue = false;

  logLevelPriorities = {
    [LogLevel.ERROR]: 0,
    [LogLevel.WARN]: 1,
    [LogLevel.LOG]: 2,
    [LogLevel.INFO]: 3,
    [LogLevel.DEBUG]: 4,
  };

  logLevelColors = {
    [LogLevel.ERROR]: 'red',
    [LogLevel.WARN]: 'orange',
    [LogLevel.LOG]: '#82d993', // green
    [LogLevel.INFO]: '#67b0f5', // blue
    [LogLevel.DEBUG]: '#9c8bfc', // purple
  };

  constructor() {
    /* Reuse the logger settings from the last session
    (for smooth debugging between hot reloads) */
    const lastLogLevel = localStorage.getItem(LOCAL_STORAGE_KEYS.logLevel);
    const lastQueueSizeThreshold = localStorage.getItem(
      LOCAL_STORAGE_KEYS.queueSizeThreshold
    );

    if (window.albertWeb.Environment === NodeEnvironment.PROD) {
      this.#logLevel = LogLevel.ERROR;
      if (lastQueueSizeThreshold !== '0') {
        this.silence();
      }
    }

    if (lastLogLevel) this.#logLevel = lastLogLevel as LogLevel;
    if (lastQueueSizeThreshold)
      this.#queueSizeThreshold = Number(lastQueueSizeThreshold);

    console.log(
      '%cWelcome to the Albert Web Console.\n' +
        "   - To show Albert's logs: window.albertWeb.logger.listen()\n" +
        "   - To hide Albert's logs: window.albertWeb.logger.silence()\n\n" +
        'Can\'t see "debug"-level logs? Ensure that your console log filter includes "debug" or "verbose".',
      `color: ${this.logLevelColors.info}; font-size: 13px`
    );
  }

  initialize() {
    /* 2021/05/25, MPR: well this one came out very exciting */
    window.albertWeb.logger = this;
  }

  // /////////////////////////////////////////////////////////////////////////////
  // Main logging methods
  // /////////////////////////////////////////////////////////////////////////////

  error(...args: any) {
    Sentry.captureException(new Error(args.join(' ')));
    this.#queueLog(LogLevel.ERROR, Object.values(args));
  }

  warn(...args: any) {
    Sentry.addBreadcrumb({
      category: 'console',
      message: args.join(' '),
      level: 'warning',
    });
    this.#queueLog(LogLevel.WARN, Object.values(args));
  }

  log(...args: any) {
    Sentry.addBreadcrumb({
      category: 'console',
      message: args.join(' '),
      level: 'log',
    });
    this.#queueLog(LogLevel.LOG, Object.values(args));
  }

  info(...args: any) {
    Sentry.addBreadcrumb({
      category: 'console',
      message: args.join(' '),
      level: 'info',
    });
    this.#queueLog(LogLevel.INFO, Object.values(args));
  }

  debug(...args: any) {
    this.#queueLog(LogLevel.DEBUG, Object.values(args));
  }

  message(message: string) {
    try {
      // This is a hack to get around the fact that Sentry doesn't support
      // custom error names. We throw an error with the name as the title.
      throw new NamedError(message);
    } catch (err) {
      Sentry.captureException(err, { level: 'log' });
    }
  }

  // /////////////////////////////////////////////////////////////////////////////
  // Logger get methods
  // /////////////////////////////////////////////////////////////////////////////

  /**
   * Get the log level.
   */
  getLogLevel(): LogLevel {
    return this.#logLevel;
  }

  /**
   * Get the logs queued to be sent.
   * @param resetQueue if true, reset the log queue to empty.
   * @returns the array of queued logs.
   */
  getQueuedLogs(resetQueue = true): Log[] {
    const queuedLogs = this.#queuedLogs;
    if (resetQueue) this.#queuedLogs = [];
    return queuedLogs;
  }

  /**
   * Get the queue size threshold.
   */
  getQueueSizeThreshold(): number {
    return this.#queueSizeThreshold;
  }

  // /////////////////////////////////////////////////////////////////////////////
  // Logger set methods
  // /////////////////////////////////////////////////////////////////////////////

  /**
   * Set the log level.
   * Logs less severe than this level will not be sent.
   * @param logLevel
   */
  setLogLevel(logLevel: LogLevel) {
    if (!Object.values(LogLevel).includes(logLevel)) {
      throw new Error(`Unrecognized log level: ${logLevel}`);
    } else {
      this.#logLevel = logLevel;
      localStorage.setItem(LOCAL_STORAGE_KEYS.logLevel, logLevel);
    }
  }

  /**
   * Silence displaying logs in the browser's console.
   */
  silence() {
    this.#queueSizeThreshold = DEFAULT_QUEUE_SIZE_THRESHOLD;
    localStorage.setItem(
      LOCAL_STORAGE_KEYS.queueSizeThreshold,
      `${this.#queueSizeThreshold}`
    );
  }

  /**
   * Listen (display) logs in the browser's console.
   * Also emit all queued logs.
   */
  listen() {
    this.#queueSizeThreshold = 0;
    this.#sendLogs();
    localStorage.setItem(
      LOCAL_STORAGE_KEYS.queueSizeThreshold,
      `${this.#queueSizeThreshold}`
    );
  }

  // /////////////////////////////////////////////////////////////////////////////
  // Internal logging methods
  // /////////////////////////////////////////////////////////////////////////////

  /**
   * Add a log to the queue.
   * @param logLevel
   * @param args
   */
  #queueLog = (logLevel: LogLevel, messages: LogMessages) => {
    const requiredLogLevelPriority = this.logLevelPriorities[this.#logLevel];
    const logLevelPriority = this.logLevelPriorities[logLevel];

    // Only create logs with a severity that meets the "logLevel" setting
    if (logLevelPriority <= requiredLogLevelPriority) {
      this.#queuedLogs.push({
        logLevel,
        timestamp: Date.now(),
        messages,
      });

      // Don't queue logs, display the log immediately
      if (!this.#queueSizeThreshold) {
        this.#sendLogs();

        // Queue size exceeded
      } else if (this.#queuedLogs.length > this.#queueSizeThreshold) {
        // Maintain the queue size by popping the oldest log
        this.#queuedLogs.splice(0, 1);

        // Display a warning for the first time this happens
        if (!this.#hasExceededQueue) {
          this.#hasExceededQueue = true;
          console.warn(
            'Console message queue size exceeded, older messages are now being dropped.\nCall albertWeb.logger.listen() to see logs in real time.'
          );
        }
      }
    }
  };

  /**
   * Send all queued logs.
   *
   * Note: Our current version of TS doesn't allow #sendLogs() {} syntax,
   * but it was introduced in TS 4.3 beta
   * https://github.com/microsoft/TypeScript/issues/37677
   * https://devblogs.microsoft.com/typescript/announcing-typescript-4-3-beta/#ecmascript-private-class-elements
   *
   * (The arrow function here will be created new per class instantiation, rather then shared between instances.
   * But considering that we'll only ever have 1 logger instance in the app, this shouldn't matter.)
   */
  #sendLogs = () => {
    this.#queuedLogs.forEach((log) => {
      const { logLevel, timestamp, messages } = log;
      this.#sendConsoleLog(logLevel, timestamp, messages);
    });
    this.#queuedLogs = [];
  };

  // /////////////////////////////////////////////////////////////////////////////
  // External Loggers
  // /////////////////////////////////////////////////////////////////////////////

  /**
   * Send a browser console style log.
   * @param logLevel
   * @param messages
   */
  #sendConsoleLog = (
    logLevel: LogLevel,
    timestamp: number,
    messages: LogMessages
  ) => {
    const color = this.logLevelColors[logLevel];
    const time = new Date(timestamp).toLocaleTimeString();
    console[logLevel](
      `%c${time} |`,
      `color: ${color}; font-weight: 600;`,
      ...messages
    );
  };
}

export default Logger;

export const logger = new Logger();
