import types from 'actions/types';
import AlbertClient from 'api/AlbertClient';
import TrackingEventType from 'constants/TrackingEventType';
import * as WEB from 'types/interfaces';

export type TrackInfoParams = {
  [key: string]: string | number;
};

export type TrackParams = {
  event_type: TrackingEventType;
  timestamp: number;

  event_subname?: string;
  // Optional unless event_type "c" (CLICK)
  event_name?: string;
  // Optional unless event_type "n" (INSTALL)
  source?: string;
  campaign?: string;
} & TrackInfoParams;

export default (params: TrackParams): WEB.Action => ({
  types: [
    types.TRACK_ANALYTICS_REQUEST,
    types.TRACK_ANALYTICS_SUCCESS,
    types.TRACK_ANALYTICS_FAILURE,
  ],
  payload: {
    request: {
      method: 'post',
      url: AlbertClient.trackingView(),
      data: {
        ...params,
      },
    },
  },
});
