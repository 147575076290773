import * as Sentry from '@sentry/react';
import React, { lazy } from 'react';

export const InfoPaneLottie = wrapSuspense(
  lazy(
    () =>
      import(
        /* webpackChunkName: "info-pane-lottie" */
        /* webpackPrefetch: true */
        './InfoPaneLottie'
      )
  )
);
export const SavingsBonusLottie = wrapSuspense(
  lazy(
    () =>
      import(
        /* webpackChunkName: "savings-bonus-lottie" */
        /* webpackPrefetch: true */
        './SavingsBonusLottie'
      )
  )
);
export const SelectCardLottie = wrapSuspense(
  lazy(
    () =>
      import(
        /* webpackChunkName: "select-card-lottie" */
        /* webpackPrefetch: true */
        './SelectCardLottie'
      )
  )
);
export const SuperchargeLottie = wrapSuspense(
  lazy(
    () =>
      import(
        /* webpackChunkName: "supercharge-lottie" */
        /* webpackPrefetch: true */
        './SuperchargeLottie'
      )
  )
);
export const TieredPricingLottie = wrapSuspense(
  lazy(
    () =>
      import(
        /* webpackChunkName: "tiered-pricing-lottie" */
        /* webpackPrefetch: true */
        './TieredPricingLottie'
      )
  )
);

function wrapSuspense<P extends object>(
  WrappedComponent: React.ComponentType<P>,
  fallback: React.SuspenseProps['fallback'] = null
) {
  return function ComponentWithSuspense(props: P) {
    return (
      <ErrorBoundary>
        <React.Suspense fallback={fallback}>
          <WrappedComponent {...props} />
        </React.Suspense>
      </ErrorBoundary>
    );
  };
}

class ErrorBoundary extends React.Component<
  { children: React.ReactNode },
  { hasError: boolean }
> {
  state = { hasError: false };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(err: any) {
    Sentry.captureException(err, {
      extra: { message: 'Rendered error boundary' },
    });
  }

  render() {
    if (this.state.hasError) return null;
    return this.props.children;
  }
}
