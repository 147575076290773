import * as React from 'react';

export function SaveMoneyIconV2() {
  return (
    <svg
      width='53'
      height='52'
      viewBox='0 0 53 52'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M13.4717 40C13.4717 39.4477 13.9194 39 14.4717 39H17.4717C18.024 39 18.4717 39.4477 18.4717 40V46C18.4717 46.5523 18.024 47 17.4717 47H14.4717C13.9194 47 13.4717 46.5523 13.4717 46V40Z'
        fill='#1A405C'
      />
      <rect x='34.4717' y='39' width='5' height='8' rx='1' fill='#1A405C' />
      <rect x='4.47168' y='7' width='44' height='38' rx='4' fill='#48B1FE' />
      <rect
        x='9.47168'
        y='12'
        width='34'
        height='28'
        rx='2'
        fill='#48B1FE'
        stroke='#1A405C'
        strokeWidth='2'
      />
      <circle cx='26.4717' cy='26' r='8' fill='#D9E8FF' />
      <circle cx='26.4714' cy='25.947' r='3.15789' fill='#1A405C' />
      <path
        d='M25.0482 30.5893L25.9455 27L26.9981 27L27.8955 30.5893C27.9744 30.9048 27.7357 31.2105 27.4104 31.2105L25.5333 31.2105C25.208 31.2105 24.9693 30.9048 25.0482 30.5893Z'
        fill='#1A405C'
      />
    </svg>
  );
}
