import * as Sentry from '@sentry/react';
import addBanner from 'actions/banner/addBanner';
import NotificationTypes from 'constants/NotificationTypes';
import getErrorMessageFromResponse from 'utils/getErrorMessageFromResponse';
import { logger } from 'utils/logger';

/**
 * Continue through the middleware where the action request failed.
 * This is the basic way to pass the middleware on error.
 *
 * Copied from the redux-axios-middleware source code:
 * https://github.com/svrcekmichal/redux-axios-middleware/blob/bea2ff2fe96815d51b444f539b5082939b9b676e/src/defaults.js#L46
 * @param param0
 * @param options
 * @returns
 */
export const onError = ({ action, next, error }: any, options: any) => {
  const errorObject = !error.response
    ? { data: error.message, status: 0 }
    : error;

  try {
    if (
      error.response &&
      error.response.status > 399 &&
      error.response.status < 500
    ) {
      logger.warn(error);
    } else if (!error.response) {
      logger.warn('HTTP Failure in Axios', error);
    } else if (error.response.data.errno) {
      Sentry.setContext('error details', {
        'server error': error.response.data.message,
        'axios error': error,
        'request URL': error.config.url.split('?')[0],
        ...error.response,
      });
      logger.warn(`FE req err: ${error.response.data.message}`);
    } else {
      Sentry.setContext('error details', {
        'server error': error.response.data.message,
        'axios error': error,
        'request URL': error.config.url.split('?')[0],
        ...error.response,
      });
      logger.warn(`BE req err: ${JSON.stringify(error.response.data)}`);
    }
  } catch (err) {
    Sentry.setContext('error details', {
      'axios error': error,
    });
    logger.error(err);
  }

  const nextAction = {
    type: action.types[2],
    error: errorObject,
    meta: {
      previousAction: action,
    },
  };

  next(nextAction);
  return nextAction;
};

/**
 * Continue through the middleware where the action request failed
 * and display a warning banner with a user-friendly message on why the request failed.
 *
 * This general error handling can be overriden at the action level
 * (e.g. to display a different message, to display the error somewhere else in the UI, etc)
 */
export const onErrorDisplayDefaultBanner = (
  { action, next, error, getState, dispatch }: any,
  options: any
) => {
  // Use the server error message
  let errorMessage = getErrorMessageFromResponse(error?.response);

  // Ignore the error message for 2FA. We have separate handling
  // to redirect the user to the 2FA flow in this case and
  // don't need a message.
  if (error?.response?.data?.is_2fa_required) {
    errorMessage = '';
  } else if (!errorMessage) {
    // Use a fallback error message
    errorMessage = 'There was an unexpected error. Please try again.';
  }

  if (errorMessage) {
    dispatch(addBanner(NotificationTypes.WARNING, errorMessage, false));
  }

  return onError({ action, next, error, getState, dispatch }, options);
};

/**
 * Continue through the middleware where the action request was successful.
 * This is the basic way to pass the middleware on success.
 *
 * Copied from the redux-axios-middleware source code:
 * https://github.com/svrcekmichal/redux-axios-middleware/blob/bea2ff2fe96815d51b444f539b5082939b9b676e/src/defaults.js#L17
 */
export const onSuccess = (
  { action, next, response, getState, dispatch }: any,
  options: any
) => {
  const nextAction = {
    type: action.types[1],
    payload: response,
    meta: {
      previousAction: action,
    },
  };
  next(nextAction);
  return nextAction;
};
