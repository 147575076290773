/**
 * Disable the translate feature to prevent fatals caused by the browser modifying the DOM
 * in a way that is incompatible with React render
 */
import React from 'react';

export default ({
  className,
  children,
}: {
  className?: string;
  children: React.ReactNode;
}): React.ReactElement => (
  // translate='no' required for Edge
  <div
    className={`${className || ''} notranslate skiptranslate`}
    translate='no'
  >
    {children}
  </div>
);
