import * as React from 'react';
import styled from 'styled-components';
import { breakpoints, fontSizes, mixins } from 'styles';
import colors from 'styles/colors';

export type LabelColors =
  | 'amber'
  | 'blue-light'
  | 'blue'
  | 'blue-dark'
  | 'gray-icon'
  | 'gray-light'
  | 'gray'
  | 'green'
  | 'orange'
  | 'red'
  | 'transparent'
  | 'black'
  | 'white'
  | 'gold'
  | '';

type Props = {
  /* unique identifier */
  id?: string;
  /* caption to appear in label, leave color and colorClass null to use mappings for colors */
  color?: LabelColors | string | undefined;
  /* size of the label eg. small, medium, large */
  size?: string;
  children?: React.ReactNode;
};

const ColorMapping = {
  gold: colors.geniusText,
  gray: colors.primaryGray,
} as { [key: string]: string };
const getHeight = (size = '') => {
  if (size === 'small') {
    return mixins.pxToRem('25px');
  }
  if (size === 'medium') {
    return mixins.pxToRem('30px');
  }
  return mixins.pxToRem('35px');
};
const getWidth = (size = '', isMobile = false) => {
  if (size === 'small') {
    return isMobile ? '70px' : mixins.pxToRem('89px');
  }
  if (size === 'medium') {
    return mixins.pxToRem('120px');
  }
  return mixins.pxToRem('150px');
};
const StyledLabel = styled.div<Props>`
  height: ${({ size }) => getHeight(size)};
  width: ${({ size }) => getWidth(size)};
  font-weight: normal;
  border-radius: ${mixins.pxToRem('4px')};
  background-color: ${({ theme, color }) => (color ? ColorMapping[color] : '')};
  display: flex;
  align-items: center;
  justify-content: center;
  ${fontSizes.fontSize12}
  color: white;
  margin: ${mixins.pxToRem('10px')};
  @media ${breakpoints.mobileLarge} {
    width: ${({ size }) => getWidth(size, true)};
    ${({ size }) => size === 'small' && 'font-size: 10px; font-weight: 600;'}
  }
`;

export default (props: Props): React.ReactElement<Props> => {
  return <StyledLabel {...props}>{props.children}</StyledLabel>;
};
