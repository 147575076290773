import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Button from 'components/common/Button';
import { renderLabels } from 'components/common/Input/shared';
import ButtonsContainer from 'components/layout/ButtonsContainer';
import PageBase from 'components/layout/PageBase';
import Pages from 'constants/Pages';
import useNavigateFunction from 'hooks/useNavigateFunction';
import useVerifyCurrentPage from 'hooks/useVerifyCurrentPage';
import * as language from 'reducers/entities/language';
import { breakpoints } from 'styles';
import * as WEB from 'types/interfaces';
import { delayWithState } from 'utils/delay';
import { getLanguageButtons, getLanguageSection } from 'utils/getFromLanguage';

type Props = {
  nextPage: string;
};

const EmailVerificationSent = (props: Props) => {
  const { updateCurrentSignupPage } = useNavigateFunction();
  // Make Redux is up-to-date with currentSignUpPage
  useVerifyCurrentPage(Pages.EMAIL_VERIFICATION_SENT);

  // ///////////////////////////////
  /* =========== HOOKS ========== */
  // ///////////////////////////////
  const dispatch = useDispatch();

  // ///////////////////////////////
  /* =========== STATE ========== */
  // ///////////////////////////////
  const [isCompleted, setIsCompleted] = React.useState(false);

  // ///////////////////////////////
  /* ======== MAPPED STATE ====== */
  // ///////////////////////////////
  const languageCtx = useSelector((state: WEB.RootState) =>
    language.getSignupPageLanguage(state, Pages.EMAIL_VERIFICATION_SENT)
  );

  // //////////////////////////////////
  /* =========== LANGUAGE ========== */
  // //////////////////////////////////
  const introSection = getLanguageSection(languageCtx, 'intro');
  const buttons = getLanguageButtons(languageCtx);

  // //////////////////////////////////
  /* =========== HANDLERS ========== */
  // //////////////////////////////////

  const handleOnNext = async () => {
    await delayWithState(400, setIsCompleted);
    dispatch(updateCurrentSignupPage({ page: props.nextPage }));
  };
  // ///////////////////////////////
  /* ========== RENDER ========== */
  // ///////////////////////////////
  return (
    <PageBase
      mobileHeader={introSection.header}
      mobileLead={[introSection.text || '']}
    >
      <HideOnMobile>
        {renderLabels({
          label: introSection.header,
          description: introSection.text,
        })}
      </HideOnMobile>

      <ButtonsContainer>
        <Button onClick={handleOnNext} isCompleted={isCompleted}>
          {isCompleted ? buttons.complete : buttons.primary}
        </Button>
      </ButtonsContainer>
    </PageBase>
  );
};

const HideOnMobile = styled.div`
  @media ${breakpoints.mobileLarge} {
    display: none;
  }
`;

export default EmailVerificationSent;
