import * as React from 'react';

export function InstantCardIconV2() {
  return (
    <svg
      width='53'
      height='52'
      viewBox='0 0 53 52'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_16489_11283)'>
        <path
          d='M28.5463 19.7246C35.4875 31.3817 49.9394 30.9664 49.2983 39.564C49.1194 41.9635 49.3649 50.5212 50.9072 53.2952C51.9388 55.1505 46.9403 53.5133 45.7587 49.8596C45.1277 47.9081 45.8428 45.5587 44.7684 45.4212C43.0067 45.1958 45.0583 50.6925 41.9001 50.2147C39.9422 49.9184 40.4466 46.7153 40.3509 45.3866C40.3409 45.2194 40.1121 45.1637 40.0263 45.3075C39.5104 46.1397 38.3772 47.6654 37.1858 47.1106C35.416 46.2881 36.4049 43.7818 36.8543 42.8331C36.901 42.7332 36.7894 42.6273 36.692 42.6792C36.0235 43.0356 34.5644 43.6029 33.8861 42.2298C32.7044 39.84 36.0501 38.9406 35.2426 36.5834C34.5189 34.4709 32.9541 36.202 31.9444 35.2436C30.055 33.4532 32.9912 26.1366 28.807 25.6595C27.1163 25.4667 28.5463 19.7246 28.5463 19.7246Z'
          fill='#D9E8FF'
        />
        <path
          d='M34.0488 23.6237C22.3917 16.6825 22.807 2.23051 14.2094 2.8716C11.8099 3.05052 3.25225 2.80501 0.478282 1.26269C-1.3771 0.231094 0.26012 5.22963 3.91384 6.41119C5.86533 7.04226 8.2147 6.32709 8.35219 7.40151C8.57764 9.16325 3.08089 7.11162 3.55877 10.2698C3.85502 12.2277 7.05814 11.7234 8.38687 11.8191C8.55401 11.8291 8.60974 12.0579 8.46592 12.1436C7.63373 12.6596 6.10805 13.7927 6.66284 14.9841C7.48532 16.7539 9.9916 15.765 10.9403 15.3156C11.0402 15.2689 11.1462 15.3806 11.0943 15.4779C10.7378 16.1464 10.1705 17.6055 11.5436 18.2838C13.9334 19.4655 14.8328 16.1198 17.19 16.9273C19.3025 17.651 17.5715 19.2158 18.5299 20.2256C20.3202 22.1149 27.6368 19.1787 28.1139 23.3629C28.3067 25.0537 34.0488 23.6237 34.0488 23.6237Z'
          fill='#D9E8FF'
        />
        <path
          d='M14.0773 43.444L5.27686 28.2241C19.8994 28.2242 22.0633 5.03817 37.923 5.03801L47.0674 20.7894C31.4401 20.7894 30.3581 43.444 14.0773 43.444Z'
          fill='#B0D839'
          stroke='#B0D839'
          strokeWidth='1.16893'
          strokeLinejoin='round'
        />
        <path
          d='M16.0249 41.1451L9.01071 29.0662C20.6999 26.339 24.2094 8.02546 36.1627 7.07692L43.3012 18.9353C30.4399 21.6628 27.7155 39.976 16.0249 41.1451Z'
          stroke='#075D44'
          strokeWidth='1.5'
          strokeLinejoin='round'
        />
        <path
          d='M28.9446 19.5806C29.4882 20.045 29.7506 20.8513 29.6185 21.8867C29.4871 22.9161 28.9678 24.095 28.0635 25.1536C27.1592 26.2123 26.076 26.9095 25.0798 27.2001C24.0777 27.4924 23.2403 27.3593 22.6967 26.895C22.1531 26.4306 21.8908 25.6243 22.0229 24.5889C22.1543 23.5595 22.6735 22.3806 23.5778 21.322C24.4821 20.2633 25.5654 19.5661 26.5616 19.2755C27.5636 18.9832 28.401 19.1163 28.9446 19.5806Z'
          fill='#075D44'
          stroke='#075D44'
          strokeWidth='1.16893'
        />
      </g>
      <defs>
        <clipPath id='clip0_16489_11283'>
          <rect
            width='52'
            height='52'
            fill='white'
            transform='translate(0.47168)'
          />
        </clipPath>
      </defs>
    </svg>
  );
}
