import * as React from 'react';

export function CancelSubscriptionsIconV2() {
  return (
    <svg
      width='53'
      height='52'
      viewBox='0 0 53 52'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_16489_11270)'>
        <path
          d='M15.9174 39.2626C14.4671 41.8837 16.2202 45.637 18.8414 47.0872L37.8183 12.7889L34.2845 10.8823L30.0804 18.4662C29.4589 19.5896 28.0445 19.9964 26.9211 19.3748L15.9174 39.2626Z'
          fill='#80B2FF'
        />
        <circle
          cx='4.92115'
          cy='4.92115'
          r='4.92115'
          transform='matrix(1 2.96539e-10 2.96539e-10 -1 33.4014 12.7744)'
          stroke='#F51616'
          strokeWidth='2.5'
        />
        <path
          d='M7.08522 25.2373C4.08963 25.2464 1.64402 21.9029 1.63484 18.9073L40.8329 18.7871L40.8026 22.8023L32.1315 22.8359C30.8476 22.8398 29.8101 23.8837 29.8141 25.1676L7.08522 25.2373Z'
          fill='#D9E8FF'
        />
        <circle
          cx='45.3958'
          cy='20.7297'
          r='4.92115'
          stroke='#F51616'
          strokeWidth='2.5'
        />
      </g>
      <defs>
        <clipPath id='clip0_16489_11270'>
          <rect
            width='52'
            height='52'
            fill='white'
            transform='translate(0.47168)'
          />
        </clipPath>
      </defs>
    </svg>
  );
}
