import { Check } from '@styled-icons/fa-solid/Check';
import * as React from 'react';
import styled from 'styled-components';
import { fontSizes, mixins } from 'styles';
import { handleActivateKeyDown } from 'utils/accessibility';

/* eslint-disable @typescript-eslint/no-explicit-any */
export type Props = {
  /* id for the checkbox */
  id: string;
  /* the label to display next to the checkbox */
  label: React.ReactElement | string;
  /* the state of the checkbox */
  disabled?: boolean;
  /* error text to display */
  errorText?: string;
  /* invalid state */
  invalid?: boolean;
  /* check state */
  check?: boolean;
  /* the theme to use for this component */
  theme?: any;
  /* onChange handler function */
  onChange?: (...args: any) => void;
  /* onClick handler function */
  onClick?: (...args: any) => void;
  /* onMouseOut handle function */
  onMouseOut?: (...args: any[]) => void;
  className?: string;
};

const getBorderColor = (theme?: any, check?: boolean, invalid?: boolean) => {
  if (invalid && !check) return theme.colors.primaryError;
  if (check) return theme.colors.primaryAlbertBrand;
  return theme.colors.primaryGray;
};

const CheckboxContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: start;
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(80%);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckLabel = styled.label`
  ${fontSizes.fontSize14}
  font-weight: normal;
  cursor: pointer;
  text-align: left;
  vertical-align: text-top;
`;

const CheckIcon = styled(Check)`
  min-width: ${mixins.pxToRem('11px')};
  width: ${mixins.pxToRem('11px')};
  color: white;
`;

const StyledCheckbox = styled.div<Props & { invalid?: boolean }>`
  width: ${mixins.pxToRem('18px')};
  min-width: ${mixins.pxToRem('18px')};
  height: ${mixins.pxToRem('18px')};
  margin-top: 1px;
  opacity: ${({ check, disabled }) => (check && disabled ? '0.5' : 1)};
  background-color: ${({ theme, check }) =>
    check ? theme.colors.primaryAlbertBrand : 'white'};
  border: ${({ theme, check, invalid }) =>
    `2px solid ${getBorderColor(theme, check, invalid)}`};
  border-radius: 2px;
  display: inline-flex;
  justify-content: center;
  margin-right: ${mixins.pxToRem('7px')};
  color: white;
  cursor: pointer;
  transition: all 0.1s ease-in 0.1s;

  ${CheckIcon} {
    visibility: ${({ check }) => (check ? 'visible' : 'hidden')};
  }

  &:hover {
    border: ${({ theme }) => `2px solid ${theme.colors.primaryAlbertBrand}`};
  }
  &:focus {
    outline-color: ${({ theme }) => theme.colors.primaryAlbertBrand};
  }
`;

const Checkbox = (props: Props): React.ReactElement => {
  const { label, check, onClick, onMouseOut, className } = props;

  return (
    <div className={className || 'input-wrapper'}>
      <CheckboxContainer onMouseOut={onMouseOut}>
        <HiddenCheckbox defaultChecked={check} />
        <StyledCheckbox
          {...props}
          tabIndex={0}
          onKeyDown={(e) => handleActivateKeyDown(e, onClick)}
        >
          <CheckIcon />
        </StyledCheckbox>
        <StyledCheckLabel onClick={onClick}>{label}</StyledCheckLabel>
      </CheckboxContainer>
    </div>
  );
};

export default Checkbox;
