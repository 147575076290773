import { combineReducers } from 'redux';
import app from './app';
import banking from './banking';
import genius from './genius';
import kyc from './kyc';
import signup from './signup';

const ui = combineReducers({
  app,
  genius,
  signup,
  kyc,
  banking,
});

export default ui;
