import * as React from 'react';
import styled from 'styled-components';
import ErrorText from 'components/common/ErrorText';
import { renderLabels } from 'components/common/Input/shared';

type Props = {
  /* unique id for the radio group */
  id: string;
  /* label container that will show only one label for all radio buttons */
  label?: string;
  /* smaller label text that will sit right next to the label */
  smallLabel?: string;
  /* error text to display */
  errorText?: string;
  /* whether to show error text */
  invalid?: boolean;
  /* description label text that will sit beneath the label */
  description?: string;
  /* assuming an input group could contain other radio buttons */
  children?: React.ReactNode | React.ReactNode[];
};

const StyledRadioGroup = styled.div`
  display: flex;
  justify-content: start;
  align-items: self-start;
  flex-direction: column;
`;

const RadioGroup = (props: Props): React.ReactElement => {
  const { id, errorText, invalid, children } = props;
  return (
    <div>
      {renderLabels(props)}
      <StyledRadioGroup {...props}>{children}</StyledRadioGroup>
      {invalid && <ErrorText name={id} text={errorText} />}
    </div>
  );
};

export default RadioGroup;
