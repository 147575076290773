import * as React from 'react';
import styled from 'styled-components';
import Button from 'components/common/Button';
import Spacer from 'components/common/Spacer';
import ZIndex from 'constants/ZIndex';
import useHandleModalKeyDown from 'hooks/useHandleModalKeyDown';
import useOutsideClickDetection from 'hooks/useOutsideClickDetection';
import { spacers } from 'styles/home';
import mixins from 'styles/mixins';
import { BaseModalProps } from 'types/interfaces';
import ModalBackdrop from './shared/ModalBackdrop';

/* eslint-disable @typescript-eslint/no-explicit-any */
export type Props = BaseModalProps & {
  /* title text for the modal */
  title: string;
  /* description text for the modal */
  description?: string;
  /* label for the submit action */
  submitLabel?: string;
  /* submitting (loading) state for the submit button */
  isSubmitting?: boolean;
  /* completed state for the submit button */
  isCompleted?: boolean;
  /* the action to handle when submitting */
  onSubmit: (...args: any[]) => void;
  /* the state of modal buttons */
  disabled?: boolean | false;
};

const TextContainer = styled.div`
  padding: 2rem 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const BaseModal = styled.div`
  width: 20rem;
  ${mixins.slideInY()}
  height: auto;
  overflow: hidden;
  display: block;
  position: absolute;
  background-color: ${({ theme }) => theme.colors.brandDark1};
  z-index: ${ZIndex.MODAL};
  border-radius: 6px;
`;

const StyledButton = styled(Button)`
  height: 3.75rem;
  min-width: initial;
  border-radius: 0;
  background-color: ${({ theme }) => theme.colors.brandDark3};
  @media (hover: hover) {
    &:hover {
      background-color: ${({ theme }) => theme.colors.brandDark3} !important;
      cursor: pointer;
    }
`;

const TitleText = styled.h2`
  margin: 0;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.25px;
  color: ${({ theme }) => theme.colors.white};
`;

const BodyText = styled.span`
  margin: 0;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.25px;
  color: ${({ theme }) => theme.colors.white};
`;

const BasicModal = (props: Props): React.ReactElement | null => {
  const {
    title,
    description,
    show,
    submitLabel,
    onCancel,
    onSubmit,
    isSubmitting,
    isCompleted,
    disabled,
  } = props;

  const containerRef = React.useRef<HTMLDivElement>(null);
  useHandleModalKeyDown(containerRef);

  React.useEffect(() => {
    // Prevent background scroll
    const { body } = document;
    if (show) {
      body.style.height = '100vh';
      body.style.overflow = 'hidden';
    } else {
      body.style.height = '';
      body.style.overflow = '';
    }

    return () => {
      body.style.height = '';
      body.style.overflow = '';
    };
  }, [show]);
  React.useEffect(() => {
    // Prevent button from focusing when modal first displays
    document.getElementById('modal-submit-button')?.blur();
  }, [show]);

  const onClickOutside = (): void => {
    if (onCancel) onCancel();
  };
  useOutsideClickDetection(containerRef, onClickOutside, show);

  if (!show) return null;

  return (
    <ModalBackdrop>
      <BaseModal>
        <TextContainer>
          <TitleText>{title}</TitleText>
          <Spacer space={spacers.g6} />
          <BodyText>{description}</BodyText>
        </TextContainer>
        <StyledButton
          stretch
          onClick={onSubmit}
          isLoading={isSubmitting}
          isCompleted={isCompleted}
          disabled={disabled}
        >
          {submitLabel}
        </StyledButton>
      </BaseModal>
    </ModalBackdrop>
  );
};

export default BasicModal;
