/**
 *  Alternative to gap for flexbox. Use this when possible, since
 *  older Safari versions don't support gap for flexbox (grid is ok)
 *  Deprecate April 25, 2023: https://caniuse.com/flexbox-gap
 */
export const flexGap = (gap: string) => `
    // To provide a gap-like effect, we set margin on every child.
    // But we don't want the parent container to have a margin,
    // so we offset it with a negative margin on the parent.
    margin-left: -${gap};
    margin-top: -${gap};
    & > * {
      margin-left: ${gap};
      margin-top: ${gap};
    }
  `;
export const flexGapRow = (gap: string) => `
    margin-top: -${gap};
    & > * {
      margin-top: ${gap};
    }
  `;
export const flexGapColumn = (gap: string) => `
    margin-left: -${gap};
    & > * {
      margin-left: ${gap};
    }
`;
