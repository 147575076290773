import styled from 'styled-components';
import { breakpoints } from 'styles';

const ButtonsContainer = styled.div.attrs(() => ({
  className: 'buttons-container',
}))`
  display: flex;
  align-items: center;

  @media ${breakpoints.mobileLarge} {
    flex-direction: column-reverse;
    button {
      width: 100%;
    }
  }
`;

export default ButtonsContainer;
