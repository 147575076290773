enum AlbertTrackingEvent {
  HOME = 'Home',
  SIGNED_UP = 'Signed Up',
  loginAutofill = 'Login Autofill',
  LOGIN_AUTOFILL_NOT_ENABLED = 'Login Autofill Not Enabled',
  // todo: Mobile specific - review
  SAW_TOUR_TICKER_CONFETTI = 'Saw Tour Ticker Confetti',
  TAPPED_TOUR_TICKER = 'Tapped Tour Ticker',
  TAPPED_PUSH_NOTIFICATION = 'Clicked on Notification',

  // Albert Cash.
  OPENED_CASH_BANK_ACCOUNT = 'Opened Bank Account',

  // Genius
  ENABLED_GENIUS = 'Enabled Genius',
  ENABLED_GENIUS_ANNUAL = 'Enabled Genius Annual',
  ENABLED_GENIUS_PRIMARY_CHECKING_GREATER_THAN_100 = 'Enabled Genius - bank > $100',
  SAW_GENIUS_TOPIC_NO_SAVINGS = 'Saw GetGeniusTopicsNoSavings',
  // todo: Mobile specific - review
  GET_GENIUS_TOPIC_NO_SAVINGS = 'Tapped Continue GetGeniusTopicsNoSavings',

  // Accounts
  ADDED_ACCOUNT = 'Added Account',
  SWIPED_CREDIT_CARD = 'Swiped Credit Card',
  RETRY_LOGIN_FOR_AUTH = 'Retry login for auth',
  DELETED_TRANSACTION = 'Deleted Transaction',
  SAVED_TRANSACTION_RECURRING = 'Saved Transaction Recurring',
  SAVED_TRANSACTION_CATEGORY = 'Saved Transaction Category',
  CHANGED_ACCOUNT_CATEGORY = 'Changed Account Category',
  NO_TRANSACTIONS_BANNER = 'No transactions banner',

  // Savings
  ENABLED_SAVINGS = 'Enabled Savings',
  SAW_GENIUS_SAVINGS = 'Saw GetGeniusSavings',
  // todo: Mobile specific - review
  GET_GENIIUS_SAVINGS = 'Tapped Continue GetGeniusSavings',
  SHARE_SAVINGS_GOAL = 'Tapped Share New Savings Goal',

  // Investments
  SAW_GENIUS_INVESTMENTS = 'Saw GetGeniusInvestments',
  SAW_GENIUS_INVESTMENTS_NO_SAVINGS = 'Saw GetGeniusInvestmentsNoSavings',
  COMPLETED_INVESTMENT_APPLICATION = 'Completed Investing Onboarding',
  // todo: Mobile specific - review
  GET_GENIUS_INVESTMENTS_NO_SAVINGS = 'Tapped Continue GetGeniusInvestmentsNoSavings',
  GET_GENIUS_INVESTMENTS = 'Tapped Continue GetGeniusInvestments',
  SHARE_TRADE = 'Tapped Share Trade',

  // Instant
  COMPLETED_INSTANT_ONBOARDING = 'Completed Instant Onboarding',
  // todo: Mobile specific - review
  SHARE_INSTANT_ADVANCE = 'Tapped Share Instant Advance',

  // Insurance
  SAW_OFFER = 'Rendered Offer Card',
  SAW_AUTO_SAVINGS = 'Rendered Auto Savings',

  // Bill shark
  // todo: Mobile specific - review
  SHARE_BILL = 'Tapped Share Bill',

  // Missions
  // todo: Mobile specific - review
  SHARE_MISSION = 'Tapped Share Mission',

  IDENTIFY = 'Identify',
}

export default AlbertTrackingEvent;
