/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import styled from 'styled-components';
import ErrorText from 'components/common/ErrorText';
import { TextColor, getColor } from 'components/common/Text';
import { breakpoints } from 'styles';
import mixins from 'styles/mixins';
import { handleActivateKeyDown } from 'utils/accessibility';

export type Props = {
  /* unique id for the radio button */
  id: string;
  /* name for the button group */
  name?: string;
  /* label for radio button */
  label?: string;
  /* label colors */
  labelColor?: TextColor;
  /* whether to show error text */
  showError?: boolean;
  /* error text to display */
  errorText?: string;
  /* invalid state */
  invalid?: boolean;
  /* the theme to use for this component */
  theme?: any;
  /* value of radio button (defaulted to label) */
  value?: string | number | boolean;
  /* is radio button selected */
  selected?: boolean | false;
  /* onclick handler for selecting */
  onClick?: (value: any) => any;
  /* onMouseOut handler function */
  onMouseOut?: (...args: any[]) => void;
};

const getBorder = (props: Props) => {
  const { theme, selected, invalid } = props;
  if (invalid) {
    return `2.5px solid ${theme.colors.primaryError}`;
  }
  if (selected) {
    return `1.5px solid ${theme.colors.primaryAlbertBrand}`;
  }
  return `1.5px solid ${theme.colors.primaryGray}`;
};

const Container = styled.div`
  margin-bottom: ${mixins.pxToRem('20px')};
  :last-child {
    margin-bottom: 0;
  }
  @media ${breakpoints.mobileLarge} {
    margin-bottom: 0;
  }
`;

const RadioButtonContainer = styled.div<Props>`
  display: inline-flex;
  height: ${mixins.pxToRem('20px')};
  text-align: center;
  cursor: pointer;

  input[type='radio'] {
    /* hide original inputs */
    visibility: hidden;
    position: absolute;
  }
  input[type='radio'] + label {
    cursor: pointer;
  }
  input[type='radio'] + label:before {
    // Using rem for the height/width leads to the radio buttons
    // looking oblong on some screen sizes
    height: 20px;
    width: 20px;
    margin-right: 12px;
    content: ' ';
    display: inline-block;
    vertical-align: bottom;
    transition: 0.3s;
    border: ${(props: Props) => getBorder(props)};
    border-radius: 50%;
    transition: 0.3s;
    background-color: ${({ theme, selected }) => {
      if (selected) {
        return theme.colors.primaryAlbertBrand;
      }
      return `white`;
    }};
    box-shadow: ${({ selected }) =>
      selected ? `inset 0px 0px 0px 2px white` : ''};
  }
  &:focus {
    outline-color: ${({ theme }) => theme.colors.primaryAlbertBrand};
  }
`;

const StyledLabel = styled.label<{ color: TextColor }>`
  color: ${({ theme, color }) => getColor({ theme, color })};
`;

const Radio = (props: Props): React.ReactElement<Props> => {
  const {
    id,
    name,
    label,
    labelColor,
    value,
    selected,
    invalid,
    errorText,
    showError,
    theme,
    onClick,
    onMouseOut,
  } = props;

  const handleOnClick = (e: React.MouseEvent) => {
    if (onClick) {
      const newValue = value !== undefined ? value : label;
      onClick(newValue);
    }
  };

  return (
    <Container id={id}>
      <RadioButtonContainer
        id={`${id}-radio-container`}
        selected={selected}
        invalid={invalid}
        theme={theme}
        tabIndex={0}
        onKeyDown={(e) => handleActivateKeyDown(e, handleOnClick)}
        className='input-wrapper'
      >
        <input
          name={name}
          type='radio'
          id={id}
          value={String(value || label)}
        />
        <StyledLabel
          id={id}
          onClick={handleOnClick}
          htmlFor={id}
          color={labelColor || TextColor.BLACK}
        >
          {label}
        </StyledLabel>
      </RadioButtonContainer>
      {invalid && showError && <ErrorText name={id} text={errorText} />}
    </Container>
  );
};

export default Radio;
