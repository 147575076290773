import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// https://v5.reactrouter.com/web/guides/scroll-restoration
const useScrollToTopOnRoute = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
};

export default useScrollToTopOnRoute;
