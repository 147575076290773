import * as React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import BannerPane from 'components/BannerPane';
import ContentPane, { ContentContainer } from 'components/layout/ContentPane';
import TwoPaneWrapper from 'components/layout/TwoPaneWrapper';
import ExternalRedirect404 from 'components/router/ExternalRedirect404';
import SignupInfoPane from 'components/signup/SignupInfoPane';
import { SignupPages } from 'constants/Pages';
import * as Pages from 'pages/signup';
import Navbar from 'pages/unauthenticated/Navbar';
import * as language from 'reducers/entities/language';
import { getCurrentPage } from 'reducers/ui/signup';

/* eslint-disable @typescript-eslint/no-explicit-any */
type Props = {
  pages: string[];
  nextSubflowPage: string;
};

// Pages representing entire subflows with a layout that breaks the standard
// left sidepanel layout and so need full layout control
const CUSTOM_LAYOUT_SUBFLOW_PAGES: SignupPages[] = [];
// Individual pages that require full layout control
const CUSTOM_LAYOUT_PAGES = [
  SignupPages.DIRECT_DEPOSIT,
  SignupPages.SUPERCHARGE,
  SignupPages.KYC_VERIFY_IDENTITY,
  SignupPages.CASH_SETUP,
  SignupPages.CASH_SHIPPING_ADDRESS,
  SignupPages.JUMPSTART_CASH,
];

const SubFlowContainer = (props: Props): React.ReactElement => {
  const { pages, nextSubflowPage } = props;
  const currentPage = useSelector(getCurrentPage) as SignupPages;

  // Mapped state
  const languageCtx = useSelector(language.getSignupLanguage);

  // Set the first route as default route
  // ie. `/create-account` -> `/create-account/tell-us-about-yourself`
  const firstPageOfFlow = pages?.[0];
  const firstPagePath = languageCtx?.[firstPageOfFlow]?.route;

  // Redirect to first page of subflow if user does not include page route in url
  // ie. "/create-account" --> "/create-account/tell-us-about-yourself"
  const redirectRoute = (
    <Route path='/' element={<Navigate to={firstPagePath} replace />} />
  );

  // ////////////////////////////////////////
  /* ============= SUBFLOWS ============== */
  // ////////////////////////////////////////

  // Create dynamic routes based on pages within the flow
  const subflowRoutes = (pages || []).map(
    (page: string, index: number): React.ReactElement | null => {
      // Get page context
      const pageContext = languageCtx?.[page];
      const PageComponent = Pages[page];
      if (!PageComponent) {
        return null;
      }

      // Get next page, if any. If there is no next page, we want to render first page of next flow
      const nextPage = pages?.[index + 1] || nextSubflowPage;

      return (
        <Route
          path={pageContext?.route}
          key={`route-${pageContext?.route}`}
          element={<PageComponent nextPage={nextPage} />}
        />
      );
    }
  );

  const routes = (
    <Routes>
      {subflowRoutes}
      {redirectRoute}
      {subflowRoutes.length && <Route element={<ExternalRedirect404 />} />}
    </Routes>
  );

  /* If the subflow includes a page that needs full layout control, only render the routes */
  if (
    CUSTOM_LAYOUT_SUBFLOW_PAGES.some((page) => props.pages.includes(page)) ||
    CUSTOM_LAYOUT_PAGES.includes(currentPage)
  ) {
    return <>{routes}</>;
  }

  /* Render the standard page layout: sidebar on the left, content on the right */
  return (
    <>
      <Navbar split signupFlow />
      <TwoPaneWrapper>
        <SignupInfoPane />
        <ContentPane>
          <BannerPane />
          <ContentContainer>{routes}</ContentContainer>
        </ContentPane>
      </TwoPaneWrapper>
    </>
  );
};

export default SubFlowContainer;
