import styled from 'styled-components';
import NoTranslate from 'components/NoTranslate';
import { breakpoints } from 'styles';

const StyledWrapper = styled(NoTranslate)`
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  width: 100%;
  flex: 1;
  /** 
   * Flex stack - stack columns vertically
   */
  @media ${breakpoints.mobileLarge} {
    flex-wrap: wrap;
    flex-direction: column;
  }
`;

export default StyledWrapper;
