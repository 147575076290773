/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { FORM_ERROR } from 'final-form';
import * as React from 'react';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import getProfile from 'actions/async/getProfile';
import submitProfileKYCIdentityCheck from 'actions/async/submitProfileKYCIdentityCheck';
import removeBanner from 'actions/banner/removeBanner';
import Button from 'components/common/Button';
import Checkbox from 'components/common/Checkbox';
import Input from 'components/common/Input';
import Modal from 'components/common/Modal';
import Spacer from 'components/common/Spacer';
import Text, { TextColor, TextSizes } from 'components/common/Text';
import ExternalLinkIcon from 'components/icons/ExternalLinkIcon';
import ButtonsContainer from 'components/layout/ButtonsContainer';
import InfoPaneExpanderGroup from 'components/layout/InfoPaneExpanderGroup';
import PageBase from 'components/layout/PageBase';
import BannerActionType from 'constants/BannerActionType';
import ErrorMessages, { ErrorTitles } from 'constants/ErrorMessages';
import NotificationTypes from 'constants/NotificationTypes';
import { HIDE_NAV_AND_BANNER } from 'constants/PageDisplayOptions';
import Pages, { PageType } from 'constants/Pages';
import ProductType from 'constants/ProductType';
import SplashLink from 'constants/SplashLink';
import useNavigateFunction from 'hooks/useNavigateFunction';
import useVerifyCurrentPage from 'hooks/useVerifyCurrentPage';
import * as language from 'reducers/entities/language';
import { breakpoints, colors, fontSizes, mixins, spacers } from 'styles';
import { spacers as homeSpacers } from 'styles/home';
import * as WEB from 'types/interfaces';
import { delayWithState } from 'utils/delay';
import {
  getLanguageButtons,
  getLanguageInfo,
  getLanguageSection,
} from 'utils/getFromLanguage';
import { composeValidators, validSSN } from 'utils/validators';

type Props = {
  fromSignup?: boolean;
  pageOnComplete: string;
  exitKYCFlow: (showPersonaVerification: boolean) => Promise<boolean>;
};

interface ModalLabels {
  icon: NotificationTypes;
  title: string;
  description: string;
  submitLabel: string;
}

const StyledBackground = styled.div`
  background-color: ${colors.lightGray2};
  border-radius: 4px;
  padding: ${mixins.pxToRem('40px')};
  padding-bottom: ${mixins.pxToRem('20px')};
  @media ${breakpoints.mobileLarge} {
    padding: ${homeSpacers.g4} ${homeSpacers.g6};
  }
`;

const HiddenOnMobile = styled.div`
  @media ${breakpoints.mobileLarge} {
    display: none;
  }
`;

const SubtextWrapper = styled.div`
  ${fontSizes.fontSize18};
  @media ${breakpoints.mobileLarge} {
    font-size: inherit;
  }
`;

// Prevent link icon widow
const LinkIconTextContainer = styled.span`
  display: inline-block;
  text-decoration: underline;
`;
const StyledExternalLinkIcon = styled(ExternalLinkIcon)`
  margin-left: 2px;
  margin-bottom: 1px;
`;

const KYCSSN = (props: Props): React.ReactElement => {
  const { fromSignup, pageOnComplete, exitKYCFlow } = props;
  const { updateCurrentKYCPage } = useNavigateFunction();

  // Make Redux is up-to-date with current page.
  useVerifyCurrentPage(Pages.KYC_SSN, PageType.KYC);

  // ///////////////////////////////
  /* =========== HOOKS ========== */
  // ///////////////////////////////

  const dispatch = useDispatch<WEB.Dispatch>();

  // ///////////////////////////////
  /* =========== STATE ========== */
  // ///////////////////////////////
  const [esignConfirmed, setEsignConfirmed] = React.useState(false);
  const [cardholderAgreementConfirmed, setCardholderAgreementConfirmed] =
    React.useState(false);
  const [investingAgreementConfirmed, setInvestingAgreementConfirmed] =
    React.useState(false);
  const [isCompleted, setIsCompleted] = React.useState(false);
  const [showErrModal, setShowErrModal] = React.useState(false);
  const [modalLabels, setModalLabels] = React.useState<ModalLabels>({
    icon: NotificationTypes.ERROR,
    title: '',
    description: '',
    submitLabel: 'Go back',
  });

  // ///////////////////////////////
  /* ======= MAPPED STATE ====== */
  // ///////////////////////////////
  const showInvestingAgreement = useSelector((state: WEB.RootState) => {
    return language.getSignupFlag(state, 'showKYCInvestingAgreement');
  });

  const socureSessionId = useSelector(
    (state: WEB.RootState) => state.ui.signup.socureSessionId
  );

  const languageCtx = useSelector((state: WEB.RootState) =>
    language.getKYCPageLanguage(state, Pages.KYC_SSN)
  );

  const showPersonaFlow = useSelector((state: WEB.RootState) =>
    language.getSignupFlag(state, 'showPersonaFlow')
  );

  const hideKYCESignAgreement = useSelector((state: WEB.RootState) =>
    language.getSignupFlag(state, 'hideKYCEsignAgreement')
  );

  const showKYCCreditAgreement = useSelector((state: WEB.RootState) =>
    language.getSignupFlag(state, 'showKYCCreditAgreement')
  );

  // ///////////////////////////////
  /* ========= LANGUAGE ========= */
  // ///////////////////////////////

  // Section keys
  const VERIFY_KEY = 'verify';
  const INPUT_KEY = 'inputField';
  const AGREEMENT_KEY = showKYCCreditAgreement
    ? 'creditAgreement'
    : 'agreement';

  // Sections
  const verifySection = getLanguageSection(languageCtx, VERIFY_KEY);
  const inputSection = getLanguageSection(languageCtx, INPUT_KEY);
  const agreementSection = getLanguageSection(languageCtx, AGREEMENT_KEY);
  const buttonSection = getLanguageButtons(languageCtx);

  const infoPaneContent = getLanguageInfo(languageCtx);

  // //////////////////////////////
  /* ========= HANDLERS ======== */
  // //////////////////////////////
  const handleEsignConfirmedOnClick = (): void => {
    setEsignConfirmed(!esignConfirmed);
  };
  const handleCardholderAgreementConfirmedOnClick = (): void => {
    setCardholderAgreementConfirmed(!cardholderAgreementConfirmed);
  };
  const handleInvestingAgreementConfirmedOnClick = (): void => {
    setInvestingAgreementConfirmed(!investingAgreementConfirmed);
  };

  const handleDisplayErrorModal = (data: any): void => {
    const DEFAULT_TITLE = ErrorTitles.kyc.unableToVerify;
    let modalTitle = DEFAULT_TITLE;
    let modalIcon = NotificationTypes.ERROR;
    let modalDesc = ErrorMessages.kyc.unableToVerify;
    let modalSubmitLabel = 'Try again';

    // Duplicate SSN (not in signup flow).
    if (data.button_action === BannerActionType.RETRY_FLOW) {
      modalTitle = data.title || DEFAULT_TITLE;
      modalIcon = NotificationTypes.WARNING;
      modalDesc =
        data.albert_api_error_message || ErrorMessages.kyc.unableToVerify;
      modalSubmitLabel = 'Okay';

      // Too many attempts.
    } else if (data.button_action === BannerActionType.DISMISS_FLOW) {
      modalTitle = data.title || DEFAULT_TITLE;
      modalDesc = ErrorMessages.kyc.tooManyFailedAttempts;
      modalSubmitLabel = 'Okay';
    }

    setModalLabels({
      icon: modalIcon,
      title: modalTitle,
      description: modalDesc,
      submitLabel: modalSubmitLabel,
    });
    setShowErrModal(true);
  };

  const onSubmit = async (values: Record<string, any>) => {
    dispatch(removeBanner());

    // Do not proceed if agreements are not all confirmed.
    if (
      (!hideKYCESignAgreement && !esignConfirmed) ||
      !cardholderAgreementConfirmed ||
      (showInvestingAgreement && !investingAgreementConfirmed)
    ) {
      return { [FORM_ERROR]: 'error' };
    }
    const reformattedSSN: number = values.ssn.replace(/-/g, '');

    const dispatchRes: any = await dispatch(
      submitProfileKYCIdentityCheck(
        ProductType.SIGNUP,
        reformattedSSN,
        socureSessionId
      )
    );
    const { response, error, showModal } = dispatchRes;
    const responseData =
      (error ? response?.error?.response?.data : response?.payload?.data) || {};

    let profileErr;
    if (!error) {
      const profileResponse: any = await dispatch(getProfile());
      profileErr = profileResponse.error;
    }

    if (responseData?.show_persona_verification) {
      return { showPersona: true };
    }
    // Handle error - all error should redirect user back to KYCConfirm.
    if (!responseData?.kyc_passed || (showModal && error)) {
      // Prompt user to MFA if MFA questions returned.
      const shouldPromptMFA = !!(responseData?.kyc_mfa_questions || []).length;
      if (shouldPromptMFA) {
        dispatch(
          updateCurrentKYCPage({
            fromSignup,
            page: Pages.KYC_MFA,
          })
        );
      } else if (showModal) {
        handleDisplayErrorModal(responseData);
      }
      return { [FORM_ERROR]: 'error' };
    }

    const exitError = await exitKYCFlow(
      responseData?.show_persona_verification
    );

    // For all other errors, stay on the page to show the default error banner
    if (error || profileErr || exitError) {
      return { [FORM_ERROR]: 'error' };
    }

    // No errors
    return {};
  };

  const submitCallback = async ({
    showPersona,
    ...errors
  }: any): Promise<void> => {
    if (Object.keys(errors || {}).length === 0) {
      await delayWithState(400, setIsCompleted);

      let page = pageOnComplete;

      if (showPersona) {
        if (showPersonaFlow) {
          page = Pages.KYC_VERIFY_IDENTITY_WEB;
        } else {
          page = Pages.KYC_VERIFY_IDENTITY;
        }
      }

      dispatch(
        updateCurrentKYCPage({
          page,
          fromSignup,
        })
      );
    }
  };

  const navToPrevPage = (): void => {
    dispatch(
      updateCurrentKYCPage({
        fromSignup,
        page: Pages.KYC_CONFIRM,
      })
    );
  };

  // Needed to prevent propagation of click event from anchor tag to
  // the checkbox component (ie. clicking on anchor tag also changed checkbox state)
  const createRedirectOnClickHandler = (link: string) =>
    React.useCallback((e: React.MouseEvent<HTMLElement>) => {
      e?.stopPropagation();
      window.open(link, '_blank');
    }, []);

  // //////////////////////////////
  /* ======= FORM ELEMENTS ====== */
  // //////////////////////////////

  const SSNInput = ({ input, meta }: Partial<WEB.InputFieldProps>) => {
    return (
      <StyledBackground>
        <Input.SSN
          id='ssn'
          {...input}
          invalid={
            meta?.error && meta.submitFailed && !meta.dirtySinceLastSubmit
          }
          errorText={inputSection.errorMessage || meta?.error}
          smallLabel={inputSection.header}
          footerLabel={inputSection.disclaimer}
          hideLabelOnMobile
        />
      </StyledBackground>
    );
  };

  const eConsentLink = SplashLink.E_SIGN_CONSENT;

  const ESIGN = (
    <Text color={TextColor.GRAY} size={TextSizes.SMALL}>
      I’ve read and agree to the terms of the&nbsp;
      <Text
        inline
        color={TextColor.GRAY}
        weight='700'
        size={TextSizes.SMALL}
        underline
        isLinkButton
        onClick={createRedirectOnClickHandler(eConsentLink)}
      >
        E-Sign{' '}
        <LinkIconTextContainer>
          Disclosure
          <StyledExternalLinkIcon />
        </LinkIconTextContainer>
      </Text>
    </Text>
  );
  const CARDHOLDER_AGREEMENT = (
    <Text color={TextColor.GRAY} size={TextSizes.SMALL}>
      I’ve read and agree to the terms and conditions of the&nbsp;
      <Text
        inline
        color={TextColor.GRAY}
        weight='700'
        size={TextSizes.SMALL}
        underline
        isLinkButton
        onClick={createRedirectOnClickHandler(
          `${SplashLink.CARDHOLDER_AGREEMENT}${HIDE_NAV_AND_BANNER}`
        )}
      >
        Cardholder{' '}
        <LinkIconTextContainer>
          agreement
          <StyledExternalLinkIcon />
        </LinkIconTextContainer>
      </Text>
      &nbsp;including the agreement to arbitration.
    </Text>
  );
  const INVESTING_AGREEMENT = (
    <Text color={TextColor.GRAY} size={TextSizes.SMALL}>
      I’ve read and agree to the terms and conditions of the{' '}
      <Text
        inline
        color={TextColor.GRAY}
        weight='700'
        size={TextSizes.SMALL}
        underline
        isLinkButton
        onClick={createRedirectOnClickHandler(
          `${SplashLink.INVESTING_ACCOUNT_AGREEMENTS}${HIDE_NAV_AND_BANNER}`
        )}
      >
        Investing account{' '}
        <LinkIconTextContainer>
          agreements
          <StyledExternalLinkIcon />
        </LinkIconTextContainer>
      </Text>{' '}
      and agree to enroll in Apex's Sweep Program.
    </Text>
  );
  /* eslint-enable jsx-a11y/no-static-element-interactions */
  /* eslint-enable jsx-a11y/anchor-is-valid */
  /* eslint-enable jsx-a11y/click-events-have-key-events */

  return (
    <>
      <PageBase mobileHeader={verifySection.header}>
        <HiddenOnMobile>
          <Text size={TextSizes.LARGE} weight='bold'>
            {verifySection.header}
          </Text>
          <Spacer space={spacers.small} />
        </HiddenOnMobile>
        <SubtextWrapper className='description'>
          <Text>{verifySection.text}</Text>
        </SubtextWrapper>
        <Spacer space={spacers.tabSmall} />
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit, submitting, submitFailed }) => (
            <form
              onSubmit={(event) => handleSubmit(event)?.then(submitCallback)}
            >
              <Field
                name='ssn'
                component={SSNInput}
                validate={composeValidators(validSSN)}
              />

              {!hideKYCESignAgreement && (
                <>
                  <Spacer space={spacers.tabLarge} desktopOnly />
                  <Spacer space={spacers.tab} mobileOnly />
                  <Field name='esign'>
                    {({ input }) => (
                      <Checkbox
                        {...input}
                        id='esign-agreement-check'
                        check={esignConfirmed}
                        onClick={handleEsignConfirmedOnClick}
                        invalid={!esignConfirmed && submitFailed}
                        label={ESIGN}
                      />
                    )}
                  </Field>
                </>
              )}

              <Spacer space={homeSpacers.g2} mobileOnly />
              <Spacer space={spacers.small} desktopOnly />
              <Field name='cardholder'>
                {({ input }) => (
                  <Checkbox
                    {...input}
                    id='cardholder-agreement-check'
                    check={cardholderAgreementConfirmed}
                    onClick={handleCardholderAgreementConfirmedOnClick}
                    invalid={!cardholderAgreementConfirmed && submitFailed}
                    label={CARDHOLDER_AGREEMENT}
                    className=''
                  />
                )}
              </Field>

              {showInvestingAgreement && (
                <>
                  <Spacer space={homeSpacers.g2} mobileOnly />
                  <Spacer space={spacers.small} desktopOnly />
                  <Field name='investing'>
                    {({ input }) => (
                      <Checkbox
                        {...input}
                        id='investing-agreement-check'
                        check={investingAgreementConfirmed}
                        onClick={handleInvestingAgreementConfirmedOnClick}
                        invalid={!investingAgreementConfirmed && submitFailed}
                        label={INVESTING_AGREEMENT}
                        className=''
                      />
                    )}
                  </Field>
                </>
              )}

              <Spacer space={spacers.tab} desktopOnly />
              <Spacer space={homeSpacers.g8} mobileOnly />
              <Text size={TextSizes.SMALL} color={TextColor.GRAY}>
                {agreementSection.text}
              </Text>
              <ButtonsContainer>
                <Button
                  isCompleted={isCompleted}
                  isLoading={submitting}
                  disabled={submitting}
                >
                  {isCompleted ? null : buttonSection.primary}
                </Button>
              </ButtonsContainer>
            </form>
          )}
        />
        <InfoPaneExpanderGroup
          key='kyc-ssn-info'
          expanders={infoPaneContent.expanders}
          addExpanderSubheadSpacing={false}
          mobileOnly
        />
        <Modal.Basic
          title={modalLabels.title}
          description={modalLabels.description}
          iconType={modalLabels.icon}
          submitLabel={modalLabels.submitLabel}
          onSubmit={navToPrevPage}
          show={showErrModal}
        />
      </PageBase>
    </>
  );
};

export default KYCSSN;
