import * as React from 'react';
import SignupGoals from 'constants/SignupGoals';
import BetterBankAccountIcon from './BetterBankAccountIcon';
import CancelSubscriptionsIcon from './CancelSubscriptionsIcon';
import CashAdvanceIcon from './CashAdvanceIcon';
import CreateBudgetIcon from './CreateBudgetIcon';
import InvestingIcon from './InvestingIcon';
import { LockIcon } from './LockIcon';
import { PlusIcon } from './PlusIcon';
import SaveMoneyIcon from './SaveMoneyIcon';
import { BankAccountIconV2 } from './v2/BankAccountIcon';
import { CancelSubscriptionsIconV2 } from './v2/CancelSubscriptionsIcon';
import { CreateBudgetIconV2 } from './v2/CreateBudgetIcon';
import { InstantCardIconV2 } from './v2/InstantCardIcon';
import { SaveMoneyIconV2 } from './v2/SaveMoneyIcon';
import { TrackSpendingIconV2 } from './v2/TrackSpendingIcon';

const iconsMap = {
  // monochrome
  BetterBankAccountIcon: BetterBankAccountIcon,
  CancelSubscriptionsIcon: CancelSubscriptionsIcon,
  CashAdvanceIcon: CashAdvanceIcon,
  CreateBudgetIcon: CreateBudgetIcon,
  InvestingIcon: InvestingIcon,
  SaveMoneyIcon: SaveMoneyIcon,

  // colored
  [SignupGoals.BANK_ACCOUNT]: BankAccountIconV2,
  [SignupGoals.BUDGET]: CreateBudgetIconV2,
  [SignupGoals.TRACK_SPENDING]: TrackSpendingIconV2,
  [SignupGoals.CASH_ADVANCE]: InstantCardIconV2,
  [SignupGoals.CANCEL_SUBSCRIPTIONS]: CancelSubscriptionsIconV2,
  [SignupGoals.SAVE_MONEY]: SaveMoneyIconV2,

  // misc.
  lock: LockIcon,
  plus: PlusIcon,
};

const Icon = ({ icon }: { icon: string }): React.ReactElement | null => {
  const Icon = iconsMap[icon];
  if (Icon) {
    return <Icon />;
  }
  return null;
};

export default Icon;
