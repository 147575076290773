import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { StyledBannerDesktop, StyledBannerMobile } from 'components/BannerPane';
import Button from 'components/common/Button';
import Spacer from 'components/common/Spacer';
import Text from 'components/common/Text';
import { SuperchargeLottie } from 'components/common/animations';
import BorderContainer from 'components/layout/BorderContainer';
import {
  LeftPane,
  PageContainer,
  PaneWrapper,
  RightPane,
} from 'components/layout/FiftyFiftyPageLayout';
import PageBase from 'components/layout/PageBase';
import Pages from 'constants/Pages';
import useBrowserNavigationBlockWithBanner from 'hooks/useBrowserNavigationBlockWithBanner';
import useNavigateFunction from 'hooks/useNavigateFunction';
import useVerifyCurrentPage from 'hooks/useVerifyCurrentPage';
import Navbar from 'pages/unauthenticated/Navbar';
import * as language from 'reducers/entities/language';
import { breakpoints, fontSizes, mixins, spacers } from 'styles';
import { spacers as homeSpacers } from 'styles/home';
import * as WEB from 'types/interfaces';
import { getLanguageButtons, getLanguageSection } from 'utils/getFromLanguage';
import { hash32 } from 'utils/hash';

type Props = {
  nextPage: string;
};

const StyledLeftPane = styled(LeftPane)`
  padding-top: 40px;
  @media ${breakpoints.mobileLarge} {
    padding-top: 0;
  }
`;

const StyledRightPane = styled(RightPane)`
  @media ${breakpoints.mobileLarge} {
    padding-top: 0;
  }
`;

const Header = styled.h1`
  margin-top: 0;
  margin-bottom: ${mixins.pxToRem('32px')};
  @media ${breakpoints.mobileLarge} {
    margin-bottom: ${mixins.pxToRem('20px')};
    ${fontSizes.fontSize30}
  }
`;

const SuperchargeText = ({
  text,
  isLastParagraph,
}: {
  text: string;
  isLastParagraph: boolean;
}): React.ReactElement => (
  <>
    <Text>{text}</Text>
    <Spacer space={spacers.tabSmall} desktopOnly />
    <Spacer
      space={isLastParagraph ? homeSpacers.g11 : spacers.small}
      mobileOnly
    />
  </>
);

const Supercharge = ({ nextPage }: Props) => {
  // //////////////////////////////////
  /* =========== HOOKS ========== */
  // //////////////////////////////////
  const { updateCurrentSignupPage } = useNavigateFunction();
  const dispatch = useDispatch();
  // Make sure Redux is up-to-date with currentSignUpPage
  useVerifyCurrentPage(Pages.SUPERCHARGE);
  useBrowserNavigationBlockWithBanner();

  /* =========== LANGUAGE ========== */
  // //////////////////////////////////
  const languageCtx = useSelector((state: WEB.RootState) =>
    language.getSignupPageLanguage(state, Pages.SUPERCHARGE)
  );
  const introCtx = getLanguageSection(languageCtx, 'intro');
  const buttonsCtx = getLanguageButtons(languageCtx);

  const superchargeBody = Array.isArray(introCtx?.text)
    ? introCtx?.text.map((text, index) => (
        <SuperchargeText
          key={hash32(text)}
          text={text}
          isLastParagraph={index === (introCtx?.text?.length || 0) - 1}
        />
      ))
    : null;

  // //////////////////////////////////
  /* =========== HANDLERS ========== */
  // //////////////////////////////////
  const onSubmit = () => {
    dispatch(updateCurrentSignupPage({ page: nextPage }));
  };

  return (
    <>
      <Navbar signupFlow />
      <PageContainer>
        <StyledBannerMobile />
        <PaneWrapper>
          <StyledLeftPane>
            <SuperchargeLottie />
          </StyledLeftPane>
          <StyledRightPane>
            <PageBase maxWidth='584px'>
              <StyledBannerDesktop />
              <Spacer space={spacers.small} desktopOnly />
              <BorderContainer>
                <Header>{introCtx?.header}</Header>
                {superchargeBody}
                <Button onClick={onSubmit} stretch>
                  {buttonsCtx?.primary}
                </Button>
              </BorderContainer>
            </PageBase>
          </StyledRightPane>
        </PaneWrapper>
      </PageContainer>
    </>
  );
};

export default Supercharge;
