import TrackingBase from 'tracking.Base';
import types from 'actions/types';
import AlbertClient from 'api/AlbertClient';
import AlbertTrackingEvent from 'constants/AlbertTrackingEvent';
import { PaymentFrequency } from 'constants/index';
import * as WEB from 'types/interfaces';
import {
  onErrorDisplayDefaultBanner,
  onSuccess,
} from 'utils/axiosMiddlewareUtils';

export interface EnableGeniusFields {
  genius_enabled?: boolean;
  number_months_free?: number;
  default_price?: number;
  monthly_price?: number;
  completed_onboarding?: boolean;
  payment_frequency?: PaymentFrequency;
  agreed_to_receipt?: boolean;
  tier_key?: string;
  flow_type?: string;
  source?: string;
  set_up_annual_payment?: boolean;
}

export default (
    fieldsToUpdate: EnableGeniusFields
  ): WEB.ActionCreator<Promise<boolean>> =>
  async (dispatch: WEB.Dispatch): Promise<boolean> => {
    let error = false;

    await dispatch({
      types: [
        types.ENABLE_GENIUS_REQUEST,
        types.ENABLE_GENIUS_SUCCESS,
        types.ENABLE_GENIUS_FAILURE,
      ],
      payload: {
        request: {
          method: 'post',
          url: AlbertClient.geniusSubscriptionView(),
          data: {
            is_web: true,
            ...fieldsToUpdate,
          },
        },
        options: {
          onSuccess: (middlewareOptions: any, options: any) => {
            TrackingBase.track({
              event: AlbertTrackingEvent.ENABLED_GENIUS,
              purchaseAmount: fieldsToUpdate.monthly_price,
              trackSocial: true,
              trackOnce: true,
            });
            return onSuccess(middlewareOptions, options);
          },
          onError: (middlewareOptions: any, options: any) => {
            error = true;
            return onErrorDisplayDefaultBanner(middlewareOptions, options);
          },
        },
      },
    });

    return error;
  };
