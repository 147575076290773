/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import * as React from 'react';
import { Field, FieldRenderProps, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import createProperty from 'actions/async/createProperty';
import removeBanner from 'actions/banner/removeBanner';
import Button from 'components/common/Button';
import Dropdown from 'components/common/Dropdown';
import Input from 'components/common/Input';
import Spacer from 'components/common/Spacer';
import Text, { TextColor, TextSizes } from 'components/common/Text';
import ButtonsContainer from 'components/layout/ButtonsContainer';
import PageBase from 'components/layout/PageBase';
import ErrorMessages from 'constants/ErrorMessages';
import Pages, { PageType } from 'constants/Pages';
import useNavigateFunction from 'hooks/useNavigateFunction';
import useVerifyCurrentPage from 'hooks/useVerifyCurrentPage';
import * as appData from 'reducers/entities/appData';
import * as language from 'reducers/entities/language';
import { breakpoints, spacers } from 'styles';
import { spacers as homeSpacers } from 'styles/home';
import * as WEB from 'types/interfaces';
import { delayWithState } from 'utils/delay';
import {
  getLanguageButtons,
  getLanguageInfo,
  getLanguageSection,
} from 'utils/getFromLanguage';
import { composeValidators, isRequired } from 'utils/validators';

type Props = {
  fromSignup?: boolean;
};

const StyledDisclaimerText = styled(Text)`
  @media ${breakpoints.mobileLarge} {
    text-align: center;
  }
`;

const DesktopWrapper = styled.div`
  display: block;
  margin-top: ${spacers.tab};
  @media ${breakpoints.mobileLarge} {
    display: none;
  }
`;
const MobileWrapper = styled.div`
  display: none;
  @media ${breakpoints.mobileLarge} {
    display: block;
  }
`;

const KYCAddress = (props: Props): React.ReactElement => {
  const { updateCurrentKYCPage } = useNavigateFunction();
  const { fromSignup } = props;

  // Make Redux is up-to-date with current page.
  useVerifyCurrentPage(Pages.KYC_ADDRESS, PageType.KYC);

  // ///////////////////////////////
  /* =========== HOOKS ========== */
  // ///////////////////////////////
  const dispatch = useDispatch<WEB.Dispatch>();

  // ///////////////////////////////
  /* =========== STATE ========== */
  // ///////////////////////////////
  // Unique sessiontoken for Google Place requests.
  const [sessiontoken, setSessiontoken] = React.useState<string>(uuidv4());
  const [isCompleted, setIsCompleted] = React.useState<boolean>(false);

  // ///////////////////////////////
  /* ======== MAPPED STATE ====== */
  // ///////////////////////////////
  const languageCtx = useSelector((state: WEB.RootState) =>
    language.getKYCPageLanguage(state, Pages.KYC_ADDRESS)
  );

  const property: WEB.Property | null = useSelector(
    (state: WEB.RootState) =>
      appData.getLegalProperty(state) || appData.getPrimaryProperty(state)
  );

  // //////////////////////////////////
  /* =========== HANDLERS ========== */
  // //////////////////////////////////
  const onSubmit = async (
    values: Record<string, any>
  ): Promise<void | Record<string, string>> => {
    // Remove any error banner from previous attempts
    dispatch(removeBanner());

    const placeid = values.address.place_id;
    const apartment = values.apartment || null;
    const own = values.ownership;
    const { error } = await dispatch(
      createProperty(placeid, apartment, own, sessiontoken)
    );

    // From Google Places docs: https://developers.google.com/maps/documentation/places/web-service/autocomplete#sessiontoken
    // > The session begins when the user starts typing a query, and concludes when they select a place
    // > and a call to Place Details is made.
    // Reset sessiontoken after a call to Place Details is made.
    setSessiontoken(uuidv4());

    if (error) return { address: ErrorMessages.input.validAddress };
  };

  const submitCallback = async (errors?: unknown): Promise<void> => {
    if (!errors) {
      await delayWithState(400, setIsCompleted);
      dispatch(
        updateCurrentKYCPage({
          fromSignup,
          page: Pages.KYC_CONFIRM,
        })
      );
    }
  };

  // //////////////////////////////////
  /* =========== LANGUAGE ========== */
  // //////////////////////////////////

  // Section keys
  const ADDRESS_KEY = 'address';
  const APARTMENT_KEY = 'apartment';

  // Sections
  const addressSection = getLanguageSection(languageCtx, ADDRESS_KEY);
  const apartmentSection = getLanguageSection(languageCtx, APARTMENT_KEY);
  const infoPaneContext = getLanguageInfo(languageCtx);

  // Buttons
  const buttonsCtx = getLanguageButtons(languageCtx);

  // ///////////////////////////////////////
  /* =========== FORM ELEMENTS ========== */
  // ///////////////////////////////////////

  const AddressInput = ({
    input,
    meta,
  }: FieldRenderProps<any, HTMLElement>): React.ReactElement => {
    return (
      <Dropdown.AddressTypeahead
        id='address-typeahead'
        label={addressSection?.header}
        hideLabelOnMobile
        sessiontoken={sessiontoken}
        value={input?.value?.description}
        placeholder={addressSection?.placeholder}
        onSelect={input.onChange}
        invalid={meta?.touched && (meta.error || meta.submitError)}
        errorText={meta?.error || meta?.submitError}
        showError
        fullWidth
      />
    );
  };

  const ApartmentInput = ({ input, meta }: Partial<WEB.InputFieldProps>) => {
    return (
      <Input.Text
        id='apartment-input'
        type='text'
        placeholder={apartmentSection?.placeholder}
        {...input}
        invalid={meta?.touched && meta.error}
        errorText={apartmentSection?.errorMessage || meta?.error}
      />
    );
  };

  const leadText =
    typeof infoPaneContext?.text === 'string' ? [infoPaneContext.text] : [];

  return (
    <>
      <PageBase
        mobileHeader={addressSection?.header || ''}
        mobileLead={leadText}
        buttonSpace='small'
      >
        <Form
          onSubmit={onSubmit}
          initialValues={{
            address: property && {
              place_id: property.google_placeid,
              description: property.google_address,
            },
            apartment: property?.apt,
            ownership: property?.own,
          }}
          render={({ handleSubmit, submitting }) => (
            <form
              onSubmit={(event) => {
                handleSubmit(event)?.then(submitCallback);
              }}
            >
              <Spacer space={homeSpacers.g4} />
              <Field
                name='address'
                component={AddressInput}
                validate={composeValidators(isRequired)}
              />
              <Spacer space={spacers.small} desktopOnly />
              <Field name='apartment' component={ApartmentInput} />
              <MobileWrapper>
                <Spacer space={homeSpacers.g8} />
                <StyledDisclaimerText
                  color={TextColor.GRAY}
                  size={TextSizes.SMALL}
                >
                  {buttonsCtx?.disclaimer}
                </StyledDisclaimerText>
              </MobileWrapper>
              <ButtonsContainer>
                <Button
                  isCompleted={isCompleted}
                  isLoading={submitting}
                  disabled={submitting}
                >
                  {isCompleted ? buttonsCtx?.complete : buttonsCtx?.primary}
                </Button>
              </ButtonsContainer>
              <DesktopWrapper>
                <StyledDisclaimerText
                  color={TextColor.GRAY}
                  size={TextSizes.SMALL}
                >
                  {buttonsCtx?.disclaimer}
                </StyledDisclaimerText>
              </DesktopWrapper>
            </form>
          )}
        />
      </PageBase>
    </>
  );
};

export default KYCAddress;
