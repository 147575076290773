import { NavigateFunction } from 'react-router-dom';
import getKYCLanguage from 'actions/async/getKYCLanguage';
import types from 'actions/types';
import updateCurrentGeniusPage from 'actions/updateCurrentGeniusPage';
import updateCurrentSignupPage from 'actions/updateCurrentSignupPage';
import Pages, { GeniusPages, SignupPages } from 'constants/Pages';
import * as language from 'reducers/entities/language';
import * as WEB from 'types/interfaces';

type Kwargs = {
  /* Page to navigate to. */
  page: string;
  /* Toggle whether or not to change the page in view. Default true. */
  shouldNavTo?: boolean;
  /* Is from signup. */
  fromSignup?: boolean;
  /* Location state to pass with navigation. */
  locationState?: Record<string, any>;
};

/**
 * Creates an action creator to update current kyc page state in redux
 * and to navigate the user to the next kyc page.
 *
 * @param navigate - Navigate hook.
 * @return {Function} Function that accepts paramsObject (object) - kwargs.
 */
const updateCurrentKYCPage =
  (navigate: NavigateFunction) =>
  ({
    page = Pages.KYC_ADDRESS,
    shouldNavTo = true,
    fromSignup = false,
    locationState = {},
  }: Kwargs): WEB.ActionCreator<void> =>
  async (dispatch: WEB.Dispatch, getState: WEB.GetStateFunc) => {
    const updateCurrentKYCPageAction = {
      type: types.UPDATE_CURRENT_KYC_PAGE,
      payload: { page },
    };

    // If page is a Genius page, handle it in updateCurrentGeniusPage action.
    if (Object.values(GeniusPages).includes(page as GeniusPages)) {
      // Wait until we've navigated to Genius page - otherwise, the "useVerifyCurrentPage" hook
      // will re-update current signup page
      await dispatch(
        updateCurrentGeniusPage(navigate)({ page, fromSignup: true })
      );

      // Reset current signup page
      updateCurrentKYCPageAction.payload.page = '';
      dispatch(updateCurrentKYCPageAction);
      return;
    }

    // If page is a signup page, handle it in updateCurrentSignupPage action.
    if (Object.values(SignupPages).includes(page as SignupPages)) {
      // Wait until we've navigated to Genius page - otherwise, the "useVerifyCurrentPage" hook
      // will re-update current signup page
      await dispatch(updateCurrentSignupPage(navigate)({ page }));

      // Reset current signup page
      updateCurrentKYCPageAction.payload.page = '';
      dispatch(updateCurrentKYCPageAction);
      return;
    }

    // Load KYC language if not yet already
    let state = getState();
    const shouldFetchLanguage = language.shouldFetchKYCLanguage(state);
    if (shouldFetchLanguage) {
      await dispatch(getKYCLanguage());
    }

    // Update redux state to set current signup page
    dispatch(updateCurrentKYCPageAction);

    // Return if we don't want to navigate to the update page
    if (shouldNavTo && page) {
      state = getState();
      const route = language.getKYCRouteByPage(state, page, fromSignup);
      route &&
        navigate(
          {
            pathname: route,
            search: window.location.search,
          },
          {
            state: locationState,
          }
        );
    }
  };

export default updateCurrentKYCPage;
