import styled from 'styled-components';

const PillBadge = styled.div`
  background-color: ${({ theme }) => theme.colors.brandLight3};
  width: fit-content;
  color: ${({ theme }) => theme.colors.primaryAlbertBrand};
  padding: 10px 12px;
  font-size: 0.75rem;
  font-weight: 700;
  border-radius: 20px;
  letter-spacing: 1px;
  line-height: 12px;
`;

export default PillBadge;
