import AddressTypeahead from './AddressTypeahead';
import Dropdown from './Dropdown';
import DropdownGroup from './DropdownGroup';
import TypeaheadDropdown from './TypeaheadDropdown';

export default {
  Menu: Dropdown,
  Group: DropdownGroup,
  Typeahead: TypeaheadDropdown,
  AddressTypeahead,
};
