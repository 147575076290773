import { FORM_ERROR } from 'final-form';
import * as React from 'react';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import updateProfile from 'actions/async/updateProfile';
import removeBanner from 'actions/banner/removeBanner';
import Button from 'components/common/Button';
import Input from 'components/common/Input';
import { InputWidth } from 'components/common/Input/shared';
import Radio from 'components/common/Radio';
import Spacer from 'components/common/Spacer';
import ButtonsContainer from 'components/layout/ButtonsContainer';
import PageBase from 'components/layout/PageBase';
import Pages from 'constants/Pages';
import useNavigateFunction from 'hooks/useNavigateFunction';
import useVerifyCurrentPage from 'hooks/useVerifyCurrentPage';
import * as language from 'reducers/entities/language';
import * as profile from 'reducers/entities/profile';
import { spacers } from 'styles';
import * as WEB from 'types/interfaces';
import { convertDateStringToUnix, getMonthDayYearFromUnix } from 'utils/dates';
import { delayWithState } from 'utils/delay';
import {
  getLanguageButtons,
  getLanguageInfo,
  getLanguageSection,
} from 'utils/getFromLanguage';
import {
  composeValidators,
  maxValue,
  minValue,
  validBirthday,
} from 'utils/validators';

type Props = {
  nextPage: string;
};

const HiddenField = styled.input`
  display: none;
`;

const ProfileInfo = (props: Props): React.ReactElement => {
  const { updateCurrentSignupPage } = useNavigateFunction();
  // Make Redux is up-to-date with currentSignUpPage
  useVerifyCurrentPage(Pages.PROFILE_INFO);
  // ///////////////////////////////
  /* =========== HOOKS ========== */
  // ///////////////////////////////
  const dispatch = useDispatch();

  // ///////////////////////////////
  /* ======== MAPPED STATE ====== */
  // ///////////////////////////////
  const languageCtx = useSelector((state: WEB.RootState) =>
    language.getSignupPageLanguage(state, Pages.PROFILE_INFO)
  );

  const birthdayDefault = useSelector((state: WEB.RootState) =>
    profile.getValueByField(state, 'birthday')
  );

  const isMarriedDefault: boolean | null = useSelector((state: WEB.RootState) =>
    profile.getInfoValueByField(state, 'personal_info', 'married')
  );

  const numKidsDefault: number = useSelector(
    (state: WEB.RootState) =>
      profile.getInfoValueByField(state, 'personal_info', 'kids') || 0
  );

  // ///////////////////////////////////////
  /* =========== DEFAULT VALUE ========== */
  // ///////////////////////////////////////
  let defaultBirthdayValue = '';
  if (birthdayDefault) {
    // Unix to javascript Date object
    const [monthDefault, dayDefault, yearDefault] = getMonthDayYearFromUnix(
      birthdayDefault,
      false,
      true
    );
    const month = Number(monthDefault) < 10 ? `0${monthDefault}` : monthDefault;
    const day = Number(dayDefault) < 10 ? `0${dayDefault}` : dayDefault;
    defaultBirthdayValue = `${month}/${day}/${yearDefault}`;
  }

  // ///////////////////////////////
  /* =========== STATE ========== */
  // ///////////////////////////////
  // const [defaultBirthday, setDefaultBirthday] = React.useState<string>('');
  const [enteredBirthday, setEnteredBirthday] =
    React.useState<string>(defaultBirthdayValue);
  const [isMarried, setIsMarried] = React.useState<boolean | null>(null);
  const [invalidBirthday, setInvalidBirthday] = React.useState<
    boolean | undefined
  >(false);
  const [invalidBirthdayErrorText, setInvalidBirthdayErrorText] =
    React.useState<string | undefined>(undefined);
  const [invalidMarriage, setInvalidMarriage] = React.useState<boolean>(false);

  const [isCompleted, setIsCompleted] = React.useState<boolean>(false);
  const [hasSubmitFailed, setHasSubmitFailed] = React.useState<boolean>(false);

  React.useEffect(() => {
    setIsMarried(isMarriedDefault);
  }, [birthdayDefault]);

  // //////////////////////////////////
  /* =========== HANDLERS ========== */
  // //////////////////////////////////
  const checkInvalid = () => {
    const formErrors: {
      birthday?: string;
      marriage?: string;
    } = {};

    // Invalid birthday
    const missingBirthday = !enteredBirthday;
    const isInvalidBirthday =
      missingBirthday || validBirthday(enteredBirthday) !== undefined;
    setInvalidBirthday(isInvalidBirthday);
    if (isInvalidBirthday) {
      formErrors.birthday = 'error';
    }

    // User did not select an option for married section
    if (isMarried === null) {
      setInvalidMarriage(true);
      formErrors.marriage = 'error';
    }

    if (Object.keys(formErrors).length > 0) {
      return formErrors;
    }
    return false;
  };

  const handleOnNext = async (values: Record<string, number>) => {
    // Remove any error banner from previous attempts
    dispatch(removeBanner());

    const { kids } = values;

    const invalid = checkInvalid();
    if (invalid) return invalid;

    // Generate unix timestamp
    const birthday = convertDateStringToUnix(enteredBirthday, 'MM/dd/yyyy');

    if (!birthday) return;

    const { error }: any = await dispatch(
      updateProfile({
        birthday,
        married: isMarried as boolean,
        kids,
        // Set set_pin and saved_contact so that users do not go thru signup flow again in app.
        // Set pin and save contact are one of the first few screens in app that users are checkpointed to.
        // NOTE: Set pin to True is ok because users are prompted to set pin in app if not yet already.
        personal_info_set_pin: true,
        personal_info_saved_contact: false,
      })
    );

    if (error) return { [FORM_ERROR]: 'error' };
  };

  const submitCallback = async (errors?: any) => {
    if (!errors) {
      await delayWithState(400, setIsCompleted);
      dispatch(updateCurrentSignupPage({ page: props.nextPage }));
    } else {
      setHasSubmitFailed(true);
    }
  };

  const handleIsMarriedOnClick = (value: boolean) => {
    setInvalidMarriage(false);
    setIsMarried(value);
  };

  // //////////////////////////////////
  /* =========== LANGUAGE ========== */
  // //////////////////////////////////

  // Section keys
  const BIRTHDAY_KEY = 'birthday';
  const MARRIED_KEY = 'married';
  const KIDS_KEY = 'kids';

  // Sections
  const birthdaySection = getLanguageSection(languageCtx, BIRTHDAY_KEY);
  const marriedSection = getLanguageSection(languageCtx, MARRIED_KEY);
  const kidsSection = getLanguageSection(languageCtx, KIDS_KEY);

  const infoPaneContext = getLanguageInfo(languageCtx);

  // Buttons
  const buttonsCtx = getLanguageButtons(languageCtx);

  // ///////////////////////////////////////
  /* =========== FORM ELEMENTS ========== */
  // ///////////////////////////////////////

  const BirthdayInput = () => {
    const handleChange = (value: string) => {
      const errorText = validBirthday(value);
      const isInvalid = !!(hasSubmitFailed && errorText);
      setEnteredBirthday(value);
      setInvalidBirthday(isInvalid);
      setInvalidBirthdayErrorText(errorText);
    };

    return (
      <Input.Date
        id='birthday'
        key='birthday'
        floatingLabel={languageCtx.content.birthday.placeholder || 'Birthday'}
        label={birthdaySection?.header}
        description={birthdaySection?.text}
        widthSize={InputWidth.SMALL}
        valueDefault={enteredBirthday}
        onChange={handleChange}
        invalid={invalidBirthday}
        errorText={invalidBirthdayErrorText}
        isFormField={false}
      />
    );
  };

  const KidsInput = ({ input, meta }: Partial<WEB.InputFieldProps>) => {
    const value = input?.value as string | undefined;
    return (
      <Input.Number
        id='num-kids-input'
        floatingLabel={kidsSection?.placeholder}
        label={kidsSection?.header}
        description={kidsSection?.text}
        widthSize={InputWidth.SMALL}
        min='0'
        max='30'
        valueDefault={value}
        {...input}
        invalid={meta?.error && meta.submitFailed && !meta.dirtySinceLastSubmit}
        errorText={kidsSection?.errorMessage || meta?.error}
      />
    );
  };

  const texts =
    infoPaneContext?.text instanceof Array ? infoPaneContext.text : [];

  return (
    <>
      <PageBase mobileHeader={infoPaneContext?.header || ''} mobileLead={texts}>
        {BirthdayInput()}
        <Spacer space={spacers.tabLarge} desktopOnly />
        <Radio.Group
          id='married-radio-buttons'
          label={marriedSection?.header}
          errorText={marriedSection?.errorMessage}
          description={marriedSection?.text}
          invalid={invalidMarriage}
        >
          <Radio.Button
            id='married-yes'
            name='married'
            selected={isMarried !== null && !!isMarried}
            label='Yes'
            value
            onClick={handleIsMarriedOnClick}
            invalid={invalidMarriage}
          />
          <Radio.Button
            id='married-no'
            name='married'
            selected={isMarried !== null && !isMarried}
            label='No'
            value={false}
            onClick={handleIsMarriedOnClick}
            invalid={invalidMarriage}
          />
        </Radio.Group>
        <Spacer space={spacers.tabLarge} desktopOnly />
        <Form
          onSubmit={handleOnNext}
          initialValues={{
            kids: numKidsDefault,
            birthday: false,
            marriage: false,
          }}
          render={({ handleSubmit, submitting }) => (
            <form
              onSubmit={(event) => {
                handleSubmit(event)?.then(submitCallback);
              }}
            >
              <Field
                name='birthday'
                component={HiddenField}
                value={invalidBirthday == null ? '' : 'good'}
                validate={composeValidators((val: string) => !!val)}
              />
              <Field
                name='marriage'
                component={HiddenField}
                value={invalidMarriage == null ? '' : 'good'}
                validate={composeValidators((val: string) => !!val)}
              />
              <Field
                name='kids'
                component={KidsInput}
                validate={composeValidators(minValue(0), maxValue(30))}
              />
              <ButtonsContainer>
                <Button
                  id='profile-info-confirm-button'
                  isCompleted={isCompleted}
                  isLoading={submitting}
                  disabled={submitting}
                >
                  {isCompleted ? buttonsCtx?.complete : buttonsCtx?.primary}
                </Button>
              </ButtonsContainer>
            </form>
          )}
        />
      </PageBase>
    </>
  );
};

export default ProfileInfo;
