import * as React from 'react';
import { isFirefox } from 'react-device-detect';
import styled, { css } from 'styled-components';

export type Props = {
  value: number;
  max: number;
};

const firefoxStyles = css`
  height: 8px;
  border-radius: 20px;
  background-color: ${({ theme }) => theme?.colors.brandLight3};
`;

const StyledProgressBar = styled.div`
  progress {
    background: transparent;
    border: none;
    margin-right: 8px;
  }
  progress[value] {
    width: 100%;
    -webkit-appearance: none;
    appearance: none;
    ${isFirefox && firefoxStyles}
  }
  progress[value]::-webkit-progress-bar {
    height: 8px;
    border-radius: 20px;
    background-color: ${({ theme }) => theme?.colors.brandLight3};
  }
  progress[value]::-webkit-progress-value {
    height: 8px;
    border-radius: 20px;
    transition: 0.2s;
    background-color: ${({ theme }) => theme?.colors.primaryAlbertBrand};
  }

  progress[value]::-moz-progress-bar {
    height: 8px;
    border-radius: 20px;
    transition: 0.2s;
    background-color: ${({ theme }) => theme?.colors.primaryAlbertBrand};
  }
`;

const ProgressBar = ({ value, max }: Props): React.ReactElement => {
  return (
    <StyledProgressBar>
      <progress value={`${value}`} max={`${max}`} />
    </StyledProgressBar>
  );
};

export default ProgressBar;
