/**
 * Persona CTA
 * KYCVerifyIdentity is not part of the KYCFlow component, since it would not benefit
 * from KYCFlow's business rules for routing and UI
 */
import * as React from 'react';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
import { StyledBannerDesktop, StyledBannerMobile } from 'components/BannerPane';
import DownloadTheAppButton from 'components/common/DownloadTheAppButton';
import Spacer from 'components/common/Spacer';
import Text, { TextColor, TextSizes } from 'components/common/Text';
import BorderContainer from 'components/layout/BorderContainer';
import PageBase from 'components/layout/PageBase';
import TwoPaneWrapper from 'components/layout/TwoPaneWrapper';
import ArtMap, { Art } from 'constants/ArtMap';
import useBrowserNavigationBlockWithBanner from 'hooks/useBrowserNavigationBlockWithBanner';
import Navbar from 'pages/unauthenticated/Navbar';
import { breakpoints, mixins } from 'styles';
import { spacers } from 'styles/home';
import { flexGap } from 'utils/polyfills';

const ImageContainer = styled.div`
  position: relative;
  overflow: hidden;
  width: 600px;
  @media ${breakpoints.mobileLarge} {
    width: auto;
  }
`;

const StyledImage = styled.img`
  margin: auto;
  display: block;
  @media ${breakpoints.mobileLarge} {
    width: 138px;
  }
`;

const H1 = styled.h1`
  margin-top: 0;
`;

const StyledContainer = styled.div<{
  right: boolean;
}>`
  width: 50vw;
  min-height: 100%;
  display: flex;
  justify-content: ${({ right }) => (right ? 'flex-start' : 'flex-end')};
  align-items: center;
  padding: 0 ${spacers.g7};
  ${({ right }) => (right ? 'padding-right: 175px; min-width: 700px;' : null)}

  @media ${breakpoints.mobileLarge} {
    width: 100%;
    justify-content: ${({ right }) => (right ? 'flex-start' : 'center')};
    ${({ right }) =>
      right ? `padding-right: ${spacers.g7}; min-width: unset;` : null}
    button {
      width: 100%;
    }
  }
`;

const ContentContainer = styled.div`
  display: flex;
  ${flexGap(mixins.pxToRem(spacers.g7))}
`;

const StyledText = styled(Text)`
  margin: auto 0;
`;

const QRCodeContainer = styled.div`
  width: 134px;
  height: 134px;
  flex-shrink: 0;
`;
const QRCode = styled.img`
  width: 100%;
`;

const MobileBannerWrapper = styled.div`
  margin: 0 ${spacers.g7};
`;

const Link = styled.a`
  color: ${({ theme }) => theme.colors.darkGray1};
  font-weight: 600;

  &:visited,
  &:active,
  &:hover,
  &:link {
    color: ${({ theme }) => theme.colors.darkGray1};
    text-decoration: none;
  }
`;

const MobileContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const DownloadTheAppButtonContainer = styled.div`
  width: 100%;
  margin: 60px 0 27px 0;
`;

const KYCVerifyIdentity = (): React.ReactElement | null => {
  useBrowserNavigationBlockWithBanner();

  const mobileContent = (
    <MobileContentContainer>
      <StyledText>
        To finish setting up your account, you&apos;ll need to continue in the
        app and verify your identity with a quick selfie. Tap the button to
        download the app. Then, sign in using your new login credentials.
      </StyledText>
      <DownloadTheAppButtonContainer>
        <DownloadTheAppButton />
      </DownloadTheAppButtonContainer>
    </MobileContentContainer>
  );

  const desktopContent = (
    <>
      <ContentContainer>
        <QRCodeContainer>
          <QRCode
            src={ArtMap(Art.DownloadTheAppQRCode)}
            alt='Download the app QR code'
          />
        </QRCodeContainer>
        <StyledText>
          To finish setting up your account, you&apos;ll need to continue on
          your phone and verify your identity with a quick selfie. Scan the code
          to download the app. Then, sign in using your new login credentials.
        </StyledText>
      </ContentContainer>
      <Spacer space={spacers.g7} />
    </>
  );

  return (
    <>
      <Navbar signupFlow />
      <MobileBannerWrapper>
        <StyledBannerMobile />
      </MobileBannerWrapper>
      <TwoPaneWrapper>
        <StyledContainer right={false}>
          <ImageContainer>
            <StyledImage src={ArtMap(Art.LicenseScan)} />
          </ImageContainer>
        </StyledContainer>
        <Spacer space={spacers.g6} mobileOnly />
        <StyledContainer right>
          <PageBase maxWidth='584px'>
            <StyledBannerDesktop />
            <BorderContainer>
              <H1>Next step, download the app</H1>
              {isMobile ? mobileContent : desktopContent}
              <Text color={TextColor.GRAY_DARK} size={TextSizes.MEDIUM}>
                Or, go to{' '}
                <Link href='https://app.albrt.co/signup-verification'>
                  app.albrt.co/signup-verification
                </Link>{' '}
                to download the app and continue signing up.
              </Text>
            </BorderContainer>
            <Spacer space={spacers.g7} mobileOnly />
          </PageBase>
        </StyledContainer>
      </TwoPaneWrapper>
    </>
  );
};

export default KYCVerifyIdentity;
