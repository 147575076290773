import addBanner from 'actions/banner/addBanner';
import removeBanner from 'actions/banner/removeBanner';
import types from 'actions/types';
import AlbertClient from 'api/AlbertClient';
import { NotificationTypes } from 'constants/index';
import * as WEB from 'types/interfaces';
import {
  onError,
  onErrorDisplayDefaultBanner,
} from 'utils/axiosMiddlewareUtils';
import getErrorMessageFromResponse from 'utils/getErrorMessageFromResponse';

export interface UpdateEmailFields {
  email_confirm_code: string;
}

export default (
    fieldsToUpdate: UpdateEmailFields
  ): WEB.ActionCreator<Promise<Record<string, any>>> =>
  async (dispatch: WEB.Dispatch): Promise<Record<string, any>> => {
    let error = false;

    // Remove any error banner from previous attempts at this action
    dispatch(removeBanner());

    const response = await dispatch({
      types: [
        types.UPDATE_EMAIL_REQUEST,
        types.UPDATE_EMAIL_SUCCESS,
        types.UPDATE_EMAIL_FAILURE,
      ],
      payload: {
        request: {
          method: 'post',
          url: AlbertClient.profileUpdateEmailView(),
          data: {
            ...fieldsToUpdate,
          },
        },
        options: {
          onError: (middlewareOptions: any, options: any) => {
            error = true;
            const message = getErrorMessageFromResponse(
              middlewareOptions?.error?.response
            );
            if (message) {
              dispatch(addBanner(NotificationTypes.WARNING, message, false));
              return onError(middlewareOptions, options);
            }

            return onErrorDisplayDefaultBanner(middlewareOptions, options);
          },
        },
      },
    });
    return { response, error };
  };
