import * as React from 'react';
import styled from 'styled-components';
import Divider from 'components/common/Divider';
import Expander from 'components/common/Expander';
import Spacer from 'components/common/Spacer';
import { spacers } from 'styles';
import { sizes } from 'styles/breakpoints';
import { spacers as homeSpacers } from 'styles/home';
import * as WEB from 'types/interfaces';
import InfoPaneList from './InfoPaneList';

type Props = {
  expanders: WEB.LanguageInfo['expanders'];
  addExpanderSubheadSpacing: boolean;
  mobileOnly?: boolean;
};

const GroupContainer = styled.div<Props>`
  ${({ mobileOnly }) =>
    mobileOnly && `@media (min-width: ${sizes.mobileLarge}) { display: none; }`}
`;

const InfoPaneExpanderGroup = (props: Props): React.ReactElement => {
  const { expanders: expandersProp, addExpanderSubheadSpacing } = props;
  const [openIndex, setOpenIndex] = React.useState<number | undefined>();

  // Open default on mount
  React.useEffect(() => {
    const expanders = props?.expanders || [];
    const index = expanders.reduce(
      (
        result: undefined | number,
        current: WEB.LanguageExpander,
        idx: number
      ) => {
        if (current?.content?.defaultOpen) {
          return idx;
        }
        return result;
      },
      undefined
    );
    setOpenIndex(index);
  }, []);

  const handleOnToggle = (e: React.MouseEvent<HTMLElement>) => {
    const index = Number(e.currentTarget.dataset?.index);
    setOpenIndex(index === openIndex ? undefined : index);
  };

  // Expander items separated by dividers
  const expanders = expandersProp || [];
  const expanderElements = expanders.map(
    (exp: WEB.LanguageExpander, index: number) => {
      const expanderBody = exp.content ? (
        <InfoPaneList
          list={exp.content}
          addExpanderSubheadSpacing={addExpanderSubheadSpacing}
        />
      ) : null;

      return (
        <React.Fragment key={`expander-${exp.header}`}>
          <Expander
            data-index={index}
            label={exp?.header}
            open={index === openIndex}
            onToggle={handleOnToggle}
          >
            <Spacer space={spacers.tabSmall} />
            {expanderBody}
          </Expander>
          <Divider
            space={spacers.tabSmall}
            style={{ margin: `${spacers.tabSmall} 0` }}
            // Remove bottom margin on last divider to prevent unnecessary overflow scroll
            noSpaceBottom={index === expanders.length - 1}
            className=''
          />
        </React.Fragment>
      );
    }
  );

  return (
    <GroupContainer {...props}>
      <Divider
        className='divider first'
        space={homeSpacers.g7}
        style={{ marginBottom: spacers.tabSmall }}
      />
      {expanderElements}
    </GroupContainer>
  );
};

export default InfoPaneExpanderGroup;
