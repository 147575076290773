import styled from 'styled-components';
import { breakpoints, mixins, spacers } from 'styles';

const ContentPane = styled.div`
  ${mixins.fadeIn()};

  /* Vertical stack content pane (right side) of two pane layout */
  display: flex;
  flex-direction: column;
  width: 58vw;
  position: relative;

  /* Default space between the top, right, bottom edges of screen */
  padding: 0 ${spacers.defaultPaddingLeftRight}
    ${spacers.defaultPaddingTopBottom} ${mixins.pxToRem('148px')};
  /* Space between left and right pnaes */
  margin: 0;

  @media ${breakpoints.desktopMedium} {
    padding-left: ${mixins.pxToRem('100px')};
  }

  @media ${breakpoints.desktopSmall} {
    padding-left: ${mixins.pxToRem('70px')};
  }

  @media ${breakpoints.mobileLarge} {
    position: static;
    width: 100%;
    /* No need for margins when left and right panes will be stacked */
    margin: 0;
    /* Responsive - apply default space between right, bottom, left edges */
    padding-left: ${spacers.defaultPaddingLeftRight};
    padding-bottom: ${spacers.mobilePaddingBottom};
  }

  @media (max-width: 481px) {
    /* 2021/7/6, MPR: 481 is the width at which point the default padding drops
     * below 24px. Think of this as 'min-padding'
     */
    padding: 0 ${mixins.pxToRem('24px')} ${spacers.defaultPaddingTopBottom}
      ${mixins.pxToRem('24px')};
  }
`;

export const ContentContainer = styled.div`
  position: relative;
`;

export default ContentPane;
