// Modules
import colors from './colors';

export default {
  main: {
    colors: {
      ...colors,
    },
  },
  genius: {
    colors: {
      ...colors,
      primaryAlbertBrand: colors.primaryGenius,
      brandDark1: colors.geniusDark1,
      brandDark2: colors.geniusDark1,
    },
  },
  dark: {
    colors: {
      ...colors,
      primaryAlbertBrand: colors.primaryText,
      brandDark1: colors.primaryAlbertBrand,
      brandDark2: colors.primaryAlbertBrand,
    },
  },
  light: {
    colors: {
      ...colors,
      primaryAlbertBrand: colors.lightGray2,
      brandDark1: colors.lightGray1,
      buttonText: colors.primaryText,
    },
  },
  warning: {
    colors: {
      ...colors,
      primaryAlbertBrand: colors.primaryWarning,
      brandDark1: colors.darkWarning1,
    },
  },
  error: {
    colors: {
      ...colors,
      primaryAlbertBrand: colors.primaryError,
      brandDark1: colors.darkError1,
    },
  },
};
