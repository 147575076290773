/* eslint-disable @typescript-eslint/naming-convention */

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import getAppData from 'actions/async/getAppData';
import cancelACHTransaction, {
  InputPayload as CancelInputPayload,
} from 'actions/async/transactions/cancelACHTransaction';
import createTransferTransaction, {
  TransferPayload,
} from 'actions/async/transactions/createTransferTransaction';
import addBanner from 'actions/banner/addBanner';
import removeBanner from 'actions/banner/removeBanner';
import getNextPage from 'actions/getNextPage';
import BannerPane from 'components/BannerPane';
import Button from 'components/common/Button';
import Modal from 'components/common/Modal';
import { Props as BasicModalProps } from 'components/common/Modal/BasicModal';
import Spacer from 'components/common/Spacer';
import SquareCard from 'components/common/SquareCard';
import SquareCardGroup from 'components/common/SquareCard/SquareCardGroup';
import Text, { TextSizes } from 'components/common/Text';
import ButtonsContainer from 'components/layout/ButtonsContainer';
import ContentPane, { ContentContainer } from 'components/layout/ContentPane';
import PageBase from 'components/layout/PageBase';
import TwoPaneWrapper from 'components/layout/TwoPaneWrapper';
import SignupInfoPane from 'components/signup/SignupInfoPane';
import Pages from 'constants/Pages';
import {
  ErrorMessages,
  ModalAction,
  NotificationTypes,
  TransfersAccountType,
  TransfersSubmissionType,
} from 'constants/index';
import useBrowserCloseWarning from 'hooks/useBrowserCloseWarning';
import useNavigateFunction from 'hooks/useNavigateFunction';
import useVerifyCurrentPage from 'hooks/useVerifyCurrentPage';
import Navbar from 'pages/unauthenticated/Navbar';
import * as appData from 'reducers/entities/appData';
import * as language from 'reducers/entities/language';
import {
  getPreviouslySubmittedTransactionACHAccountId,
  getPreviouslySubmittedTransactionId,
} from 'reducers/ui/banking';
import { breakpoints, fontSizes, spacers } from 'styles';
import { spacers as homeSpacers } from 'styles/home';
import * as WEB from 'types/interfaces';
import { delayWithState } from 'utils/delay';
import {
  getLanguageButtons,
  getLanguageInfo,
  getLanguageSection,
} from 'utils/getFromLanguage';
import { logger } from 'utils/logger';
import renderTemplateContext from 'utils/renderTemplateContext';

const MINIMUM_BALANCE_REQUIRED = 50;
export let JUMPSTART_CASH_MINIMUM_BALANCE_REQUIRED = MINIMUM_BALANCE_REQUIRED; // eslint-disable-line prefer-const

type Props = {
  nextPage: string;
};

type ModalProps = Omit<BasicModalProps, 'show'>;

const SmallText = styled.span`
  ${fontSizes.fontSize16}
  font-weight: 500;
`;

const LargeText = styled.span`
  ${fontSizes.fontSize24}
  font-weight: 700;

  @media ${breakpoints.mobileLarge} {
    ${fontSizes.fontSize18}
  }

  @media ${breakpoints.mobileMedium} {
    ${fontSizes.fontSize16}
  }
`;

const StyledSquareCardGroup = styled(SquareCardGroup)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-auto-rows: auto;
  grid-gap: 12px;

  @media ${breakpoints.mobileMedium} {
    grid-auto-rows: 4.06rem;
    height: auto;
  }
`;

const HideOnMobile = styled.div`
  @media ${breakpoints.mobileLarge} {
    display: none;
  }
`;

const JumpstartButtonsContainer = styled(ButtonsContainer)`
  @media ${breakpoints.mobileLarge} {
    &.buttons-container {
      margin-top: ${homeSpacers.g13};
    }
  }
`;

const JumpstartCash = (props: Props): React.ReactElement | null => {
  // //////////////////////////////////
  /* =========== STATE ========== */
  // //////////////////////////////////
  const [selectedAmount, setSelectedAmount] = React.useState(50);
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [isCompleted, setIsCompleted] = React.useState<boolean>(false);

  const [showModal, setShowModal] = React.useState(false);
  const [modalPayload, setModalPayload] = React.useState<ModalProps | null>(
    null
  );

  // ///////////////////////////////
  /* ======= MAPPED STATE ======= */
  // ///////////////////////////////
  const languageCtx = useSelector((state: WEB.RootState) =>
    language.getSignupPageLanguage(state, Pages.JUMPSTART_CASH)
  );

  const bankingAccount: WEB.BankingAccount | null = useSelector(
    appData.getBankingAccount
  );

  const primaryCheckingAccount: WEB.InstitutionAccount | null = useSelector(
    appData.getPrimaryCheckingAccount
  );

  const transactions: WEB.BankingTransaction[] = useSelector(
    appData.getBankingTransactions
  );

  const latestTransactions =
    React.useRef<WEB.BankingTransaction[]>(transactions);

  // Ensure we are using the latest transaction data within our event handlers
  latestTransactions.current = transactions;
  const previousTransactionId: number | null = useSelector(
    getPreviouslySubmittedTransactionId
  );
  const previousTransactionACHAccountId: number | null = useSelector(
    getPreviouslySubmittedTransactionACHAccountId
  );

  // //////////////////////////////////
  /* =========== HOOKS ========== */
  // //////////////////////////////////
  const dispatch = useDispatch();
  // Make sure Redux is up-to-date with currentSignUpPage
  useVerifyCurrentPage(Pages.JUMPSTART_CASH);
  // Fire alert if user attempts to close this page since they will not be able to navigate back here again
  useBrowserCloseWarning();
  const { updateCurrentSignupPage } = useNavigateFunction();

  // //////////////////////////////////
  /* =========== LANGUAGE ========== */
  // //////////////////////////////////
  const infoCtx = getLanguageInfo(languageCtx);
  const introCtx = getLanguageSection(languageCtx, 'intro');
  const buttonsCtx = getLanguageButtons(languageCtx);
  const confirmCtx = getLanguageSection(languageCtx, 'confirmModal');

  // //////////////////////////////////
  /* =========== HANDLERS ========== */
  // //////////////////////////////////

  const handleCloseModal = (): void => {
    if (!isSubmitting) {
      setShowModal(false);
      setModalPayload(null);
    }
  };

  const createModalActionHandler =
    (modalAction: ModalAction): (() => void) =>
    async () => {
      if (modalAction === ModalAction.SKIP) {
        setIsSubmitting(true);
        const { error, nextPageOverride }: any = await dispatch(getNextPage());
        setIsSubmitting(false);
        if (!error) {
          await delayWithState(400, setIsCompleted);
          dispatch(
            updateCurrentSignupPage({
              page: nextPageOverride || props.nextPage,
            })
          );
        }
      } else {
        // Default behavior would be to close modal.
        setShowModal(false);
        setModalPayload(null);
      }
    };

  /**
   * @return Promise<boolean> True if transaction was successful; false if any errors occurred
   */
  const handleCancelTransaction = async (): Promise<boolean> => {
    // primaryCheckingAccount shouldn't be null, but just check for TypeScript
    if (
      !previousTransactionId ||
      !previousTransactionACHAccountId ||
      primaryCheckingAccount === null
    ) {
      return true;
    }
    const cancelPayload: CancelInputPayload = {
      achAccountId: previousTransactionACHAccountId,
      transactionId: previousTransactionId,
      // Don't show error messages from this call since the user is unaware a transaction is being cancelled
      suppressErrorBanner: true,
    };
    const { error }: any = await dispatch(cancelACHTransaction(cancelPayload));
    return !error;
  };

  /**
   * @return Promise<boolean> True if transaction was successful; false if any errors occurred
   */
  const handleCreateTransaction = async (): Promise<boolean> => {
    // Sanity check for TypeScript -- We shouldn't be on this page if this is null (see "Pre-action Logic" above)
    if (primaryCheckingAccount === null || bankingAccount === null) {
      const missingAccType =
        primaryCheckingAccount === null
          ? 'linked external primary checking account'
          : 'Albert Cash bank account';

      logger.error(
        `Unable to initiate jumpstart transfer because ${missingAccType} does not exist`
      );
      dispatch(
        addBanner(NotificationTypes.WARNING, ErrorMessages.web.generic, false)
      );
      setShowModal(false);
      setModalPayload(null);
      return false;
    }

    if (previousTransactionId) {
      // Refresh transaction data
      await dispatch(getAppData());
      const transactionData: WEB.BankingTransaction | null =
        latestTransactions.current.find(
          (transaction: WEB.BankingTransaction) =>
            transaction.pk === previousTransactionId
        ) || null;
      if (transactionData?.amount === selectedAmount) {
        // Transaction for the selected amount is already in progress
        return true;
      }
      const isCancelSuccess = await handleCancelTransaction();
      if (!isCancelSuccess) {
        dispatch(
          addBanner(NotificationTypes.WARNING, ErrorMessages.web.generic, false)
        );
        // Do not initiate a jumpstart transaction if there was a cancellation error
        setShowModal(false);
        setModalPayload(null);
        return false;
      }
    }

    if (selectedAmount === 0) return true;

    // Create Transaction
    // NOTE: reference iOS code - ASCashJumpStartPresenter.swift > initiateTransfer().
    const transferPayload: TransferPayload = {
      amount: selectedAmount,
      submission_type: TransfersSubmissionType.JUMPSTART,
      source_id: primaryCheckingAccount.id,
      source_type: TransfersAccountType.EXTERNAL,
      target_id: bankingAccount.id,
      target_type: TransfersAccountType.ALBERT_CASH,
    };

    const { errorPayload }: any = await dispatch(
      createTransferTransaction(transferPayload)
    );

    const DEFAULT_TRANSFER_ERROR_TITLE = 'Hold on';
    const DEFAULT_TRANSFER_ERROR_MESSAGE =
      'Unable to transfer money at this time.\n\nPlease try again later.';

    const {
      title = DEFAULT_TRANSFER_ERROR_TITLE,
      albert_api_error_message = DEFAULT_TRANSFER_ERROR_MESSAGE,
      button,
      button_action,
      cancel,
      cancel_action,
    } = errorPayload || {};

    // Create modal payload based on error response.
    setModalPayload((prev) =>
      errorPayload
        ? {
            title,
            description: albert_api_error_message,
            cancelLabel: cancel,
            onCancel: createModalActionHandler(cancel_action),
            onClickX: handleCloseModal,
            submitLabel: button,
            onSubmit: createModalActionHandler(button_action),
            iconType: NotificationTypes.WARNING,
          }
        : prev
    );
    setShowModal((prev) => (!showModal && !!errorPayload) || prev);

    // Success
    return !errorPayload;
  };

  const handleConfirmModalOnSubmit = async (): Promise<void> => {
    setIsSubmitting(true);
    const isSuccess = await handleCreateTransaction();
    const { error, nextPageOverride }: any = await dispatch(getNextPage());
    setIsSubmitting(false);
    if (isSuccess && !error) {
      await delayWithState(400, setIsCompleted);
      setShowModal(false);
      setModalPayload(null);

      dispatch(
        updateCurrentSignupPage({ page: nextPageOverride || props.nextPage })
      );
    }
  };

  const confirmModalPayload: ModalProps = {
    title: renderTemplateContext(
      confirmCtx?.header || 'Transfer of $[[amount]]',
      { amount: selectedAmount }
    ),
    description: renderTemplateContext(
      confirmCtx?.text ||
        "We'll jumpstart your Albert account by transferring $[[amount]] from your bank account into Albert today.",
      { amount: selectedAmount }
    ),
    submitLabel: confirmCtx?.primary || 'Okay',
    cancelLabel: confirmCtx?.secondary || 'Cancel',
    onSubmit: handleConfirmModalOnSubmit,
    onCancel: handleCloseModal,
    iconType: NotificationTypes.SUCCESS,
  };

  const handlePageOnSubmit = async (): Promise<void> => {
    // Remove any error banner from previous attempts
    dispatch(removeBanner());

    if (selectedAmount === 0) {
      setIsSubmitting(true);
      await handleCancelTransaction();
      const { error, nextPageOverride }: any = await dispatch(getNextPage());

      setIsSubmitting(false);
      // Move on to the next page regardless of whether handleCancelTransaction had errors
      if (!error) {
        await delayWithState(400, setIsCompleted);
        dispatch(
          updateCurrentSignupPage({ page: nextPageOverride || props.nextPage })
        );
        return;
      }
    }
    setShowModal(true);
    setModalPayload(confirmModalPayload);
  };

  const handleCardClick = (value: number): void => {
    setSelectedAmount(value);
  };

  // //////////////////////////////////
  /* =========== RENDER ========== */
  // //////////////////////////////////

  // TODO: Move this to a component to be shared with SavingsSetup - [WEB-0721-1202]
  const cards = ((introCtx?.options as number[]) || []).map((option) => {
    const header = (
      <>
        <SmallText>$</SmallText>
        <LargeText>{option}</LargeText>
      </>
    );
    return (
      <SquareCard
        grid
        aria-label={`$${option}`}
        key={`${option}`}
        value={option}
        selected={option === selectedAmount}
        size={TextSizes.SMALL}
        header={header}
        clickHandler={handleCardClick}
      />
    );
  });

  return (
    <>
      <Navbar split signupFlow />
      <TwoPaneWrapper>
        <SignupInfoPane />
        <ContentPane>
          <BannerPane />
          <ContentContainer>
            <Spacer space={homeSpacers.g3} />
            <PageBase mobileHeader={infoCtx?.header || ''}>
              <HideOnMobile>
                <h3>{introCtx?.header}</h3>
              </HideOnMobile>
              <Text className='description'>{introCtx?.text}</Text>
              <Spacer space={spacers.tabSmall} desktopOnly />
              <Spacer space={homeSpacers.g2} mobileOnly />
              <StyledSquareCardGroup id='jumpstart-amount-group'>
                {cards}
              </StyledSquareCardGroup>
              <JumpstartButtonsContainer>
                <Button
                  id='jumpstart-cash-confirm-button'
                  isCompleted={isCompleted}
                  isLoading={isSubmitting}
                  disabled={isSubmitting}
                  onClick={handlePageOnSubmit}
                >
                  {isCompleted ? buttonsCtx?.complete : buttonsCtx?.primary}
                </Button>
              </JumpstartButtonsContainer>
              {modalPayload && (
                <Modal.Basic
                  show={showModal}
                  isSubmitting={isSubmitting}
                  isCompleted={isCompleted}
                  disabled={isSubmitting}
                  {...modalPayload}
                />
              )}
            </PageBase>
          </ContentContainer>
        </ContentPane>
      </TwoPaneWrapper>
    </>
  );
};

export default JumpstartCash;
