import { Minus } from '@styled-icons/fa-solid/Minus';
import { Plus } from '@styled-icons/fa-solid/Plus';
import * as React from 'react';
import styled, { css } from 'styled-components';
import Text, { TextSizes } from 'components/common/Text';
import { breakpoints, mixins } from 'styles';

export type Props = {
  /* the label to display */
  label: string | React.ReactNode;
  /* flag for showing the contents of the expansion panel */
  open: boolean;
  /* the theme to use for this component */
  theme?: any;
  /* config expander (+/-) icon */
  iconSize?: string;
  /* assuming an expander element can have child elements */
  children?: React.ReactNode | React.ReactNode[];
  /* whenever the panel is expanded */
  onToggle: (e: React.MouseEvent<HTMLDivElement>) => void;
};

const StyledExpander = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const IconStyling = css<{ iconSize?: string }>`
  color: ${({ theme }) => theme.colors.primaryText};
  width: ${({ iconSize }) => mixins.pxToRem(iconSize || '16px')};
  margin-left: ${mixins.pxToRem('16px')};
  @media ${breakpoints.mobileLarge} {
    width: 12px;
  }
`;
const StyledPlusIcon = styled(Plus)<{ iconSize?: string }>`
  ${IconStyling}
`;

const StyledMinusIcon = styled(Minus)<{ iconSize?: string }>`
  ${IconStyling}
`;

const StyledText = styled(Text)<{ className: string }>`
  line-height: normal;
  max-width: ${mixins.pxToRem('310px')};
`;

const Expander = (props: Props): React.ReactElement => {
  const { open, label, children, iconSize, onToggle, ...otherProps } = props;

  const icon = open ? (
    <StyledMinusIcon iconSize={iconSize} />
  ) : (
    <StyledPlusIcon iconSize={iconSize} />
  );
  const expansionTitle =
    typeof label === 'string' ? (
      <StyledText
        className='expander-label'
        size={TextSizes.MEDIUM}
        weight='700'
        inline
      >
        {label}
      </StyledText>
    ) : (
      label
    );

  return (
    <>
      <StyledExpander
        {...otherProps}
        onClick={onToggle}
        className='styled-expander'
      >
        {expansionTitle}
        {icon}
      </StyledExpander>
      {open && children}
    </>
  );
};

export default Expander;
