import { Checkmark } from '@styled-icons/icomoon/Checkmark';
import * as React from 'react';
import styled from 'styled-components';
import ProgressBar from 'components/common/ProgressBar';
import Spacer from 'components/common/Spacer';
import PageBase from 'components/layout/PageBase';
import { breakpoints, mixins, spacers } from 'styles';
import { spacers as homeSpacers } from 'styles/home';
import { delay } from 'utils/delay';

type Props = {
  /* page header */
  header: string;
  /* list of checkpoints to render */
  checkpoints: string[];
  /* indicator for when we can proceed to nextPage */
  canProceed: boolean;
  /* triggered when submitting the page, e.g. to navigate to the next page */
  onSubmit: () => void;
};

const Container = styled.div`
  width: 100%;
`;

const StyledList = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${spacers.small};
`;

const StyledListItem = styled.div`
  margin-bottom: ${spacers.small};
`;

const StyledCheckMark = styled(Checkmark)`
  color: ${({ theme }) => theme?.colors.primaryAlbertBrand};
  width: 14px;
  margin-right: ${mixins.pxToRem('8px')};
  vertical-align: unset;
`;

const StyledLabel = styled.span`
  color: ${({ theme }) => theme?.colors.primaryGray};
`;

const DesktopHeader = styled.h1`
  margin: 0;
  @media ${breakpoints.mobileLarge} {
    display: none;
  }
`;

const Processing = (props: Props): React.ReactElement => {
  const [progress, setProgress] = React.useState(0);

  const checkpointElements = props.checkpoints.map((checkpoint: string) => {
    return (
      <StyledListItem key={`checkpoint-${checkpoint}`}>
        <StyledCheckMark />
        <StyledLabel>{checkpoint}</StyledLabel>
      </StyledListItem>
    );
  });

  const proceedToNextPage = async () => {
    // In the case where "Finishing up" and "Complete!" render almost simultaneously,
    // we want to create a 0.5 second delay before "Complete!" shows up
    await delay(500); // delay 0.5 seconds
    setProgress((oldValue) => oldValue + 1);
    await delay(2000); // delay 2 seconds
    props.onSubmit();
  };

  // On mount start interval
  React.useEffect(() => {
    const interval = setInterval(() => {
      setProgress((oldValue) => {
        const newValue = oldValue + 1;
        if (newValue === props.checkpoints.length - 1) {
          clearInterval(interval);
        }
        return newValue;
      });
    }, 2000);

    // Clean up
    return () => clearInterval(interval);
  }, []);

  // Handle when parent component indicates that its ready to move on
  React.useEffect(() => {
    // Proceed to next page if:
    // 1. Already finished rendering all checkpoints
    // 2. props.canProceed === true
    const willProceed =
      progress === props.checkpoints.length - 1 && props.canProceed;
    willProceed && proceedToNextPage();
  }, [progress, props.canProceed]);

  return (
    <PageBase mobileHeader={props.header}>
      <Container>
        <Spacer space={spacers.tabLarge} desktopOnly />
        <DesktopHeader>{props.header}</DesktopHeader>
        <Spacer space={homeSpacers.g8} />
        <ProgressBar value={progress} max={props.checkpoints.length} />
        <StyledList>{checkpointElements.slice(0, progress)}</StyledList>
      </Container>
    </PageBase>
  );
};

export default Processing;
