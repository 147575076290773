export enum SignupPages {
  EMAIL_VERIFICATION_SENT = 'EmailVerificationSent',
  SET_UP_ALBERT = 'SetUpAlbert',
  CONFIRM_NUMBER = 'ConfirmNumber',
  SOURCE = 'Source',
  TEXT_OPTIN = 'TextOptin',
  PROFILE_INFO = 'ProfileInfo',
  ABOUT_YOU_GUIDANCE = 'AboutYouGuidance',
  EMPLOYMENT_STATUS = 'EmploymentStatus',
  PROFILE_EMPLOYMENT_INFO = 'ProfileEmploymentInfo',
  EMPLOYMENT = 'Employment',
  BIRTHDAY = 'Birthday',
  INVESTING_AFFILIATIONS = 'InvestingAffiliations',
  ADD_TRUSTED_CONTACT = 'AddTrustedContact',

  SUPERCHARGE = 'Supercharge',
  SET_UP_FIRST = 'SetUpFirst',
  INSTANT_BENEFITS = 'InstantBenefits',
  INSTANT_CONFIRM = 'InstantConfirm',
  CANCEL_SUBSCRIPTIONS_BENEFITS = 'CancelSubscriptionsBenefits',
  SUBSCRIPTIONS_NOTED = 'SubscriptionsNoted',
  BUDGETING_BENEFITS = 'BudgetingBenefits',
  BUDGETING_NOTED = 'BudgetingNoted',
  INVESTING_BENEFITS = 'InvestingBenefits',
  INVESTING_INTERESTS = 'InvestingInterests',
  INVESTING_CONFIRM = 'InvestingConfirm',
  SAVINGS_SETUP = 'SavingsSetup',
  SAVINGS_BENEFITS = 'SavingsBenefits',
  SAVINGS_BENEFITS_SINGLE_SCREEN = 'SavingsBenefitsSingleScreen',
  SAVINGS_CONFIRM = 'SavingsConfirm',
  INVESTING_PREFERENCES = 'InvestingPreferences',

  SOURCE_PHONE_NUMBER = 'SourcePhoneNumber',
  ADDRESS = 'Address',
  BANK_SETUP = 'BankSetup',
  INSTANT_INFO = 'InstantInfo',
  INVESTING_INFO = 'InvestingInfo',
  INVESTING_INTERESTS_INFO = 'InvestingInterestsInfo',
  SAVINGS_BENEFITS_INFO = 'SavingsBenefitsInfo',
  ADD_TRUSTED_CONTACT_INFO = 'AddTrustedContactInfo',

  // Banking
  CASH_SETUP = 'CashSetup',
  CASH_DEBIT_CARD_SETUP = 'CashDebitCardSetup',
  CASH_VALUE_PROP = 'CashValueProp',
  SELECT_CARD = 'SelectCard',
  CASH_SELECT_SHIP_DEBIT_CARD = 'CashSelectShipDebitCard',
  CASH_SHIPPING_ADDRESS = 'CashShippingAddress',
  CARD_HAS_SHIPPED = 'CardHasShipped',
  JUMPSTART_CASH = 'JumpstartCash',
  DIRECT_DEPOSIT = 'DirectDeposit',

  VERIFY_EMAIL = 'VerifyEmail',

  // Intentionally separate from KYCPages so as to not use the KYCFlow
  KYC_VERIFY_IDENTITY = 'KYCVerifyIdentity',

  TRACK_SPENDING = 'TrackSpending',
}

export enum GeniusPages {
  GENIUS_TOUR = 'GeniusTour',
  GENIUS_PRICING = 'GeniusPricing',
  GENIUS_SUCCESS = 'GeniusSuccess',
  GENIUS_WELCOME = 'GeniusWelcome',
  GENIUS_ANNUAL_MONTHLY = 'GeniusAnnualMonthly',
  GENIUS_CONFIRM = 'GeniusConfirm',
}

export enum KYCPages {
  KYC = 'KYC',
  KYC_ADDRESS = 'KYCAddress',
  KYC_CONFIRM = 'KYCConfirm',
  KYC_SSN = 'KYCSSN',
  KYC_MFA = 'KYCMFA',
  KYC_CONFIRM_NUMBER = 'KYCConfirmNumber',
  KYC_CONFIRM_EMAIL = 'KYCConfirmEmail',
  KYC_VERIFY_IDENTITY_WEB = 'KYCVerifyIdentityWeb',
}

export enum PageType {
  GENIUS = 'genius',
  SIGNUP = 'signup',
  KYC = 'kyc',
}

export enum OtherPages {
  // Public pages
  LOGIN = 'Login',
  SIGNUP = 'Signup',

  // Download the app
  DOWNLOAD_APP = 'DownloadApp',

  // 2FA
  TWO_FA = 'TwoFAConfirmCode',

  // Link account
  LINK_ACCOUNT = 'LinkAccount',
  SELECT_ACCOUNT = 'SelectAccount',
  FIND_INSTITUTION = 'FindInstitution',
}

// All the pages in Albert Web
export const Pages = {
  // Sign up / banking flow
  ...SignupPages,

  // Genius
  ...GeniusPages,

  // KYC
  ...KYCPages,

  ...OtherPages,
};

export type Pages = SignupPages | KYCPages | GeniusPages | OtherPages;

export default Pages;
