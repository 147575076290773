/* eslint-disable no-param-reassign */

/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import styled from 'styled-components';
import Spacer from 'components/common/Spacer';
import Text, { TextSizes } from 'components/common/Text';
import ZIndex from 'constants/ZIndex';
import { breakpoints, fontSizes, mixins, spacers } from 'styles';

export type Props = {
  /* unique identifier */
  id?: string;
  /* default slider values */
  sliderValues: number[];
  /* default value to display */
  valueDefault: number;
  /* controlled value */
  value: number;
  /* step size */
  step?: number;
  /* text to display for minimum amount */
  minText: string;
  /* onAfterChange fired after slider stops moving */
  onChange: (value: number) => any;
};

const StyledNumberSlider = styled.input.attrs({ type: 'range' })`
  -webkit-appearance: none;
  overflow: none;
  width: 100%;
  &:focus {
    outline: none;
  }
  &::-webkit-slider-runnable-track {
    background-color: ${({ theme }) => theme.colors.lightGray2};
    height: ${mixins.pxToRem('5px')};
    border: none;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    justify-content: start;
    align-items: self-start;
    top: 0px;
    z-index: ${ZIndex.NUMBER_SLIDER_TRACK};
    @media ${breakpoints.tabletXSmall} {
      height: ${mixins.pxToRem('3px')};
    }
  }
  &::-webkit-slider-thumb {
    width: ${mixins.pxToRem('48px')};
    height: ${mixins.pxToRem('48px')};
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    cursor: pointer;
    border: none;
    border-radius: 50%;
    margin-top: -20px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    z-index: ${ZIndex.NUMBER_SLIDER_THUMB} !important;
    -webkit-appearance: none;
    @media ${breakpoints.tabletXSmall} {
      margin-top: -14px;
      width: ${mixins.pxToRem('28px')};
      height: ${mixins.pxToRem('28px')};
    }
  }
  &:focus::-webkit-slider-runnable-track {
    background-color: ${({ theme }) => theme.colors.lightGray2};
  }
  &::-moz-range-track {
    background-color: ${({ theme }) => theme.colors.lightGray2};
    height: ${mixins.pxToRem('5px')};
    border: none;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    justify-content: start;
    align-items: self-start;
    top: 0px;
    z-index: ${ZIndex.NUMBER_SLIDER_TRACK};
  }
  &::-moz-range-thumb {
    width: ${mixins.pxToRem('52px')};
    height: ${mixins.pxToRem('52px')};
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    cursor: pointer;
    border: none;
    border-radius: 50%;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    z-index: ${ZIndex.NUMBER_SLIDER_THUMB} !important;
    @media ${breakpoints.tabletXSmall} {
      width: ${mixins.pxToRem('32px')};
      height: ${mixins.pxToRem('32px')};
    }
  }
`;

const StyledNumberSliderWrapper = styled.div`
  position: relative;
  width: 100%;
  text-align: center;
`;

const StyledNumberValueBubble = styled.div`
  visibility: hidden;
  position: absolute;
`;

const StyledDollarDescription = styled.div`
  max-width: ${mixins.pxToRem('150px')};
  white-space: normal;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  text-align: justify;
`;

const StyledContentWrapper = styled.div`
  margin-top: 1rem;
  width: ${mixins.pxToRem('80px')};
  display: flex;
  justify-content: flex-start;
  div,
  span {
    align-self: baseline;
  }
  @media ${breakpoints.tabletXSmall} {
    margin-top: 0.8rem;
  }
`;

const StyledDollarContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
`;

const StyledDollarText = styled(Text)<{
  value?: number;
}>`
  transition: none;
  color: ${({ theme, value }) =>
    value && value > 0 ? theme.colors.geniusText : theme.colors.primaryGray};
  text-align: left;
  &:first-child {
    margin-right: 2px;
  }

  .dollar-sign {
    ${fontSizes.fontSize20}
  }
  .dollar-value {
    ${fontSizes.fontSize40}
  }
  @media ${breakpoints.tabletXSmall} {
    font-size: 0.9rem;
    line-height: 1.2;
    vertical-align: sub;
    .dollar-value {
      line-height: 1;
      font-size: 2rem;
    }
  }
`;

const NumberSlider = (props: Props): React.ReactElement => {
  const { id, value, valueDefault, minText, sliderValues } = props;
  const componentRef = React.useRef<HTMLDivElement>(null);
  const min = 0;
  const max = sliderValues.length - 1;

  const handleOnChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const valueIndex = Number.parseInt(evt.currentTarget.value);
    const value = sliderValues[valueIndex];
    props.onChange(value);
  };

  const setBubble = (range: any, bubble: any) => {
    const val = range.value;
    const newVal = Number(((val - min) * 100) / (max - min));
    bubble.style.visibility = 'visible';
    bubble.style.left = `calc(${newVal}% + (${10 - newVal * 0.66}px))`;
  };

  React.useEffect(() => {
    let range: any;
    let bubble: any;
    let rangeListener: any;
    if (componentRef?.current) {
      range = componentRef.current.querySelector('.number-slider');
      bubble = componentRef.current.querySelector('.number-value');
      rangeListener = () => setBubble(range, bubble);
      range.addEventListener('input', rangeListener);
      setBubble(range, bubble);
    }

    return () => {
      if (range && rangeListener) {
        range.removeEventListener('input', rangeListener);
      }
    };
  }, [value]);

  return (
    <StyledNumberSliderWrapper
      ref={componentRef}
      className='number-slider-wrap'
    >
      <StyledNumberSlider
        id={id}
        className='number-slider'
        value={sliderValues.indexOf(value)}
        min={min}
        max={max}
        onChange={handleOnChange}
        list='sliderValues'
      />
      <datalist id='sliderValues'>
        {sliderValues.map((val, idx) => {
          return <option key={idx} value={val} label={`${val}`} />;
        })}
      </datalist>
      <StyledNumberValueBubble className='number-value'>
        <StyledDollarContent>
          <StyledContentWrapper>
            <StyledDollarText value={value} weight='400' inline>
              <span className='dollar-sign'>$</span>
            </StyledDollarText>
            <StyledDollarText value={value} weight='700' inline>
              <span className='dollar-value'>{value || 0}</span>
            </StyledDollarText>
          </StyledContentWrapper>
          <StyledDollarDescription>
            <StyledDollarText
              value={value}
              weight='700'
              size={TextSizes.MEDIUM}
            >
              A month
            </StyledDollarText>
            {value === valueDefault && (
              <>
                <Spacer space={spacers.miniscule} />
                <StyledDollarText weight='400' size={TextSizes.SMALL}>
                  {minText}
                </StyledDollarText>
              </>
            )}
          </StyledDollarDescription>
        </StyledDollarContent>
      </StyledNumberValueBubble>
    </StyledNumberSliderWrapper>
  );
};
export default NumberSlider;
