import { differenceInYears, parse } from 'date-fns';
import ErrorMessages from 'constants/ErrorMessages';
import { getDaysInMonth } from 'utils/dates';

export const composeValidators =
  (...validators: any[]) =>
  (value: string): string | undefined => {
    return validators.reduce(
      (error, validator) => error || validator(value),
      undefined
    );
  };

export const isRequired = (value: string): string | undefined => {
  const isDefined = value != null;
  return isDefined ? undefined : ErrorMessages.input.required;
};

export const isNotEmpty = (value: string): string | undefined => {
  const isNotEmpty = !!value.trim();
  return isNotEmpty ? undefined : ErrorMessages.input.required;
};

export const isBooleanRequired = (value: boolean): string | undefined => {
  return value ? undefined : ErrorMessages.input.required;
};

export const mustBeNumber = (value: string): string | undefined => {
  return Number.isNaN(value) ? ErrorMessages.input.mustBeNumber : undefined;
};

export const minLen =
  (min: number) =>
  (value: string): string | undefined => {
    return value && value.length >= min
      ? undefined
      : ErrorMessages.input.minLen;
  };

export const maxLen =
  (max: number) =>
  (value: string): string | undefined => {
    return value && value.length <= max
      ? undefined
      : ErrorMessages.input.maxLen;
  };

/* 2021/04/16, MPR: visual diagram https://emailregex.com/wp-content/uploads/sites/2/2014/06/General-Email-Regex-Railroad-Diagram-emailregex.com_.png,
 * from https://emailregex.com/
 */
export const validEmail = (value: string): string | undefined => {
  const REGEX_EMAIL =
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
  return REGEX_EMAIL.test(value) ? undefined : ErrorMessages.input.validEmail;
};

export const validSSN = (value: string): string | undefined => {
  const REGEX_SSN = /\d{3}-\d{2}-\d{4}/;
  return REGEX_SSN.test(value) ? undefined : ErrorMessages.input.validSSN;
};

export const validDate = (value: string): string | undefined => {
  const REGEX_DATE =
    /^(0[1-9]|1[012])\/(0[1-9]|[12][0-9]|3[01])\/((18|19|20|21)[0-9]{2})$/;

  if (REGEX_DATE.test(value)) {
    const year = Number(value.split('/')[2]);
    const day = Number(value.split('/')[1]);
    const month = Number(value.split('/')[0]) - 1; // month is zero-based
    const validNumberOfDays = getDaysInMonth(year, month);

    const dateTimestamp = new Date(year, month, day).valueOf();

    return !Number.isNaN(dateTimestamp) && day <= validNumberOfDays
      ? undefined
      : ErrorMessages.input.validDate;
  }
  return ErrorMessages.input.validDate;
};

export const validBirthday = (value: string): string | undefined => {
  const isInvalidDate = validDate(value);

  if (!isInvalidDate) {
    const MIN_AGE = 18;
    const MAX_AGE = 120;

    const birthdate = parse(value, 'MM/dd/yyyy', new Date());

    const yearsBetweenDates = differenceInYears(new Date(), birthdate);

    if (yearsBetweenDates < MIN_AGE) {
      return ErrorMessages.input.validBirthdayMinAge;
    }

    if (yearsBetweenDates >= MAX_AGE) {
      return ErrorMessages.input.validBirthday;
    }

    return undefined;
  }
  return ErrorMessages.input.validBirthday;
};

// //////////////////////////////////
/* ========= NUMBER INPUT ======== */
// //////////////////////////////////

export const minValue =
  (min: number) =>
  (value: number): string | undefined => {
    return value >= min ? undefined : ErrorMessages.input.minValue;
  };

export const maxValue =
  (max: number) =>
  (value: number): string | undefined => {
    return value <= max ? undefined : ErrorMessages.input.maxValue;
  };
