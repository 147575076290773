import { CheckCircle } from '@styled-icons/boxicons-solid/CheckCircle';
import { ErrorCircle } from '@styled-icons/boxicons-solid/ErrorCircle';
import { XCircle } from '@styled-icons/boxicons-solid/XCircle';
import { PlusCircle } from '@styled-icons/feather/PlusCircle';
import * as React from 'react';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
import Label from 'components/common/Label';
import CheckCircleRounded from 'components/icons/CheckCircleRounded';
import { Roles } from 'constants/Accessibility';
import ArtMap, { Art } from 'constants/ArtMap';
import ComponentSize from 'constants/ComponentSize';
import GeniusSubscriptionBillingLengths from 'constants/GeniusSubscriptionBillingLengths';
import { breakpoints, fontSizes, mixins } from 'styles';
import { handleActivateKeyDown } from 'utils/accessibility';

export type Props = {
  /* the state of the card */
  selected?: boolean | false;
  /* space between format? */
  spaceBetween?: boolean | false;
  /* the size of the card ie. large,small */
  size?: ComponentSize;
  /* the theme to use for this component */
  theme?: any;
  /* image on the card */
  image?: Art | string;
  /* alt image for non-selected card */
  altImage?: Art | string;
  /* if true, skips adding a width to image
  (useful for svgs when you want to use its inherent size) */
  noImageSizing?: boolean;
  /* lottie animation component */
  lottie?: React.ReactElement | null;
  /* caption of the image */
  caption?: string;
  /* multiple choice cards or single choice cards */
  choice?: string;
  /* error state of the card */
  isError?: boolean | false;
  /* type of card e.g. genius */
  type?: string;
  /* header of the card content */
  header?: string;
  /* body of the card content */
  body?: string;
  /* color override for specific cards */
  color?: string;
  /* label text */
  label?: string;
  /* value to pass on click */
  value?: any;
  /* tab index to be applied to element so it can be selected with tab key */
  tabIndex?: number;
  /* onClick handler function */
  onClick?: (...args: any) => any;
  className?: string;
  isFirst?: boolean;
};

const getBorderColor = (props: Props, isHovering = false) => {
  const { theme, type } = props;
  if (isHovering || props.selected) {
    if (type === 'genius') {
      return theme?.colors.geniusText;
    }
    return theme?.colors.primaryAlbertBrand;
  }
  return theme?.colors.lightGray1;
};

const getCardHeight = ({ size }: Props) => {
  switch (size) {
    case ComponentSize.XSMALL:
      return '5rem'; // 80px
    case ComponentSize.SMALL:
      return '5.31rem'; // 85px
    case ComponentSize.MEDIUM:
      return '5.81rem'; // 93px
    case ComponentSize.LARGE:
      return '6.88rem'; // 110px
    default:
      return '5.81rem'; // 93px
  }
};

const getCardWidth = ({ size }: Props) =>
  size === ComponentSize.SMALL
    ? mixins.pxToRem('429px')
    : mixins.pxToRem('540px');

const getImageWidth = ({ size }: Props) =>
  size === ComponentSize.SMALL
    ? mixins.pxToRem('35px')
    : mixins.pxToRem('75px');

const getMarginLeft = ({ size }: Props) => {
  switch (size) {
    case ComponentSize.XSMALL:
      return '0.5rem'; // 8px
    case ComponentSize.SMALL:
      return '1.6rem'; // 25px
    default:
      return '1.9rem'; // 30px
  }
};

const getMarginLeftContent = ({ size }: Props) => {
  switch (size) {
    case ComponentSize.XSMALL:
      return '1rem'; // 16px
    case ComponentSize.SMALL:
      return '1.6rem'; // 25px
    default:
      return '1.9rem'; // 30px
  }
};

const getContentColor = ({ theme, type, selected, color, value }: Props) => {
  if (color) {
    switch (color) {
      case 'black':
        return theme?.colors.primaryText;
      default:
        return theme?.colors.primaryAlbertBrand;
    }
  }
  if (type === 'genius' && selected) {
    if (value === GeniusSubscriptionBillingLengths.MONTHLY) {
      return theme?.colors.primary;
    }
    return theme?.colors.geniusText;
  }
  return theme?.colors.primaryGray;
};

const StyledCard = styled.div<Props>`
  padding: 28px 22px;
  border-color: ${(props: Props) => getBorderColor(props)};
  ${(props) =>
    props.selected && `box-shadow: 0 0 0 1px ${getBorderColor(props)};`}
  height: ${(props: Props) =>
    props.type === 'genius' ? '' : getCardHeight(props)};
  min-height: ${(props: Props) =>
    props.type === 'genius' ? getCardHeight(props) : ''};
  width: ${(props: Props) => getCardWidth(props)};
  border-style: solid;
  border-width: 1.5px;
  align-items: center;
  border-radius: 4px;
  display: flex;
  justify-content: ${({ type }) =>
    type === 'genius' ? 'center' : 'flex-start'};
  flex-direction: ${({ type }) => (type === 'genius' ? 'column' : 'row')};
  flex-grow: 2;

  @media (hover: hover) {
    &:hover {
      border-color: ${(props: Props) => getBorderColor(props, true)};
      box-shadow: 0 0 0 1px ${(props: Props) => getBorderColor(props, true)};
      cursor: pointer;
    }
  }
  position: relative;
  margin-top: ${({ isFirst }) => (isFirst ? '0' : '14px')};

  @media ${breakpoints.mobileLarge} {
    width: auto;
  }
`;

const StyledCardContent = styled.div<Props>`
  display: flex;
  flex-direction: column;
  color: ${(props) => getContentColor(props)};
  flex-direction: ${({ spaceBetween }) => (spaceBetween ? 'row' : 'column')};
  margin: 0;
  margin-left: ${(props) => getMarginLeftContent(props)};
  ${({ spaceBetween }) => {
    if (spaceBetween) {
      return `
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      margin: 0;
      align-items: center;
      `;
    }
    return `
      justify-content: center;
    `;
  }}
`;

const StyledCardHeader = styled.header<Props>`
  display: flex;
  align-items: center;
  ${fontSizes.fontSize16}
  line-height: ${mixins.pxToRem('25px')};
  ${({ type, theme }) => {
    if (type === 'genius') {
      return `
      ${fontSizes.fontSize28}
      font-weight: bold;
      line-height: ${mixins.pxToRem('33px')};
      @media ${breakpoints.mobileLarge} {
        font-size: 24px;
      }
      `;
    }
    return `
      color: ${theme.colors.primaryText as string};
    `;
  }}
`;

const StyledCardBody = styled.div<Props>`
  display: flex;
  text-align: ${({ spaceBetween }) => (spaceBetween ? 'right' : 'left')};
  align-items: center;
  ${fontSizes.fontSize14}
  max-width: ${({ spaceBetween }) => (spaceBetween ? '83px' : '250px')};
  @media ${breakpoints.mobileLarge} {
    margin-right: ${(props) =>
      !props.spaceBetween && props.size !== ComponentSize.XSMALL
        ? getMarginLeft(props)
        : ''};
  }
`;

const StyledImage = styled.img<Props>`
  ${(props) =>
    !props.noImageSizing &&
    `
    max-width: ${getImageWidth(props)};
    min-width: ${getImageWidth(props)};
  `}
`;

const ImageContainer = styled.div<{ size?: ComponentSize; choice?: string }>`
  width: ${(props) => getImageWidth(props)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${(props) =>
    props?.choice === 'multiple' &&
    `
      margin-left: ${getMarginLeft(props)};
    `}
`;

const CheckCircleRoundedContainer = styled.div<Props>`
  /* width property added due to a Firefox inconsistency */
  width: ${mixins.pxToRem('30px')};
  max-width: ${mixins.pxToRem('30px')};
  min-width: ${mixins.pxToRem('30px')};
  color: ${({ theme, type }) =>
    type === 'genius'
      ? theme?.colors.geniusText
      : theme?.colors.primaryAlbertBrand};
  position: absolute;
  top: -14px;
  right: -16px;
`;

const StyledCheckCircleRounded = (props: Props) => (
  <CheckCircleRoundedContainer {...props}>
    <CheckCircleRounded />
  </CheckCircleRoundedContainer>
);
// Min width to the icon or else the icons would shift size depending on the
// size of the right content on smaller screens
const StyledPlusCircle = styled(PlusCircle)<Props>`
  /* width property added due to a Firefox inconsistency */
  width: 1.625rem;
  max-width: 1.625rem;
  min-width: 1.625rem;
  color: ${(props: Props) => props.theme?.colors.primaryGray};
  margin: 0;
`;

const StyledXCircleFill = styled(XCircle)<Props>`
  /* width property added due to a Firefox inconsistency */
  width: 1.625rem;
  max-width: 1.625rem;
  min-width: 1.625rem;
  color: ${(props: Props) => props.theme?.colors.primaryAlbertBrand};
  margin: 0;
`;

const StyledCheckCircle = styled(CheckCircle)<Props>`
  /* width property added due to a Firefox inconsistency */
  width: 1.625rem;
  max-width: 1.625rem;
  min-width: 1.625rem;
  color: ${({ theme, type }) =>
    type === 'genius'
      ? theme?.colors.geniusText
      : theme?.colors.primaryAlbertBrand};
`;

const StyledLottieWrapper = styled.div<Props>`
  padding-top: ${mixins.pxToRem('10px')};
  padding-bottom: ${mixins.pxToRem('27px')};
`;
const StyledErrorCircle = styled(ErrorCircle)<Props>`
  color: #ff504d;
  width: ${mixins.pxToRem('14px')};
  height: ${mixins.pxToRem('14px')};
  margin-left: 5px;
  margin-bottom: 2px;
  vertical-align: middle;
`;

const getIconSmall = ({ selected, choice, type, color }: Props) =>
  selected ? (
    <StyledCheckCircleRounded color={color} choice={choice} type={type} />
  ) : null;

const getIconLarge = ({ selected, choice }: Props, isHovering: boolean) => {
  if (selected && isHovering) return <StyledXCircleFill />;
  if (selected && !isHovering) return <StyledCheckCircle choice={choice} />;
  return <StyledPlusCircle />;
};

const getIcon = (
  props: Props,
  isHovering = false
): React.ReactElement<Props> | null => {
  return props.choice === 'multiple'
    ? getIconLarge(props, isHovering && !isMobile)
    : getIconSmall(props);
};

export default (props: Props): React.ReactElement<Props> => {
  const [isHovering, setIsHovering] = React.useState<boolean>(false);

  const {
    type,
    selected,
    altImage,
    image,
    label: propLabel,
    choice,
    lottie,
    theme,
    onClick,
    value,
    size,
    header,
    isError,
    spaceBetween,
    body,
    noImageSizing,
    tabIndex,
  } = props;

  const handleOnClick = () => {
    onClick && onClick(value);
  };

  const imageSource: string = ArtMap(
    (type === 'genius' && !selected ? altImage : image) || ''
  );
  const labelColor = selected ? 'gold' : 'gray';
  const label = propLabel ? (
    <Label color={labelColor} size='small'>
      {propLabel}
    </Label>
  ) : null;
  return (
    <StyledCard
      {...props}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      onClick={handleOnClick}
      onKeyDown={(e) => handleActivateKeyDown(e, handleOnClick)}
      tabIndex={tabIndex || 0}
      role={Roles.BUTTON}
      aria-label='Button'
    >
      {getIcon(props, isHovering)}
      {image && (
        <ImageContainer size={size} choice={choice}>
          <StyledImage
            src={imageSource || (image as string)}
            size={size}
            noImageSizing={noImageSizing}
          />
        </ImageContainer>
      )}
      {lottie && <StyledLottieWrapper>{lottie}</StyledLottieWrapper>}
      <StyledCardContent {...props}>
        <StyledCardHeader type={type} theme={theme}>
          {header}
          {label}
          {isError && <StyledErrorCircle />}
        </StyledCardHeader>
        <StyledCardBody
          className='rectangle-card-body'
          spaceBetween={spaceBetween}
          size={size}
        >
          {body}
        </StyledCardBody>
      </StyledCardContent>
    </StyledCard>
  );
};
