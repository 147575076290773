import * as React from 'react';
import styled, { css } from 'styled-components';
import Text from 'components/common/Text';
import { breakpoints, spacers } from 'styles';
import { spacers as homeSpacers } from 'styles/home';
import { hash32 } from 'utils/hash';

type ButtonSpace = 'tiny' | 'small' | 'default';

type Props = {
  maxWidth?: string;
  children?: React.ReactNode;
  /** @deprecated Please instead use or create generic layout components in src/components/layout */
  mobileHeader?: string;
  /** @deprecated Please instead use or create generic layout components in src/components/layout */
  mobileLead?: string[];
  isGenius?: boolean;
  buttonSpace?: ButtonSpace;
};

const getMobileButtonSpace = (space: ButtonSpace) => {
  if (space === 'tiny') return homeSpacers.g4;
  if (space === 'small') return homeSpacers.g6;
  return homeSpacers.g9;
};
/**
 * Set of class styles to enforce layout consistency
 * @deprecated - Please instead use or create generic layout components in src/components/layout
 */
const componentLayoutStyles = css<{
  buttonSpace: ButtonSpace;
  isGenius: boolean;
}>`
  .buttons-container {
    margin-top: ${({ isGenius }) =>
      isGenius ? homeSpacers.g9 : homeSpacers.g11};
    @media ${breakpoints.mobileLarge} {
      flex-direction: column-reverse;
      button {
        width: 100%;
      }
    }
  }
  .common-list {
    margin-top: ${homeSpacers.g11};
  }
  .info-pane-list {
    margin-top: 0;
  }
  @media ${breakpoints.mobileLarge} {
    .description {
      margin-top: ${homeSpacers.g3};
    }
    .input-wrapper {
      margin-top: ${homeSpacers.g4};
    }
    .secondary-button {
      margin-top: ${homeSpacers.g6};
    }
    .label-header {
      margin-top: ${homeSpacers.g11};
    }
    .buttons-container {
      margin-top: ${({ buttonSpace }) => getMobileButtonSpace(buttonSpace)};
    }
    .divider {
      margin: ${homeSpacers.g11} 0 0 0;

      &.first {
        margin: ${homeSpacers.g7} 0 0 0;
      }
    }
  }
`;

const Container = styled.div<{
  maxWidth?: string;
  buttonSpace: ButtonSpace;
  isGenius: boolean;
}>`
  position: relative;
  width: 100%;
  max-width: ${({ maxWidth }) => maxWidth || '500px'};
  padding-top: ${spacers.tab};

  @media ${breakpoints.mobileLarge} {
    width: 100%;
    max-width: 100%;
    position: static;
    padding-top: 0;
  }
  ${componentLayoutStyles}
`;

const MobileHeader = styled.h1`
  display: none;
  @media ${breakpoints.mobileLarge} {
    display: block;
    margin: ${homeSpacers.g2} 0 0 0;
  }
`;

const MobileLeadWrapper = styled.div`
  display: none;
  @media ${breakpoints.mobileLarge} {
    display: block;
  }
`;

const PageBase = ({
  children,
  maxWidth,
  mobileHeader,
  mobileLead = [],
  buttonSpace = 'default',
  isGenius = false,
}: Props) => {
  React.useEffect(() => {
    // Scroll to top of page on mount
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container
      maxWidth={maxWidth}
      buttonSpace={buttonSpace}
      isGenius={isGenius}
    >
      <>
        {mobileHeader && <MobileHeader>{mobileHeader}</MobileHeader>}
        <MobileLeadWrapper>
          {mobileLead.map((txt: string) => (
            <div
              key={`info-text-fragment-${hash32(txt)}`}
              className='description'
            >
              <Text key={`info-text-${hash32(txt)}`}>{txt}</Text>
            </div>
          ))}
        </MobileLeadWrapper>
        {children}
      </>
    </Container>
  );
};

export default PageBase;
