/* eslint-disable no-useless-escape */
/* eslint-disable @typescript-eslint/no-explicit-any */
const renderTemplateContext = (
  text = '',
  context: { [key: string]: any }
): string => {
  if (!text) return '';

  // regex to match anything from lanuage that has [[]] ex. [[jumpstartAmount]]
  const regex = /\[\[(.*?)\]\]/gm;
  const matches = text.match(regex);
  let templatedText = text;
  matches?.forEach((matchedString: string) => {
    // find context variable and strip the brackets to get key in context
    const contextVariable = matchedString.replace(/[\[\]']+/g, '');

    // replace context variable with appropriate context
    templatedText = templatedText.replace(
      matchedString,
      context[contextVariable]
    );
  });
  return templatedText;
};

export default renderTemplateContext;
