import * as React from 'react';
import * as WEB from 'types/interfaces';

/**
 * Fire a generic browser alert if the user attempts to reload or close the window.
 * Unfortunately it is not possible to customize this message, and the alert might not fire in some cases.
 * Most browsers will only fire the alert if the user has first interacted with the page in some way.
 * https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload
 */
const useBrowserCloseWarning = (): void => {
  React.useEffect(() => {
    const onBeforeUnload = (e: WEB.WindowEventHandler): string => {
      // If you prevent default behavior in Mozilla Firefox prompt will always be shown
      e.preventDefault();
      // Chrome requires returnValue to be set
      e.returnValue = '';
      // Safari requires a return value
      return '';
    };
    window.addEventListener('beforeunload', onBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, []);
};

export default useBrowserCloseWarning;
