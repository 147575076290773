import AlbertTrackingEvent from './AlbertTrackingEvent';

const BranchEvent = {
  [AlbertTrackingEvent.SIGNED_UP]: 'COMPLETE_REGISTRATION',
  [AlbertTrackingEvent.ADDED_ACCOUNT]: 'UNLOCK_ACHIEVEMENT',
  [AlbertTrackingEvent.ENABLED_SAVINGS]: 'ACHIEVE_LEVEL',
  [AlbertTrackingEvent.ENABLED_GENIUS]: 'PURCHASE',
  [AlbertTrackingEvent.ENABLED_GENIUS_ANNUAL]: 'PURCHASE',
  [AlbertTrackingEvent.ENABLED_GENIUS_PRIMARY_CHECKING_GREATER_THAN_100]:
    'Enabled Genius - bank > $100',
  [AlbertTrackingEvent.COMPLETED_INSTANT_ONBOARDING]:
    'Completed Instant Onboarding',
  [AlbertTrackingEvent.COMPLETED_INVESTMENT_APPLICATION]:
    'Completed Investing Onboarding',

  // Albert Cash.
  [AlbertTrackingEvent.OPENED_CASH_BANK_ACCOUNT]: 'Opened Bank Account',
};

export default BranchEvent;
