import AccountProfileConstants from 'constants/AccountProfile';

/**
 *
 * @param value The value to format as a phone number.
 * @returns The formatted value as a phone number.
 * Example: 1234567890 becomes (123) 456-7890.
 */
export const formatPhone = (value: string): string => {
  let tmpValue = value;
  const numbers = tmpValue.replace(/\D/g, '');
  const char = { 0: '(', 3: ') ', 6: '-' };
  tmpValue = '';
  for (let i = 0; i < numbers.length; i += 1) {
    tmpValue += (char[i] || '') + numbers[i];
  }
  /* 
    Since the parentheses, dash and the additional space are also
    characters, the string starts at 0 and ends index 14. 
  */
  return tmpValue.substring(0, 14);
};

/**
 *
 * @param value The value to format as a social security #.
 * @returns The formatted value as a social security #.
 * Example: 123456789 becomes 123-45-6789.
 */
export const formatSSN = (value: string): string => {
  let tmpValue = value;
  const numbers = tmpValue.replace(/\D/g, '');
  const char = { 3: '-', 5: '-' };
  tmpValue = '';
  for (let i = 0; i < numbers.length; i += 1) {
    tmpValue += (char[i] || '') + numbers[i];
  }
  /* 
    Since the dashes are also characters, the string starts at 0 
    and ends index 10. 
  */
  return tmpValue.substring(0, 11);
};

export const formatGenderKey = (gender: string): string => {
  switch (gender) {
    case AccountProfileConstants.Gender.MALE:
      return 'Male';
    case AccountProfileConstants.Gender.FEMALE:
      return 'Female';
    case AccountProfileConstants.Gender.OTHER:
      return 'Other';
    default:
      return '';
  }
};

export const formatDate = (value: string): string => {
  let tmpValue = value;
  const numbers = tmpValue.replace(/\D/g, '');
  const char = { 1: '/', 3: '/' };
  tmpValue = '';

  for (let i = 0; i < numbers.length; i += 1) {
    tmpValue += numbers[i] + (char[i] || '');
  }
  return tmpValue.substring(0, 10);
};
