import types from 'actions/types';
import AlbertClient from 'api/AlbertClient';
import * as WEB from 'types/interfaces';
import { onErrorDisplayDefaultBanner } from 'utils/axiosMiddlewareUtils';

export default (names: string[]): WEB.ActionCreator<Promise<boolean>> =>
  async (dispatch: WEB.Dispatch): Promise<boolean> => {
    let error = false;

    await dispatch({
      types: [
        types.UPDATE_INVESTING_PREFERENCES_REQUEST,
        types.UPDATE_INVESTING_PREFERENCES_SUCCESS,
        types.UPDATE_INVESTING_PREFERENCES_FAILURE,
      ],
      payload: {
        request: {
          method: 'put',
          url: AlbertClient.investingPreferences(),
          data: {
            names,
          },
        },
        options: {
          onError: (middlewareOptions: any, options: any) => {
            error = true;
            return onErrorDisplayDefaultBanner(middlewareOptions, options);
          },
        },
      },
    });

    return error;
  };
