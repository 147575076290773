enum SplashLink {
  // Albert
  HOME = 'https://albert.com/',
  TERMS = 'https://albert.com/terms/',
  TERMS_SMS = 'https://albert.com/terms/sms/?hide_nav=true&hide_banner=true',
  TERMS_AIS = 'https://albert.com/terms/?hide_nav=true#ais',
  PLAID = 'https://albert.com/terms/plaid/',
  NOT_FOUND = 'https://albert.com/404/',
  PRIVACY = 'https://albert.com/privacy/',
  E_SIGN_CONSENT = 'https://albert.com/terms/?hide_nav=true&hide_banner=true#econsent',
  CARDHOLDER_AGREEMENT = 'https://albert.com/terms/banking/cardholder-agreement-dda/',
  PASSWORD_RESET = 'https://albert.com/password/reset/',
  DISCLOSURE_LIBRARY = 'https://albert.com/terms/disclosure-library/',
  INVESTING_TERMS = 'https://albert.com/terms/investing',
  CASH_FEES = 'https://albert.com/terms/banking/fees',
  CUSTOMER_ACCOUNT_AGREEMENT = 'https://albert.com/terms/investing/customer-account-agreement',
  INVESTING_ACCOUNT_AGREEMENTS = 'https://albert.com/terms/investing/account-agreements',

  // Coastal Bank
  COASTAL_PRIVACY = 'https://www.coastalbank.com/privacy-notice.html',

  // Atomic
  ATOMIC_PRIVACY = 'https://atomic.financial/privacy-policy/',
}

export default SplashLink;
