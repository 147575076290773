import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import createBankingAccount from 'actions/async/createBankingAccount';
import getKYCLanguage from 'actions/async/getKYCLanguage';
import submitBankingApplication from 'actions/async/submitBankingApplication';
import BannerPane from 'components/BannerPane';
import KYCInfoPane from 'components/kyc/KYCInfoPane';
import ContentPane, { ContentContainer } from 'components/layout/ContentPane';
import TwoPaneWrapper from 'components/layout/TwoPaneWrapper';
import { Pages, ProductType } from 'constants/index';
import useNavigateFunction from 'hooks/useNavigateFunction';
import * as KYCPages from 'pages/kyc';
import Navbar from 'pages/unauthenticated/Navbar';
import * as appData from 'reducers/entities/appData';
import * as language from 'reducers/entities/language';
import * as signup from 'reducers/ui/signup';
import * as WEB from 'types/interfaces';
import { getIsCheckpointDisabled } from 'utils/checkpointNavUtils';

type Props = {
  /* Albert product to KYC */
  product: ProductType;
  /* page to render after finishing flow */
  nextPage: string;
};

type PageRoute = {
  page: string;
  route: string;
  pageOnComplete: string;
  exitKYCFlow: (showPersonaVerification: boolean) => Promise<boolean>;
};

export const KYCPageList = [
  Pages.KYC_ADDRESS,
  Pages.KYC_CONFIRM,
  Pages.KYC_CONFIRM_EMAIL,
  Pages.KYC_CONFIRM_NUMBER,
  Pages.KYC_SSN,
  Pages.KYC_MFA,
  Pages.KYC_VERIFY_IDENTITY_WEB,
] as string[];

const KYCFlow = (props: Props): React.ReactElement | null => {
  const { product } = props;

  // TODO: Remove `fromSignup` from all KYC pages and strict compare with `product` instead.
  const fromSignup = product === ProductType.SIGNUP;

  // //////////////////////////////
  /* ========== Hooks ========== */
  // //////////////////////////////
  const dispatch = useDispatch<WEB.Dispatch>();
  const { updateCurrentSignupPage } = useNavigateFunction();

  // //////////////////////////////
  /* ======= Mapped state ====== */
  // //////////////////////////////
  const property: WEB.Property | null = useSelector(
    (state: WEB.RootState) =>
      appData.getLegalProperty(state) || appData.getPrimaryProperty(state)
  );
  const currentSignupPage = useSelector(signup.getCurrentPage);
  const shouldFetchKYCLanguage = useSelector(language.shouldFetchKYCLanguage);
  const kycLanguage = useSelector(language.getKYCLanguage);

  const bankAccount = useSelector((state: WEB.RootState) =>
    appData.getBankingAccount(state)
  );

  // //////////////////////////////
  /* ======== Local state ====== */
  // //////////////////////////////
  // Determine whether or not to skip KYCAddress on component mount.
  // NOTE: Without this, we try to perform a state update when the component is unmounted.
  const [skipKYCAddress] = React.useState(
    !!property && !getIsCheckpointDisabled()
  );

  // //////////////////////////////
  /* ======== Effect ====== */
  // //////////////////////////////
  React.useEffect(() => {
    // Load genius language
    if (shouldFetchKYCLanguage) {
      dispatch(getKYCLanguage());
    }
  }, [shouldFetchKYCLanguage]);

  // Separate useEffect for updating signup flow page.
  // We do not want to fetch KYC language more than once by placing all in one useEffect.
  React.useEffect(() => {
    // Set current signup page to 'KYC' if not yet set.
    // NOTE: This appropriately updates the signup progress bar.
    if (fromSignup && currentSignupPage !== Pages.KYC) {
      dispatch(
        updateCurrentSignupPage({
          page: Pages.KYC,
          shouldNavTo: false,
        })
      );
    }
  }, []);

  /**
   * Perform any requests before exiting the KYC flow.
   *
   * showPersonaVerification (boolean) - if the KYC flow will end with displaying
   *  KYCVerifyIdentity.
   */
  const exitKYCFlow = React.useCallback(
    async (showPersonaVerification: boolean) => {
      let error = true;

      if (showPersonaVerification) {
        // If showing KYCVerifyIdentity next, don't create a bank account.
        error = false;
      }
      // Check if a banking account exists. If not, create it.
      else if (bankAccount?.id) {
        error = false;
      } else {
        // Create and submit the banking account.
        const { response, error: createError }: any = await dispatch(
          createBankingAccount()
        );

        if (!createError && response?.payload?.data?.id) {
          const data = {
            express_mail: false,
            is_debit_card_style_required: false,
            shipping_address: {
              street: property?.street,
              apt: property?.apt,
              city: property?.city,
              state: property?.state,
              zipcode: property?.zipcode,
            },
          };

          const { error: submitError }: any = await dispatch(
            submitBankingApplication({
              bankingAccountId: response?.payload?.data?.id,
              data,
            })
          );

          if (!submitError) error = false;
        }
      }

      return error;
    },
    [bankAccount, property, dispatch]
  );

  // //////////////////////////////
  /* ========== Render ========= */
  // //////////////////////////////
  const pageRoutes: PageRoute[] = KYCPageList.map((page: string) => {
    const pageContext = kycLanguage[page];
    return {
      page,
      exitKYCFlow: exitKYCFlow,
      pageOnComplete: props.nextPage,
      route: pageContext?.route,
    };
  });

  // If user already has address, omit KYCAddress page from flow.
  const filteredRoutes = !skipKYCAddress
    ? pageRoutes
    : pageRoutes.filter((item: PageRoute) => item.page !== Pages.KYC_ADDRESS);

  const routes = filteredRoutes.map(
    ({ page, route, pageOnComplete, exitKYCFlow }: PageRoute) => {
      // Page component to render
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const PageComponent = KYCPages[page];

      return (
        <Route
          path={route}
          key={`route-${route}`}
          element={
            <PageComponent
              skipAddress={skipKYCAddress}
              product={product}
              fromSignup={fromSignup}
              pageOnComplete={pageOnComplete}
              exitKYCFlow={exitKYCFlow}
            />
          }
        />
      );
    }
  );

  // Redirect to first page of subflow if path missing page route slug.
  const redirectRoute = (
    <Route
      path='*'
      element={<Navigate to={filteredRoutes[0].route} replace />}
    />
  );

  return (
    <>
      <Navbar split signupFlow={!!fromSignup} />
      <TwoPaneWrapper>
        <KYCInfoPane />
        <ContentPane>
          <BannerPane />
          <ContentContainer>
            <Routes>
              {routes}
              {!shouldFetchKYCLanguage && redirectRoute}
            </Routes>
          </ContentContainer>
        </ContentPane>
      </TwoPaneWrapper>
    </>
  );
};

export default KYCFlow;
