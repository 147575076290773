import * as React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Button from 'components/common/Button';
import Link from 'components/common/Link';
import Spacer from 'components/common/Spacer';
import Text from 'components/common/Text';
import ButtonsLayout from 'components/layout/ButtonsLayout';
import LeadContent from 'components/layout/LeadContent';
import PageBase from 'components/layout/PageBase';
import ArtMap from 'constants/ArtMap';
import { HIDE_NAV_AND_BANNER } from 'constants/PageDisplayOptions';
import Pages from 'constants/Pages';
import SplashLink from 'constants/SplashLink';
import * as language from 'reducers/entities/language';
import { breakpoints } from 'styles';
import { spacers } from 'styles/home';
import * as WEB from 'types/interfaces';
import { getLanguageButtons, getLanguageSection } from 'utils/getFromLanguage';
import { getRemainingPageHeight } from 'utils/layout';

type Props = {
  onContinue: () => void;
  isCompleted: boolean;
  isSubmitting: boolean;
};

const Container = styled.div`
  @media ${breakpoints.mobileLarge} {
    display: flex;
    flex-direction: column;
    // 35px comes from ContentPane padding
    min-height: calc(${getRemainingPageHeight()} - 35px);
    align-items: flex-start;
  }
`;
const BottomContainer = styled.div`
  @media ${breakpoints.mobileLarge} {
    margin-top: auto;
    padding-top: 24px;
  }
`;

const GeniusSuccess = ({
  onContinue,
  isCompleted,
  isSubmitting,
}: Props): React.ReactElement => {
  const languageCtx = useSelector((state: WEB.RootState) => {
    return language.getGeniusPageLanguage(state, Pages.GENIUS_SUCCESS);
  });

  const successSection = getLanguageSection(languageCtx, 'intro');
  const leadImage = getLanguageSection(languageCtx, 'leadImage');
  const buttonsCtx = getLanguageButtons(languageCtx);

  return (
    <PageBase>
      <Container>
        <img height={90} src={ArtMap(leadImage.image?.filename || '')} alt='' />
        <Spacer space={spacers.g8} />
        <LeadContent
          header={successSection?.header || ''}
          text={successSection?.text}
        />
        <Spacer space={spacers.g8} desktopOnly />
        <BottomContainer>
          <Text size='small' color='gray' weight='400'>
            By continuing, I agree to let Albert obtain my credit data from
            consumer reporting agencies based on the{' '}
            <Link
              href={`${SplashLink.TERMS}${HIDE_NAV_AND_BANNER}`}
              target='_blank'
              underline
            >
              Terms of use
            </Link>{' '}
            and{' '}
            <Link
              href={`${SplashLink.PRIVACY}${HIDE_NAV_AND_BANNER}`}
              target='_blank'
              underline
            >
              Privacy policy
            </Link>
            .
          </Text>
          <Spacer space={spacers.g8} desktopOnly />
          <ButtonsLayout
            marginTop={spacers.g4}
            primaryButton={
              <Button
                onClick={onContinue}
                isCompleted={isCompleted}
                isLoading={isSubmitting}
              >
                {isCompleted ? '' : buttonsCtx?.primary}
              </Button>
            }
          />
        </BottomContainer>
      </Container>
    </PageBase>
  );
};
export default GeniusSuccess;
