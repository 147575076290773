import styled from 'styled-components';
import { breakpoints, mixins } from 'styles';

const BorderContainer = styled.div<{
  desktopPadding?: string;
  mobilePadding?: string;
  showMobileBorder?: boolean;
  borderThickness?: string;
  borderRadius?: string;
}>`
  ${({ borderThickness, theme }) =>
    borderThickness
      ? `border: ${borderThickness} solid ${theme.colors.lightGray1};`
      : `border: 1.5px solid ${theme.colors.lightGray1};`}
  ${({ borderRadius }) =>
    borderRadius ? `border-radius: ${borderRadius};` : `border-radius: 4px;`}
  background: ${({ theme }) => theme.colors.primaryBackground};
  ${({ desktopPadding }) =>
    desktopPadding
      ? `padding: ${desktopPadding};`
      : `padding: ${mixins.pxToRem('80px')};`}

  @media ${breakpoints.mobileLarge} {
    ${({ mobilePadding }) =>
      mobilePadding
        ? `padding: ${mobilePadding};`
        : `padding: ${mixins.pxToRem('40px')};`}
    ${({ showMobileBorder }) =>
      showMobileBorder ? null : 'border: none; padding: 0;'}
  }
`;

export default BorderContainer;
