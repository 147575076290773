import * as React from 'react';

export function BankAccountIconV2() {
  return (
    <svg
      width='53'
      height='52'
      viewBox='0 0 53 52'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2.47168 12C2.47168 10.8954 3.36711 10 4.47168 10H48.4717C49.5763 10 50.4717 10.8954 50.4717 12V40C50.4717 41.1046 49.5763 42 48.4717 42H4.47168C3.36711 42 2.47168 41.1046 2.47168 40V12Z'
        fill='#B0D839'
      />
      <path d='M2.47168 17H50.4717V24H2.47168V17Z' fill='#D4F378' />
      <path
        d='M9.47168 30.5L18.8851 30.5'
        stroke='#075D44'
        strokeWidth='2'
        strokeLinecap='round'
      />
      <path
        d='M22.4131 30.5L29.4732 30.5'
        stroke='#075D44'
        strokeWidth='2'
        strokeLinecap='round'
      />
      <path
        d='M9.47168 35L25.4717 35'
        stroke='#075D44'
        strokeWidth='2'
        strokeLinecap='round'
      />
    </svg>
  );
}
