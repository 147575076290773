import * as React from 'react';
import styled from 'styled-components';
import ErrorText from 'components/common/ErrorText';
import { fontSizes, mixins } from 'styles';
import { BaseInputProps } from 'types/interfaces';
import { floatNumToString, floatStringToNum } from 'utils/textFormatters';
import { BaseInput, InputWrapper, renderLabels } from './shared';

export type Props = BaseInputProps & {
  /* mininum number */
  min?: string;
  /* maximum number */
  max?: string;
  /* number of decimal places */
  numDecimalPlaces?: number;
  /* convert undefined, null, empty value to zero */
  defaultNoValueToZero?: boolean;
};

type InternalProps = Props & {
  value: string;
};

const StyledCurrencyInput = styled(BaseInput)<InternalProps>`
  padding: 15px 15px 15px 55px;

  /* Hide default spinners for number input */
  -moz-appearance: textfield;
  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const StyledCurrencyContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.primaryText};
  ${fontSizes.fontSize14}
  font-weight: 600;
  position: absolute;
  top: ${mixins.pxToRem('5px')};
  bottom: ${mixins.pxToRem('4px')};
  padding-left: 16px;
  padding-right: 15px;
  border-right: 1.5px solid #e6e7eb;
`;

const StyledCurrencyWrapper = styled.div`
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CurrencyInput = (props: Props): React.ReactElement => {
  const {
    id,
    invalid,
    errorText,
    theme,
    min = '0',
    max = '999999999999999',
    valueDefault,
    numDecimalPlaces = 0,
    onChange,
    widthSize,
    defaultNoValueToZero = true,
  } = props;
  const initialValue = defaultNoValueToZero
    ? floatStringToNum(String(valueDefault || min))
    : valueDefault;
  const initialFormattedValue =
    initialValue === null ||
    initialValue === undefined ||
    typeof initialValue !== 'number'
      ? ''
      : floatNumToString(initialValue, numDecimalPlaces);

  // Includes commas and a period for currency formatting
  const [currencyValue, setCurrencyValue] = React.useState<string>(
    initialFormattedValue
  );
  const didMountRef = React.useRef(false);

  const minRange = min ? Number.parseFloat(min) : -Infinity;
  const maxRange = max ? Number.parseFloat(max) : Infinity;

  const isBelowRange = (value: number) => value < minRange;
  const isAboveRange = (value: number) => value > maxRange;
  const isWithinRange = (value: number) => {
    return value >= minRange && value <= maxRange;
  };

  const setValue = (newValue: number | null) => {
    if (newValue === null && !defaultNoValueToZero) {
      setCurrencyValue('');
      return;
    }
    const formattedValue = floatNumToString(newValue || 0, numDecimalPlaces);
    setCurrencyValue(formattedValue);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (value === '' && !defaultNoValueToZero) {
      setValue(null);
      return;
    }

    let floatValue = floatStringToNum(value);

    // Don't allow non-float values in the input
    if (floatValue !== null) {
      // If using decimal places, shift the decimal point
      // depending on if the input change added or deleted from the input.
      // e.g. If numDecimalPlaces = 2 and 6 was just typed,
      //      convert 123.456 to 1234.56 to maintain the decimal places
      if (numDecimalPlaces) {
        if (value.length > currencyValue.length) {
          floatValue *= 10;
        } else {
          floatValue /= 10;
        }
      }

      setValue(floatValue);
    }
  };

  const setInputRange = (value: number) => {
    if (isBelowRange(value)) setValue(minRange);
    if (isAboveRange(value)) setValue(maxRange);
  };

  /**
   * On input blur, force the input value to be within
   * the min/max bounds.
   */
  const handleValidateInput = () => {
    const floatValue = floatStringToNum(currencyValue);
    if (floatValue !== null) {
      const isInvalid = !isWithinRange(floatValue);
      if (isInvalid) setInputRange(floatValue);
    }
  };

  React.useEffect(() => {
    // Guard against running onChange when
    // the component mounts
    if (!didMountRef.current) {
      didMountRef.current = true;
    } else {
      const floatValue = floatStringToNum(currencyValue);
      onChange && onChange(floatValue);
    }
  }, [currencyValue]);

  return (
    <div>
      {renderLabels(props)}
      <InputWrapper widthSize={widthSize} className='input-wrapper'>
        <StyledCurrencyInput
          {...props}
          onChange={handleChange}
          onBlur={handleValidateInput}
          value={currencyValue}
          inputMode='numeric'
        />
        <StyledCurrencyContent theme={theme}>
          <StyledCurrencyWrapper>
            <span>$</span>
          </StyledCurrencyWrapper>
        </StyledCurrencyContent>
      </InputWrapper>
      {invalid && errorText && <ErrorText name={id} text={errorText} />}
    </div>
  );
};

export default CurrencyInput;
