import trackAnalytics, {
  TrackInfoParams,
  TrackParams,
} from 'actions/async/trackAnalytics';
import TrackingEventType from 'constants/TrackingEventType';
import { Dispatch } from 'types/interfaces';
import { store } from './index';

/**
 * Albert tracking client
 * Implements methods that track to Albert's data sources
 */
const AlbertAnalytics = {
  /**
   * Identify a user (ie. when a user uses the app)
   */
  identify() {
    const params: TrackParams = {
      event_type: TrackingEventType.IDENTIFY,
      timestamp: Date.now(),
    };

    // Make async request
    const dispatch = store.dispatch as Dispatch;
    dispatch(trackAnalytics(params));
  },

  /**
   * Track an event
   *
   * @param eventName (string) - event name
   * @param subEvent (string) - sub event
   * @param info (TrackInfoParams) - info object
   */
  track(eventName: string, subEvent: string, info?: TrackInfoParams) {
    const params: TrackParams = {
      event_type: TrackingEventType.CLICK,
      timestamp: Date.now(),
      event_name: eventName,
    };

    if (subEvent) {
      params.event_subname = subEvent;
    }

    Object.entries(info || {}).forEach(
      ([key, value]: [string, string | number]) => {
        params[`info_${key}`] = value;
      }
    );

    // Make async request
    const dispatch = store.dispatch as Dispatch;
    dispatch(trackAnalytics(params));
  },
};

export default AlbertAnalytics;
