import * as React from 'react';
import * as ReactDOM from 'react-dom';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import Spacer from 'components/common/Spacer';
import ExternalLinkIcon from 'components/icons/ExternalLinkIcon';
import { spacers as homeSpacers } from 'styles/home';
import { LanguageExpander } from 'types/interfaces';
import { hash32 } from 'utils/hash';
import Modal from './index';

type Props = {
  expanders?: LanguageExpander[];
  showModal: boolean;
  setHideModal: (showModal: boolean) => void;
};

const StyledHeader = styled(ReactMarkdown)`
  p {
    margin: 0;
  }
  font-weight: 700;
`;

const StyledText = styled(ReactMarkdown)`
  p {
    margin: 0;
  }
`;

const StyledExternalLinkIcon = styled(ExternalLinkIcon)`
  margin-bottom: 3px;
`;

const MarkdownLink = ({ children, href, ...props }: any) => {
  const cleanedHref = href.replace('&amp;', '&');
  const hasExternalLinkIcon = children.includes('[externalLinkIcon]');
  const text = children.replace('[externalLinkIcon]', '');
  return (
    <a href={cleanedHref} {...props} target='_blank' rel='noopener noreferrer'>
      {text}
      {hasExternalLinkIcon && <StyledExternalLinkIcon />}
    </a>
  );
};

/**
 * Render expander content for modal use.
 * Expanders show the expander header and a list of items. Each item might have
 * its own header. In the modal, we only show the item header if it exists. If
 * not, then we fall back to the expander header for the group of items.
 */
const ModalExpanderContent = ({
  expanders,
  showModal,
  setHideModal,
}: Props): React.ReactElement | null => {
  const rootNode = document.getElementById('root');
  if (!rootNode || !expanders) {
    return null;
  }

  return ReactDOM.createPortal(
    <Modal.Basic title='' show={showModal} onCancel={() => setHideModal(false)}>
      {expanders.map((expander, i) => (
        <React.Fragment key={`expander-${hash32(expander.header)}`}>
          {(expander.content.items || []).map((item, j) => {
            let header = null;
            if (item.header) {
              header = item.header;
            } else if (j === 0) {
              header = expander.header;
            }
            return (
              <React.Fragment key={`expander-item-${hash32(item.text)}`}>
                {header && (
                  <>
                    <StyledHeader>{expander.header}</StyledHeader>
                    <Spacer space={homeSpacers.g3} />
                  </>
                )}
                <StyledText components={{ a: MarkdownLink }}>
                  {item.text}
                </StyledText>
                {j < (expander.content.items || []).length - 1 && (
                  <Spacer space={homeSpacers.g3} />
                )}
              </React.Fragment>
            );
          })}
          {i < (expanders || []).length - 1 && (
            <Spacer space={homeSpacers.g6} />
          )}
        </React.Fragment>
      ))}
    </Modal.Basic>,
    rootNode
  );
};

export default React.memo(ModalExpanderContent);
