import { ExternalLink } from '@styled-icons/heroicons-solid/ExternalLink';
import styled from 'styled-components';
import { mixins } from 'styles';

type Props = {
  dark?: boolean;
};

const StyledExternalLink = styled(ExternalLink)<Props>`
  width: ${mixins.pxToRem('13px')};
  vertical-align: sub;
  cursor: pointer;
  ${({ theme, dark }) => dark && `color: ${theme.colors.darkGray1}`};

  &:hover {
    color: ${({ theme }) => theme.colors.primaryText};
  }
`;

export default StyledExternalLink;
