import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import updateProfile from 'actions/async/updateProfile';
import addBanner from 'actions/banner/addBanner';
import Button from 'components/common/Button';
import ButtonsLayout from 'components/layout/ButtonsLayout';
import LeadContent from 'components/layout/LeadContent';
import PageBase from 'components/layout/PageBase';
import Pages from 'constants/Pages';
import { ErrorMessages, NotificationTypes } from 'constants/index';
import useBrowserNavigationOverride from 'hooks/useBrowserNavigationOverride';
import useNavigateFunction from 'hooks/useNavigateFunction';
import useVerifyCurrentPage from 'hooks/useVerifyCurrentPage';
import AddTrustedContactInfo from 'pages/signup/AddTrustedContactInfo';
import * as language from 'reducers/entities/language';
import { spacers } from 'styles/home';
import * as WEB from 'types/interfaces';
import { delayWithState } from 'utils/delay';
import { getLanguageButtons, getLanguageSection } from 'utils/getFromLanguage';

type Props = {
  nextPage: string;
};

enum ViewState {
  INTRO = 'intro',
  INFO = 'info',
}

const AddTrustedContact = (props: Props) => {
  const { updateCurrentSignupPage } = useNavigateFunction();
  const dispatch = useDispatch();
  // Make sure Redux is up-to-date with currentSignUpPage
  useVerifyCurrentPage(Pages.ADD_TRUSTED_CONTACT);
  const [choice, setChoice] = React.useState<'yes' | 'no' | null>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isCompleted, setIsCompleted] = React.useState<boolean>(false);
  const [currentView, setCurrentView] = React.useState<ViewState>(
    ViewState.INTRO
  );

  const languageCtx = useSelector((state: WEB.RootState) =>
    language.getSignupPageLanguage(state, Pages.ADD_TRUSTED_CONTACT)
  );

  const introCtx = getLanguageSection(languageCtx, 'intro');
  const buttonsCtx = getLanguageButtons(languageCtx);

  const handleGoBack = React.useCallback(() => {
    if (currentView === ViewState.INFO) {
      setCurrentView(ViewState.INTRO);
      setChoice(null);
      setIsCompleted(false);
      // Block browser backwards navigation
      return true;
    }
    // Allow browser backwards navigation
    return false;
  }, [currentView]);
  useBrowserNavigationOverride(handleGoBack, true);

  const onSubmit = async (showInfo: boolean) => {
    // Remove any error banner from previous attempts
    setChoice(showInfo ? 'yes' : 'no');
    setIsLoading(true);
    const { error }: any = await dispatch(
      updateProfile({
        investment_info: { trusted_contact: showInfo },
      })
    );
    if (error) {
      dispatch(
        addBanner(NotificationTypes.WARNING, ErrorMessages.web.generic, false)
      );
      setIsLoading(false);
      return;
    }
    setIsLoading(false);
    await delayWithState(400, setIsCompleted);
    if (!showInfo) {
      dispatch(
        updateCurrentSignupPage({
          page: props.nextPage,
        })
      );
    } else {
      setCurrentView(ViewState.INFO);
    }
  };

  return (
    <PageBase>
      {currentView === ViewState.INFO ? (
        <AddTrustedContactInfo nextPage={props.nextPage} />
      ) : (
        <>
          <LeadContent
            header={introCtx?.header || ''}
            text={introCtx?.text || ''}
            desktopHeaderSize='small'
          />
          <ButtonsLayout
            marginTop={spacers.g9}
            mobileDirection='row'
            flexGap='12px'
            primaryButton={
              <Button
                onClick={() => onSubmit(true)}
                isCompleted={choice === 'yes' && isCompleted}
                isLoading={choice === 'yes' && isLoading}
                disabled={choice === 'no'}
              >
                {buttonsCtx?.primary}
              </Button>
            }
            secondaryButton={
              <Button
                onClick={() => onSubmit(false)}
                isCompleted={choice === 'no' && isCompleted}
                isLoading={choice === 'no' && isLoading}
                disabled={choice === 'yes'}
                secondary
              >
                {buttonsCtx?.secondary}
              </Button>
            }
          />
        </>
      )}
    </PageBase>
  );
};

export default AddTrustedContact;
