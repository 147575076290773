enum AccountCategory {
  CHECKING = 'checking',
  SAVINGS = 'savings',
  CREDIT = 'credit',
  BROKERAGE = 'brokerage',
  RETIREMENT = 'retirement',
  PLAN529 = '529 plan',
  PRIVATE = 'private',
  STUDENT_LOANS = 'student loans',
  PERSONAL_LOANS = 'personal loans',
  MORTGAGES = 'mortgages',
  AUTO_LOANS = 'auto loans',
  YOU = 'you',
  YOUR_STUFF = 'your stuff',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  HOME = 'home',
  AUTO = 'auto',
}

export default AccountCategory;
