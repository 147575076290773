export default {
  // General
  g1: '4px',
  g2: '8px',
  g3: '12px',
  g4: '16px',
  g5: '20px',
  g6: '24px',
  g7: '28px',
  g8: '32px',
  g9: '36px',
  g10: '40px',
  g11: '48px',
  g12: '56px',
  g13: '60px',
  g14: '72px',
  g15: '80px',
  g16: '88px',
  g17: '96px',
  g18: '104px',

  // Modal
  m1: '4px',
  m2: '8px',
  m3: '12px',
  m4: '16px',
  m5: '20px',
  m6: '24px',
  m7: '28px',
  m8: '32px',
  m9: '36px',
  m10: '40px',
  m11: '48px',
  m12: '56px',

  // Button
  button: '48px',

  // Table
  tableRowHeight1: '20px',
  tableRowHeight2: '38px',
  tableRowHeight3: '40px',
  tableRowHeight4: '53px',
  tableRowHeight5: '70px',
  tableRowHeight6: '80px',
  tableRowHeight7: '95px',
};
