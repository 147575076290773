/* eslint-disable @typescript-eslint/no-unsafe-assignment */

/* eslint-disable no-param-reassign */
import produce from 'immer';
import initialState from 'store.initialState';
import types from 'actions/types';
import Pages from 'constants/Pages';
import * as WEB from 'types/interfaces';

// /////////////////////////////////////////////////////////////////////////////
// selectors
// /////////////////////////////////////////////////////////////////////////////
export const getCurrentPage = (state: WEB.RootState): Pages => {
  return (state.ui.kyc.currentPage as Pages) || '';
};

export const getMFAQuestions = (state: WEB.RootState): WEB.MFAQuestion[] => {
  return (state.ui.kyc.mfa as WEB.MFAQuestion[]) || [];
};

export const showPersonaVerification = (state: WEB.RootState): boolean =>
  state.ui.kyc.showPersonaVerification;

// /////////////////////////////////////////////////////////////////////////////
// reducers
// /////////////////////////////////////////////////////////////////////////////

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable consistent-return */
const kyc = (state = initialState.ui.kyc, action: WEB.Action) => {
  const { type, payload, error } = action;
  return produce(state, (draft: WEB.RootState['ui']['kyc']) => {
    switch (type) {
      case types.UPDATE_CURRENT_KYC_PAGE: {
        draft.currentPage = payload?.page || '';
        return;
      }

      case types.KYC_IDENTITY_CHECK_SUCCESS: {
        // Set MFA questions if response payload includes it.
        const mfaQuestions: WEB.MFAQuestion[] =
          payload?.data?.kyc_mfa_questions || [];
        if (mfaQuestions.length) {
          draft.mfa = mfaQuestions;
        }
        draft.showPersonaVerification =
          !!payload?.data?.show_persona_verification;
        return;
      }

      case types.SUBMIT_KYC_MFA_SUCCESS: {
        draft.showPersonaVerification =
          !!payload?.data?.show_persona_verification;
        return;
      }

      case types.LOGOUT_SUCCESS: {
        return initialState.ui.kyc;
      }
      default:
    }
  });
};

export default kyc;
