/**
 * Get the user-friendly server error message from
 * a request response that failed.
 *
 * @param response (Response object) - Axios response object to extract error message from
 * @param defaultMessage (string) - default message
 */

const getErrorMessageFromResponse = (
  response?: Record<string, any>
): string => {
  const data = response?.data || {};
  return data['albert_api_error_message'] || '';
};

export default getErrorMessageFromResponse;
