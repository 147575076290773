export const ErrorTitles = {
  kyc: {
    unableToVerify: 'Unable to verify identity',
    tooManyFailedAttempts: 'Too many failed attempts',
  },
};

const ErrorMessages = {
  web: {
    login: 'Please enter a valid email or password.',
    createProfile: 'An unexpected error occurred when creating your profile.',
    updateProfile:
      'An unexpected error occurred while trying to update your profile. Please try again later.',
    generic: 'An unexpected error occurred.',
    invalidIdentity:
      'We were unable to verify your identity. Please update your personal information and try again.',
  },
  kyc: {
    unableToVerify:
      'We were unable to verify your identity. Please update your personal information and try again.',
    tooManyFailedAttempts:
      'We were unable to verify your identity. Please text Genius at **639-37** for further assistance.',
  },
  linkAccount: {
    checkingNotFound:
      'Checking account not found. Please connect another institution with a checking account.',
    checkingNoActivity:
      'Please connect another institution with an active checking account. No activity was found in the account linked.',
  },
  input: {
    validEmail: 'Please enter a valid email address.',
    validSSN: 'Please enter a valid social security number',
    validAddress: 'Please enter a valid address.',
    validPhone: 'Please enter a valid phone number.',
    validRoutingNum: 'Please enter a valid 9 digit routing number.',
    validAccountNum: 'Please enter a valid account number.',
    validDate: 'Please enter a valid date.',
    validBirthday: 'Please enter a valid birthday.',
    validBirthdayMinAge: 'You must be 18 years or older to sign up.',
    required: 'This field is required.',
    mustBeNumber: 'This field must be a number.',
    minLen: 'This field is too short.',
    maxLen: 'This field is too long.',
    noFutureDate: 'Date cannot be a future date.',
    minValue: 'The value is too small.',
    maxValue: 'The value is too large.',
  },
};

export default ErrorMessages;
