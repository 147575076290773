import { FORM_ERROR } from 'final-form';
import React from 'react';
import { Field, FieldRenderProps, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import updateEmail from 'actions/async/updateEmail';
import removeBanner from 'actions/banner/removeBanner';
import Button from 'components/common/Button';
import Input from 'components/common/Input';
import { InputTypes } from 'components/common/Input/TextInput';
import { renderLabels } from 'components/common/Input/shared';
import ButtonsContainer from 'components/layout/ButtonsContainer';
import PageBase from 'components/layout/PageBase';
import useBrowserNavigationOverrideWithBanner from 'hooks/useBrowserNavigationBlockWithBanner';
import * as language from 'reducers/entities/language';
import { breakpoints } from 'styles';
import * as WEB from 'types/interfaces';
import { delayWithState } from 'utils/delay';
import { getLanguageButtons, getLanguageSection } from 'utils/getFromLanguage';
import { composeValidators, isRequired, validEmail } from 'utils/validators';

type Props = {
  email: string;
  onComplete: () => void;
};

const VerifyEmailUpdate = (props: Props) => {
  // ///////////////////////////////
  /* =========== HOOKS ========== */
  // ///////////////////////////////
  const dispatch = useDispatch();
  useBrowserNavigationOverrideWithBanner();

  // ///////////////////////////////
  /* =========== STATE ========== */
  // ///////////////////////////////
  const [isCompleted, setIsCompleted] = React.useState(false);

  // ///////////////////////////////
  /* ======== MAPPED STATE ====== */
  // ///////////////////////////////
  const languageCtx = useSelector((state: WEB.RootState) =>
    language.getSignupPageLanguage(state, 'VerifyEmailUpdate')
  );

  // //////////////////////////////////
  /* =========== LANGUAGE ========== */
  // //////////////////////////////////
  const emailSection = getLanguageSection(languageCtx, 'email');
  const buttons = getLanguageButtons(languageCtx);

  // //////////////////////////////////
  /* =========== HANDLERS ========== */
  // //////////////////////////////////

  const handleOnSubmit = async (values: Record<string, any>) => {
    // Remove any error banner from previous attempts
    dispatch(removeBanner());

    const { error }: any = await dispatch(
      updateEmail({ email: values.email }, 'signup')
    );

    if (error) return { [FORM_ERROR]: 'error' };
  };

  const submitCallback = async (errors?: any) => {
    if (!errors) {
      await delayWithState(400, setIsCompleted);
      props.onComplete();
    }
  };

  // ///////////////////////////////////////
  /* =========== FORM ELEMENTS ========== */
  // ///////////////////////////////////////
  const EmailInput = ({ input, meta }: FieldRenderProps<any, HTMLElement>) => (
    <Input.Text
      id='email'
      type={InputTypes.EMAIL}
      placeholder={props.email}
      errorText={emailSection.errorMessage}
      invalid={meta?.error && meta.submitFailed && !meta.dirtySinceLastSubmit}
      {...input}
    />
  );

  // ///////////////////////////////
  /* ========== RENDER ========== */
  // ///////////////////////////////
  return (
    <PageBase
      mobileHeader={emailSection.header}
      mobileLead={[emailSection.text || '']}
    >
      <Form
        onSubmit={handleOnSubmit}
        initialValues={{
          email: props.email,
        }}
        render={({ handleSubmit, submitting }) => (
          <form
            onSubmit={(event) => {
              handleSubmit(event)?.then(submitCallback);
            }}
          >
            <HideOnMobile>
              {renderLabels({
                label: emailSection.header,
                description: emailSection.text,
              })}
            </HideOnMobile>
            <Field
              name='email'
              component={EmailInput}
              validate={composeValidators(isRequired, validEmail)}
            />
            <ButtonsContainer>
              <Button
                disabled={submitting}
                isLoading={submitting}
                isCompleted={isCompleted}
              >
                {isCompleted ? buttons.complete : buttons.primary}
              </Button>
            </ButtonsContainer>
          </form>
        )}
      />
    </PageBase>
  );
};

const HideOnMobile = styled.div`
  @media ${breakpoints.mobileLarge} {
    display: none;
  }
`;

export default VerifyEmailUpdate;
