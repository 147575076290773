import * as React from 'react';
import { useLocation } from 'react-router-dom';
import NodeEnvironment from 'constants/NodeEnvironment';
import useScrollToTopOnRoute from 'hooks/useScrollToTopOnRoute';

export type Props = {
  children: React.ReactElement;
};

/**
 * Wrapper component for React Router v6 element
 * "Protected" is synonymous with "authenticated" - meaning these are routes can only be accessed by
 * logged-in users. For these "protected" routes, we initialize app (if not initialized already)
 * on route mount and only render the protected route's component if and when initialize app is successful.
 * Initializing app successfully means the user is authenticated. If call to initialize app fails,
 * user is redirected to the login page and we set state "loggedIn" as "false" in our store.
 */
const SignupRerouteWrapper = ({
  children,
}: Props): React.ReactElement | null => {
  const location = useLocation();

  // @todo - This was a desperate attempt to fix an iOS Safari scrolling bug
  //  we had struggled to pinpoint. We have since solved the core issue, and
  //  I think this isn't necessary, but we should test more before removal. -AR
  useScrollToTopOnRoute();

  // Only enforce redirect to signup domain iff prod.
  if (window.albertWeb.Environment === NodeEnvironment.PROD) {
    const signupAppURL = new URL(window.albertWeb.SignupAppDomain);
    if (window.location.hostname !== signupAppURL.hostname) {
      window.location.href = `${signupAppURL.origin}${location.pathname}`;
      return null;
    }
  }
  return children;
};

export default SignupRerouteWrapper;
