import * as React from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import Button from 'components/common/Button';
import ArtMap, { Art } from 'constants/ArtMap';
import { NotificationTypes } from 'constants/index';
import useOutsideClickDetection from 'hooks/useOutsideClickDetection';
import { breakpoints } from 'styles';
import mixins from 'styles/mixins';
import { BaseModalProps } from 'types/interfaces';
import Text from '../Text';
import { BaseModal, IconWrapper, ModalContent } from './shared/BaseModal';

/* eslint-disable @typescript-eslint/no-explicit-any */
export type Props = BaseModalProps & {
  /* main icon to display at the top of the modal */
  iconType?: NotificationTypes | NotificationTypes.WARNING | string;
  /* custom component to display at the top of the modal */
  customIcon?: React.ReactNode;
  /* title text for the modal */
  title: string;
  /* is Genius (use alt Genius colors) */
  isGenius?: boolean;
  /* description text for the modal */
  description?: string;
  /* label for the cancel action */
  cancelLabel?: string;
  /* label for the submit action */
  submitLabel?: string;
  /* label for the submit action when completed (props.isCompleted) */
  submitCompletedLabel?: string;
  /* disclosure text for the submit action */
  submitDisclosure?: string;
  /* submitting (loading) state for the submit button */
  isSubmitting?: boolean;
  /* completed state for the submit button */
  isCompleted?: boolean;
  /* the action to handle when submitting */
  onSubmit?: (...args: any[]) => void;
  /* the action to handle clicking the X button to close the modal.
  If not given, the onCancel prop is used instead. */
  onClickX?: (...args: any[]) => void;
  /* the state of modal buttons */
  disabled?: boolean | false;
  width?: number;
  /* custom padding above title */
  customTitlePadding?: string;
  /* has a sticky footer */
  stickyFooter?: boolean;
};

const PreStyledWarningIcon = ({
  isGenius,
  className,
}: {
  isGenius: boolean | undefined;
  className?: string;
}) => (
  <img
    src={
      isGenius
        ? ArtMap(Art.ExclamationCircleGenius)
        : ArtMap(Art.ExclamationCircle)
    }
    alt='Warning'
    className={className || ''}
  />
);
const StyledWarningIcon = styled(PreStyledWarningIcon)<{
  isGenius: boolean | undefined;
}>`
  width: 55px;
  color: ${({ theme, isGenius }) =>
    isGenius ? theme.colors.geniusText : theme.colors.primaryWarning};
`;

const StyledIcon = styled.img`
  width: 55px;
`;

export const StyledTitleWrapper = styled.div<{ customTitlePadding?: string }>`
  padding-top: ${({ customTitlePadding }) =>
    mixins.pxToRem(customTitlePadding || '24px')};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const StyledDescriptionWrapper = styled.div`
  padding-bottom: 32px;
  max-height: ${mixins.pxToRem('250px')};
  position: relative;
  text-align: center;
  overflow-y: auto;
  white-space: pre-line;
`;

export const StyledButtonWrapper = styled.div<{ sticky?: boolean }>`
  ${({ sticky }) =>
    sticky &&
    `
    position: sticky;
    background-color: white;
    bottom: -1px;
    margin-left: -42px;
    margin-right: -42px;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 16px;
    padding-bottom: 16px;
  `}

  display: flex;
  justify-content: space-between;
  align-items: center;
  /* Add seperation between flex items */
  *:not(:last-child) {
    margin-right: ${mixins.pxToRem('15px')};
  }
`;

export const StyledButton = styled(Button)`
  height: ${mixins.pxToRem('52px')};
  min-width: initial;
`;

const DisclosureText = styled(Text)`
  font-size: 12.5px;

  @media ${breakpoints.mobileLarge} {
    font-weight: 600;
  }

  text-align: center;
  margin-bottom: 8px;
  padding-left: 8px;
  padding-right: 8px;
`;

const SubmitContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const CancelButton = styled(StyledButton)`
  border-width: 2.5px;
`;

export const StyledH2 = styled.h2`
  margin: 0;
`;

const BasicModal = (props: Props): React.ReactElement | null => {
  const {
    id,
    theme,
    title,
    description,
    show,
    iconType,
    customIcon,
    cancelLabel,
    submitLabel,
    submitDisclosure,
    submitCompletedLabel,
    children,
    isGenius,
    onCancel,
    onSubmit,
    onClickX,
    width,
    isSubmitting,
    isCompleted,
    disabled,
    customTitlePadding,
    stickyFooter,
  } = props;

  const containerRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    // Prevent button from focusing when modal first displays
    document.getElementById('modal-submit-button')?.blur();
  }, [show]);

  const ModalIcon = {
    [NotificationTypes.SUCCESS]: (
      <StyledIcon src={ArtMap(Art.GreenCheck)} alt='Success' />
    ),
    [NotificationTypes.WARNING]: (
      <StyledWarningIcon theme={theme} isGenius={isGenius} />
    ),
    [NotificationTypes.ERROR]: (
      <StyledIcon src={ArtMap(Art.ModalErr)} alt='Error' />
    ),
    [NotificationTypes.NEUTRAL]: (
      <StyledIcon src={ArtMap(Art.NeutralCheck)} alt='Notice' />
    ),
    [NotificationTypes.NEUTRAL_WARNING]: (
      <StyledIcon src={ArtMap(Art.ExclamationCircleGray)} alt='Error' />
    ),
  };

  const renderModalIcon = (): React.ReactElement | null => {
    if (iconType && !customIcon) {
      return <IconWrapper>{ModalIcon[iconType]}</IconWrapper>;
    }
    if (!iconType && customIcon) {
      return <IconWrapper>{customIcon}</IconWrapper>;
    }
    return null;
  };

  const onClickOutside = (): void => {
    if (onClickX) onClickX();
    else if (onCancel) onCancel();
  };
  useOutsideClickDetection(containerRef, onClickOutside, show);

  if (!show) return null;

  const submitButton = (
    <StyledButton
      id='modal-submit-button'
      stretch
      theme={theme}
      onClick={onSubmit}
      isLoading={isSubmitting}
      isCompleted={isCompleted}
      disabled={disabled}
    >
      {isCompleted ? submitCompletedLabel : submitLabel}
    </StyledButton>
  );

  return (
    <BaseModal
      id={id}
      ref={containerRef}
      onClickClose={onClickX || onCancel}
      width={width}
      bottomPad={stickyFooter ? 0 : undefined}
    >
      {renderModalIcon()}
      <StyledTitleWrapper customTitlePadding={customTitlePadding}>
        <StyledH2>{title}</StyledH2>
      </StyledTitleWrapper>
      {description && (
        <StyledDescriptionWrapper>
          <ReactMarkdown>{description}</ReactMarkdown>
        </StyledDescriptionWrapper>
      )}
      {children && <ModalContent>{children}</ModalContent>}
      <StyledButtonWrapper sticky={stickyFooter}>
        {cancelLabel && (
          <CancelButton
            id='modal-cancel-button'
            secondary
            stretch
            theme={theme}
            onClick={onCancel}
            disabled={disabled}
          >
            {cancelLabel}
          </CancelButton>
        )}
        {submitLabel &&
          onSubmit &&
          (submitDisclosure ? (
            <SubmitContainer>
              <DisclosureText>{submitDisclosure}</DisclosureText>
              {submitButton}
            </SubmitContainer>
          ) : (
            submitButton
          ))}
      </StyledButtonWrapper>
    </BaseModal>
  );
};

export default BasicModal;
