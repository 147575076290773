import * as React from 'react';
import { IconContainer, Props } from './shared';

const CashAdvanceIcon = (props: Props): React.ReactElement => {
  const { color } = props;

  return (
    <IconContainer>
      <svg
        width='14'
        height='25'
        viewBox='0 0 14 25'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g clipPath='url(#clip0_9839_5453)'>
          <path
            d='M0.783875 18.7409C1.79188 20.8409 4.22787 21.9049 6.41187 22.1849V23.2489C6.41187 23.8649 6.91588 24.3689 7.53188 24.3689C8.14788 24.3689 8.65188 23.8649 8.65188 23.2489V22.2129C12.0679 21.8489 13.8879 19.6929 13.8879 17.0329C13.8879 14.7649 12.1239 12.4129 8.65188 11.7969V5.44094C10.0239 5.72094 11.1159 6.42094 11.7879 7.82094C11.9559 8.21294 12.3479 8.46494 12.7959 8.46494C13.4119 8.46494 13.9159 7.96094 13.9159 7.34494C13.9159 7.17694 13.8879 7.00894 13.8039 6.86894C12.7399 4.62894 10.7519 3.53694 8.65188 3.17294V2.08094C8.65188 1.46494 8.14788 0.960938 7.53188 0.960938C6.91588 0.960938 6.41187 1.46494 6.41187 2.08094V3.08894C2.79988 3.42494 0.951875 6.08494 0.951875 8.38094C0.951875 10.4249 2.46387 12.9729 6.41187 13.7289V19.9449C4.84387 19.7209 3.41588 19.0209 2.79988 17.7889C2.63188 17.3969 2.23988 17.1449 1.79188 17.1449C1.17588 17.1449 0.671875 17.6489 0.671875 18.2649C0.671875 18.4329 0.699875 18.6009 0.783875 18.7409ZM3.19188 8.38094C3.19188 6.98094 4.11588 5.58094 6.41187 5.32894V11.4049C3.91988 10.8729 3.19188 9.61294 3.19188 8.38094ZM8.65188 19.9729V14.0929C10.8079 14.5409 11.6479 15.7449 11.6479 17.0329C11.6479 18.6289 10.8079 19.7209 8.65188 19.9729Z'
            fill={color || '#333333'}
          />
        </g>
        <defs>
          <clipPath id='clip0_9839_5453'>
            <rect
              width='13.244'
              height='23.408'
              fill='white'
              transform='translate(0.671875 0.960938)'
            />
          </clipPath>
        </defs>
      </svg>
    </IconContainer>
  );
};

export default CashAdvanceIcon;
