import './utils/polyfills/hasOwn.js';
import { RewriteFrames as RewriteFramesIntegration } from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import cookie from 'cookie';
import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { Store } from 'redux';
import trackingBase from 'tracking.Base';
import { v4 as uuidv4 } from 'uuid';
import App from 'components/App';
import { logger } from 'utils/logger';
import { BUCKET_KEY } from 'utils/percentageRollout';
import configureStore from './store.configureStore';

trackingBase.initialize();
logger.initialize();

// Sync Cohort Bucket
const cookies = cookie.parse(document.cookie);
const storageBucket = localStorage.getItem(BUCKET_KEY);
const cookieBucket = cookies[BUCKET_KEY];

if (cookieBucket == null && storageBucket != null) {
  const domain = window.albertWeb.IsLocal ? '' : ';domain=.albert.com;secure';
  document.cookie = `${BUCKET_KEY}=${storageBucket};path=/${domain}`;
  logger.debug(
    `Cookie bucket was cleared, but storage persisted. Setting cohort bucket to ${storageBucket}`
  );
}
if (
  cookieBucket != null &&
  storageBucket != null &&
  cookieBucket !== storageBucket
) {
  localStorage.setItem(BUCKET_KEY, cookieBucket);
  logger.debug(
    `Cohort mismatch, storage: ${storageBucket}, cookie: ${cookieBucket}. Favoring cookie.`
  );
}

// Initialize Sentry
Sentry.init({
  dsn: window.albertWeb.SentryDSN,
  environment: window.albertWeb.AlbertEnv || window.albertWeb.Environment,
  release: window.albertWeb.Version,
  integrations: [
    new Integrations.BrowserTracing(),
    new RewriteFramesIntegration({
      iteratee: (frame) => {
        // Remove "In App" flag and stack trace URL from NrWrapper to solve:
        // 1. Alerting on external script errors
        // 2. Capturing full URLs in stack traces, preventing similar errors
        //    from being grouped, and logging user emails in query params
        if (/nrWrapper/.test(frame.function || '')) {
          frame.filename = '?';
          frame.lineno = 0;
          frame.colno = 0;
          frame.in_app = false;
        }
        return frame;
      },
    }),
  ],
  sampleRate: 1,
  tracesSampleRate: 1.0,
  attachStacktrace: true,
  ignoreErrors: [
    'ResizeObserver loop limit exceeded',
    "Can't find variable: setIOSParameters",
    // Mute generic browser network errors
    'TypeError: Failed to fetch',
    'Failed to fetch',
    'TypeError: NetworkError when attempting to fetch resource.',
    'NetworkError when attempting to fetch resource.',
    'TypeError: cancelled',
    'cancelled',
    // Thrown by suspicious users
    'Non-Error promise rejection captured with keys: code, error, message',
    // Thrown by Safari users, mostly on Mac; feels like it's caused by a Safari extension
    'Non-Error promise rejection captured with keys: currentTarget, detail, isTrusted, target',
  ],
  denyUrls: [
    // Webkit errors
    /^:\/\/hidden\//,
  ],
  beforeSend: (event) => {
    if (event?.request?.url) {
      // Remove PII that might be present in a query param
      event.request.url = event.request.url.replace(
        /(email=)[^&]+/g,
        'email=[sanitized]'
      );
    }
    return event;
  },
});

Sentry.setContext('location', { host: document.location.host });

// Intercept fetch to set trace ID.
const nativeFetch = window.fetch;
window.fetch = (...args) => {
  try {
    const requestURL = (args[0] || '') as string;
    const requestOptions = args[1] || {};
    const requestHeaders = requestOptions.headers || {};

    // Add albert-trace-id if does not exist.
    // NOTE: Do not add albert-trace-id for cross origin requests (CORS).
    const shouldAddAlbertTraceID =
      new URL(requestURL).host === window.location.host &&
      !requestHeaders?.['albert-trace-id'];
    if (shouldAddAlbertTraceID) {
      requestHeaders['albert-trace-id'] = uuidv4();
    }

    requestOptions.headers = requestHeaders;

    // eslint-disable-next-line no-param-reassign
    args[1] = requestOptions;
    return nativeFetch(...args);
  } catch (_) {
    return nativeFetch(...args);
  }
};

declare global {
  interface Window {
    albertWeb: any;
    fbq: any;
    gtag: any;
    dataLayer: any;
    devicer: any;
    uetq: any;
  }
}

/* eslint-disable @typescript-eslint/no-var-requires */
export const store: Store = configureStore();

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

// Expose store when run in Cypress
if (window.Cypress) {
  window.store = store;
}
