import * as React from 'react';
import styled from 'styled-components';
import Divider from 'components/common/Divider';
import Text, { TextColor, TextSizes } from 'components/common/Text';
import ChevronLeftIcon from 'components/icons/ChevronLeftIcon';
import ChevronRightIcon from 'components/icons/ChevronRightIcon';
import useOutsideClickDetection from 'hooks/useOutsideClickDetection';
import { mixins, spacers } from 'styles';
import { BaseModalProps } from 'types/interfaces';
import { BaseModal, ModalContent } from './shared/BaseModal';

/* eslint-disable @typescript-eslint/no-explicit-any */
export interface Props extends BaseModalProps {
  defaultPage?: number;
  bottomPad?: number;
}

const StyledLinkWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledIconLink = styled.div`
  cursor: pointer;
`;

const StyledLeftIcon = styled(ChevronLeftIcon)<{
  theme?: any;
  hasNext: boolean;
}>`
  width: ${mixins.pxToRem('18px')};
  margin-right: ${mixins.pxToRem('9px')};
  color: ${({ theme, hasNext }) =>
    hasNext ? theme.colors.primaryText : theme.colors.darkGray1};
`;

const StyledRightIcon = styled(ChevronRightIcon)<{
  theme?: any;
  hasNext: boolean;
}>`
  width: ${mixins.pxToRem('18px')};
  margin-left: ${mixins.pxToRem('9px')};
  color: ${({ theme, hasNext }) =>
    hasNext ? theme.colors.primaryText : theme.colors.darkGray1};
`;

const StyledModalContent = styled(ModalContent)`
  padding-bottom: 0;
`;

const NavigationModal = (props: Props): React.ReactElement | null => {
  const { id, defaultPage = 0, show, children, onCancel, bottomPad } = props;
  const numPages = React.Children.count(children);
  const [page, setPage] = React.useState<number>(defaultPage);
  const containerRef = React.useRef<HTMLDivElement>(null);

  // Update state when default page changes
  React.useEffect(() => {
    setPage(defaultPage);
  }, [defaultPage]);

  const handleOnNext = () => {
    if (page + 1 < numPages) {
      setPage(page + 1);
    } else {
      setPage(0);
    }
  };

  const handleOnPrev = () => {
    if (page > 0) {
      setPage(page - 1);
    } else {
      setPage(numPages - 1);
    }
  };

  const onClickOutside = (): void => {
    onCancel && onCancel();
  };

  useOutsideClickDetection(containerRef, onClickOutside, show);

  if (!show) return null;

  return (
    <BaseModal
      id={id}
      ref={containerRef}
      onClickClose={onCancel}
      bottomPad={bottomPad}
    >
      {children && <StyledModalContent>{children[page]}</StyledModalContent>}
      <Divider space={spacers.tabSmall} className='' />
      <StyledLinkWrapper>
        <StyledIconLink className={`nav-modal-link-prev-${id}`}>
          <StyledLeftIcon hasNext={page > 0} />
          <Text
            isLinkButton
            underline
            inline
            size={TextSizes.SMALL}
            weight='700'
            color={TextColor.BLACK}
            onClick={handleOnPrev}
          >
            Previous
          </Text>
        </StyledIconLink>
        <StyledIconLink className={`nav-modal-link-next-${id}`}>
          <Text
            isLinkButton
            underline
            inline
            size={TextSizes.SMALL}
            weight='700'
            color={TextColor.BLACK}
            onClick={handleOnNext}
          >
            Next
          </Text>
          <StyledRightIcon hasNext={page < numPages - 1} />
        </StyledIconLink>
      </StyledLinkWrapper>
    </BaseModal>
  );
};

export default NavigationModal;
