import * as React from 'react';

type Props = {
  url: string;
};
const ExternalRedirect = ({ url }: Props) => {
  React.useEffect(() => {
    window.location.href = url;
  }, [url]);

  return null;
};

export default ExternalRedirect;
