import CashAdvanceIcon from './CashAdvanceIcon';
import BudgetIcon from './FinancialAdviceIcon';
import InvestingIcon from './InvestingIcon';
import PayOffDebtIcon from './PayOffDebtIcon';
import PrimaryBankAccountIcon from './PrimaryBankAccountIcon';
import SaveMoneyIcon from './SaveMoneyIcon';

export enum AboutYouGuidanceIcons {
  PRIMARY_BANK_ACCOUNT = 'PrimaryBankAccountIcon',
  SAVE_MONEY = 'SaveMoneyIcon',
  CASH_ADVANCE = 'CashAdvanceIcon',
  PAY_OFF_DEBT = 'PayOffDebtIcon',
  BUDGET = 'BudgetIcon',
  INVESTING = 'InvestingIcon',
}

export default {
  [AboutYouGuidanceIcons.PRIMARY_BANK_ACCOUNT]: PrimaryBankAccountIcon,
  [AboutYouGuidanceIcons.SAVE_MONEY]: SaveMoneyIcon,
  [AboutYouGuidanceIcons.CASH_ADVANCE]: CashAdvanceIcon,
  [AboutYouGuidanceIcons.PAY_OFF_DEBT]: PayOffDebtIcon,
  [AboutYouGuidanceIcons.BUDGET]: BudgetIcon,
  [AboutYouGuidanceIcons.INVESTING]: InvestingIcon,
};
