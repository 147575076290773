import mixins from 'styles/mixins';

/**
 * Given a font size in pixels, return the CSS style declaration
 * for the font size in rem.
 *
 * @param px font size in pixels, e.g. '16px'
 * @returns css style declaration, e.g. 'font-size: 1rem;'
 */
const getFontSizeStyle = (px: string): string => {
  return `font-size: ${mixins.pxToRem(px)};`;
};

export default {
  fontSize12: getFontSizeStyle('12px'),
  fontSize14: getFontSizeStyle('14px'),
  fontSize16: getFontSizeStyle('16px'),
  fontSize18: getFontSizeStyle('18px'),
  fontSize20: getFontSizeStyle('20px'),
  fontSize22: getFontSizeStyle('22px'),
  fontSize23: getFontSizeStyle('23px'),
  fontSize24: getFontSizeStyle('24px'),
  fontSize28: getFontSizeStyle('28px'),
  fontSize30: getFontSizeStyle('30px'),
  fontSize35: getFontSizeStyle('35px'),
  fontSize40: getFontSizeStyle('40px'),
};
