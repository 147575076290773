import styled from 'styled-components';
import NoTranslate from 'components/NoTranslate';
import TwoPaneWrapper from 'components/layout/TwoPaneWrapper';
import { breakpoints, mixins, spacers } from 'styles';

export const PageContainer = styled(NoTranslate)`
  padding-left: ${spacers.defaultPaddingLeftRight};
  padding-right: ${spacers.defaultPaddingLeftRight};

  @media (max-width: 481px) {
    /* 2021/7/6, MPR: 481 is the width at which point the default padding drops
     * below 24px. Think of this as 'min-padding'
     */
    padding-left: ${mixins.pxToRem('24px')};
    padding-right: ${mixins.pxToRem('24px')};
  }
`;

export const PaneWrapper = styled(TwoPaneWrapper)<{ centered?: boolean }>`
  align-items: center;
  ${({ centered }) => (centered ? 'padding-top: 10vh;' : 'padding-top: 0vh;')}
  @media ${breakpoints.mobileLarge} {
    padding-top: 0;
  }
`;

/* Based on ContentPane */
const Pane = styled.div`
  ${mixins.fadeIn()};

  /* Vertical stack content pane (right side) of two pane layout */
  display: flex;
  flex-direction: column;
  width: 50vw;
  position: relative;

  padding-bottom: ${spacers.defaultPaddingTopBottom};

  @media ${breakpoints.mobileLarge} {
    position: static;
    width: 100%;
  }
`;

export const LeftPane = styled(Pane)`
  width: 45vw;

  padding-right: ${mixins.pxToRem('82px')};
  align-items: flex-end;
  @media ${breakpoints.mobileLarge} {
    width: 100%;
    padding-right: 0;
    padding-top: 30px;
    padding-bottom: 0;
    align-items: center;
  }
`;

export const RightPane = styled(Pane)`
  width: 55vw;

  padding-left: ${mixins.pxToRem('82px')};
  @media ${breakpoints.mobileLarge} {
    width: 100%;
    padding-left: 0;
    padding-top: 40px;
    padding-bottom: ${spacers.mobilePaddingBottom};
  }
`;
