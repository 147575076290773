import * as React from 'react';
import styled from 'styled-components';
import ErrorText from 'components/common/ErrorText';
import Spacer from 'components/common/Spacer';
import ArtMap, { Art } from 'constants/ArtMap';
import { breakpoints, mixins, spacers } from 'styles';
import { spacers as homeSpacers } from 'styles/home';
import { BaseInputProps } from 'types/interfaces';
import { formatSSN } from 'utils/inputFormatters';
import { BaseInput, InputWrapper, renderLabels } from './shared';

const SMALL_LABEL_DEFAULT = 'Social Security number';
const PLACEHOLDER_DEFAULT = 'xxx-xx-xxxx';
const FOOTER_LABEL_DEFAULT = 'Data secured with 256-bit encryption';

type Props = BaseInputProps & {
  footerLabel?: string;
};

const StyledLock = styled.img`
  height: ${mixins.pxToRem('12px')};
  width: ${mixins.pxToRem('12px')};
  margin: auto ${mixins.pxToRem('8px')} auto -${mixins.pxToRem('50px')};
  position: relative;
  left: ${mixins.pxToRem('15px')};
  @media ${breakpoints.mobileLarge} {
    width: ${mixins.pxToRem('14px')};
    height: ${mixins.pxToRem('14px')};
  }
`;

const StyledSubInputWrapper = styled.div`
  display: flex;
`;

const LabelWrapper = styled.div<{ isLast?: boolean }>`
  // Override mobile margins intended for input labels
  .label-header {
    margin: 0;
    margin-top: 0 !important;
  }
  margin-bottom: ${mixins.pxToRem('24px')};
  @media ${breakpoints.mobileLarge} {
    margin-bottom: ${({ isLast }) => (isLast ? '0' : homeSpacers.g4)};
  }
`;

const SSNInput = (props: Props): React.ReactElement => {
  const {
    id,
    smallLabel,
    placeholder,
    footerLabel,
    invalid,
    errorText,
    onChange,
    onBlur,
  } = props;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    e.target.value = formatSSN(value);
    onChange && onChange(e);
  };

  return (
    <div>
      <LabelWrapper>
        {renderLabels({
          ...props,
          smallLabel: smallLabel || SMALL_LABEL_DEFAULT,
        })}
      </LabelWrapper>
      <InputWrapper>
        <StyledSubInputWrapper>
          <BaseInput
            {...props}
            placeholder={placeholder || PLACEHOLDER_DEFAULT}
            onChange={handleChange}
            onBlur={onBlur}
            invalid={invalid}
            inputMode='tel'
            pattern='[0-9\-]+'
          />
          <StyledLock src={ArtMap(Art.SecureLock)} />
        </StyledSubInputWrapper>
        {invalid && <ErrorText name={id} text={errorText} />}
      </InputWrapper>
      <Spacer space={spacers.small} />
      <LabelWrapper isLast>
        {renderLabels({
          smallLabel: footerLabel || FOOTER_LABEL_DEFAULT,
          noMargin: true,
        })}
      </LabelWrapper>
    </div>
  );
};

export default SSNInput;
