/* eslint-disable no-param-reassign */

/* eslint-disable @typescript-eslint/no-explicit-any */
import produce from 'immer';
import initialState from 'store.initialState';
import types from 'actions/types';
import { AccountType, PropertySourceTypes } from 'constants/';
import SignupGoals, { SignupGoalsType } from 'constants/SignupGoals';
import * as WEB from 'types/interfaces';

// /////////////////////////////////////////////////////////////////////////////
// selectors
// /////////////////////////////////////////////////////////////////////////////

// Get values from app data fields
export const getValueByField = (state: WEB.RootState, field: string): any => {
  const { appData } = state.entities;
  return appData?.[field] ?? null;
};

// ========= Signup goals ==========
export const hasSetupGoal = (
  state: WEB.RootState,
  goal: SignupGoals
): boolean => {
  const signupGoalObjects = getValueByField(state, 'signup_goal_objects') || [];
  return signupGoalObjects.some(
    (goalObject: WEB.SignupGoalObject) =>
      goalObject.type === SignupGoalsType.SETUP_GOALS &&
      goalObject.name === goal
  );
};

// ========= Property ==========
/**
 * Get the user's legal property.
 * @param state
 */
export const getLegalProperty = (state: WEB.RootState): WEB.Property | null => {
  const legalProperties = Object.values(
    state.entities.appData?.accounts_dict || {}
  ).filter(
    (account: any) =>
      // Filter for properties
      account.account_type === WEB.PropertyAccountType.PROPERTY &&
      account.account_category === WEB.PropertyAccountCategory.HOME &&
      // Filter for legal properties
      account.input_source === PropertySourceTypes.LEGAL
  ) as WEB.Property[];

  // Sort the properties by timestamp in descending order,
  // so we can pick the latest
  legalProperties.sort(
    (a: WEB.Property, b: WEB.Property) => b.input_timestamp - a.input_timestamp
  );
  return legalProperties[0] || null;
};

/**
 * Get the user's primary property.
 * @param state
 */
export const getPrimaryProperty = (
  state: WEB.RootState
): WEB.Property | null => {
  const legalProperties = Object.values(
    state.entities.appData?.accounts_dict || {}
  ).filter(
    (account: any) =>
      // Filter for properties
      account.type === WEB.PropertyAccountType.PROPERTY &&
      account.category === WEB.PropertyAccountCategory.HOME &&
      // Filter for the primary property
      account.is_primary
  ) as WEB.Property[];
  return legalProperties[0] || null;
};

/**
 * Format a property id with the "property_" prefix.
 * @param id raw property id e.g. 123
 * @returns
 */
const getFormattedPropertyId = (id: number): string => `property_${id}`;

// ========= Accounts ==========
export const getSavingsAccountId = (state: WEB.RootState): number | null => {
  const appData = state.entities.appData as WEB.AppData;
  const savingsAccount =
    appData?.savings?.account || ({} as WEB.InstitutionAccount);
  return (savingsAccount?.id as number) || null;
};

export const getAllAccounts = (
  state: WEB.RootState
): WEB.InstitutionAccount[] => {
  const appData = state.entities.appData as WEB.AppData;
  const accountsById = appData?.accounts_dict || {};
  return Object.values(accountsById) as WEB.InstitutionAccount[];
};

export const getAccountById = (
  state: WEB.RootState,
  id: string | number
): WEB.InstitutionAccount | WEB.Property | null => {
  const appData = state.entities.appData as WEB.AppData;
  const accounts = appData?.accounts_dict || {};
  return accounts[id] || null;
};

export const getAllAccountsByInsId = (
  state: WEB.RootState,
  institutionId: string
): WEB.InstitutionAccount[] => {
  const appData = state.entities.appData as WEB.AppData;
  const accountsById = appData?.accounts_dict || {};
  const accountsArr = Object.values(accountsById) as WEB.InstitutionAccount[];
  return accountsArr.filter((acc: WEB.InstitutionAccount) => {
    return acc.institution_login?.institution?.institution_id === institutionId;
  });
};

export const getPrimaryCheckingAccounts = (
  state: WEB.RootState
): WEB.InstitutionAccount[] => {
  const accounts = getAllAccounts(state);
  return accounts.filter(
    (acc: WEB.InstitutionAccount) => acc.is_primary_checking
  );
};

export const getPrimaryCheckingAccount = (
  state: WEB.RootState
): WEB.InstitutionAccount | null => {
  const accounts = getAllAccounts(state);
  const primaryCheckingAccounts = accounts.filter(
    (acc: WEB.InstitutionAccount) => {
      return (
        acc.account_type === AccountType.BANKING && acc.is_primary_checking
      );
    }
  );

  // Mimic how iOS gets primary checking account.
  if (primaryCheckingAccounts.length > 0) {
    return primaryCheckingAccounts[0];
  }

  return null;
};

export const hasPrimaryChecking = (state: WEB.RootState): boolean => {
  const allAccounts = getAllAccounts(state);
  return allAccounts.some((acc: WEB.InstitutionAccount) => {
    return acc.account_type === AccountType.BANKING && acc.is_primary_checking;
  });
};

// ========= Banking ==========
export const getBankingAccount = (
  state: WEB.RootState
): WEB.BankingAccount | null => {
  const appData = state.entities.appData as WEB.AppData;
  const account = appData.banking?.account;

  // Note: When a profile is banking enabled but the banking account doesn't exist,
  // the API returns it as an empty object in app data.
  if (account && Object.keys(account).length > 0) {
    // Return the account if it's an actual account
    return account;
  }

  // Return null if the account doesn't exist
  return null;
};

export const getBankingCommonData = (
  state: WEB.RootState
): WEB.BankingCommonData | null => {
  const appData = state.entities.appData as WEB.AppData;
  const data = appData.banking?.common;
  return data || null;
};

export const getBankingLinkedAccounts = (
  state: WEB.RootState
): WEB.BankingLinkedAccount[] => {
  const appData = state.entities.appData as WEB.AppData;
  const data = appData.banking?.linked_accounts;
  return data || [];
};
export const getBankingTransactions = (
  state: WEB.RootState
): WEB.BankingTransaction[] => {
  const appData = state.entities.appData as WEB.AppData;
  const data = appData.banking?.transactions;
  return data || [];
};

export const getLinkedAccount = (
  state: WEB.RootState
): WEB.BankingLinkedAccount | null => {
  const linkedAccounts = getBankingLinkedAccounts(state);
  const bankingAccount = getBankingAccount(state);
  const linkedAccount = linkedAccounts.find(
    (account) =>
      account.bank_account === bankingAccount?.id && !!account.bank_account
  );
  return linkedAccount || null;
};

export const getMatchingBankAccount = (
  state: WEB.RootState,
  id: number
): WEB.InstitutionAccount | null => {
  const accounts = getAllAccounts(state);
  const matchingAccount = accounts.find((account) => account.id === id);
  return matchingAccount || null;
};

export const getDebitCardData = (
  state: WEB.RootState
): WEB.BankingDebitCard | null => {
  const appData = state.entities.appData as WEB.AppData;
  const data = appData.banking?.debit_card;
  if (data && Object.keys(data).length > 0) {
    return data;
  }
  return null;
};

export const getCardholderName = (
  state: WEB.RootState
): WEB.CardholderName | null => {
  const appData = state.entities.appData as WEB.AppData;
  const data = appData.banking?.cardholder_name;
  if (data && Object.keys(data).length > 0) {
    return data;
  }
  return null;
};

// /////////////////////////////////////////////////////////////////////////////
// reducers
// /////////////////////////////////////////////////////////////////////////////

/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const appData = (state = initialState.entities.appData, action: WEB.Action) => {
  const { type, payload, error } = action;
  return produce(state, (draft: WEB.RootState['entities']['appData']) => {
    switch (type) {
      case types.GET_APP_DATA_SUCCESS: {
        if (payload.data) {
          if (payload.data.savings) {
            payload.data.savings.transactions.reverse();
            payload.data.savings.transactions_all.reverse();
          }
          // Transfer history is not returned from the backend so we need to manually append it
          // TODO: Move this into a new reducer for all transactions from the backend
          if (draft.transfer_history) {
            payload.data.transfer_history = draft.transfer_history;
          }
          return payload.data;
        }
        return {};
      }

      case types.CREATE_PROPERTY_SUCCESS: {
        const { properties } = payload.data;

        if (Array.isArray(properties)) {
          properties.forEach((property) => {
            property.id = getFormattedPropertyId(property.id);
            draft.accounts_dict[property.id] = property;
          });
        }
        return;
      }

      case types.UPDATE_PROPERTY_SUCCESS: {
        const property = payload.data;
        property.id = getFormattedPropertyId(property.id);
        draft.accounts_dict[property.id] = property;
        return;
      }

      case types.CREATE_BANKING_ACCOUNT_SUCCESS:
      case types.UPDATE_BANKING_ACCOUNT_SUCCESS:
      case types.COMPLETE_BANKING_APPLICATION_SUCCESS: {
        const bankingAccount = payload.data;

        if (!draft.banking) draft.banking = {};
        draft.banking.account = bankingAccount;

        return;
      }

      case types.GET_BANK_ACCOUNT_NUM_SUCCESS: {
        const accountNum = payload.data;
        draft.banking.account.account_number = accountNum.account_number;
        return;
      }

      case types.GET_ACCOUNT_SUCCESS: {
        const accountId = payload?.data?.account?.id;
        if (accountId && state?.accounts_dict) {
          draft.accounts_dict[accountId] = payload.data.account;
        }
        return;
      }

      case types.VERIFY_EXTERNAL_ACCOUNT_SUCCESS: {
        const accountId = payload?.data?.account?.id;
        if (accountId && state?.accounts_dict) {
          draft.accounts_dict[accountId] = payload.data.account;
        }
        return;
      }

      case types.SUBMIT_KYC_MFA_SUCCESS: {
        draft.kyc = payload.data;
        return;
      }

      case types.CLEAR_KYC_MFA_QUESTIONS: {
        draft.kyc.kyc_mfa_questions = [];
        return;
      }

      case types.GET_BANKING_DATA_SUCCESS: {
        draft.banking = payload.data;
        return;
      }

      case types.GET_BANKING_STATEMENTS_SUCCESS: {
        draft.banking.statements = payload.data.bank_account_statements;
        return;
      }

      case types.CANCEL_SAVINGS_TRANSACTION_SUCCESS: {
        draft.savings = payload.data;
        return;
      }

      case types.GET_WF_SAVINGS_STATEMENTS_SUCCESS: {
        draft.savings.statements.wells_fargo = payload.data;
        return;
      }

      case types.GET_COASTAL_SAVINGS_STATEMENTS_SUCCESS: {
        draft.savings.coastal_statements = payload.data.statements;
        return;
      }

      case types.GET_INVESTING_DOCUMENTS_SUCCESS: {
        draft.investing.documents = payload.data;
        return;
      }

      case types.GET_TRANSFER_HISTORY_SUCCESS: {
        draft.transfer_history = payload.data;
        return;
      }

      case types.GET_BANKING_DOCUMENTS_SUCCESS: {
        draft.banking.account.documents = payload.data;
        return;
      }

      case types.LOGOUT_SUCCESS: {
        return initialState.entities.appData;
      }

      default:
    }
  });
};

export default appData;
