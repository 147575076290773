import { InfoCircle } from '@styled-icons/boxicons-regular/InfoCircle';
import * as React from 'react';
import ReactMarkdown, { Options } from 'react-markdown';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import TrackingBase from 'tracking.Base';
import enableGenius from 'actions/async/enableGenius';
import updateGeniusPricing from 'actions/async/updateGeniusPricing';
import updateProfile from 'actions/async/updateProfile';
import addBanner from 'actions/banner/addBanner';
import removeBanner from 'actions/banner/removeBanner';
import Button from 'components/common/Button';
import CheckCircleList from 'components/common/List/CheckCircleList';
import BasicModal from 'components/common/Modal/BasicModal';
import DrawerModal from 'components/common/Modal/DrawerModal';
import ModalExpanderContent from 'components/common/Modal/ModalExpanderContent';
import Spacer from 'components/common/Spacer';
import Text, { TextColor, TextSizes } from 'components/common/Text';
import { getColor } from 'components/common/Text';
import { SavingsBonusLottie } from 'components/common/animations';
import CheckCircle from 'components/icons/CheckCircleRounded';
import { Header } from 'components/layout/LeadContent';
import PageBase from 'components/layout/PageBase';
import ArtMap, { Art } from 'constants/ArtMap';
import {
  AlbertTrackingEvent,
  NotificationTypes,
  Pages,
  PaymentFrequency,
} from 'constants/index';
import useNavigateFunction from 'hooks/useNavigateFunction';
import * as appData from 'reducers/entities/appData';
import * as language from 'reducers/entities/language';
import * as profile from 'reducers/entities/profile';
import * as genius from 'reducers/ui/genius';
import { breakpoints } from 'styles';
import { flexGapRow } from 'styles/flexGap';
import { spacers as homeSpacers } from 'styles/home';
import spacers from 'styles/spacers';
import theme from 'styles/theme';
import * as WEB from 'types/interfaces';
import { GeniusTieredPricing } from 'types/interfaces';
import { delayWithState } from 'utils/delay';
import { getLanguageSection } from 'utils/getFromLanguage';
import { hash32 } from '../../utils/hash';

const StyledTableRow = styled.div<{ disabled?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
`;

const LinkText = styled(Text)`
  cursor: pointer;
  border-bottom: 2px solid black;
  width: fit-content;
  margin: 0 auto;
  padding-bottom: 1px;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${homeSpacers.g4};

  @media ${breakpoints.mobileLarge} {
    flex-direction: column-reverse;
    button {
      width: 100%;
    }
  }
`;

const UnfixedBottomSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-top: ${homeSpacers.g8};

  @media ${breakpoints.mobileLarge} {
    display: block;
  }
`;

const FixedBottomSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: fixed;
  bottom: 0;
  left: 42vw;
  right: 0;

  background-color: white;

  padding-top: ${homeSpacers.g4};
  padding-bottom: ${homeSpacers.g4};
  padding-left: 5vw;
  padding-right: 5vw;

  @media ${breakpoints.mobileLarge} {
    position: fixed;
    display: block;
    left: 0;
    padding-top: 0;
  }
`;

const DetailsContainer = styled.div`
  padding: ${homeSpacers.g4};
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.lightGray3};
`;

const DetailsMarkdown = styled(MarkdownContent)`
  p {
    font-size: 0.875rem;
  }
`;

const DetailsFooter = styled(Text)`
  cursor: pointer;
`;

const AlignContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Badge = styled.span<{ color: string }>`
  margin-left: 6px;
  background-color: ${getColor};
  color: white;
  width: fit-content;
  padding: 4px 8px;
  font-size: 0.75rem;
  font-weight: 600;
  border-radius: 20px;
  letter-spacing: none;
  line-height: 12px;
`;

const StyledCheckCircle = styled(CheckCircle)<{ color?: string }>`
  width: 20px;
  height: 20px;
  color: ${getColor};
`;

const AnnualImage = styled.div`
  text-align: center;
  background-color: ${({ theme }) => theme.colors.lightGray3};
  padding: 32px 0 16px 0;
  border-radius: 8px;
  overflow: hidden;
`;

const Centered = styled.div`
  text-align: center;
`;

const LineSeparator = styled.div`
  height: 2px;
  width: 100px;
  background-color: black;
  margin-top: 8px;
  margin-left: auto;
  margin-right: auto;
`;

const StyledDisclaimer = styled(Text)`
  font-size: 12.5px;

  @media ${breakpoints.mobileLarge} {
    font-weight: 600;
    p {
      line-height: 120%;
    }
  }

  text-align: center;
`;

const StyledInfoCircle = styled(InfoCircle)`
  width: 14px;
  height: 14px;
  display: inline-block;
  cursor: pointer;
  vertical-align: -2px;
  color: ${({ theme }) => theme.colors.grayDark};
`;

const Separator = styled.div`
  height: 1.5px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.lightGray1};
`;

type Props = {
  fromSignup?: boolean;
};

const TextContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${homeSpacers.g3};
  ${flexGapRow(homeSpacers.g1)};
  @media ${breakpoints.mobileLarge} {
    padding-top: ${homeSpacers.g1};
    ${flexGapRow('0')};
  }
`;

const GeniusSingleScreen = ({
  fromSignup = false,
}: Props): React.ReactElement => {
  const { updateCurrentGeniusPage } = useNavigateFunction();

  const dispatch = useDispatch<WEB.Dispatch>();

  const [showInfoModal, setShowInfoModal] = React.useState(false);
  const [selectedPlan, setSelectedPlan] = React.useState<
    'none' | 'annual' | 'monthly'
  >('none');
  const [stagedPlan, setStagedPlan] = React.useState<'annual' | 'monthly'>(
    'annual'
  );
  const [showPlansModal, setShowPlansModal] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isSubmittingModal, setIsSubmittingModal] = React.useState(false);
  const [isCompleted, setIsCompleted] = React.useState(false);

  const [primaryChecking] = useSelector(appData.getPrimaryCheckingAccounts);

  const geniusLanguage = useSelector(language.getGeniusLanguage);
  const languageCtx = useSelector((state: WEB.RootState) => {
    return language.getGeniusPageLanguage(state, Pages.GENIUS_TOUR);
  });
  const annualBonus = useSelector(genius.getAnnualBonus);
  const isPFM = useSelector((state: WEB.RootState) =>
    language.getSignupFlag(state, 'pfm')
  );

  const showTierPricing = useSelector((state: WEB.RootState) =>
    profile.getValueByField(state, 'has_tiered_genius_pricing')
  );

  const tieredPricing: GeniusTieredPricing = useSelector(
    (state: WEB.RootState) => profile.getValueByField(state, 'tiered_pricing')
  );

  const defaultAnnual = Boolean(
    geniusLanguage.pricing?.details?.default_annual
  );

  const showDrawer = Boolean(geniusLanguage.pricing?.show_drawer);

  const showLongDisclosure = Boolean(
    geniusLanguage.pricing?.show_long_disclosure
  );

  const languageOrder = useSelector(language.getSignupFlowOrder);

  const geniusIndex = languageOrder.findIndex((subflow) =>
    subflow.pages.includes(Pages.GENIUS_TOUR)
  );

  const geniusNextPage =
    languageOrder[geniusIndex + 1]?.pages?.[0] || Pages.DOWNLOAD_APP;

  const highestBalance = primaryChecking?.balance || 0;
  let defaultGeniusPrice = geniusLanguage?.pricing?.default;

  const defaultTierPricing = useSelector(profile.getDefaultTierPrice);

  defaultGeniusPrice =
    (showTierPricing && defaultTierPricing) || defaultGeniusPrice;

  const expanders =
    ((languageCtx?.content?.agreement as any)
      ?.expanders as WEB.LanguageExpander[]) ?? [];
  const benefits = (languageCtx?.content?.benefits as { text: string }[]) ?? [];

  const MONTHLY_SECTION = 'monthly';
  const YEARLY_SECTION = 'yearly';

  const yearlySection: Plan = getLanguageSection(languageCtx, YEARLY_SECTION);
  const monthlySection: Plan = getLanguageSection(languageCtx, MONTHLY_SECTION);
  const noYearlySection = yearlySection?.price === 'None';

  const longAgreementSection = languageCtx?.content?.long_agreement as
    | LongAgreement
    | undefined;

  // Set default staged plan based on user's current plan
  React.useEffect(() => {
    setStagedPlan(defaultAnnual ? 'annual' : 'monthly');
  }, [defaultAnnual]);

  // Set selectedPlan to monthly if there is no yearly plan
  React.useEffect(() => {
    if (noYearlySection) {
      setSelectedPlan('monthly');
    }
  }, [noYearlySection]);

  const handleSubmit = async ({ fromModal }: { fromModal?: boolean } = {}) => {
    // Use staged plan if submitting from modal
    const plan = fromModal ? stagedPlan : selectedPlan;

    const selectedPrice =
      plan === 'monthly'
        ? Number.parseFloat(monthlySection.price)
        : Number.parseFloat(yearlySection.price);

    const paymentFrequency =
      plan === 'monthly' ? PaymentFrequency.MONTHLY : PaymentFrequency.ANNUAL;

    // Remove any error banner from previous attempts
    dispatch(removeBanner());

    try {
      setIsSubmitting(true);
      setIsSubmittingModal(true);

      const isAnnualSubscription = paymentFrequency === PaymentFrequency.ANNUAL;

      const geniusError = await dispatch(
        enableGenius({
          genius_enabled: true,
          number_months_free: 0,
          default_price: defaultGeniusPrice,
          monthly_price: selectedPrice,
          completed_onboarding: true,
          payment_frequency: paymentFrequency,
          agreed_to_receipt: true,
          tier_key: showTierPricing ? tieredPricing.default_tier : undefined,
          flow_type: 'single_annual_monthly_screen',
          source: 'signup',
          set_up_annual_payment: isAnnualSubscription,
        })
      );

      // Handle the enable genius request response
      if (geniusError) throw new Error();

      // Update genius pricing if annual bonus present
      const pricingPromise = isAnnualSubscription
        ? dispatch(
            updateGeniusPricing({
              savings_bonus: annualBonus,
            })
          )
        : Promise.resolve();

      // We are intentionally calling `pricingPromise` and `profilePromise` sequentially and not using Promise.all
      const pricingResult = await pricingPromise;
      const profilePromise = dispatch(
        updateProfile({
          completed_genius_signup: true,
        })
      );
      const profileResult = await profilePromise;

      setIsSubmitting(false);
      setIsSubmittingModal(false);

      if (highestBalance > 100) {
        TrackingBase.track({
          event:
            AlbertTrackingEvent.ENABLED_GENIUS_PRIMARY_CHECKING_GREATER_THAN_100,
          purchaseAmount: selectedPrice,
          trackSocial: true,
          trackOnce: true,
        });
      }

      // Handle the genius pricing request response if sent
      if (isAnnualSubscription) {
        const pricingError = pricingResult;

        if (pricingError) throw new Error();

        TrackingBase.track({
          event: AlbertTrackingEvent.ENABLED_GENIUS_ANNUAL,
          purchaseAmount: selectedPrice,
          trackSocial: true,
          trackOnce: true,
        });
      }

      // Handle the update profile request response
      const { response: updateProfileResponse, error } = profileResult;
      if (error) throw new Error();

      const profile = (updateProfileResponse.payload?.data ||
        {}) as WEB.Profile;
      const pricingData = profile?.pricing_data;

      // Notify user if any error while saving annual bonus
      if (isAnnualSubscription && pricingData?.error_enabling_annual_payment) {
        dispatch(
          addBanner(
            NotificationTypes.WARNING,
            'There was an error setting up your yearly plan. Please text Genius.'
          )
        );
      } else {
        await delayWithState(400, setIsCompleted);

        dispatch(
          updateCurrentGeniusPage({
            // Since this is a single screen flow, override next page to be the one after the entire genius subflow
            page: geniusNextPage,
            fromSignup,
          })
        );
      }
    } catch (e) {
      setIsSubmitting(false);
      setIsSubmittingModal(false);
    } finally {
      setShowPlansModal(false);
    }
  };

  const handleOnContinue = async () => {
    // If user has not selected a plan, show pricing plan modal
    if (selectedPlan === 'none') {
      // Indicate that the user is submitting the form
      setIsSubmitting(true);
      setShowPlansModal(true);
      return;
    }

    return handleSubmit();
  };

  const handleOnSavePlan = async () => {
    // If this is the final step, submit the form
    if (isSubmitting) {
      setIsSubmittingModal(true);
      return handleSubmit({ fromModal: true });
    }

    // Otherwise, save the plan and close the modal
    setSelectedPlan(stagedPlan);
    setShowPlansModal(false);
  };

  const selectPlanSection = (
    <>
      <PricingPlan
        plan={yearlySection}
        isSelected={stagedPlan === 'annual'}
        onSelect={() => setStagedPlan('annual')}
      />
      <Spacer space={homeSpacers.g4} />
      <PricingPlan
        plan={monthlySection}
        isSelected={stagedPlan === 'monthly'}
        onSelect={() => setStagedPlan('monthly')}
      />
    </>
  );

  const showAnnualPlan = stagedPlan === 'annual';
  const leadText = showAnnualPlan
    ? yearlySection?.intro
    : monthlySection?.intro;
  const planTable = (
    <>
      <StyledTableRow>
        <div>
          <Text weight='700'>
            Total due {languageCtx.info.dueDate || getDueDate()}
          </Text>
          {showAnnualPlan && (
            <Text color={TextColor.GRAY} size={TextSizes.SMALL}>
              12 months x ${Number.parseFloat(yearlySection.price).toFixed(2)}
            </Text>
          )}
        </div>
        <Text weight='700'>
          $
          {(showAnnualPlan
            ? Number.parseFloat(yearlySection.price) * 12
            : Number.parseFloat(monthlySection.price)
          ).toFixed(2)}
        </Text>
      </StyledTableRow>
      <Spacer space={spacers.tiny} />
      <StyledTableRow>
        <Text weight='700' inline>
          Total due today
        </Text>
        <Text weight='700'>$0</Text>
      </StyledTableRow>
      <Spacer space={homeSpacers.g6} />
    </>
  );

  const disclosureSection = longAgreementSection ? (
    <DetailsContainer>
      <Text size='small' weight='600'>
        {longAgreementSection.subscription_details.title}
      </Text>
      <DetailsMarkdown>
        {injectTemplateStrings(
          isSubmitting
            ? longAgreementSection.subscription_details.save_plan_body ??
                longAgreementSection.subscription_details.body
            : longAgreementSection.subscription_details.body,
          {
            price: `$${Number.parseFloat(
              showAnnualPlan ? yearlySection.price : monthlySection.price
            ).toFixed(2)}`,
            payment_frequency: showAnnualPlan ? 'yearly' : 'monthly',
          }
        )}
      </DetailsMarkdown>
      <DetailsFooter size='small' onClick={() => setShowInfoModal(true)}>
        {longAgreementSection.subscription_details.footer} <StyledInfoCircle />
      </DetailsFooter>
    </DetailsContainer>
  ) : null;

  const PlanModal = showDrawer ? DrawerModal : BasicModal;

  const BottomSection = showLongDisclosure
    ? FixedBottomSection
    : UnfixedBottomSection;

  return (
    <>
      <PlanModal
        title='Select your plan'
        submitLabel='Save plan'
        submitDisclosure={
          isSubmitting && showLongDisclosure
            ? longAgreementSection?.save_plan_text
            : undefined
        }
        show={showPlansModal}
        onSubmit={handleOnSavePlan}
        onCancel={() => {
          setShowPlansModal(false);
          setIsSubmitting(false);
        }}
        isSubmitting={isSubmittingModal}
        customTitlePadding='0px'
        stickyFooter={isSubmitting && showLongDisclosure}
      >
        <div>
          <Spacer space={homeSpacers.g8} />
          <AnnualImage>
            <SavingsBonusLottie
              amount={30}
              caption='YEARLY SAVINGS'
              gray={!showAnnualPlan}
              textSize='small'
              height={80}
            />
          </AnnualImage>
          <Spacer space={homeSpacers.g8} />
          {selectPlanSection}
          <Spacer space={homeSpacers.g4} />
          <Separator />
          <Spacer space={homeSpacers.g4} />
          {planTable}
          {showLongDisclosure && isSubmitting && (
            <>
              {disclosureSection}
              <Spacer space={homeSpacers.g4} />
            </>
          )}
        </div>
      </PlanModal>
      <ModalExpanderContent
        expanders={expanders}
        showModal={showInfoModal}
        setHideModal={() => setShowInfoModal(false)}
      />
      <PageBase>
        <Centered>
          <img height={isPFM ? 70 : 80} src={ArtMap(Art.GeniusPopper)} alt='' />
          <Spacer space={homeSpacers.g2} />
          <Header mobileHeaderSize='small'>
            {languageCtx?.content?.intro?.header || ''}
          </Header>
          <TextContentContainer>
            {leadText.map((txt: string, i: number) => (
              <React.Fragment key={i}>
                <Text>{txt}</Text>
              </React.Fragment>
            ))}
          </TextContentContainer>
          <Spacer space={homeSpacers.g3} />
          {noYearlySection ? (
            <LineSeparator />
          ) : (
            <LinkText onClick={() => setShowPlansModal(true)}>
              See all plans
            </LinkText>
          )}
        </Centered>
        <Spacer desktopOnly space={homeSpacers.g2} />
        <Spacer mobileOnly space={homeSpacers.g4} />
        <div style={{ width: 'fit-content', margin: 'auto' }}>
          <CheckCircleList items={benefits} mobileSpace='2px' />
        </div>
        {showLongDisclosure && (
          <>
            <Spacer space={homeSpacers.g4} />
            {disclosureSection}
            <Spacer space='140px' />
          </>
        )}
        <BottomSection>
          <StyledDisclaimer
            color={TextColor.GRAY_DARK}
            onClick={
              showLongDisclosure ? undefined : () => setShowInfoModal(true)
            }
          >
            {showLongDisclosure ? (
              <MarkdownContent>{longAgreementSection?.text}</MarkdownContent>
            ) : (
              <>
                {languageCtx?.content?.agreement?.text} <StyledInfoCircle />
              </>
            )}
          </StyledDisclaimer>
          <ButtonContainer>
            <Button
              isCompleted={isCompleted}
              isLoading={isSubmitting}
              disabled={isSubmitting}
              onClick={handleOnContinue}
              theme={theme.genius}
            >
              {languageCtx?.buttons?.primary}
            </Button>
          </ButtonContainer>
        </BottomSection>
      </PageBase>
    </>
  );
};

type Plan = {
  title: string;
  intro: string[];
  description: string;
  // `price` is a string representation of a number
  price: string;
  label?: string;
};

type LongAgreement = {
  text: string;
  save_plan_text: string;
  subscription_details: {
    title: string;
    body: string;
    save_plan_body?: string;
    footer: string;
  };
};

function PricingPlan({
  plan,
  isSelected,
  onSelect,
}: {
  plan: Plan;
  isSelected: boolean;
  onSelect?: () => void;
}) {
  const color = isSelected ? 'black' : 'gray';

  return (
    <StyledTableRow onClick={onSelect} disabled={!isSelected}>
      <div>
        <AlignContainer>
          <Text color={color} size='large' weight='700'>
            {plan.title}
          </Text>
          {plan.label && <Badge color={color}>{plan.label}</Badge>}
        </AlignContainer>
        <Text color={color}>{plan.description}</Text>
      </div>
      <StyledCheckCircle color={color} noFill={!isSelected} />
    </StyledTableRow>
  );
}

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

// 30 days from now
function getDueDate() {
  const date = new Date();
  date.setDate(date.getDate() + 30);

  const month = monthNames[date.getMonth()];
  const day = String(date.getDate()).padStart(2, '0');
  const dueDate = `${month} ${day}`;

  return dueDate;
}

// Replace `{{key}}` with `context[key]` in `lang`
function injectTemplateStrings(lang: string, context: Record<string, string>) {
  return lang.replace(/\{\{(.+?)\}\}/g, (_, key) => context[key]);
}

function MarkdownContent({ children, ...props }: Readonly<Options>) {
  return (
    <ReactMarkdown
      components={{
        a: ({ href, children, ...props }) => (
          <a href={href} target='_blank' rel='noopener noreferrer' {...props}>
            {children}
          </a>
        ),
      }}
      {...props}
    >
      {children}
    </ReactMarkdown>
  );
}

export default GeniusSingleScreen;
