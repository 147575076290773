import { FORM_ERROR } from 'final-form';
import * as React from 'react';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import updateProfile from 'actions/async/updateProfile';
import removeBanner from 'actions/banner/removeBanner';
import Button from 'components/common/Button';
import Dropdown from 'components/common/Dropdown';
import Input from 'components/common/Input';
import { InputTypes } from 'components/common/Input/TextInput';
import Spacer from 'components/common/Spacer';
import ButtonsLayout from 'components/layout/ButtonsLayout';
import LeadContent from 'components/layout/LeadContent';
import PageBase from 'components/layout/PageBase';
import Pages from 'constants/Pages';
import useNavigateFunction from 'hooks/useNavigateFunction';
import * as language from 'reducers/entities/language';
import * as profile from 'reducers/entities/profile';
import { spacers } from 'styles/home';
import * as WEB from 'types/interfaces';
import { InputFieldProps } from 'types/interfaces';
import { delayWithState } from 'utils/delay';
import { formatDropdownOptions } from 'utils/dropdown';
import { getLanguageButtons, getLanguageSection } from 'utils/getFromLanguage';
import { isTruthyOrZero } from 'utils/validation';
import {
  composeValidators,
  isNotEmpty,
  isRequired,
  minLen,
} from 'utils/validators';

type Props = {
  nextPage: string;
};

const AddTrustedContactInfo = (props: Props) => {
  const { updateCurrentSignupPage } = useNavigateFunction();
  const dispatch = useDispatch();
  const [isCompleted, setIsCompleted] = React.useState<boolean>(false);
  const [submitAttempted, setSubmitAttempted] = React.useState<boolean>(false);

  const languageCtx = useSelector((state: WEB.RootState) =>
    language.getSignupPageLanguage(state, Pages.ADD_TRUSTED_CONTACT_INFO)
  );
  const defaultFirstName = useSelector((state: WEB.RootState) =>
    profile.getInfoValueByField(
      state,
      'investment_info',
      'trusted_contact_given_name'
    )
  );
  const defaultLastName = useSelector((state: WEB.RootState) =>
    profile.getInfoValueByField(
      state,
      'investment_info',
      'trusted_contact_family_name'
    )
  );
  const defaultPhoneNumber = useSelector((state: WEB.RootState) =>
    profile.getInfoValueByField(
      state,
      'investment_info',
      'trusted_contact_phone_number'
    )
  );
  const defaultPhoneType = useSelector((state: WEB.RootState) =>
    profile.getInfoValueByField(
      state,
      'investment_info',
      'trusted_contact_phone_number_type'
    )
  );

  const [phoneType, setPhoneType] = React.useState<number | null>(
    isTruthyOrZero(defaultPhoneType) ? defaultPhoneType : null
  );
  const firstNameSection = getLanguageSection(languageCtx, 'firstName');
  const lastNameSection = getLanguageSection(languageCtx, 'lastName');
  const phoneNumberSection = getLanguageSection(languageCtx, 'phoneNumber');
  const phoneTypeSection = getLanguageSection(languageCtx, 'phoneType');

  const phoneTypeOptions = (phoneTypeSection?.options || []) as {
    display: string;
    value: number;
  }[];

  // Formatted data for dropdowns
  const [phoneTypeItems, phoneTypeDefaultDisplay] = formatDropdownOptions(
    phoneTypeOptions,
    defaultPhoneType
  );

  const introCtx = getLanguageSection(languageCtx, 'intro');
  const buttonsCtx = getLanguageButtons(languageCtx);

  const handlePhoneTypeOnChange = ({ key }: { key: number }): void => {
    let value: number | null = phoneTypeOptions[key]?.value;
    if (!isTruthyOrZero(value)) {
      value = null;
    }
    setPhoneType(value);
  };

  const onSubmit = async (values: Record<string, any>) => {
    // Remove any error banner from previous attempts
    dispatch(removeBanner());

    // Check validity for manually validated input
    if (phoneType === null) {
      return { [FORM_ERROR]: 'error' };
    }

    const { error }: any = await dispatch(
      updateProfile({
        investment_info: {
          trusted_contact_given_name: values.firstName?.trim(),
          trusted_contact_family_name: values.lastName?.trim(),
          trusted_contact_phone_number: values.phoneNumber,
          trusted_contact_phone_number_type: phoneType,
        },
      })
    );
    if (error) return { [FORM_ERROR]: 'error' };
  };

  const submitCallback = async (errors?: unknown) => {
    if (!errors) {
      await delayWithState(400, setIsCompleted);
      dispatch(updateCurrentSignupPage({ page: props.nextPage }));
    }
  };

  const FirstNameInput = ({ input, meta }: Partial<WEB.InputFieldProps>) => {
    return (
      <Input.Text
        id='first-name-input'
        type='text'
        label={firstNameSection?.header}
        floatingLabel={firstNameSection?.placeholder}
        placeholder={firstNameSection?.placeholder}
        {...input}
        invalid={meta?.error && meta.submitFailed && !meta.dirtySinceLastSubmit}
        errorText={firstNameSection?.errorMessage || meta?.error}
      />
    );
  };

  const LastNameInput = ({ input, meta }: Partial<WEB.InputFieldProps>) => {
    return (
      <Input.Text
        id='last-name-input'
        type='text'
        floatingLabel={lastNameSection?.placeholder}
        placeholder={lastNameSection?.placeholder}
        {...input}
        invalid={meta?.error && meta.submitFailed && !meta.dirtySinceLastSubmit}
        errorText={lastNameSection?.errorMessage || meta?.error}
      />
    );
  };

  const PhoneNumberInput = ({
    input,
    inputOnChange,
    meta,
  }: Partial<InputFieldProps>) => {
    const invalid =
      meta?.error && meta?.submitFailed && !meta.dirtySinceLastSubmit;

    const inputProps = {
      ...input,
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        input?.onChange(e);
        inputOnChange && inputOnChange(e);
      },
    };

    return (
      <Input.Text
        id='phone-number'
        type={InputTypes.PHONE}
        label={phoneNumberSection?.header}
        floatingLabel={phoneNumberSection?.text}
        placeholder={phoneNumberSection?.text}
        {...inputProps}
        invalid={!!invalid}
        errorText={phoneNumberSection?.errorMessage || meta?.error}
      />
    );
  };

  return (
    <PageBase>
      <LeadContent
        header={introCtx?.header || ''}
        text={introCtx?.text || ''}
        desktopHeaderSize='small'
      />
      <Form
        onSubmit={onSubmit}
        initialValues={{
          firstName: defaultFirstName,
          lastName: defaultLastName,
          phoneNumber: defaultPhoneNumber,
          phoneType: defaultPhoneType,
        }}
        render={({ handleSubmit, submitting }) => (
          <form
            onSubmit={(event) => {
              setSubmitAttempted(true);
              handleSubmit(event)?.then(submitCallback);
            }}
          >
            <Spacer space={spacers.g6} desktopOnly />
            <Field
              name='firstName'
              component={FirstNameInput}
              validate={composeValidators(isRequired, isNotEmpty)}
            />
            <Spacer space={spacers.g5} desktopOnly />
            <Field
              name='lastName'
              component={LastNameInput}
              validate={composeValidators(isRequired, isNotEmpty)}
            />
            <Spacer space={spacers.g11} desktopOnly />
            <Field
              name='phoneNumber'
              label={phoneNumberSection?.header}
              component={PhoneNumberInput}
              validate={composeValidators(minLen(14), isRequired)}
            />
            <Spacer space={spacers.g5} desktopOnly />
            <Dropdown.Menu
              id='phone-type'
              defaultValue={phoneTypeDefaultDisplay}
              floatingLabel={phoneTypeSection?.placeholder}
              items={phoneTypeItems}
              invalid={submitAttempted && phoneType === null}
              errorText={phoneTypeSection?.errorMessage}
              onSelect={handlePhoneTypeOnChange}
              showError
            />
            <ButtonsLayout
              marginTop={spacers.g9}
              primaryButton={
                <Button
                  isLoading={submitting}
                  disabled={submitting}
                  isCompleted={isCompleted}
                >
                  {isCompleted ? '' : buttonsCtx?.primary}
                </Button>
              }
            />
          </form>
        )}
      />
    </PageBase>
  );
};

export default AddTrustedContactInfo;
