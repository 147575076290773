// Libraries
import * as React from 'react';

const threshold = 50;

type Input = {
  onSwipeLeft: () => void;
  onSwipeRight: () => void;
};

const useSwipe = ({ onSwipeLeft, onSwipeRight }: Input): void => {
  const touchStartRef = React.useRef(0);

  React.useEffect(() => {
    const handleTouchStart = (e: TouchEvent) => {
      touchStartRef.current = e.targetTouches[0].clientX;
    };

    const handleTouchEnd = (e: TouchEvent) => {
      const touchEnd = e.changedTouches[0].clientX;
      const xDistance = touchStartRef.current - touchEnd;

      if (xDistance > threshold) {
        onSwipeLeft();
      } else if (xDistance < -threshold) {
        onSwipeRight();
      }
    };
    window.addEventListener('touchstart', handleTouchStart);
    window.addEventListener('touchend', handleTouchEnd);
    return () => {
      window.removeEventListener('touchstart', handleTouchStart);
      window.removeEventListener('touchend', handleTouchEnd);
    };
  }, [onSwipeLeft, onSwipeRight]);
};

export default useSwipe;
