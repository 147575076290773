import { logger } from 'utils/logger';

export enum InstitutionsArt {
  AES,
  Acorns,
  Albert,
  AmericanExpress,
  Ameriprise,
  Avant,
  BBT,
  BankIconCircleWhite,
  BankOfAmerica,
  Barclays,
  Betterment,
  CapitalOne,
  CapitalOneWide,
  Chase,
  Chime,
  Citibank,
  Citizens,
  CommonBond,
  Digit,
  Discover,
  ETrade,
  Earnest,
  EdFinancial,
  EdwardJones,
  FSA,
  FedLoan,
  Fidelity,
  FifthThird,
  Geico,
  GreatLakes,
  HSBC,
  HuntingtonBank,
  Lemonade,
  LendingClub,
  MerrillLynch,
  MorganStanley,
  NFCU,
  Navient,
  Nelnet,
  PNC,
  Pave,
  Payoff,
  Progressive,
  Prosper,
  ProsperWide,
  QuickenLoans,
  Quovo,
  Regions,
  Robinhood,
  Schwab,
  Scottrade,
  Sofi,
  StateFarm,
  Suntrust,
  TDAmeritrade,
  TIAACREF,
  TRowePrice,
  Tangerine,
  USAA,
  USBank,
  Upstart,
  Vanguard,
  Vouch,
  Wealthfront,
  WellsFargo,
}

export const NORMALIZING_MAP: Record<string, number> = {
  AES: InstitutionsArt.AES,
  Acorns: InstitutionsArt.Acorns,
  Albert: InstitutionsArt.Albert,
  AmericanExpress: InstitutionsArt.AmericanExpress,
  Ameriprise: InstitutionsArt.Ameriprise,
  Avant: InstitutionsArt.Avant,
  BBT: InstitutionsArt.BBT,
  BankIconCircleWhite: InstitutionsArt.BankIconCircleWhite,
  BankOfAmerica: InstitutionsArt.BankOfAmerica,
  Barclays: InstitutionsArt.Barclays,
  Betterment: InstitutionsArt.Betterment,
  CapitalOne: InstitutionsArt.CapitalOne,
  CapitalOneWide: InstitutionsArt.CapitalOneWide,
  Chase: InstitutionsArt.Chase,
  Chime: InstitutionsArt.Chime,
  ChimeBank: InstitutionsArt.Chime,
  Citibank: InstitutionsArt.Citibank,
  Citi: InstitutionsArt.Citibank,
  Citizens: InstitutionsArt.Citizens,
  CommonBond: InstitutionsArt.CommonBond,
  Digit: InstitutionsArt.Digit,
  Discover: InstitutionsArt.Discover,
  ETrade: InstitutionsArt.ETrade,
  Earnest: InstitutionsArt.Earnest,
  EdFinancial: InstitutionsArt.EdFinancial,
  EdwardJones: InstitutionsArt.EdwardJones,
  FSA: InstitutionsArt.FSA,
  FedLoan: InstitutionsArt.FedLoan,
  Fidelity: InstitutionsArt.Fidelity,
  FifthThird: InstitutionsArt.FifthThird,
  Geico: InstitutionsArt.Geico,
  GreatLakes: InstitutionsArt.GreatLakes,
  HSBC: InstitutionsArt.HSBC,
  HuntingtonBank: InstitutionsArt.HuntingtonBank,
  Lemonade: InstitutionsArt.Lemonade,
  LendingClub: InstitutionsArt.LendingClub,
  MerrillLynch: InstitutionsArt.MerrillLynch,
  MorganStanley: InstitutionsArt.MorganStanley,
  NFCU: InstitutionsArt.NFCU,
  Navient: InstitutionsArt.Navient,
  Nelnet: InstitutionsArt.Nelnet,
  PNC: InstitutionsArt.PNC,
  Pave: InstitutionsArt.Pave,
  Payoff: InstitutionsArt.Payoff,
  Progressive: InstitutionsArt.Progressive,
  Prosper: InstitutionsArt.Prosper,
  ProsperWide: InstitutionsArt.ProsperWide,
  QuickenLoans: InstitutionsArt.QuickenLoans,
  Quovo: InstitutionsArt.Quovo,
  Regions: InstitutionsArt.Regions,
  Robinhood: InstitutionsArt.Robinhood,
  Schwab: InstitutionsArt.Schwab,
  Scottrade: InstitutionsArt.Scottrade,
  Sofi: InstitutionsArt.Sofi,
  StateFarm: InstitutionsArt.StateFarm,
  Suntrust: InstitutionsArt.Suntrust,
  TDAmeritrade: InstitutionsArt.TDAmeritrade,
  TdBank: InstitutionsArt.TDAmeritrade,
  TIAACREF: InstitutionsArt.TIAACREF,
  TRowePrice: InstitutionsArt.TRowePrice,
  Tangerine: InstitutionsArt.Tangerine,
  USAA: InstitutionsArt.USAA,
  USBank: InstitutionsArt.USBank,
  Upstart: InstitutionsArt.Upstart,
  Vanguard: InstitutionsArt.Vanguard,
  Vouch: InstitutionsArt.Vouch,
  Wealthfront: InstitutionsArt.Wealthfront,
  WellsFargo: InstitutionsArt.WellsFargo,
};

export const INSTITUTIONS_ART_FILE_MAP: Record<number, string> = {
  [InstitutionsArt.AES]: '/images/institutions/AES.png',
  [InstitutionsArt.Acorns]: '/images/institutions/Acorns.png',
  [InstitutionsArt.Albert]: '/images/institutions/Albert.png',
  [InstitutionsArt.AmericanExpress]: '/images/institutions/AmericanExpress.png',
  [InstitutionsArt.Ameriprise]: '/images/institutions/Ameriprise.png',
  [InstitutionsArt.Avant]: '/images/institutions/Avant.png',
  [InstitutionsArt.BBT]: '/images/institutions/BBT.png',
  [InstitutionsArt.BankIconCircleWhite]:
    '/images/institutions/BankIconCircleWhite.png',
  [InstitutionsArt.BankOfAmerica]: '/images/institutions/BankOfAmerica.png',
  [InstitutionsArt.Barclays]: '/images/institutions/Barclays.png',
  [InstitutionsArt.Betterment]: '/images/institutions/Betterment.png',
  [InstitutionsArt.CapitalOne]: '/images/institutions/CapitalOne.png',
  [InstitutionsArt.CapitalOneWide]: '/images/institutions/CapitalOneWide.png',
  [InstitutionsArt.Chase]: '/images/institutions/Chase.png',
  [InstitutionsArt.Chime]: '/images/institutions/Chime.png',
  [InstitutionsArt.Citibank]: '/images/institutions/Citibank.png',
  [InstitutionsArt.Citizens]: '/images/institutions/Citizens.png',
  [InstitutionsArt.CommonBond]: '/images/institutions/CommonBond.png',
  [InstitutionsArt.Digit]: '/images/institutions/Digit.png',
  [InstitutionsArt.Discover]: '/images/institutions/Discover.png',
  [InstitutionsArt.ETrade]: '/images/institutions/ETrade.png',
  [InstitutionsArt.Earnest]: '/images/institutions/Earnest.png',
  [InstitutionsArt.EdFinancial]: '/images/institutions/EdFinancial.png',
  [InstitutionsArt.EdwardJones]: '/images/institutions/EdwardJones.png',
  [InstitutionsArt.FSA]: '/images/institutions/FSA.png',
  [InstitutionsArt.FedLoan]: '/images/institutions/FedLoan.png',
  [InstitutionsArt.Fidelity]: '/images/institutions/Fidelity.png',
  [InstitutionsArt.FifthThird]: '/images/institutions/FifthThird.png',
  [InstitutionsArt.Geico]: '/images/institutions/Geico.png',
  [InstitutionsArt.GreatLakes]: '/images/institutions/GreatLakes.png',
  [InstitutionsArt.HSBC]: '/images/institutions/HSBC.png',
  [InstitutionsArt.HuntingtonBank]: '/images/institutions/HuntingtonBank.png',
  [InstitutionsArt.Lemonade]: '/images/institutions/Lemonade.png',
  [InstitutionsArt.LendingClub]: '/images/institutions/LendingClub.png',
  [InstitutionsArt.MerrillLynch]: '/images/institutions/MerrillLynch.png',
  [InstitutionsArt.MorganStanley]: '/images/institutions/MorganStanley.png',
  [InstitutionsArt.NFCU]: '/images/institutions/NFCU.png',
  [InstitutionsArt.Navient]: '/images/institutions/Navient.png',
  [InstitutionsArt.Nelnet]: '/images/institutions/Nelnet.png',
  [InstitutionsArt.PNC]: '/images/institutions/PNC.png',
  [InstitutionsArt.Pave]: '/images/institutions/Pave.png',
  [InstitutionsArt.Payoff]: '/images/institutions/Payoff.png',
  [InstitutionsArt.Progressive]: '/images/institutions/Progressive.png',
  [InstitutionsArt.Prosper]: '/images/institutions/Prosper.png',
  [InstitutionsArt.ProsperWide]: '/images/institutions/ProsperWide.png',
  [InstitutionsArt.QuickenLoans]: '/images/institutions/QuickenLoans.png',
  [InstitutionsArt.Quovo]: '/images/institutions/Quovo.png',
  [InstitutionsArt.Regions]: '/images/institutions/Regions.png',
  [InstitutionsArt.Robinhood]: '/images/institutions/Robinhood.png',
  [InstitutionsArt.Schwab]: '/images/institutions/Schwab.png',
  [InstitutionsArt.Scottrade]: '/images/institutions/Scottrade.png',
  [InstitutionsArt.Sofi]: '/images/institutions/Sofi.png',
  [InstitutionsArt.StateFarm]: '/images/institutions/StateFarm.png',
  [InstitutionsArt.Suntrust]: '/images/institutions/Suntrust.png',
  [InstitutionsArt.TDAmeritrade]: '/images/institutions/TDAmeritrade.png',
  [InstitutionsArt.TIAACREF]: '/images/institutions/TIAACREF.png',
  [InstitutionsArt.TRowePrice]: '/images/institutions/TRowePrice.png',
  [InstitutionsArt.Tangerine]: '/images/institutions/Tangerine.png',
  [InstitutionsArt.USAA]: '/images/institutions/USAA.png',
  [InstitutionsArt.USBank]: '/images/institutions/USBank.png',
  [InstitutionsArt.Upstart]: '/images/institutions/Upstart.png',
  [InstitutionsArt.Vanguard]: '/images/institutions/Vanguard.png',
  [InstitutionsArt.Vouch]: '/images/institutions/Vouch.png',
  [InstitutionsArt.Wealthfront]: '/images/institutions/Wealthfront.png',
  [InstitutionsArt.WellsFargo]: '/images/institutions/WellsFargo.png',
};

const InstitutionsArtMap = (query: string | InstitutionsArt): string | null => {
  if (typeof query === 'number') {
    return INSTITUTIONS_ART_FILE_MAP[query];
  }

  const path = INSTITUTIONS_ART_FILE_MAP[NORMALIZING_MAP[query]];
  if (!path) {
    logger.log(`Error: non-mapped institution icon image ${query} request.`);
    return null;
  }
  return path;
};

export default InstitutionsArtMap;
