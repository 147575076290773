import { FORM_ERROR } from 'final-form';
import * as React from 'react';
import { Field, FieldRenderProps, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import createProperty from 'actions/async/createProperty';
import updateProperty from 'actions/async/updateProperty';
import removeBanner from 'actions/banner/removeBanner';
import Button from 'components/common/Button';
import AddressTypeahead from 'components/common/Dropdown/AddressTypeahead';
import Input from 'components/common/Input';
import Radio from 'components/common/Radio';
import Spacer from 'components/common/Spacer';
import ButtonsContainer from 'components/layout/ButtonsContainer';
import InfoPane from 'components/layout/InfoPane';
import PageBase from 'components/layout/PageBase';
import Pages from 'constants/Pages';
import useNavigateFunction from 'hooks/useNavigateFunction';
import useVerifyCurrentPage from 'hooks/useVerifyCurrentPage';
import * as appData from 'reducers/entities/appData';
import * as language from 'reducers/entities/language';
import { spacers } from 'styles';
import * as WEB from 'types/interfaces';
import { delayWithState } from 'utils/delay';
import {
  getLanguageButtons,
  getLanguageInfo,
  getLanguageSection,
} from 'utils/getFromLanguage';
import { composeValidators, isRequired } from 'utils/validators';

type Props = {
  nextPage: string;
};

/**
 * DEPRECATED
 **/
const Address = (props: Props): React.ReactElement => {
  // Make sure Redux is up-to-date with currentSignUpPage
  useVerifyCurrentPage(Pages.ADDRESS);

  // ///////////////////////////////
  /* =========== HOOKS ========== */
  // ///////////////////////////////
  const dispatch = useDispatch<WEB.Dispatch>();
  const { updateCurrentSignupPage } = useNavigateFunction();

  // ///////////////////////////////
  /* =========== STATE ========== */
  // ///////////////////////////////
  // Unique sessiontoken for Google Place requests.
  const [sessiontoken, setSessiontoken] = React.useState<string>(uuidv4());
  const [isCompleted, setIsCompleted] = React.useState<boolean>(false);

  // ///////////////////////////////
  /* ======== MAPPED STATE ====== */
  // ///////////////////////////////
  const languageCtx = useSelector((state: WEB.RootState) =>
    language.getSignupPageLanguage(state, Pages.ADDRESS)
  );

  const property: WEB.Property | null = useSelector(
    (state: WEB.RootState) =>
      appData.getLegalProperty(state) || appData.getPrimaryProperty(state)
  );

  // //////////////////////////////////
  /* =========== HANDLERS ========== */
  // //////////////////////////////////

  const onSubmit = async (values: Record<string, any>) => {
    // Remove any error banner from previous attempts
    dispatch(removeBanner());

    const placeid = values.address.place_id;
    const apartment = values.apartment || null;
    const own = values.ownership;
    let error = false;

    // If the address is new or different, create a new property
    if (property?.google_placeid !== placeid) {
      const response = await dispatch(
        createProperty(placeid, apartment, own, sessiontoken)
      );
      error = response.error;

      // From Google Places docs: https://developers.google.com/maps/documentation/places/web-service/autocomplete#sessiontoken
      // > The session begins when the user starts typing a query, and concludes when they select a place
      // > and a call to Place Details is made.
      // Reset sessiontoken after a call to Place Details is made.
      setSessiontoken(uuidv4());
    } else if (
      // If the address was previously entered and only the
      // apt/own fields were changed, update the property
      property &&
      (property?.apt !== apartment || property?.own !== own)
    ) {
      error = await dispatch(updateProperty(property.id, apartment, own));
    }

    if (error) return { [FORM_ERROR]: 'error' };
  };

  const submitCallback = async (errors?: any) => {
    if (!errors) {
      await delayWithState(400, setIsCompleted);
      dispatch(updateCurrentSignupPage({ page: props.nextPage }));
    }
  };

  // //////////////////////////////////
  /* =========== LANGUAGE ========== */
  // //////////////////////////////////

  // Section keys
  const ADDRESS_KEY = 'address';
  const APARTMENT_KEY = 'apartment';
  const OWNERSHIP_KEY = 'ownership';

  // Sections
  const addressSection = getLanguageSection(languageCtx, ADDRESS_KEY);
  const apartmentSection = getLanguageSection(languageCtx, APARTMENT_KEY);
  const ownershipSection = getLanguageSection(languageCtx, OWNERSHIP_KEY);

  const ownershipOptions = (ownershipSection?.options || []) as {
    display: string;
    value: boolean;
  }[];

  const infoPaneContext = getLanguageInfo(languageCtx);

  // Buttons
  const buttonsCtx = getLanguageButtons(languageCtx);

  // ///////////////////////////////////////
  /* =========== FORM ELEMENTS ========== */
  // ///////////////////////////////////////

  const AddressInput = ({
    input,
    meta,
  }: FieldRenderProps<any, HTMLElement>) => {
    return (
      <AddressTypeahead
        id='address-typeahead'
        label={addressSection?.header}
        sessiontoken={sessiontoken}
        value={input.value?.description}
        placeholder={addressSection?.placeholder}
        onSelect={input.onChange}
        invalid={meta?.touched && meta.error}
        errorText={meta?.error}
        showError
        fullWidth
      />
    );
  };

  const ApartmentInput = ({ input, meta }: Partial<WEB.InputFieldProps>) => {
    return (
      <Input.Text
        id='apartment-input'
        type='text'
        placeholder={apartmentSection?.placeholder}
        {...input}
        invalid={meta?.touched && meta.error}
        errorText={apartmentSection?.errorMessage || meta?.error}
      />
    );
  };

  const OwnershipInput = ({ input, meta }: Partial<WEB.InputFieldProps>) => {
    return (
      <Radio.Group
        id='ownership-radio-buttons'
        label={ownershipSection?.header}
        invalid={meta?.touched && meta.error}
        errorText={ownershipSection?.errorMessage || meta?.error}
      >
        {ownershipOptions.map((option) => (
          <Radio.Button
            id={`ownership-${option.display}`}
            key={`ownership-${option.display}`}
            name={option.display}
            label={option.display}
            value={option.value}
            selected={input?.value === option.value}
            onClick={input?.onChange}
          />
        ))}
      </Radio.Group>
    );
  };

  return (
    <>
      <InfoPane.Mobile {...infoPaneContext} />
      <PageBase>
        <Form
          id='signup-address'
          onSubmit={onSubmit}
          initialValues={{
            address: property && {
              place_id: property.google_placeid,
              description: property.google_address,
            },
            apartment: property?.apt,
            ownership: property?.own,
          }}
          render={({ handleSubmit, submitting, values }) => (
            <form
              onSubmit={(event) => {
                handleSubmit(event)?.then(submitCallback);
              }}
            >
              <Field
                name='address'
                component={AddressInput}
                validate={composeValidators(isRequired)}
              />
              <Spacer space={spacers.small} />
              <Field name='apartment' component={ApartmentInput} />

              {values.address && (
                <>
                  <Spacer space={spacers.tabLarge} />
                  <Field
                    name='ownership'
                    component={OwnershipInput}
                    validate={composeValidators(isRequired)}
                  />
                </>
              )}

              <Spacer space={spacers.tabLarge} />
              <ButtonsContainer>
                <Button
                  isCompleted={isCompleted}
                  isLoading={submitting}
                  disabled={submitting}
                >
                  {isCompleted ? buttonsCtx?.complete : buttonsCtx?.primary}
                </Button>
              </ButtonsContainer>
            </form>
          )}
        />
      </PageBase>
    </>
  );
};

export default Address;
