import { PlaidEnvironment, PlaidProduct } from 'constants/';
import * as WEB from 'types/interfaces';

export interface OnSuccessMetadata {
  link_session_id: string;
  institution: WEB.PlaidInstitution;
  accounts?: WEB.PlaidInstitutionAccount[];
  request_id?: string;
}

export interface OnEventMetadata {
  link_session_id?: string;
  request_id?: string;
  timestamp?: string;
  view_name?: string;
  error_code?: string;
  error_message?: string;
  error_type?: string;
  exit_status?: string;
  institution_id?: string;
  institution_name?: string;
  institution_search_query?: string;
  mfa_type?: string;
}

export interface OnExitMetadata {
  link_session_id: string;
  request_id: string;
  institution: WEB.PlaidInstitution;
  status: string;
}

/**
 * Get the Plaid environment. Unless the profile preferences denote otherwise, the
 * Plaid environment corresponds to the development environment.
 *
 * NOTE: When node env is 'development' (ie. in dev), we can optionally use the 'development'
 *       Plaid environment. Using 'development' lets us use Plaid on real accounts (limited to 250).
 *
 * @param profilePreferences - override Plaid env based on profilePreferences['plaid_environment']
 * @returns environment (string)
 */
export const getPlaidEnvironment = (
  profilePreferences: WEB.ProfilePreferences
): string => {
  // Return override if it exists and is valid
  const overrideEnv = profilePreferences.plaid_environment;
  if (overrideEnv && PlaidEnvironment[overrideEnv.toUpperCase()]) {
    return overrideEnv;
  }

  // Return Plaid env based on node env
  switch (window.albertWeb.Environment) {
    case 'production': {
      return PlaidEnvironment.PRODUCTION;
    }
    case 'development': {
      // NOTE: swap this to DEVELOPMENT if we want to test on real accounts in dev
      return PlaidEnvironment.SANDBOX;
    }
    case 'local': {
      return PlaidEnvironment.SANDBOX;
    }
    default:
      // Fail safe for whatever reason window.albertWeb.Environment is undefined
      return PlaidEnvironment.SANDBOX;
  }
};

/**
 * Get webhook URL to attach to newly created Plaid Item
 *
 * @param plaidEnvironment - current Plaid environment
 * @param profilePreferences - profile preferences to override if present
 * @returns webhook URL (string)
 */
export const getWebhookUrl = (
  plaidEnvironment: PlaidEnvironment,
  profilePreferences: WEB.ProfilePreferences
): string => {
  let domain = '';

  switch (plaidEnvironment) {
    case PlaidEnvironment.PRODUCTION:
      domain = 'app.albert.com';
      break;
    case PlaidEnvironment.DEVELOPMENT:
      // Dev TEAL
      domain = 'albert-dev-public-main-teal.herokuapp.com';
      break;
    case PlaidEnvironment.SANDBOX:
      domain = 'meetalbert-plaid.ngrok.io';
      break;
    default:
      break;
  }

  // profile.preferences allows us to experiment with plaid link in development
  if (
    profilePreferences.plaid_webhook_domain &&
    profilePreferences.plaid_environment
  ) {
    domain = profilePreferences.plaid_webhook_domain;
  }

  return `https://${domain}/v2/webhook/plaid/`;
};

/**
 * Get Plaid product to request for each Plaid Item
 *
 * @param getAuth - specifically include the auth product (for linking accounts)
 * @returns product (string)
 */
export const getPlaidProduct = (getAuth: boolean): string => {
  return getAuth ? PlaidProduct.AUTH : PlaidProduct.TRANSACTIONS;
};

/**
 * Exit parameters to send to the BE on addInstitutionLogin request
 *
 * @param error - error
 * @param metadata - Plaid item metadata
 * @returns params (object)
 */
export const getExitParameters = (
  error: Error | null,
  metadata: OnExitMetadata
): { [key: string]: string } => {
  const params: { [key: string]: string } = {};

  // Metadata
  if (metadata) {
    const { link_session_id, status, institution, request_id } = metadata;

    params.link_session_id = link_session_id;
    params.status = status;
    // Only append institution_id to params if institution exists
    if (institution) {
      params.institution_id = institution?.institution_id;
    }
    if (request_id) {
      params.request_id = request_id;
    }
  }

  // Error
  if (error) {
    Object.entries(error).forEach(([key, value]: [string, string]) => {
      params[key] = String(value);
    });
  }

  return params;
};
