enum AutoTransferSchedule {
  AUTO = 'a',
  WEEKLY = '7',
  BIWEEKLY = '14',
  MONTHLY = '30',
}

export enum AutoTransferOptions {
  ON = 'on',
  PAUSED = 'paused',
  PENDING = 'pending',
}

export default AutoTransferSchedule;
