import { NavigateFunction } from 'react-router-dom';
import getGeniusLanguage from 'actions/async/getGeniusLanguage';
import textDownloadApp from 'actions/async/textDownloadApp';
import types from 'actions/types';
import { SignupPages } from 'constants/Pages';
import { PageRoutes, Pages } from 'constants/index';
import * as language from 'reducers/entities/language';
import * as WEB from 'types/interfaces';

type Kwargs = {
  /* Page to navigate to. */
  page: string;
  /* Toggle whether or not to change the page in view. Default true. */
  shouldNavTo?: boolean;
  /* Is from signup. */
  fromSignup?: boolean;
  /* Location state to pass with navigation. */
  locationState?: Record<string, any>;
  /* optional query param to include on navigate */
  search?: string;
};

/**
 * Create an action creator to update current genius page state in redux
 * and to navigate the user to the next genius page.
 *
 * @param navigate - Navigate hook.
 * @return {Function} Function that accepts paramsObject (object) - kwargs.
 */
const updateCurrentGeniusPage =
  (navigate: NavigateFunction) =>
  ({
    page,
    shouldNavTo = true,
    fromSignup = false,
    locationState = {},
    search,
  }: Kwargs): WEB.ActionCreator<void> =>
  async (dispatch: WEB.Dispatch, getState: WEB.GetStateFunc) => {
    const updateCurrentGeniusPageAction: WEB.Action = {
      type: types.UPDATE_CURRENT_GENIUS_PAGE,
      payload: { page },
    };

    // If Genius flow completed and we're proceeding the user to the download app page
    if (page === Pages.DOWNLOAD_APP) {
      // Send download app text.
      fromSignup && dispatch(textDownloadApp(true));

      // Navigate to page
      navigate({
        pathname: PageRoutes.DOWNLOAD_APP,
        search: window.location.search,
      });

      // Reset genius page
      updateCurrentGeniusPageAction.payload.page = '';
      dispatch(updateCurrentGeniusPageAction);
      return;
    }

    // If page is signup page, navigate to signup page.
    if (Object.values(SignupPages).includes(page as SignupPages)) {
      const state = getState();
      const route = language.getSignupRouteByPage(state, page as SignupPages);

      // Update signup page.
      // NOTE: Not invoking `updateCurrentSignupPage` action because of circular dependency.
      // TODO: Need to refactor page navigation actions - either consolidate into single file or rethink approach.
      dispatch({
        type: types.UPDATE_CURRENT_SIGNUP_PAGE,
        payload: { page },
      });

      // Reset genius page.
      updateCurrentGeniusPageAction.payload.page = '';
      dispatch(updateCurrentGeniusPageAction);

      // Navigate to page.
      navigate({
        pathname: route,
        search: window.location.search,
      });

      return;
    }

    // Load Genius language if not yet already
    let state = getState();
    const shouldFetchGeniusLanguage = language.shouldFetchGeniusLanguage(state);
    if (shouldFetchGeniusLanguage) {
      await dispatch(getGeniusLanguage());
    }

    // Update redux state to set current signup page
    dispatch(updateCurrentGeniusPageAction);

    // Return if we don't want to navigate to the update page
    if (shouldNavTo && page) {
      state = getState();
      const route = language.getGeniusRouteByPage(state, page, fromSignup);
      route &&
        navigate(
          {
            pathname: route,
            search: search || window.location.search,
          },
          {
            state: { ...locationState, fromSignup },
          }
        );
    }
  };

export default updateCurrentGeniusPage;
