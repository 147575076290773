import types from 'actions/types';
import AlbertClient from 'api/AlbertClient';
import * as WEB from 'types/interfaces';
import { onErrorDisplayDefaultBanner } from 'utils/axiosMiddlewareUtils';

type RequestData = {
  setup_type: string; // 'auto' | 'auto_with_existing' | 'manual'
  account_id: string;

  // Manual auth
  account_number?: string;
  routing_number?: string;
};

export default (
    data: RequestData
  ): WEB.ActionCreator<Promise<Record<string, any>>> =>
  async (dispatch: WEB.Dispatch): Promise<Record<string, any>> => {
    let error = false;

    const response = await dispatch({
      types: [
        types.CREATE_SAVINGS_ACCOUNT_REQUEST,
        types.CREATE_SAVINGS_ACCOUNT_SUCCESS,
        types.CREATE_SAVINGS_ACCOUNT_FAILURE,
      ],
      payload: {
        request: {
          data,
          method: 'post',
          url: AlbertClient.savingsView(),
        },
        options: {
          onError: (middlewareOptions: any, options: any) => {
            error = true;
            return onErrorDisplayDefaultBanner(middlewareOptions, options);
          },
        },
      },
    });

    return { response, error };
  };
