enum Investing {
  INITIATED = 'i',
  PENDING = 'p',
  COMPLETED = 'c',
  UNDER_REVIEW = 'mr',
  REJECTED = 'r',
  CANCEL_IN_PROGRESS = 'cp',
}

export default { Investing };
