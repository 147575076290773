/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { VirtualCardConfigId } from '../constants/DebitCardStyle';

const URLS = {
  // Profile level
  appData: '/api/v2/app/',
  profile: '/api/v2/profile/',
  profileEmailUpdate: '/api/v2/profile/update/email/',
  profilePhoneUpdate: '/api/v2/profile/update/phone/',

  // Genius
  geniusOnboarding: '/api/v2/genius/web/',
  geniusSubscription: '/api/v2/genius/',
  geniusPayments: '/api/v2/payments/',

  // KYC
  kycLanguage: '/api/v2/language/kyc/web/',
  kycProfile: '/api/v2/profile/kyc/',
  kycProfileMFA: '/api/v2/profile/kyc/mfa/',

  // Persona
  personaCallback: '/api/v2/persona/callback/',

  // Utils
  login: '/api/login/',
  logout: '/api/logout/',
  signup: '/api/v2/signup/web/',
  signupIntro: '/api/v2/signup/intro/web/',
  verifyRouting: '/api/v2/utility/verifyrouting/',
  influencers: '/api/v2/influencers/',
  finishSignupFlow: '/api/v2/signup/web/finish/',

  // Texting
  textWelcome: '/api/v2/text/welcome/',
  textDownloadApp: '/api/v2/text/downloadapp/',
  textConfirmCode: '/api/v2/text/confirmcode/',

  // Email
  emailVerification: '/api/v2/email/verify/',

  // Savings
  savings: '/api/v2/savings/',
  savingsAccount: '/api/v2/savings/account/',
  savingsTransaction: '/api/v2/savings/transaction/',
  wellsFargoSavingsStatements: '/api/v2/statements/savings/',
  coastalSavingsStatements: '/api/v2/statements/savings/coastal/',
  coastalSavingsStatement: ({ statementId }: { statementId: number }) =>
    `/api/v2/statements/savings/coastal/${statementId}/`,

  // Investing
  investingDocuments: '/api/v2/investing/accounts/docs/',
  investingTransaction: ({ transactionId }: { transactionId: number }) =>
    `/api/v2/investing/trade/transaction/${transactionId}/`,
  investingCashTransaction: ({ transactionId }: { transactionId: number }) =>
    `/api/v2/investing/cash/transactions/${transactionId}/`,
  investingPreferences: '/api/v2/investing/investing_preferences/',

  // Institutions
  institutionsLinkToken: '/api/v2/institutions/linktoken/',
  institutionsLogin: '/api/v2/institutions/login/',
  institutions: '/api/v2/institutions/',
  linkingVerification: '/api/v2/account/linkingverification/',

  // Property
  property: '/api/v2/property/',

  // Move Money.
  transfers: '/api/v2/transfers/',
  account: ({ accountId }: { accountId: number }) =>
    `/api/v2/account/${accountId}/`,
  accountAuth: ({ accountId }: { accountId: number }) =>
    `/api/v2/account/auth/${accountId}/`,

  // Banking
  bankingData: '/api/v2/banking/data/',
  bankingAccount: '/api/v2/banking/account/',
  bankingAccountNum: ({ bankAccountId }: { bankAccountId: number }) =>
    `/api/v2/banking/account/${bankAccountId}/sensitivedata/`,
  bankingAccountDocuments: ({ bankAccountId }: { bankAccountId: number }) =>
    `/api/v2/banking/account/${bankAccountId}/documents/`,
  bankingStatements: ({ bankAccountId }: { bankAccountId: number }) =>
    `/api/v2/banking/account/${bankAccountId}/statement/`,
  bankingStatement: ({
    bankAccountId,
    statementId,
  }: {
    bankAccountId: number;
    statementId: number;
  }) => `/api/v2/banking/account/${bankAccountId}/statement/${statementId}/`,
  transferHistory: '/api/v2/banking/transferhistory/',
  submitBankingApplication: ({ bankAccountId }: { bankAccountId: number }) =>
    `/api/v2/banking/account/${bankAccountId}/approval/`,
  completeBankingApplication: ({ bankAccountId }: { bankAccountId: number }) =>
    `/api/v2/banking/account/${bankAccountId}/completion/`,
  fetchAtomicAccessToken: ({ bankAccountId }: { bankAccountId: number }) =>
    `/api/v2/banking/account/${bankAccountId}/directdepositswitch/`,
  achTransactionCreate: ({ achAccountId }: { achAccountId: number }) =>
    `/api/v2/banking/ach/${achAccountId}/transaction/`,
  achTransactionCancel: ({
    achAccountId,
    transactionId,
  }: {
    achAccountId: number;
    transactionId: number;
  }) => `/api/v2/banking/ach/${achAccountId}/transaction/${transactionId}/`,

  debitCardVirtualImage: ({ debitCardId }: { debitCardId: number }) =>
    `/api/v2/banking/debitcard/${debitCardId}/virtualimage/`,

  // Third party
  googlePlacePredictions: '/thirdparty/google-place-predictions/',
  googlePlaceById: '/thirdparty/google-place/',

  // Tracking
  tracking: '/api/v2/track/',
};

const AlbertClient = {
  // //////////////////////////////////
  /* ======= PROFILE LEVEL ========= */
  // //////////////////////////////////
  appDataView(): string {
    return URLS.appData;
  },

  profileView(queryParams?: URLSearchParams): string {
    const url = queryParams ? `${URLS.profile}?${queryParams}` : URLS.profile;
    return url;
  },

  profileUpdateEmailView(): string {
    return URLS.profileEmailUpdate;
  },

  profileUpdatePhoneView(): string {
    return URLS.profilePhoneUpdate;
  },

  // //////////////////////////////////
  /* =========== GENIUS ============ */
  // //////////////////////////////////

  geniusOnboardingView(): string {
    return URLS.geniusOnboarding;
  },

  geniusSubscriptionView(): string {
    return URLS.geniusSubscription;
  },

  geniusPaymentsView(): string {
    return URLS.geniusPayments;
  },

  // ///////////////////////////////
  /* =========== KYC ============ */
  // ///////////////////////////////

  kycLanguageView(): string {
    return URLS.kycLanguage;
  },

  kycVerifyProfile(): string {
    return URLS.kycProfile;
  },

  profileKYCMFAView(): string {
    return URLS.kycProfileMFA;
  },

  // ///////////////////////////////
  /* ========= PERSONA ========== */
  // ///////////////////////////////
  personaCallbackView(): string {
    return URLS.personaCallback;
  },

  // //////////////////////////////////
  /* =========== SAVINGS ============ */
  // //////////////////////////////////
  savingsAccountView(): string {
    return URLS.savingsAccount;
  },

  savingsTransactionView(): string {
    return URLS.savingsTransaction;
  },

  wellsFargoSavingsStatementsView(): string {
    return URLS.wellsFargoSavingsStatements;
  },

  coastalSavingsStatementsView(): string {
    return URLS.coastalSavingsStatements;
  },

  coastalSavingsStatementView(statementId: number): string {
    return URLS.coastalSavingsStatement({ statementId });
  },

  // //////////////////////////////////
  /* =========== INVESTING ============ */
  // //////////////////////////////////
  investingDocumentsView(): string {
    return URLS.investingDocuments;
  },

  investingTradeTransactionView(transactionId: number): string {
    return URLS.investingTransaction({ transactionId });
  },

  investingCashTransactionView(transactionId: number): string {
    return URLS.investingCashTransaction({ transactionId });
  },

  investingPreferences(): string {
    return URLS.investingPreferences;
  },

  // //////////////////////////////////
  /* ============= UTILS =========== */
  // //////////////////////////////////
  loginView(): string {
    return URLS.login;
  },

  logoutView(): string {
    return URLS.logout;
  },

  signupLanguageView(): string {
    return URLS.signup;
  },

  signupIntroView(): string {
    return URLS.signupIntro;
  },

  verifyRoutingView(queryParams?: URLSearchParams): string {
    const url = queryParams
      ? `${URLS.verifyRouting}?${queryParams}`
      : URLS.verifyRouting;
    return url;
  },

  influencersView(queryParms: URLSearchParams): string {
    return `${URLS.influencers}?${queryParms}`;
  },

  finishSignupFlowView(): string {
    return URLS.finishSignupFlow;
  },

  // //////////////////////////////////
  /* =========== TEXT/EMAIL ======== */
  // //////////////////////////////////
  textWelcomeView(): string {
    return URLS.textWelcome;
  },

  textDownloadApp(): string {
    return URLS.textDownloadApp;
  },

  textConfirmCode(): string {
    return URLS.textConfirmCode;
  },

  emailConfirmCode(): string {
    // Same URL as text confirm code, only with added query param.
    return `${URLS.textConfirmCode}?m=e`;
  },

  emailVerification(): string {
    return URLS.emailVerification;
  },

  // //////////////////////////////////
  /* ========== INSTITUTION ======== */
  // //////////////////////////////////

  institutionView(queryParams?: URLSearchParams): string {
    const url = queryParams
      ? `${URLS.institutions}?${queryParams}`
      : URLS.institutions;

    return url;
  },

  institutionsLoginView(queryParams?: URLSearchParams): string {
    return queryParams
      ? `${URLS.institutionsLogin}?${queryParams}`
      : URLS.institutionsLogin;
  },

  institutionsLinkTokenView(): string {
    return URLS.institutionsLinkToken;
  },

  linkingVerificationView(queryParams: URLSearchParams): string {
    return `${URLS.linkingVerification}?${queryParams}`;
  },

  // //////////////////////////////////
  /* ========== INSTITUTION ======== */
  // //////////////////////////////////
  savingsView(): string {
    return URLS.savings;
  },

  // //////////////////////////////////
  /* ========== PROPERTY ======== */
  // //////////////////////////////////

  /**
   * Get the property endpoint.
   * @param propertyId e.g. "property_123"
   * @returns endpoint url
   */
  propertyView(propertyId?: string): string {
    let url = URLS.property;
    if (propertyId) {
      const id = propertyId.replace('property_', '');
      url += `${id}`;
    }
    return url;
  },

  // //////////////////////////////////
  /* ========== MM Transfers ======== */
  // //////////////////////////////////
  transfersView(): string {
    return URLS.transfers;
  },

  accountView(accountId: number): string {
    return URLS.account({ accountId });
  },

  accountAuthView(accountId: number): string {
    return URLS.accountAuth({ accountId });
  },

  // //////////////////////////////////
  /* ========== BANKING ======== */
  // //////////////////////////////////
  /**
   * Get the banking account endpoint.
   * @param bankingAccountId
   * @returns endpoint url
   */
  bankingAccountView(bankingAccountId?: number): string {
    let url = URLS.bankingAccount;
    if (bankingAccountId) {
      url += `${bankingAccountId}/`;
    }
    return url;
  },

  bankingAccountNum(bankAccountId: number): string {
    return URLS.bankingAccountNum({ bankAccountId });
  },

  bankingAccountDocumentsView(bankAccountId: number): string {
    return URLS.bankingAccountDocuments({ bankAccountId });
  },

  bankingData(firstName?: string, lastName?: string): string {
    let url = URLS.bankingData;
    if (firstName && lastName) {
      url += `?first_name=${firstName}&last_name=${lastName}`;
    }
    return url;
  },

  bankingStatementView(bankAccountId: number, statementId: number): string {
    return URLS.bankingStatement({ bankAccountId, statementId });
  },

  bankingStatementsView(bankAccountId: number): string {
    return URLS.bankingStatements({ bankAccountId });
  },

  transferHistoryView(): string {
    return URLS.transferHistory;
  },

  bankingSubmitApplication(bankAccountId: number): string {
    return URLS.submitBankingApplication({ bankAccountId });
  },

  bankingCompleteApplication(bankAccountId: number): string {
    return URLS.completeBankingApplication({ bankAccountId });
  },

  bankingFetchAtomicAccessToken(bankAccountId: number): string {
    return URLS.fetchAtomicAccessToken({ bankAccountId });
  },

  bankingACHTransactionCreate(achAccountId: number): string {
    return URLS.achTransactionCreate({ achAccountId });
  },

  bankingACHTransactionCancel(
    achAccountId: number,
    transactionId: number
  ): string {
    return URLS.achTransactionCancel({ achAccountId, transactionId });
  },

  debitCardVirtualImageView(
    debitCardId: number,
    style: VirtualCardConfigId
  ): string {
    return `${URLS.debitCardVirtualImage({ debitCardId })}?config_id=${style}`;
  },

  // //////////////////////////////////
  /* ========== THIRD PARTY ======== */
  // //////////////////////////////////

  googlePlacePredictionsView(input: string, sessiontoken: string): string {
    return `${URLS.googlePlacePredictions}?input=${input}&sessiontoken=${sessiontoken}`;
  },

  googlePlaceByIdView(id: string, sessiontoken: string): string {
    return `${URLS.googlePlaceById}${id}?sessiontoken=${sessiontoken}`;
  },

  // //////////////////////////////////
  /* ============ TRACKING ========= */
  // //////////////////////////////////

  trackingView(): string {
    return URLS.tracking;
  },
};

export default AlbertClient;
