/** Enums represent backend values and what's in Redux store
 * OPTIONS constants represent what to render on the UI
 */
import * as WEB from 'types/interfaces';

enum EmploymentStatus {
  EMPLOYED = 'Employed',
  SELF_EMPLOYED = 'Self-Employed',
  STUDENT = 'Student',
  UNEMPLOYED = 'Unemployed',
  RETIRED = 'Retired',
}

const EMPLOYMENT_OPTIONS: Record<string, WEB.DropdownItem> = {
  [EmploymentStatus.EMPLOYED]: { value: 'Employed' },
  [EmploymentStatus.SELF_EMPLOYED]: { value: 'Self-Employed' },
  [EmploymentStatus.STUDENT]: { value: 'Student' },
  [EmploymentStatus.UNEMPLOYED]: { value: 'Unemployed' },
  [EmploymentStatus.RETIRED]: { value: 'Retired' },
};

enum CreditScore {
  EXCELLENT = 'excellent',
  GOOD = 'good',
  AVERAGE = 'average',
  POOR = 'poor',
  UNKNOWN = 'n/a',
}

const CREDIT_SCORE_OPTIONS: Record<string, WEB.DropdownItem> = {
  [CreditScore.EXCELLENT]: { value: 'Excellent (720-850)' },
  [CreditScore.GOOD]: { value: 'Good (690-719)' },
  [CreditScore.AVERAGE]: { value: 'Average (630-689)' },
  [CreditScore.POOR]: { value: 'Poor (320-629)' },
  [CreditScore.UNKNOWN]: { value: 'I don’t know' },
};

enum InvestingExperience {
  NO_EXPERIENCE = 0,
  LIMITED = 1,
  GOOD = 2,
  EXTENSIVE = 3,
}

const INVESTING_EXPERIENCE_OPTIONS: Record<number, WEB.DropdownItem> = {
  [InvestingExperience.NO_EXPERIENCE]: {
    value: 'No experience',
    subValue: 'I have never invested before',
  },
  [InvestingExperience.LIMITED]: {
    value: 'Limited',
    subValue: 'I know a little bit about investing',
  },
  [InvestingExperience.GOOD]: {
    value: 'Good',
    subValue: `I've done my fair share of investing`,
  },
  [InvestingExperience.EXTENSIVE]: {
    value: 'Extensive',
    subValue: 'I have extensive investing knowledge and experience',
  },
};

enum InvestingRisk {
  AGGRESSIVE = 0,
  MODERATE = 1,
  CONSERVATIVE = 2,
}

const INVESTING_RISK_OPTIONS: Record<number, WEB.DropdownItem> = {
  [InvestingRisk.AGGRESSIVE]: {
    value: 'Aggressive',
    subValue: 'High risk, with potential for larger growth',
  },
  [InvestingRisk.MODERATE]: {
    value: 'Moderate',
    subValue: 'Some risk, with potential for moderate growth',
  },
  [InvestingRisk.CONSERVATIVE]: {
    value: 'Conservative',
    subValue: 'Stability, with potential for lower growth',
  },
};

enum InvestingLiquidity {
  VERY_IMPORTANT = 0,
  SOMEWHAT_IMPORTANT = 1,
  NOT_IMPORTANT = 2,
}

const INVESTING_LIQUIDITY_OPTIONS: Record<number, WEB.DropdownItem> = {
  [InvestingLiquidity.VERY_IMPORTANT]: { value: 'Less than 1 year' },
  [InvestingLiquidity.SOMEWHAT_IMPORTANT]: { value: '2-5 years' },
  [InvestingLiquidity.NOT_IMPORTANT]: { value: 'More than 5 years' },
};

enum Gender {
  MALE = 'm',
  FEMALE = 'f',
  OTHER = 'o',
}

const GENDER_OPTIONS = [
  {
    value: 'Male',
    key: 'm',
  },
  {
    value: 'Female',
    key: 'f',
  },
  {
    value: 'Other',
    key: 'o',
  },
];

enum MartialStatus {
  SINGLE = 'Single',
  MARRIED = 'Married',
}

const MARRIED_OPTIONS = [MartialStatus.SINGLE, MartialStatus.MARRIED];

const DATE_INPUT_FORMAT = 'yyyy-MM-dd';

export default {
  EMPLOYMENT_OPTIONS,
  CREDIT_SCORE_OPTIONS,
  INVESTING_EXPERIENCE_OPTIONS,
  INVESTING_RISK_OPTIONS,
  INVESTING_LIQUIDITY_OPTIONS,
  DATE_INPUT_FORMAT,
  Gender,
  GENDER_OPTIONS,
  MartialStatus,
  MARRIED_OPTIONS,
};
