import { ExclamationCircle } from '@styled-icons/fa-solid/ExclamationCircle';
import * as React from 'react';
import styled from 'styled-components';
import Text, { TextColor, TextSizes } from 'components/common/Text';
import { mixins } from 'styles';

type Props = {
  /* name of the component */
  name: string;
  /* error text to display */
  text?: string;
  /* icon to render besides default */
  icon?: React.ReactElement;
};

const StyledErrorText = styled.div`
  margin-top: ${mixins.pxToRem('6px')};
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  *:first-child {
    margin-top: ${mixins.pxToRem('2px')};
    margin-right: ${mixins.pxToRem('8px')};
  }
`;

const StyledErrorIcon = styled(ExclamationCircle)`
  color: ${({ theme }) => theme.colors.primaryError};
  flex-grow: 0;
  flex-shrink: 0;
  // Using rem for the flex-basis leads to the error icon
  // looking oblong on some screen sizes
  flex-basis: 12px;
`;

const ErrorText = (props: Props): React.ReactElement | null => {
  const { text, icon } = props;
  return (
    <StyledErrorText {...props}>
      {icon || <StyledErrorIcon className='error-text' />}
      <Text size={TextSizes.TINY} inline color={TextColor.GRAY_DARK}>
        {text}
      </Text>
    </StyledErrorText>
  );
};

export default ErrorText;
