/* eslint-disable @typescript-eslint/no-explicit-any */
import { FORM_ERROR } from 'final-form';
import * as React from 'react';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import textWelcome from 'actions/async/textWelcome';
import updateProfile from 'actions/async/updateProfile';
import hideBanner from 'actions/banner/hideBanner';
import removeBanner from 'actions/banner/removeBanner';
import Button from 'components/common/Button';
import Dropdown from 'components/common/Dropdown';
import { DropdownWidthSize } from 'components/common/Dropdown/Dropdown';
import Input from 'components/common/Input';
import { InputTypes } from 'components/common/Input/TextInput';
import { InputWidth } from 'components/common/Input/shared';
import Spacer from 'components/common/Spacer';
import ButtonsContainer from 'components/layout/ButtonsContainer';
import PageBase from 'components/layout/PageBase';
import Pages from 'constants/Pages';
import useNavigateFunction from 'hooks/useNavigateFunction';
import useVerifyCurrentPage from 'hooks/useVerifyCurrentPage';
import * as language from 'reducers/entities/language';
import * as profile from 'reducers/entities/profile';
import { breakpoints, spacers } from 'styles';
import * as WEB from 'types/interfaces';
import { DropdownItem, InputFieldProps } from 'types/interfaces';
import { delayWithState } from 'utils/delay';
import {
  getLanguageButtons,
  getLanguageInfo,
  getLanguageSection,
} from 'utils/getFromLanguage';
import { composeValidators, isRequired, minLen } from 'utils/validators';

type Props = {
  nextPage: string;
};

const StyledDropdown = styled(Dropdown.Menu)`
  width: 55%;
  @media ${breakpoints.mobileLarge} {
    width: 100%;
  }
`;

const HiddenInput = styled.input`
  display: none;
`;

/**
 * DEPRECATED
 */
const SourcePhoneNumber = (props: Props): React.ReactElement => {
  const { updateCurrentSignupPage } = useNavigateFunction();
  // Make Redux is up-to-date with currentSignUpPage
  useVerifyCurrentPage(Pages.SOURCE_PHONE_NUMBER);

  // ///////////////////////////////
  /* =========== STATE ========== */
  // ///////////////////////////////
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [isCompleted, setIsCompleted] = React.useState<boolean>(false);
  const [phoneError, setPhoneError] = React.useState('');
  const [source, setSource] = React.useState('');
  const [visitedSourceMenu, setVisitedSourceMenu] =
    React.useState<boolean>(false);

  // ///////////////////////////////
  /* ======== MAPPED STATE ====== */
  // ///////////////////////////////
  const languageCtx = useSelector((state: WEB.RootState) =>
    language.getSignupPageLanguage(state, Pages.SOURCE_PHONE_NUMBER)
  );

  const sourceDefault = useSelector(
    (state: WEB.RootState) =>
      profile.getInfoValueByField(state, 'personal_info', 'signup_source') || ''
  );

  const phoneDefault = useSelector(
    (state: WEB.RootState) => profile.getValueByField(state, 'phone') || ''
  );

  React.useEffect(() => {
    setSource(sourceDefault);
  }, [sourceDefault]);

  // ///////////////////////////////
  /* =========== HOOKS ========== */
  // ///////////////////////////////
  const dispatch = useDispatch();

  // //////////////////////////////////
  /* =========== LANGUAGE ========== */
  // //////////////////////////////////

  // Section keys
  const SOURCE_KEY = 'source';
  const PHONE_NUMBER_KEY = 'phoneNumber';

  // Sections
  const sourceSection = getLanguageSection(languageCtx, SOURCE_KEY);
  const sources = (sourceSection?.options || []) as {
    display: string;
    subtitle: string;
    value: string;
  }[];

  const phoneNumberSection = getLanguageSection(languageCtx, PHONE_NUMBER_KEY);

  const infoPaneContext = getLanguageInfo(languageCtx);

  // Buttons
  const buttonsCtx = getLanguageButtons(languageCtx);

  // //////////////////////////////////
  /* =========== HANDLERS ========== */
  // //////////////////////////////////
  const handleOnNext = async (values: Record<string, string>) => {
    // Remove any error banner from previous attempts
    dispatch(removeBanner());

    if (!source) {
      setVisitedSourceMenu(true);
      return { source: 'error' };
    }

    // Indicate that we are submitting a form and show a loading button state
    setIsSubmitting(true);

    // Hide any error banners until all requests are complete
    dispatch(hideBanner());

    // Send welcome text will also save phone number on Profile
    const phoneNumber = values['phone-number'];
    const textPromise: any = dispatch(textWelcome(phoneNumber));

    const profilePromise: any = dispatch(
      updateProfile({ personal_info_signup_source: source })
    );

    const results = await Promise.all([textPromise, profilePromise]);
    setIsSubmitting(false);
    dispatch(hideBanner(false));

    // Handle the text welcome request response
    const textError = results[0];
    if (textError) {
      if (typeof textError === 'string') {
        setPhoneError(textError);
      }
      return { 'phone-number': 'error' };
    }

    // Handle the update profile request response
    const { error: profileError } = results[1];
    if (profileError) return { [FORM_ERROR]: 'error' };

    // Indicate that we have completed the form and show a completed button state
    await delayWithState(400, setIsCompleted);
    dispatch(updateCurrentSignupPage({ page: props.nextPage }));
  };

  const handleVisitedSourceMenu = () => setVisitedSourceMenu(true);
  const handleSourceOnChange = ({ key }: { key: number | string }) => {
    const selectedSourceKey = sources[key]?.value || '';
    setSource(selectedSourceKey);
    handleVisitedSourceMenu();
  };

  // Rework options to match the format expected by the Dropdown component
  let dropdownDefaultValue = '';
  const dropdownItems: DropdownItem[] = sources.map(
    ({ value, display, subtitle }) => {
      if (value === sourceDefault) {
        dropdownDefaultValue = display;
      }
      return { value: display, subValue: subtitle || null };
    }
  );

  // ///////////////////////////////////////
  /* =========== FORM ELEMENTS ========== */
  // ///////////////////////////////////////

  const PhoneNumberInput = ({
    input,
    inputOnChange,
    meta,
  }: Partial<InputFieldProps>) => {
    const invalid = (meta?.touched && meta.error) || phoneError;
    const inputProps = {
      ...input,
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        input?.onChange(e);
        inputOnChange && inputOnChange(e);
      },
    };

    return (
      <Input.Text
        id='phone-number'
        type={InputTypes.PHONE}
        widthSize={InputWidth.SMALL}
        label={phoneNumberSection?.header}
        description={phoneNumberSection?.text}
        floatingLabel={phoneNumberSection?.placeholder}
        placeholder={phoneNumberSection?.placeholder}
        {...inputProps}
        invalid={invalid}
        errorText={
          phoneError || phoneNumberSection?.errorMessage || meta?.error
        }
      />
    );
  };

  return (
    <>
      <PageBase mobileHeader={infoPaneContext?.header || ''}>
        <StyledDropdown
          id='source-menu'
          label={sourceSection?.header}
          description={sourceSection?.text}
          floatingLabel={sourceSection?.placeholder || ''}
          items={dropdownItems}
          defaultValue={dropdownDefaultValue || ''}
          onClose={handleVisitedSourceMenu}
          onSelect={handleSourceOnChange}
          invalid={!source && visitedSourceMenu}
          errorText={sourceSection?.errorMessage}
          showError
          widthSize={DropdownWidthSize.SMALL}
        />
        <Form
          onSubmit={handleOnNext}
          initialValues={{
            'phone-number': phoneDefault,
            source: false,
          }}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Spacer space={spacers.tabLarge} />
              <Field name='source' component={HiddenInput} />
              <Field
                name='phone-number'
                component={PhoneNumberInput}
                validate={composeValidators(minLen(14), isRequired)}
                inputOnChange={() => setPhoneError('')}
              />
              <Spacer space={spacers.tabLarge} />
              <ButtonsContainer>
                <Button
                  id='source-confirm-button'
                  isCompleted={isCompleted}
                  isLoading={isSubmitting}
                  disabled={isSubmitting}
                >
                  {!isCompleted ? buttonsCtx?.primary : buttonsCtx?.complete}
                </Button>
              </ButtonsContainer>
            </form>
          )}
        />
      </PageBase>
    </>
  );
};

export default SourcePhoneNumber;
