import types from 'actions/types';
import AlbertClient from 'api/AlbertClient';
import { PropertySourceTypes } from 'constants/index';
import * as WEB from 'types/interfaces';
import { onErrorDisplayDefaultBanner } from 'utils/axiosMiddlewareUtils';

type Data = {
  placeid: string;
  apartment?: string;
  own: boolean;
  input_source: PropertySourceTypes;
  session_token: string;
};

type ResponseData = {
  status: 'ERROR' | 'SUCCESS';
  properties: WEB.Property[];
};

/**
 * Create a property.
 *
 * @param placeid Google Maps place id for the property address
 * @param apartment field value for the property
 * @param own field value for the property
 * @param source field value for the property
 */
export default (
    placeid: string,
    apartment: string,
    own: boolean,
    sessiontoken: string,
    source: PropertySourceTypes = PropertySourceTypes.LEGAL
  ) =>
  async (dispatch: WEB.Dispatch) => {
    let error = false;
    const data: Data = {
      placeid,
      own,
      session_token: sessiontoken,
      input_source: source,
    };

    if (apartment) {
      data.apartment = apartment;
    }

    const response = await dispatch<ResponseData>({
      types: [
        types.CREATE_PROPERTY_REQUEST,
        types.CREATE_PROPERTY_SUCCESS,
        types.CREATE_PROPERTY_FAILURE,
      ],
      payload: {
        request: {
          method: 'post',
          url: AlbertClient.propertyView(),
          data,
        },
        options: {
          onError: (middlewareOptions: any, options: any) => {
            error = true;
            return onErrorDisplayDefaultBanner(middlewareOptions, options);
          },
        },
      },
    });

    if (response.payload?.data?.status === 'ERROR') {
      error = true;
    }

    return { response, error };
  };
