import * as React from 'react';
import styled from 'styled-components';
import { mixins } from 'styles';

type Props = {
  length: number;
  currentIndex: number;
  onClick?: (i: number) => void;
};

const StyledSliderDotsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  *:not(:last-child) {
    margin-right: ${mixins.pxToRem('11px')};
  }
`;
const StyledSliderDot = styled.div<{ isActive?: boolean }>`
  background-color: ${({ theme, isActive }) =>
    isActive ? theme.colors.primaryText : theme.colors.lightGray1};
  ${mixins.fadeIn()}
  width: 8px;
  height: 8px;
  border-radius: 50%;
  cursor: pointer;
`;

const ProgressDots = ({
  length,
  currentIndex,
  onClick,
}: Props): React.ReactElement => (
  <StyledSliderDotsContainer>
    {Array.from(Array(length).keys()).map((i: number) => (
      <StyledSliderDot
        key={i}
        className={`progress-dot-${i}`}
        isActive={currentIndex === i}
        {...(onClick && { onClick: () => onClick(i) })}
      />
    ))}
  </StyledSliderDotsContainer>
);

export default ProgressDots;
