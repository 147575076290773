/* eslint-disable @typescript-eslint/no-unsafe-member-access */

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import cookie from 'cookie';
import * as WEB from 'types/interfaces';
import { BUCKET_KEY } from 'utils/percentageRollout';

// Redux middleware to append the Albert cohort bucket to outgoing requests.
export default () =>
  (next: (action: WEB.Action) => void) =>
  (action: WEB.Action): void => {
    const { request = {} } = action?.payload || {};
    const requestHeaders = request?.headers || {};

    const parsedCookie = cookie.parse(document.cookie);
    let cookieBucket = parsedCookie[BUCKET_KEY];
    if (cookieBucket == null || Number.isNaN(+cookieBucket)) {
      // all features off, generic
      cookieBucket = '100';
    }

    // Add bucket if request is outbound and it does not exist.
    if (
      !!request?.method &&
      !!request?.url &&
      !requestHeaders?.['x-albert-bucket']
    ) {
      requestHeaders['x-albert-bucket'] = cookieBucket;
    }

    request.headers = requestHeaders;

    // Proceed with action
    return next(action);
  };
